import { useEffect, useMemo, useState } from "react";

// Packages
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { isArray, isEmpty, join, map, sortBy } from "lodash";

// Hooks
import { useGetProjectCanvasLite, UseGetProjectCanvasQueryKeys } from "src/hooks/api";
import useGetScenarios from "src/hooks/api/scenarios/useGetScenarios";

// Utils
import {
  isConnectorDatasets as isConnectorDatasetsHelper,
  isRecipesInProject as isRecipesInProjectHelper
} from "../../../utils";

// Open API
import { ProjectCanvasDto, SegmentDto } from "@rapidcanvas/rc-api-core";

// Stores
import { useCanvasStore } from "src/store/store";

const useRecipesStatus = () => {
  const { projectId } = useParams() || {};

  const queryClient = useQueryClient();

  // Stores - STARTS >>
  const isRecipesRunningAcrossScenariosStore = useCanvasStore(
    (state) => state?.isRecipesRunningAcrossScenarios
  );
  // << ENDS - Stores

  // States - STARTS >>
  const [isRecipesInProject, setIsRecipesInProject] = useState(false);
  const [isConnectorDatasets, setIsConnectorDatasets] = useState(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Queries
  const {
    isLoading: isLoadingScenarios,
    data: scenariosDataResponse,
    refetch: refetchScenarios
  } = useGetScenarios({
    projectId: projectId!,
    refetchOnMount: true
  });

  const scenariosData = useMemo(
    () =>
      isArray(scenariosDataResponse)
        ? map(scenariosDataResponse, (scenario) => ({
            ...scenario,
            segments: isEmpty(scenario?.segments)
              ? ""
              : join(
                  map(
                    sortBy(scenario?.segments, "name"),
                    (segment) => (segment as SegmentDto)?.name
                  ),
                  ", "
                )
          }))
        : [],
    [scenariosDataResponse]
  );

  const { isLoading: isRefetchingProjectCanvas, refetch: refetchProjectCanvas } =
    useGetProjectCanvasLite({
      projectId,
      onSuccess: (data) => {
        setIsRecipesInProject(() => isRecipesInProjectHelper(data));
        setIsConnectorDatasets(() => isConnectorDatasetsHelper(data));
      },
      enabled: false,
      initialData: null
    });

  useEffect(() => {
    if (!!projectId) {
      const data: ProjectCanvasDto | undefined = queryClient.getQueryData([
        UseGetProjectCanvasQueryKeys.ProjectCanvasLite,
        projectId
      ]);

      if (isEmpty(data)) {
        refetchProjectCanvas();
      } else {
        setIsRecipesInProject(() => isRecipesInProjectHelper(data));
        setIsConnectorDatasets(() => isConnectorDatasetsHelper(data));
      }
    }
  }, [projectId]);

  const isLoading = useMemo(() => !!isLoadingScenarios, [isLoadingScenarios]);

  return {
    isLoading,
    isRefetchingProjectCanvas,

    isRecipesInProject,
    isRecipesRunningAcrossScenariosStore,

    isConnectorDatasets,

    scenariosData,
    refetchScenarios
  };
};

export default useRecipesStatus;
