import React, { useEffect, useMemo, useState } from "react";
import _, { orderBy, toLower } from "lodash";
import { makeStyles, Checkbox, Tooltip } from "@material-ui/core";

import AccordianCard from "./AccordianCard";
import { projectMetadata } from "../utils/CopyDownloadProject.constants";
import { AssociateDto, AssociateDtoInputTypeEnum } from "@rapidcanvas/rc-api-core";

interface IProps {
  data: AssociateDto[];
  onChange: (key: string, value: string[], predictionServices: string[]) => void;
}

const useStyles = makeStyles({
  flexBetween: { display: "flex", gap: "6px", alignItems: "center" }
});

interface IDataApp {
  id: string;
  name: string;
  checked: boolean;
  disabled: boolean;
  predictionService?: string;
}

const CopyDataApp: React.FC<IProps> = (props) => {
  const { data, onChange } = props;
  const [dataApps, setDataApps] = useState<IDataApp[]>([]);
  const { flexBetween } = useStyles();

  useEffect(() => {
    if (data) {
      setDataApps(
        _.map(data, (app) => ({
          id: app.id ?? "",
          name: (app.displayName || app.name) ?? "",
          disabled: app.inputType === AssociateDtoInputTypeEnum.JobCanvas,
          checked: false,
          predictionService: app.predictionService
        }))
      );
    }
  }, [data]);

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const newDataApps: IDataApp[] = [];
    const selectedDataApps: string[] = [];
    const predictionServices: string[] = [];
    _.forEach(dataApps, (app) => {
      if (app.id === id) {
        newDataApps.push({ ...app, checked: e.target.checked });
        if (e.target.checked) {
          selectedDataApps.push(id);
          if (app.predictionService) {
            predictionServices.push(app.predictionService);
          }
        }
      } else {
        newDataApps.push(app);
        if (app.checked) {
          selectedDataApps.push(app.id);
          if (app.predictionService) {
            predictionServices.push(app.predictionService);
          }
        }
      }
    });

    setDataApps(newDataApps);
    onChange(projectMetadata.dataapps.id, selectedDataApps, predictionServices);
  };

  const checkedAll = useMemo(
    () => !_.isEmpty(dataApps) && !_.some(dataApps, { checked: false }),
    [dataApps]
  );

  const handleCheckAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDataApps: IDataApp[] = [];
    const selectedDataApps: string[] = [];
    const predictionServices: string[] = [];
    _.forEach(dataApps, (app) => {
      newDataApps.push({ ...app, checked: e.target.checked && !app.disabled });
      if (!app.disabled) {
        if (e.target.checked) {
          selectedDataApps.push(app.id);
          if (app.predictionService) {
            predictionServices.push(app.predictionService);
          }
        }
      }
    });

    setDataApps(newDataApps);
    onChange(projectMetadata.dataapps.id, selectedDataApps, predictionServices);
  };

  if (_.isEmpty(dataApps)) {
    return null;
  }

  return (
    <AccordianCard
      accordianDetailsStyle={{ maxHeight: "135px", overflow: "auto" }}
      label={projectMetadata.dataapps.label}
      title={
        <div className={flexBetween}>
          <Checkbox
            checked={checkedAll}
            disabled={_.isEmpty(dataApps)}
            inputProps={{ "aria-label": "primary checkbox" }}
            onChange={handleCheckAllChange}
            onClick={(e) => e.stopPropagation()}
          />
          <span>{projectMetadata.dataapps.label}</span>
        </div>
      }>
      <div>
        {_.map(
          orderBy(dataApps, (item) => toLower(item.name)),
          (app) => (
            <Tooltip
              title={
                app.disabled
                  ? "Copying a DataApp with a Job as input is not allowed because project job runs are not included in this action"
                  : ""
              }>
              <div className={flexBetween} key={app.id}>
                <Checkbox
                  disabled={app.disabled}
                  checked={app.checked}
                  onChange={(e) => handleCheckChange(e, app.id)}
                  inputProps={{ "aria-label": "dataApps checkbox" }}
                />
                <span>{app.name}</span>
              </div>
            </Tooltip>
          )
        )}
      </div>
    </AccordianCard>
  );
};

export default CopyDataApp;
