import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { deleteAPIWithRethrow, handleResponse } from "src/utils/apiService";

interface IVariables {
  id: string;
}

const useDeleteProjectRun = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  return useMutation({
    mutationFn: async ({ id }) => {
      return await deleteAPIWithRethrow(`/v2/project-runs/${id}`, {});
    },
    onSuccess: () => {
      handleResponse({ successMessage: "Prediction Run deleted successfully" });
    }
  });
};

export default useDeleteProjectRun;
