import IconButton from "@material-ui/core/IconButton";
import React, { useMemo, useState } from "react";
import _, { capitalize, map } from "lodash";
import { Box, Grid, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { useNavigate, useParams, generatePath } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import useGetPaginatedModels, {
  QUERY_KEY_PAGINATED_MODELS
} from "src/hooks/api/models/useGetPaginatedModels";
import useGetPaginatedArtifacts, {
  QUERY_KEY_ARTIFACTS_PAGINATED
} from "src/hooks/api/artifacts/useGetPaginatedArtifacts";
import useDeleteModel from "src/hooks/useDeleteModel";
import ArtifactsTable from "./Artifacts/ArtifactsTable";
import CreateArtifact from "./CreateArtifact";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import ModelsTable from "./Models/ModelsTable";
import NoDataFoundDefault from "src/pages/common/NoDataFoundDefault";
import Search from "src/components/custom/Search/Search";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import styles from "./ArtifactsAndModels.module.scss";
import useDeleteArtifact from "src/hooks/useDeleteArtifact";
import { ArtifactDto } from "@rapidcanvas/rc-api-core";
import { PlusIcon } from "src/icons/NewUX/PlusIcon";
import { Tabs, Tab, TabPanel } from "src/components";
import { WebPaths } from "src/routing/routes";
import { handleResponse } from "src/utils/apiService";
import CommonLoader from "src/components/CommonLoader";
import SubTopNavBarBreadcrumbs from "./subTopNavBarBreadcrumbs";
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { AddArtifactsModal } from "src/pages/Projects/common/AddArtifactsModal";
import { useCanvasStore } from "src/store/store";
import shallow from "zustand/shallow";
import { createEntityWithRethrow } from "src/api";
import { useGetProjectCanvas } from "src/hooks/api";
import { AddModelsModal } from "src/pages/Projects/common/AddModelsModal";

const useStyles = makeStyles({
  wrapperContainer: {
    height: "100%"
  }
});

const TABS = {
  artifacts: {
    key: "artifacts",
    singular: "artifact",
    label: "Artifacts"
  },
  models: {
    key: "models",
    singular: "model",
    label: "Models"
  }
};

const ArtifactsAndModels: React.FC = () => {
  const { entityName, projectId } = useParams();
  const navigate = useNavigate();
  const { project } = useProjectContext() || {};

  const classes = useStyles();
  const deleteArtifact = useDeleteArtifact();
  const deleteModel = useDeleteModel();
  const queryClient = useQueryClient();

  const isModelTab = entityName === TABS.models.key;
  const [value, setValue] = useState(isModelTab ? 2 : 1);
  const [targetName, setTargetName] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isAddArtifactModalOpen, setIsAddArtifactModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isAddModelsModalOpen, setIsAddModelsModalOpen] = useState(false);

  const [setReloadTrigger] = useCanvasStore((state) => [state.setReloadTrigger], shallow);
  const projectCanvasData = useGetProjectCanvas({
    projectId,
    refetchOnMount: true,
    enabled: !!projectId
  });
  const { artifactNodes, modelNodes } = useMemo(() => {
    const groupBy = _.groupBy(projectCanvasData.data?.nodes, (node) =>
      (node as $TSFixMe)?.type?.toLowerCase()
    );

    return {
      artifactNodes: _.get(groupBy, "artifact", []),
      modelNodes: _.get(groupBy, "model", [])
    };
  }, [projectCanvasData.data]);

  const { selectedTab, tabName } = useMemo(() => {
    return {
      selectedTab: isModelTab ? TABS.models.key : TABS.artifacts.key,
      tabName: isModelTab ? TABS.models.singular : TABS.artifacts.singular
    };
  }, [isModelTab]);

  const { data: paginatedArtifactsData, isLoading: isPaginatedArtifactsLoading } =
    useGetPaginatedArtifacts(projectId);

  const { data: paginatedModelsData, isLoading: isPaginatedModelsLoading } =
    useGetPaginatedModels(projectId);

  const getUpdateValue = (artifact: ArtifactDto) =>
    artifact?.fileObjects?.reduce(
      (acc, item) => ((item?.updated as number) > acc! ? item?.updated : acc),
      artifact.updated ?? artifact.created
    ) ??
    artifact.updated ??
    artifact.created;

  const artifacts = useMemo(() => {
    const updated = _.map(paginatedArtifactsData?.data, (artifact) => ({
      ...artifact,
      updated: getUpdateValue(artifact)
    }));
    return _.filter(updated, ({ name }) => {
      return _.includes(_.toLower(name), _.toLower(searchValue));
    });
  }, [paginatedArtifactsData?.data, searchValue]);

  const models = useMemo(() => {
    return _.filter(
      paginatedModelsData?.data,
      ({ name, predictionServiceDetails }) =>
        _.includes(_.toLower(name), _.toLower(searchValue)) ||
        _.includes(_.toLower(predictionServiceDetails?.displayName), _.toLower(searchValue))
    );
  }, [paginatedModelsData?.data, searchValue]);

  const handleTabChange = (newValue: number) => {
    if (value === newValue) {
      return;
    }
    setValue(newValue);

    const tab = newValue === 1 ? TABS.artifacts.key : TABS.models.key;
    setSearchValue("");
    if (!!projectId) {
      navigate(
        generatePath(WebPaths.ProjectArtifactsAndModels, { entityName: tab, projectId: projectId })
      );
    } else {
      navigate(generatePath(WebPaths.ArtifactsAndModels, { entityName: tab }), { replace: true });
    }
  };

  const handleSubmit = () => {
    selectedTab === TABS.artifacts.key ? handleArtifactDelete() : handleModelDelete();
  };

  const handleArtifactDelete = async () => {
    deleteArtifact.mutate(
      {
        name: targetName
      },
      {
        onSuccess: () => {
          queryClient.setQueryData([QUERY_KEY_ARTIFACTS_PAGINATED, projectId], (prev: any) => {
            return {
              ...prev,
              data: _.filter(prev?.data, ({ name }) => name !== targetName),
              total: prev?.total - 1
            };
          });

          handleResponse({
            successMessage: `Artifact - ${targetName} deleted successfully`
          });
          setTargetName("");
        }
      }
    );
  };

  const handleModelDelete = async () => {
    deleteModel.mutate(
      {
        name: targetName
      },
      {
        onSuccess: () => {
          queryClient.setQueryData([QUERY_KEY_PAGINATED_MODELS, projectId], (prev: any) => {
            return {
              ...prev,
              data: _.filter(prev?.data, ({ name }) => name !== targetName),
              total: prev?.total - 1
            };
          });
          handleResponse({ successMessage: `Model - ${targetName} deleted successfully` });
          setTargetName("");
        }
      }
    );
  };

  const createEntities = React.useCallback(
    async (entityNames: Array<string>, entityViewType: string) => {
      const entityBody = {
        entityMeta: {
          entityViewType,
          entityType: "EVENT"
        },
        projectId
      };
      try {
        const response = await Promise.all(
          entityNames.map(async (artifactName: string) => {
            return await createEntityWithRethrow({
              ...entityBody,
              name: artifactName
            });
          })
        );

        queryClient.setQueryData([QUERY_KEY_ARTIFACTS_PAGINATED, projectId], (old: any) => {
          return {
            ...old,
            data: [
              ...map(response, (item) => ({
                name: _.get(item, "name"),
                producer: null,
                fileObjects: null,
                created: Date.now() / 1000,
                updated: Date.now() / 1000
              })),

              ...(old?.data ?? [])
            ],
            total: old?.total + 1
          };
        });

        setReloadTrigger();
        queryClient.invalidateQueries([QUERY_KEY_ARTIFACTS_PAGINATED, projectId]);
      } catch (error: $TSFixMe) {
        handleResponse({
          errorMessage:
            error.response?.data?.msg ||
            error.message ||
            `Error in adding ${entityViewType === "ARTIFACT" ? "artifacts" : "models"}`
        });
      }
    },
    [projectId, setReloadTrigger]
  );

  const onAddArtifacts = React.useCallback(
    async (artifactNames: Array<string>) => {
      const existingArtifactNames = artifactNodes?.map((artifact: $TSFixMe) => artifact.name);
      const newArtifacts = artifactNames.filter((name) => !existingArtifactNames.includes(name));
      await createEntities(newArtifacts, "ARTIFACT");
      setIsAddArtifactModalOpen(false);
    },
    [artifactNodes, createEntities]
  );

  const onAddModels = React.useCallback(
    async (modelNames: Array<string>) => {
      const existingModelNames = modelNodes?.map((model: $TSFixMe) => model.name);
      const newModels = modelNames.filter((name) => !existingModelNames.includes(name));
      await createEntities(newModels, "MODEL");
      setIsAddModelsModalOpen(false);
    },
    [createEntities, modelNodes]
  );

  const handleOpenCreationModal = () => {
    setShowCreateModal(true);
  };

  const handleClose = () => {
    setShowCreateModal(false);
  };

  const handleCancelClose = () => {
    setTargetName("");
  };

  const handleDeleteProcessV2 = (id: string) => {
    setTargetName(id);
  };

  const handleSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
  };

  return (
    <Grid
      container
      className={classes.wrapperContainer}
      style={{ paddingLeft: !!projectId ? "55px" : "" }}>
      {!!targetName && (
        <Modal
          isSubmitting={deleteArtifact.isLoading || deleteModel.isLoading}
          isSubmitDisabled={deleteArtifact.isLoading || deleteModel.isLoading}
          open={true}
          variant={ModalVariants.Delete}
          title={`Delete ${capitalize(tabName)}`}
          content={[
            `If you confirm, you will delete the ${tabName} completely`,
            `Do you really want to delete this ${tabName}?`,
            <Box fontStyle="italic" key="content">
              Note: Deleting this might impact associated DataApps (if any).
            </Box>
          ]}
          onClose={handleCancelClose}
          onSubmit={handleSubmit}
        />
      )}
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={
          !!projectId
            ? {
                component: <SubTopNavBarBreadcrumbs project={project} />
              }
            : undefined
        }
        subTopNavBarRightSection={{
          component: (
            <>
              <Search
                value={searchValue}
                placeholder={`Search ${selectedTab}`}
                onSearch={handleSearch}
              />
              {selectedTab === TABS.artifacts.key &&
                (!projectId ? (
                  <IconButton color="primary" size="small" onClick={handleOpenCreationModal}>
                    <PlusIcon width={28} height={28} />
                  </IconButton>
                ) : (
                  <>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                      }}>
                      <PlusIcon width={28} height={28} />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                        style: { borderRadius: "20px" }
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}
                      getContentAnchorEl={null}>
                      <MenuItem
                        style={{ fontSize: "14px" }}
                        onClick={() => {
                          handleOpenCreationModal();
                          setAnchorEl(null);
                        }}>
                        Create New artifact
                      </MenuItem>
                      <MenuItem
                        style={{ fontSize: "14px" }}
                        onClick={() => {
                          setIsAddArtifactModalOpen(true);
                          setAnchorEl(null);
                        }}>
                        Add Existing
                      </MenuItem>
                    </Menu>
                  </>
                ))}
              {selectedTab === TABS.models.key && !!projectId && (
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    setIsAddModelsModalOpen(true);
                  }}>
                  <PlusIcon width={28} height={28} />
                </IconButton>
              )}
            </>
          )
        }}
      />

      <Grid item xs={12} className={styles.container}>
        {showCreateModal && (
          <CreateArtifact
            open={true}
            onClose={handleClose}
            artifactList={artifacts}
            projectId={projectId}
          />
        )}
        <AddArtifactsModal
          open={isAddArtifactModalOpen}
          selectedArtifacts={artifactNodes}
          shouldDisableInitialSelectedRows
          onAddArtifacts={onAddArtifacts}
          onClose={() => setIsAddArtifactModalOpen(false)}
        />

        <AddModelsModal
          open={isAddModelsModalOpen}
          onAddModels={onAddModels}
          selectedModels={modelNodes}
          shouldDisableInitialSelectedRows
          onClose={() => setIsAddModelsModalOpen(false)}
        />
        <div className={styles.tabsSection}>
          <div className={styles.tabsContainer}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              variant="scrollable">
              <Tab value={1} label={TABS.artifacts.label} />
              <Tab value={2} label={TABS.models.label} />
            </Tabs>
          </div>
          <div className={styles.tabPanelContainer}>
            <TabPanel value={value} index={1}>
              {isPaginatedArtifactsLoading ? (
                <CommonLoader />
              ) : (
                <>
                  <ArtifactsTable
                    data={artifacts}
                    onDelete={handleDeleteProcessV2}
                    openAddArtifactModal={() => {
                      setIsAddArtifactModalOpen(true);
                    }}
                    openCreationModal={handleOpenCreationModal}>
                    {!_.isEmpty(paginatedArtifactsData?.data) ? (
                      <NoDataFoundDefault
                        title={`No artifact found with keyword "${searchValue}"`}
                        onClear={() => setSearchValue("")}
                      />
                    ) : null}
                  </ArtifactsTable>
                  {/* <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    open={
                      isArtifactsFetching &&
                      !!paginatedArtifactsData?.total &&
                      _.size(paginatedArtifactsData?.data) < paginatedArtifactsData?.total
                    }
                    message={`${_.size(paginatedArtifactsData?.data)} out of ${paginatedArtifactsData?.total} records retrieved. Loading more, please wait`}
                  /> */}
                </>
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {isPaginatedModelsLoading ? (
                <CommonLoader />
              ) : (
                <ModelsTable data={models} onDelete={handleDeleteProcessV2}>
                  {_.isEmpty(models) ? (
                    <NoDataFoundDefault
                      title={
                        _.isEmpty(paginatedModelsData?.data)
                          ? !!projectId
                            ? "This project currently has no associated model(s)"
                            : "No model found"
                          : `No model found with keyword "${searchValue}"`
                      }
                      subTitle={_.isEmpty(paginatedModelsData?.data) ? "" : undefined}
                      onClear={() => setSearchValue("")}
                    />
                  ) : null}
                </ModelsTable>
              )}
            </TabPanel>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ArtifactsAndModels;
