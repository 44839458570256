import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import { PS_DELETE_SUCCESSFUL_MESSAGE } from "../pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.constants";
import { QUERY_KEY_PREDICTION_SERVICE_BY_MODEL } from "./usePredictionServiceByModel";
import { deleteAPIWithRethrow, handleResponse } from "src/utils/apiService";
import { QUERY_KEY_PAGINATED_MODELS } from "./api/models/useGetPaginatedModels";

interface IVariables {
  id: string;
}

const useDeletePredictionService = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }) => {
      const data = await deleteAPIWithRethrow(`/v2/prediction-services/${id}`, { id });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PAGINATED_MODELS],
        refetchType: "all"
      });
      return data;
    },
    onSuccess: async (__) => {
      handleResponse({ successMessage: PS_DELETE_SUCCESSFUL_MESSAGE });
      await queryClient.invalidateQueries([QUERY_KEY_PREDICTION_SERVICE_BY_MODEL]);
    }
  });
};

export default useDeletePredictionService;
