import { UseQueryOptions, useQuery } from "@tanstack/react-query";
// import { useRef } from "react";
import _ from "lodash";

import api from "src/utils/AxiosClient";
import { ModelDto } from "@rapidcanvas/rc-api-core";

export const QUERY_KEY_PAGINATED_MODELS = "query-key-paginated-models";

interface IReturn {
  data: ModelDto[];
  total: number;
}

// const PAGE_SIZE = 50;

const useGetPaginatedModels = (projectId?: string, options?: UseQueryOptions<IReturn>) => {
  // const currentRef = useRef(0);
  // const queryClient = useQueryClient();

  return useQuery<IReturn>({
    queryKey: [QUERY_KEY_PAGINATED_MODELS, projectId],
    queryFn: async () => {
      // const start = currentRef.current * PAGE_SIZE;
      // const end = (currentRef.current + 1) * PAGE_SIZE;
      const response = await api.fetchResponse(
        async () =>
          await api.PredictionServiceControllerApi.getModels1(undefined, undefined, projectId)
      );

      // const total = response.totalElements ?? 0;
      // const oldData = queryClient.getQueryData([QUERY_KEY_PAGINATED_MODELS]) as IReturn;

      // const newData = {
      //   data: _.uniqBy([...(oldData?.data ?? []), ...(response.content ?? [])], "name"),
      //   start,
      //   end,
      //   total,
      //   current: currentRef.current
      // };

      // if (total > end) {
      //   currentRef.current += 1;
      //   queryClient.setQueryData([QUERY_KEY_PAGINATED_MODELS], newData);
      //   queryClient.invalidateQueries([QUERY_KEY_PAGINATED_MODELS]);
      // }

      return { data: response.content ?? [], total: response.totalElements ?? 0 };
    },
    refetchOnMount: true,
    ...options
  });
};

export default useGetPaginatedModels;
