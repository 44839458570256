import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import api from "utils/AxiosClient";
import { AIChatRequestDto, AIChatResponseDto } from "@rapidcanvas/rc-api-core";
import { AI_GUIDE_MESSAGES_KEY } from "./useGetAIGuideMessages";
import { useAIGuideStore } from "src/store/store";

type IProps = { request: AIChatRequestDto; messageId: string; controller?: any };

export const useRetryAIGuideChatMutation = (threadId: string) => {
  const [setGeneratingState, setIsGenerated] = useAIGuideStore((state) => [
    state.setGeneratingState,
    state.setIsGenerated
  ]);
  const queryClient = useQueryClient();
  return useMutation<any, unknown, IProps, AIChatRequestDto>({
    mutationFn: async ({ messageId, request, controller }: IProps) => {
      const { query } = request;
      const currTime = Date.now();
      const currTimestamp = new Date(currTime).toISOString();
      setGeneratingState(threadId, query!, currTimestamp, false, true);
      const response = await api.fetchResponse(() =>
        api.AiControllerApi.chat1(
          threadId,
          request,
          controller ? { signal: controller.signal } : {}
        )
      );
      if (controller?.signal?.aborted) {
        return null;
      }
      await api.fetchResponse(() => api.AiControllerApi.deleteMessage2(threadId, messageId));

      queryClient.setQueryData(
        [AI_GUIDE_MESSAGES_KEY, threadId],
        (prevData: AIChatResponseDto[] | undefined) => {
          return _.map(prevData, (prevResponse) =>
            prevResponse.askAIMessageId === messageId ? response : prevResponse
          );
        }
      );
      return response;
    },
    onSuccess: (__, variables) => {
      const { controller } = variables;
      if (controller?.signal?.aborted) {
        return;
      }
      setIsGenerated(threadId);
    },
    onError: (__, variables) => {
      const { controller } = variables;
      if (controller?.signal?.aborted) {
        return;
      }
      setIsGenerated(threadId);
    }
  });
};
