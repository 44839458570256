import React, { useMemo } from "react";
import _, { compact, isEmpty, join, map } from "lodash";
import { Link } from "react-router-dom";
import { UseTableRowProps } from "react-table";
import { Button, Grid, makeStyles, Tooltip } from "@material-ui/core";

import Text from "src/components/Widget/Text";
import { Delete } from "../../../../icons/Delete";
import { LinkCellWithMenu } from "../../../../components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { SplashIcon } from "src/icons/NewUX";
import { SplashSection, Table } from "../../../../components";
import { dateFormat } from "src/utils/dateFormat";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { generatePath, useParams } from "react-router";
import { WebPaths } from "src/routing/routes";
import { RecipeTypes } from "src/pages/private/ProjectsModule/utils";
import { ArtifactProducer } from "@rapidcanvas/rc-api-core";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "calc(100vh - 64px - 254px)",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey[700]
  },
  gridIcon: {
    height: 215,
    width: 215,
    marginRight: 140
  }
}));

const getRecipeLink = (value: any) => {
  switch (value.recipeType) {
    case RecipeTypes.AiAssisted:
      return generatePath(WebPaths.CodeRecipeContainer, {
        projectId: value.projectId,
        groupId: value.recipeId,
        scenarioId: value.scenarioId
      });
    case RecipeTypes.ApiConnector:
      return generatePath(WebPaths.APIConnectorRecipeContainer + "/:groupId", {
        projectId: value.projectId,
        groupId: value.recipeId,
        scenarioId: value.scenarioId
      });
    case RecipeTypes.RapidModel:
      return generatePath(WebPaths.AutoMLRecipeContainer, {
        projectId: value.projectId,
        groupId: value.recipeId,
        scenarioId: value.scenarioId
      });
    case RecipeTypes.Template:
      return generatePath(WebPaths.StandardRecipeDataContainer, {
        projectId: value.projectId,
        groupId: value.recipeId,
        scenarioId: value.scenarioId
      });
  }
};

export const getSourceVal = (value: ArtifactProducer, isProjectPath: boolean = false) => {
  if (!value) {
    return "Manual addition";
  }

  const recipeLink = value.recipeName ? (
    <Link
      style={{ color: " #003656", cursor: "pointer", textDecoration: "underline" }}
      to={getRecipeLink(value) ?? ""}>
      {value.recipeName}
    </Link>
  ) : null;

  const projectLink = value.projectName ? (
    <>
      {"("}
      <Link
        style={{ color: " #003656", cursor: "pointer", textDecoration: "underline" }}
        to={generatePath("/projects/:projectId", { projectId: value.projectId ?? "" })}>
        {value.projectName}
      </Link>
      {")"}
    </>
  ) : null;

  return (
    <>
      {recipeLink}
      {!isProjectPath && projectLink}
    </>
  );
};
export const getDestinationValList = (
  values: ArtifactProducer[],
  isProjectPath: boolean = false
): JSX.Element[] | string => {
  if (isEmpty(values)) {
    return "";
  }

  return values.map((value, index) => {
    const recipeLink = value.recipeName ? (
      <Link
        key={`recipe-${index}`}
        style={{ color: "#003656", cursor: "pointer", textDecoration: "underline" }}
        to={getRecipeLink(value) ?? ""}>
        {value.recipeName}
      </Link>
    ) : null;

    const projectLink = value.projectName ? (
      <>
        {"("}
        <Link
          key={`project-${index}`}
          style={{ color: "#003656", cursor: "pointer", textDecoration: "underline" }}
          to={generatePath("/projects/:projectId", { projectId: value.projectId ?? "" })}>
          {value.projectName}
        </Link>
        {")"}
      </>
    ) : null;

    return (
      <span key={`artifact-${index}`}>
        {recipeLink}
        {!isProjectPath && projectLink}
        {index < values.length - 1 && ", "}
      </span>
    );
  });
};

export const getSourceString = (value: any, isProjectPath: boolean = false) => {
  return value
    ? `${value?.recipeName} ${value?.projectName && !isProjectPath ? `(${value?.projectName})` : ""}`
    : "Manual addition";
};
export const getDestinationsString = (value: any[], isProjectPath: boolean = false) => {
  return isEmpty(value)
    ? ""
    : join(
        map(
          value,
          (item) =>
            `${item?.recipeName} ${item?.projectName && !isProjectPath ? `(${item?.projectName})` : ""}`
        ),
        ", "
      );
};

const ArtifactsTable = ({
  data,
  onDelete,
  openCreationModal,
  openAddArtifactModal,
  children
}: $TSFixMe) => {
  const { gridContainer, gridIcon } = useStyles();
  const { projectId } = useParams();

  const sourceSortType = useMemo(
    () => (rowA: UseTableRowProps<any>, rowB: UseTableRowProps<any>) => {
      const valA = getSourceString(_.get(rowA.original, "producer"));
      const valB = getSourceString(_.get(rowB.original, "producer"));

      if (_.lowerCase(valA) > _.lowerCase(valB)) {
        return 1;
      }
      if (_.lowerCase(valA) < _.lowerCase(valB)) {
        return -1;
      }

      return 0;
    },
    []
  );

  const destinationSortType = useMemo(
    () => (rowA: UseTableRowProps<any>, rowB: UseTableRowProps<any>) => {
      const valA = getDestinationsString(_.get(rowA.original, "consumers"));
      const valB = getDestinationsString(_.get(rowB.original, "consumers"));

      if (_.lowerCase(valA) > _.lowerCase(valB)) {
        return 1;
      }
      if (_.lowerCase(valA) < _.lowerCase(valB)) {
        return -1;
      }

      return 0;
    },
    []
  );

  const updatedSortType = useMemo(
    () => (rowA: UseTableRowProps<any>, rowB: UseTableRowProps<any>) => {
      const valA = _.get(rowA.original, "updated");
      const valB = _.get(rowB.original, "updated");

      if (_.lowerCase(valA) > _.lowerCase(valB)) {
        return 1;
      }
      if (_.lowerCase(valA) < _.lowerCase(valB)) {
        return -1;
      }

      return 0;
    },
    []
  );

  const columns = [
    {
      id: "Name",
      accessor: "name",
      Header: "Name",
      isSortable: true,
      isTooltip: false,
      Cell: ({ row }: $TSFixMe) =>
        !row?.original?.producer && !projectId ? (
          <LinkCellWithMenu
            url={
              !!projectId
                ? generatePath(WebPaths.ProjectArtifactsDetails, {
                    projectId,
                    artifactName: row.original.name
                  })
                : `/artifacts-and-models/artifact/${row.original.name}`
            }
            linkText={<Text value={row.original.name} />}
            menuButtons={[
              {
                label: "DELETE",
                icon: <Delete />,
                action: () => onDelete(row?.original?.name)
              }
            ]}
          />
        ) : (
          <Link
            className="name-link"
            to={
              !!projectId
                ? generatePath(WebPaths.ProjectArtifactsDetails, {
                    projectId,
                    artifactName: row.original.name
                  })
                : `/artifacts-and-models/artifact/${row.original.name}`
            }>
            {row.original.name}
          </Link>
        )
    },
    {
      id: "Updated on",
      accessor: "updated",
      Header: "Updated on",
      isSortable: true,
      sortType: updatedSortType,
      Cell: ({ value }: $TSFixMe) => <span>{dateFormat(value, false)}</span>
    },
    {
      id: "Source",
      accessor: "producer",
      Header: (
        <span style={{ fontSize: "14px", gap: "5px", display: "flex", alignItems: "center" }}>
          Source
          <Tooltip arrow title="Recipe where artifact is the output">
            <InfoOutlined color="disabled" fontSize="small" />
          </Tooltip>
        </span>
      ),
      isSortable: true,
      sortType: sourceSortType,
      Cell: ({ value }: $TSFixMe) => getSourceVal(value)
    },
    {
      id: "Destinations",
      accessor: "consumers",
      Header: (
        <span style={{ fontSize: "14px", gap: "5px", display: "flex", alignItems: "center" }}>
          Destinations
          <Tooltip arrow title="Recipes where artifact is the input.">
            <InfoOutlined color="disabled" fontSize="small" />
          </Tooltip>
        </span>
      ),
      isSortable: true,
      sortType: destinationSortType,
      Cell: ({ value }: $TSFixMe) => getDestinationValList(value)
    }
  ];

  return (
    <>
      {data.length ? (
        <Table
          data={data}
          size="small"
          columns={columns}
          isCellSortEnabled
          orderByDefault="Updated on"
          sortInverted
          skipPageReset
          maxHeight="calc(100vh - 248px)"
          isTheadSticky
        />
      ) : (
        children ?? (
          <NewThemeWrapper>
            <SplashSection
              gridContainerStyle={gridContainer}
              gridIconStyle={gridIcon}
              button={
                !projectId
                  ? {
                      color: "primary",
                      onClick: openCreationModal,
                      label: "+ Create Artifact"
                    }
                  : undefined
              }
              buttonComponent={
                !!projectId ? (
                  <Grid container alignItems="center" style={{ gap: "5px" }}>
                    <Button variant="contained" color="primary" onClick={openCreationModal}>
                      + Create New Artifact
                    </Button>
                    <Button variant="contained" color="primary" onClick={openAddArtifactModal}>
                      Add Existing
                    </Button>
                  </Grid>
                ) : undefined
              }
              titleSection={{
                title: !!projectId
                  ? "This project currently has no associated artifact(s)."
                  : "Get Started with a New Artifact",
                subtitleLines: compact([
                  !projectId ? "Welcome to the artifacts page." : "",
                  !projectId
                    ? "Create a new one by clicking on the button below."
                    : "Create a new one or Add Existing by clicking on the buttons below."
                ]),
                size: 22
              }}
              icon={<SplashIcon />}
            />
          </NewThemeWrapper>
        )
      )}
    </>
  );
};

export default ArtifactsTable;
