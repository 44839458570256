import React, { useCallback, useEffect, useMemo, useState } from "react";

// Packages
import { useLocation, useParams } from "react-router-dom";
import shallow from "zustand/shallow";
import {
  cloneDeep,
  filter,
  find,
  get,
  includes,
  isEmpty,
  isNil,
  map,
  mapValues,
  nth,
  size,
  toLower
} from "lodash";

// Utils
import {
  projectRegExpLiteralNotations,
  isRecipesRunning as isRecipesRunningHelper
} from "src/pages/private/ProjectsModule/utils";
import { Edge, Node, ScenarioDto, RecipeRunData } from "@rapidcanvas/rc-api-core";

// Helpers
import {
  edgeConfig,
  resetNodesSelectionStore
} from "src/pages/private/ProjectsModule/utils/Dag.helpers";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";

// Hooks
import { useGetProjectCanvas } from "src/hooks/api";

// Stores
import { useCanvasStore, useProjectsStore, useScenariosStore } from "src/store/store";
import { projectsGetter } from "src/store/store.selectors";

// Contexts
import { usePrivateRouteContext } from "src/routing/PrivateRoute/context/usePrivateRouteContext";
import { DagFlowContext, NodeActions } from "./DagFlowContext";

// Types
import { NodeData } from "src/types";
import { JobProps } from "../../../Dag.types";

type DagFlowContextProviderProps = {
  jobProps?: JobProps;
  children: React.ReactNode;
};

const DagFlowContextProvider = (props: DagFlowContextProviderProps) => {
  const { jobProps, children } = props || {};

  const { projectId, scenarioId } = useParams() || {};

  const location = useLocation();

  const { historyStack } = usePrivateRouteContext();

  const isFromDatasetPage = useMemo(
    () => nth(historyStack?.current || [], -2)?.includes("/dataset") || false,
    [historyStack?.current]
  );

  const isJobPath = useMemo(
    () => projectRegExpLiteralNotations.jobs.test(location.pathname),
    [location.pathname]
  );

  const isJobCanvasComparePath = useMemo(
    () => projectRegExpLiteralNotations.jobCanvasCompare.test(location.pathname),
    [location.pathname]
  );

  const isJobCanvasPath = useMemo(
    () => projectRegExpLiteralNotations.jobCanvas.test(location.pathname),
    [location.pathname]
  );

  // Stores - STARTS >>
  const projectsStore = useProjectsStore(projectsGetter);
  const scenariosStore = useScenariosStore((state) => state.scenarios);

  const [
    nodesStore,
    setNodesStore,
    edgesStore,
    setEdgesStore,
    setNodeToFocusStore,
    reloadTriggerStore,
    setReloadTriggerStore,
    isRunRequestPendingStore,
    pendingRecipeRunsInQueueStore
  ] = useCanvasStore(
    (state) => [
      state.nodes,
      state.setNodes,
      state.edges,
      state.setEdges,
      state.setNodeToFocus,
      state.reloadTrigger,
      state.setReloadTrigger,
      state.isRunRequestPending,
      state.pendingRecipeRunsInQueue
    ],
    shallow
  );
  // << ENDS - Stores

  // States - STARTS >>
  const [initialNodes, setInitialNodes] = useState<Node[]>([]);
  const [nodesExpanded, setNodesExpanded] = useState<{ [key: string]: boolean }>({});
  const [nodeActions, setNodeActions] = useState<NodeActions>({
    autoLayout: false,
    nodeDrag: false
  });

  const [contextMenuNodeId, setContextMenuNodeId] = useState<string | null>(null);
  const [resetNodesAndEdgesTimer, setResetNodesAndEdgesTimer] = useState<number | null>(null);
  // << ENDS - States

  const project = useMemo(
    () => find(projectsStore, (eachProject) => eachProject?.id === projectId),
    [projectsStore, projectId]
  );

  const scenario = useMemo(
    () => find(scenariosStore, (eachScenario: ScenarioDto) => eachScenario?.id === scenarioId),
    [scenariosStore, scenarioId]
  );

  const [scenarios] = useScenariosStore((state) => [state.scenarios]);
  const isDefaultScenario = useMemo(
    () => (!isEmpty(scenario) ? checkIfDefaultScenario(scenario, scenarios, scenarioId) : true),
    [scenarioId, scenarios]
  );

  // Edge type - STARTS >>
  const edgeTypeInStorage =
    (!!projectId &&
      JSON.parse(localStorage.getItem("projectConfig") || "{}")?.[projectId]?.edgeType) ||
    localStorage.getItem("edgeType") ||
    "smoothstep";

  const [edgeType, setEdgeType] = useState(edgeTypeInStorage);

  const changeEdgeType = () => {
    const newEdgeType = edgeType === "smoothstep" ? "default" : "smoothstep";

    setEdgeType(newEdgeType);

    localStorage.setItem("edgeType", newEdgeType);

    if (projectId) {
      const currentConfigs = JSON.parse(localStorage.getItem("projectConfig") || "{}");
      const updatedConfigs = { ...currentConfigs, [projectId]: { edgeType: newEdgeType } };
      localStorage.setItem("projectConfig", JSON.stringify(updatedConfigs));
    }
  };
  // << ENDS - Edge type

  const isRecipesRunning = useMemo(() => isRecipesRunningHelper(nodesStore), [nodesStore]);

  const isRecipesPendingInQueue = useMemo(() => {
    if (size(pendingRecipeRunsInQueueStore) === 0) {
      return false;
    }

    return (
      size(
        filter(
          pendingRecipeRunsInQueueStore,
          (pendingRecipe: RecipeRunData) => pendingRecipe?.scenarioId === scenarioId
        )
      ) > 0
    );
  }, [pendingRecipeRunsInQueueStore, scenarioId]);

  // Filtering nodes out for recipe-types with status running.
  const isManualRunTriggered = useMemo(
    () => isRunRequestPendingStore && !isRecipesRunning,
    [isRunRequestPendingStore, isRecipesRunning]
  );

  const isPollDag = useMemo(
    () => !!isRecipesRunning || !!isRecipesPendingInQueue || !!isManualRunTriggered,
    [isRecipesRunning, isRecipesPendingInQueue, isManualRunTriggered]
  );

  // Query hooks - STARTS >>
  const {
    isLoading: isFetchingProjectCanvas,
    isSuccess: isProjectCanvasFetched,
    data: projectCanvasData,
    refetch: refetchProjectCanvas
  } = useGetProjectCanvas({
    projectId,
    jobProps,
    scenarioId: scenarioId!,

    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely

    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchInterval: isJobCanvasComparePath ? false : isPollDag ? 500 : false,
    // @REFACTOR
    // Disabled for the right-canvas of Scheduler Compare Canvas screen.
    enabled: !isJobCanvasComparePath
  });
  // << ENDS - Query hooks

  useEffect(() => {
    if (!isJobCanvasComparePath) {
      if (isProjectCanvasFetched) {
        setNodesStore(projectCanvasData?.nodes || []);
        setEdgesStore([
          ...(projectCanvasData?.edges || []),
          ...(projectCanvasData?.groupEdges || [])
        ]);
      }
    }
  }, [isJobCanvasComparePath, isProjectCanvasFetched, projectCanvasData]);

  useEffect(() => {
    if (!isJobCanvasComparePath) {
      if (!!reloadTriggerStore) {
        refetchProjectCanvas();
      }
    }
  }, [isJobCanvasComparePath, reloadTriggerStore]);

  const edgeTargets = useMemo(
    () => map(edgesStore || [], (eachEdge: Edge) => eachEdge?.target) || [],
    [edgesStore]
  );

  // @REFACTOR
  // Relying upon canvas-data of props when rendering for the right-canvas of Scheduler Compare Canvas screen.
  // And not depending upon the canvas-data in common-store.
  // Conditional nodes & edges - STARTS >>
  const getNodesForMapping = () =>
    isJobCanvasComparePath ? jobProps?.canvasData?.nodes || [] : nodesStore || [];

  const getEdgesForMapping = () =>
    isJobCanvasComparePath ? jobProps?.canvasData?.edges || [] : edgesStore || [];
  // << ENDS - Conditional nodes & edges

  const initialEdges = useMemo(() => {
    return isEmpty(getEdgesForMapping())
      ? []
      : map(getEdgesForMapping(), (edge: Edge) => ({
          ...edge,
          type: edgeType || edgeConfig.type,
          markerEnd: { ...edgeConfig.markerEnd },
          style: { ...edgeConfig.style }
        }));
  }, [edgesStore, isJobCanvasComparePath, jobProps?.canvasData, edgeType]);

  const isOutputDataset = (item: Node) => {
    const entityDSDetails: any = get(item, "entityDSDetails") || {};
    if (entityDSDetails?.id && entityDSDetails?.purpose) {
      return entityDSDetails?.purpose === "EXPORT";
    }
    return includes(edgeTargets, item.id);
  };

  const { sourceNodes, targetNodes } = useMemo(() => {
    return isEmpty(getEdgesForMapping())
      ? { sourceNodes: [], targetNodes: [] }
      : {
          sourceNodes: map(getEdgesForMapping(), (edge) => edge?.source),
          targetNodes: map(getEdgesForMapping(), (edge) => edge?.target)
        };
  }, [edgesStore, isJobCanvasComparePath, jobProps?.canvasData]);

  useEffect(() => {
    const thisNodesExpanded: { [key: string]: boolean } = {};

    const thisInitialNodes: any = isEmpty(getNodesForMapping())
      ? []
      : map(getNodesForMapping(), (node: Node | any) => {
          const isSourceNode = includes(sourceNodes, node?.id);

          const type = `${node?.type?.toLowerCase()}`;
          const data: NodeData = {
            projectId: projectId as string,
            scenarioId: scenarioId || jobProps?.scenarioId || "",

            isRootNode: !(edgeTargets || [])?.includes(node?.id),
            isSourceNode,
            isTargetNode: includes(targetNodes, node?.id),
            isOutputDataset: isOutputDataset(node),

            id: node?.id,
            type,
            // @TODO: itemId is being used in old components. Hence retaining. It'll be removed soon.
            itemId: node?.id,
            name: node?.name,
            displayName: node?.displayName || node?.name,
            label: node?.displayName || node?.name,
            image: node?.image,
            status: node?.status,
            recipeType: node?.recipeType,

            // Is this required?
            // shouldDisableBlockInteraction: false,

            column: node?.column,
            row: node?.row,

            viewData: node?.viewData,
            entityDSDetails: node?.entityDSDetails,

            // Jobs
            jobProps,
            isJobCanvas: !!isJobPath,
            isJobCanvasPath
          };

          if (!!isSourceNode) {
            const collapsed = !!isJobPath ? false : node?.groupName === "collapsed";
            data.collapsed = collapsed;

            thisNodesExpanded[node?.id] = !collapsed;
          }

          return {
            id: node?.id,
            type,
            parentNode: node?.parentNode,
            extent: "parent",
            draggable: node?.draggable,
            // position: { x: 0, y: 0 },
            position: node?.position || {
              x: node?.column * 180,
              y: node?.row * 180
            },
            originalStyles: node?.originalStyles,
            hidden: Boolean(node?.isHidden),

            data
          };
        });

    setNodesExpanded(() => ({ ...thisNodesExpanded }));

    setInitialNodes(() => thisInitialNodes);
  }, [
    isJobPath,
    isJobCanvasComparePath,
    jobProps?.canvasData,
    isJobCanvasPath,
    nodesStore,
    edgesStore,
    edgeTargets,
    sourceNodes,
    targetNodes
  ]);

  useEffect(() => resetNodesSelectionStore(), []);

  const isNodeHighlighted = (name: string, nodeToFocus: string) =>
    !isNil(nodeToFocus) && !isEmpty(nodeToFocus) && includes(toLower(name), toLower(nodeToFocus));

  const updateNodesExpanded = useCallback(
    (nodeId?: string) => {
      if (!nodeId) {
        return;
      }

      let updatedNodesExpanded = cloneDeep(nodesExpanded);
      updatedNodesExpanded = mapValues(updatedNodesExpanded, (value, key) => {
        if (key === nodeId) {
          return !value;
        }
        return value;
      });

      setNodesExpanded(updatedNodesExpanded);
    },
    [nodesExpanded]
  );

  const value = useMemo(
    () => ({
      isFromDatasetPage,

      project,
      scenario,

      nodesExpanded,
      setNodesExpanded,

      updateNodesExpanded,

      nodeActions,
      setNodeActions,

      isJobPath,
      isJobCanvasComparePath,
      isDefaultScenario,

      isFetchingProjectCanvas,

      nodesStore,
      setNodesStore,
      edgesStore,
      setEdgesStore,
      setNodeToFocusStore,
      setReloadTriggerStore,

      initialNodes,
      setInitialNodes,

      initialEdges,

      edgeType,
      changeEdgeType,

      isRecipesRunning,
      isNodeHighlighted,

      contextMenuNodeId,
      setContextMenuNodeId,

      resetNodesAndEdgesTimer,
      setResetNodesAndEdgesTimer
    }),
    [
      isFromDatasetPage,

      project,
      scenario,

      nodesExpanded,
      setNodesExpanded,

      updateNodesExpanded,

      nodeActions,
      setNodeActions,

      isJobPath,
      isJobCanvasComparePath,
      isDefaultScenario,

      isFetchingProjectCanvas,

      nodesStore,
      setNodesStore,
      edgesStore,
      setEdgesStore,
      setNodeToFocusStore,
      setReloadTriggerStore,

      initialNodes,
      setInitialNodes,

      initialEdges,

      edgeType,
      changeEdgeType,

      isRecipesRunning,
      isNodeHighlighted,

      contextMenuNodeId,
      setContextMenuNodeId,

      resetNodesAndEdgesTimer,
      setResetNodesAndEdgesTimer
    ]
  );

  return <DagFlowContext.Provider value={value}>{children}</DagFlowContext.Provider>;
};

export default DagFlowContextProvider;
