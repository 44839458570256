import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";

interface IPayload {
  id?: string;
}

const useDeleteEnvironment = (): UseMutationResult<unknown, unknown, IPayload, unknown> =>
  useMutation({
    mutationFn: async ({ id }: IPayload) => {
      if (!id) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(async () => await api.EnvControllerApi.deleteEnvs(id));
    }
  });

export default useDeleteEnvironment;
