import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip
} from "@material-ui/core";
import React from "react";
import { InfoOutlined } from "@material-ui/icons";

import { dataAppConfigFields } from "./CreateDataAppForm";

interface IProps {
  readOnly?: boolean;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DataAppAccess: React.FC<IProps> = (props) => {
  const { value, readOnly, onChange } = props;

  return (
    <FormControl component="fieldset" disabled={readOnly}>
      <FormLabel component="legend">Access Control</FormLabel>
      <RadioGroup
        row={readOnly}
        aria-label={dataAppConfigFields.isPrivate}
        name={dataAppConfigFields.isPrivate}
        value={String(value)}
        data-testid="dataAppaskAIAccessControl"
        onChange={onChange}>
        <FormControlLabel
          value="false"
          data-testid="dataAppaskAIAccessControlItem"
          control={<Radio />}
          label={
            <>
              <span>Team Access</span>{" "}
              <Tooltip title="Chats and Charts within this DataApp will be accessible to all users of this tenant">
                <InfoOutlined
                  data-testid="dataAppaskAIAccessControlTeamInfo"
                  style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }}
                />
              </Tooltip>
            </>
          }
        />
        <FormControlLabel
          value="true"
          data-testid="dataAppaskAIAccessControlItem"
          control={<Radio />}
          label={
            <>
              <span>Individual Access</span>{" "}
              <Tooltip title="Chats and Charts within this DataApp will be visible only to the user who created them">
                <InfoOutlined
                  data-testid="dataAppaskAIAccessControlIndividualInfo"
                  style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }}
                />
              </Tooltip>
            </>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

export default DataAppAccess;
