import React from "react";

// Packages
import { useQueryClient } from "@tanstack/react-query";
import { includes, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";
import { updateEnvironmentsQueryData } from "src/hooks/api/environments/useGetEnvironments";

// Hooks
import { useDeleteEnvironment } from "src/hooks/api";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import {
  EnvironmentDeletePromptDetails,
  EnvironmentsHelperText
} from "../../Environments/utils/Environments.constants";

// Constants
import {
  EnvironmentActionsStatusEnum,
  resetEnvironmentStatus,
  setEnvironmentStatus
} from "src/stores/environmentsSlice";
import { environmentActionStatusSelector } from "../../../Environment.selectors";

interface IProps {
  environment: EnvDto;
  onDelete?: () => void;
  resetDeleteEnvironment: () => void;
}

const DeleteEnvironment: React.FC<IProps> = (props) => {
  const { environment, onDelete, resetDeleteEnvironment } = props;

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const envId = environment?.id!;

  const { mutateAsync: deleteEnvironmentMutation, reset: resetDeleteEnvironmentMutation } =
    useDeleteEnvironment();

  const hasProjects = () => {
    if (!isEmpty(environment?.projects)) {
      toastWrapper({
        type: ToastTypes.Error,
        content: `Cannot delete this environment because it is currently used in other project(s) [${environment?.projects?.join(
          ", "
        )}]`
      });

      resetDeleteEnvironment();

      return true;
    }

    return false;
  };

  const environmentStatus = useSelector(environmentActionStatusSelector(envId));
  const isDeleting = environmentStatus === EnvironmentActionsStatusEnum.Deleting;

  const deleteEnvironment = async () => {
    if (hasProjects()) return;

    dispatch(setEnvironmentStatus({ id: envId, status: EnvironmentActionsStatusEnum.Deleting }));

    resetDeleteEnvironmentMutation();
    await deleteEnvironmentMutation(
      { id: environment?.id },
      {
        onSuccess: async () => {
          updateEnvironmentsQueryData(queryClient, { id: envId }, "delete");

          toastWrapper({
            type: ToastTypes.Success,
            content: EnvironmentsHelperText.EnvironmentDeleted
          });

          onDelete?.();
        },
        onSettled: () => {
          resetDeleteEnvironment();
          dispatch(resetEnvironmentStatus({ id: envId }));
        }
      }
    );
  };

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={EnvironmentDeletePromptDetails.title}
      content={[
        EnvironmentDeletePromptDetails.messageLine1,
        EnvironmentDeletePromptDetails.messageLine2
      ]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetDeleteEnvironment();
        }
      }}
      onSubmit={deleteEnvironment}
      cancelLabel={EnvironmentDeletePromptDetails.cancelLabel}
      isCancelDisabled={isDeleting}
      isSubmitDisabled={isDeleting}
      isSubmitting={isDeleting}
      hideCloseIcon
    />
  );
};

export default DeleteEnvironment;
