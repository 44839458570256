import React, { useMemo } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

import DataAppDashboard from "./DataAppDashboard";
import NotFoundIcon from "src/icons/NotFoundIcon";
import Result from "components/Errors/Result";
import useAuthStore from "src/stores/auth.store";
import useGetDataAppAcrossTenants from "hooks/api/dataapps/useGetDataAppAcrossTenants";
import CommonLoader from "src/components/CommonLoader";

const DataApp: React.FC = () => {
  const { dataAppName } = useParams();
  const { isLoading, data, isError } = useGetDataAppAcrossTenants({
    dataAppName
  });

  const [tenantId, tenantName] = useAuthStore((state) => [state.tenantId, state.tenantName]);

  const dataApp = useMemo(() => {
    return data?.find((dataApp) => dataApp.tenantId === tenantId);
  }, [data, tenantId]);

  if (isLoading) {
    return <CommonLoader size={40} />;
  }

  if (!dataApp || _.isEmpty(dataApp) || isError) {
    return (
      <Result
        icon={<NotFoundIcon />}
        heading="Oops, This DataApp does not exist "
        subtitle1="It seems like the DataApp you're looking for isn't available. It might have been renamed or deleted."
      />
    );
  }

  const dataAppDeepLink =
    data && dataApp?.accessUrl && dataApp?.name
      ? `${window.location.origin}/apps/${encodeURIComponent(dataApp.name)}${
          data?.length > 1 ? `/${encodeURIComponent(tenantName || "")}` : ""
        }`
      : null;

  return <DataAppDashboard dataApp={dataApp} dataAppDeepLink={dataAppDeepLink} />;
};

export default DataApp;
