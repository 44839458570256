import { useCallback } from "react";

// Packages
import { QueryClient, useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import shallow from "zustand/shallow";
import { isArray } from "lodash";

// Open API
import api from "utils/AxiosClient";
import { ScenarioDto } from "@rapidcanvas/rc-api-core";

// Stores
import { useScenariosStore } from "src/store/store";

export const enum UseGetScenariosQueryKeys {
  Scenarios = "scenarios"
}

interface IPrefetchingScenariosProps {
  queryClient: QueryClient;
  projectId?: string;
  jobId?: string;
}

// @ts-ignore
const prefetchScenarios = (props: IPrefetchingScenariosProps) => {
  const { queryClient, projectId, jobId } = props;

  queryClient.prefetchQuery({
    queryKey: [UseGetScenariosQueryKeys.Scenarios, projectId],
    queryFn: async () => {
      if (!projectId) {
        throw "projectId is null!";
      }

      return await api.fetchResponse(
        async () => await api.ScenarioControllerApi.findScenarios(projectId, "", jobId)
      );
    }
  });
};

interface IScenariosProps extends UseQueryOptions<unknown, unknown, ScenarioDto[]> {
  projectId?: string;
  jobId?: string;
}

const useGetScenarios = (props: IScenariosProps): UseQueryResult<ScenarioDto[]> => {
  const { projectId, jobId, ...options } = props;

  const [setScenariosStore] = useScenariosStore(
    useCallback((state) => [state.setScenarios], []),
    shallow
  );

  return useQuery({
    queryKey: [UseGetScenariosQueryKeys.Scenarios, projectId],
    queryFn: async () => {
      if (!projectId) {
        throw "projectId is null!";
      }

      return await api.fetchResponse(
        async () => await api.ScenarioControllerApi.findScenarios(projectId, "", jobId)
      );
    },
    onSuccess: (data) => setScenariosStore(data),
    select: (data) => (isArray(data) ? data : []),
    enabled: !!projectId,
    ...options
  });
};

export default useGetScenarios;
