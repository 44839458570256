import React, { Dispatch, SetStateAction } from "react";

// Packages
import { useParams } from "react-router-dom";
import { ReactFlowProvider } from "react-flow-renderer";
import { isEmpty, isNil } from "lodash";

// Contexts
import DagFlowContextProvider from "./context/DagFlowContextProvider";
import { useDagFlowContext } from "./context/useDagFlowContext";

// Components
import SplashSectionWrapper from "../SplashSection";
import DagFlowContainer from "../DagFlowContainer/DagFlowContainer";
import RecipeRunsPopover from "../RecipeRunsPopover/RecipeRunsPopover";

// Types
import { JobProps } from "../../Dag.types";

// Styles
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import CommonLoader from "src/components/CommonLoader";

type DagFlowProps = {
  jobProps?: JobProps;
  onAddDatasetAction?: () => void;
  recipeRunsPopoverAnchorEl?: HTMLButtonElement | null;
  setRecipeRunsPopoverAnchorEl?: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

type DagFlowWrapperProps = {
  jobProps?: JobProps;
  onAddDatasetAction?: () => void;
  recipeRunsPopoverAnchorEl?: HTMLButtonElement | null;
  setRecipeRunsPopoverAnchorEl?: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const DagFlowSubWrapper = (props: DagFlowWrapperProps) => {
  const { jobProps, onAddDatasetAction, recipeRunsPopoverAnchorEl, setRecipeRunsPopoverAnchorEl } =
    props || {};

  const { projectId } = useParams() || {};

  const { project, isJobPath, isFetchingProjectCanvas, isJobCanvasComparePath, nodesStore } =
    useDagFlowContext();

  return (
    <>
      {!isNil(recipeRunsPopoverAnchorEl) && !isNil(setRecipeRunsPopoverAnchorEl) && (
        <RecipeRunsPopover
          recipeRunsPopoverAnchorEl={recipeRunsPopoverAnchorEl}
          setRecipeRunsPopoverAnchorEl={setRecipeRunsPopoverAnchorEl}
        />
      )}

      {!!isJobCanvasComparePath ? (
        <ReactFlowProvider>
          <DagFlowContainer
            projectId={projectId}
            project={project}
            jobProps={jobProps}
            nodesDraggable={false}
          />
        </ReactFlowProvider>
      ) : isFetchingProjectCanvas ? (
        <CommonLoader />
      ) : isEmpty(nodesStore) ? (
        <SplashSectionWrapper onClick={() => onAddDatasetAction?.()} />
      ) : (
        <ReactFlowProvider>
          <DagFlowContainer
            projectId={projectId}
            project={project}
            jobProps={jobProps}
            nodesDraggable={!isJobPath}
          />
        </ReactFlowProvider>
      )}
    </>
  );
};

const DagFlowWrapper = (props: DagFlowProps) => {
  const { jobProps, onAddDatasetAction, recipeRunsPopoverAnchorEl, setRecipeRunsPopoverAnchorEl } =
    props || {};

  return (
    <DagFlowContextProvider jobProps={jobProps}>
      <NewThemeWrapper>
        <DagFlowSubWrapper
          jobProps={jobProps}
          onAddDatasetAction={onAddDatasetAction}
          recipeRunsPopoverAnchorEl={recipeRunsPopoverAnchorEl}
          setRecipeRunsPopoverAnchorEl={setRecipeRunsPopoverAnchorEl}
        />
      </NewThemeWrapper>
    </DagFlowContextProvider>
  );
};

export default DagFlowWrapper;
