import _ from "lodash";
import React, { useMemo } from "react";

import NoJobsFound from "src/pages/Dashboard/NoJobsFound";
import SomethingWentWrong from "src/components/Errors/SomethingWentWrong";
import useGetProjectRunsByProjectId from "src/hooks/api/jobs/useProjectRunsByProjectId";
import { JobsList } from "src/pages/Dashboard/JobsList";
import { Spinner } from "src/components";
import { dataAppConfigFields } from "./CreateDataAppForm";
import { Divider } from "@material-ui/core";

interface IProps {
  value: string;
  projectId: string;
  readOnly?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DataAppSelectJob: React.FC<IProps> = (props) => {
  const { value, readOnly, projectId, onChange } = props;
  const { isLoading, data, isError } = useGetProjectRunsByProjectId(projectId);

  const filtered = useMemo(() => {
    const filteredWithProjects = _.filter(data, (job) => job.dto?.projectId === projectId);
    if (readOnly) {
      return _.filter(filteredWithProjects, (job) => job?.dto?.id === value);
    }

    return filteredWithProjects;
  }, [data, readOnly, projectId]);

  const getChild = () => {
    if (isError) {
      return <SomethingWentWrong heading="Something went wrong while fetching job details" />;
    }

    if (isLoading) {
      return <Spinner />;
    }

    if (readOnly && filtered.length === 0) {
      return (
        <NoJobsFound msg="Looks like the job associated with this DataApp no longer exists. Kindly check again" />
      );
    }

    if (filtered?.length === 0) {
      return <NoJobsFound msg="There are no jobs set up for this project" />;
    }

    return (
      <div style={{ maxHeight: "350px", overflow: "auto" }}>
        {_.map(filtered, (job: any, i: number) => (
          <JobsList
            data={job}
            key={i}
            showSelectionName={dataAppConfigFields.projectRunId}
            disabled={readOnly}
            value={value}
            onChange={onChange}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <span style={{ color: "#515151" }}>Job</span>
      {getChild()}
      {!readOnly && <Divider />}
    </>
  );
};

export default DataAppSelectJob;
