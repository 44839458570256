import FileCopyIcon from "@material-ui/icons/FileCopy";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React, { useMemo } from "react";
import { IconButton, Paper, TextField, Tooltip, makeStyles } from "@material-ui/core";

import api from "src/utils/AxiosClient";
import useCopyClipboard from "src/hooks/useCopyClipboard";
import { CURL_COMMAND_INFO, PSFields } from "../utils/PredictionService.constants";
import { getCurlCommand } from "../utils/PredictionService.helpers";

interface IProps {
  name: string;
}

export const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",

    "& button": {
      height: "fit-content",
      alignSelf: "center"
    }
  },
  copyBtn: () => ({
    "& span": {
      color: "black"
    }
  }),
  flex: {
    display: "flex",
    gap: "10px",
    alignItems: "center",

    "& textarea, input": {
      fontSize: "12px",
      color: "rgba(81, 81, 81, 1)"
    }
  },
  text: { fontSize: 12, color: "rgb(153, 153, 153)", width: "90%", marginBottom: "16px" },
  details: {
    fontSize: "14px",
    fontWeight: 400
  }
});

const SampleCurlCommand: React.FC<IProps> = (props) => {
  const token = api.getToken();
  const classes = useStyles();

  const value = useMemo(() => {
    return getCurlCommand(props.name, token!);
  }, [props.name, token]);

  const { handleCopyClick, isCopied } = useCopyClipboard(value);

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.details}>Sample Curl Command</div>
      <span className={classes.text}>
        Use the provided CURL command as a reference to make real-time predictions with your chosen
        model by passing the desired input data
      </span>
      <div className={classes.flex}>
        <TextField
          fullWidth
          id={PSFields.curlCommand.id}
          name={PSFields.curlCommand.id}
          label={PSFields.curlCommand.label}
          value={value}
          maxRows={4}
          minRows={4}
          multiline
          disabled
          size="small"
          test-id="prediction-service-endpoint"
          variant="outlined"
        />
        <Tooltip title={CURL_COMMAND_INFO}>
          <InfoOutlinedIcon />
        </Tooltip>
        <Tooltip title={isCopied ? "Copied" : "Copy"}>
          <IconButton className={classes.copyBtn} onClick={handleCopyClick}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      </div>
    </Paper>
  );
};

export default SampleCurlCommand;
