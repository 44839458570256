enum WebPaths {
  About = "/projects/:projectId/about",
  AppendDataset = "/projects/:projectId/scenario/:scenarioId/dataset/:datasetId",
  ArtifactsAndModels = "/artifacts-and-models/:entityName",
  ArtifactsDetails = "/artifacts-and-models/artifact/:artifactName",
  AuthPath = "/auth",
  AutoMLRecipeContainer = "/projects/:projectId/scenario/:scenarioId/add-auto-ml-recipe/:groupId?",
  AutoMLNotebookContainer = "/projects/:projectId/scenario/:scenarioId/add-auto-ml-recipe/:groupId/notebook",
  Canvas = "/scenario/:scenarioId/canvas",
  Charts = "/projects/:projectId/scenario/:scenarioId/charts/:chartId",
  CodeRecipeContainer = "/projects/:projectId/scenario/:scenarioId/add-code-recipe/:groupId?",
  CodeNotebookContainer = "/projects/:projectId/scenario/:scenarioId/add-code-recipe/:groupId/notebook",
  APIConnectorRecipeContainer = "/projects/:projectId/scenario/:scenarioId/api-connector-recipe",

  APIConnectorNotebookContainer = "/projects/:projectId/scenario/:scenarioId/api-connector-recipe/:groupId/notebook",
  CreateDataConnector = "/data-connectors/create",
  CreateJob = "/create-job",
  Dag = "/projects/:projectId",
  Dashboard = "/",
  DataAppDashboard = "/dataapps-ui/:dataAppName",
  DataAppDeeplink = "/apps/:dataAppName/:tenantName?",
  DataAppLogs = "/dataapps-ui/:id/:dataAppName/logs",
  DataConnector = "/data-connectors/:id?",
  DataConnectors = "/data-connectors",
  Dataapps = "/dataapps-ui",
  Dataset = "/projects/:projectId/scenario/:scenarioId/dataset",
  EditPredictionJob = "/projects/:projectId/prediction-job/:projectRunId",
  EnvironmentConfig = "/environments/:envId",
  EnvironmentLogs = "/environments/:envId/logs",
  Environments = "/environments",
  JobArtifacts = "/:jobId/scenario/:scenarioId/job-runs/:jobRunId/artifact/:artifactName",
  JobAutoMLRecipe = "/:jobId/scenario/:scenarioId/job-runs/:jobRunId/recipes/add-auto-ml-recipe/:groupId",
  JobAPIConnectorRecipe = "/:jobId/scenario/:scenarioId/job-runs/:jobRunId/recipes/api-connector-recipe/:groupId",
  JobCanvas = "/:jobId/job-runs/:jobRunId/job-canvas",
  JobChart = "/:jobId/scenario/:scenarioId/job-runs/:jobRunId/charts/chart/:chartId",
  JobCodeRecipe = "/:jobId/scenario/:scenarioId/job-runs/:jobRunId/recipes/add-code-recipe/:groupId",
  JobCompare = "/:jobId/scenario/:scenarioId/job-compare",
  JobDataRoutes = "/:jobId/scenario/:scenarioId/job-runs/:jobRunId/entity/:entityId/:section?",
  JobId = "/:jobId",
  JobModels = "/:jobId/scenario/:scenarioId/job-runs/:jobRunId/model/:modelName",
  JobRoutes = "/projects/:projectId/jobs",
  JobRuns = "/:jobId/job-runs",
  JobSegment = "/:jobId/scenario/:scenarioId/job-runs/:jobRunId/entity/:entityId/segment/:segmentId",
  JobStandardRecipe = "/:jobId/scenario/:scenarioId/job-runs/:jobRunId/recipes/add-recipe/:groupId",
  ModelDetails = "/artifacts-and-models/model/:modelName",
  PredictionJob = "/projects/:projectId/prediction-job",
  PredictionLogs = "/projects/:projectId/prediction-job/:predictionRunId/prediction-runs/:predictionRunEntryId/logs",
  ProjectDashboard = "/projects/:projectId/project-dataapps",
  ProjectDataApp = "/projects/:projectId/project-dataapps/:dataAppName?",
  Projects = "/projects",
  RecipeRunLogs = "/recipe-run-logs/:projectId/:scenarioId/:groupId",
  ResetLink = "/reset-link",
  ResetPasswordLinkSent = "/reset-password-link-sent",
  RunIdLogs = "/:jobId/job-runs/:jobRunId/logs",
  Scenarios = "/projects/:projectId/scenarios",
  Scenario = "/projects/:projectId/scenarios/scenario/:scenarioId",
  NewScenario = "/projects/:projectId/scenarios/scenario/new-scenario",
  SendResetPassword = "/reset-password-link-send",
  SignIn = "/sign-in",
  SignUp = "/sign-up",
  StandardRecipeDataContainer = "/projects/:projectId/scenario/:scenarioId/add-recipe/:groupId?",
  StandardRecipeNotebookContainer = "/projects/:projectId/scenario/:scenarioId/add-recipe/:groupId/notebook/:transformId",
  TenantManagement = "/tenant-management/:tenantId",
  UsageAndPolicy = "/usage-and-policy",
  UserProfile = "/user-profile",
  VariablesList = "/variables/:projectId",
  ViewData = "/view-data/projects/:projectId/entity/:entityId/scenario/:scenarioId/:section",
  Segment = "/projects/:projectId/scenario/:scenarioId/entity/:entityId/segment/:segmentId?",
  ReleaseNotes = "/release-notes/:name?",
  ConfigureChatbot = "/chatbot/configure?"
}

const loginPath = `${WebPaths.AuthPath}${WebPaths.SignIn}`;

export { WebPaths, loginPath };
