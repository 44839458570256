import AssignmentIcon from "@material-ui/icons/Assignment";
import React from "react";
import _ from "lodash";
import { Grid, makeStyles } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";

import ApiConnectorIcon from "src/icons/NewUX/ApiConnectorIcon";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import styles from "./DeleteNodeModal.module.scss";
import useDeleteCandidates from "src/hooks/api/projects/useDeleteCandidates";
import useGetDeleteCandidates from "src/hooks/api/projects/useGetDeleteCandidates";
import { AiAssistedIcon } from "src/icons/AiAssistedIcon";
import { ArtifactIcon } from "src/icons/NewUX/ArtifactIcon";
import { ChartIcon } from "src/icons/NewUX";
import { DatasetIcon } from "src/icons/NewUX/DatasetIcon";
import { Modal } from "src/components/custom";
import { ModelIcon } from "src/icons/NewUX/ModelIcon";
import { PipelineStepTypeEnum } from "@rapidcanvas/rc-api-core";
import { RapidModelIcon } from "src/icons/RapidModelIcon";
import { RecipeIcon } from "src/icons/RecipeIcon";
import { RecipeTypes } from "src/pages/private/ProjectsModule/utils";
import { clearArtifactCache, clearModelCache } from "src/hooks/api";
import { toastWrapper } from "src/utils/toastWrapper";
import { useCanvasStore } from "../../../store/store";
import CommonLoader from "src/components/CommonLoader";

const keyMap = {
  [PipelineStepTypeEnum.Artifact]: "artifact",
  [PipelineStepTypeEnum.Chart]: "chart",
  [PipelineStepTypeEnum.Dfsgroup]: "group",
  [PipelineStepTypeEnum.Entity]: "entity",
  [PipelineStepTypeEnum.Model]: "model",
  [PipelineStepTypeEnum.IntermediateEntity]: ""
};
const nodeTypes = {
  chart: {
    label: PipelineStepTypeEnum.Chart,
    displayName: "Chart",
    warningText: "Delete chart",
    icon: <ChartIcon width={18} height={20} viewBox="-2 -4 40 40" />
  },
  entity: {
    label: PipelineStepTypeEnum.Entity,
    displayName: "Entity",
    warningText: "Delete entity",
    icon: <DatasetIcon width={18} height={18} viewBox="-1 0 20 20" />
  },
  group: {
    label: PipelineStepTypeEnum.Dfsgroup,
    displayName: "Recipe",
    warningText: "Delete recipe",
    recipeIcons: {
      [RecipeTypes.AiAssisted]: <AiAssistedIcon />,
      [RecipeTypes.RapidModel]: <RapidModelIcon />,
      [RecipeTypes.ApiConnector]: <ApiConnectorIcon />,
      [RecipeTypes.Template]: <RecipeIcon />
    }
  },
  artifact: {
    label: PipelineStepTypeEnum.Artifact,
    displayName: "Artifact",
    warningText: "Delete artifact",
    icon: <ArtifactIcon width={18} height={18} viewBox="-2 0 17 17" />
  },
  model: {
    label: PipelineStepTypeEnum.Model,
    displayName: "Model",
    warningText: "Delete model",
    icon: <ModelIcon width={22} height={22} viewBox="-2 -2 20 20" />
  }
};

const useStyles = makeStyles({
  deleteNote: {
    fontStyle: "italic",
    color: "#000000",
    opacity: 0.5,
    paddingTop: "12px",
    fontSize: "14px"
  }
});

export const psMessage = (
  <span>
    This will also delete the associated prediction service(s) and prediction run(s) linked to the
    model(s) of this recipe(if any).
  </span>
);

interface IProps {
  open: boolean;
  onClose: () => void;
  nodeId?: string;
  nodeName?: string;
  nodeType: string;
  deleteNote?: React.JSX.Element | string;
  onAfterSubmit?: () => void;
  onDeleteSuccess?: () => void;
}

const DeleteNodeModal: React.FC<IProps> = (props) => {
  const {
    open,
    nodeId = "",
    nodeName = "",
    nodeType = "entity",
    deleteNote,
    onClose,
    onAfterSubmit,
    onDeleteSuccess
  } = props;

  const classes = useStyles();
  const [setReloadTrigger] = useCanvasStore((state) => [state.setReloadTrigger]);
  const queryClient = useQueryClient();
  const deleteCandidates = useDeleteCandidates();

  const { data, isLoading, isError } = useGetDeleteCandidates(
    nodeId,
    _.get(nodeTypes, [nodeType, "label"]),
    nodeType,
    {
      enabled: open
    }
  );

  const handleDelete = () => {
    deleteCandidates.mutate(
      {
        stepId: nodeId,
        nodeType,
        stepType: _.get(nodeTypes, [nodeType, "label"])
      },
      {
        onSuccess: async () => {
          nodeType === "artifact" && (await clearArtifactCache(queryClient));
          nodeType === "model" && (await clearModelCache(queryClient));
          toastWrapper({
            type: "success",
            content: `${_.get(nodeTypes, [nodeType, "displayName"]) || "Node"} ${nodeName} deleted successfully!`
          });

          setReloadTrigger();
          onDeleteSuccess?.();
        },
        onSettled: () => {
          onClose();
          onAfterSubmit?.();
        }
      }
    );
  };

  return open ? (
    <NewThemeWrapper>
      <Modal
        open={open}
        title={_.get(nodeTypes, [nodeType, "warningText"])}
        onClose={onClose}
        isSubmitting={deleteCandidates.isLoading}
        onSubmit={handleDelete}
        isSubmitDisabled={isLoading || isError || deleteCandidates.isLoading}
        submitLabel="Delete">
        {isLoading ? (
          <CommonLoader />
        ) : (
          <>
            <p className={styles.deletedItemsTitle}>Following items will be deleted:</p>
            {_.map(
              _.sortBy(data, (obj) => (obj.id === nodeId ? -1 : 1)),
              (item) => {
                return (
                  <div key={item.id} className={styles.toBeDeletedItem}>
                    <div className={styles.toBeDeletedIcon}>
                      {item.type === PipelineStepTypeEnum.Dfsgroup ? (
                        item.recipeType ? (
                          _.get(nodeTypes, ["group", "recipeIcons", item.recipeType])
                        ) : (
                          <AssignmentIcon />
                        )
                      ) : item.type ? (
                        _.get(nodeTypes, [_.get(keyMap, item.type), "icon"])
                      ) : (
                        <AssignmentIcon />
                      )}
                    </div>
                    <div className={styles.toBeDeletedName}>{item.displayName}</div>
                  </div>
                );
              }
            )}
            {deleteNote && <Grid className={classes.deleteNote}>{deleteNote}</Grid>}
          </>
        )}
      </Modal>
    </NewThemeWrapper>
  ) : (
    <></>
  );
};

export default DeleteNodeModal;
