import {
  every,
  has,
  includes,
  isEmpty,
  isNil,
  reduce,
  replace,
  size,
  startCase,
  toLower,
  toUpper
} from "lodash";

import { removeLocalStorageItem } from "src/services/LocalStorage/LocalStorage.service";
import { removeSessionStorageItem } from "src/services/SessionStorage/SessionStorage.service";

import { TableSessionConfig } from "src/constants/table.constants";

export const onLogout = () => {
  removeLocalStorageItem(TableSessionConfig.TablePreferencesSessionKey);
  removeSessionStorageItem(TableSessionConfig.TablePreferencesSessionKey);
};

const LOCALHOST = "localhost";

const isLocalhost = () => {
  const hostname = window.location.hostname;
  return hostname === LOCALHOST;
};
const DEFAULT_SERVER_URL = "https://test.dev.rapidcanvas.net";

export const getBasePath = () => {
  return isLocalhost() ? DEFAULT_SERVER_URL : window.location.origin;
};

export const areAllKeysPresentAndNotNil = (dataMap?: { [key: string]: any }, keys?: string[]) => {
  if (isNil(dataMap) || isNil(keys)) {
    return false;
  }

  if (isEmpty(dataMap) || isEmpty(keys)) {
    return false;
  }

  return every(keys, (key) => has(dataMap, key) && !isNil(dataMap[key]));
};

interface GetReactHookFormTextFieldRulesProps {
  fieldName?: string;
  minLength?: number;
  maxLength?: number;
}

export const getReactHookFormTextFieldRules = (props?: GetReactHookFormTextFieldRulesProps) => {
  const { fieldName, minLength = 3, maxLength = 64 } = props || {};

  return {
    validate: {
      noInvalidPatterns: (value: string) => {
        if (!value) {
          return `${fieldName ? `The ${toLower(fieldName)}` : "This field"} cannot be blank.`;
        }

        const thisValue = value.trim();
        if (size(thisValue) < minLength || size(thisValue) > maxLength) {
          return `${fieldName ? `The ${toLower(fieldName)}` : "This field"} needs to be between ${minLength} and ${maxLength} characters.`;
        }

        if (
          // Spaces, underscores and dashes are allowed. No other special characters
          !/^(?:[a-zA-Z0-9 _-]*)?$/.test(thisValue) ||
          // Should not have only special characters
          /^(?:[_ -]*)?$/.test(thisValue) ||
          // Should not start with special characters
          /^(?:[_-]*)?$/.test(thisValue.substring(0, 1))
        ) {
          return `${fieldName ? `The ${toLower(fieldName)}` : "This field"} contains invalid characters. It needs to start with alphanumerics and can only contain alphanumerics, spaces, underscores and dashes.`;
        }

        return true;
      }
    }
  };
};

const createCanvasContext = () => {
  const canvas = document.createElement("canvas");
  return canvas.getContext("2d");
};

export const getTextWidth = (
  text: string,
  fontSize = 14.5,
  fontFamily: "Roboto" | "Helvetica" | "Arial" | "sans-serif" = "Roboto"
) => {
  const context = createCanvasContext();

  if (context) {
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  }

  return 0; // Fallback in case the context is not available
};

const transformSubstringsCase = (
  input: string,
  substrings: string[],
  transformFn: (text: string) => string
): string =>
  reduce(
    substrings,
    (result, substring) =>
      replace(result, new RegExp(substring, "gi"), (match) => transformFn(match)),
    input
  );

export const snakeCaseToStartCase = (
  text: string,
  options?: Partial<{ toUpperCase: string[]; toLowerCase: string[] }>
): string => {
  const { toUpperCase = [], toLowerCase = [] } = options || {};
  let output = startCase(toLower(text));

  if (!isEmpty(toUpperCase)) {
    output = transformSubstringsCase(output, toUpperCase, toUpper);
  }
  if (!isEmpty(toLowerCase)) {
    output = transformSubstringsCase(output, toLowerCase, toLower);
  }

  return output;
};

export const safeParseJSON = (json: string | undefined, defaultValue = {}) => {
  if (!json) {
    return defaultValue;
  }
  try {
    return JSON.parse(json);
  } catch (error) {
    console.error("Failed to parse JSON:", error);
    return defaultValue;
  }
};

export const preventExponentialInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (includes(["e", "E", "+"], event?.key)) {
    event?.preventDefault();
  }
};
