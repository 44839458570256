// Packages
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

// Open API
import { EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Utils
import api from "utils/AxiosClient";

// Hooks
import { updateEnvsQueryDataById } from "./usePollEnvironments";

interface IPayload {
  id?: string;
}

const useStopEnvironment = (): UseMutationResult<unknown, unknown, IPayload, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: IPayload) => {
      if (!id) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(async () => await api.EnvControllerApi.stopEnv(id, true));
    },
    onSuccess: (__1, payload) => {
      !!payload?.id &&
        updateEnvsQueryDataById(queryClient, payload?.id, EnvDtoLaunchStatusEnum.Stopping);
    }
  });
};

export default useStopEnvironment;
