import MagicGenerateIcon from "src/icons/MagicGenerateIcon";
import { Button, Grid, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  btn: {
    border: "1px solid #C7C7C7",
    backgroundColor: "#fff",
    color: "#003656",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: 400
  },
  prompts: {
    overflow: "auto",
    minHeight: "40px",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 16px 8px 16px"
  },
  grid: {
    gap: "8px",
    padding: "5px"
  },
  gridItem: {
    maxWidth: "33%"
  }
});

export const SuggestionPrompts = ({
  suggestions,
  onClick,
  isDisabled,
  disabledTitle
}: {
  disabledTitle: string;
  isDisabled: boolean;
  suggestions: string[];
  onClick: (suggestion: string) => void;
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        className={classes.grid}>
        {suggestions.map((suggestion, index) => (
          <Grid key={index} className={classes.gridItem}>
            <Tooltip title={disabledTitle}>
              <div>
                <Button
                  disabled={isDisabled}
                  className={classes.btn}
                  data-test-id="ai-suggestion-prompt"
                  startIcon={<MagicGenerateIcon fill="#003656" opacity={!!isDisabled ? 0.5 : 1} />}
                  onClick={() => onClick(suggestion)}>
                  {suggestion}
                </Button>
              </div>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
