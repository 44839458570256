import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { useGetRecipe } from "src/hooks/api";
import { AutoMLRecipeContainer } from "./AutoMLRecipeContainer/AutoMLRecipeContainer";
import { AutoMLRecipeContextProvider } from "./AutoMLRecipeContext/AutoMLRecipeContextProvider";
import CommonLoader from "src/components/CommonLoader";

const AutoMLRecipeDataContainer = () => {
  const { groupId, scenarioId, jobRunId } = useParams<$TSFixMe>();
  const { isLoading, data, isSuccess } = useGetRecipe({
    recipeId: groupId,
    scenarioId,
    jobRunId,
    options: { refetchOnMount: true }
  });
  const [key, setKey] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setKey(Date.now().toString());
    }
  }, [isSuccess]);
  if (!groupId) {
    return (
      <AutoMLRecipeContextProvider>
        <AutoMLRecipeContainer />
      </AutoMLRecipeContextProvider>
    );
  }

  return isLoading ? (
    <CommonLoader />
  ) : (
    <div key={key}>
      <AutoMLRecipeContextProvider recipe={data?.recipe}>
        <AutoMLRecipeContainer />
      </AutoMLRecipeContextProvider>
    </div>
  );
};

export default AutoMLRecipeDataContainer;
