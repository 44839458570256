import {
  postAPI,
  putAPI,
  getAPI,
  deleteAPI,
  postAPIWithRethrow,
  deleteAPIWithRethrow,
  getAPIWithRethrow,
  putAPIWithRethrow
} from "../utils/apiService";

export const getRecipesByProjectId = async (projectId: $TSFixMe) =>
  await getAPI(`/v2/dfs-run-config-groups?projectId=${projectId}`);

export const addTransformGroup = async (payload: $TSFixMe, id: $TSFixMe) =>
  await postAPI(`/v2/dfs-run-config-groups/${id}`, payload);

export const addTransformGroupWithRethrow = async (payload: $TSFixMe, id: $TSFixMe) =>
  // // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
  await postAPIWithRethrow(`/v2/dfs-run-config-groups/${id}`, payload);

export const updateTransformGroup = async (payload: $TSFixMe, id: $TSFixMe) =>
  await putAPIWithRethrow(`/v2/dfs-run-config-groups/${id}`, payload);

export const updateTransformGroupWithRethrow = async (payload: $TSFixMe, id: $TSFixMe) =>
  await putAPIWithRethrow(`/v2/dfs-run-config-groups/${id}`, payload);

export const getTransformGroup = async ({ groupId, scenarioId, jobRunId }: $TSFixMe = {}) =>
  await getAPI(
    `/v2/dfs-run-config-groups?id=${groupId}${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}${
      !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
    }`
  );

export const getTransformGroupWithRethrow = async (
  groupId: $TSFixMe,
  scenarioId?: string,
  jobRunId?: string
) =>
  await getAPIWithRethrow(
    `/v2/dfs-run-config-groups?id=${groupId}${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}${
      !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
    }`
  );

export const runTransformGroup = async (groupId: $TSFixMe, scenarioId: $TSFixMe) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await postAPI(
    `/v2/dfs-run-config-groups/run/${groupId}${scenarioId ? `?scenarioId=${scenarioId}` : ""}`
  );

export const runTransformGroupWithRethrow = async (
  groupId: $TSFixMe,
  scenarioId: $TSFixMe,
  options?: $TSFixMe,
  shouldDispatchEvent = true
) =>
  await postAPIWithRethrow(
    `/v2/dfs-run-config-groups/run/${groupId}${scenarioId ? `?scenarioId=${scenarioId}` : ""}`,
    {},
    options,
    shouldDispatchEvent
  );

export const runRecipeLogsWithRethrow = async (
  groupId: $TSFixMe,
  scenarioId: $TSFixMe,
  limit = 0,
  jobRunId?: string
) =>
  await getAPIWithRethrow(
    `/v2/dfs-run-config-groups/${groupId}/live-status?scenarioId=${scenarioId}${
      !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
    }${limit ? "&limit=" + limit : ""}`
  );

export const testTransformGroup = async (template: $TSFixMe, groupId: $TSFixMe) =>
  await postAPI(`/v2/dfs-run-config-groups/test/${groupId}`, template);

export const testTransformGroupWithRethrow = async (template: $TSFixMe, groupId: $TSFixMe) =>
  // // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
  await postAPIWithRethrow(`/v2/dfs-run-config-groups/test/${groupId}`, template);

// Transforms
export const getTransformsByGroupId = async (groupId: $TSFixMe) =>
  await getAPI(`/v2/dfs-run-configs?groupId=${groupId}`);

export const getTransformsByGroupIdWithRethrow = async (groupId: $TSFixMe) =>
  await getAPIWithRethrow(`/v2/dfs-run-configs?groupId=${groupId}`);

export const deleteTransformById = async (id: $TSFixMe) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await deleteAPI(`/v2/dfs-run-configs/${id}`);

export const deleteTransformByIdWithRethrow = async (id: $TSFixMe) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await deleteAPIWithRethrow(`/v2/dfs-run-configs/${id}`);

export const saveTransformById = async (body: $TSFixMe) =>
  await postAPI("/v2/dfs-run-configs", body);

export const saveTransformByIdWithRethrow = async (body: $TSFixMe, options?: any) =>
  // // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
  await postAPIWithRethrow("/v2/dfs-run-configs", body, options);

export const updateTransformById = async (body: $TSFixMe) =>
  await putAPI("/v2/dfs-run-configs", body);

export const updateTransformByIdWithRethrow = async (body: $TSFixMe) =>
  await putAPIWithRethrow("/v2/dfs-run-configs", body);

export const getTemplates = async () => await getAPI(`/v2/dfs-templates?status=ACTIVE`);

export const getTemplatesWithRethrow = async ({ projectId }: { projectId: string }) =>
  await getAPIWithRethrow(`/v2/dfs-templates?status=ACTIVE`, { params: { projectId } });

export const createTemplate = async (payload: $TSFixMe) =>
  await postAPI("/v2/dfs-templates", payload);

export const createTemplateWithRethrow = async (payload: $TSFixMe) =>
  await postAPIWithRethrow("/v2/dfs-templates", payload);

export const updateTemplate = async (payload: $TSFixMe) =>
  await putAPI("/v2/dfs-templates?id=" + payload.id, payload);

export const updateTemplateWithRethrow = async (payload: $TSFixMe) =>
  await putAPIWithRethrow("/v2/dfs-templates?id=" + payload.id, payload, {}, false);

export const deleteTemplate = async (templateId: $TSFixMe) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await deleteAPIWithRethrow(`/v2/dfs-templates?id=${templateId}`);

export const getTemplateWithRethrow = async (templateId: string) =>
  await getAPIWithRethrow(`/v2/dfs-templates/${templateId}`);
