import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";
import { ProjectRunDetailDto } from "@rapidcanvas/rc-api-core";
import _ from "lodash";

const QUERY_KEY_PROJECT_RUN_BY_ID = "project-run-by-id";

const getSortedJobs = (data: ProjectRunDetailDto[]) => {
  return data?.sort((a, b) => {
    const aTime =
      a?.lastRunEntry?.updated ||
      a?.lastRunEntry?.created ||
      a?.dto?.updated ||
      a?.dto?.created ||
      0;
    const bTime =
      b?.lastRunEntry?.updated ||
      b?.lastRunEntry?.created ||
      b?.dto?.updated ||
      b?.dto?.created ||
      0;
    return bTime - aTime;
  });
};
const useGetProjectRunsByProjectId = (
  projectId?: string,
  options?: UseQueryOptions<ProjectRunDetailDto[]>
): UseQueryResult<ProjectRunDetailDto[]> => {
  return useQuery({
    queryKey: [QUERY_KEY_PROJECT_RUN_BY_ID, projectId],
    queryFn: async () => {
      if (!projectId) {
        throw "Invalid projectId!";
      }

      const response = await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.findProjectRuns(projectId)
      );

      return getSortedJobs(response);
    },
    enabled: !!projectId,
    refetchOnMount: true,
    ...options
  });
};

export default useGetProjectRunsByProjectId;
