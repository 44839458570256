import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { isEmpty, some, toNumber, find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { EnvDto, UpdateEnvDto } from "@rapidcanvas/rc-api-core/openapi/Models";

import { EnvironmentsTypes, DEFAULT_NAME } from "../../../../../constants/environments.constants";
import { ITypes, extractEnvUsageTypes } from "../../utils/Environments.helpers";
import { EnvironmentInputs } from "./containers/EnvironmentInputs/EnvironmentInputs";
import { EnvironmentTabs } from "./containers/EnvironmentTabs/EnvironmentTabs";

import { useUpdateEnvironment, updateEnvironmentsQueryData } from "src/hooks/api";
import { handleResponse } from "src/utils/apiService";
import useGetEnvironmentUsage from "src/hooks/api/environments/useGetEnvironmentUsage";
import { Modal } from "src/components/custom";
import { formatStringsWithAnd } from "src/helpers/helpers";
import { ModalVariants } from "src/components/custom/Modal/Modal";
import { EnvironmentActionsStatusEnum, setEnvironmentStatus } from "src/stores/environmentsSlice";
import { environmentActionStatusSelector } from "../../Environment.selectors";
import EnvironmentHeader from "src/pages/private/EnvironmentsModule/pages/Environment/containers/EnvironmentHeader/EnvironmentHeader";
import { Spinner } from "src/components";

export type Values = {
  name: string;
  description: string;
  envType: string;
  code: string;
};

export interface IPredictionService {
  id: string;
  pdCreated: number;
  pdCreator: string;
  pdId: string;
  pdName: string;
  pdUpdated: number;
  pdUpdater: string;
  pdModelName: string;
}

export interface EnvAction {
  types: ITypes;
  action: "stopping" | "modifying" | "changing";
}

const useStyles = makeStyles(() => ({
  root: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    minHeight: "calc(100vh - 94px)"
  },
  container: { padding: 24, width: "calc(100vw)" },
  tab: {
    padding: "0 !important",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    "&.Mui-selected": {
      backgroundColor: "rgba(63, 81, 181, 0.08)"
    }
  }
}));

export interface EnvConfig extends Omit<UpdateEnvDto, "memInMbs"> {
  memory: string;
  diskSpace: number;
}

const Environment = ({
  environment,
  environmentsTypes,
  fetchEnvironmentData,
  environments,
  isEnvironmentsLoading
}: {
  environmentsTypes: any[];
  environment: EnvDto;
  fetchEnvironmentData: () => Promise<EnvDto | undefined>;
  environments: EnvDto[] | undefined;
  isEnvironmentsLoading: boolean;
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { envId } = useParams();
  const { isLoading: isSaving, mutateAsync: updateEnvMutation } = useUpdateEnvironment();
  const environmentStatus = useSelector(environmentActionStatusSelector(envId!));

  const getDefaultEnvValues = (env: any) => ({
    ...env,
    envType: env?.envType || (env as any)?.customEnvType,
    memory: env?.memInMbs ? (env?.memInMbs / 1024).toFixed() : "",
    cores: toNumber(env?.cores),
    diskSpace: toNumber(env?.diskInGbs)
  });

  const [isDirtyFields, setIsDirtyFields] = useState(false);
  const [environmentError, setEnvironmentError] = useState<string | undefined>("");
  const [confirmEnvAction, setConfirmEnvAction] = useState<EnvAction | null>(null);
  const [environmentConfigSession, setEnvironmentConfigSession] = useState<EnvConfig | null>(() =>
    getDefaultEnvValues(environment)
  );

  useEffect(() => {
    setEnvironmentConfigSession((prev) => ({
      ...prev,
      ...getDefaultEnvValues(environment)
    }));
  }, [environment]);

  const {
    data: envUsage,
    isLoading: isLoadingEnvUsage,
    isFetched: isFetchedEnvUsage,
    refetch: refetchEnvUsage
  } = useGetEnvironmentUsage(envId!);

  const handleSave = () => {
    if ((environmentsTypes || [])?.length === 0) {
      return;
    }

    dispatch(setEnvironmentStatus({ id: envId!, status: EnvironmentActionsStatusEnum.Updating }));

    const selectedEnvType = find(environmentsTypes, { name: environmentConfigSession?.envType });

    const valuesToSend: UpdateEnvDto = {
      name: environment.name,
      description: environmentConfigSession?.description?.trim(),
      requirements: environmentConfigSession?.requirements,
      envType: environmentConfigSession?.envType,
      linuxPkgs: environmentConfigSession?.linuxPkgs,
      ...(environmentConfigSession?.envType === EnvironmentsTypes.Custom
        ? {
            cores: environmentConfigSession?.cores,
            memInMbs: Number(environmentConfigSession?.memory ?? 0) * 1024
          }
        : {
            cores: selectedEnvType?.cores,
            memInMbs: selectedEnvType?.memInMbs
          })
    };
    updateEnvMutation(
      { id: envId!, payload: valuesToSend, async: true },
      {
        onSuccess: (environmentResponse) => {
          if (!isEmpty(environmentResponse)) {
            updateEnvironmentsQueryData(queryClient, environmentResponse);
          }
          handleResponse({ successMessage: "Environment updated successfully!" });

          setIsDirtyFields(() => false);
        },
        onError: () => {
          setIsDirtyFields(() => true);
        },
        onSettled: () => {
          setConfirmEnvAction(null);
          dispatch(setEnvironmentStatus({ id: envId!, status: EnvironmentActionsStatusEnum.Idle }));
        }
      }
    );
  };

  const handleSaveAction = async () => {
    const usageResponse = await refetchEnvUsage();
    const types = await extractEnvUsageTypes(usageResponse.data);
    if (isEmpty(types)) {
      handleSave();
    } else {
      setConfirmEnvAction({ types, action: "modifying" });
    }
  };

  const isIdle = useMemo(
    () => environmentStatus === EnvironmentActionsStatusEnum.Idle,
    [environmentStatus]
  );

  const isSaveDisabled = useMemo(() => {
    let isDisabled = false;

    isDisabled = isDisabled || !isIdle;
    isDisabled = isDisabled || !isDirtyFields;
    isDisabled = isDisabled || !!environmentError;
    isDisabled = isDisabled || isSaving;

    return isDisabled;
  }, [isIdle, isDirtyFields, environmentError, isSaving]);

  const saveTitle = useMemo(() => {
    return !isIdle ? "Wait until existing action completes" : environmentError ?? "";
  }, [isIdle, environmentError]);

  const isEnvironmentNameInUse = useCallback(
    (name: string) => some(environments, (env) => env.name === name),
    [environment]
  );

  const environmentActionWarning = `Please note that there are active processes within this environment, such as ${
    !!confirmEnvAction ? formatStringsWithAnd(confirmEnvAction.types) : ""
  }. Continuing with ${
    !!confirmEnvAction ? confirmEnvAction?.action : ""
  } the environment may affect these ongoing operations`;

  return (
    <>
      {confirmEnvAction && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Are you sure?"
          content={[environmentActionWarning]}
          onClose={() => setConfirmEnvAction(null)}
          onSubmit={handleSave}
          isSubmitting={isSaving}
          isSubmitDisabled={isSaving}
        />
      )}
      <Grid container direction="column" alignItems="stretch" className={classes.root}>
        <EnvironmentHeader
          isEnvironmentNameInUse={isEnvironmentNameInUse}
          environment={environment}
          isSaving={isSaving}
          isSaveActionDisabled={isSaveDisabled}
          saveTitle={saveTitle}
          onSaveAction={handleSaveAction}
          readOnly={!!(environment?.defaultFlag && environment?.name === DEFAULT_NAME)}
          fetchEnvironmentData={fetchEnvironmentData}
        />
        <Grid item xs={12} className={`${classes.container} container-height`}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              {isEnvironmentsLoading ? (
                <Spinner size={12} noPadding />
              ) : (
                <EnvironmentInputs
                  setIsDirtyFields={setIsDirtyFields}
                  isEnvTypeDisabled={isEnvironmentsLoading}
                  environmentsTypes={environmentsTypes || []}
                  setEnvironmentError={setEnvironmentError}
                  environmentConfigSession={environmentConfigSession}
                  setEnvironmentConfigSession={setEnvironmentConfigSession}
                />
              )}
            </Grid>
            <Grid item xs={9}>
              <EnvironmentTabs
                setIsDirtyFields={setIsDirtyFields}
                envUsage={envUsage}
                isLoadingEnvUsage={isLoadingEnvUsage}
                isDefault={!!(environment?.defaultFlag && environment?.name === DEFAULT_NAME)}
                environmentConfigSession={environmentConfigSession}
                setEnvironmentConfigSession={setEnvironmentConfigSession}
                isFetchedEnvUsage={isFetchedEnvUsage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Environment;
