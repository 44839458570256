// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash";

// Open API
import api from "utils/AxiosClient";

// Types
import { EnvironmentLogModeTypes } from "src/pages/private/EnvironmentsModule/pages/Environments/Environments.types";

export const enum UseGetEnvironmentLogsQueryKeys {
  EnvironmentLogs = "environmentLogs"
}

interface IEnvironmentLogsProps extends UseQueryOptions<unknown, unknown, string[]> {
  id?: string;
  mode?: EnvironmentLogModeTypes;
}

const useGetEnvironmentLogs = (props: IEnvironmentLogsProps): UseQueryResult<string[]> => {
  const { id, mode, ...options } = props;

  return useQuery({
    queryKey: [UseGetEnvironmentLogsQueryKeys.EnvironmentLogs, id],
    queryFn: async () => {
      if (!id) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.EnvControllerApi.getLogs2(id, mode),
        false,
        false
      );
    },
    select: (data: any) => (isArray(data?.logs) ? data?.logs : []),
    enabled: !!id,
    ...options
  });
};

export default useGetEnvironmentLogs;
