import { useState } from "react";

import { switchTenantUserWithRethrow } from "../api-requests/user.api-requests";

import { postAPIWithRethrow } from "src/utils/apiService";
import { logNotebooksOut } from "src/utils/operateNotebookService";

import { useProjectsStore } from "src/store/store";
import { shouldRefreshProjectsToggler } from "src/store/store.selectors";
import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";

export const useFetchTenantActions = () => {
  const [switchingTenantId, setSwitchingTenantId] = useState("");

  const { setAuthResponse } = useAuthSetAuthResponse();

  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);

  const updateAuthStore = (data: $TSFixMe) => {
    setAuthResponse({ data });
    toggleShouldProjectsRefresh();
  };

  const switchTenant = async (tenantId: $TSFixMe, redirectUrl?: string) => {
    setSwitchingTenantId(tenantId);
    try {
      const { token: switchToken } = await switchTenantUserWithRethrow({ tenantId });

      const response = await postAPIWithRethrow("/token/validation", {
        token: switchToken
      });

      updateAuthStore(response);

      // @TODO: This needs to be centralized.
      await logNotebooksOut();
      setSwitchingTenantId("");

      if (redirectUrl) {
        window.location.href = redirectUrl;
        window.history.pushState(null, "", redirectUrl);
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error({ error });
      setSwitchingTenantId("");
    }
  };

  const leaveTenantAndSwitch = async (id: string, redirectUrl?: string) => {
    try {
      const response = await postAPIWithRethrow(`/tenants/leave-current/switch/${id}`, {});

      setAuthResponse({ data: response });
      await logNotebooksOut();
      if (redirectUrl) {
        window.location.href = redirectUrl;
        window.history.pushState(null, "", redirectUrl);
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error({ error });
    }
  };

  return { switchingTenantId, switchTenant, leaveTenantAndSwitch };
};
