// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray, sortBy, toLower } from "lodash";

// Open API
import { ProjectRunDetailDto } from "@rapidcanvas/rc-api-core";

// Utils
import api from "src/utils/AxiosClient";
import { getJobsDashboardWithRethrow, getJobsWithRethrow } from "src/api/projects";

export const enum UseGetJobsDashboardQueryKeys {
  JobsDashboard = "jobs-dashboard",
  Jobs = "schedulers"
}

export const enum UseGetJobsQueryKeys {
  JobsNew = "jobsNew"
}

interface JobsProps extends UseQueryOptions<$TSFixMe> {
  projectId?: string;
  shouldRefreshJobsGetter?: $TSFixMe;

  // useQuery options
  cacheTime?: number;
  enabled?: boolean;
  refetchOnMount?: boolean;
}

interface IJobsProps extends UseQueryOptions<unknown, unknown, ProjectRunDetailDto[], any> {
  projectId?: string;
  shouldSort?: boolean;
}

export const useGetJobsDashboard = (
  options?: UseQueryOptions<ProjectRunDetailDto[]>
): UseQueryResult<ProjectRunDetailDto[]> =>
  useQuery({
    queryKey: [UseGetJobsDashboardQueryKeys.JobsDashboard],
    queryFn: async () => await getJobsDashboardWithRethrow(),
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    ...options
  });

export const useGetJobs = (props: JobsProps): UseQueryResult<$TSFixMe> => {
  const {
    projectId,
    shouldRefreshJobsGetter,

    // useQuery options
    cacheTime = 0,
    ...options
  } = props;

  return useQuery({
    queryKey: ["jobs", projectId],
    queryFn: async () => await getJobsWithRethrow(projectId),
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    cacheTime,
    enabled: !!projectId || (!!projectId && !!shouldRefreshJobsGetter),
    ...options
  });
};

export const useGetJobsNew = (props: IJobsProps): UseQueryResult<ProjectRunDetailDto[]> => {
  const { projectId, shouldSort = true, ...options } = props;

  return useQuery({
    queryKey: [UseGetJobsQueryKeys.JobsNew, projectId],
    queryFn: async () => {
      if (!projectId) {
        throw "projectId is null!";
      }

      return await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.findProjectRuns(projectId)
      );
    },
    select: (data) =>
      isArray(data) ? (!!shouldSort ? sortBy(data, (job) => toLower(job?.dto?.name)) : data) : [],
    enabled: !!projectId,
    ...options
  });
};
