import React, { useState } from "react";
import {
  Grid,
  Chip,
  makeStyles,
  Typography,
  IconButton,
  Box,
  Tooltip,
  Menu,
  useTheme,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { BarButton } from "src/components/SubtopBar/BarButton";
import { DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";
import { EnvironmentStatuses } from "src/constants/environments.constants";
import { JobRunStatuses } from "pages/private/ProjectsModule/pages/Jobs/utils/Jobs.constants";
import { RecipeStatuses } from "src/constants";
import { SearchField } from "src/components";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import { ExportIcon } from "src/icons/NewUX/ExportIcon";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

export const getStatusEquivalence = (
  status: RecipeStatuses | DataAppDtoLaunchStatusEnum | EnvironmentStatuses | JobRunStatuses
) => {
  switch (status) {
    case RecipeStatuses.Error:
    case EnvironmentStatuses.Failed:
    case JobRunStatuses.Failure:
    case JobRunStatuses.RecipeTimedOut:
    case JobRunStatuses.TimedOut:
    case DataAppDtoLaunchStatusEnum.Stopped:
      return "error";
    case EnvironmentStatuses.Inactive:
      return "warning";
    case RecipeStatuses.UnBuilt:
    case EnvironmentStatuses.Unknown:
      return "inactive";
    case DataAppDtoLaunchStatusEnum.Running:
    case RecipeStatuses.Success:
    case EnvironmentStatuses.Active:
    case JobRunStatuses.Success:
    case JobRunStatuses.SuccessWithWarn:
      return "success";
    case RecipeStatuses.Running:
    case DataAppDtoLaunchStatusEnum.Launching:
    case DataAppDtoLaunchStatusEnum.Stopping:
    case EnvironmentStatuses.Launching:
    case JobRunStatuses.Created:
    case JobRunStatuses.Started:
    case JobRunStatuses.EntityLoading:
    case JobRunStatuses.Running:
      return "default";
    default:
      return "default";
  }
};

export type StatusType = "inactive" | "warning" | "error" | "success" | "default";

type Props = {
  url?: string;
  title: string;
  status?: { color: StatusType; text: string };
  onFullLogsClick?: $TSFixMeFunction;
  onClose?: $TSFixMeFunction;
  onSearch?: $TSFixMeFunction;
  searchValue?: string;
  prefix?: string;
  statusComponent?: JSX.Element;
  exportLogs?: () => Promise<void>;
  exportLogLoading?: boolean;
  exportDisabled: boolean;
  extra?: React.JSX.Element;
  exportDisabledMessage: string;
};

export const useStyles = makeStyles((theme) => ({
  chipStyles: {
    textTransform: "capitalize"
  },
  error: {
    backgroundColor: "#fff2f1",
    borderColor: "#f74c54",
    color: "#f74c54"
  },
  inactive: {},
  warning: {
    backgroundColor: "#fff2f1",
    borderColor: "#c77700",
    color: "#c77700"
  },
  success: {
    backgroundColor: "#ddfbe5",
    borderColor: "#1fa045",
    color: "#1fa045"
  },
  default: {
    backgroundColor: "#ecf5ff",
    borderColor: "#0844cf",
    color: "#0844cf"
  },
  headerWrap: {
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "44px",
    maxHeight: "44px",
    padding: "0 8px 0 16px"
  },
  headerTitle: {
    padding: "0 5px",
    margin: "0",
    fontSize: "18px",
    textTransform: "capitalize",
    display: "flex"
  },
  linkButton: {
    height: "20px",
    width: "20px",
    minWidth: "20px",
    marginLeft: "10px",
    color: "#008FE4"
  },
  closeIcon: {
    color: "#003656",
    width: "20px",
    height: "20px",
    alignSelf: "center",
    "&::before": {
      content: "",
      width: "1px",
      height: "35px",
      background: "#000",
      opacity: "0.2"
    }
  },
  searchStyles: {
    height: "32px",
    width: "195px",
    "& > div": { height: "30px" }
  },
  title: {
    maxWidth: "160px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  moreAction: {
    minHeight: 28,
    padding: theme.spacing(2)
  },
  menuItemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(1)
  },
  loader: { color: theme.palette.text.secondary }
}));

export const ShowLogsDrawerTitle = ({
  url,
  prefix,
  status,
  title,
  searchValue,
  statusComponent,
  onFullLogsClick,
  onClose,
  onSearch,
  exportLogs,
  extra,
  exportLogLoading,
  exportDisabled,
  exportDisabledMessage
}: Props) => {
  const classes = useStyles();

  const goToLogsPage = () => {
    setAnchorEl(null);
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onDropDownClose = () => {
    setAnchorEl(null);
  };

  const onExportClick = async () => {
    if (exportLogs) {
      await exportLogs();
    }
    setAnchorEl(null);
  };

  return (
    <NewThemeWrapper>
      <Grid container direction="row" className={classes.headerWrap}>
        <Typography className={classes.headerTitle} color="textPrimary" test-id="logsSideBar">
          <Tooltip title={`Logs ${title ? `- ${title}` : ""}`}>
            <span
              className={
                classes.title
              }>{`${prefix ? prefix : ""} Logs ${title ? `- ${title}` : ""}`}</span>
          </Tooltip>
        </Typography>
        <Box display="flex" sx={{ justifyContent: "flex-end", gridGap: 8 }}>
          {typeof searchValue === "string" && !!onSearch && (
            <SearchField
              placeholder="Search"
              value={searchValue}
              onChange={onSearch}
              className={classes.searchStyles}
              size="small"
            />
          )}
          {onFullLogsClick && status && (
            <BarButton
              id="full-logs-button"
              main
              variant="contained"
              label="View Full Log"
              onClick={onFullLogsClick}
            />
          )}
          {status &&
            (statusComponent ? (
              statusComponent
            ) : (
              <Chip
                variant="outlined"
                className={`${classes.chipStyles} ${classes[status?.color]}`}
                label={<Typography variant="caption">{status?.text}</Typography>}
              />
            ))}
          {extra}
          <IconButton
            color="primary"
            size="small"
            onClick={onOpen}
            data-testid="subTopNavBarMoreAction">
            <MoreVertRoundedIcon opacity={0.5} />
          </IconButton>
          {Boolean(anchorEl) && (
            <Menu
              open
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              onClose={onDropDownClose}
              PaperProps={{
                style: {
                  marginTop: 6,
                  width: 225,
                  borderRadius: theme.spacing(1.5)
                }
              }}>
              <Tooltip key={"export"} title={exportDisabled ? exportDisabledMessage : "Export"}>
                <span>
                  <MenuItem
                    onClick={onExportClick}
                    disabled={exportDisabled}
                    data-testid={"exportLogs"}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      {<ExportIcon viewBox="0 0 22 24" />}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Export"}
                      primaryTypographyProps={{ variant: "body2" }}
                    />
                    {exportLogLoading && (
                      <ListItemSecondaryAction>
                        <CircularProgress size={18} color="inherit" className={classes.loader} />
                      </ListItemSecondaryAction>
                    )}
                  </MenuItem>
                </span>
              </Tooltip>
              {url && (
                <Tooltip key={"opem-in-new-tab"} title="Open in New Tab">
                  <span>
                    <MenuItem onClick={goToLogsPage} data-testid={"openInNewTab"}>
                      <ListItemIcon className={classes.menuItemIcon}>
                        <OpenInNewIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Open in New Tab"}
                        primaryTypographyProps={{ variant: "body2" }}
                      />
                    </MenuItem>
                  </span>
                </Tooltip>
              )}
            </Menu>
          )}

          {onClose && (
            <IconButton color="primary" onClick={onClose} size="small">
              <CloseRoundedIcon opacity={0.5} />
            </IconButton>
          )}
        </Box>
      </Grid>
    </NewThemeWrapper>
  );
};
