import React from "react";

// Packages
import { Link } from "react-router-dom";
import { useFormContext } from "react-hook-form";

// MUI
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";

// Open API
import { ProjectDashboardDto } from "@rapidcanvas/rc-api-core";

// Components
import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";

// Constants
import {
  ScenarioConfig,
  ScenarioFormFields,
  ScenarioHelperText
} from "../utils/Scenario.constants";

type Props = {
  isReadOnly?: boolean;
  scenarioId?: string;
  project: ProjectDashboardDto;
  updateScenario: (name?: string) => void;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { isReadOnly, scenarioId, project, updateScenario } = props || {};

  const { watch, setValue } = useFormContext();

  const scenarioName = watch(ScenarioFormFields.Name);

  const onScenarioNameChange = (newName: string) => {
    !!scenarioId ? updateScenario(newName) : setValue(ScenarioFormFields.Name, newName);
  };

  const breadcrumbs: React.ReactNode[] = [];

  if (!!project?.id) {
    breadcrumbs.push(
      <Link
        key="projects"
        to={`/projects/${project?.id}/canvas`}
        data-testid="scenarioBreadcrumbProjectName">
        <RenderText>{project?.name || ScenarioHelperText.Project}</RenderText>
      </Link>
    );

    breadcrumbs.push(
      <Link
        key="scenarios"
        to={`/projects/${project?.id}/scenarios`}
        data-testid="scenarioBreadcrumbScenarios">
        <RenderText>{ScenarioHelperText.Scenarios}</RenderText>
      </Link>
    );

    breadcrumbs.push(
      <EditName
        key="editScenarioName"
        fieldNameLabel="scenario name"
        inputValue={scenarioName}
        onSave={onScenarioNameChange}
        isLoading={!!isReadOnly} // isLoading prop here serves for disabled prop of TextField.
        containerProps={{
          style: {
            width:
              ((scenarioName || "")?.length + 1) * (ScenarioConfig.ScenarioNameFontSize - 4) + 16
          }
        }}
        data-testid="scenarioBreadcrumbScenarioName"
      />
    );
  } else {
    breadcrumbs.push(
      <RenderText key="projects" color="textSecondary" data-testid="scenarioBreadcrumbProjectName">
        {project?.name || ScenarioHelperText.Project}
      </RenderText>
    );

    breadcrumbs.push(
      <RenderText key="dataset" color="textSecondary" data-testid="scenarioBreadcrumbScenarios">
        {ScenarioHelperText.Scenarios}
      </RenderText>
    );

    breadcrumbs.push(
      <RenderText key="dataset" color="textSecondary" data-testid="scenarioBreadcrumbScenario">
        {ScenarioHelperText.Scenario}
      </RenderText>
    );
  }

  return (
    <Breadcrumbs data-testid="segmentBreadcrumbsContainer">
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
