import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";

import { PlusIcon } from "src/icons/NewUX/PlusIcon";
import {
  ADD_DATAAPP_TOOLTIP,
  DataAppsSplashSection,
  IMPORT_TOOLTIP
} from "../../utils/DataApps.constants";

interface IProps {
  onClick: (type: "auto-create" | "import-zip") => void;
}

const AddDataAppButton: React.FC<IProps> = (props) => {
  const { onClick } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdd = (type: "auto-create" | "import-zip") => {
    onClick(type);
    handleClose();
  };

  return (
    <>
      <IconButton color="primary" size="small" onClick={handleClick} data-testid="addDataAppIcon">
        <PlusIcon width={28} height={28} />
      </IconButton>
      <Menu
        data-testid="add-dataApp"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        getContentAnchorEl={null}
        onClick={handleClose}
        onClose={handleClose}>
        <Tooltip title={ADD_DATAAPP_TOOLTIP}>
          <MenuItem
            style={{ display: "flex", gap: "10px" }}
            onClick={() => handleAdd("auto-create")}>
            {DataAppsSplashSection.actionLabel}
          </MenuItem>
        </Tooltip>
        <Tooltip title={IMPORT_TOOLTIP}>
          <MenuItem
            style={{ display: "flex", gap: "10px" }}
            onClick={() => handleAdd("import-zip")}>
            {DataAppsSplashSection.importLabel}
          </MenuItem>
        </Tooltip>
      </Menu>
    </>
  );
};

export default AddDataAppButton;
