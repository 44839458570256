import { useQuery } from "@tanstack/react-query";

import { getAPI } from "src/utils/apiService";
import { ChatbotPropertyDto } from "@rapidcanvas/rc-api-core";

export const useGetChatbotProperties = ({ id }: { id?: string }) => {
  return useQuery<ChatbotPropertyDto>({
    queryKey: ["license"],
    queryFn: () => {
      return getAPI(`/chatbot-property/${id}`, {}, false);
    },
    enabled: !!id
  });
};
