import { CodeCheckpointDto, CreateCodeCheckpointDto } from "@rapidcanvas/rc-api-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";
import { CODE_CHECKPOINTS_KEY } from "./useGetCodeCheckpoints";
import _ from "lodash";

export const useSaveCodeCheckpoints = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ request }: { request: CreateCodeCheckpointDto }) => {
      const response = await api.fetchResponse(() =>
        api.CodeCheckpointControllerApi.create7(request)
      );
      queryClient.setQueryData(
        ["recipe", request.recipeId!, CODE_CHECKPOINTS_KEY],
        (prevData: CodeCheckpointDto[] | undefined) => _.concat(prevData || [], response)
      );
      return response;
    }
  });
};
