import React from "react";

// Packages
import { generatePath, Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";
import { WebPaths } from "src/routing/routes";

type Props = {
  project: $TSFixMe;
  jobData: $TSFixMe;
  jobRunName: string;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, jobData, jobRunName } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="projects" to={generatePath(`${WebPaths.Dag}/canvas`, { projectId: project?.id })}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="project-name" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    !!project?.id && (
      <Link key="jobs" to={generatePath(WebPaths.JobRoutes, { projectId: project?.id })}>
        <RenderText>Schedulers</RenderText>
      </Link>
    )
  );

  breadcrumbs.push(
    !!project?.id && !!jobData?.id ? (
      <Link
        key="job-name"
        to={generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, {
          projectId: project?.id,
          jobId: jobData?.id
        })}>
        <RenderText>{jobData?.name || "Scheduler"}</RenderText>
      </Link>
    ) : (
      <RenderText key="job-name" color="textSecondary">
        {jobData?.name || "Scheduler"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    <RenderText
      key="job-canvas"
      color="textSecondary">{`Run Name ${jobRunName} - Canvas View`}</RenderText>
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
