// Packages
import { useDispatch } from "react-redux";

// Utils
import api from "utils/AxiosClient";

// Open API
import { useMutation, UseMutationResult } from "@tanstack/react-query";

// Stores
import { resetEnvironmentStatus } from "src/stores/environmentsSlice";

interface IPayload {
  id?: string;
}

const useStopEnvironment = (): UseMutationResult<unknown, unknown, IPayload, unknown> => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ id }: IPayload) => {
      if (!id) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(async () => await api.EnvControllerApi.stopEnv(id, true));
    },
    onSettled: (__1, __2, payload) => {
      if (!!payload?.id) {
        dispatch(resetEnvironmentStatus({ id: payload.id! }));
      }
    }
  });
};

export default useStopEnvironment;
