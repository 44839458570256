import React, { useCallback, useMemo, useRef, useState } from "react";

// Packages
import { difference, filter, includes, isEmpty, toLower } from "lodash";

// MUI
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

// Open API
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Hooks
import { useGetEnvironmentLogs } from "src/hooks/api";

// Components
import { LogsContent } from "pages/Projects/common/ShowLogsModal/LogsContent";
import Header from "./Header";

// Constants
import {
  EnvironmentsHelperText,
  EnvironmentLogModes
} from "../../Environments/utils/Environments.constants";

const useStyles = makeStyles({
  drawer: {
    width: 600,
    flexShrink: 0
  },
  drawerPaper: {
    width: 600,
    backgroundColor: "#f5f5f5"
  }
});

interface IProps {
  open: boolean;
  environment: EnvDto;
  onClose: () => void;
}

const EnvironmentLogs: React.FC<IProps> = (props) => {
  const { open, environment, onClose } = props;

  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");

  const isLiveLogs = useMemo(
    () =>
      includes(
        [
          EnvDtoLaunchStatusEnum.Launching,
          EnvDtoLaunchStatusEnum.Stopping,
          EnvDtoLaunchStatusEnum.Success
        ],
        environment?.launchStatus
      ),
    [environment?.launchStatus]
  );

  const { isLoading, isFetching, isFetched, data, error } = useGetEnvironmentLogs({
    id: environment?.id,
    enabled: !!open,
    mode: !!isLiveLogs ? EnvironmentLogModes.Live : EnvironmentLogModes.Offline,
    refetchInterval: !!isLiveLogs ? 2000 : false
  });

  const errorMessage = useMemo(
    () =>
      error
        ? // @ts-ignore
          error?.response?.data?.msg || error?.message || EnvironmentsHelperText.SomethingWentWrong
        : undefined,
    [error]
  );

  const onSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value || "";
    setSearchValue(() => value);
  }, []);

  const previousLogsRef = useRef<string[]>([]);

  const filteredLogs = useMemo(() => {
    if (!data) return previousLogsRef.current;

    // Find new logs by comparing the current and previous data
    const newLogs = difference(data, previousLogsRef.current);

    // Update the ref with all logs seen so far
    previousLogsRef.current = [...previousLogsRef.current, ...newLogs];

    // Filter logs based on searchValue
    return filter(previousLogsRef.current, (item: string) =>
      !!searchValue ? includes(toLower(item), toLower(searchValue)) : true
    );
  }, [data, searchValue]);

  return (
    <Drawer
      open={open}
      anchor="right"
      variant="temporary"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      onClose={onClose}>
      <Header
        open={open}
        environment={environment}
        onClose={onClose}
        searchValue={searchValue}
        onSearch={onSearch}
      />
      <Box mt="44px">
        {(!!isLoading || !!isFetching || !isFetched) && isEmpty(filteredLogs) ? (
          <LogsContent logs={[EnvironmentsHelperText.LogsFetchingInfo]} />
        ) : (
          <LogsContent logs={filteredLogs} {...(errorMessage ? { info: errorMessage } : {})} />
        )}
      </Box>
    </Drawer>
  );
};

export default EnvironmentLogs;
