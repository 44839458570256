import axios from "axios";
import { ReactNode } from "react";
import { includes } from "lodash";

import EventBus from "./EventBus";
import api from "./AxiosClient";
import { Action } from "src/components/ToastifyAlert/ToastContent";
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";
import { toastWrapper } from "./toastWrapper";

const NO_RECORD_FOUND_MSG = "No record found for the given";
const ACCESS_DENIED_MSG = "You do not have access to this resource. Please contact platform admin";

const client = api.client;

export const getErrorMessage = api.getErrorMessage;
const watchNotifications = api.watchNotifications;
export const handleErrorWithRedirectToLogs = api.handleErrorWithRedirectToLogs;

const checkAccess = (message: string, error: any) => {
  if (includes(message, ACCESS_DENIED_MSG) && error?.response?.status === 401) {
    EventBus.publish(EVENTBUS_EVENTS.AccessDenied);
    return true;
  }
  return false;
};

export const getAPI = async (
  url: string,
  options?: $TSFixMe,
  shouldDispatchEvent: boolean = true
) => {
  try {
    const { headers, ...restOptions } = options || {};

    const result = await client(url, { headers, ...restOptions });
    return result.data;
  } catch (error: any) {
    // The /env-alerts API functions which is a logging tool, does not require notifying the user directly on the page if it fails (especially with unauthorized).
    // Therefore, it can be bypassed in cases of unauthorized access.
    if (error?.response?.status === 401 && includes(url, "/env-alerts")) {
      return;
    }

    const message = getErrorMessage(error);
    if (checkAccess(message, error)) {
      return;
    }

    if (includes(message, NO_RECORD_FOUND_MSG) && shouldDispatchEvent) {
      EventBus.publish(EVENTBUS_EVENTS.RecordNotFound);
    } else {
      shouldDispatchEvent && handleErrorWithRedirectToLogs(getErrorMessage(error));
    }

    console.error({ error });
  }
};

export const getAPIWithRethrow = async (
  url: string,
  options?: $TSFixMe,
  shouldDispatchEvent: boolean = true
) => {
  try {
    const { ...restOptions } = options || {};
    let headers: $TSFixMe = {};

    if (options?.signedUrl) {
      headers =
        Object.keys(options?.headers || {}).length === 0
          ? { ...headers, "Content-Type": "" }
          : { ...headers, ...options?.headers };
    } else {
      headers = {
        ...headers,
        ...(options?.headers || {})
      };
    }

    const axiosClient = options?.signedUrl ? axios : client;
    delete options?.signedUrl;
    delete options?.headers;

    const result = await axiosClient(url, { headers, ...restOptions });

    return result.data;
  } catch (error: any) {
    const message = getErrorMessage(error);

    if (checkAccess(message, error)) {
      return;
    }

    if (includes(message, NO_RECORD_FOUND_MSG) && shouldDispatchEvent) {
      EventBus.publish(EVENTBUS_EVENTS.RecordNotFound);
    } else {
      shouldDispatchEvent && handleErrorWithRedirectToLogs(getErrorMessage(error));
    }

    console.error({ error });

    throw error;
  }
};

export const postAPI = async (url: string, data: $TSFixMe, shouldDispatchEvent: boolean = true) => {
  watchNotifications();
  try {
    const result = await client(url, {
      method: "post",
      data
    });
    return result.data;
  } catch (error: any) {
    const message = getErrorMessage(error);
    if (checkAccess(message, error)) {
      return;
    }
    shouldDispatchEvent && handleErrorWithRedirectToLogs(message);

    console.error({ error });
  } finally {
    watchNotifications();
  }
};

export const postAPIWithRethrow = async (
  url: string,
  data: $TSFixMe,
  options?: $TSFixMe,
  shouldDispatchEvent = true
) => {
  watchNotifications();
  try {
    const result = await client(url, {
      method: "post",
      data,
      ...options
    });
    return result.data;
  } catch (error: any) {
    if (axios.isCancel(error) || error?.message === "canceled") {
      console.log("Request canceled", error.message);
      return;
    }

    const message = getErrorMessage(error);

    if (checkAccess(message, error)) {
      return;
    }

    shouldDispatchEvent && handleErrorWithRedirectToLogs(getErrorMessage(error));
    console.error({ error });
    throw error;
  } finally {
    watchNotifications();
  }
};

export const putAPI = async (url: string, data: $TSFixMe, shouldHideNotifications?: boolean) => {
  watchNotifications(shouldHideNotifications);
  try {
    const result = await client(url, {
      method: "put",
      data
    });
    return result.data;
  } catch (error: any) {
    const message = getErrorMessage(error);
    if (checkAccess(message, error)) {
      return;
    }

    handleErrorWithRedirectToLogs(message);

    console.error({ error });
  } finally {
    watchNotifications(shouldHideNotifications);
  }
};

export const putAPIWithRethrow = async (
  url: string,
  data: $TSFixMe,
  options?: $TSFixMe,
  shouldDispatchEvent = true
) => {
  watchNotifications();
  try {
    let headers: $TSFixMe = {};

    if (options?.signedUrl) {
      if (Object.keys(options?.headers || {})?.length === 0) {
        headers = { ...headers, ...{ "Content-Type": "" } };
      } else {
        headers = { ...headers, ...options?.headers };
      }
    } else {
      headers = {
        ...headers,
        ...(options?.headers || {})
      };
    }

    const axiosClient = options?.signedUrl ? axios : client;
    delete options?.signedUrl;
    delete options?.headers;
    const result = await axiosClient(url, {
      method: "put",
      data,
      headers,
      ...options
    });
    return result.data;
  } catch (error: any) {
    const message = getErrorMessage(error);
    if (checkAccess(message, error)) {
      return;
    }

    shouldDispatchEvent && handleErrorWithRedirectToLogs(message);

    console.error({ error });

    throw error;
  } finally {
    watchNotifications();
  }
};

export const patchAPI = async (url: string, data: $TSFixMe) => {
  watchNotifications();
  try {
    const result = await client(url, {
      method: "patch",
      data
    });
    return result.data;
  } catch (error: any) {
    const message = getErrorMessage(error);
    if (checkAccess(message, error)) {
      return;
    }
    handleErrorWithRedirectToLogs(message);

    console.error({ error });
  } finally {
    watchNotifications();
  }
};

export const patchAPIWithRethrow = async (
  url: string,
  data: $TSFixMe,
  options?: $TSFixMe,
  shouldDispatchEvent = true
) => {
  watchNotifications();
  try {
    const result = await client(url, {
      method: "patch",
      data,
      ...options
    });
    return result.data;
  } catch (error: any) {
    const message = getErrorMessage(error);
    if (checkAccess(message, error)) {
      return;
    }
    shouldDispatchEvent && handleErrorWithRedirectToLogs(message);

    console.error({ error });

    throw error;
  } finally {
    watchNotifications();
  }
};

export const deleteAPI = async (url: string, data: $TSFixMe) => {
  try {
    const result = await client(url, {
      method: "delete",
      data
    });
    return result.data;
  } catch (error: any) {
    const message = getErrorMessage(error);
    if (checkAccess(message, error)) {
      return;
    }
    handleErrorWithRedirectToLogs(message);

    console.error({ error });
  }
};

export const deleteAPIWithRethrow = async (
  url: string,
  data: $TSFixMe,
  options?: $TSFixMe,
  shouldDispatchEvent = true
) => {
  try {
    const result = await client(url, {
      method: "delete",
      data,
      ...options
    });
    return result.data;
  } catch (error: any) {
    const message = getErrorMessage(error);
    if (checkAccess(message, error)) {
      return;
    }
    shouldDispatchEvent && handleErrorWithRedirectToLogs(message);

    console.error({ error });

    throw error;
  }
};

export const handleSuccessWithRedirectToLogs = (
  successMsg: string,
  actions?: Action[],
  extraProps = {}
) => {
  toastWrapper({
    ...extraProps,
    type: "success",
    content: successMsg,
    actions
  });
};

export const handleResponse = ({
  errorMessage,
  successMessage
}: {
  successMessage?: string | ReactNode;
  errorMessage?: string | ReactNode;
}) => {
  const hasError = Boolean(errorMessage);
  const message = hasError ? errorMessage : successMessage;
  if (message !== undefined) {
    toastWrapper({
      type: hasError ? "error" : "success",
      content: message
    });
  }
};
