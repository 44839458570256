import { Box, Grid, IconButton, makeStyles, Tab, Tabs, Tooltip } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useCodeRecipeContext } from "../CodeRecipeContext/useCodeRecipeContext";
import { filter, map } from "lodash";
import { OverflowTooltip, TabPanel } from "src/components";
import { CodeRecipeCodeEditor } from "./CodeRecipeCodeEditor/CodeRecipeCodeEditor";
import ReadOnlyCodeRecipe from "./ReadOnlyCodeRecipe/ReadOnlyCodeRecipe";
import { useParams } from "react-router";
import { CloseOutlined, InfoOutlined } from "@material-ui/icons";
import { AiAssistedIcon } from "src/icons/NewUX/AiAssistedRecipeIcon";
import NewThemeWrapper from "src/styles/NewThemeWrapper";

const useStyles = makeStyles({
  tabDetails: {
    padding: "0 16px",
    height: "100%"
  },
  codeEditorWrap: {
    border: "1px solid #C5C5C5",
    height: "100%"
  },

  codeTab: {
    textTransform: "none",
    background: "white",
    margin: "10px 10px 0 0",
    padding: "6px 2px 6px 12px",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px"
  },
  currentCodeTab: {
    textTransform: "none",
    background: "white",
    margin: "10px 10px 0 0",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px"
  },
  codeTabList: {
    marginLeft: "16px",
    marginRight: "16px",
    minHeight: "30px",
    gap: "5px",
    width: "calc( 100% - 32px)",
    "& .Mui-selected": {
      background: "#E7E7FF"
    }
  },
  tabHeader: {
    flexWrap: "nowrap",
    justifyContent: "space-between",
    paddingRight: "16px"
  }
});

interface IProps {
  isDefaultScenario: boolean;
  insertedCode: string | null;
  setInsertedCode: Dispatch<SetStateAction<string | null>>;
  actionsContainer?: React.ReactNode;
  readonly?: boolean;
}

const CodeRecipeCodesTab: React.FC<IProps> = (props) => {
  const { isDefaultScenario, readonly, setInsertedCode, insertedCode, actionsContainer } = props;
  const [value, setValue] = useState<string>("current");

  const { scenarioId } = useParams();
  const classes = useStyles();

  const {
    editorValue,
    codeErrorDetails,
    setSelectedCodeRecipe,
    setEditorValue,
    handleTest,
    selectedCodeRecipe,
    currentSelectedCodeRecipe,
    setCurrentSelectedCodeRecipe,
    isSaveDisabled,
    isSaveInProgress,
    handleSave
  } = useCodeRecipeContext();

  const handleChange = (__: any, newValue: string) => {
    if (value === newValue) return;
    setValue(newValue);
  };

  useEffect(() => {
    if (currentSelectedCodeRecipe) {
      setValue(currentSelectedCodeRecipe);
    } else {
      setValue("current");
    }
  }, [currentSelectedCodeRecipe]);

  const handleClose = (index: string) => {
    setSelectedCodeRecipe(filter(selectedCodeRecipe, (item) => item.id !== index));
    if (currentSelectedCodeRecipe === index) {
      setCurrentSelectedCodeRecipe(undefined);
    }
  };

  const triggerSaveCode = useCallback(
    (code: string) => {
      const canSaveRecipe = !isSaveDisabled && isDefaultScenario && !isSaveInProgress;
      canSaveRecipe && handleSave({ code, isAutoSaving: true });
    },
    [isSaveDisabled, isDefaultScenario, isSaveInProgress, handleSave]
  );

  return (
    <NewThemeWrapper>
      <Grid container direction="row" className={classes.tabHeader}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.codeTabList}
          TabIndicatorProps={{
            style: { background: "#4646b5", marginRight: "16px" }
          }}>
          <Tab
            test-id="code-recipe-ask-ai-editor-tab"
            key="current"
            value="current"
            label={
              <Grid container style={{ gap: "5px" }} alignItems="center" justifyContent="center">
                <span>Current</span>
                <Tooltip title="Type entity names to get auto suggestions">
                  <InfoOutlined style={{ fontSize: "14px" }} />
                </Tooltip>
              </Grid>
            }
            className={classes.currentCodeTab}
          />
          {map(selectedCodeRecipe, (recipe) => {
            return (
              <Tab
                test-id={`code-recipe-code-editor-tab-${recipe.displayName}`}
                key={recipe.id}
                label={
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid
                      container
                      item
                      alignItems="center"
                      style={{
                        gap: "5px",
                        width: "calc(100% - 50px)",
                        flexWrap: "nowrap"
                      }}>
                      <Grid item style={{ width: "16px" }}>
                        <AiAssistedIcon viewBox="0 0 20 20" />
                      </Grid>
                      <Grid item xs>
                        <OverflowTooltip
                          value={recipe.displayName}
                          title={recipe.displayName}
                          style={{ whiteSpace: "nowrap" }}
                        />
                      </Grid>
                    </Grid>
                    <IconButton
                      test-id={`code-recipe-code-editor-close-tab-btn-${recipe.displayName}`}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClose(recipe.id);
                      }}
                      style={{
                        padding: "2px"
                      }}>
                      <CloseOutlined fontSize="small" />
                    </IconButton>
                  </Grid>
                }
                value={recipe.id}
                className={classes.codeTab}
              />
            );
          })}
        </Tabs>
        {actionsContainer}
      </Grid>
      <TabPanel
        key="current"
        value={value}
        index="current"
        boxProps={{ height: "100%" }}
        style={{ height: "calc(100% - 65px)" }}>
        <Grid className={classes.tabDetails}>
          <Grid className={classes.codeEditorWrap}>
            <Box height="calc(100% - 2px)" mt="1px" mb="4px">
              <CodeRecipeCodeEditor
                disabled={!isDefaultScenario || readonly}
                editorValue={editorValue}
                setEditorValue={setEditorValue}
                insertedCode={insertedCode}
                resetInsertedCode={() => setInsertedCode(null)}
                errDetails={codeErrorDetails}
                onControlT={(val) => handleTest(undefined, val)}
                onEditorBlur={triggerSaveCode}
              />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
      {map(selectedCodeRecipe, (recipe) => {
        return (
          <TabPanel
            key={recipe.id}
            value={value}
            index={recipe.id}
            boxProps={{ height: "100%" }}
            style={{ height: "calc(100% - 65px)" }}>
            <Grid className={classes.tabDetails}>
              <Grid className={classes.codeEditorWrap}>
                <Box height="calc(100% - 2px)" mt="1px" mb="4px">
                  <ReadOnlyCodeRecipe recipeId={recipe.id} scenarioId={scenarioId} />
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
        );
      })}
    </NewThemeWrapper>
  );
};

export default CodeRecipeCodesTab;
