import React, { useMemo, useState } from "react";
import _, { includes, isEmpty, pickBy } from "lodash";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@material-ui/core";

import CreateDataAppInputs from "./CreateDataAppInputs";
import DataAppAccess from "./DataAppAccess";
import DataAppModelControls from "./DataAppModelControls";
import DataAppSelectJob from "./DataAppSelectJob";
import EnableResponseCache from "./EnableResponseCache";
import SelectAskAIInputType from "./SelectAskAIInputType";
import SelectAskAILLMModel from "./SelectAskAILLMModel";
import SelectDataAppSecurity from "./SelectDataAppSecurity";
import {
  DataappAskAIConfigInputTypeEnum,
  DataappAskAIConfigLlmTypeEnum,
  CreateDataAppRequestDtoDataAppTypeEnum,
  DataappAskAIConfigDataSharingStrategyEnum,
  DataappAskAIConfig,
  PublishAppTemplateRequestDtoAppTypeEnum,
  PublishAppTemplateRequestDtoSourceEnum,
  AppTemplateDto
} from "@rapidcanvas/rc-api-core";
import { IRecipes } from "./CreateDataApp";
import { handleResponse } from "src/utils/apiService";
import useUploadDataAppZip from "src/hooks/api/dataapps/useUploadDataAppZip";
import { useCreateDataAppMutation } from "src/hooks/api/dataapps/useCreateDataAppMutation";
import { useForm } from "src/utils/useForm";
import { useParams } from "react-router-dom";
import { validateNameField } from "src/utils/formFieldUtils";
import {
  DescriptionCharacterLimit,
  DescriptionCharacterLimitMessage
} from "src/pages/private/ProjectsModule/utils";
import DataAppViewCode from "./DataAppViewCode";
import { EnvironmentsTypes } from "src/constants/environments.constants";
import { validateDataAppEnvConfig } from "./DataAppEnvironment";
import ColumnHyperlinks from "./ColumnHyperlinks";

interface IProps {
  recipes: IRecipes[];
  appTemplates?: AppTemplateDto[];
  askAIDataAppTemplateId?: string;
  recipeLoading: boolean;
  dataAppType: "auto-create" | "import-zip";
  disabledAddDataAppActionMessage?: string;
  disabledAskAICreateActionMessage?: string;
  onBack: () => void;
  onSuccess: () => void;
}

export const dataAppConfigFields = {
  llmType: "llmType",
  inputType: "inputType",
  dataAppName: "dataAppName",
  description: "description",
  imageBase64: "imageBase64",
  recipeId: "recipeId",
  systemMessage: "systemMessage",
  dataSharingStrategy: "dataSharingStrategy",
  enableCache: "enableCache",
  customEnvId: "customEnvId",
  isPrivate: "isPrivate",
  projectRunId: "projectRunId",
  showAICode: "showAICode",
  zipEnv: "zipEnv",
  zipFile: "zipFile",
  appType: "appType",
  metadata: "metadata",
  allowColumnHyperLink: "allowColumnHyperLink",
  columnsHyperlinksMapping: "columnsHyperlinksMapping"
};

const CreateDataAppForm: React.FC<IProps> = (props) => {
  const {
    recipes,
    appTemplates,
    askAIDataAppTemplateId,
    recipeLoading,
    dataAppType,
    onSuccess,
    onBack
  } = props;

  const { projectId } = useParams();
  const createDataAppMutation = useCreateDataAppMutation();
  const uploadZip = useUploadDataAppZip();
  const allowedRecipes = useMemo(() => recipes?.filter((recipe) => recipe.allowed), [recipes]);
  const [hyperlinkError, setHyperlinkError] = useState<string>("");

  const { values, handleInputChange, setValues } = useForm({
    [dataAppConfigFields.dataAppName]: "",
    [dataAppConfigFields.description]: "",
    [dataAppConfigFields.recipeId]: allowedRecipes?.length === 1 ? allowedRecipes?.[0]?.id : "",
    [dataAppConfigFields.llmType]: DataappAskAIConfigLlmTypeEnum.OpenaiGpt4O,
    [dataAppConfigFields.inputType]: DataappAskAIConfigInputTypeEnum.ProjectCanvas,
    [dataAppConfigFields.dataSharingStrategy]: DataappAskAIConfigDataSharingStrategyEnum.SampleData,
    [dataAppConfigFields.enableCache]: false,
    [dataAppConfigFields.isPrivate]: false,
    [dataAppConfigFields.showAICode]: true,
    [dataAppConfigFields.metadata]: "",
    [dataAppConfigFields.customEnvId]: "",
    [dataAppConfigFields.zipEnv]: {
      name: EnvironmentsTypes.Small,
      cores: "",
      diskInGbs: "",
      memInGbs: ""
    },
    [dataAppConfigFields.appType]: PublishAppTemplateRequestDtoAppTypeEnum.Streamlit,
    [dataAppConfigFields.allowColumnHyperLink]: false,
    [dataAppConfigFields.columnsHyperlinksMapping]: ""
  });

  const type = useMemo(() => {
    if (
      _.get(values, dataAppConfigFields.inputType) ===
      CreateDataAppRequestDtoDataAppTypeEnum.RapidModel
    ) {
      return CreateDataAppRequestDtoDataAppTypeEnum.RapidModel;
    } else {
      return CreateDataAppRequestDtoDataAppTypeEnum.Askai;
    }
  }, [values]);

  const errorMsgs = useMemo(() => {
    const name = _.trim(_.get(values, dataAppConfigFields.dataAppName));

    const { error } = validateNameField({
      fieldName: name,
      fieldNameLabel: "dataApp name"
    });

    return {
      [dataAppConfigFields.dataAppName]: name ? error : "",
      [dataAppConfigFields.description]:
        _.size(_.get(values, dataAppConfigFields.description)) > DescriptionCharacterLimit
          ? DescriptionCharacterLimitMessage
          : ""
    };
  }, [values]);

  const disabledTooltipMsg = useMemo(() => {
    const name = _.trim(_.get(values, dataAppConfigFields.dataAppName));

    if (props.disabledAskAICreateActionMessage) {
      return props.disabledAskAICreateActionMessage;
    }

    if (dataAppType === "import-zip") {
      if (!name) {
        return "Please input DataApp Name to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.dataAppName)) {
        return "Please use valid DataApp Name to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.description)) {
        return "Description charater limit exceeded";
      }

      if (!_.get(values, dataAppConfigFields.zipFile)) {
        return "Please upload a zip to enable";
      }

      const zipEnv = _.get(values, dataAppConfigFields.zipEnv);
      const { cores, diskInGbs, memInGbs } = zipEnv;
      if (zipEnv?.name === EnvironmentsTypes.Custom) {
        if (
          !cores ||
          !diskInGbs ||
          !memInGbs ||
          validateDataAppEnvConfig("Cores", cores, 12) ||
          validateDataAppEnvConfig("Memory", memInGbs, 48) ||
          validateDataAppEnvConfig("Disk Space", diskInGbs, 100)
        ) {
          return "Please select valid environment configuration to enable";
        }
      }

      return "";
    }

    if (type === CreateDataAppRequestDtoDataAppTypeEnum.Askai) {
      if (!name) {
        return "Please input DataApp Name to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.dataAppName)) {
        return "Please use valid DataApp Name to enable";
      }
      if (!!_.get(errorMsgs, dataAppConfigFields.description)) {
        return "Description charater limit exceeded";
      }

      if (!_.get(values, dataAppConfigFields.inputType)) {
        return "Please select an input to enable";
      }

      if (!_.get(values, dataAppConfigFields.customEnvId)) {
        return "Please wait until the environment is loaded for this DataApp's AskAI";
      }

      if (
        _.get(values, dataAppConfigFields.inputType) ===
          DataappAskAIConfigInputTypeEnum.JobCanvas &&
        !_.get(values, dataAppConfigFields.projectRunId)
      ) {
        return "Please select a scheduler to enable";
      }

      if (!!hyperlinkError) {
        return hyperlinkError;
      }

      return "";
    } else {
      const recipeId = _.get(values, dataAppConfigFields.recipeId);
      if (!name || !recipeId) {
        return "Please input DataApp Name and select Recipe to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.dataAppName)) {
        return "Please use valid DataApp Name to enable";
      }

      if (!_.get(values, dataAppConfigFields.customEnvId)) {
        return "Please wait until the environment is loaded for this DataApp's AskAI";
      }

      if (!!hyperlinkError) {
        return hyperlinkError;
      }

      return "";
    }
  }, [
    values,
    type,
    errorMsgs,
    props.disabledAskAICreateActionMessage,
    dataAppType,
    hyperlinkError
  ]);

  const handleSubmit = () => {
    if (projectId) {
      const hyperlinksStr = dataAppConfigFields.allowColumnHyperLink
        ? _.get(values, dataAppConfigFields.columnsHyperlinksMapping)
        : "";
      if (dataAppType === "import-zip") {
        let md = _.get(values, dataAppConfigFields.metadata);
        let metadata: any;
        if (md) {
          try {
            metadata = JSON.parse(md);
          } catch {
            handleResponse({
              errorMessage: "Unable to parse JSON metadata. Please use valid JSON"
            });
            return;
          }
        }

        uploadZip.mutate(
          {
            payload: {
              name: _.replace(
                `${_.get(values, dataAppConfigFields.dataAppName)}_template`,
                / /g,
                "_"
              ),
              appType: _.get(values, dataAppConfigFields.appType)
            },
            file: _.get(values, [dataAppConfigFields.zipFile, "file"]),
            source: PublishAppTemplateRequestDtoSourceEnum.Tenant,
            appType: _.get(values, dataAppConfigFields.appType)
          },
          {
            onSuccess: (id: string | null) => {
              if (!id) {
                return;
              }

              createDataAppMutation.mutate({
                dataAppName: _.get(values, dataAppConfigFields.dataAppName),
                description: _.get(values, dataAppConfigFields.description),
                appTemplateId: id,
                projectId,
                askAIConfig: {
                  allowColumnHyperLink: _.get(values, dataAppConfigFields.allowColumnHyperLink)
                },
                metadata: _.merge(
                  {},
                  metadata,
                  hyperlinksStr ? { columns_hyperlinks_mapping: hyperlinksStr } : {}
                ),
                envType: _.get(values, [dataAppConfigFields.zipEnv, "name"]),
                iconUrl: _.get(values, dataAppConfigFields.imageBase64),
                cores: _.get(values, [dataAppConfigFields.zipEnv, "cores"])
                  ? _.toNumber(_.get(values, [dataAppConfigFields.zipEnv, "cores"]))
                  : undefined,
                diskInGbs: _.get(values, [dataAppConfigFields.zipEnv, "diskInGbs"])
                  ? _.toNumber(_.get(values, [dataAppConfigFields.zipEnv, "diskInGbs"]))
                  : undefined,
                memInMbs: _.get(values, [dataAppConfigFields.zipEnv, "memInGbs"])
                  ? _.toNumber(_.get(values, [dataAppConfigFields.zipEnv, "memInGbs"])) * 1024
                  : undefined,
                dataAppType: CreateDataAppRequestDtoDataAppTypeEnum.Custom,
                onSuccess
              });
            }
          }
        );
        return;
      }

      if (type === CreateDataAppRequestDtoDataAppTypeEnum.Askai) {
        let askAIConfig: DataappAskAIConfig;
        const hyperlinksStr = dataAppConfigFields.allowColumnHyperLink
          ? _.get(values, dataAppConfigFields.columnsHyperlinksMapping)
          : "";
        if (askAIDataAppTemplateId) {
          if (
            _.get(values, dataAppConfigFields.inputType) ===
            DataappAskAIConfigInputTypeEnum.PredictionService
          ) {
            askAIConfig = {
              inputType: _.get(values, dataAppConfigFields.inputType),
              customEnvId: _.get(values, dataAppConfigFields.customEnvId),
              allowColumnHyperLink: _.get(values, dataAppConfigFields.allowColumnHyperLink)
            };
          } else {
            askAIConfig = {
              llmType: _.get(values, dataAppConfigFields.llmType),
              inputType: _.get(values, dataAppConfigFields.inputType),
              systemMessage: _.get(values, dataAppConfigFields.systemMessage),
              enableCache: _.get(values, dataAppConfigFields.enableCache),
              allowColumnHyperLink: _.get(values, dataAppConfigFields.allowColumnHyperLink),
              dataSharingStrategy:
                _.get(values, dataAppConfigFields.inputType) !==
                DataappAskAIConfigInputTypeEnum.RagFiles
                  ? _.get(values, dataAppConfigFields.dataSharingStrategy)
                  : undefined,
              customEnvId: _.get(values, dataAppConfigFields.customEnvId),
              projectRunId: _.get(values, dataAppConfigFields.projectRunId)
            };
          }

          createDataAppMutation.mutate({
            dataAppName: _.get(values, dataAppConfigFields.dataAppName),
            description: _.get(values, dataAppConfigFields.description),
            appTemplateId: askAIDataAppTemplateId,
            projectId,
            metadata: hyperlinksStr
              ? {
                  columns_hyperlinks_mapping: hyperlinksStr
                }
              : {},
            envType: EnvironmentsTypes.Small,
            iconUrl: _.get(values, dataAppConfigFields.imageBase64),
            dataAppType: type,
            askAIConfig,
            showAICode: _.get(values, dataAppConfigFields.showAICode),
            isPrivate: _.includes([true, "true"], _.get(values, dataAppConfigFields.isPrivate)),
            onSuccess
          });
        } else {
          handleResponse({
            errorMessage: "Please make sure to publish ask AI template before creating the app"
          });
        }
        return;
      }

      if (type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel) {
        const currRecipe = recipes.find(
          (recipe) => recipe.id === _.get(values, dataAppConfigFields.recipeId)
        );
        const currentAppTemplate = appTemplates?.find(
          (appTemplate: any) =>
            appTemplate.autoMlProblemType?.toLowerCase() === currRecipe?.metadata?.problemType &&
            appTemplate.buildStatus
        );
        if (!currentAppTemplate) {
          handleResponse({ errorMessage: `App Template not found for the specified recipe` });
        }

        projectId &&
          currentAppTemplate &&
          currRecipe &&
          currentAppTemplate.id &&
          createDataAppMutation.mutate({
            dataAppName: _.get(values, dataAppConfigFields.dataAppName),
            description: _.get(values, dataAppConfigFields.description),
            projectId,
            envType: EnvironmentsTypes.Small,
            iconUrl: _.get(values, dataAppConfigFields.imageBase64),
            appTemplateId: currentAppTemplate.id,
            recipeId: currRecipe.id,
            metadata: _.merge(
              {},
              currRecipe?.runConfigs?.[0]?.variables,
              hyperlinksStr ? { columns_hyperlinks_mapping: hyperlinksStr } : {}
            ),
            dataAppType: type,
            showAICode: _.get(values, dataAppConfigFields.showAICode),
            askAIConfig: {
              systemMessage: _.get(values, dataAppConfigFields.systemMessage),
              customEnvId: _.get(values, dataAppConfigFields.customEnvId),
              allowColumnHyperLink: _.get(values, dataAppConfigFields.allowColumnHyperLink)
            },
            onSuccess
          });
        return;
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((old) => ({
      ...old,
      [e.target.name]: e.target.checked
    }));
  };

  const handleInputValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ..._.omit(values, dataAppConfigFields.projectRunId),
      [e.target.name]: e.target.value,
      [dataAppConfigFields.llmType]:
        DataappAskAIConfigInputTypeEnum.RagFiles === e.target.value &&
        _.includes(
          [
            DataappAskAIConfigLlmTypeEnum.AzureOpenaiGpt4O,
            DataappAskAIConfigLlmTypeEnum.AnthropicClaude35Sonnet
          ],
          _.get(values, dataAppConfigFields.llmType)
        )
          ? DataappAskAIConfigLlmTypeEnum.OpenaiGpt4O
          : _.get(values, dataAppConfigFields.llmType)
    });
  };

  const updateHyperLinks = (parsedMapping: { [key: string]: string }) => {
    setValues((old) => ({
      ...old,
      [dataAppConfigFields.columnsHyperlinksMapping]: !isEmpty(
        pickBy(parsedMapping, (value, key) => key && value)
      )
        ? JSON.stringify(parsedMapping)
        : ""
    }));
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{
          display: "flex",
          marginBottom: "10px"
        }}>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <IconButton style={{ padding: 0 }} onClick={onBack} data-testid="dataAppBackBtn">
            <ArrowBackIcon />
          </IconButton>
          <Typography component="h3" style={{ color: "#515151" }} data-testid="dataAppConfigType">
            Configuration
          </Typography>
        </div>
        <Tooltip title={disabledTooltipMsg ?? ""}>
          <span>
            <Button
              size="small"
              disabled={
                !!disabledTooltipMsg || createDataAppMutation.isLoading || uploadZip.isLoading
              }
              variant="contained"
              startIcon={
                createDataAppMutation.isLoading || uploadZip.isLoading ? (
                  <CircularProgress size={16} />
                ) : undefined
              }
              data-testid="createDatAppBtn"
              color="primary"
              onClick={handleSubmit}>
              Create
            </Button>
          </span>
        </Tooltip>
      </Grid>
      <Grid
        container
        style={{ maxHeight: "calc(100vh - 180px)", overflow: "auto", display: "block" }}
        direction="column"
        spacing={2}>
        <Grid item xs={12}>
          <CreateDataAppInputs
            type={type}
            dataAppType={dataAppType}
            projectId={projectId}
            values={values}
            errorMsgs={errorMsgs}
            recipes={recipes}
            recipeLoading={recipeLoading}
            onChange={handleInputChange}
          />
        </Grid>
        {dataAppType === "auto-create" && (
          <Grid item container direction="row" xs={12} spacing={2} style={{ margin: 0 }}>
            <Grid item xs={"auto"} style={{ minHeight: "100%", width: "315px", paddingLeft: 0 }}>
              <Card style={{ background: "#fff", padding: "16px", height: "100%" }}>
                <SelectAskAIInputType
                  disabledAddDataAppActionMessage={props.disabledAddDataAppActionMessage}
                  value={_.get(values, dataAppConfigFields.inputType)}
                  onChange={handleInputValChange}
                />
              </Card>
            </Grid>
            {_.get(values, dataAppConfigFields.inputType) !==
              DataappAskAIConfigInputTypeEnum.PredictionService && (
              <Grid item xs style={{ padding: "8px 0px 8px 8px" }}>
                <Card style={{ background: "#fff", padding: "16px", height: "100%" }}>
                  <Grid container direction="row">
                    <Grid
                      item
                      xs={
                        _.get(values, dataAppConfigFields.inputType) ===
                        DataappAskAIConfigInputTypeEnum.JobCanvas
                          ? 9
                          : 7
                      }
                      style={{
                        minHeight: "100%",
                        padding: "0px 16px 0px 0px",
                        display: "flex",
                        flex: "column",
                        gap: "24px",
                        flexDirection: "column"
                      }}>
                      {_.get(values, dataAppConfigFields.inputType) ===
                        DataappAskAIConfigInputTypeEnum.JobCanvas && (
                        <DataAppSelectJob
                          projectId={projectId!}
                          value={_.get(values, dataAppConfigFields.projectRunId)}
                          onChange={handleInputChange}
                        />
                      )}
                      {_.get(values, dataAppConfigFields.inputType) !==
                        CreateDataAppRequestDtoDataAppTypeEnum.RapidModel && (
                        <SelectAskAILLMModel
                          inputType={_.get(values, dataAppConfigFields.inputType)}
                          value={_.get(values, dataAppConfigFields.llmType)}
                          onChange={handleInputChange}
                        />
                      )}
                      <DataAppModelControls values={values} onChange={handleInputChange} />
                    </Grid>

                    <Grid
                      item
                      xs={
                        _.get(values, dataAppConfigFields.inputType) ===
                        DataappAskAIConfigInputTypeEnum.JobCanvas
                          ? 3
                          : 5
                      }
                      style={{
                        minHeight: "100%",
                        padding: "4px 16px",
                        borderLeft: "1px solid #e5e5e5",
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px"
                      }}>
                      {_.get(values, dataAppConfigFields.inputType) !==
                      CreateDataAppRequestDtoDataAppTypeEnum.RapidModel ? (
                        <>
                          <EnableResponseCache
                            value={_.get(values, dataAppConfigFields.enableCache)}
                            onChange={handleChange}
                          />
                          <DataAppAccess
                            value={_.get(values, dataAppConfigFields.isPrivate)}
                            onChange={handleInputChange}
                          />
                          <DataAppViewCode
                            value={_.get(values, dataAppConfigFields.showAICode)}
                            onChange={handleChange}
                          />
                          {_.get(values, dataAppConfigFields.inputType) !==
                            DataappAskAIConfigInputTypeEnum.RagFiles && (
                            <SelectDataAppSecurity
                              value={_.get(values, dataAppConfigFields.dataSharingStrategy)}
                              onChange={handleInputChange}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <h3 style={{ fontSize: "1.25rem" }}>Model DataApp</h3>
                          <p
                            data-testid="dataAppTypeDescription"
                            style={{ color: "#64748B", fontSize: "16px", marginBottom: 0 }}>
                            A model dataapp helps in sharing model metrics and interactive model
                            usage capabilities to other users
                          </p>
                          <ul style={{ margin: "0px 16px" }}>
                            {_.map(
                              [
                                "Uses model from the Canvas",
                                "Contains model performance metrics",
                                "What-If analysis",
                                "Prediction Service",
                                "AskAI on prediction data",
                                "Save additional charts"
                              ],
                              (item) => (
                                <li key={item} style={{ marginBottom: "4px" }}>
                                  {item}
                                </li>
                              )
                            )}
                          </ul>
                        </>
                      )}

                      {!includes(
                        [
                          DataappAskAIConfigInputTypeEnum.RagFiles,
                          DataappAskAIConfigInputTypeEnum.PredictionService
                        ],
                        _.get(values, dataAppConfigFields.inputType)
                      ) &&
                        type === CreateDataAppRequestDtoDataAppTypeEnum.Askai && (
                          <ColumnHyperlinks
                            value={_.get(values, dataAppConfigFields.allowColumnHyperLink)}
                            onChange={handleChange}
                            updateHyperLinks={updateHyperLinks}
                            mapping={_.get(values, dataAppConfigFields.columnsHyperlinksMapping)}
                            updateHyperLinkError={setHyperlinkError}
                          />
                        )}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
            {_.get(values, dataAppConfigFields.inputType) ===
              DataappAskAIConfigInputTypeEnum.PredictionService && (
              <Grid item xs style={{ padding: "8px 0px 8px 8px" }}>
                <Card
                  style={{
                    background: "#fff",
                    padding: "16px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px"
                  }}>
                  <DataAppAccess
                    value={_.get(values, dataAppConfigFields.isPrivate)}
                    onChange={handleInputChange}
                  />
                  <DataAppViewCode
                    value={_.get(values, dataAppConfigFields.showAICode)}
                    onChange={handleChange}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CreateDataAppForm;
