import React, { useEffect, useState } from "react";
import _, { includes } from "lodash";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography
} from "@material-ui/core";

import NewModal from "components/Modal/NewModal";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import SyntaxList from "./SyntaxList";
import MonacoEditor from "react-monaco-editor";
import { downloadMarkdown, getSnippets } from "./utils/ApiKey.constant";
import { Field, TabPanel, Tab, Tabs } from "src/components";
import EventBus from "src/utils/EventBus";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import LibraryAddCheckOutlinedIcon from "@material-ui/icons/LibraryAddCheckOutlined";
import useCopyClipboard from "src/hooks/useCopyClipboard";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles({
  gridItem: {
    width: "300px",
    maxHeight: "280px",
    overflow: "auto",
    paddingRight: "8px",
    borderRight: "1px solid #dedada",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",

    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  curlCommandBox: {
    "& .Mui-disabled": {
      color: "#003656 !important"
    }
  }
});
interface ISelected {
  title: string;
  key: string;
  code: string;
  tooltip: string;
}

const ApiKeySuccessModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState<any>();
  const [tabValue, setTabValue] = useState("token");

  const [isCopied, setIsCopied] = useState(false);
  const [selected, setSelected] = useState<ISelected>({
    ..._.get(getSnippets(), "API Key Usage")[0],
    key: "usage-curl"
  });

  const { handleCopyClick, isCopied: isTokenCopied } = useCopyClipboard(key?.apiKey ?? "", 10000);

  useEffect(() => {
    if (key) {
      const snippets = getSnippets(key.apiKey);

      setSelected({
        ..._.get(snippets, "API Key Usage")[0],
        key: "usage-curl"
      });
    }
  }, [key]);

  const classes = useStyles();
  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    EventBus.subscribe("SUCCESS_API_KEY", (payload: any) => {
      setKey(payload);
      setOpen(true);
    });
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(selected.code);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 50000);
  };

  const handleChange = (newVal: ISelected) => {
    setSelected(newVal);
    setIsCopied(false);
  };

  const handleDownload = () => {
    downloadMarkdown(getSnippets(key?.apiKey), `${key?.name}.md`, key?.apiKey ?? "");
  };

  return (
    <NewThemeWrapper>
      <NewModal
        header={<Typography variant="h6">Your new API Key</Typography>}
        open={open}
        data-testid="api-connectorsample-syntax"
        width={800}
        headerStyle={{ borderBottom: "1px solid rgb(224, 224, 224)" }}
        height={"calc(100vh - 100px)"}
        background="#fff"
        keepMounted={false}
        cancelButtonLabel="Close"
        submitDisabled={tabValue === "token" ? false : isCopied}
        hideCancel={tabValue === "token"}
        submitButtonLabel={tabValue === "token" ? "Done" : isCopied ? "Copied" : "Copy"}
        onClose={onClose}
        onFinish={tabValue === "token" ? onClose : handleCopy}>
        <Grid
          container
          direction="column"
          style={{ background: "rgb(243, 243, 243)", borderRadius: "8px" }}>
          <Grid style={{ padding: "10px" }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab value="token" label="Token" />
              <Tab value="usage" label="Syntax" />
            </Tabs>
          </Grid>
          <Grid
            style={{
              border: "1px solid rgb(224, 224, 224)",
              background: "#FFFFFF",
              padding: "10px",
              borderRadius: "4px",
              height: "280px"
            }}>
            <TabPanel value="token" index={tabValue} boxProps={{ height: "100%", width: "100%" }}>
              <Grid direction="column" style={{ width: "100%", gap: "10px" }}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    gap: "10px"
                  }}>
                  <span style={{ fontSize: "16px", padding: "0 40px", fontWeight: 500 }}>
                    Make sure you copy your new API Key. You wont be able to see this token again.
                  </span>

                  <Typography
                    style={{ fontSize: "14px", color: "#000000", opacity: 0.5, padding: "0 40px" }}>
                    API Key
                  </Typography>
                  <Grid
                    container
                    alignItems="center"
                    direction="column"
                    style={{ gap: "10px", padding: "0 40px" }}>
                    <Field
                      id="user-auth-token"
                      variant="outlined"
                      fullWidth
                      value={key?.apiKey}
                      type="password"
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position="start"></InputAdornment>
                      }}
                    />
                    <Grid container style={{ justifyContent: "space-between" }}>
                      <Grid style={{ width: "50%" }}>
                        {isTokenCopied && <Alert> API Key is copied to clipboard</Alert>}
                      </Grid>
                      <Grid
                        item
                        container
                        style={{ width: "50%", gap: "10px", justifyContent: "flex-end" }}>
                        <Button
                          size="small"
                          style={{ height: "40px" }}
                          variant="outlined"
                          color="primary"
                          onClick={handleCopyClick}
                          onMouseDown={handleCopyClick}
                          startIcon={
                            <IconButton aria-label="copy auth token" edge="end">
                              {isTokenCopied ? (
                                <LibraryAddCheckOutlinedIcon fontSize="small" />
                              ) : (
                                <FilterNoneIcon fontSize="small" />
                              )}
                            </IconButton>
                          }>
                          {isTokenCopied ? "Copied" : "Copy"}
                        </Button>
                        <Button
                          size="small"
                          style={{ height: "40px" }}
                          variant="outlined"
                          color="primary"
                          onClick={handleDownload}>
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="usage" index={tabValue} boxProps={{ height: "100%", width: "100%" }}>
              <Grid container>
                <Grid item className={classes.gridItem}>
                  <SyntaxList value={selected.key} onChange={handleChange} apiKey={key?.apiKey} />
                </Grid>
                <Grid
                  item
                  style={{
                    height: "280px",
                    width: "450px",
                    overflowX: "hidden",
                    overflowY: "auto"
                  }}>
                  <MonacoEditor
                    key="code-recipe-code-editor"
                    height="100%"
                    value={selected.code}
                    width="100%"
                    language={includes(selected.key, "curl") ? "shell" : "python"}
                    theme="vs"
                    options={{
                      wordWrap: "on",
                      readOnly: true,
                      theme: "vs",
                      minimap: { enabled: false },
                      renderLineHighlight: "none",
                      scrollbar: {
                        vertical: "hidden",
                        horizontal: "hidden"
                      },
                      overviewRulerLanes: 0,
                      padding: {
                        top: 16
                      }
                    }}
                  />
                  {/* )} */}
                </Grid>
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      </NewModal>
    </NewThemeWrapper>
  );
};

export default ApiKeySuccessModal;
