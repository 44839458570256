import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { Grid } from "@material-ui/core";
import { isEqual, isNil, toNumber } from "lodash";

import { DataSourceTile } from "./DataSourceTile";
import { getFivetranConnectorStatus } from "src/utils/fivetran";
import {
  ConnectorsConfig,
  thirdPartyTypeName
} from "src/pages/DataSources/utils/DataSources.constants";
import { useDataSourcesStore } from "src/store/store";

const DataSourcesTiles = (props: $TSFixMe) => {
  const { dataSources, editDataSource, deleteDataSource, onManualSync } = props || {};

  const newConnectorToSync = useDataSourcesStore((state) => state.newConnectorToSync);

  const isPlural = (text: string, number: number) => (isEqual(number, 1) ? text : `${text}s`);

  const getProjectCount = (value: any) => {
    if (isNil(value) || value === false || value === "" || Number.isNaN(value)) {
      return 0;
    }

    // Convert string to number if it can be parsed as a finite number
    const numericValue = toNumber(value);
    if (isFinite(numericValue)) {
      return numericValue;
    }

    // Return 0 for non-numeric strings and other invalid cases
    return 0;
  };

  const formattedDataSources = useMemo(() => {
    return dataSources?.map((data: $TSFixMe) => {
      const isFivetran = data?.dataSourceType === thirdPartyTypeName;

      const isSameConnector = data?.id === newConnectorToSync?.id;
      const status = getFivetranConnectorStatus(
        data?.thirdPartyDataSourceStatus,
        isSameConnector,
        newConnectorToSync?.created
      );

      const projectCount = getProjectCount(data?.projects);

      const transformData = {
        id: data?.id,
        title: data?.name,
        dataSourceType: data?.dataSourceType,
        description: "",
        lastUpdate: data?.updated,
        updatedBy: data.updater,
        source: isFivetran ? data?.tpConnectorType : data?.dataSourceType || "",
        sourceLink: isFivetran ? data?.iconUrl : "",
        linkPreview: "",
        link: "/data-connectors",
        metadata: {
          // [isPlural("file", data?.files)]: data?.files,
          [isPlural("project", projectCount)]: projectCount
        },
        connectCardUrl: data?.options?.connectCardUrl ?? "",
        status
      };

      return transformData;
    });
  }, [dataSources]);

  const actions = (data: $TSFixMe) => [
    {
      id: "editDataSource",
      label: "Edit Data Connector",
      click: () => editDataSource(data)
    },
    {
      id: "deleteDataSource",
      label: "Delete Data Connector",
      click: () => deleteDataSource(data?.id)
    }
  ];

  const availableWindowSize = window.innerWidth * (1 - ConnectorsConfig.ExemptingWidth);
  return (
    <Grid container justifyContent="flex-start" style={{ gap: 24, width: availableWindowSize }}>
      {formattedDataSources?.map((dataSource: $TSFixMe) => {
        return (
          <Grid
            key={uuidv4()}
            item
            xs
            style={{
              flex: `0 0 ${ConnectorsConfig.CardWidth}px`,
              width: ConnectorsConfig.CardWidth,
              height: ConnectorsConfig.CardHeight
            }}>
            <DataSourceTile
              data={dataSource}
              options={actions(dataSource)}
              onManualSync={onManualSync}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DataSourcesTiles;
