import { useMutation } from "@tanstack/react-query";
import api from "utils/AxiosClient";
import {
  InputIdDto,
  AIChatRequestDtoUseCaseEnum,
  AIChatRequestDtoOutputTypeEnum
} from "@rapidcanvas/rc-api-core";
import _ from "lodash";

interface IVariables {
  inputIds?: Array<InputIdDto>;
}

export const useAutoGenerateSuggestionsMutation = (threadId: string) => {
  return useMutation<string[], unknown, IVariables>({
    mutationFn: async ({ inputIds }) => {
      const request = {
        query: "Prompt Suggestions",
        useCase: AIChatRequestDtoUseCaseEnum.PromptBubblesUseCase,
        outputType: AIChatRequestDtoOutputTypeEnum.TextNoCode,
        inputIds
      };

      const response = await api.fetchResponse(() => api.AiControllerApi.chat1(threadId!, request));
      const suggestions = _.map(response.outputEntityResponseList, "textAnswer") as string[];
      return suggestions;
    }
  });
};
