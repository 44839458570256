import React, { useCallback, useMemo, useState } from "react";

// Packages
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { delay, includes } from "lodash";

// MUI
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import { ConfigIcon } from "src/icons/ConfigIcon";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { Clear } from "src/icons/Clear";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Hooks
import { UseGetEnvironmentsQueryKeys } from "src/hooks/api/environments/useGetEnvironments";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { StatusBar } from "./StatusBar/StatusBar";
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import {
  ConfirmCleanCustomPackagesPromptDetails,
  EnvironmentHelperText,
  EnvironmentsHelperText
} from "../utils/Environments.constants";

import { Environment } from "../Environments";
import EnvironmentHeaderBreadcrumb from "./EnvironmentHeaderBreadcrumb";
import { useCleanCache, useUpdateEnvironment } from "src/hooks/api";
import { handleResponse } from "src/utils/apiService";
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { validateNameField } from "src/utils/formFieldUtils";
import {
  getEnvironmentConfigSession,
  setEnvironmentConfigSession
} from "../utils/environments.helpers";
import { useParams } from "react-router-dom";
import { EnvCleanCachePayload } from "src/hooks/api/environments/useCleanCache";
import { EnvironmentStatuses } from "src/constants/environments.constants";
import { getEnvironmentById } from "src/api";
import { Environment as EnvironmentType } from "../Environments";

type Props = {
  environment: Environment;
  isLoading: boolean;
  isLaunching: boolean;
  isEnvironmentsTypesFetching: boolean;
  isStopping: boolean;
  isSaving: boolean;
  isSaveActionDisabled: boolean;
  isUsageDisabled: boolean;
  checkIsDuplicate: (name: string) => boolean;
  onRelaunch: $TSFixMeFunction;
  onStopEnvironmentAction: $TSFixMeFunction;
  onSaveAction: $TSFixMeFunction;
  onConfigAction: $TSFixMeFunction;
  onLogsAction: $TSFixMeFunction;
  onDelete: $TSFixMeFunction;
  readOnly: boolean;
  watchEnvironment: (env: Environment) => void;
  fetchEnvironmentData: () => void;
};

const EnvironmentHeader = (props: Props) => {
  const {
    environment,
    isLoading,
    isLaunching,
    isEnvironmentsTypesFetching,
    isStopping,
    isSaving,
    isSaveActionDisabled,
    isUsageDisabled,
    checkIsDuplicate,
    onRelaunch,
    onStopEnvironmentAction,
    onSaveAction,
    onConfigAction,
    onLogsAction,
    onDelete,
    readOnly,
    watchEnvironment,
    fetchEnvironmentData
  } = props || {};

  const { envId } = useParams();
  const queryClient = useQueryClient();

  const [shouldInvalidateListQuery, setShouldInvalidateListQuery] = useState(false);
  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);
  const [showCleanCustomPackagesConfirmScreen, setShowCleanCustomPackagesConfirmScreen] =
    useState(false);

  const handleRelaunch = useCallback((body: EnvironmentType) => {
    setShouldInvalidateListQuery(() => true);
    onRelaunch(body);
  }, []);

  const handleStop = useCallback(() => {
    setShouldInvalidateListQuery(() => true);
    onStopEnvironmentAction();
  }, []);

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isClearingCache,
    mutateAsync: clearCacheMutation,
    reset: resetClearCacheMutation
  } = useCleanCache();

  const {
    isLoading: isCleaningCustomPackages,
    mutateAsync: cleanCustomPackagesMutation,
    reset: resetCleanCustomPackagesMutation
  } = useCleanCache();

  const invalidateListQuery = useCallback(() => {
    if (!shouldInvalidateListQuery) return;

    // Removes the query data
    queryClient.removeQueries([UseGetEnvironmentsQueryKeys.Environments]);

    // Refetch the query
    queryClient.invalidateQueries([UseGetEnvironmentsQueryKeys.Environments]);
  }, [shouldInvalidateListQuery]);
  // << ENDS - Query hooks

  const updateEnv = useUpdateEnvironment();

  const validateDatasourcetName = (value: $TSFixMe) => {
    const { isValid, error: errorMessage } = validateNameField({
      fieldName: value,
      fieldNameLabel: `environment name`
    });

    if (!isValid) {
      return errorMessage;
    }

    if (checkIsDuplicate(value)) {
      return "The Environment name already exist.";
    }

    return "";
  };

  const updateEnvironmentName = (name: string) => {
    setShouldInvalidateListQuery(() => true);
    const errorMessage = validateDatasourcetName(name);

    if (errorMessage) {
      handleResponse({ errorMessage });
      setShouldResetName(Date.now());
      return;
    }
    updateEnv.mutate(
      {
        id: environment?.id,
        // @ts-ignore
        payload: {
          ...environment,
          name
        }
      },
      {
        onSuccess: () => {
          toastWrapper({
            type: ToastTypes.Success,
            content: "Environment Name updated successfully."
          });
          const thisValue = getEnvironmentConfigSession();
          setEnvironmentConfigSession({ ...thisValue, name });
        },
        onError: () => {
          setShouldResetName(Date.now());
        }
      }
    );
  };

  const onClearCache = async () => {
    if (!envId) {
      return;
    }

    const toastId = toastWrapper({
      type: ToastTypes.Info,
      content: "Clearing cache is in progress.",
      ...{ closeOnClick: false }
    });

    const payload: EnvCleanCachePayload = {
      envId,
      isDeleteRecent: true
    };

    await resetClearCacheMutation();
    await clearCacheMutation(payload, {
      onSuccess: () =>
        toastWrapper({
          type: ToastTypes.Success,
          content: "Cache cleared successfully!"
        }),
      onSettled: () => toast.dismiss(toastId)
    });
  };

  const onCleanCustomPackages = async () => {
    resetCleanCustomPackages();

    if (!envId) {
      return;
    }

    const toastId = toastWrapper({
      type: ToastTypes.Info,
      content: "Cleaning custom packages is in progress.",
      ...{ closeOnClick: false }
    });

    delay(async () => {
      try {
        const [thisEnvironment] = await getEnvironmentById(envId);
        watchEnvironment(thisEnvironment);
      } catch (error) {
        console.log(error);
      }
    }, 250);

    const payload: EnvCleanCachePayload = {
      envId,
      isCleanCustomPackages: true
    };

    await resetCleanCustomPackagesMutation();
    await cleanCustomPackagesMutation(payload, {
      onSuccess: async () => {
        await fetchEnvironmentData();

        toastWrapper({
          type: ToastTypes.Success,
          content: "Custom packages cleaned successfully!"
        });
      },
      onSettled: () => toast.dismiss(toastId)
    });
  };

  const disabledClearCacheActionMessage = useMemo(() => {
    if (!!isClearingCache) {
      return EnvironmentHelperText.ClearingCacheInfo;
    }

    if (!!isCleaningCustomPackages) {
      return EnvironmentHelperText.CleaningCustomPackagesInfo;
    }

    if (
      environment?.launchStatus?.trim()?.toLowerCase() !== EnvironmentStatuses.Active.toLowerCase()
    ) {
      return EnvironmentHelperText.CleanActionsDisabledInfo;
    }

    return "";
  }, [isClearingCache, isCleaningCustomPackages, environment]);

  const disabledCleanCustomPackagesActionMessage = useMemo(() => {
    if (!!isClearingCache) {
      return EnvironmentHelperText.ClearingCacheInfo;
    }

    if (!!isCleaningCustomPackages) {
      return EnvironmentHelperText.CleaningCustomPackagesInfo;
    }

    if (
      environment?.launchStatus?.trim()?.toLowerCase() !== EnvironmentStatuses.Active.toLowerCase()
    ) {
      return EnvironmentHelperText.CleanActionsDisabledInfo;
    }

    return "";
  }, [isClearingCache, isCleaningCustomPackages, environment]);

  // Confirm clean custom packages - STARTS >>
  const promptConfirmCleanCustomPackages = () => {
    setShowCleanCustomPackagesConfirmScreen(() => true);
  };

  const resetCleanCustomPackages = () => {
    setShowCleanCustomPackagesConfirmScreen(() => false);
  };
  // << ENDS - Confirm clean custom packages

  const moreOptions = useMemo(
    () => [
      {
        label: "Configs",
        icon: <ConfigIcon />,
        action: onConfigAction,
        isCloseMoreAction: true
      },
      {
        label: "Logs",
        icon: <LogsNewIconV2 />,
        isDisabled: isEnvironmentsTypesFetching,
        action: onLogsAction,
        isCloseMoreAction: true
      },
      {
        label: "Clear Cache",
        icon: <Clear width={20} height={20} />,
        isDisabled: !!disabledClearCacheActionMessage,
        isLoading: !!isClearingCache,
        tooltip: disabledClearCacheActionMessage,
        action: onClearCache
      },
      {
        label: "Clean Custom Packages",
        icon: <Clear width={20} height={20} />,
        isDisabled: !!disabledCleanCustomPackagesActionMessage,
        isLoading: !!isCleaningCustomPackages,
        tooltip: disabledCleanCustomPackagesActionMessage,
        action: promptConfirmCleanCustomPackages
      },
      {
        label: "Delete",
        icon: <TrashIcon width={18} height={18} viewBox="0 0 20 20" />,
        isDisabled: isLaunching,
        tooltip: isLaunching ? EnvironmentsHelperText.DeleteInfo : "",
        action: onDelete,
        isCloseMoreAction: true
      }
    ],
    [
      isUsageDisabled,
      isEnvironmentsTypesFetching,
      isLaunching,
      disabledClearCacheActionMessage,
      isClearingCache,
      disabledCleanCustomPackagesActionMessage,
      isCleaningCustomPackages
    ]
  );

  return (
    <>
      {showCleanCustomPackagesConfirmScreen && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title={ConfirmCleanCustomPackagesPromptDetails.title}
          content={[
            ConfirmCleanCustomPackagesPromptDetails.messageLine1,
            ConfirmCleanCustomPackagesPromptDetails.messageLine2
          ]}
          onClose={(_, reason: string) => {
            if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
              resetCleanCustomPackages();
            }
          }}
          onSubmit={onCleanCustomPackages}
          hideCloseIcon
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          backNavAction: invalidateListQuery,
          component: (
            <EnvironmentHeaderBreadcrumb
              environmentName={environment.name}
              onEditEnvironmentName={updateEnvironmentName}
              shouldResetName={shouldResetName}
              readonly={readOnly}
            />
          )
        }}
        subTopNavBarRightSection={{
          moreOptions,
          moreActionWidth: 250,
          component: (
            <>
              {isLoading ? (
                <CircularProgress size={24} style={{ marginRight: 20 }} />
              ) : (
                environment && (
                  <StatusBar
                    env={environment}
                    onRelaunchEnv={handleRelaunch}
                    stoppingEnvironments={isStopping ? [environment?.id] : []}
                    onStopEnv={handleStop}
                    subtopBarStyles
                  />
                )
              )}

              <Button
                color="primary"
                startIcon={isSaving ? <CircularProgress size={16} /> : undefined}
                onClick={onSaveAction}
                disabled={isSaveActionDisabled}
                data-testid="saveEnvironment">
                {EnvironmentsHelperText.Save}
              </Button>
            </>
          )
        }}
      />
    </>
  );
};

export default EnvironmentHeader;
