import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";
import { CreateEnvDto, EnvDto } from "@rapidcanvas/rc-api-core";

const useSaveEnvironment = (): UseMutationResult<EnvDto, unknown, CreateEnvDto, unknown> =>
  useMutation({
    mutationFn: async (payload: CreateEnvDto) =>
      await api.fetchResponse(async () => await api.EnvControllerApi.createEnv({ ...payload }))
  });

export default useSaveEnvironment;
