import React, { useCallback, useMemo, useState } from "react";

// Packages
import { Link, useNavigate } from "react-router-dom";
import { isEmpty, map } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

// Icons
import envIcon from "src/assets/images/environment-medium.svg";
import { PencilIcon } from "src/icons/NewUX";
import { ConfigIcon } from "src/icons/ConfigIcon";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { Delete } from "src/icons/Delete";

// Open API
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Utils
import { dateFormat } from "src/utils/dateFormat";
import { snakeCaseToStartCase } from "src/utils/helpers";

// Components
import OverflowTooltip from "src/components/OverflowTooltip";
import Text from "src/components/Widget/Text";
import StatusBar from "../StatusBar/StatusBar";
import ProjectsField from "../ProjectsField/ProjectsField";

// Constants
import {
  EnvironmentCardHelperText,
  EnvironmentsHelperText
} from "../../utils/Environments.constants";

// Styles
import useStyles from "./EnvironmentCard.styles";

interface IProps {
  environment: EnvDto;
  onEdit: (envId?: string) => void;
  onConfig: (env?: EnvDto) => void;
  onLogs: (env?: EnvDto) => void;
  onDelete: (env?: EnvDto) => void;
}

const EnvironmentCard: React.FC<IProps> = (props) => {
  const { environment, onEdit, onConfig, onLogs, onDelete } = props;

  const navigate = useNavigate();

  const theme = useTheme();
  const classes = useStyles();

  const isLaunching = useMemo(
    () => environment?.launchStatus === EnvDtoLaunchStatusEnum.Launching,
    [environment?.launchStatus]
  );

  const cardOptions = useMemo(
    () => [
      {
        label: "Edit",
        icon: (
          <Typography color="textSecondary">
            <PencilIcon data-testid="envCardEditIcon" />
          </Typography>
        ),
        click: () => onEdit(environment?.id)
      },
      {
        label: "Configs",
        icon: <ConfigIcon data-testid="envCardConfigIcon" />,
        click: () => onConfig(environment)
      },
      {
        label: "Logs",
        icon: <LogsNewIconV2 fill="#003656" width="24" height="24" data-testid="envCardLogsIcon" />,
        click: () => onLogs(environment)
      },
      {
        label: "Delete",
        icon: (
          <Typography color="textSecondary">
            <Delete data-testid="envCardDeleteIcon" />
          </Typography>
        ),
        click: () => onDelete(environment),
        disabled: isLaunching,
        tooltip: isLaunching ? "Cannot delete a launching environment." : ""
      }
    ],
    [environment, isLaunching, onEdit, onConfig, onLogs, onDelete]
  );

  // More options - STARTS >>
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    event?.preventDefault();
    setMoreOptionsAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMoreOptionsAnchorEl(null);
  };

  const onMenuOptionClick = (clickHandler: () => void) => {
    clickHandler();
    handleMenuClose();
  };
  // << ENDS - More options

  const fields = useMemo(() => {
    const items = [
      {
        label: EnvironmentCardHelperText.EnvironmentType,
        content: snakeCaseToStartCase(environment?.envType || "", { toUpperCase: ["cpu"] })
      },
      {
        label: EnvironmentCardHelperText.Projects,
        content: !isEmpty(environment?.projectDtos) ? (
          <ProjectsField environment={environment} />
        ) : (
          EnvironmentsHelperText.NoProjects
        )
      },
      {
        label: EnvironmentCardHelperText.UpdatedOn,
        content: dateFormat(environment?.updated)
      }
    ];

    return map(items, (item, index) => (
      <Grid container>
        <Grid item xs={12}>
          <Box fontWeight={500} fontSize={14} data-testid={`envCardFieldLabel_${index}`}>
            {item.label}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box fontSize={14} data-testid={`envCardFieldContent_${index}`}>
            {item.content}
          </Box>
        </Grid>
      </Grid>
    ));
  }, [environment]);

  const onCardClick = useCallback(
    (event: any) => {
      event?.stopPropagation();
      event?.preventDefault();
      navigate(`/environments/${environment?.id}`);
    },
    [environment?.id]
  );

  return (
    <>
      {Boolean(moreOptionsAnchorEl) && (
        <Menu open anchorEl={moreOptionsAnchorEl} onClose={handleMenuClose}>
          {map(cardOptions, (cardOption: any, index: number) => (
            <MenuItem
              onClick={() => onMenuOptionClick(cardOption?.click)}
              style={{ columnGap: theme.spacing(1) }}
              data-testid={`envCardMenuItem_${index}`}>
              <ListItemIcon
                className={classes.moreOptionListItem}
                data-testid={`envCardMenuItemIcon_${index}`}>
                {cardOption?.icon}
              </ListItemIcon>
              {cardOption?.label}
            </MenuItem>
          ))}
        </Menu>
      )}

      <Card className={classes.root} onClick={onCardClick}>
        <CardHeader
          classes={{
            content: classes.cardHeaderContent,
            action: classes.cardHeaderAction
          }}
          avatar={<img alt="environment-icon" src={envIcon} data-testid="envCardEnvAvatar" />}
          action={
            <IconButton
              className="actions"
              onClick={handleMenuOpen}
              data-testid="envCardMoreOption">
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Link
              to={`/environments/${environment?.id}`}
              onClick={(event) => event.stopPropagation()}
              data-testid="envCardTitleLinkAction">
              <Typography variant="h6" color="textPrimary" style={{ width: "85%" }}>
                <OverflowTooltip
                  tooltipProps={{
                    placement: "bottom-start",
                    PopperProps: { style: { marginTop: -12 } }
                  }}
                  value={
                    <Text
                      value={environment?.name}
                      data-testid="envCardTitle"
                      style={{ whiteSpace: "pre" }}
                    />
                  }
                  data-testid="envCardTitleOverflowTooltip"
                />
              </Typography>
            </Link>
          }
        />
        <CardContent className={classes.content}>
          <Grid container style={{ rowGap: theme.spacing(1) }}>
            <Grid item className={classes.descriptionContainer}>
              <Typography variant="caption" color="textSecondary">
                <OverflowTooltip
                  style={{ whiteSpace: "nowrap" }}
                  value={environment?.description}
                  data-testid="envCardDescriptionOverflowTooltip"
                />
              </Typography>
            </Grid>

            <Grid container direction="row" style={{ rowGap: theme.spacing(2) }}>
              {fields}
            </Grid>
          </Grid>

          {environment?.updater && (
            <Grid container alignItems="center" className={classes.updaterContainer}>
              <Grid item>
                <Avatar className={classes.updaterAvatar} data-testid="envCardAvatar">
                  {environment?.updater?.charAt(0)?.toUpperCase()}
                </Avatar>
              </Grid>
              <Grid item style={{ width: "85%" }}>
                <OverflowTooltip
                  style={{ whiteSpace: "nowrap" }}
                  value={
                    <Typography
                      color="textSecondary"
                      variant="caption"
                      data-testid="envCardUpdater">
                      {environment?.updater}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          )}
        </CardContent>
        <CardActions disableSpacing className={classes.cardActions}>
          <StatusBar environment={environment} isActions />
        </CardActions>
      </Card>
    </>
  );
};

export default EnvironmentCard;
