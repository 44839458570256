import React, { useCallback, useEffect, useMemo, useState } from "react";

// Packages
import { Controller, FormProvider, useForm } from "react-hook-form";
import { includes, isEmpty } from "lodash";

// MUI
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

// Icons
import InfoOutlined from "@material-ui/icons/InfoOutlined";

// Open API
import { EnvDto, EnvDtoLaunchStatusEnum, ShutdownStrategyTypeEnum } from "@rapidcanvas/rc-api-core";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";

// Hooks
import {
  useGetEnvironment,
  useGetEnvironmentResourceUsage,
  useUpdateEnvironment
} from "src/hooks/api";

// Components
import Header from "./Header";
import Footer from "./Footer";
import CpuUsage from "./CpuUsage";
import ConfirmClose from "./ConfirmClose";
import ConfirmUpdate from "./ConfirmUpdate";

// Constants
import {
  defaultValues,
  EnvironmentConfigConfig,
  EnvironmentConfigFormFields,
  EnvironmentConfigFormFieldsNameMapping,
  EnvironmentConfigHelperText
} from "./EnvironmentConfig.constants";

// Styles
import useStyles from "./EnvironmentConfig.styles";
import MemoryUsage from "./MemoryUsage";
import CommonLoader from "src/components/CommonLoader";

export type EnvironmentConfigFormFieldsType = {
  [EnvironmentConfigFormFields.EnvShutdownTimeout]: number;
  [EnvironmentConfigFormFields.EnvShutdownStrategyType]: boolean;
};

interface IProps {
  open: boolean;
  environment: EnvDto;
  onUpdate?: () => void;
  onClose: () => void;
}

const EnvironmentConfig: React.FC<IProps> = (props) => {
  const { open, environment: env, onUpdate, onClose } = props;

  const { isLoading: isLoadingEnvironment, data: environment } = useGetEnvironment({
    id: env?.id,
    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely
    refetchOnMount: true
  });

  const isLaunchingOrSucceed = useMemo(
    () =>
      includes(
        [EnvDtoLaunchStatusEnum.Launching, EnvDtoLaunchStatusEnum.Success],
        env?.launchStatus
      ),
    [env?.launchStatus]
  );

  const theme = useTheme();
  const classes = useStyles({ disabled: !isLaunchingOrSucceed });

  const inputProps = { classes: { root: classes.inputField } };
  const inputLabelProps = {
    classes: { root: classes.inputLabel, shrink: classes.inputLabelShrink },
    shrink: true
  };

  // States - STARTS >>
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmUpdateModal, setShowConfirmUpdateModal] = useState(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Mutations
  const { mutateAsync: updateEnvironmentMutation, reset: resetUpdateEnvironmentMutation } =
    useUpdateEnvironment();

  // Queries
  const isPollEnvironmentResourceUsage = useMemo(
    () => !!open && !!environment?.id && !!isLaunchingOrSucceed,
    [open, environment?.id, isLaunchingOrSucceed]
  );

  const { data, isLoading: isLoadingEnvResourceUsage } = useGetEnvironmentResourceUsage({
    id: environment?.id,
    enabled: !!isPollEnvironmentResourceUsage,
    refetchInterval: !!isPollEnvironmentResourceUsage ? 5000 : false
  });
  // << ENDS - Query hooks

  // Form - STARTS >>
  const formMethods = useForm<EnvironmentConfigFormFieldsType>({
    mode: "onChange", // Validate onChange
    reValidateMode: "onChange", // Re-validate onChange
    defaultValues
  });

  const {
    watch,
    getValues,
    setValue,
    control,
    trigger,
    reset,
    formState: { isValid, isDirty }
  } = formMethods || {};

  useEffect(() => {
    if (!isEmpty(environment?.shutdownStrategy)) {
      const isEvergreen =
        environment?.shutdownStrategy?.type === ShutdownStrategyTypeEnum.Evergreen;

      reset({
        [EnvironmentConfigFormFields.EnvShutdownTimeout]: isEvergreen
          ? undefined
          : Number(environment?.shutdownStrategy?.inactivityInHours),
        [EnvironmentConfigFormFields.EnvShutdownStrategyType]: isEvergreen
      });
    }
  }, [environment]);
  // << ENDS - Form

  // Confirm update - STARTS >>
  const promptConfirmUpdate = useCallback(() => {
    setShowConfirmUpdateModal(() => true);
  }, []);

  const resetConfirmUpdate = useCallback(() => {
    setShowConfirmUpdateModal(() => false);
  }, []);
  // << ENDS - Confirm update

  // Confirm close - STARTS >>
  const promptConfirmClose = useCallback(() => {
    setShowConfirmModal(() => true);
  }, []);

  const resetConfirmClose = useCallback(() => {
    setShowConfirmModal(() => false);
  }, []);
  // << ENDS - Confirm close

  const updateEnvironment = async () => {
    if (!environment?.id) {
      return;
    }

    setIsSaving(() => true);
    trigger();

    const values = getValues();

    const isEvergreen = !!values?.[EnvironmentConfigFormFields.EnvShutdownStrategyType];

    const payload = {
      id: environment?.id,
      payload: {
        ...environment,
        shutdownStrategy: {
          type: isEvergreen
            ? ShutdownStrategyTypeEnum.Evergreen
            : ShutdownStrategyTypeEnum.InactivityTimePeriod,
          inactivityInHours: isEvergreen
            ? (undefined as unknown as number)
            : Number(values?.[EnvironmentConfigFormFields.EnvShutdownTimeout])
        }
      }
    };

    resetUpdateEnvironmentMutation();
    await updateEnvironmentMutation(payload, {
      onSuccess: () => {
        reset({ ...values });
        toastWrapper({
          type: ToastTypes.Success,
          content: `The inactivity shutdown period for ${environment?.name} is updated successfully!`
        });

        setIsSaving(() => false);
        resetConfirmUpdate();

        onUpdate?.();
      },
      onError: () => {
        setIsSaving(() => false);
        resetConfirmUpdate();
      }
    });
  };

  const isEvergreenChecked = watch(EnvironmentConfigFormFields.EnvShutdownStrategyType);
  const isReadOnly = useMemo(() => !!isSaving, [isSaving]);

  const validationRules = () => ({
    required: "This field is required",
    validate: (value: unknown): string | true => {
      if (
        !isReadOnly &&
        !isEvergreenChecked &&
        (Number(value) < EnvironmentConfigConfig.EnvironmentMinInactivity ||
          Number(value) > EnvironmentConfigConfig.EnvironmentMaxInactivity)
      ) {
        return `The inactivity shutdown period should be min ${EnvironmentConfigConfig.EnvironmentMinInactivity} and at most ${EnvironmentConfigConfig.EnvironmentMaxInactivity}.`;
      }

      return true;
    }
  });

  const isAttemptedClose = useCallback(() => {
    if (!!isDirty) {
      promptConfirmClose();
    } else {
      onClose();
    }
  }, [isDirty]);

  const disabledCancelActionMessage = useMemo(() => {
    if (!!isSaving) {
      return "Please wait. The save action is in progress.";
    }

    return "";
  }, [isSaving]);

  const isLoading = useMemo(
    () => !!environment?.id && !!isLaunchingOrSucceed && !!isLoadingEnvResourceUsage,
    [environment?.id, isLaunchingOrSucceed, isLoadingEnvResourceUsage]
  );

  const disabledSaveActionMessage = useMemo(() => {
    if (!!isLoading) {
      return "Please wait. Fetching required data.";
    }

    if (!isValid) {
      return "Invalid fields.";
    }

    if (!isDirty) {
      return "Change fields to enable this action.";
    }

    return "";
  }, [isLoading, isValid, isDirty]);

  return (
    <>
      {!!showConfirmModal && <ConfirmClose onConfirm={onClose} onCancel={resetConfirmClose} />}

      {!!showConfirmUpdateModal && (
        <ConfirmUpdate
          isSaving={isSaving}
          onConfirm={updateEnvironment}
          onCancel={resetConfirmUpdate}
        />
      )}

      <Drawer
        open={open}
        anchor="right"
        variant="temporary"
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        onClose={isAttemptedClose}>
        {!!isLoadingEnvironment ? (
          <CommonLoader />
        ) : (
          <>
            <Header environment={env} onClose={isAttemptedClose} />

            <Box mt="44px">
              <Grid
                container
                direction="column"
                style={{ rowGap: theme.spacing(2), padding: theme.spacing(2) }}>
                <FormProvider {...formMethods}>
                  <Grid container direction="column" style={{ rowGap: theme.spacing(2) }}>
                    <Grid item>
                      <Controller
                        control={control}
                        name={EnvironmentConfigFormFields.EnvShutdownTimeout}
                        rules={validationRules()}
                        render={({ field, fieldState }) => {
                          const { error } = fieldState;

                          return (
                            <TextField
                              {...field}
                              type="number"
                              variant="outlined"
                              size="small"
                              fullWidth
                              label={
                                EnvironmentConfigFormFieldsNameMapping[
                                  EnvironmentConfigFormFields.EnvShutdownTimeout
                                ]
                              }
                              inputProps={{ min: 1 }}
                              InputProps={{
                                ...inputProps,
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    data-testid="envConfigEnvShutdownTimeoutInputHr">
                                    hr
                                  </InputAdornment>
                                )
                              }}
                              InputLabelProps={inputLabelProps}
                              disabled={!!isReadOnly || !!isEvergreenChecked}
                              error={!!error}
                              {...(!!error?.message ? { helperText: error?.message } : {})}
                              data-testid="envConfigEnvShutdownTimeoutInput"
                            />
                          );
                        }}
                        data-testid="envConfigEnvShutdownTimeout"
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        name={EnvironmentConfigFormFields.EnvShutdownStrategyType}
                        render={({ field, fieldState }) => {
                          const { error } = fieldState;

                          return (
                            <FormControl size="small" error={!!error} component="fieldset">
                              <FormControlLabel
                                disabled={!!isReadOnly}
                                control={
                                  <Checkbox
                                    {...field}
                                    checked={!!field.value}
                                    size="small"
                                    color="primary"
                                    onChange={(event) => {
                                      const isChecked = event.target.checked;
                                      field.onChange(isChecked);
                                      setValue(EnvironmentConfigFormFields.EnvShutdownTimeout, 2, {
                                        shouldValidate: true
                                      });
                                    }}
                                    data-testid="envConfigEnvShutdownStrategyType"
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body2"
                                    data-testid="envConfigEnvShutdownStrategyTypeLabelContainer">
                                    {
                                      EnvironmentConfigFormFieldsNameMapping[
                                        EnvironmentConfigFormFields.EnvShutdownStrategyType
                                      ]
                                    }
                                    <Tooltip
                                      title={
                                        EnvironmentConfigHelperText.EnvShutdownStrategyTypeInfo
                                      }
                                      data-testid="envConfigEnvShutdownStrategyTypeLabelTooltip">
                                      <InfoOutlined
                                        fontSize="small"
                                        style={{ marginLeft: theme.spacing(1) }}
                                      />
                                    </Tooltip>
                                  </Typography>
                                }
                              />
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </FormProvider>
                <Grid container direction="column" style={{ rowGap: theme.spacing(2) }}>
                  <Box p={2} className={classes.container}>
                    <MemoryUsage environment={environment} data={data} isLoading={isLoading} />
                  </Box>
                  <Box px="25%" py={2} className={classes.container}>
                    <CpuUsage
                      percentage={`${data?.cpuPercentage || 0}`}
                      disabled={!data?.cpuPercentage}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Footer
              disabledCancelActionMessage={disabledCancelActionMessage}
              disabledSaveActionMessage={disabledSaveActionMessage}
              onClose={isAttemptedClose}
              updateEnvironment={promptConfirmUpdate}
            />
          </>
        )}
      </Drawer>
    </>
  );
};

export default EnvironmentConfig;
