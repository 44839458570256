import React, { useEffect, useState, useMemo } from "react";

import { generatePath, useNavigate, useParams } from "react-router-dom";

import {
  Grid,
  Paper,
  Button,
  Tooltip,
  Badge,
  makeStyles,
  CircularProgress
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import AddIcon from "@material-ui/icons/Add";

import { toastWrapper } from "src/utils/toastWrapper";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";

import { useSaveJob, usePublishJob, useUpdateJob, useDeleteJob } from "src/hooks/api";

import { useJobContext } from "./context/useJobContext";

import { JobHeader, JobRunConfig, JobCanvas, JobDestinations } from "..";
import { jobWeekDays, JobsHelperText } from "../../utils/Jobs.constants";
import { WebPaths } from "src/routing/routes";
import { queryClient } from "src/app-configs/react-query.config";
import { QUERY_KEY_PROJECT_DETAILS } from "src/hooks/api/projects/useProjectDetails";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles(() => ({
  customBadge: {
    "& span": {
      top: "4px",
      right: "-2px"
    }
  }
}));

const Job = () => {
  const { projectId, jobId }: $TSFixMe = useParams() || {};
  const classes = useStyles();
  const navigate = useNavigate();
  const [isDestinationSaving, setIsDestinationSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!projectId && !jobId) {
      navigate(WebPaths.Projects);
    }
  }, [projectId, jobId]);

  // Job context
  const {
    jobContextState,
    jobRunConfigContextState,
    jobParametersContextState,
    jobDestinationsContextState,
    isFetchingJob,
    isJobFetched,
    jobData,
    refetchJob,
    validateNodes,
    currentJobId,
    currentScenarioId,
    destinationsData,
    setIsSaved
  } = useJobContext() || {};

  // States - STARTS >>
  const [isDestinationsOpen, setIsDestinationsOpen] = useState<boolean>(false);

  const [isValidNodes, setIsValidNodes] = useState<$TSFixMe>(false);
  const [isValidatingNodes, setIsValidatingNodes] = useState<$TSFixMe>(false);
  const [isNodesValidated, setIsNodesValidated] = useState<$TSFixMe>(false);

  const [isValidatingNodesOnSaving, setIsValidatingNodesOnSaving] = useState(false);
  const [isCheckingRelaunchEnvOnSaving, setIsCheckingRelaunchEnvOnSaving] = useState(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isSavingJob,
    data: savedJobData,
    isSuccess: isJobSaved,
    mutateAsync: saveJobMutation,
    reset: resetSaveJobMutation
  } = useSaveJob();

  const {
    isLoading: isPublishingSavedJob,
    isSuccess: isSavedJobPublished,
    mutateAsync: publishSavedJobMutation,
    reset: resetPublishSavedJobMutation
  } = usePublishJob();

  const {
    isLoading: isPublishingJob,
    isSuccess: isJobPublished,
    mutateAsync: publishJobMutation,
    reset: resetPublishJobMutation
  } = usePublishJob();

  const {
    isLoading: isUpdatingJob,
    mutateAsync: updateJobMutation,
    reset: resetUpdateJobMutation
  } = useUpdateJob();

  const {
    isLoading: isUpdatingJobGlobalVariables,
    isSuccess: isJobGlobalVariablesUpdated,
    mutateAsync: updateJobGlobalVariablesMutation,
    reset: resetUpdateJobGlobalVariablesMutation
  } = useUpdateJob();

  const {
    isLoading: isUpdatingJobStatus,
    mutateAsync: updateJobStatusMutation,
    reset: resetUpdateJobStatusMutation
  } = useUpdateJob();

  const { mutateAsync: updateJobTimeoutMutation, reset: resetUpdateJobTimeoutMutation } =
    useUpdateJob();

  const {
    isLoading: isJobDeleting,
    mutateAsync: deleteJobMutation,
    reset: resetDeleteJobMutation
  } = useDeleteJob();
  // << ENDS - Query hooks

  useEffect(() => {
    if (jobId && isJobFetched) {
      setIsSaved(isJobFetched);
    }
  }, [jobId, isJobFetched]);

  useEffect(() => {
    !!jobId && refetchJob();
  }, [jobId]);

  useEffect(() => {
    const _ = async () => {
      if (!!currentJobId) {
        setIsValidNodes(() => true);
        setIsNodesValidated(() => true);
      } else {
        setIsValidatingNodes(() => true);
        const isValid = await validateNodes();
        setIsValidNodes(() => isValid);
        setIsValidatingNodes(() => false);
        setIsNodesValidated(() => true);
      }
    };

    !!projectId && _();
  }, [projectId, currentJobId]);

  // Save job - STARTS >>
  const onSaveOrUpdateJob = (data: $TSFixMe) => {
    if (Object.keys(data || {})?.length > 0) {
      if (jobId) {
        location.reload();
      } else {
        navigate(
          generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, {
            projectId,
            jobId: data?.id
          }),
          { replace: true }
        );
      }
    }
  };

  const saveJob = async () => {
    // Resetting save-job related mutations to their initial state.
    resetSaveJobMutation();
    resetPublishSavedJobMutation();
    resetUpdateJobMutation();

    setIsSaved(() => false);

    if (!currentJobId) {
      if (!!projectId && !!jobRunConfigContextState?.values?.scenario) {
        setIsValidatingNodesOnSaving(() => true);
        const validNodes = await validateNodes();
        setIsValidatingNodesOnSaving(() => false);

        if (!validNodes) {
          toastWrapper({
            type: "error",
            content: JobsHelperText.JobCanvasCannotCreateJob
          });

          return;
        }
      }
    }

    let schedule: $TSFixMe = "";
    if (jobRunConfigContextState?.values?.frequency === "cron") {
      // $FixMe: Below code should be replaced by jobRunConfigContextState.
      // Due to unknown reasons latest cron value is not being available through store here,
      // though store gets updated with latest cron value. Hence, took support of session-storage work-around for time-being.
      schedule = sessionStorage.getItem("cronValueSession");

      // schedule = jobRunConfigContextState?.values?.cron;
    } else {
      const defaultCron = ["*", "*", "*", "*", "*"];

      let daysIndexes: $TSFixMe = "*";
      if (jobRunConfigContextState?.values?.frequency === "weekly") {
        const jobWeekDaysIds = jobWeekDays?.map((eachDay: $TSFixMe) => eachDay?.id);
        daysIndexes = jobRunConfigContextState?.values?.days
          ?.map((eachDay: $TSFixMe) => jobWeekDaysIds?.indexOf(eachDay))
          ?.join(",");
      }

      defaultCron[0] = `${+jobRunConfigContextState?.values?.min}`;
      defaultCron[1] = `${+jobRunConfigContextState?.values?.hr}`;
      defaultCron[4] = daysIndexes || "*";

      schedule = defaultCron?.join(" ");
    }

    let payload: $TSFixMe = {};

    if (!!currentJobId) {
      payload = { ...payload, id: currentJobId };
    }

    payload = {
      ...payload,
      projectId,
      name: jobContextState?.jobName,

      scenarioId: jobRunConfigContextState?.values?.scenario,
      schedule,
      scheduleInfo: {
        cronConfig: {
          frequency: jobRunConfigContextState?.values?.frequency,
          days: jobRunConfigContextState?.values?.days,
          hr: jobRunConfigContextState?.values?.hr,
          min: jobRunConfigContextState?.values?.min
        }
      }
    };

    if (!jobId && !jobData?.id) {
      // variables - STARTS >>
      const variables: $TSFixMe = {};
      if ((jobParametersContextState || [])?.length > 0) {
        (jobParametersContextState || [])?.forEach((eachParameter: $TSFixMe) => {
          if (!!eachParameter?.key && !!eachParameter?.value) {
            variables[eachParameter.key] = eachParameter.value;
          }
        });
      }
      // << ENDS - variables

      // destinations - STARTS >>
      const destinations: $TSFixMe = [];

      if ((jobDestinationsContextState || [])?.length > 0) {
        (jobDestinationsContextState || [])?.forEach((eachDestination: $TSFixMe) => {
          let thisDestination: $TSFixMe = {
            entityId: eachDestination?.datasetId,
            dataSourceId: eachDestination?.dataSourceId,
            options: eachDestination?.options
          };
          destinations.push(thisDestination);
        });
      }
      // << ENDS - destinations ends

      payload = {
        ...payload,
        status: "ACTIVE",
        variables,
        syncDtos: destinations
      };
    }

    if (!!currentJobId) {
      await updateJobMutation(payload, {
        onSuccess: (data: $TSFixMe) => {
          toastWrapper({ type: "success", content: JobsHelperText.JobUpdated });
          setIsSaved(() => true);
          onSaveOrUpdateJob(data);
        }
      });
    } else {
      setIsCheckingRelaunchEnvOnSaving(() => true);
      !!projectId && (await checkEnvRelaunch(projectId));
      setIsCheckingRelaunchEnvOnSaving(() => false);

      await saveJobMutation(payload, {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEY_PROJECT_DETAILS]);
        }
      });
    }
  };

  useEffect(() => {
    const _ = async () => {
      await publishSavedJobMutation(savedJobData?.id);
    };

    isJobSaved && !!savedJobData?.id && _();
  }, [isJobSaved, savedJobData]);

  useEffect(() => {
    if (isJobSaved && isSavedJobPublished) {
      toastWrapper({ type: "success", content: JobsHelperText.JobCreated });
      setIsSaved(() => true);
      onSaveOrUpdateJob(savedJobData);
    }
  }, [isJobSaved, isSavedJobPublished, savedJobData]);
  // << ENDS - Save job

  const isLoading = useMemo(
    () => isFetchingJob || isUpdatingJobStatus,
    [isFetchingJob, isUpdatingJobStatus]
  );

  const isSaving = useMemo(
    () =>
      isValidatingNodesOnSaving ||
      isCheckingRelaunchEnvOnSaving ||
      isSavingJob ||
      isPublishingSavedJob ||
      isUpdatingJob ||
      isUpdatingJobGlobalVariables,
    [
      isValidatingNodesOnSaving,
      isCheckingRelaunchEnvOnSaving,
      isSavingJob,
      isPublishingSavedJob,
      isUpdatingJob,
      isUpdatingJobGlobalVariables
    ]
  );

  const disabledDestinationsActionMessage = useMemo(() => {
    if (!currentJobId) {
      return "Save job in order to use this option.";
    } else if (currentScenarioId !== jobRunConfigContextState?.values?.scenario) {
      return "Save any unsaved changes in order to use this option.";
    } else if (isSaving) {
      return "There is another action in progress. Please wait.";
    } else {
      return "";
    }
  }, [currentJobId, currentScenarioId, jobRunConfigContextState?.values?.scenario, isSaving]);

  useEffect(
    () => () => {
      // $FixMe: Below code should be removed, once this value is being read by jobRunConfigContextState.
      sessionStorage.removeItem("cronValueSession");
    },
    []
  );

  return (
    <>
      <JobDestinations
        isDestinationsOpen={isDestinationsOpen}
        close={() => setIsDestinationsOpen(() => false)}
        isDestinationSaving={isDestinationSaving}
        setIsDestinationSaving={setIsDestinationSaving}
      />

      {isLoading ? (
        <CommonLoader />
      ) : (
        <>
          <JobHeader
            projectId={projectId}
            saveJob={saveJob}
            isValidatingNodes={isValidatingNodes}
            isSaving={isSaving}
            isUpdatingJob={isUpdatingJob}
            updateJobMutation={updateJobMutation}
            resetUpdateJobMutation={resetUpdateJobMutation}
            isUpdatingJobStatus={isUpdatingJobStatus}
            updateJobStatusMutation={updateJobStatusMutation}
            resetUpdateJobStatusMutation={resetUpdateJobStatusMutation}
            updateJobTimeoutMutation={updateJobTimeoutMutation}
            resetUpdateJobTimeoutMutation={resetUpdateJobTimeoutMutation}
            isJobDeleting={isJobDeleting}
            deleteJobMutation={deleteJobMutation}
            resetDeleteJobMutation={resetDeleteJobMutation}
          />

          {/* @ts-ignore */}
          <Grid item xs={12} alignItem="center">
            <div className="container-height">
              <JobRunConfig>
                <Tooltip title={disabledDestinationsActionMessage}>
                  <Grid container alignItems="center" style={{ gap: "5px" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsDestinationsOpen(() => true)}
                      disabled={!!disabledDestinationsActionMessage}
                      startIcon={
                        (destinationsData || [])?.length > 0 ? <CreateIcon /> : <AddIcon />
                      }>
                      <Badge
                        variant="dot"
                        overlap="rectangular"
                        className={classes.customBadge}
                        color="error"
                        invisible={(destinationsData || [])?.length == 0}>
                        Destination
                      </Badge>
                    </Button>

                    <div>{isDestinationSaving && <CircularProgress size={20} />}</div>
                  </Grid>
                </Tooltip>
              </JobRunConfig>
              <Paper
                style={{
                  margin: "10px 16px",
                  width: "auto",
                  // 100vh - (TopNavBar 50px + SubTopNavBar 48px + RunConfigContainer etc. 124px)
                  height: "calc(100vh - 222px)"
                }}>
                <JobCanvas
                  isValidNodes={isValidNodes}
                  isValidatingNodes={isValidatingNodes}
                  isNodesValidated={isNodesValidated}
                  hideCanvasActions={false}
                  hideCanvasCompareAction={false}
                  isPublishingJob={isPublishingJob}
                  publishJobMutation={publishJobMutation}
                  resetPublishJobMutation={resetPublishJobMutation}
                  isJobPublished={isJobPublished}
                  updateJobGlobalVariablesMutation={updateJobGlobalVariablesMutation}
                  resetUpdateJobGlobalVariablesMutation={resetUpdateJobGlobalVariablesMutation}
                  isJobGlobalVariablesUpdated={isJobGlobalVariablesUpdated}
                />
              </Paper>
            </div>
          </Grid>
        </>
      )}
    </>
  );
};

export default Job;
