import React from "react";

// Packages
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";

// Open API
import { ProjectDto } from "@rapidcanvas/rc-api-core";

type Props = {
  project: ProjectDto;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link
        key="projects"
        to={`/projects/${project?.id}/canvas`}
        data-testid="scenariosBreadcrumbProjectName">
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText
        key="project-name"
        color="textSecondary"
        data-testid="scenariosBreadcrumbProjectName">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    <RenderText key="scenarios" color="textSecondary" data-testid="scenariosBreadcrumbScenarios">
      Scenarios
    </RenderText>
  );

  return (
    <Breadcrumbs data-testid="segmentBreadcrumbsContainer">
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
