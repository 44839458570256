import _ from "lodash";
import React, { useState } from "react";
import { Button, Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";

import AddRemoveToRecipeButton from "./AddRemoveToRecipeButton";
import AutoSelectDataset from "../AskAIInputDatasets/AutoSelectDataset";
import PinnedDatasets from "../AskAIInputDatasets/PinnedDatasets";
import {
  AIChatRequestDtoOutputTypeEnum,
  AIChatResponseDto,
  AddOutputRequestDtoFlowTypeEnum,
  OutputEntity,
  OutputEntityDto,
  OutputEntityDtoOutputTypeEnum
} from "@rapidcanvas/rc-api-core";
import { MODEL, ShowUpdateNameDialog } from "../AskAIResponseContainer/ShowUpdateNameDialog";
import { Spinner } from "src/components";
import {
  QUERY_KEY_RECIPE,
  useAddMessageToOutputMutation,
  useRemoveMessageFromOutputMutation
} from "src/hooks/api";
import { ViewCodeIcon } from "src/icons/NewUX/ViewCodeIcon";
import { ViewOutputIcon } from "src/icons/NewUX/ViewOutputIcon";
import { handleResponse } from "src/utils/apiService";
import { useQueryClient } from "@tanstack/react-query";
import { IExisingEntities } from "src/pages/Projects/AIGuide/common/AIGuideResponse";

const useStyles = makeStyles({
  recipeBtn: {
    backgroundColor: "#fff",
    height: "26px"
  },
  actions: {
    gap: "16px"
  },
  queryBtn: {
    borderRadius: "4px",
    background: "#fff",
    padding: "4px"
  }
});

export const AskAIHeaderActions = ({
  message,
  isOutputDataset,
  setShouldShowCode,
  shouldShowCode,
  existingEntityNames,
  isOutputModel,
  explainIsloading,
  onExplainCode,
  isRetryInProgress,
  explainCodeSuccess,
  isOutputText,
  isOutputChart
}: {
  message: AIChatResponseDto;
  isOutputDataset: boolean;
  setShouldShowCode: React.Dispatch<React.SetStateAction<boolean>>;
  shouldShowCode: boolean;
  targetId: string | undefined;
  isOutputChart: boolean;
  isOutputModel: boolean;
  existingEntityNames?: IExisingEntities;
  onExplainCode: () => void;
  explainIsloading: boolean;
  explainCodeSuccess: boolean;
  isRetryInProgress: boolean;
  isOutputText: boolean;
}) => {
  const classes = useStyles();
  const {
    transformId,
    outputEntityList,
    outputEntityResponseList,
    threadId,
    askAIMessageId,
    error
  } = message;
  const isAddedToRecipe = !!transformId;
  const [showUpdateNameDialog, setShowUpdateNameDialog] = useState(false);
  const addMessageToOutputMutation = useAddMessageToOutputMutation(threadId!);
  const removeMessageFromOutputMutation = useRemoveMessageFromOutputMutation(threadId!);

  const onRemoveFromRecipe = () => {
    removeMessageFromOutputMutation.mutate(
      {
        messageId: askAIMessageId!
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEY_RECIPE] });
        }
      }
    );
  };

  const queryClient = useQueryClient();

  const onAddToRecipe = (data: { string: string }) => {
    let nameChanges = _.map(_.entries(data), ([oldOutputName, newOutputName]) => ({
      oldName: oldOutputName,
      newName: newOutputName
    }));

    const { inputNames, existingEntities } = existingEntityNames ?? {
      inputNames: [],
      existingEntities: {}
    };

    const normalize = (arr: string[]) => arr.map((name) => _.toLower(name));

    let existingDatasets = normalize(inputNames);
    let existingModels: string[] = [];
    let currentDatasets: string[] = [];

    _.forEach(message.outputEntityList || message.outputEntityResponseList, (val) => {
      const name = _.get(val, "name");
      if (_.get(val, "outputType") === AIChatRequestDtoOutputTypeEnum.Dataset && name) {
        existingDatasets.push(_.toLower(name));
        currentDatasets.push(_.toLower(name));
      }
    });

    let nonIncludedDatasets: string[] = _.difference(existingDatasets, currentDatasets);

    _.forEach(existingEntities, (val, key) => {
      if (key !== message.askAIMessageId) {
        _.forEach(val, ({ name, outputType }) => {
          if (outputType === OutputEntityDtoOutputTypeEnum.Model) {
            existingModels.push(_.toLower(name));
          }
        });
      }
    });

    if (isOutputDataset) {
      const duplicates: string[] = [];

      _.forEach(data, (val) => {
        const lower = _.trim(_.toLower(val));
        if (_.includes(nonIncludedDatasets, lower) || _.includes(existingModels, lower)) {
          duplicates.push(val);
        }
      });
      if (duplicates.length > 0) {
        handleResponse({
          errorMessage: `The entity name(s) ${duplicates.join(", ")} are already being used in either the input/output dataset or another model. Please ensure that all names are unique.`
        });
        return;
      }
    }
    if (isOutputChart) {
      const values = normalize(_.values(data));
      const duplicates = _.keys(_.pickBy(_.countBy(values), (count) => count > 1)).map(String);
      if (duplicates.length > 0) {
        handleResponse({
          errorMessage: `The chart name(s) ${duplicates?.length ? `- ${duplicates.join(", ")}` : "provided"} are already in use in this recipe. Please ensure that all chart names are unique.`
        });
        return;
      }
    }

    if (isOutputModel) {
      const modelName = _.get(data, MODEL) as unknown as string;
      const charts = _.omit(data, MODEL);
      const lower = _.trim(_.toLower(modelName));
      const modelOldName = _.get(
        _.find(outputEntityResponseList || outputEntityList, {
          outputType: AIChatRequestDtoOutputTypeEnum.Model
        }),
        "name"
      ) as unknown as string;

      if (_.includes(existingDatasets, lower) || _.includes(existingModels, lower)) {
        handleResponse({
          errorMessage: `The model name(s) ${modelName} are already being used in either the input/output dataset or another model. Please ensure that all names are unique.`
        });
        return;
      }

      const values = normalize(_.values(charts));
      const duplicates = _.keys(_.pickBy(_.countBy(values), (count) => count > 1)).map(String);
      if (duplicates.length > 0) {
        handleResponse({
          errorMessage: `The chart name(s) ${duplicates?.length ? `- ${duplicates.join(", ")}` : "provided"} are already in use in this recipe. Please ensure that all chart names are unique.`
        });
        return;
      }

      nameChanges = _.map(_.entries(data), ([oldOutputName, newOutputName]) => ({
        oldName: oldOutputName === MODEL ? modelOldName : oldOutputName,
        newName: newOutputName
      }));
    }

    addMessageToOutputMutation.mutate(
      {
        messageId: askAIMessageId!,
        request: {
          flowType: AddOutputRequestDtoFlowTypeEnum.Recipe,
          nameChanges
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEY_RECIPE] });
          setShowUpdateNameDialog(false);
        }
      }
    );
  };

  const datasetName = _.first(_.map(outputEntityResponseList || outputEntityList, "name"));
  const isRecipeUpdating = removeMessageFromOutputMutation.isLoading;

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="flex-end"
      className={classes.actions}
      onClick={(e) => e.stopPropagation()}>
      {isOutputDataset && datasetName && (
        <>
          <PinnedDatasets name={datasetName} />
          <AutoSelectDataset name={datasetName} />
        </>
      )}

      {!!message.code && (
        <Tooltip title={shouldShowCode ? "View Output" : "View Code"}>
          <div>
            <IconButton
              className={classes.queryBtn}
              data-testid={`ask-ai-view-${shouldShowCode ? "output" : "code"}-btn`}
              onClick={(event: any) => {
                event.stopPropagation();
                setShouldShowCode((show) => !show);
              }}>
              {shouldShowCode ? <ViewOutputIcon /> : <ViewCodeIcon />}
            </IconButton>
          </div>
        </Tooltip>
      )}
      {shouldShowCode && (
        <Tooltip
          title={
            isRetryInProgress
              ? "Please wait until retry query succeeds"
              : explainCodeSuccess
                ? "Code explanation is already done"
                : ""
          }>
          <span>
            <Button
              color="primary"
              size="small"
              className={classes.recipeBtn}
              variant="outlined"
              data-testid={`ask-ai-view-explain-code-btn`}
              disabled={explainIsloading || explainCodeSuccess || isRetryInProgress}
              onClick={(event: any) => {
                event.stopPropagation();
                onExplainCode();
              }}>
              {explainIsloading ? <Spinner size={18} noPadding /> : "Explain"}
            </Button>
          </span>
        </Tooltip>
      )}

      {_.isEmpty(error) && !isOutputText && (
        <AddRemoveToRecipeButton
          isRetryInProgress={isRetryInProgress}
          isRecipeUpdating={isRecipeUpdating}
          isAddedToRecipe={isAddedToRecipe}
          disabled={isRecipeUpdating || addMessageToOutputMutation.isLoading || isRetryInProgress}
          onClick={() => {
            isAddedToRecipe ? onRemoveFromRecipe() : setShowUpdateNameDialog(true);
          }}
        />
      )}
      {showUpdateNameDialog && (
        <ShowUpdateNameDialog
          isSubmitLoading={addMessageToOutputMutation.isLoading}
          isSubmitDisabled={addMessageToOutputMutation.isLoading}
          onSubmit={onAddToRecipe}
          title={"Update Name"}
          onClose={() => {
            setShowUpdateNameDialog(false);
          }}
          label={isOutputDataset ? "Dataset Name" : "Chart Name"}
          queryOutputs={_.map(
            outputEntityResponseList || outputEntityList,
            (entity: OutputEntityDto | OutputEntity) => {
              return {
                name: entity.name as string,
                isModel: entity.outputType === AIChatRequestDtoOutputTypeEnum.Model
              };
            }
          )}
        />
      )}
    </Grid>
  );
};

export default React.memo(AskAIHeaderActions);
