import React, { useCallback, useMemo, useState } from "react";

// Packages
import clsx from "clsx";
import { capitalize, get, includes, isEmpty } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

// Utils
import { getEnvironmentUsageDetails } from "../../utils/Environments.helpers";

// Open API
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Hooks
import useStatus from "../../hooks/useStatus";

// Components
import StopEnvironment from "../StopEnvironment";

// Constants
import { EnvironmentStatusValuesMapping } from "src/pages/private/EnvironmentsModule/utils/Environments.constants";

// Styles
import useStyles from "./StatusBar.styles";

interface IProps {
  environment: EnvDto;
  isActions?: boolean;
  subtopBarStyles?: boolean;
  styleProps?: any;
}

const StatusBar: React.FC<IProps> = (props) => {
  const { environment, isActions = false, subtopBarStyles, styleProps } = props;
  const { id, launchStatus: status } = environment;

  const classes: any = useStyles();

  const [showConfirmStopEnvModal, setShowConfirmStopEnvModal] = useState<any>(null);
  const [isFetchingUsage, setIsFetchingUsage] = useState(false);

  const { onRelaunch, onStop, isRelaunching, isStopping } = useStatus({ environment });

  const { statusClass, statusText, showProgress } = useMemo(() => {
    let currentStatus;
    if (!!isRelaunching) currentStatus = EnvDtoLaunchStatusEnum.Launching;
    else if (!!isStopping || !!isFetchingUsage) currentStatus = EnvDtoLaunchStatusEnum.Stopping;
    else currentStatus = status;

    if (!currentStatus)
      return {
        statusClass: "",
        statusText: "",
        showProgress: false
      };

    const statusClass =
      classes[get(EnvironmentStatusValuesMapping, [currentStatus, "cssClassName"])] || "";

    const statusText = get(
      EnvironmentStatusValuesMapping,
      [currentStatus, "displayName"],
      capitalize(currentStatus) || "Unknown"
    );

    const showProgress = includes(
      [EnvDtoLaunchStatusEnum.Launching, EnvDtoLaunchStatusEnum.Stopping],
      currentStatus
    );

    return {
      statusClass,
      statusText,
      showProgress
    };
  }, [status, isRelaunching, isStopping, isFetchingUsage]);

  // Stop environment - STARTS >>
  const promptConfirmStopEnvironment = useCallback(async () => {
    if (!id || !status) return;

    setIsFetchingUsage(() => true);
    const types = await getEnvironmentUsageDetails(id);
    setIsFetchingUsage(() => false);

    if (isEmpty(types)) {
      onStop(id);
    } else {
      setShowConfirmStopEnvModal(() => ({ types, action: "stopping" }));
    }
  }, [id, status]);

  const resetStopEnvironment = useCallback(() => setShowConfirmStopEnvModal(() => null), []);

  const confirmStopEnvironment = useCallback(() => {
    resetStopEnvironment();
    onStop(id);
  }, [id]);
  // << ENDS - Stop environment

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    event?.preventDefault();

    status === EnvDtoLaunchStatusEnum.Success ? promptConfirmStopEnvironment() : onRelaunch(id);
  };

  return (
    <>
      {!!showConfirmStopEnvModal && (
        <StopEnvironment
          data={showConfirmStopEnvModal}
          confirmStopEnvironment={confirmStopEnvironment}
          resetStopEnvironment={resetStopEnvironment}
        />
      )}

      <Grid
        container
        className={clsx(classes.statusContainer, statusClass, {
          [classes.subtopBarStyles]: !!subtopBarStyles
        })}
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        {...styleProps}>
        <Grid item>
          <Typography variant="body2" data-testid="envStatusBarText">
            {statusText}
          </Typography>
        </Grid>
        {!!isActions && (
          <Grid item>
            {!!showProgress ? (
              <CircularProgress size={14} />
            ) : (
              <Button size="small" onClick={onClick} data-testid="envStatusBarStatusAction">
                {!!status && get(EnvironmentStatusValuesMapping, [status, "buttonText"])}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default StatusBar;
