import React, { useEffect } from "react";

// Stores
import { useToastsStore } from "src/store/store";
import { toastContentSetter } from "src/store/store.selectors";

// Styles
import "react-toastify/dist/ReactToastify.css";

import AppProviders from "src/AppProviders";
import AppRoutes from "src/AppRoutes";

const App: React.FC = () => {
  // Stores - STARTS >>
  const setToastContentStore = useToastsStore(toastContentSetter);
  // << ENDS - Stores

  // @TODO: The below logic is yet to be reconsidered.
  useEffect(() => {
    document.getElementById("splash-screen")?.remove();

    return () => {
      setToastContentStore(null);
    };
  }, []);

  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  );
};

export default App;
