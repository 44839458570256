import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import NewThemeWrapper from "src/styles/NewThemeWrapper";
import PredictionService from "pages/private/ProjectsModule/pages/PredictionService/components/PredictionService";
import ReactExceptionIcon from "src/icons/ReactExceptionIcon";
import Result from "src/components/Errors/Result";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import styles from "../ArtifactsAndModels.module.scss";
import usePredictionServiceByModel from "src/hooks/usePredictionServiceByModel";
import { ModelTable } from "./ModelTable";
import { TabPanel } from "src/components";
import { Tabs, Tab } from "src/components";
import { useGetJob, useGetJobRun } from "src/hooks/api";
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import CommonLoader from "src/components/CommonLoader";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";

export const TABS = {
  files: {
    value: "files",
    label: "Files"
  },
  predictionService: {
    value: "prediction-service",
    label: "Prediction Service"
  }
};

const useStyles = makeStyles({
  selectedButton: {
    background: "#e7e7ff !important",
    color: "#4646b5 !important"
  }
});

const ModelDetails = () => {
  const { modelName, projectId, scenarioId, jobId, jobRunId } = useParams<$TSFixMe>();
  const { pathname, state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isPredictionService = searchParams.get("isPredictionService");
  const [openLogsDrawer, setOpenLogsDrawer] = useState(false);
  const classes = useStyles();

  // Project context
  const { project } = useProjectContext() || {};

  const isJobPath = useMemo(() => /jobs/.test(pathname), [pathname]);

  // Query hooks - STARTS >>
  const { data: jobData } = useGetJob({ projectId, jobId });
  const { data: jobRunData } = useGetJobRun({ jobRunId, isApiWithRethrow: false });
  // << ENDS - Query hooks

  const [tab, setTab] = useState(
    isPredictionService ? TABS.predictionService.value : TABS.files.value
  );
  const [open, setOpen] = useState(false);

  const predictionServiceResults = usePredictionServiceByModel(modelName, scenarioId, jobRunId);
  const { data, isLoading, isError } = predictionServiceResults;
  const isProjectNav = location.pathname.includes("artifacts-and-model") && !!projectId;

  useEffect(() => {
    if (_.get(state, "tab") === TABS.predictionService.value) {
      setTab(TABS.predictionService.value);
    }
  }, []);

  useEffect(() => {
    if (tab === TABS.predictionService.value) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("isPredictionService", "true");
      setSearchParams(newParams, { replace: true });
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete("isPredictionService");
      setSearchParams(newParams, { replace: true });
    }
  }, [tab]);

  const handleChange = (newValue: string) => {
    setTab(newValue);
  };

  const handleViewChange = (__: React.MouseEvent<HTMLElement>, nextView: boolean) => {
    setOpen(nextView);
  };

  return (
    <>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunData?.entryDto?.runId}
              modelName={modelName}
              isProjectNav={isProjectNav}
            />
          )
        }}
      />

      <NewThemeWrapper>
        <Grid item xs={12} className={`${styles.container} container-height`}>
          <div className={styles.tabsSection}>
            {isError ? (
              <Result
                icon={<ReactExceptionIcon />}
                heading="Something went wrong"
                subtitle1="Error while fetching Model details"
              />
            ) : isLoading ? (
              <CommonLoader />
            ) : (
              <>
                <Grid container alignItems="center">
                  <Tabs
                    value={tab}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                    onChange={handleChange}>
                    <Tab value={TABS.files.value} label={TABS.files.label} />
                    {!isJobPath && (
                      <Tab
                        value={TABS.predictionService.value}
                        label={TABS.predictionService.label}
                      />
                    )}
                  </Tabs>
                  {tab === TABS.predictionService.value && data?.predictionServiceDetails?.id && (
                    <Grid container alignItems="center" style={{ gap: "5px" }}>
                      <span style={{ marginLeft: "auto" }}>
                        <ToggleButtonGroup
                          size="small"
                          color="primary"
                          orientation="horizontal"
                          value={open}
                          exclusive
                          onChange={handleViewChange}>
                          <ToggleButton
                            classes={{ selected: classes.selectedButton }}
                            data-testid="predictoinServiceTestButton"
                            disabled={!open}
                            style={{ padding: "5px", textTransform: "none" }}
                            value={false}>
                            <span> Test</span>
                          </ToggleButton>
                          <ToggleButton
                            data-testid="predictoinServiceLogButton"
                            classes={{ selected: classes.selectedButton }}
                            disabled={open}
                            style={{ padding: "5px", textTransform: "none" }}
                            value={true}>
                            <span>History</span>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </span>
                      {data?.predictionServiceDetails?.id && (
                        <Button
                          size="small"
                          style={{ minWidth: "32px", padding: "5px 4px" }}
                          variant="outlined"
                          onClick={() => setOpenLogsDrawer(true)}>
                          <LogsNewIconV2 width="22" height="22" color={"#4646b5"} />
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
                <div className={styles.tabPanelContainer}>
                  <TabPanel key={TABS.files.value} value={tab} index={TABS.files.value}>
                    <ModelTable modelName={modelName!} data={data} />
                  </TabPanel>
                  {!isJobPath && (
                    <TabPanel
                      key={TABS.predictionService.value}
                      value={tab}
                      index={TABS.predictionService.value}>
                      <PredictionService
                        modelName={modelName}
                        data={data}
                        open={open}
                        setOpen={setOpen}
                        openLogsDrawer={openLogsDrawer}
                        setOpenLogsDrawer={setOpenLogsDrawer}
                      />
                    </TabPanel>
                  )}
                </div>
              </>
            )}
          </div>
        </Grid>
      </NewThemeWrapper>
    </>
  );
};

export default ModelDetails;
