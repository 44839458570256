import AddIcon from "@material-ui/icons/Add";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PersonIcon from "@material-ui/icons/Person";
import React, { useState, useCallback } from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import shallow from "zustand/shallow";
import {
  Button,
  Menu,
  MenuItem,
  Grid,
  Divider,
  makeStyles,
  Box,
  Typography,
  ListItemIcon,
  useTheme,
  Tooltip
} from "@material-ui/core";
import { generatePath, useNavigate } from "react-router-dom";
import { TenantIcon } from "src/icons/NewUX/TenantIcon";
import { ArrowDownIcon } from "src/icons/NewUX/ArrowDownIcon";

import Analytics from "src/utils/analytics";
import InviteUsersModal from "src/pages/TenantManagement/TenantSettings/TenantUsersManagement/InviteUsersModal";
import useAuthStore from "src/stores/auth.store";
import useTenantsStore from "src/stores/tenant-management.store";
import { CreateTenantModal } from "src/pages/common/CreateTenantModal";
import { EVENTS } from "src/constants/events";
import { NotebookSizesListItems } from "./NotebookSizesListItems";
import { OverflowTooltip } from "src/components";
import { TenantSettingsListItems } from "./TenantSettingsListItems";
import { capitalizeFirstLetter } from "src/utils/capitalize";
import { handleResponse } from "src/utils/apiService";
import { useGetRole } from "src/hooks/useGetRole";
import { usePatchTenantMutation, useTenantSwitchMutation } from "src/hooks/api";
import { WebPaths } from "src/routing/routes";

const useStyles = makeStyles({
  tenantDetails: {
    maxWidth: "156px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  tenantDetailsName: {
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    fontWeight: "normal"
  },
  subTitle: {
    fontSize: "12px",
    marginBottom: "10px"
  },
  subTitle2: {
    fontSize: "12px",
    marginTop: "10px",
    color: "#A3A3A3"
  },
  menuIcon: {
    minWidth: 24
  },
  menuPaper: {
    borderRadius: "20px",
    maxWidth: "410px",
    background: "linear-gradient(36.71deg, #B8B8FF -1.63%, #FFE2D2 116.16%)",
    boxShadow: "0px 4px 4px 0px #3A3A3A40",
    border: "1px solid #FFFFFF",
    width: "320px"
  },
  tenantNameWrap: {
    flexWrap: "nowrap"
  },
  menuList: {
    paddingRight: "4px"
  },
  btn: {
    backgroundColor: "inherit"
  },
  wrap: {
    background: "#fff",
    border: "1px solid #C7C7C7",
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "20px",
    width: "100%",
    padding: "10px 0px"
  }
});

export const TopNavBarTenantMenu = () => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [openCreateTenantModal, setOpenCreateTenantModal] = useState(false);
  const [isNotebookSettingsMenuOpen, setIsNotebookSettingsMenuOpen] = useState(false);
  const [isTenantSettingsMenuOpen, setIsTenantSettingsMenuOpen] = useState(false);

  const patchTenantMutation = usePatchTenantMutation();
  const [tenantId, tenantName, cpuGuarantee, memGuarantee, hasPrivileges, licenseEnabled] =
    useAuthStore(
      (state) => [
        state.tenantId,
        state.tenantName,
        state.cpuGuarantee,
        state.memGuarantee,
        state.hasPrivileges,
        state.licenseEnabled
      ],
      shallow
    );
  const { isAdmin, hasAccess, hasDemoRole, isBusinessUser } = useGetRole();
  const [userTenants] = useTenantsStore((state) => [state.userTenants], shallow);

  const tenantSwitchMutation = useTenantSwitchMutation({
    onSuccess: () => {
      window.location.href = WebPaths.Dashboard;
      window.history.pushState(null, "", WebPaths.Dashboard);
    }
  });

  const handleClick = useCallback((e: $TSFixMe) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
    setIsNotebookSettingsMenuOpen(false);
    setIsTenantSettingsMenuOpen(false);
  };

  const updateNotebookConfig = ({
    memGuarantee,
    cpuGuarantee
  }: {
    memGuarantee: number;
    cpuGuarantee: number;
  }) => {
    patchTenantMutation.mutate(
      { name: tenantName || "", memGuarantee, cpuGuarantee, isForNotebookSizeUpdate: true },
      {
        onSuccess: () => {
          handleResponse({
            successMessage: `Updating the Notebook settings. Please wait for a few minutes. To see the updated settings, please relaunch the Notebook or log in again.`
          });
          handleClose();
        }
      }
    );
  };

  const onInviteUserClick = () => {
    handleClose();
    setShowAddUsersModal(true);
  };

  const handleTenantCreation = async () => {
    handleClose();
    setOpenCreateTenantModal(true);
    Analytics.track({
      category: EVENTS.createTenant.category,
      action: EVENTS.createTenant.action,
      label: EVENTS.createTenant.category
    });
  };

  const navigateToTenantPage = (events: any) => {
    handleClose();
    Analytics.track({
      category: EVENTS.tenantSwitch.category,
      action: EVENTS.tenantSwitch.action,
      label: EVENTS.tenantSwitch.category
    });

    if (events.metaKey || events.ctrlKey) {
      const newTab = window.open();
      if (newTab) {
        newTab.opener = null; // Security measure
        newTab.location.href = generatePath(WebPaths.TenantManagement, {
          tenantId
        }); // Construct the full URL
      }
    } else {
      navigate(
        generatePath(WebPaths.TenantManagement, {
          tenantId
        })
      );
    }
  };

  const onTenantSwitch = (tenantId: string) => {
    tenantSwitchMutation.mutate({ tenantId });
    Analytics.track({
      category: EVENTS.tenantSwitch.category,
      action: EVENTS.tenantSwitch.action,
      label: EVENTS.tenantSwitch.category
    });
  };

  const canCreateTenant = licenseEnabled
    ? (userTenants as $TSFixMe)?.find((tenant: $TSFixMe) => tenant?.id === tenantId)
        ?.canCreateTenant
    : true;

  return (
    <>
      <Button
        className={classes.btn}
        startIcon={<TenantIcon color={theme.palette.text.primary} opacity={0.3} />}
        endIcon={<ArrowDownIcon color={theme.palette.text.primary} opacity={0.3} />}
        onClick={handleClick}
        data-testid="top-nav-bar-workspace-menu"
        color="primary">
        <Grid className={classes.tenantDetails}>
          <Tooltip title={tenantName ?? ""}>
            <Typography
              color="textPrimary"
              variant="body2"
              component="span"
              className={classes.tenantDetailsName}>
              {tenantName}
            </Typography>
          </Tooltip>
        </Grid>
      </Button>
      <Menu
        classes={{
          paper: classes.menuPaper,
          list: classes.menuList
        }}
        transitionDuration={{ exit: 0 }}
        onClose={handleClose}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}>
        <Box p="20px">
          <Typography className={classes.subTitle} data-testid="workspace-header">
            WORKSPACE
          </Typography>
          <div className={classes.wrap}>
            {isNotebookSettingsMenuOpen ? (
              <NotebookSizesListItems
                onClose={() => setIsNotebookSettingsMenuOpen(false)}
                cpuGuaranteeUpdating={patchTenantMutation?.variables?.cpuGuarantee}
                memGuaranteeUpdating={patchTenantMutation?.variables?.memGuarantee}
                memGuarantee={memGuarantee}
                cpuGuarantee={cpuGuarantee}
                isLoading={patchTenantMutation?.isLoading}
                onUpdateConfig={updateNotebookConfig}
              />
            ) : isTenantSettingsMenuOpen ? (
              <TenantSettingsListItems
                userTenants={userTenants}
                tenantId={tenantId}
                onClose={() => setIsTenantSettingsMenuOpen(false)}
                onTenantSwitch={onTenantSwitch}
                isLoading={tenantSwitchMutation?.isLoading}
                tenantSwitchId={tenantSwitchMutation?.variables?.tenantId}
              />
            ) : (
              <div>
                <MenuItem
                  onClick={() => setIsTenantSettingsMenuOpen(true)}
                  data-testid="workspace-menu-tenant-name">
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.tenantNameWrap}>
                    {tenantName && (
                      <>
                        <OverflowTooltip
                          tooltipProps={{ placement: "left" }}
                          value={<Typography>{capitalizeFirstLetter(tenantName)}</Typography>}
                          title={tenantName}
                        />
                        <ChevronRightIcon fontSize="small" />
                      </>
                    )}
                  </Grid>
                </MenuItem>
                {(isAdmin || hasDemoRole || isBusinessUser) && (
                  <div>
                    <MenuItem onClick={navigateToTenantPage} test-id="tenant-menu-tenant-settings">
                      <ListItemIcon className={classes.menuIcon}>
                        <SettingsIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="body2" color="primary">
                        Settings
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      disabled={!hasPrivileges && !isBusinessUser}
                      onClick={onInviteUserClick}
                      data-testid="workspace-menu-invite-users">
                      <ListItemIcon className={classes.menuIcon}>
                        <PersonIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="body2" color="primary">
                        Invite User
                      </Typography>
                    </MenuItem>
                  </div>
                )}
                {(isAdmin || hasDemoRole) && (
                  <MenuItem
                    disabled={!hasPrivileges || !canCreateTenant}
                    onClick={handleTenantCreation}
                    data-testid="workspace-menu-create-tenant">
                    <ListItemIcon className={classes.menuIcon}>
                      <AddIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body2" color="primary">
                      Create a new workspace
                    </Typography>
                  </MenuItem>
                )}
                {hasAccess && (
                  <div>
                    <Divider />
                    <Box p="6px 16px 0px 16px">
                      <Typography className={classes.subTitle2}>MY NOTEBOOK SIZE</Typography>
                    </Box>
                    <MenuItem
                      onClick={() => setIsNotebookSettingsMenuOpen(true)}
                      test-id="tenant-menu-notebook-size">
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Typography>{`${memGuarantee} GB RAM & ${cpuGuarantee} GHz CPU`}</Typography>
                        <ChevronRightIcon fontSize="small" />
                      </Grid>
                    </MenuItem>
                  </div>
                )}
              </div>
            )}
          </div>
        </Box>
      </Menu>
      {showAddUsersModal && <InviteUsersModal onClose={() => setShowAddUsersModal(false)} />}
      {openCreateTenantModal && (
        <CreateTenantModal
          open={openCreateTenantModal}
          onClose={() => setOpenCreateTenantModal(false)}
          licenseEnabled={licenseEnabled}
        />
      )}
    </>
  );
};
