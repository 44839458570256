import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { handleResponse } from "src/utils/apiService";
import api from "src/utils/AxiosClient";

interface IVariables {
  projectId: string;
  name: string;
  path: string;
}

const useAddPredictionOutputToCanvas = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  return useMutation({
    mutationFn: async ({ projectId, name, path }) => {
      const response = await api.fetchResponse(
        async () =>
          await api.EntityControllerApi.addEntityToProject({
            projectId,
            name,
            entityMeta: { entityOntology: "USER", entityType: "EVENT" }
          })
      );
      const id = response.id;

      if (id) {
        try {
          const data = await api.fetchResponse(
            async () => await api.EntityControllerApi.processUploadedFile1(id, [name], path)
          );

          return data;
        } catch {
          await api.ProjectsControllerV2Api.deleteStep(id, "ENTITY");

          throw "Error";
        }
      }
    },
    onSuccess: () => {
      handleResponse({ successMessage: "Output successfully added to canvas" });
    }
  });
};

export default useAddPredictionOutputToCanvas;
