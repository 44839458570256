import React from "react";

// Packages
import _ from "lodash";
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";
import { TABS } from "src/pages/Library/ArtifactsAndModels/Models/ModelDetails";

type Props = {
  predictionServiceId: string;
  modelName: string | undefined;
  predictionServiceName: string | undefined;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { modelName, predictionServiceName } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    modelName ? (
      <Link key="model" to={`/artifacts-and-models/model/${modelName}`}>
        <RenderText>{modelName || "Model"}</RenderText>
      </Link>
    ) : (
      <RenderText key="recipe" color="textSecondary">
        {"Model"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    predictionServiceName && modelName ? (
      <Link
        key="model"
        to={`/artifacts-and-models/model/${modelName}`}
        state={{
          tab: TABS.predictionService.value,
          predictionServiceDetails: predictionServiceName
        }}>
        <RenderText>{predictionServiceName || "Prediction Service"}</RenderText>
      </Link>
    ) : (
      <RenderText key="recipe" color="textSecondary">
        {"Prediction Service"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    <RenderText key="logs" color="textSecondary">
      Logs
    </RenderText>
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
