import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { generatePath, useParams, useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  useSaveCodeRecipeMutation,
  useUpdateTransformGroupMutation,
  useTestTransformGroupV2Mutation,
  useRunTransformGroupMutation,
  useDeleteTransformMutation,
  useSaveCodeRecipeMutationV2,
  useGetInputEntities,
  useGetCodeCheckpoints,
  useSaveCodeCheckpoints
} from "src/hooks/api";
import { useGetJob, useGetJobRun } from "src/hooks/api";
import { handleResponse } from "src/utils/apiService";
import {
  ArtifactMini,
  Entities,
  Entity,
  EntityFeaturesResponse,
  EntityTypeEnum,
  ModelMini,
  Recipe
} from "src/types";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { ApiConnectorRecipeContext } from "./ApiConnectorRecipeContext";
import { useTransformErrorUtils } from "../../common/hooks/useTransformErrorUtils";
import { RecipeStatuses } from "src/constants";
import { usePollRecipe } from "src/hooks/api/transforms/usePollRecipe";
import { WebPaths } from "src/routing/routes";
import useCreateApiConnectorRecipe, {
  IParent
} from "src/hooks/api/codeRecipe/useCreateApiConnectorRecipe";
import { useAutoSaveCodeRecipeMutation } from "src/hooks/api/codeRecipe/useAutoSaveCodeRecipeMutation";
import useAutoSave from "src/hooks/useAutoSave";
import _, { first, get, includes, isEmpty, map, size } from "lodash";
import useUpdateRecipeName from "src/hooks/api/transforms/useUpdateRecipeName";
import useUpdateRecipeTimeout from "src/hooks/api/transforms/useUpdateRecipeTimeout";
import useUnbuiltRecipe from "src/hooks/api/transforms/useUnbuiltRecipe";
import { useQuery } from "src/hooks";
import useUpdateRecipeCondition from "src/hooks/api/transforms/useUpdateRecipeCondition";
import CommonLoader from "src/components/CommonLoader";

export enum OUTPUT_TYPE {
  ARTIFACT = "ARTIFACT",
  DATASET = "DATASET",
  CHART = "CHART",
  TEXT = "TEXT",
  AUTO_INFER = "AUTO_INFER",
  PROMPT_SUGGESTIONS = "PROMPT_SUGGESTIONS",
  MODEL = "MODEL"
}

export const ApiConnectorRecipeContextProvider = ({
  templates: initialTemplates,
  recipe: initialRecipe,
  entityIds,
  modelsIds,
  artifactsIds,
  children
}: $TSFixMe) => {
  const { projectId, scenarioId, jobId, jobRunId, groupId } = useParams();
  const [recipe, setRecipe] = React.useState<Recipe | null>(initialRecipe || null);
  const [recipeName, setRecipeName] = React.useState<string>(recipe?.name || "");
  const [templates, setTemplates] = React.useState<$TSFixMe[]>(initialTemplates || []);
  const [editorValue, setEditorValue] = React.useState("");
  const [savedEditorValue, setSavedEditorValue] = React.useState("");
  const [selectedApiRecipe, setSelectedApiRecipe] = React.useState<Record<string, string>[]>([]);
  const [currentSelectedApiRecipe, setCurrentSelectedApiRecipe] = React.useState<string>();
  const testControllerRef = useRef<AbortController>();
  const isStopped = useRef<boolean>(false);
  const [defaultCode, setDefaultCode] = useState<string>();
  const [isCheckpointSaveInProgress, setIsCheckpointSaveInProgress] = useState<boolean>(false);

  const [previewTabs, setPreviewTabs] = React.useState<string[]>([]);
  const [inputDatasets, setInputDatasets] = React.useState<Array<Entity>>([]);
  const [selectedArtifacts, setSelectedArtifacts] = React.useState<Array<ArtifactMini>>([]);
  const [selectedModels, setSelectedModels] = React.useState<Array<ModelMini>>([]);
  const [isTestInProgress, setIsTestInProgress] = React.useState(false);
  const [codeErrorDetails, setCodeErrorDetails] = React.useState<{
    lineNo: number;
    line: string;
    errorExplanation?: string;
  } | null>(null);
  const [isSelectedEntitiesUpdateInProgess, setIsSelectedEntitiesUpdateInProgess] =
    React.useState<boolean>(false);
  const [entityFeaturesMap, setEntityFeaturesMap] = React.useState<{
    [id: string]: EntityFeaturesResponse;
  } | null>(null);

  const navigate = useNavigate();
  const queryParameters = useQuery();

  useEffect(() => {
    setTemplates(initialTemplates);
    if (!isEmpty(initialTemplates)) {
      setEditorValue(initialTemplates?.map((template: any) => template.code)?.join("\r\n\n"));
    } else {
      setEditorValue(initialRecipe?.defaultCode);
      setDefaultCode(initialRecipe?.defaultCode);
    }
  }, [initialTemplates]);

  useEffect(() => {
    setRecipe(initialRecipe);
  }, [initialRecipe]);

  const onUpdateRecipe = (recipe: Recipe) => {
    setRecipeName(recipe?.name);
    setRecipe(recipe);
  };

  const updateTransformGroupMutation = useUpdateTransformGroupMutation();
  const updateTransformGroupName = useUpdateRecipeName();
  const updateTransformGroupCondition = useUpdateRecipeCondition();
  const saveCodeCheckpoints = useSaveCodeCheckpoints();

  const saveCodeRecipeMutation = useSaveCodeRecipeMutation();
  const saveAndTestCodeRecipeMutation = useSaveCodeRecipeMutation();
  const updateTranformGroupTimeout = useUpdateRecipeTimeout();
  const unbuiltRecipe = useUnbuiltRecipe();

  const testTransformGroupMutation = useTestTransformGroupV2Mutation();
  const runTransformGroupMutation = useRunTransformGroupMutation();
  const saveCodeRecipeMutationV2 = useSaveCodeRecipeMutationV2();
  const deleteTransformMutation = useDeleteTransformMutation();
  const { fetchAndRenderFullErrorLogs, renderTestTransformErrors } = useTransformErrorUtils();
  const { data: jobData } = useGetJob({ projectId, jobId });
  const { data: jobRunData } = useGetJobRun({ jobRunId, isApiWithRethrow: false });
  const { data: codeCheckpoints, isLoading: isLoadingCodeCheckpoints } = useGetCodeCheckpoints({
    recipeId: recipe?.id
  });

  const handleInputDatasetsChange = (
    newEntities: Entities,
    artifacts?: ArtifactMini[],
    models?: ModelMini[]
  ) => {
    if (!recipe?.id) {
      setInputDatasets(newEntities);
      if (artifacts) {
        setSelectedArtifacts(artifacts);
      }
      if (models) {
        setSelectedModels(models);
      }
      return;
    }
    const newArtifacts =
      artifacts?.map((artifact: $TSFixMe) => ({
        name: artifact.name,
        type: EntityTypeEnum.ARTIFACT
      })) || [];
    const newModels =
      models?.map((model: $TSFixMe) => ({
        name: model.name,
        type: EntityTypeEnum.MODEL
      })) || [];
    setIsSelectedEntitiesUpdateInProgess(true);
    const payload = {
      parents: [
        ...newEntities?.map((entity) => ({ id: entity.id, type: "ENTITY" })),
        ...newArtifacts,
        ...newModels
      ]
    };
    const onSuccess = () => {
      setInputDatasets(newEntities);
      if (artifacts) {
        setSelectedArtifacts(artifacts);
      }
      if (models) {
        setSelectedModels(models);
      }
      setIsSelectedEntitiesUpdateInProgess(false);
    };
    handleUpdateRecipe({ payload, onSuccess });
  };

  const handleSaveSuccess = ({
    showSuccessMsg = true,
    templatesResponse,
    transformGroupResponse,
    silent = false
  }: any) => {
    !silent &&
      showSuccessMsg &&
      handleResponse({
        successMessage: `Recipe code is saved successfully.`
      });

    setTemplates(templatesResponse);
    onUpdateRecipe(transformGroupResponse);

    setEditorValue(templatesResponse?.map((template: any) => template.code)?.join("\r\n\n"));
  };

  //Auto save recipe every two minutes
  useAutoSave(() => {
    autoSaveRecipe();
  }, 120 * 1000);

  const handleAsyncSave = React.useCallback(async () => {
    await saveCodeRecipeMutation.mutateAsync(
      {
        codeStrings: [editorValue],
        projectId: projectId!,
        recipe,
        templates,
        groupId
      },
      {
        onSuccess: ({ templatesResponse, transformGroupResponse }) => {
          handleSaveSuccess({ templatesResponse, transformGroupResponse, silent: true });
        }
      }
    );
  }, [editorValue, projectId, recipe, saveCodeRecipeMutation, templates]);

  const handleSave = (params?: {
    onError?: () => void;
    onSuccess?: ({
      transformGroupResponse,
      silent
    }: {
      transformGroupResponse: Recipe;
      silent: boolean;
    }) => void;
    isAutoSaving?: boolean;
    code?: string;
  }) => {
    if (!params?.code && !editorValue) {
      return;
    }
    saveCodeRecipeMutation.mutate(
      {
        codeStrings: params?.code ? [params?.code] : [editorValue],
        projectId: projectId!,
        recipe,
        templates,
        groupId,
        isAutoSaving: params?.isAutoSaving || false
      },
      {
        onSuccess: ({ templatesResponse, transformGroupResponse }) => {
          if (recipe?.id) {
            unbuiltRecipe.mutate({ groupId: recipe.id });
          }
          const saveResult = {
            templatesResponse,
            transformGroupResponse,
            silent: !!params?.isAutoSaving
          };
          handleSaveSuccess(saveResult);
          params?.onSuccess?.(saveResult);
        },
        onError: () => {
          params?.onError?.();
        }
      }
    );
  };

  const handleStopTesting = () => {
    isStopped.current = true;
    testTransformGroupMutation.reset();
    testControllerRef.current?.abort();
  };

  const handleTest = React.useCallback(
    (sampleRows?: number, editVal?: string) => {
      setIsTestInProgress(true);

      saveAndTestCodeRecipeMutation.mutate(
        {
          codeStrings: [editVal ?? editorValue],
          projectId: projectId!,
          recipe,
          templates,
          groupId: groupId || recipe?.id
        },
        {
          onSuccess: ({ templatesResponse, transformGroupResponse }) => {
            handleSaveSuccess({
              templatesResponse,
              transformGroupResponse,
              showSuccessMsg: false
            });
            const newController = new AbortController();
            testControllerRef.current = newController;
            testTransformGroupMutation.mutate(
              {
                recipeId: groupId || recipe?.id,
                projectId,
                sampleRows,
                testTransformConfig: transformGroupResponse?.runConfigs?.map((config: any) => ({
                  ...config,
                  templateId: config.templateId,
                  projectId,
                  name: config.name
                })),
                controller: newController
              },
              {
                onSuccess: (response: $TSFixMe) => {
                  if (isStopped.current || !response) {
                    isStopped.current = false;
                    return;
                  }
                  const outputDatasets = response?.dataMap
                    ? Object.keys(response?.dataMap).map((item) => ({
                        name: item,
                        data: response?.dataMap?.[item],
                        type: OUTPUT_TYPE.DATASET,
                        id: uuidv4()
                      }))
                    : [];
                  const outputCharts =
                    response?.entityViewData.map((item: $TSFixMe) => ({
                      ...item,
                      type: OUTPUT_TYPE.CHART,
                      id: uuidv4()
                    })) || [];
                  const outputModels = response?.entityModelData
                    ? response?.entityModelData.map((item: any) => ({
                        name: item.name,
                        data: item.name,
                        type: OUTPUT_TYPE.MODEL,
                        id: uuidv4()
                      }))
                    : [];
                  const outputArtifacts = response?.entityArtifactData
                    ? response?.entityArtifactData.map((item: any) => ({
                        name: item.name,
                        data: item.name,
                        type: OUTPUT_TYPE.ARTIFACT,
                        id: uuidv4()
                      }))
                    : [];

                  setPreviewTabs([
                    ...outputDatasets,
                    ...outputCharts,
                    ...outputArtifacts,
                    ...outputModels
                  ]);
                  setCodeErrorDetails(null);
                  handleResponse({ successMessage: `Test successful` });
                },
                onError: (error: $TSFixMe) => {
                  renderTestTransformErrors(error, false, true);
                  const responseData = error?.response?.data;
                  responseData?.extraInfo?.runErrDetails &&
                    setCodeErrorDetails(responseData?.extraInfo?.runErrDetails);
                },
                onSettled: () => {
                  setIsTestInProgress(false);
                }
              }
            );
          },
          onError: (error) => {
            if (isStopped.current || !error) {
              isStopped.current = false;
              return;
            }
            setIsTestInProgress(false);
          }
        }
      );
    },
    [
      editorValue,
      fetchAndRenderFullErrorLogs,
      projectId,
      recipe,
      saveAndTestCodeRecipeMutation,
      templates,
      testTransformGroupMutation
    ]
  );

  const onRunSuccess = (response: any) => {
    if (response && !response.msg) {
      setCodeErrorDetails(null);
      handleResponse({ successMessage: `Run successful` });
    }
  };

  const onRunError = (error: $TSFixMe) => {
    fetchAndRenderFullErrorLogs(error, { projectId, scenarioId, groupId: recipe?.id }, false, true);
    const responseData = error?.response?.data;
    responseData?.extraInfo && setCodeErrorDetails(responseData?.extraInfo?.runErrDetails);
  };

  const handleRun = React.useCallback(async () => {
    if (!recipe?.id) {
      return;
    }
    projectId && checkEnvRelaunch(projectId);

    let timeoutId: $TSFixMe;

    runTransformGroupMutation.mutate(
      { recipeId: recipe?.id ?? groupId, scenarioId: scenarioId! },
      {
        onSuccess: onRunSuccess,
        onError: onRunError
      }
    );

    return () => clearTimeout(timeoutId);
  }, [projectId, recipe?.id, runTransformGroupMutation, scenarioId, onRunSuccess, onRunError]);

  const handleUpdateRecipe = React.useCallback(
    async ({
      payload,
      onSuccess,
      onError
    }: {
      payload: $TSFixMe;
      onError?: () => void;
      onSuccess?: (recipe: Recipe) => void;
    }) => {
      const runConfigs = recipe?.runConfigs?.length
        ? recipe?.runConfigs?.map((item) => item.id)
        : [];
      const updatedPayload = {
        ...recipe,
        runConfigs,
        projectId,
        name: recipe?.name || recipeName,
        displayName: recipe?.displayName || recipe?.name || recipeName,
        parents: recipe?.parents,
        ...payload
      };
      updateTransformGroupMutation.mutate(
        {
          recipeId: recipe?.id,
          transformGroup: updatedPayload,
          projectId: projectId!
        },
        {
          onSuccess: (response: $TSFixMe) => {
            onUpdateRecipe(response);
            onSuccess?.(response);
          },
          onError: () => {
            onError?.();
          }
        }
      );
    },
    [projectId, recipe, recipeName, updateTransformGroupMutation]
  );
  const handleUpdateRecipeTimeout = React.useCallback(
    async ({
      payload,
      onSuccess,
      onError
    }: {
      payload: $TSFixMe;
      onError?: () => void;
      onSuccess?: (recipe: Recipe) => void;
    }) => {
      if (recipe?.id)
        updateTranformGroupTimeout.mutate(
          {
            groupId: recipe?.id,
            timeout: payload.timeout,
            condition: recipe.condition
          },
          {
            onSuccess: (response: $TSFixMe) => {
              onUpdateRecipe(response);
              onSuccess?.(response);
            },
            onError: () => {
              onError?.();
            }
          }
        );
    },
    [projectId, recipe, recipeName, updateTranformGroupTimeout]
  );
  const handleUpdateRecipeName = React.useCallback(
    async ({
      payload,
      onSuccess,
      onError
    }: {
      payload: $TSFixMe;
      onError?: () => void;
      onSuccess?: (recipe: Recipe) => void;
    }) => {
      if (recipe?.id)
        updateTransformGroupName.mutate(
          {
            groupId: recipe?.id,
            displayName: payload.displayName,
            condition: recipe.condition
          },
          {
            onSuccess: (response: $TSFixMe) => {
              onUpdateRecipe(response);
              onSuccess?.(response);
            },
            onError: () => {
              onError?.();
            }
          }
        );
    },
    [projectId, recipe, recipeName, updateTransformGroupMutation]
  );

  const handleUpdateRecipeCondition = React.useCallback(
    async ({
      payload,
      onSuccess,
      onError
    }: {
      payload: $TSFixMe;
      onError?: () => void;
      onSuccess?: (recipe: Recipe) => void;
    }) => {
      if (recipe?.id)
        await updateTransformGroupCondition.mutateAsync(
          {
            groupId: recipe?.id,
            condition: payload.condition
          },
          {
            onSuccess: (response: $TSFixMe) => {
              onUpdateRecipe(response);
              onSuccess?.(response);
            },
            onError: () => {
              onError?.();
            }
          }
        );
    },
    [projectId, recipe, recipeName, updateTransformGroupMutation]
  );

  const {
    data: runningRecipe,
    isFetched: isFetchedRunningRecipe,
    isError,
    isSuccess,
    error: runError
  } = usePollRecipe({
    enabled: !!(recipe?.id && recipe?.status === RecipeStatuses.Running),
    recipeId: recipe?.id,
    scenarioId
  });

  useEffect(() => {
    if (isSuccess && runningRecipe?.status === RecipeStatuses.Success) {
      onRunSuccess(runningRecipe);
    } else if (isSuccess && runningRecipe?.status === RecipeStatuses.Error) {
      onRunError(runError);
    }
  }, [runningRecipe, runError]);

  useEffect(() => {
    isError && onRunError(runError);
  }, [runError]);

  const isRunInProgress = React.useMemo(
    () =>
      isFetchedRunningRecipe
        ? runningRecipe?.status === RecipeStatuses.Running
        : runTransformGroupMutation.isLoading || recipe?.status === RecipeStatuses.Running,
    [recipe, runTransformGroupMutation.isLoading, runningRecipe?.status, isFetchedRunningRecipe]
  );

  const isSaveInProgress = React.useMemo(
    () => saveCodeRecipeMutation.isLoading,
    [saveCodeRecipeMutation.isLoading]
  );
  const isAskAiRecipeUpdateInProgress =
    saveCodeRecipeMutationV2.isLoading || deleteTransformMutation.isLoading;

  const autoSaveCodeRecipeMutation = useAutoSaveCodeRecipeMutation();
  const autoSaveRecipe = useCallback(() => {
    if (
      !!groupId &&
      !!editorValue &&
      savedEditorValue !== editorValue &&
      !isTestInProgress &&
      !isRunInProgress &&
      !isSaveInProgress &&
      !isAskAiRecipeUpdateInProgress
    ) {
      autoSaveCodeRecipeMutation.mutate(
        {
          codeStrings: [editorValue],
          projectId: projectId!,
          recipe,
          templates,
          groupId
        },
        {
          onSuccess: (autoSavedRecipeResponse) => {
            const { templatesResponse, transformGroupResponse } = autoSavedRecipeResponse;
            const savedEditorValue = templatesResponse
              ?.map((template: any) => template.code)
              ?.join("\r\n\n");
            setSavedEditorValue(savedEditorValue);
            setTemplates(templatesResponse);
            onUpdateRecipe(transformGroupResponse);
          }
        }
      );
    }
  }, [
    groupId,
    editorValue,
    savedEditorValue,
    recipe,
    templates,
    projectId,
    isTestInProgress,
    isRunInProgress,
    isSaveInProgress
  ]);

  const isAutoSaving =
    autoSaveCodeRecipeMutation.isLoading ||
    (saveCodeRecipeMutation.variables?.isAutoSaving ? saveCodeRecipeMutation.isLoading : false);

  const isDeletableRecipe = useMemo(
    () =>
      !recipe?.condition?.expression &&
      recipe?.runConfigs?.length === 0 &&
      (!editorValue || editorValue === defaultCode) &&
      previewTabs?.length === 0,
    [recipe, editorValue, previewTabs, defaultCode]
  );

  const isSaveDisabled =
    !editorValue ||
    isSaveInProgress ||
    isAutoSaving ||
    isTestInProgress ||
    isAskAiRecipeUpdateInProgress;

  const saveToolTip = !editorValue
    ? "Add code to enable save"
    : isAutoSaving
      ? "Autosaving code"
      : undefined;
  const runTooltip = !editorValue
    ? "Add code to enable run"
    : isRunInProgress
      ? "Recipe run is in progress"
      : !recipe?.id
        ? "Please save your changes to enable this"
        : undefined;
  const testTooltip = !editorValue ? "Add  code to enable test" : undefined;
  const isRunDisabled =
    !recipe?.id ||
    !editorValue ||
    isRunInProgress ||
    isAskAiRecipeUpdateInProgress ||
    templates?.length === 0;
  const isTestDisabled =
    !editorValue ||
    isTestInProgress ||
    isSaveInProgress ||
    isAutoSaving ||
    isRunInProgress ||
    isAskAiRecipeUpdateInProgress;

  const onAddCodeToRecipe = React.useCallback(
    ({
      code: newCode,
      onSuccess,
      codeHistoryId
    }: {
      code: string;
      onSuccess?: (transformId: string) => void;
      codeHistoryId: string;
    }) => {
      saveCodeRecipeMutationV2.mutate(
        {
          recipe,
          code: newCode,
          codeHistoryId,
          projectId: projectId!
        },
        {
          onSuccess: ({ templateResponse, saveTransformResponse, transformGroupResponse }) => {
            const updatedTemplates = [...templates, templateResponse];
            onUpdateRecipe(transformGroupResponse);
            setTemplates((templates: $TSFixMe) => [...templates, templateResponse]);
            setEditorValue(updatedTemplates?.map((template) => template.code)?.join("\r\n\n"));
            onSuccess?.(saveTransformResponse.id);
          }
        }
      );
    },
    [projectId, recipe, saveCodeRecipeMutationV2, templates]
  );

  const onRemoveCodeFromRecipe = React.useCallback(
    ({ transformId, onSuccess }: { transformId: string; onSuccess: () => void }) => {
      recipe &&
        deleteTransformMutation.mutate(
          { transformId, recipeId: recipe.id },
          {
            onSuccess: ({ transformGroupResponse }: { transformGroupResponse: Recipe }) => {
              onUpdateRecipe(transformGroupResponse);
              const recipeTemplateIds =
                transformGroupResponse?.runConfigs?.map((config) => config.templateId) || [];
              const updatedTemplates = templates?.filter((currTemplate) =>
                recipeTemplateIds.includes(currTemplate.id)
              );
              const updatedCode = updatedTemplates?.map((template) => template.code);
              setTemplates(updatedTemplates);
              setEditorValue(updatedCode?.join("\r\n\n"));
              onSuccess?.();
            }
          }
        );
    },
    [deleteTransformMutation, recipe, templates]
  );

  const isQueryAssociatedWithRecipe = React.useCallback(
    (identifier: string) => {
      return (
        (deleteTransformMutation.isLoading &&
          deleteTransformMutation.variables?.transformId === identifier) ||
        (saveCodeRecipeMutationV2.isLoading &&
          saveCodeRecipeMutationV2.variables?.codeHistoryId === identifier)
      );
    },
    [deleteTransformMutation.isLoading, saveCodeRecipeMutationV2.isLoading]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const entity = searchParams.get("entity");
  const spArtifact = searchParams.get("artifact");
  const spModel = searchParams.get("model");
  const selectedEntitiesStr = sessionStorage.getItem("selectedEntities");
  const selectedEntities = selectedEntitiesStr ? JSON.parse(selectedEntitiesStr) : null;
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const {
    isLoading,
    isSuccess: isEntitiesFetched,
    data: allEntities
  } = useGetInputEntities({
    id: projectId,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {})
  });

  useEffect(() => {
    const filteredInputDatasets = allEntities?.filter(
      (dataset) =>
        entityIds?.includes(dataset.id) ||
        queryParameters?.get("entity") === dataset.id ||
        (selectedEntities?.includes(dataset.id) &&
          dataset.entityMeta?.entityViewType?.toLowerCase() !== "chart" &&
          dataset.entityMeta?.entityViewType?.toLowerCase() !== "artifact" &&
          dataset.entityMeta?.entityViewType?.toLowerCase() !== "model")
    );

    const models = map(
      allEntities?.filter(
        (dataset) => queryParameters?.get("model") === dataset.id || modelsIds?.includes(dataset.id)
      ),
      (model: $TSFixMe) => ({
        name: model.name,
        type: EntityTypeEnum.MODEL
      })
    );

    const artifacts = map(
      allEntities?.filter(
        (dataset) =>
          queryParameters?.get("artifact") === dataset.id || artifactsIds?.includes(dataset.id)
      ),
      (artifact: $TSFixMe) => ({
        name: artifact.name,
        type: EntityTypeEnum.MODEL
      })
    );
    setInputDatasets(filteredInputDatasets ?? []);
    setSelectedArtifacts(artifacts as ArtifactMini[]);
    setSelectedModels(models as ModelMini[]);
  }, [allEntities, artifactsIds, modelsIds, entityIds]);

  const entityToSend = useMemo(() => {
    const returnVal: IParent[] = [];

    _.forEach(allEntities, (en) => {
      if (en.id === entity || includes(selectedEntities, en.id)) {
        returnVal.push({ ...en, type: EntityTypeEnum.ENTITY });
      }

      if (en.id === spArtifact || includes(_.map(selectedArtifacts, "name"), en.name)) {
        returnVal.push({ ...en, type: EntityTypeEnum.ARTIFACT });
      }

      if (en.id === spModel || includes(_.map(selectedModels, "name"), en.name)) {
        returnVal.push({ ...en, type: EntityTypeEnum.MODEL });
      }
    });

    return returnVal;
  }, [
    selectedEntities,
    allEntities,
    artifactsIds,
    selectedArtifacts,
    selectedModels,
    entity,
    spArtifact,
    spModel
  ]);

  const { isFetching: isCreatingRecipe } = useCreateApiConnectorRecipe({
    projectId,
    inputDatasets,
    entity: entityToSend,
    options: {
      enabled: !groupId && isEntitiesFetched,
      onSuccess: (response) => {
        onUpdateRecipe(response.group);

        const entityIds = response.group.parents
          ?.filter((parent) => parent.type === "ENTITY")
          ?.map((parent) => parent.id);

        const filteredInputDatasets = allEntities?.filter(
          (dataset) => entityIds?.includes(dataset.id) || entity === dataset.id
        );
        setSearchParams({}, { replace: true });
        setInputDatasets(filteredInputDatasets ?? []);

        if (projectId && scenarioId && response.group.id) {
          navigate(
            generatePath(`${WebPaths.APIConnectorRecipeContainer}/:groupId`, {
              projectId,
              scenarioId,
              groupId: response.group.id
            }),
            { replace: true }
          );
        }
      }
    }
  });

  const getNextCheckpointName = () => {
    const checkpointNumbers = (codeCheckpoints || [])
      .map((checkpoint) => {
        const match = checkpoint.name?.match(/^Checkpoint (\d+)$/);
        return match ? parseInt(match[1], 10) : null;
      })
      .filter(_.isNumber);

    const nextIndex = _.range(1, Math.max(...checkpointNumbers, 0) + 2).find(
      (index) => !checkpointNumbers.includes(index)
    );

    return `Checkpoint ${nextIndex}`;
  };

  const handleSaveCodeCheckpoint = () => {
    const checkpointName = getNextCheckpointName();
    setIsCheckpointSaveInProgress(true);
    saveCodeCheckpoints.mutate(
      {
        request: {
          name: checkpointName,
          codeContent: editorValue!,
          recipeId: recipe?.id!,
          templateId: get(first(templates), "id")
        }
      },
      {
        onSuccess: () => {
          handleResponse({
            successMessage: `Checkpoint ${checkpointName} saved successfully`
          });
        },
        onSettled: () => {
          setIsCheckpointSaveInProgress(false);
        }
      }
    );
  };

  // Hide Checkpoints until templateId is created(first save)
  const hideSaveCheckpoints = !size(templates);

  const contextValue = React.useMemo(() => {
    return {
      jobData,
      jobRunData,
      recipe,
      handleUpdateRecipe,
      recipeId: recipe?.id,
      isRunInProgress,
      isTestInProgress,
      isSaveInProgress: isSaveInProgress || isAutoSaving,
      isAutoSaving,
      isSaveDisabled,
      inputDatasets,
      entityFeaturesMap,
      isRunDisabled,
      saveToolTip,
      runTooltip,
      isSelectedEntitiesUpdateInProgess,
      testTooltip,
      handleSave,
      handleRun,
      isTestDisabled,
      onUpdateRecipe,
      onAddCodeToRecipe,
      onRemoveCodeFromRecipe,
      isAskAiRecipeUpdateInProgress,
      previewTabs,
      setEntityFeaturesMap,
      handleInputDatasetsChange,
      handleTest,
      setInputDatasets,
      editorValue,
      setEditorValue,
      setPreviewTabs,
      codeErrorDetails,
      isQueryAssociatedWithRecipe,
      isDeletableRecipe,
      allEntities: allEntities || [],
      setSelectedApiRecipe,
      selectedApiRecipe,
      currentSelectedApiRecipe,
      setCurrentSelectedApiRecipe,
      handleAsyncSave,
      selectedArtifacts,
      selectedModels,
      setSelectedArtifacts,
      setSelectedModels,
      handleUpdateRecipeName,
      handleUpdateRecipeTimeout,
      handleUpdateRecipeCondition,
      handleStopTesting,
      isTestSaving: saveAndTestCodeRecipeMutation.isLoading,
      codeCheckpointsData: {
        codeCheckpoints: codeCheckpoints || [],
        isLoading: isLoadingCodeCheckpoints,
        handleSave: handleSaveCodeCheckpoint,
        isSaveInProgress: isCheckpointSaveInProgress,
        hideSave: hideSaveCheckpoints
      }
    };
  }, [
    jobData,
    jobRunData,
    inputDatasets,
    entityFeaturesMap,
    isSelectedEntitiesUpdateInProgess,
    handleRun,
    handleSave,
    handleUpdateRecipe,
    setEntityFeaturesMap,
    setInputDatasets,
    handleInputDatasetsChange,
    isAskAiRecipeUpdateInProgress,
    isRunDisabled,
    isRunInProgress,
    isSaveDisabled,
    isSaveInProgress,
    isAutoSaving,
    isTestDisabled,
    isTestInProgress,
    onAddCodeToRecipe,
    onRemoveCodeFromRecipe,
    recipe,
    saveToolTip,
    runTooltip,
    testTooltip,
    previewTabs,
    handleTest,
    editorValue,
    setEditorValue,
    setPreviewTabs,
    codeErrorDetails,
    isQueryAssociatedWithRecipe,
    isDeletableRecipe,
    allEntities,
    setSelectedApiRecipe,
    selectedApiRecipe,
    currentSelectedApiRecipe,
    setCurrentSelectedApiRecipe,
    handleAsyncSave,
    selectedArtifacts,
    selectedModels,
    setSelectedArtifacts,
    setSelectedModels,
    handleUpdateRecipeName,
    handleUpdateRecipeTimeout,
    handleUpdateRecipeCondition,
    handleStopTesting,
    saveAndTestCodeRecipeMutation.isLoading,
    codeCheckpoints,
    isLoadingCodeCheckpoints,
    handleSaveCodeCheckpoint,
    isCheckpointSaveInProgress,
    hideSaveCheckpoints
  ]);

  if (isCreatingRecipe || isLoading) {
    return <CommonLoader />;
  }

  return (
    <ApiConnectorRecipeContext.Provider value={contextValue}>
      {children}
    </ApiConnectorRecipeContext.Provider>
  );
};
