import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";

// Packages
import { useQueryClient } from "@tanstack/react-query";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { delay, isEmpty, keyBy, map } from "lodash";

// MUI
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Stores
import { useCanvasStore, useScenariosStore } from "src/store/store";

// Utils
import useHelpers from "./useHelpers";
import useReorderRecipe from "./useReorderRecipe";

// Hooks
import { UseGetRecipeRunsQueueQueryKeys } from "src/hooks/api/recipes";

// Components
import Header from "./Header";
import RecipeListHeader from "./RecipeListHeader";
import RecipeRow from "./RecipeRow";
import Footer from "./Footer";

// Constants
import { QueuedRecipeRunsConfig, RecipeRunsHelperText } from "./RecipeRunsPopover.constants";

// Contexts
import { useDagFlowContext } from "../DagFlow/context/useDagFlowContext";
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.5),
    width: 750,
    borderRadius: theme.spacing(1.5)
  },
  list: {
    maxHeight: 36 * QueuedRecipeRunsConfig.MaxNoOfRecipeRunsInClient, // 4 times the height of each-item
    overflow: "auto"
  }
}));

type Props = {
  recipeRunsPopoverAnchorEl: HTMLButtonElement | null;
  setRecipeRunsPopoverAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const RecipeRunsPopover = (props: Props) => {
  const { recipeRunsPopoverAnchorEl, setRecipeRunsPopoverAnchorEl } = props || {};

  const theme = useTheme();
  const classes = useStyles();

  const queryClient = useQueryClient();

  const scenariosStore = useScenariosStore((state) => state.scenarios);
  const setOpenRecipeRunsQueueStore = useCanvasStore((state) => state.setOpenRecipeRunsQueue);

  const scenariosMap = useMemo(
    () => (isEmpty(scenariosStore) ? {} : keyBy(scenariosStore, "id")),
    [scenariosStore]
  );

  const { recipeRunsQueue } = useProjectContext();
  const { scenario } = useDagFlowContext();

  const listRef = useRef<HTMLUListElement | null>(null);

  const setListRef = useCallback((event: HTMLUListElement | null) => {
    listRef.current = event;
  }, []);

  // States - STARTS >>
  // Initiating showMore with "false" will skip more functionality as entire list will be shown by default.
  // To enable more functionality initiate it with "true".
  const [showMore, setShowMore] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // << ENDS - States

  const { queuedRecipes, slicedRecipeRunsQueue, isRecipesRunning, hiddenRecipeRunsCount } =
    useHelpers({
      showMore,
      recipeRunsQueue
    });

  const { onDragEnd, isReorderingRecipeInQueue } = useReorderRecipe({ slicedRecipeRunsQueue });

  useEffect(() => {
    const _ = async () => {
      setIsLoading(() => true);
      await queryClient.refetchQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]);
      setIsLoading(() => false);
    };

    _();
  }, []);

  useLayoutEffect(() => {
    if (!showMore) {
      // Wrapping auto-scroll up in a delay, so that the DOM is available to apply auto-scroll upon.
      delay(() => {
        if (!!listRef?.current) {
          listRef.current.scrollTop = listRef?.current?.scrollHeight ?? 0;
        }
      }, 0);
    }
  }, [showMore, listRef?.current]);

  const onClose = () => {
    setOpenRecipeRunsQueueStore(false);
    setRecipeRunsPopoverAnchorEl(null);
  };

  return (
    <Popover
      id="recipeRunsPopover"
      open
      anchorEl={recipeRunsPopoverAnchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      classes={{ paper: classes.root }}>
      <Header
        scenario={scenario}
        isEmptyRecipeRunsQueue={isEmpty(recipeRunsQueue)}
        isRecipesRunning={isRecipesRunning}
      />

      <Box bgcolor={theme.palette.grey[50]}>
        {isLoading && isEmpty(recipeRunsQueue) ? (
          <Box display="flex" justifyContent="center" py={2}>
            <CircularProgress size={24} color="secondary" />
          </Box>
        ) : isEmpty(recipeRunsQueue) ? (
          <Typography align="center" variant="body2" color="textSecondary">
            <Box py={1} data-testid="recipeRunsNoDataFoundMessage">
              {RecipeRunsHelperText.NoDataFoundMessage}
            </Box>
          </Typography>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <List
                  className={classes.list}
                  disablePadding
                  {...provided?.droppableProps}
                  ref={(event) => {
                    provided?.innerRef(event);
                    setListRef(event);
                  }}
                  data-testid="recipeRunsList">
                  <RecipeListHeader />
                  <Divider />
                  {map(slicedRecipeRunsQueue, (data, index: number) => (
                    <Draggable
                      key={`${data?.groupId}-${data?.index}-${index}`}
                      // Below draggableId can only be a string, is captured by onDragEnd() method. Hence, passing recipeId, scenarioId within a single string.
                      // Using colons (:) to separate the fragments.
                      // As per HTML specification, an id may contain digits (0-9), hyphens (-), underscores (_), colons (:), and periods (.).
                      draggableId={`recipeId:${data?.groupId}:scenarioId:${data?.scenarioId}:draggableIndex:${index}`}
                      disableInteractiveElementBlocking={isReorderingRecipeInQueue}
                      index={index}>
                      {(provided) => (
                        <RecipeRow
                          key={`recipeRunsPopoverRecipe-${index}`}
                          scenariosMap={scenariosMap}
                          queuedRecipes={queuedRecipes}
                          data={data}
                          isReorderingRecipeInQueue={isReorderingRecipeInQueue}
                          provided={provided}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided?.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Box>

      {hiddenRecipeRunsCount > 0 && (
        <Footer
          buttonLabel={`${hiddenRecipeRunsCount} ${RecipeRunsHelperText.More}`}
          toggleShowMore={() => setShowMore(() => false)}
        />
      )}
    </Popover>
  );
};

export default RecipeRunsPopover;
