import { useEffect, useState } from "react";

// Packages
import { useLocation } from "react-router-dom";
import shallow from "zustand/shallow";
import { every, filter, includes, isArray, isEmpty, size, sortBy, toUpper } from "lodash";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";

// Open API
import { RecipeRunData, RecipeRunDataStatusEnum } from "@rapidcanvas/rc-api-core";

// Hooks
import {
  RecipeRunsConfig,
  useGetRecipeRunsQueue,
  useGetRecipeRunsQueueAcrossProjects
} from "src/hooks/api/recipes";

// Stores
import { useCanvasStore } from "src/store/store";

type Props = {
  projectId: string;
};

const useRecipeRunsQueue = (props: Props) => {
  const { projectId } = props || {};

  const location = useLocation();

  // Stores - STARTS >>
  const [
    isRecipesRunningAcrossScenariosStore,
    setIsRecipesRunningAcrossScenariosStore,
    isPendingRecipeRunsInQueueStore,
    setIsPendingRecipeRunsInQueueStore,
    setPendingRecipeRunsInQueueStore
  ] = useCanvasStore(
    (state) => [
      state.isRecipesRunningAcrossScenarios,
      state.setIsRecipesRunningAcrossScenarios,
      state.isPendingRecipeRunsInQueue,
      state.setIsPendingRecipeRunsInQueue,
      state.setPendingRecipeRunsInQueue
    ],
    shallow
  );
  // << ENDS - Stores

  // States - STARTS >>
  const [recipeRunsQueue, setRecipeRunsQueue] = useState<RecipeRunData[]>([]);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Queries
  const { refetch } = useGetRecipeRunsQueue({
    projectId,
    onSuccess: (data) => {
      setIsRecipesRunningAcrossScenariosStore(!!data?.projectHasRunningRecipe);

      const queue = isArray(data?.queue) ? data?.queue : [];
      let pendingRecipeRunsInQueue: RecipeRunData[] = [];

      if (isEmpty(queue)) {
        setIsPendingRecipeRunsInQueueStore(false);
        setPendingRecipeRunsInQueueStore([]);
        setRecipeRunsQueue(() => []);
      } else {
        pendingRecipeRunsInQueue = filter(queue, (recipe) =>
          includes(
            [toUpper(RecipeRunDataStatusEnum.InQueue), toUpper(RecipeRunDataStatusEnum.Running)],
            toUpper(recipe?.status)
          )
        );

        const isRecipeRunsFulfilled = every(queue, (recipe) =>
          includes(
            [
              toUpper(RecipeRunDataStatusEnum.Unbuilt),
              toUpper(RecipeRunDataStatusEnum.Success),
              toUpper(RecipeRunDataStatusEnum.Error)
            ],
            toUpper(recipe?.status)
          )
        );

        // Checking if any running recipes are fulfilled
        if (!!isPendingRecipeRunsInQueueStore && isRecipeRunsFulfilled) {
          toastWrapper({
            type: ToastTypes.Success,
            content: "Recipe Queue is completed!"
          });
        }

        setIsPendingRecipeRunsInQueueStore(size(pendingRecipeRunsInQueue) > 0);
        setPendingRecipeRunsInQueueStore(pendingRecipeRunsInQueue);
        setRecipeRunsQueue(() => sortBy(queue, ["index"]));
      }
    },
    refetchOnMount: true,
    enabled: !!projectId,
    ...(!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore
      ? { refetchInterval: RecipeRunsConfig.PollIntervalInProjectContext }
      : {})
  });
  // << ENDS - Query hooks

  useEffect(() => {
    !!location?.pathname && refetch();
  }, [location?.pathname]);

  const { refetch: refetchAcrossProjects } = useGetRecipeRunsQueueAcrossProjects({ projectId });

  useEffect(
    () => () => {
      refetchAcrossProjects();
    },
    []
  );

  return { recipeRunsQueue };
};

export default useRecipeRunsQueue;
