import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import NotebookLoading from "./NotebookLoading";
import api from "src/utils/AxiosClient";
import envRelaunchNotification from "src/utils/envRelaunchNotification";
import { ChangeTemplateResponseDto } from "@rapidcanvas/rc-api-core";
import { EnvironmentStatuses } from "src/constants/environments.constants";
import { UseQueryResult } from "@tanstack/react-query";
import { getBasePath } from "src/utils/helpers";
import { getEnvironmentById } from "src/api";
import { getProjectById } from "src/api/projects";

interface IProps {
  templateUrlResult: UseQueryResult<ChangeTemplateResponseDto, Error>;
  iframeLoading: boolean;
  setIframeLoading: Dispatch<SetStateAction<boolean>>;
}

const checkEnvRelaunch = async (projectId: $TSFixMe) => {
  try {
    const projects = await getProjectById(projectId);

    const project = projects?.[0];
    const envStatus = project?.launchStatus;
    // For checking default-env associate to the project use defaultEnvFlag in the project's response.

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!envStatus) {
      if (
        [
          EnvironmentStatuses.Inactive.toLowerCase(),
          EnvironmentStatuses.Failed.toLowerCase(),
          EnvironmentStatuses.Launching.toLowerCase()
        ].includes(envStatus?.trim()?.toLowerCase())
      ) {
        const response = await getEnvironmentById(project?.envId);
        const shutdownTimeInHrs = response?.[0].shutdownStrategy?.inactivityInHours;
        return envRelaunchNotification(shutdownTimeInHrs);
      }
    }
  } catch (error: $TSFixMe) {
    console.error(error);
  }
};

const AutoMLNotebookIframeContainer: React.FC<IProps> = (props) => {
  const {
    templateUrlResult: { isFetching, data, isLoading, isError },
    setIframeLoading,
    iframeLoading
  } = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { projectId } = useParams();
  const token = api.getToken();

  const basePath = getBasePath();

  useEffect(() => {
    projectId && checkEnvRelaunch(projectId);
  }, []);

  const handleIframeLoad = () => {
    setIframeLoading(false);
  };

  if (isFetching || isLoading) {
    return <NotebookLoading />;
  }
  if (!data?.success || isError) {
    return <>Something went wrong</>;
  }

  return (
    <>
      {iframeLoading && <NotebookLoading />}
      <iframe
        ref={iframeRef}
        onLoad={handleIframeLoad}
        style={{ height: "calc(100vh - 97px)", display: iframeLoading ? "none" : "block" }}
        width="100%"
        src={`${basePath}/${data?.templateUrl}?token=${token}`}
      />
    </>
  );
};

export default AutoMLNotebookIframeContainer;
