import React, { useCallback, useMemo, useState } from "react";

// Packages
import { useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { find, includes, map, toLower, toNumber, trim } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

// Icons
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

// Open API
import { CreateEnvDto, CreateEnvDtoEnvTypeEnum, EnvDto } from "@rapidcanvas/rc-api-core";

// Hooks
import { useSaveEnvironment } from "src/hooks/api";
import { UseGetEnvironmentsQueryKeys } from "src/hooks/api/environments/useGetEnvironments";

// Utils
import { getReactHookFormTextFieldRules, snakeCaseToStartCase } from "src/utils/helpers";
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";

// Components
import { Modal, PreviewPlaceholder, Space } from "src/components/custom";
import { CodeEditor } from "src/components";
import { EnvironmentTypeConfig } from "src/pages/private/EnvironmentsModule";
import ConfirmClose from "./ConfirmClose";

// Constants
import {
  EnvironmentPackagesInfo,
  NewEnvironmentFormFields,
  NewEnvironmentFormFieldsNameMapping,
  NewEnvironmentModalHelperText
} from "../../utils/Environments.constants";

// Styles
import useStyles from "./NewEnvironmentModal.styles";

// Types
import { NewEnvironmentFormFieldsType } from "../../Environments.types";

const EnvironmentTypeCustom = "CUSTOM";

interface IProps {
  isEnvironmentTypesLoading: boolean;
  environmentTypes?: any[];
  isEnvironmentsLoading: boolean;
  environments?: EnvDto[];
  onClose: () => void;
}

const NewEnvironmentModal: React.FC<IProps> = (props) => {
  const {
    isEnvironmentTypesLoading,
    environmentTypes,
    isEnvironmentsLoading,
    environments,
    onClose
  } = props;

  const theme = useTheme();
  const classes = useStyles();

  const inputProps = { classes: { root: classes.inputField } };
  const inputLabelProps = {
    classes: { root: classes.inputLabel, shrink: classes.inputLabelShrink }
  };

  const queryClient = useQueryClient();

  // States - STARTS >>
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // << ENDS - States

  const { mutateAsync: saveEnvironmentMutation, reset: resetSaveEnvironmentMutation } =
    useSaveEnvironment();

  // Form - STARTS >>
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { isDirty, isValid }
  } = useForm<NewEnvironmentFormFieldsType>({
    mode: "onChange", // Validate onChange
    reValidateMode: "onChange" // Re-validate onChange
  });

  const watchDescription = watch(NewEnvironmentFormFields.Description);
  const watchEnvironmentType = watch(NewEnvironmentFormFields.EnvironmentType);

  const getEnvironmentNameRules = () => {
    const environmentNames = map(environments, (environment) => toLower(trim(environment?.name)));

    const defaultRules = getReactHookFormTextFieldRules({
      fieldName: NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.Name]
    });

    return {
      ...defaultRules,
      validate: {
        ...defaultRules?.validate,
        noDuplicateName: (value: string) =>
          !includes(environmentNames, toLower(trim(value))) ||
          `This ${toLower(NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.Name])} already exists.`
      }
    };
  };

  const getEnvironmentTypeFieldRules = (formFieldName: string, fieldName: string) => ({
    required:
      watchEnvironmentType === EnvironmentTypeCustom ? `The ${fieldName} cannot be blank.` : false,
    validate: {
      isInteger: (value: number) =>
        Number.isInteger(Number(value)) || `The ${fieldName} must be an integer.`,
      isInRange: (value: number) => {
        const maxLimit = environmentTypeValues()?.[formFieldName] || 0;
        return (
          (value >= 1 && value <= maxLimit) ||
          `The ${fieldName} needs to be between 1 and ${maxLimit}.`
        );
      }
    }
  });
  // << ENDS - Form

  // Confirm close modal - STARTS >>
  const setShowConfirmCloseModalHandler = () => setShowConfirmCloseModal(() => true);
  const resetShowConfirmCloseModalHandler = () => setShowConfirmCloseModal(() => false);

  const isAttemptedClose = useCallback(() => {
    if (!!isSaving) {
      return;
    }

    if (!!isDirty) {
      setShowConfirmCloseModalHandler();
    } else {
      onClose();
    }
  }, [isSaving, isDirty]);
  // << ENDS - Confirm close modal

  const environmentTypeValues = useCallback(
    () => find(environmentTypes, ["name", watchEnvironmentType]),
    [environmentTypes, watchEnvironmentType]
  );

  const getEnvironmentConfig = useCallback(() => {
    if (!watchEnvironmentType) {
      return;
    }

    if (watchEnvironmentType === EnvironmentTypeCustom) {
      return;
    }

    return (
      <Typography variant="caption" color="textSecondary" data-testid="newEnvEnvConfigContainer">
        <EnvironmentTypeConfig
          environmentType={watchEnvironmentType}
          cores={environmentTypeValues().cores}
          memInGbs={environmentTypeValues().memInGbs}
          diskInGbs={environmentTypeValues().diskInGbs}
        />
      </Typography>
    );
  }, [watchEnvironmentType, environmentTypes]);

  const onPackagesChange = useCallback((__: string, value: string) => {
    setValue(NewEnvironmentFormFields.Packages, value, { shouldDirty: true });
  }, []);

  const saveEnvironment = async () => {
    setIsSaving(() => true);
    const values = getValues();

    const payload: CreateEnvDto = {
      name: trim(values?.[NewEnvironmentFormFields.Name]),
      description: trim(values?.[NewEnvironmentFormFields.Description]),
      requirements: values?.[NewEnvironmentFormFields.Packages],
      envType: values?.[NewEnvironmentFormFields.EnvironmentType] as CreateEnvDtoEnvTypeEnum
    };

    if (values?.[NewEnvironmentFormFields.EnvironmentType] === EnvironmentTypeCustom) {
      payload.cores = toNumber(values?.[NewEnvironmentFormFields.Cores]);
      payload.memInMbs = toNumber(values?.[NewEnvironmentFormFields.Memory] * 1024);
      payload.diskInGbs = toNumber(values?.[NewEnvironmentFormFields.DiskSpace]);
    } else {
      payload.cores = toNumber(environmentTypeValues().cores);
      payload.memInMbs = toNumber(environmentTypeValues().memInMbs);
      payload.diskInGbs = toNumber(environmentTypeValues().diskInGbs);
    }

    resetSaveEnvironmentMutation();
    await saveEnvironmentMutation(payload, {
      onSuccess: async () => {
        await queryClient.invalidateQueries([UseGetEnvironmentsQueryKeys.Environments]);

        toastWrapper({
          type: ToastTypes.Success,
          content: NewEnvironmentModalHelperText.EnvironmentSaved
        });

        setIsSaving(() => false);
        onClose();
      },
      onError: () => setIsSaving(() => false)
    });
  };

  const isLoading = useMemo(
    () => !!isEnvironmentTypesLoading || !!isEnvironmentsLoading,
    [isEnvironmentTypesLoading, isEnvironmentsLoading]
  );

  const disabledCancelActionMessage = useMemo(() => {
    if (!!isSaving) {
      return "Please wait. The create action is in progress.";
    }

    return "";
  }, [isSaving]);

  const disabledSaveActionMessage = useMemo(() => {
    if (!!isLoading) {
      return "Please wait. Fetching required data.";
    }

    if (!!isSaving) {
      return "Please wait. The create action is in progress.";
    }

    if (!isValid) {
      return "Invalid fields.";
    }

    if (!isDirty) {
      return "Change fields to enable this action.";
    }

    return "";
  }, [isLoading, isSaving, isValid, isDirty]);

  return (
    <>
      {!!showConfirmCloseModal && (
        <ConfirmClose onConfirm={onClose} onCancel={resetShowConfirmCloseModalHandler} />
      )}

      <Modal
        open
        size="md"
        title={NewEnvironmentModalHelperText.title}
        onClose={isAttemptedClose}
        isCancelDisabled={!!disabledCancelActionMessage}
        cancelActionInfo={disabledCancelActionMessage}
        submitLabel={NewEnvironmentModalHelperText.submitLabel}
        isSubmitDisabled={!!disabledSaveActionMessage}
        isSubmitting={!!isSaving}
        submitActionInfo={disabledSaveActionMessage}
        onSubmit={saveEnvironment}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid item>
              <Grid container direction="column" wrap="nowrap" style={{ rowGap: theme.spacing(2) }}>
                <Grid item>
                  {!!isEnvironmentsLoading ? (
                    <PreviewPlaceholder
                      label={`${NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.Name]} *`}
                      data-testid="newEnvNamePreview"
                    />
                  ) : (
                    <Controller
                      control={control}
                      name={NewEnvironmentFormFields.Name}
                      rules={getEnvironmentNameRules()}
                      render={({ field, fieldState }) => {
                        const { error } = fieldState;

                        return (
                          <TextField
                            {...field}
                            variant="outlined"
                            size="small"
                            fullWidth
                            label={`${NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.Name]} *`}
                            InputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            error={!!error}
                            {...(!!error?.message ? { helperText: error?.message } : {})}
                            data-testid="newEnvNameInput"
                          />
                        );
                      }}
                      data-testid="newEnvName"
                    />
                  )}
                  <Typography variant="caption" color="textSecondary" data-testid="newEnvNameInfo">
                    {NewEnvironmentModalHelperText.EnvironmentNameInfo}
                  </Typography>
                </Grid>
                <Grid item>
                  <Controller
                    control={control}
                    name={NewEnvironmentFormFields.Description}
                    render={({ field }) => (
                      <>
                        <Tooltip title={watchDescription ?? ""} arrow>
                          <TextField
                            {...field}
                            variant="outlined"
                            size="small"
                            fullWidth
                            multiline
                            minRows={4}
                            maxRows={4}
                            label={
                              NewEnvironmentFormFieldsNameMapping[
                                NewEnvironmentFormFields.Description
                              ]
                            }
                            InputProps={{ ...inputProps, style: { minHeight: 55 } }}
                            InputLabelProps={inputLabelProps}
                            data-testid="newEnvDescriptionInput"
                          />
                        </Tooltip>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          data-testid="newEnvDescriptionInfo">
                          {NewEnvironmentModalHelperText.EnvironmentDescriptionInfo}
                        </Typography>
                      </>
                    )}
                    data-testid="newEnvDescription"
                  />
                </Grid>
                <Grid item>
                  {!!isEnvironmentTypesLoading ? (
                    <PreviewPlaceholder
                      label={`${NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.EnvironmentType]} *`}
                      data-testid="newEnvEnvironmentTypePreview"
                    />
                  ) : (
                    <>
                      <Controller
                        control={control}
                        name={NewEnvironmentFormFields.EnvironmentType}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel data-testid="newEnvEnvironmentTypeInputLabel">
                              {`${NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.EnvironmentType]} *`}
                            </InputLabel>
                            <Select
                              {...field}
                              variant="outlined"
                              fullWidth
                              label={`${NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.EnvironmentType]} *`}
                              inputProps={{
                                className: classes.inputField,
                                style: { minHeight: 75 }
                              }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                style: { maxHeight: 275 }
                              }}
                              data-testid="newEnvEnvironmentTypeSelect">
                              {map(environmentTypes, (environment: any, index: number) => (
                                <MenuItem
                                  key={`${environment?.name}_${index}`}
                                  value={environment?.name}
                                  data-testid={`newEnvEnvironmentTypeMenuItem_${index}`}>
                                  {snakeCaseToStartCase(environment?.name, {
                                    toUpperCase: ["cpu"]
                                  })}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        data-testid="newEnvEnvironmentType"
                      />

                      {getEnvironmentConfig()}
                    </>
                  )}
                </Grid>
                {watchEnvironmentType === EnvironmentTypeCustom && (
                  <>
                    <Grid container justifyContent="space-between">
                      <Grid item style={{ width: "47.5%" }}>
                        <Controller
                          control={control}
                          name={NewEnvironmentFormFields.Cores}
                          rules={getEnvironmentTypeFieldRules(
                            NewEnvironmentFormFields.Cores,
                            NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.Cores]
                          )}
                          render={({ field, fieldState }) => {
                            const { error } = fieldState;

                            return (
                              <TextField
                                {...field}
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                label={`${NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.Cores]} *`}
                                inputProps={{ min: 1 }}
                                InputProps={inputProps}
                                InputLabelProps={inputLabelProps}
                                error={!!error}
                                {...(!!error?.message ? { helperText: error?.message } : {})}
                                data-testid="newEnvCoresInput"
                              />
                            );
                          }}
                          data-testid="newEnvCores"
                        />
                      </Grid>
                      <Grid item style={{ width: "47.5%" }}>
                        <Controller
                          control={control}
                          name={NewEnvironmentFormFields.Memory}
                          rules={getEnvironmentTypeFieldRules(
                            NewEnvironmentFormFields.Memory,
                            NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.Memory]
                          )}
                          render={({ field, fieldState }) => {
                            const { error } = fieldState;

                            return (
                              <TextField
                                {...field}
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                label={`${NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.Memory]} *`}
                                inputProps={{ min: 1 }}
                                InputProps={{
                                  ...inputProps,
                                  endAdornment: <InputAdornment position="end">GB</InputAdornment>
                                }}
                                InputLabelProps={inputLabelProps}
                                error={!!error}
                                {...(!!error?.message ? { helperText: error?.message } : {})}
                                data-testid="newEnvMemoryInput"
                              />
                            );
                          }}
                          data-testid="newEnvMemory"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name={NewEnvironmentFormFields.DiskSpace}
                        rules={getEnvironmentTypeFieldRules(
                          NewEnvironmentFormFields.DiskSpace,
                          NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.DiskSpace]
                        )}
                        render={({ field, fieldState }) => {
                          const { error } = fieldState;

                          return (
                            <TextField
                              {...field}
                              type="number"
                              variant="outlined"
                              size="small"
                              fullWidth
                              label={`${NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.DiskSpace]} *`}
                              inputProps={{ min: 1 }}
                              InputProps={{
                                ...inputProps,
                                endAdornment: <InputAdornment position="end">GB</InputAdornment>
                              }}
                              InputLabelProps={inputLabelProps}
                              error={!!error}
                              {...(!!error?.message ? { helperText: error?.message } : {})}
                              data-testid="newEnvDiskSpaceInput"
                            />
                          );
                        }}
                        data-testid="newEnvDiskSpace"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Alert
                variant="outlined"
                severity="info"
                className={classes.diskSpaceInfo}
                icon={<InfoOutlinedIcon fontSize="small" color="action" />}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  data-testid="newEnvDiskSpaceInfo">
                  {NewEnvironmentModalHelperText.EnvironmentTypeInfo}
                </Typography>
              </Alert>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column" className={classes.packagesContainer}>
              <Space>
                <Typography variant="subtitle2">
                  {NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.Packages]}
                </Typography>
                <Tooltip arrow title={EnvironmentPackagesInfo} data-testid="newEnvPackageInfo">
                  <InfoOutlinedIcon fontSize="small" color="action" />
                </Tooltip>
              </Space>
              <Paper className={classes.packagesEditorContainer}>
                <CodeEditor
                  name="code"
                  height="99%" // 100% would conflicts with container height and impacts grow & shrink behavior.
                  scriptType="SYSTEM"
                  onChange={onPackagesChange}
                  hideTestCode
                  data-testid="newEnvPackage"
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default NewEnvironmentModal;
