import { ReactNode } from "react";

// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

// Open API
import api from "src/utils/AxiosClient";
import { ProjectCanvasDto } from "@rapidcanvas/rc-api-core";

// Hooks
import { getProjectCanvas } from "src/api/projects";

export const enum UseGetProjectCanvasQueryKeys {
  ProjectCanvas = "query-key-project-canvas",
  ProjectCanvasLite = "query-key-project-canvas-lite"
}

type ProjectJobProps = {
  scenarioId?: string;
  jobId?: string;
  jobRunId?: string;
  renderContent?: ReactNode;
  skipJobCanvas?: boolean;
};
interface IProps extends UseQueryOptions<$TSFixMe> {
  projectId?: string;
  scenarioId?: string;
  jobProps?: ProjectJobProps;

  // useQuery options
  cacheTime?: number;
}

export const useGetProjectCanvas = (props: IProps): UseQueryResult<$TSFixMe> => {
  const {
    projectId,
    scenarioId,
    jobProps,

    // useQuery options
    cacheTime = 0,
    enabled,
    ...options
  } = props;

  return useQuery({
    queryKey: [
      UseGetProjectCanvasQueryKeys.ProjectCanvas,
      projectId,
      scenarioId || jobProps?.scenarioId
    ],
    // queryKey: ["projectCanvas", projectId, scenarioId],
    queryFn: () => {
      return getProjectCanvas({
        projectId,
        scenarioId: scenarioId || jobProps?.scenarioId,
        ...(!!jobProps?.jobId ? { jobId: jobProps?.jobId } : {}),
        ...(!!jobProps?.jobRunId ? { jobRunId: jobProps?.jobRunId } : {})
      });
    },
    select: (data: $TSFixMe) => (typeof data === "object" ? data : {}),
    cacheTime,
    enabled: enabled ?? (!!projectId && (!!scenarioId || !!jobProps?.scenarioId)),
    ...options
  });
};

export const useGetProjectCanvasLite = (props: IProps): UseQueryResult<ProjectCanvasDto> => {
  const { projectId, scenarioId, ...options } = props;

  return useQuery({
    queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvasLite, projectId, scenarioId],
    queryFn: async () => {
      if (!projectId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.ProjectsControllerV2Api.getProjectCanvas(projectId, true, scenarioId)
      );
    },
    select: (data: $TSFixMe) => (typeof data === "object" ? data : {}),
    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely
    ...options
  });
};
