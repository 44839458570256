export enum EnvironmentsSessionConfig {
  EnvironmentConfigSessionKey = "environmentConfigSession"
}

export type EnvironmentType = {
  cores: number;
  diskInGbs: number;
  gpus: $TSFixMe;
  memInGbs: number;
  memInMbs: number;
  name: string;
};

export const DEFAULT_NAME = "DEFAULT";

export enum EnvironmentsTypes {
  ExtraSmall = "EXTRA_SMALL",
  Small = "SMALL",
  Medium = "MEDIUM",
  Large = "LARGE",
  CpuLarge = "CPU_LARGE",
  MaxLarge = "MAX_LARGE",
  ExtraMaxLarge = "EXTRA_MAX_LARGE",
  Custom = "CUSTOM"
}

export const environmentsTypes: $TSFixMe = {
  [EnvironmentsTypes.ExtraSmall]: "Extra Small",
  [EnvironmentsTypes.Small]: "Small",
  [EnvironmentsTypes.Medium]: "Medium",
  [EnvironmentsTypes.Large]: "Large",
  [EnvironmentsTypes.CpuLarge]: "CPU Large",
  [EnvironmentsTypes.MaxLarge]: "Max Large",
  [EnvironmentsTypes.ExtraMaxLarge]: "Extra Max Large",
  [EnvironmentsTypes.Custom]: "Custom"
};

export enum EnvironmentStatuses {
  Inactive = "SHUTDOWN",
  Launching = "LAUNCHING",
  Stopping = "STOPPING",
  Active = "SUCCESS",
  Failed = "FAILED",
  Unknown = "UNKNOWN"
}
