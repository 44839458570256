import React, { useMemo, useState } from "react";
import _ from "lodash";
import { Box, CircularProgress, Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";
import { concat, filter, includes, isEmpty, map, size } from "lodash";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import AskAICollapsed from "./AskAICollapsed";
import ChatBox from "src/pages/Projects/AIGuide/common/ChatBox";
import CopyDataButton from "src/pages/Projects/AIGuide/common/CopyDataButton";
import ViewDataData from "src/pages/ViewData/ViewDataData/ViewDataData";
import useGetEntityData, { QUERY_KEY_ENTITY_DATA } from "src/hooks/api/entities/useGetEntityData";
import { DatasetCustomColumnsProps, EntityFeaturesResponse } from "src/types";
import { EntityDataDto } from "@rapidcanvas/rc-api-core";
import { RecordSelector } from "pages/Projects/common/RecordSelector";
import { useEntityDataCustomColumns } from "src/hooks/api/projects/useEntityDataAndStats";

const useStyles = makeStyles({
  rightAligned: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    flexWrap: "nowrap",
    flex: 0
  },
  leftAligned: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px"
  },
  datasetName: {
    color: "#4646B5",
    fontWeight: 400
  },
  span: {
    fontSize: "14px",
    color: "#515151"
  },
  boxWrap: {
    width: "100%",
    maxWidth: "100%",
    boxShadow: "0px 4px 4px 0px #82828240"
  },
  titleBox: {
    color: "#4646B5",
    cursor: "pointer",
    padding: "6px 12px",
    borderTopRightRadius: "4px",
    gap: "8px"
  },
  dataContainer: {
    background: "#fff",
    borderRadius: "4px",
    maxWidth: "100%"
  },
  dataset: {
    margin: "5px 12px 0px 12px",
    border: "1px solid #D1D1D1",
    overflowY: "auto",
    borderRadius: "2px"
  }
});

interface IProps {
  entityFeatures: EntityFeaturesResponse | undefined;
  inputDatasetId: string;
  inputDatasetName: string;
  onExpandToggle: () => void;
  extra?: React.JSX.Element;
}

const AskAIInputDatasetExpanded: React.FC<IProps> = (props) => {
  const { inputDatasetId, inputDatasetName, entityFeatures, extra, onExpandToggle } = props;
  const [value, setValue] = useState(100);

  const classes = useStyles();
  const { scenarioId, jobRunId } = useParams();
  const queryClient = useQueryClient();
  const { data, isLoading } = useGetEntityData(inputDatasetId, scenarioId, jobRunId);

  const { mutateAsync: datasetDataCustomColumnsMutation } = useEntityDataCustomColumns({
    onSuccess: (datasetDataResponse) => {
      const updatedAllEntitiesData: EntityDataDto = {
        ...data,
        data: {
          ...data?.data,
          columns: concat(data?.data?.columns, datasetDataResponse?.data?.columns),
          rows: map(data?.data?.rows, (row, index) => {
            if (datasetDataResponse?.data?.rows?.[index]) {
              row.cells = concat(row?.cells, datasetDataResponse?.data?.rows?.[index]?.cells);
            }
            return row;
          })
        }
      };

      queryClient.setQueryData(
        [QUERY_KEY_ENTITY_DATA, inputDatasetId, scenarioId, jobRunId],
        updatedAllEntitiesData
      );
    }
  });

  const tableData = useMemo(() => {
    return data?.data?.rows?.map(({ cells }: any) => _.zipObject(data?.data?.columns ?? [], cells));
  }, [data?.data?.rows, data?.data?.columns]);

  const entityData = useMemo(() => {
    return {
      ...data?.data,
      rows: data?.data?.rows?.slice(0, value),
      numCols: data?.entityDetails?.columns,
      numRows: data?.entityDetails?.rows,
      entityId: inputDatasetId
    };
  }, [data, value]);

  const handleCountChange = (event: $TSFixMe) => {
    event.stopPropagation();
    setValue(event.target.value);
  };

  const getDatasetCustomColumnsData = async ({
    columnNames,
    entityId
  }: DatasetCustomColumnsProps) => {
    if (isEmpty(entityData)) {
      return;
    }

    const newColumns = filter(columnNames, (col) => !includes(entityData?.columns, col));

    if (isEmpty(newColumns)) {
      return;
    }

    const payload = {
      entityId,
      payload: {
        scenarioId: scenarioId!,
        rowsStart: 0,
        rowsEnd: size(entityData?.rows),
        cols: newColumns
      }
    };

    await datasetDataCustomColumnsMutation(payload);
  };

  if (isLoading) {
    return (
      <AskAICollapsed
        name={inputDatasetName}
        icon={<CircularProgress size={16} />}
        onExpandToggle={onExpandToggle}
      />
    );
  }

  return (
    <ChatBox
      noPadding
      color={"#F2F2FF"}
      border={"#4646B5"}
      width={"calc(100% - 194px)"}
      childWidth="100%">
      <Grid container direction="column" className={classes.boxWrap}>
        <Grid
          container
          direction="row"
          className={classes.titleBox}
          wrap="nowrap"
          style={{ padding: isLoading ? "0px" : "" }}
          alignItems="center"
          onClick={onExpandToggle}>
          <Grid container item className={classes.leftAligned}>
            <Typography
              variant="subtitle2"
              className={classes.datasetName}
              data-testid="expandInputDSName">
              {inputDatasetName}
            </Typography>
          </Grid>
          <Grid container item className={classes.rightAligned} alignItems="center">
            <CopyDataButton tableData={(tableData as any) ?? []} />
            {extra}
            <IconButton size="small" data-testid="expandLess">
              <ExpandLess fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid className={classes.dataContainer}>
          <Box className={classes.dataset}>
            <MemoizedInputDataset
              value={value}
              entityData={entityData}
              entityFeatures={entityFeatures}
              getDatasetCustomColumnsData={getDatasetCustomColumnsData}
            />
          </Box>
          <Box
            p="0px 12px 12px 12px"
            style={{ display: "flex", gap: "16px", alignItems: "center" }}>
            <RecordSelector value={value} handleCountChange={handleCountChange} />
            <span className={classes.span} data-testid="entitySizeText">{`Size: ${
              entityData?.numRows ?? entityData?.rows?.length ?? 0
            } Rows * ${entityData?.numCols ?? entityData?.columns?.length ?? 0} Columns`}</span>
          </Box>
        </Grid>
      </Grid>
    </ChatBox>
  );
};

const InputDataset = ({
  entityFeatures,
  value,
  entityData,
  getDatasetCustomColumnsData
}: {
  entityData: any;
  value: number;
  entityFeatures: EntityFeaturesResponse | undefined;
  getDatasetCustomColumnsData?: ({ columnNames, entityId }: DatasetCustomColumnsProps) => void;
}) => {
  const slicedRows = useMemo(() => entityData?.rows?.slice(0, value) || [], [entityData, value]);

  return (
    <ViewDataData
      isLoadingData={false}
      responseData={entityData ? { ...entityData, rows: slicedRows } : { columns: [], rows: [] }}
      entityFeatures={entityFeatures}
      maxHeight="236px"
      {...(!!entityData?.entityId
        ? {
            onColumnChange: (columnNames: string[]) =>
              getDatasetCustomColumnsData?.({
                columnNames,
                entityId: entityData?.entityId
              })
          }
        : {})}
    />
  );
};

const MemoizedInputDataset = React.memo(InputDataset);

export default AskAIInputDatasetExpanded;
