import {
  DataAppDto,
  PublishAppTemplateRequestDtoAppTypeEnum,
  PublishAppTemplateRequestDtoSourceEnum,
  SignedUrlRequestDtoSignedUrlObjectTypeEnum,
  UpdateDataAppRequestDto
} from "@rapidcanvas/rc-api-core";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import _ from "lodash";

import api from "src/utils/AxiosClient";
import { QUERY_KEY_DATA_APPS } from "./useGetDataApps";
import { QUERY_KEY_DATAAPPS_ACROSS_TENANTS } from "./useGetDataAppAcrossTenants";

interface IProps {
  file: File;
  templateId: string;
  source: PublishAppTemplateRequestDtoSourceEnum;
  appType: PublishAppTemplateRequestDtoAppTypeEnum;
  id: string;
  payload: UpdateDataAppRequestDto;
}

const useUpdateDataAppWithZip = (): UseMutationResult<DataAppDto, unknown, IProps, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ file, source, appType, payload, id, templateId }) => {
      const template = await api.fetchResponse(
        async () => await api.AppTemplateControllerApi.getAppTemplate(templateId)
      );
      const data = await api.fetchResponse(
        async () =>
          await api.SignedUrlControllerApi.uploadFileUrl1({
            fileName: `${template.name}.zip`,
            signedUrlObjectType:
              appType === PublishAppTemplateRequestDtoAppTypeEnum.Streamlit
                ? SignedUrlRequestDtoSignedUrlObjectTypeEnum.AppTemplateStreamlit
                : SignedUrlRequestDtoSignedUrlObjectTypeEnum.AppTemplateReactjs,
            metadata: {
              appType: _.lowerCase(appType),
              SOURCE: source
            }
          })
      );

      await axios.put(data.signedUrl!, file, {
        headers: data.headers
      });

      await api.fetchResponse(
        async () =>
          await api.AppTemplateControllerApi.updateAppTemplate(template.id!, {
            ...template,
            name: template.name!
          })
      );

      const response = await api.fetchResponse(
        async () => await api.DataAppControllerApi.updateDataApp(id, payload)
      );

      try {
        await api.fetchResponse(
          async () => await api.DataAppControllerApi.launch(response.id as string)
        );
        return response;
      } catch {
        return response;
      } finally {
        await queryClient.invalidateQueries([QUERY_KEY_DATA_APPS]);
        await queryClient.invalidateQueries([QUERY_KEY_DATAAPPS_ACROSS_TENANTS]);
      }
    }
  });
};

export default useUpdateDataAppWithZip;
