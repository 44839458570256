import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  inputField: {
    backgroundColor: theme.palette.common.white
  },
  inputLabel: {
    opacity: 0.625
  },
  inputLabelShrink: {
    shrink: true,
    opacity: 1
  },
  diskSpaceInfo: {
    margin: 0,
    padding: 0,
    border: "none",
    "& [class^='MuiAlert-icon']": {
      marginRight: theme.spacing(1)
    },
    "& [class^='MuiAlert-message']": {
      paddingTop: theme.spacing(0.75)
    }
  },
  packagesContainer: { height: "100%", rowGap: theme.spacing(1) },
  packagesEditorContainer: {
    margin: 0,
    flexGrow: 1,
    flexShrink: 1,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(0.5)
  },
  tablistSelector: {
    '& div[role="tablist"]': {
      border: "1px solid #D5D5D5",
      width: "fit-content",
      borderRadius: "20px",
      boxShadow: "0px 2px 4px 0px #00000040"
    }
  }
}));

export default useStyles;
