import { WebPaths } from "./routes";

export const docsToRouteMapping = [
  {
    path: WebPaths.About,
    url: "/Projects/Projects.html#generating-the-about-content-for-the-project"
  },
  {
    path: WebPaths.Scenarios,
    url: "/Projects/Segments%20and%20Scenarios.html#scenarios"
  },
  {
    path: WebPaths.NewScenario,
    url: "/Projects/Segments%20and%20Scenarios.html#scenarios"
  },
  {
    path: WebPaths.Scenario,
    url: "/Projects/Segments%20and%20Scenarios.html#scenarios"
  },
  {
    path: WebPaths.AppendDataset,
    url: "/Connectors/Connect%20to%20external%20dataconnectors.html#importing-dataset-s-to-the-canvas-from-the-local-system"
  },
  {
    path: WebPaths.ArtifactsAndModels,
    url: "/Artifacts%20%26%20Models.html#"
  },
  {
    path: WebPaths.ArtifactsDetails,
    url: "/Artifacts%20%26%20Models.html#managing-artifacts"
  },
  {
    path: WebPaths.AuthPath,
    url: "/"
  },
  {
    path: WebPaths.AutoMLRecipeContainer,
    url: "/Projects/Recipes.html#developing-ml-models-using-the-rapid-model-recipe"
  },
  {
    path: `${WebPaths.Dag}${WebPaths.Canvas}`,
    url: "/Projects.html#canvas-overview"
  },
  {
    path: WebPaths.Charts,
    url: "/Artifacts%20%26%20Models.html#"
  },
  {
    path: WebPaths.CodeRecipeContainer,
    url: "/Projects/Recipes.html#adding-an-ai-assisted-recipe"
  },
  {
    path: WebPaths.CreateDataConnector,
    url: "/Connectors.html#connectors-overview"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.CreateJob}`,
    url: "/Projects/Schedulers.html#creating-a-scheduler"
  },
  {
    path: WebPaths.Dag,
    url: "/Projects/Projects.html"
  },
  {
    path: WebPaths.Dashboard,
    url: "/"
  },
  {
    path: WebPaths.DataAppDashboard,
    url: "/DataApps.html#viewing-dataapps-in-a-tenant"
  },
  {
    path: WebPaths.DataAppDeeplink,
    url: "/DataApps.html#dataapps"
  },
  {
    path: WebPaths.DataAppLogs,
    url: "/DataApps.html#viewing-dataapp-logs"
  },
  {
    path: WebPaths.DataConnectors,
    url: "/Connectors.html#connectors-overview"
  },
  {
    path: WebPaths.DataConnector,
    url: "/Connectors/Connect%20to%20external%20dataconnectors.html#connect-to-external-connectors"
  },

  {
    path: WebPaths.Dataapps,
    url: "/DataApps.html#dataapps-dashboard"
  },
  {
    path: WebPaths.Dataset,
    url: "/Connectors/Connect%20to%20external%20dataconnectors.html#importing-dataset-s-to-the-canvas-from-the-local-system"
  },
  {
    path: WebPaths.EditPredictionJob,
    url: "/Projects/Prediction%20Run.html#creating-a-prediction-run"
  },
  {
    path: WebPaths.EnvironmentConfig,
    url: "/Environments.html#editing-the-environment-details"
  },
  {
    path: WebPaths.EnvironmentLogs,
    url: "/Environments.html#creating-an-environment"
  },
  {
    path: WebPaths.Environments,
    url: "/Environments.html#environment-dashboard"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobArtifacts}`,
    url: "/Artifacts%20%26%20Models.html#managing-artifacts"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobAutoMLRecipe}`,
    url: "/Projects/Recipes.html#developing-ml-models-using-the-rapid-model-recipe"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobCanvas}`,
    url: "/Projects/Schedulers.html#scheduler-overview"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobChart}`,
    url: "/Artifacts%20%26%20Models.html#"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobCodeRecipe}`,
    url: "/Projects/Recipes.html#adding-an-ai-assisted-recipe"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobCompare}`,
    url: "/Projects/Schedulers.html#comparing-the-canvas-of-the-scheduler-with-current-canvas-of-the-project"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`,
    url: "/Connectors/Connect%20to%20external%20dataconnectors.html#viewing-the-dataset-information"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobId}`,
    url: "/Projects/Schedulers.html"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobModels}`,
    url: "/Artifacts%20%26%20Models.html#prediction-services"
  },
  {
    path: WebPaths.JobRoutes,
    url: "/Projects/Schedulers.html"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobRuns}`,
    url: "/Projects/Schedulers.html#viewing-the-run-history-of-a-specific-scheduler"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobStandardRecipe}`,
    url: "/Projects/Recipes.html#adding-a-template-recipe"
  },
  {
    path: WebPaths.ModelDetails,
    url: "/Artifacts%20%26%20Models.html#viewing-models"
  },
  {
    path: WebPaths.PredictionJob,
    url: "/Projects/Prediction%20Run.html"
  },
  {
    path: WebPaths.PredictionLogs,
    url: "/Projects/Prediction%20Run.html"
  },
  {
    path: WebPaths.ProjectDashboard,
    url: "/DataApps.html#accessing-and-creating-a-dataapp-at-a-project-level"
  },
  {
    path: WebPaths.ProjectDataApp,
    url: "/DataApps.html#accessing-and-creating-a-dataapp-at-a-project-level"
  },
  {
    path: WebPaths.Projects,
    url: "/Projects/Projects.html"
  },
  {
    path: WebPaths.RecipeRunLogs,
    url: "/Projects/Recipes.html#viewing-and-editing-the-recipe-details"
  },
  {
    path: WebPaths.ResetLink,
    url: "/"
  },
  {
    path: WebPaths.ResetPasswordLinkSent,
    url: "/"
  },
  {
    path: WebPaths.RunIdLogs,
    url: "/Projects/Schedulers.html#scheduler-overview"
  },
  {
    path: WebPaths.SendResetPassword,
    url: ""
  },
  {
    path: WebPaths.SignIn,
    url: ""
  },
  {
    path: WebPaths.SignUp,
    url: ""
  },
  {
    path: WebPaths.StandardRecipeDataContainer,
    url: "/Projects/Recipes.html#adding-a-template-recipe"
  },
  {
    path: WebPaths.TenantManagement,
    url: "/Workspaces.html#settings"
  },
  {
    path: WebPaths.UserProfile,
    url: "/"
  },
  {
    path: WebPaths.VariablesList,
    url: "/Projects/Projects.html#configuring-global-variables-at-the-project-level"
  },
  {
    path: WebPaths.ViewData,
    url: "/Connectors/Connect%20to%20external%20dataconnectors.html#viewing-the-dataset-information"
  }
];
