import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Grid, makeStyles, Tooltip } from "@material-ui/core";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import markdownToTxt from "markdown-to-txt";

import AIGuideTextRow from "./AIGuideTextRow";
import { DeleteQueryModal } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/DeleteQueryModal";
import { handleResponse } from "src/utils/apiService";
import AIGuideTextResponse from "./AIGuideTextResponse";
import AIGuideDatasetResponse from "./AIGuideDatasetResponse";
import AIGuideChartResponse from "./AIGuideChartResponse";
import {
  AI_GUIDE_MESSAGES_KEY,
  useDeleteAIGuideMessages,
  useRetryAIGuideChatMutation
} from "src/hooks/api";
import { get, isEmpty } from "lodash";

import {
  AIChatResponseDto,
  AIChatRequestDtoOutputTypeEnum,
  MessageFeedbackRequestDto,
  InputIdDto,
  AIChatRequestDtoUseCaseEnum
} from "@rapidcanvas/rc-api-core";
import AIGuideFeedback from "./AIGuideFeedback";
import { Spinner } from "src/components";
import AskAIHeaderActions from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponses/AskAIHeaderActions";
import { PromptSuggestions } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIPromptSuggestions/PromptSuggestions";
import { DeleteQueryErrorModal } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/DeleteQueryErrorModal";
import useExplainCode from "src/hooks/api/codeRecipe/useExplainCode";
import EventBus from "src/utils/EventBus";
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";
import { RELOAD_MUTATION_KEY } from "src/hooks/api/codeRecipe/useReloadThread";

export const STOPPED_QUERY_ID = "stopped_query";

const useStyles = makeStyles((theme) => ({
  message: {
    paddingBottom: "16px"
  },
  answer: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: 400
  },
  dataset: {
    padding: "0px 32px 0px 16px",
    width: "calc(100% - 28px)"
  },
  btn: {
    padding: "2px 8px !important"
  }
}));

const AIGuideResponse = ({
  message,
  isGenerating,
  canRetry,
  inputIds,
  showActions = false,
  targetId,
  hasMarkDown = false,
  existingEntityNames = undefined,
  setIsRetryInProgress,
  canDelete = true,
  hideInputs,
  descendants,
  hideFeedback = false,
  projectId,
  isAIGuide = false,
  autoGenerateCode
}: {
  message: AIChatResponseDto;
  isGenerating: boolean;
  canRetry: boolean;
  inputIds: InputIdDto[];
  showActions?: boolean;
  targetId?: string;
  hasMarkDown?: boolean;
  existingEntityNames?: {
    inputs: (string | undefined)[];
    existingCharts: (string | undefined)[];
    existingDatasets: (string | undefined)[];
    existingModels: (string | undefined)[];
  };
  canDelete?: boolean;
  setIsRetryInProgress?: (isRetryInProgress: boolean) => void;
  hideInputs?: boolean;
  descendants?: string[];
  hideFeedback?: boolean;
  projectId?: string;
  isAIGuide?: boolean;
  autoGenerateCode: (text: string) => void;
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const explainCode = useExplainCode();

  const {
    askAIMessageId: messageId,
    query,
    outputType,
    threadId,
    outputEntityResponseList,
    feedback,
    useCase,
    targetInputs,
    error,
    code
  } = message;
  const isOutputError = !isEmpty(error);
  const controllerRef = useRef<AbortController | null>(null);
  const [explainedCode, setExplainedCode] = useState<string>();
  const [explainCodeSuccess, setExplainCodeSuccess] = useState(false);

  const [shouldShowCode, setShouldShowCode] = useState<boolean>(false);
  const deleteQueriesMutation = useDeleteAIGuideMessages();
  const retryAIGuideChatMutation = useRetryAIGuideChatMutation(threadId!);
  const outputEntityResponse = outputEntityResponseList?.[0];
  const isOutputDataset = outputType === AIChatRequestDtoOutputTypeEnum.Dataset;
  const isOutputChart = outputType === AIChatRequestDtoOutputTypeEnum.Chart;
  const isOutputModel = outputType === AIChatRequestDtoOutputTypeEnum.Model;
  const isOutputText = !isOutputDataset && !isOutputChart && !isOutputModel;
  const isMutating = useIsMutating({ mutationKey: [RELOAD_MUTATION_KEY, threadId] });

  const isPromptSuggestionsUseCase =
    useCase === AIChatRequestDtoUseCaseEnum.PromptSuggestionsUseCase;

  const [isExpanded, setIsExpanded] = useState<boolean>(
    isOutputError
      ? false
      : outputType === AIChatRequestDtoOutputTypeEnum.Dataset ||
          outputType === AIChatRequestDtoOutputTypeEnum.Chart
        ? !isEmpty(outputEntityResponseList)
        : !!outputEntityResponse?.textAnswer || (message as any)?.isExpanded || false
  );

  useEffect(() => {
    EventBus.subscribe(EVENTBUS_EVENTS.StopRetryQuery, () => {
      controllerRef?.current?.abort();
      retryAIGuideChatMutation.reset();
    });

    return () => {
      EventBus.unsubscribe(EVENTBUS_EVENTS.StopRetryQuery);
    };
  }, []);

  const onDeleteQuery = (messageId: string) => {
    deleteQueriesMutation.mutate(
      {
        messageIds: [messageId, ...(descendants || [])],
        threadId: message.threadId!,
        projectId
      },
      {
        onSuccess: () => {
          setShowDeleteModal(false);
          handleResponse({ successMessage: "Query deleted successfully" });
        }
      }
    );
  };

  const onExplainCode = () => {
    if (!!messageId && !!threadId) {
      explainCode.mutate(
        { threadId, messageId },
        {
          onSuccess: (data) => {
            const isAnyErrorMsgExists =
              !!data?.error?.explanation ||
              !!data?.error?.errMessage ||
              !!data?.error?.originalErrMessage;
            if (!isAnyErrorMsgExists) {
              const response = get(data, ["outputEntityResponseList", 0, "textAnswer"]);
              if (response) {
                setExplainedCode(response);
                setExplainCodeSuccess(true);
              }
            } else {
              handleResponse({ errorMessage: "Something went wrong.Please try again" });
            }
          }
        }
      );
    }
  };

  const handleOnFeedbackSuccess = ({ feedback, comment }: MessageFeedbackRequestDto) => {
    queryClient.setQueryData(
      [AI_GUIDE_MESSAGES_KEY, threadId!],
      (messages: AIChatResponseDto[] | undefined) => {
        return (messages || [])?.map((message) => {
          if (message.askAIMessageId !== messageId) {
            return message;
          }
          return {
            ...message,
            feedback,
            comment
          };
        });
      }
    );
  };

  const isDeleteInProgress = deleteQueriesMutation.isLoading;

  useEffect(() => {
    isGenerating && isExpanded && setIsExpanded(false);
  }, [isGenerating]);

  const handleRetry = () => {
    setIsRetryInProgress?.(true);
    const newController = new AbortController();
    controllerRef.current = newController;

    retryAIGuideChatMutation.mutate(
      {
        messageId: messageId!,
        request: {
          inputIds,
          query: query!,
          useCase: useCase!,
          outputType: outputType!
        },
        controller: newController
      },
      {
        onSettled: () => {
          setIsRetryInProgress?.(false);
          setExplainCodeSuccess(false);
          setExplainedCode(undefined);
        }
      }
    );
  };

  const commonProps = React.useMemo(
    () => ({
      message,
      isExpanded,
      setIsExpanded,
      shouldShowCode,
      explainedCode
    }),
    [message, isExpanded, setIsExpanded, shouldShowCode, explainedCode]
  );

  const rightContainer =
    showActions && isExpanded ? (
      <AskAIHeaderActions
        message={message}
        isOutputChart={isOutputChart}
        isOutputModel={isOutputModel}
        isOutputText={isOutputText}
        isOutputDataset={isOutputDataset}
        shouldShowCode={shouldShowCode}
        setShouldShowCode={setShouldShowCode}
        targetId={targetId}
        existingEntityNames={existingEntityNames}
        onExplainCode={onExplainCode}
        explainIsloading={explainCode.isLoading}
        explainCodeSuccess={explainCodeSuccess}
        isRetryInProgress={retryAIGuideChatMutation.isLoading}
      />
    ) : (
      <React.Fragment />
    );

  const queryInputs = useMemo(
    () =>
      (targetInputs || [])?.map((targetInput) => ({
        name: targetInput.intermediateDataset?.entityName || targetInput.entityName!
      })),
    [targetInputs]
  );

  const handleToggle = () => {
    setIsExpanded((expanded) => !expanded);
  };

  const cleanedPrompts = useMemo(
    () =>
      (message.outputEntityList || message.outputEntityResponseList)?.map((response) => {
        const prompt = markdownToTxt(response.textAnswer || "");
        const [title, description] = prompt.replace(/Prompt \d+: /, "").split(". ");
        return { title, description };
      }),
    [message]
  );

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.message}>
      {isPromptSuggestionsUseCase && !isOutputError ? (
        <PromptSuggestions
          isGenerating={isGenerating}
          isExpanded={isExpanded}
          setShowDeleteModal={setShowDeleteModal}
          userInput={message.query}
          handleToggle={handleToggle}
          inputNames={hideInputs ? [] : queryInputs?.map(({ name }) => name)}
          promts={cleanedPrompts || []}
          tags={["Prompt Suggestions"]}
          autoGenerateCode={autoGenerateCode}
        />
      ) : (
        <>
          <AIGuideTextRow
            userInput={isAIGuide ? undefined : query!}
            title={isAIGuide ? query! : undefined}
            titleColor={isAIGuide ? "#000" : "#4646B5"}
            width="750px"
            canDelete={messageId !== STOPPED_QUERY_ID}
            setShowDeleteModal={setShowDeleteModal}
            maxHeight="132px"
            disableDelete={isGenerating && isOutputDataset}
            queryInputs={hideInputs ? [] : queryInputs}
            color={"#fff"}
            hoverBgColor="#fff"
            deleteTooltip={
              isGenerating && isOutputDataset ? "Please wait until existing query is complete" : ""
            }
          />
          {isOutputError && code ? (
            <AIGuideTextResponse
              {...commonProps}
              hasMarkDown={hasMarkDown}
              rightContainer={rightContainer}
              isOutputDataset={isOutputDataset}
              isOutputChart={isOutputChart}
              isOutputModel={isOutputModel}
              setIsExpanded={(expanded: boolean) => {
                isOutputError && setIsExpanded(expanded);
              }}
            />
          ) : isOutputDataset && !isOutputError ? (
            <AIGuideDatasetResponse {...commonProps} rightContainer={rightContainer} />
          ) : (isOutputChart || isOutputModel) && !isOutputError ? (
            <AIGuideChartResponse
              {...commonProps}
              rightContainer={rightContainer}
              isAIGuide={isAIGuide}
            />
          ) : (
            <AIGuideTextResponse
              {...commonProps}
              hasMarkDown={hasMarkDown}
              isOutputDataset={isOutputDataset}
              isOutputChart={isOutputChart}
              isOutputModel={isOutputModel}
              rightContainer={rightContainer}
              isAIGuide={isAIGuide}
            />
          )}
        </>
      )}
      {isExpanded && (
        <Box pl="56px">
          <AIGuideFeedback
            key={messageId!}
            threadId={threadId!}
            messageId={messageId!}
            feedback={feedback}
            hideButtons={hideFeedback}
            onSuccess={handleOnFeedbackSuccess}
            rightComponent={
              canRetry &&
              isOutputError && (
                <Tooltip
                  title={
                    explainCode.isLoading
                      ? "Please wait explain code action is in progress"
                      : !!isMutating
                        ? "Please wait until the chat is getting refreshed"
                        : ""
                  }>
                  <span>
                    <Button
                      className={classes.btn}
                      data-testid="ai-retry-btn"
                      color="primary"
                      variant="text"
                      size="small"
                      disabled={
                        retryAIGuideChatMutation.isLoading || explainCode.isLoading || !!isMutating
                      }
                      onClick={handleRetry}>
                      Retry
                      {retryAIGuideChatMutation.isLoading && (
                        <Spinner size={16} padding="3px 8px" />
                      )}
                    </Button>
                  </span>
                </Tooltip>
              )
            }
          />
        </Box>
      )}

      {showDeleteModal &&
        (canDelete ? (
          <DeleteQueryModal
            userInput={query}
            handleSubmit={() => onDeleteQuery?.(messageId!)}
            onClose={() => setShowDeleteModal?.(false)}
            loading={isDeleteInProgress}
          />
        ) : (
          <DeleteQueryErrorModal onClose={() => setShowDeleteModal?.(false)} />
        ))}
    </Grid>
  );
};

export default React.memo(AIGuideResponse);
