import React, { useCallback, useMemo, useState } from "react";

// Packages
import { useParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { filter, includes, map, size } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";

// Icons
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { getErrorMessage, handleErrorWithRedirectToLogs } from "src/utils/apiService";

// Open API
import { ProjectRunDetailDto } from "@rapidcanvas/rc-api-core";

// Hooks
import { useGetJobsNew, usePublishJob, UsePublishJobsQueryKeys } from "src/hooks/api";

// Components
import { Modal, PreviewPlaceholder } from "src/components/custom";
import ConfirmClose from "./ConfirmClose";

// Constants
import {
  PublishJobsFormFields,
  PublishJobsFormFieldsNameMapping,
  PublishJobsModalHelperText
} from "./PublishJobsModal.constants";

// Styles
import useStyles from "./PublishJobsModal.styles";

type PublishJobsFormFieldsType = {
  [PublishJobsFormFields.Jobs]: string[];
};

interface IProps {
  onClose: () => void;
}

const PublishJobsModal: React.FC<IProps> = (props) => {
  const { onClose } = props;

  const { projectId } = useParams();

  const classes = useStyles();

  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);

  // Query hooks - STARTS >>
  // Queries
  const { isLoading: isJobsFetching, data: jobs } = useGetJobsNew({
    projectId,
    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely
    refetchOnMount: true
  });

  // Mutations
  const {
    isLoading: isPublishJobInProgress,
    mutateAsync: publishJobMutation,
    reset: resetPublishJobMutation
  } = usePublishJob({ projectId });

  const pendingPublishJobMutations = useIsMutating({
    mutationKey: [UsePublishJobsQueryKeys.PublishJobs, projectId]
  });

  const isPublishingJob = useMemo(
    () => !!isPublishJobInProgress || pendingPublishJobMutations > 0,
    [isPublishJobInProgress, pendingPublishJobMutations]
  );
  // << ENDS - Query hooks

  // Form - STARTS >>
  const {
    control,
    getValues,
    formState: { isDirty, isValid }
  } = useForm<PublishJobsFormFieldsType>({
    mode: "onChange", // Validate onChange
    reValidateMode: "onChange" // Re-validate onChange
  });

  // Utility function to handle checkbox changes
  const handleCheckboxChange = (
    selectedValues: string[],
    onChange: (value: string[]) => void,
    jobId?: string
  ) => {
    const updatedValue = (
      includes(selectedValues, jobId)
        ? filter(selectedValues, (id) => id !== jobId) // Remove jobId if already selected
        : [...selectedValues, jobId]
    ) as string[]; // Add jobId if not already selected
    onChange(updatedValue);
  };
  // << ENDS - Form

  // Confirm close modal - STARTS >>
  const setShowConfirmCloseModalHandler = () => setShowConfirmCloseModal(() => true);
  const resetShowConfirmCloseModalHandler = () => setShowConfirmCloseModal(() => false);

  const isAttemptedClose = useCallback(() => {
    if (!!isDirty && !isPublishingJob) {
      setShowConfirmCloseModalHandler();
    } else {
      onClose();
    }
  }, [isDirty, isPublishingJob]);
  // << ENDS - Confirm close modal

  const publishJob = async () => {
    const jobIds = getValues()?.[PublishJobsFormFields.Jobs];

    try {
      resetPublishJobMutation();
      const promises = map(jobIds, (jobId) => publishJobMutation(jobId));
      await Promise.all(promises);
      toastWrapper({
        type: ToastTypes.Success,
        content: `Project Canvas is republished to selected ${size(jobIds)} Scheduler${size(jobIds) > 1 ? "s" : ""}!`
      });

      onClose();
    } catch (error) {
      handleErrorWithRedirectToLogs(getErrorMessage(error));
    }
  };

  const isLoading = useMemo(() => !!isJobsFetching, [isJobsFetching]);
  const isReadonly = useMemo(() => !!isPublishingJob, [isPublishingJob]);

  const disabledCancelActionMessage = useMemo(() => {
    if (!!isPublishingJob) {
      return "Please wait. The republish action is in progress.";
    }

    return "";
  }, [isPublishingJob]);

  const disabledPublishActionMessage = useMemo(() => {
    if (!!isLoading) {
      return "Please wait. Fetching required data.";
    }

    if (!!isPublishingJob) {
      return "Please wait. The republish action is in progress.";
    }

    if (!isDirty) {
      return "Change fields to enable this action.";
    }

    if (!isValid) {
      return "Invalid fields.";
    }

    return "";
  }, [isLoading, isPublishingJob, isDirty, isValid]);

  return (
    <>
      {!!showConfirmCloseModal && (
        <ConfirmClose onConfirm={onClose} onCancel={resetShowConfirmCloseModalHandler} />
      )}

      <Modal
        open
        title={PublishJobsModalHelperText.title}
        onClose={isAttemptedClose}
        // Cancel
        cancelLabel={PublishJobsModalHelperText.cancelLabel}
        isCancelDisabled={!!disabledCancelActionMessage}
        cancelActionInfo={disabledCancelActionMessage}
        // Submit
        submitLabel={PublishJobsModalHelperText.submitLabel}
        isSubmitDisabled={!!disabledPublishActionMessage}
        isSubmitting={!!isPublishingJob}
        submitActionInfo={disabledPublishActionMessage}
        onSubmit={publishJob}>
        <Grid container direction="column">
          <Grid item>
            {!!isJobsFetching ? (
              <FormControl component="fieldset" size="small" margin="dense" fullWidth>
                <FormLabel component="legend">
                  <Typography variant="body2" data-testid="publishJobsModalJobsPreviewLabel">
                    {PublishJobsFormFieldsNameMapping[PublishJobsFormFields.Jobs]}
                  </Typography>
                </FormLabel>
                <FormGroup>
                  <PreviewPlaceholder
                    label={PublishJobsFormFieldsNameMapping[PublishJobsFormFields.Jobs]}
                    simulateFormControl={false}
                    height={38}
                    data-testid="publishJobsModalJobsPreview"
                  />
                </FormGroup>
              </FormControl>
            ) : (
              <Controller
                control={control}
                name={PublishJobsFormFields.Jobs}
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  const { value = [], onChange } = field;
                  const { error } = fieldState;

                  return (
                    <FormControl
                      component="fieldset"
                      size="small"
                      margin="dense"
                      error={!!error}
                      fullWidth>
                      <FormLabel component="legend">
                        <Typography variant="body2" data-testid="publishJobsModalJobsLabel">
                          {PublishJobsFormFieldsNameMapping[PublishJobsFormFields.Jobs]}
                        </Typography>
                      </FormLabel>
                      <FormGroup className={classes.publishJobsListContainer}>
                        {map(jobs, (job: ProjectRunDetailDto) => (
                          <FormControlLabel
                            key={job?.dto?.id}
                            control={
                              <Checkbox
                                size="small"
                                color="primary"
                                disabled={!!isReadonly}
                                checked={includes(value, job?.dto?.id)}
                                onChange={() =>
                                  handleCheckboxChange(value as string[], onChange, job?.dto?.id)
                                }
                                value={job?.dto?.id}
                                data-testid={`publishJobsModalJob${job?.dto?.id}`}
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                data-testid={`publishJobsModalJob${job?.dto?.id}Label`}>
                                {job?.dto?.name}
                              </Typography>
                            }
                            className="publishJobsListItem"
                          />
                        ))}
                      </FormGroup>
                      {error?.message && (
                        <FormHelperText data-testid="publishJobsModalErrorMessage">
                          {error.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
                data-testid="publishJobsModalJobs"
              />
            )}
          </Grid>
          <Grid item>
            <Alert
              variant="outlined"
              severity="info"
              className={classes.publishJobsModalInfo}
              icon={
                <ReportProblemOutlinedIcon
                  fontSize="small"
                  color="action"
                  data-testid="publishJobsModalInfoIcon"
                />
              }>
              <Typography
                variant="caption"
                color="textSecondary"
                data-testid="publishJobsModalInfo">
                {PublishJobsModalHelperText.Info}
              </Typography>
            </Alert>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default PublishJobsModal;
