import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import SplashIcon from "src/icons/NewUX/SplashIcon";
import { SplashSection } from "src/components";
import { EnvironmentsHelperText, SplashSectionHelperText } from "../utils/Environments.constants";

type IProps = {
  onClick: $TSFixMeFunction;
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "calc(100vh - 64px - 254px)",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey[700]
  },
  gridIcon: {
    height: 215,
    width: 215,
    marginRight: 140
  }
}));

export { useStyles };

const SplashSectionWrapper: React.FC<IProps> = (props) => {
  const { onClick } = props;

  const classes = useStyles();

  return (
    <SplashSection
      gridContainerStyle={classes.gridContainer}
      gridIconStyle={classes.gridIcon}
      button={{
        color: "primary",
        onClick,
        label: `+ ${EnvironmentsHelperText.NewEnvironment}`
      }}
      titleSection={{
        title: SplashSectionHelperText.Title,
        subtitleLines: [SplashSectionHelperText.SubLine1, SplashSectionHelperText.SubLine2]
      }}
      icon={<SplashIcon />}
    />
  );
};

export default SplashSectionWrapper;
