import React, { useCallback, useMemo } from "react";

// Packages
import { isEmpty } from "lodash";

// MUI
import Typography from "@material-ui/core/Typography";

// Icons
import { PencilIcon } from "src/icons/NewUX";
import { Delete } from "src/icons/Delete";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { ConfigIcon } from "src/icons/ConfigIcon";

// Open API
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Utils
import { dateFormat } from "src/utils/dateFormat";
import { snakeCaseToStartCase } from "src/utils/helpers";

// Components
import { OverflowTooltip, Table } from "src/components";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import Projects from "../ProjectsField/ProjectsField";
import Status from "./Status";

// Constants
import { EnvironmentsHelperText } from "../../utils/Environments.constants";

interface IProps {
  environments: EnvDto[];
  searchValue: string;
  onEdit: (envId?: string) => void;
  onConfig: (env: EnvDto) => void;
  onLogs: (env: EnvDto) => void;
  onDelete: (env: EnvDto) => void;
}

const EnvironmentsTable: React.FC<IProps> = (props) => {
  const { environments, searchValue, onEdit, onConfig, onLogs, onDelete } = props || {};

  const disabledDeleteActionMessage = useCallback((environment?: EnvDto) => {
    if (environment?.launchStatus === EnvDtoLaunchStatusEnum.Launching) {
      return "Cannot delete a launching environment";
    }

    return "";
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        id: "Name",
        accessor: "name",
        isTooltip: false,
        Cell: ({ row, value }: any) => (
          <LinkCellWithMenu
            linkText={
              <OverflowTooltip
                style={{ whiteSpace: "nowrap" }}
                value={value}
                data-testid="envListNameAction"
              />
            }
            onClick={() => onEdit(row?.original?.id)}
            menuButtons={[
              {
                icon: (
                  <Typography color="textSecondary" data-testid="envListEditIcon">
                    <PencilIcon />
                  </Typography>
                ),
                label: EnvironmentsHelperText.Edit,
                action: () => onEdit(row?.original?.id)
              },
              {
                icon: (
                  <Typography color="textSecondary" data-testid="envListConfigIcon">
                    <ConfigIcon />
                  </Typography>
                ),
                label: EnvironmentsHelperText.Config,
                action: () => onConfig(row?.original)
              },
              {
                icon: (
                  <Typography color="textSecondary" data-testid="envListLogsIcon">
                    <LogsNewIconV2 width="24" />
                  </Typography>
                ),
                label: EnvironmentsHelperText.Logs,
                action: () => onLogs(row?.original)
              },
              {
                icon: (
                  <Typography color="textSecondary" data-testid="envListDeleteIcon">
                    <Delete />
                  </Typography>
                ),
                label: EnvironmentsHelperText.Delete,
                action: () => onDelete(row?.original),
                disabled: !!disabledDeleteActionMessage(row?.original),
                tooltip: disabledDeleteActionMessage(row?.original)
              }
            ]}
          />
        )
      },
      {
        Header: "Env Type",
        id: "Env Type",
        accessor: "envType",
        Cell: ({ value }: any) => snakeCaseToStartCase(value, { toUpperCase: ["cpu"] })
      },
      {
        Header: "Description",
        id: "Description",
        accessor: "description"
      },
      {
        Header: "Updated by",
        id: "Updated by",
        accessor: "updater"
      },
      {
        Header: "Updated on",
        id: "Updated on",
        accessor: "updated",
        Cell: ({ value }: any) => <span data-testid="envListDate">{dateFormat(value)}</span>
      },
      {
        id: "Used in",
        accessor: (props: $TSFixMe) =>
          props.projects
            ? props.projects?.reduce(
                (acc: string, project: $TSFixMe) =>
                  acc.length ? acc.concat(`, ${project}`) : project,
                ""
              )
            : "",
        Header: "Used In",
        isSortable: true,
        isTooltip: false,
        Cell: ({ row }: $TSFixMe) =>
          isEmpty(row?.original?.projectDtos) ? <></> : <Projects environment={row?.original} />
      },
      {
        Header: "Status",
        id: "Status",
        accessor: "launchStatus",
        isTooltip: false,
        Cell: ({ row }: any) => <Status environment={row?.original} />
      }
    ],
    []
  );

  return (
    <Table
      size="small"
      data={environments}
      columns={columns}
      isTheadSticky
      hideCount
      globalFilter={searchValue}
      emptyTableMessage="No records"
      orderByDefault="Updated on"
      maxHeight="calc(100vh - 339px)"
      unsorted
    />
  );
};

export default EnvironmentsTable;
