import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";
import { SegmentDto, UpdateSegmentDto } from "@rapidcanvas/rc-api-core";

import { areAllKeysPresentAndNotNil } from "src/utils/helpers";

const useUpdateSegment = (): UseMutationResult<SegmentDto, unknown, UpdateSegmentDto, unknown> =>
  useMutation({
    mutationFn: async (payload: UpdateSegmentDto) => {
      if (!areAllKeysPresentAndNotNil(payload, ["id", "name"])) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.SegmentControllerApi.update1({ ...payload })
      );
    }
  });

export default useUpdateSegment;
