import React, { useMemo, useState } from "react";

// Packages
import clsx from "clsx";
import shallow from "zustand/shallow";
import { find, get, has, includes } from "lodash";

// MUI
import { Typography } from "@material-ui/core";

// Utils
import { addEllipsis, handleClickClosure } from "src/helpers/helpers";
import { DatasetStatuses, NodeDatasetTheme } from "src/pages/private/ProjectsModule/utils";
import {
  isNodeSelected,
  updateNodeSelectionStore
} from "src/pages/private/ProjectsModule/utils/Dag.helpers";

// Open API
import {
  ThreadResponseDtoDatasetContextEnum,
  ThreadResponseDtoTargetTypeEnum
} from "@rapidcanvas/rc-api-core";

// Stores
import { useCanvasStore, useDataSourcesStore } from "src/store/store";
import { connectorsGetter } from "src/store/store.selectors";

// Components
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";
import NodesWrapper from "../NodesWrapper";
import ContextMenuWrapper from "../ContextMenuWrapper";
import ContextMenu from "./ContextMenu";
import NodeActionWrapper from "../NodeActionWrapper";
import ExpandCollapseIconButton from "../ExpandCollapseIconButton";
import AIGuideDialog from "src/pages/Projects/AIGuide/common/AIGuideDialog";
import NodeCaptionOverflowTooltip from "../NodeCaptionOverflowTooltip";

// Hooks
import { useCtrlKeyPress } from "src/hooks/useCtrlKeyPress";
import useAttributes from "./useAttributes";
import useActions from "./useActions";
import useContextMenu from "../useContextMenu";

// Contexts
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { useDagFlowContext } from "../../DagFlow/context/useDagFlowContext";

// Types
import { NodeData } from "src/types";

// Styles
import useStyles from "./Dataset.styles";
import useNodeStyle from "../../../hooks/useNodeStyle";

type Props = {
  data: NodeData;
};

const Dataset = (props: Props) => {
  const { data } = props || {};

  const { classes, statusKey } = useNodeStyle({
    classes: useStyles({ status: data?.status }),
    status: data?.status
  });

  // Contexts
  const { canAddStandardRecipe } = useAccessControlContext();
  const { scenario, isNodeHighlighted, nodesExpanded } = useDagFlowContext();

  // Stores - STARTS >>
  const connectorsStore = useDataSourcesStore(connectorsGetter);
  const [nodeToFocusStore, shouldBlockClickHandlerTriggerStore] = useCanvasStore(
    (state) => [state.nodeToFocus, state.shouldBlockClickHandlerTrigger],
    shallow
  );
  // << ENDS - Stores

  // States - STARTS >>
  const [isHovered, setIsHovered] = useState(false);
  const [showAIGuideDialog, setShowAIGuideDialog] = useState(false);
  // << ENDS - States

  const connector = useMemo(
    () =>
      find(connectorsStore, {
        name:
          data?.entityDSDetails?.type === thirdPartyTypeName
            ? data?.entityDSDetails?.tpConnectorType
            : data?.entityDSDetails?.type
      }) || {},
    [connectorsStore, data?.entityDSDetails?.type]
  );

  const disabledMessage = useMemo(() => {
    if (
      !!data?.isJobCanvas &&
      includes(
        [DatasetStatuses.UnBuilt, DatasetStatuses.Empty, DatasetStatuses.Skipped],
        data?.status
      )
    ) {
      return `Certain click actions are restricted as supplementary details for the specified entity ${data?.label} are not accessible in the context of this job run entry.`;
    }

    return "";
  }, [data?.isJobCanvas, data?.status, data?.label]);

  // Keeping hooks at the bottom so as to pass the above defined props to it.
  // Hooks - STARTS >>
  const { isCtrlPressed } = useCtrlKeyPress();

  const { onClick, onDoubleClick } = useActions({
    canAddStandardRecipe,
    data,
    connector,
    disabledMessage
  });
  const { icon, status } = useAttributes({ data, connector });

  const {
    contextMenuAnchorEl,
    // Mouse-leave action is not fully supported. Hence, deferring mouse-enter action.
    // actionsOnMouseEnter,
    closeContextMenu,
    contextMenuProp
  } = useContextMenu({
    data,
    disabledMessage
  });
  // << ENDS - Hooks

  const additionalStyles = useMemo(() => {
    let thisStyles = classes.defaultStyles;

    if ((data?.entityDSDetails?.id && data?.entityDSDetails?.purpose === "IMPORT") || data?.image) {
      thisStyles = classes.connectorNodeStyles;
    }

    return thisStyles;
  }, [data?.entityDSDetails, data?.image]);

  return (
    <>
      <ContextMenuWrapper
        id={`datasetContextMenu-${data?.id}`}
        contextMenuAnchorEl={contextMenuAnchorEl}
        closeContextMenu={closeContextMenu}>
        <ContextMenu
          closeContextMenu={closeContextMenu}
          isDefaultScenario={!!scenario?.default}
          openAIGuideDialog={() => setShowAIGuideDialog(true)}
          data={data}>
          {!!data?.id && !!data?.isSourceNode && (
            <NodeActionWrapper title={nodesExpanded[data?.id] ?? true ? "Collapse" : "Expand"}>
              <ExpandCollapseIconButton nodeId={data?.id} />
            </NodeActionWrapper>
          )}
        </ContextMenu>
      </ContextMenuWrapper>
      <AIGuideDialog
        open={showAIGuideDialog}
        onClose={() => {
          setShowAIGuideDialog(false);
        }}
        projectId={data?.projectId!}
        scenarioId={data?.scenarioId!}
        datasetId={data.id!}
        targetType={ThreadResponseDtoTargetTypeEnum.Dataset}
        datasetContext={ThreadResponseDtoDatasetContextEnum.Dataset}
      />
      <NodesWrapper
        nodeId={data?.id}
        disabledMessage={disabledMessage}
        isSourceNode={data?.isSourceNode}
        isTargetNode={data?.isTargetNode}
        backgroundColor={
          has(NodeDatasetTheme, data?.status)
            ? get(NodeDatasetTheme, [data?.status, "backgroundColor"])
            : NodeDatasetTheme.backgroundColor
        }
        isHovered={isHovered}
        setIsHovered={setIsHovered}>
        <div
          role="button"
          tabIndex={0}
          onMouseEnter={() => setIsHovered(() => true)}
          onMouseLeave={() => setIsHovered(() => false)}
          onClick={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTriggerStore || data?.shouldDisableBlockInteraction,
            isCtrlPressed,
            handleDoubleClick: onDoubleClick,
            handleSingleClick: onClick,
            handleMultiSelection: () => updateNodeSelectionStore(data)
          })}
          onKeyPress={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTriggerStore || data?.shouldDisableBlockInteraction,
            isCtrlPressed,
            handleDoubleClick: onDoubleClick,
            handleSingleClick: onClick,
            handleMultiSelection: () => updateNodeSelectionStore(data)
          })}
          {...contextMenuProp}
          className={clsx(classes.root, additionalStyles, classes[statusKey], {
            highlighted:
              isNodeHighlighted(data?.label, nodeToFocusStore) || Boolean(contextMenuAnchorEl),
            active: isNodeSelected(data)
          })}>
          <div className={classes.container}>
            <Typography
              variant="caption"
              className={clsx(classes.status, {
                active: isNodeSelected(data)
              })}>
              {status}
            </Typography>
            <div className={classes.icon}>{icon}</div>
            {data?.label && (
              <NodeCaptionOverflowTooltip label={data?.label}>
                <Typography
                  variant="caption"
                  className={clsx(classes.label, {
                    active: isNodeSelected(data)
                  })}>
                  {addEllipsis(data?.label)}
                </Typography>
              </NodeCaptionOverflowTooltip>
            )}
          </div>
        </div>
      </NodesWrapper>
    </>
  );
};

export default Dataset;
