export enum PublishJobsFormFields {
  Jobs = "schedulers"
}

export const PublishJobsFormFieldsNameMapping = {
  [PublishJobsFormFields.Jobs]: "Select Schedulers"
};

export enum PublishJobsCloseModalPromptDetails {
  title = "Confirm",

  messageLine1 = "If you leave you will lose any unsaved changes.",
  messageLine2 = "Do you really want to close this window?",

  cancelLabel = "No"
}

export enum PublishJobsModalHelperText {
  title = "Republish Canvas to Schedulers",

  Info = "This will replace the existing scheduler canvas with the latest project canvas. Are you sure?",

  cancelLabel = "No",
  submitLabel = "Yes, Republish"
}
