import React, { useMemo } from "react";

// Packages
import { includes, isEmpty } from "lodash";

// Utils
import { formatStringsWithAnd } from "src/helpers/helpers";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

interface IProps {
  data: any;
  confirmStopEnvironment: (env: EnvDto) => void;
  resetStopEnvironment: () => void;
}

const StopEnvironment: React.FC<IProps> = (props) => {
  const { data, confirmStopEnvironment, resetStopEnvironment } = props;

  const onStop = () => confirmStopEnvironment(data?.env);

  const content = useMemo(
    () => [
      `Please note that there are active processes within this environment, such as ${
        !isEmpty(data?.types) ? formatStringsWithAnd(data?.types) : ""
      }. Continuing with ${
        !!data?.action ? data?.action : ""
      } the environment may affect these ongoing operations.`
    ],
    [data]
  );

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title="Are you sure?"
      content={content}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetStopEnvironment();
        }
      }}
      onSubmit={onStop}
      hideCloseIcon
    />
  );
};

export default StopEnvironment;
