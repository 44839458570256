import React, { useCallback, useEffect, useMemo, useState } from "react";

// Packages
import { useQueryClient } from "@tanstack/react-query";
import { Controller, FormProvider } from "react-hook-form";
import {
  every,
  filter,
  find,
  forEach,
  has,
  includes,
  isEmpty,
  keys,
  map,
  size,
  sortBy,
  toLower,
  trim
} from "lodash";

// MUI
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme } from "@material-ui/core/styles";

// Utils
import { getReactHookFormTextFieldRules } from "src/utils/helpers";
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { deleteAPIWithRethrow, postAPIWithRethrow, putAPIWithRethrow } from "src/utils/apiService";

// Open API
import { EnvDto, GlobalVariableDto, UpdateProjectDto } from "@rapidcanvas/rc-api-core";

// Hooks
import { useEnvironments } from "src/hooks";
import {
  useGetEnvironmentTypes,
  useGetProject,
  useGetVariables,
  useUpdateProject
} from "src/hooks/api";
import { useGetRole } from "src/hooks/useGetRole";
import useRelaunchEnvironment from "src/hooks/api/environments/useRelaunchEnvironment";
import { UseGetProjectsQueryKeys } from "src/hooks/api/projects/useGetProjects";
import useProjectSettingsForm from "./hooks/useProjectSettingsForm";

// Stores
import { useProjectsStore } from "src/store/store";
import { projectsGetter } from "src/store/store.selectors";

// Components
import default_image from "src/assets/images/projectImages/default_project_thumbnail1.svg";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PreviewPlaceholder from "./components/PreviewPlaceholder";
import PreviewImageSelectorNew from "./components/ImageSelectionModal/PreviewImageSelectorNew";
import EnvironmentType from "./components/EnvironmentType";
import Variables from "./components/Variables/Variables";
import ConfirmCloseWindow from "./components/ConfirmCloseWindow";

// Constants
import {
  ProjectSettingsFormFields,
  ProjectSettingsFormFieldsNameMapping,
  ProjectSettingsHelperText
} from "./utils/ProjectSettings.constants";

// Styles
import useStyles from "./ProjectSettings.styles";

// Types
import { DirtyFieldsKeys, Variable } from "./ProjectSettings.types";
import {
  DescriptionCharacterLimit,
  DescriptionCharacterLimitMessage,
  DescriptionInfo
} from "../../utils";

type Props = {
  projectId: string;
  onClose: () => void;
  onDelete?: () => void;
};

const ProjectSettings = (props: Props) => {
  const { projectId, onClose, onDelete } = props || {};

  const theme = useTheme();
  const classes = useStyles();

  const queryClient = useQueryClient();

  const inputProps = { classes: { root: classes.inputField } };
  const inputLabelProps = {
    classes: { root: classes.inputLabel, shrink: classes.inputLabelShrink }
  };

  const { isBusinessUser } = useGetRole();
  const relaunch = useRelaunchEnvironment();

  // Stores - STARTS >>
  const projectsStore = useProjectsStore(projectsGetter);
  // << ENDS - Stores

  // States - STARTS >>
  const [environments, setEnvironments] = useState<EnvDto[] | undefined>([]);
  const [variables, setVariables] = useState<GlobalVariableDto[]>([]);

  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Mutations
  const { mutateAsync: saveProjectMutation, reset: resetSaveProjectMutation } = useUpdateProject();

  // Queries
  const { isLoading: isFetchingEnvironmentTypes, data: environmentTypes } =
    useGetEnvironmentTypes();

  const {
    isLoading: isFetchingProject,
    data: project,
    refetch: refetchProject
  } = useGetProject({
    projectId,
    includeJobCount: true,
    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely
    refetchOnMount: true
  });

  const { isLoading: isFetchingEnvironments, data: environmentsData } = useEnvironments();

  useEffect(
    () =>
      setEnvironments(() => sortBy(environmentsData, (environment) => toLower(environment?.name))),
    [environmentsData]
  );
  // << ENDS - Query hooks

  // Form - STARTS >>
  const { formMethods, resetVariables } = useProjectSettingsForm({
    isBusinessUser,
    project,
    variables
  });

  const {
    setValue,
    getValues,
    watch,
    control,
    trigger,
    reset,
    formState: { isValid, isDirty, dirtyFields }
  } = formMethods || {};
  // << ENDS - Form

  const watchImage = watch(ProjectSettingsFormFields.Image);
  const watchDescription = watch(ProjectSettingsFormFields.Description);
  const watchAskAiSystemMessage = watch(ProjectSettingsFormFields.AskAiSystemMessage);

  const { isLoading: isFetchingVariables, refetch: refetchVariables } = useGetVariables({
    projectId,
    ...(!!isBusinessUser
      ? { enabled: false }
      : {
          staleTime: 0, // Ensures data is always considered stale
          cacheTime: Infinity, // Keeps the data in memory indefinitely
          refetchOnMount: true
        }),
    onSuccess: (data) => {
      setVariables(() => data);
      resetVariables(data);
    }
  });

  const saveVariables = async () => {
    if (!!isBusinessUser) {
      return;
    }

    const newVariables = getValues()?.[ProjectSettingsFormFields.Variables];

    const variablesToUpdate: { id?: string; key: string; value: string }[] = [];
    const variablesToDelete: string[] = [];
    const variablesToCreate: Variable[] = [];

    try {
      forEach(variables, (oldVariable) => {
        const newVariable = find(newVariables, { key: oldVariable.name });

        if (!!newVariable) {
          if (!!oldVariable?.name && newVariable?.value !== oldVariable?.value) {
            if (!!oldVariable?.id && !!oldVariable?.name && !!newVariable?.value) {
              variablesToUpdate.push({
                id: oldVariable?.id,
                key: oldVariable?.name,
                value: newVariable?.value
              });
            }
          }
        } else {
          !!oldVariable?.id && variablesToDelete?.push(oldVariable?.id);
        }
      });

      // Handle adding new newVariables that are not part of variables
      forEach(newVariables, (newVariable) => {
        if (!find(variables, { name: newVariable?.key })) {
          if (!!newVariable.key && !!newVariable?.value) {
            variablesToCreate?.push(newVariable);
          }
        }
      });
    } catch {
      toastWrapper({
        type: ToastTypes.Error,
        content: "There is an error in new project variables!"
      });

      return;
    }

    if (every([variablesToUpdate, variablesToDelete, variablesToCreate], (item) => isEmpty(item))) {
      resetVariables(variables);
      return;
    }

    try {
      if (!isEmpty(variablesToUpdate)) {
        await Promise.all(
          map(variablesToUpdate, (variable) =>
            putAPIWithRethrow(
              `/v2/variables/${variable?.id}`,
              {
                name: variable?.key,
                type: "STRING",
                value: variable?.value,
                projectId
              },
              {}
            )
          )
        );
      }

      if (!isEmpty(variablesToDelete)) {
        await Promise.all(
          map(variablesToDelete, (id: string) => deleteAPIWithRethrow(`/v2/variables/${id}`, {}))
        );
      }

      if (!isEmpty(variablesToCreate)) {
        await Promise.all(
          map(variablesToCreate, (variable) =>
            postAPIWithRethrow(
              "/v2/variables",
              {
                name: variable?.key,
                type: "STRING",
                value: variable?.value,
                projectId
              },
              {}
            )
          )
        );
      }

      toastWrapper({
        type: ToastTypes.Success,
        content: ProjectSettingsHelperText.ProjectSaved
      });
    } finally {
      await refetchVariables();
      reset(undefined, { keepValues: true });
      setIsSaving(() => false);
    }
  };

  const isOnlyVariablesFieldDirty = () => {
    // Check if 'variables' key exists and has a non-false value
    const hasValidVariables =
      has(dirtyFields, ProjectSettingsFormFields.Variables) &&
      !isEmpty(dirtyFields[ProjectSettingsFormFields.Variables]);

    // Check if all other fields are either not present or are false
    const allOtherFieldsAreFalse = every(
      keys(dirtyFields),
      (key: DirtyFieldsKeys) =>
        key === ProjectSettingsFormFields.Variables || dirtyFields[key] === false
    );

    return !!hasValidVariables && !!allOtherFieldsAreFalse;
  };

  const getSaveProjectPayload = useCallback(() => {
    const values = getValues();

    let payload: UpdateProjectDto = {
      id: projectId
    };

    if (has(dirtyFields, ProjectSettingsFormFields.Name)) {
      payload = {
        ...payload,
        name: trim(values?.[ProjectSettingsFormFields.Name]),
        display_name: trim(values?.[ProjectSettingsFormFields.Name])
      };
    }

    if (has(dirtyFields, ProjectSettingsFormFields.Description)) {
      payload = {
        ...payload,
        description: trim(values?.[ProjectSettingsFormFields.Description])
      };
    }

    if (has(dirtyFields, ProjectSettingsFormFields.Image)) {
      payload = {
        ...payload,
        image: values?.[ProjectSettingsFormFields.Image]
      };
    }

    if (has(dirtyFields, ProjectSettingsFormFields.EnvironmentType)) {
      payload = {
        ...payload,
        envId: trim(values?.[ProjectSettingsFormFields.EnvironmentType])
      };
    }

    if (has(dirtyFields, ProjectSettingsFormFields.AskAiSystemMessage)) {
      payload = {
        ...payload,
        askAIContext: trim(values?.[ProjectSettingsFormFields.AskAiSystemMessage])
      };
    }

    return payload;
  }, [dirtyFields]);

  const saveProject = async () => {
    setIsSaving(() => true);
    trigger();

    !!projectId && checkEnvRelaunch(projectId);

    if (isOnlyVariablesFieldDirty()) {
      await saveVariables();
      setIsSaving(() => false);
      return;
    }

    let payload: UpdateProjectDto = getSaveProjectPayload();

    resetSaveProjectMutation();
    await saveProjectMutation(payload, {
      onSuccess: async (data) => {
        !!dirtyFields[ProjectSettingsFormFields.EnvironmentType] &&
          !!data?.envId &&
          relaunch.mutate({ envId: data?.envId });

        await refetchProject();
        await queryClient.invalidateQueries([UseGetProjectsQueryKeys.Projects]);

        if (!dirtyFields[ProjectSettingsFormFields.Variables]) {
          reset(undefined, { keepValues: true });
          toastWrapper({
            type: ToastTypes.Success,
            content: ProjectSettingsHelperText.ProjectSaved
          });
          setIsSaving(() => false);
        }
      },
      onError: () => {
        if (!dirtyFields[ProjectSettingsFormFields.Variables]) {
          setIsSaving(() => false);
        }
      }
    });

    if (!!dirtyFields[ProjectSettingsFormFields.Variables]) {
      await saveVariables();
      setIsSaving(() => false);
    }
  };

  const getProjectNameRules = () => {
    const projectNames = map(
      filter(projectsStore, (project) => project?.id !== projectId),
      (project) => toLower(trim(project.name))
    );

    const defaultRules = getReactHookFormTextFieldRules({
      fieldName: ProjectSettingsFormFieldsNameMapping[ProjectSettingsFormFields.Name]
    });

    return {
      ...defaultRules,
      validate: {
        ...defaultRules?.validate,
        noDuplicateName: (value: string) =>
          !includes(projectNames, toLower(trim(value))) ||
          `This ${toLower(ProjectSettingsFormFieldsNameMapping[ProjectSettingsFormFields.Name])} already exists.`
      }
    };
  };

  const getProjectDescriptionRules = () => {
    return {
      validate: {
        maximumCharacter: (value: string) =>
          size(value) <= DescriptionCharacterLimit || DescriptionCharacterLimitMessage
      }
    };
  };

  const isLoadingEnvTypeMetaData = useMemo(
    () => !!isFetchingEnvironmentTypes || !!isFetchingEnvironments,
    [isFetchingEnvironmentTypes, isFetchingEnvironments]
  );

  const isLoading = useMemo(
    () => !!isFetchingProject || !!isLoadingEnvTypeMetaData,
    [isFetchingProject, isLoadingEnvTypeMetaData]
  );

  const isReadOnly = useMemo(() => !!isSaving, [isSaving]);

  const disabledCancelActionMessage = useMemo(() => {
    if (!!isSaving) {
      return "Please wait. The save action is in progress.";
    }

    return "";
  }, [isSaving]);

  const disabledSaveActionMessage = useMemo(() => {
    if (!!isLoading) {
      return "Please wait. Fetching required data.";
    }

    if (!!isSaving) {
      return "Please wait. The save action is in progress.";
    }

    if (!isDirty) {
      return "Change fields to enable this action.";
    }

    if (!isValid) {
      return "Invalid fields.";
    }

    return "";
  }, [isLoading, isSaving, isValid, isDirty]);

  const isAttemptedClose = () => {
    if (!!isDirty) {
      setShowConfirmScreen(() => true);
    } else {
      onClose();
    }
  };

  return (
    <>
      {!!showConfirmScreen && (
        <ConfirmCloseWindow
          onConfirm={onClose}
          onCancel={() => setShowConfirmScreen(() => false)}
        />
      )}

      <Drawer
        open
        anchor="right"
        variant="temporary"
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        {...(!!isSaving ? { disableEscapeKeyDown: true } : { onClose: isAttemptedClose })}>
        <Header
          projectId={projectId}
          projectName={project?.name || project?.display_name || ""}
          isSaving={!!isSaving}
          onClose={isAttemptedClose}
          onDelete={onDelete}
        />

        <FormProvider {...formMethods}>
          <Box mt={6} p={2}>
            <Grid container direction="column" wrap="nowrap" style={{ rowGap: theme.spacing(2.5) }}>
              <Grid container wrap="nowrap" style={{ columnGap: theme.spacing(2) }}>
                <Grid item>
                  {!!isFetchingProject ? (
                    <PreviewPlaceholder
                      width={240}
                      height={120}
                      label={ProjectSettingsFormFieldsNameMapping[ProjectSettingsFormFields.Image]}
                      data-testid="projectSettingsImagePreview"
                    />
                  ) : (
                    <PreviewImageSelectorNew
                      isNewTheme
                      noText
                      previewType="mini-project"
                      defaultImage={watchImage || default_image}
                      disabled={!!isReadOnly}
                      onChange={(image: string) =>
                        setValue(ProjectSettingsFormFields.Image, image, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true
                        })
                      }
                    />
                  )}
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    style={{ rowGap: theme.spacing(2) }}>
                    <Grid item>
                      {!!isFetchingProject ? (
                        <PreviewPlaceholder
                          label={`${ProjectSettingsFormFieldsNameMapping[ProjectSettingsFormFields.Name]} *`}
                          data-testid="projectSettingsNamePreview"
                        />
                      ) : (
                        <Controller
                          control={control}
                          name={ProjectSettingsFormFields.Name}
                          rules={getProjectNameRules()}
                          render={({ field, fieldState }) => {
                            const { error } = fieldState;

                            return (
                              <TextField
                                {...field}
                                variant="outlined"
                                size="small"
                                fullWidth
                                label={`${ProjectSettingsFormFieldsNameMapping[ProjectSettingsFormFields.Name]} *`}
                                disabled={!!isReadOnly}
                                InputProps={inputProps}
                                InputLabelProps={inputLabelProps}
                                error={!!error}
                                {...(!!error?.message ? { helperText: error?.message } : {})}
                                data-testid="projectSettingsNameInput"
                              />
                            );
                          }}
                          data-testid="projectSettingsName"
                        />
                      )}
                    </Grid>

                    <Grid item>
                      {!!isFetchingProject ? (
                        <PreviewPlaceholder
                          height={60}
                          label={
                            ProjectSettingsFormFieldsNameMapping[
                              ProjectSettingsFormFields.Description
                            ]
                          }
                          data-testid="projectSettingsDescriptionPreview"
                        />
                      ) : (
                        <Grid container direction="column">
                          <Controller
                            control={control}
                            name={ProjectSettingsFormFields.Description}
                            rules={getProjectDescriptionRules()}
                            render={({ field, fieldState }) => {
                              const { error } = fieldState;
                              return (
                                <Tooltip title={watchDescription ?? ""} arrow>
                                  <TextField
                                    {...field}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    multiline
                                    minRows={2}
                                    maxRows={2}
                                    label={
                                      ProjectSettingsFormFieldsNameMapping[
                                        ProjectSettingsFormFields.Description
                                      ]
                                    }
                                    error={!!error}
                                    {...(!!error?.message ? { helperText: error?.message } : {})}
                                    disabled={!!isReadOnly}
                                    InputProps={{ ...inputProps, style: { minHeight: 55 } }}
                                    InputLabelProps={inputLabelProps}
                                    data-testid="projectSettingsDescriptionInput"
                                  />
                                </Tooltip>
                              );
                            }}
                            data-testid="projectSettingsDescription"
                          />
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: 400,
                              fontStyle: "italic",
                              opacity: 0.7
                            }}>
                            {DescriptionInfo}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  data-testid="projectSettingsAdditional">
                  {ProjectSettingsHelperText.Additional}
                </Typography>
              </Grid>

              <Grid item>
                <EnvironmentType
                  control={control}
                  isLoadingEnvTypeMetaData={isLoadingEnvTypeMetaData}
                  environmentTypes={environmentTypes}
                  environments={environments}
                  project={project}
                  isReadOnly={!!isReadOnly}
                />
              </Grid>

              {!isBusinessUser && (
                <Variables isFetchingVariables={!!isFetchingVariables} isReadOnly={!!isReadOnly} />
              )}

              <Grid item>
                {!!isFetchingProject ? (
                  <PreviewPlaceholder
                    height={75}
                    label={
                      ProjectSettingsFormFieldsNameMapping[
                        ProjectSettingsFormFields.AskAiSystemMessage
                      ]
                    }
                    data-testid="projectSettingsAskAiSystemMessagePreview"
                  />
                ) : (
                  <Controller
                    control={control}
                    name={ProjectSettingsFormFields.AskAiSystemMessage}
                    render={({ field }) => (
                      <Tooltip
                        title={watchAskAiSystemMessage ?? ""}
                        arrow
                        data-testid="projectSettingsAskAiSystemMessageInfo">
                        <TextField
                          {...field}
                          variant="outlined"
                          size="small"
                          fullWidth
                          multiline
                          minRows={3}
                          maxRows={3}
                          label={
                            ProjectSettingsFormFieldsNameMapping[
                              ProjectSettingsFormFields.AskAiSystemMessage
                            ]
                          }
                          disabled={!!isReadOnly}
                          InputProps={{ ...inputProps, style: { minHeight: 75 } }}
                          InputLabelProps={inputLabelProps}
                          placeholder={ProjectSettingsHelperText.AskAiSystemMessagePlaceholder}
                          data-testid="projectSettingsAskAiSystemMessageInput"
                        />
                      </Tooltip>
                    )}
                    data-testid="projectSettingsAskAiSystemMessage"
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </FormProvider>

        <Footer
          disabledCancelActionMessage={disabledCancelActionMessage}
          disabledSaveActionMessage={disabledSaveActionMessage}
          isSaving={!!isSaving}
          onClose={isAttemptedClose}
          saveProject={saveProject}
        />
      </Drawer>
    </>
  );
};

export default ProjectSettings;
