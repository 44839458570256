import { CodeCheckpointDto, UpdateCodeCheckpointDto } from "@rapidcanvas/rc-api-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";
import { CODE_CHECKPOINTS_KEY } from "./useGetCodeCheckpoints";
import { map } from "lodash";

export const useUpdateCodeCheckpoint = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      request,
      recipeId,
      checkpointId
    }: {
      request: UpdateCodeCheckpointDto;
      recipeId: string;
      checkpointId: string;
    }) => {
      const response = await api.fetchResponse(() =>
        api.CodeCheckpointControllerApi.update8(recipeId, checkpointId, request)
      );
      queryClient.setQueryData(
        ["recipe", recipeId!, CODE_CHECKPOINTS_KEY],
        (prevData: CodeCheckpointDto[] | undefined) =>
          map(prevData, (checkpoint) => (checkpoint.id === checkpointId ? response : checkpoint))
      );
      return response;
    }
  });
};
