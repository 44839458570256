import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { isEmpty, map, uniq, get, size, filter } from "lodash";

import CreateDataApp from "./CreateDataApp/CreateDataApp";
import { DataAppType } from "pages/DataApps/DataApps.type";
import { DataAppsHeader } from "pages/DataApps/DataAppsDashboard/DataAppsHeader/DataAppsHeader";
import { DataAppsSplashSection } from "pages/DataApps/utils/DataApps.constants";
import { DataAppsView } from "pages/DataApps/DataAppsDashboard/DataAppsView";
import { DeleteDataAppModal } from "pages/DataApps/DataAppsDashboard/DeleteDataAppModal";
import { SplashIcon } from "src/icons/NewUX";
import { SplashSection } from "src/components";
import { listViewPages, PagesViewConfiguration } from "src/constants";
import ReactException from "components/Errors/ReactException";
import useGetDataApps from "../../../../hooks/api/dataapps/useGetDataApps";
import useProjectDataAppsDetails from "src/hooks/api/dataapps/useProjectDataAppsDetails";
import useGetAppTemplates from "src/hooks/api/dataapps/useGetAppTemplates";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "calc(100vh - 64px - 254px)",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey[700]
  },
  gridIcon: {
    height: 215,
    width: 215,
    marginRight: 140
  }
}));

const ProjectDataApps: React.FC = () => {
  const { data, isLoading, isError, refetch } = useGetDataApps();

  const { projectId } = useParams();
  const location = useLocation();

  const dataAppDetailsResponse = useProjectDataAppsDetails(projectId);
  const templateResponse = useGetAppTemplates({
    enabled: !!projectId
  });

  const dataApps = useMemo(
    () => filter(data, (app) => app.projectId === projectId),
    [data, projectId]
  );

  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");
  const isTilesView = get(pagesViewConfiguration, listViewPages.DATAAPPS, true);
  const [tilesView, setTilesView] = useState(isTilesView);

  const [openCreateDataApp, setOpenCreateDataApp] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const [dataAppToDelete, setDataAppToDelete] = useState<DataAppType | null>(null);
  const [selectedProjects, setSelectedProjects] = useState<string[]>(
    uniq(dataApps?.map((dataApp) => dataApp.projectId)) || []
  );

  useEffect(() => {
    if (location?.state?.openCreateDataApp) {
      setOpenCreateDataApp(location?.state?.openCreateDataApp);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(selectedProjects)) {
      setSelectedProjects(uniq(map(dataApps, (dataApp) => dataApp.projectId)));
    }
  }, [dataApps]);

  const classes = useStyles();

  const searchedDataApps = React.useMemo(
    () =>
      dataApps?.filter((dataApp: DataAppType) =>
        (dataApp.displayName || dataApp.name)?.toLowerCase?.().includes?.(searchValue.toLowerCase())
      ),
    [dataApps, searchValue]
  );

  const filteredDataApps = React.useMemo(
    () =>
      selectedProjects.length !== 0
        ? searchedDataApps
            ?.sort((a: DataAppType, b: DataAppType) => b?.updatedOn - a?.updatedOn)
            ?.filter((dataApp: DataAppType) => selectedProjects?.includes(dataApp.projectId))
        : [],
    [searchedDataApps, selectedProjects]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleDelete = (dataApp: DataAppType) => {
    setDataAppToDelete(dataApp);
  };

  const handleCreateClick = () => {
    setOpenCreateDataApp(true);
    setSearchValue("");
  };

  if (isLoading) {
    return <CommonLoader />;
  }

  if (isError) {
    return <ReactException onClick={refetch} />;
  }

  return (
    <>
      <DataAppsHeader
        searchValue={searchValue}
        isTilesView={tilesView}
        openCreateDataApp={openCreateDataApp}
        onSearch={handleSearch}
        onViewChange={setTilesView}
        onClick={handleCreateClick}
      />

      {openCreateDataApp ? (
        <CreateDataApp
          dataApps={dataApps}
          binaryClassRecipes={dataAppDetailsResponse.data}
          recipeLoading={dataAppDetailsResponse.isLoading}
          appTemplates={templateResponse.data}
          isLoading={dataAppDetailsResponse.isLoading || templateResponse.isLoading}
          onCancel={() => setOpenCreateDataApp(false)}
        />
      ) : size(dataApps) === 0 ? (
        <SplashSection
          gridContainerStyle={classes.gridContainer}
          gridIconStyle={classes.gridIcon}
          button={{
            color: "primary",
            onClick: handleCreateClick,
            label: DataAppsSplashSection.actionLabel,
            dataTestId: "createDataApp"
          }}
          titleSection={{
            title: DataAppsSplashSection.title,
            subtitleLines: [
              DataAppsSplashSection.subTitleLine1,
              DataAppsSplashSection.subTitleLine2
            ]
          }}
          icon={<SplashIcon />}
        />
      ) : (
        <DataAppsView
          allDataApps={dataApps}
          searchValue={searchValue}
          dataApps={filteredDataApps}
          onDelete={handleDelete}
          setIsTilesView={setTilesView}
          isTilesView={tilesView}
          onSearch={handleSearch}
          isProjectDataApps={true}
          onClearSearch={() => setSearchValue("")}
          searchedDataApps={searchedDataApps}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
        />
      )}
      {dataAppToDelete && (
        <DeleteDataAppModal
          dataAppId={dataAppToDelete?.id}
          dataAppName={dataAppToDelete?.displayName || dataAppToDelete?.name}
          open={!!dataAppToDelete?.id}
          onClose={() => setDataAppToDelete(null)}
        />
      )}
    </>
  );
};

export default ProjectDataApps;
