import { useQueryClient, useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import _ from "lodash";

import { getDataConnectors } from "src/api/dataSources";

import { useDataSourcesStore } from "src/store/store";
import { connectorsSetter } from "src/store/store.selectors";

import { dataSourcesTypes } from "src/pages/DataSources/utils/DataSources.constants";

export const enum UseGetDataConnectorsQueryKeys {
  Connectors = "data-connectors"
}

const DataConnectorErrorMessage =
  "An error occurred while retrieving third-party connector details. Please refresh the page and try loading them again.";

interface ISource {
  description: string;
  iconUrl: string;
  id: string;
  linkToDocs: string;
  linkToErd: string;
  name: string;
}
interface IReturn {
  status: string;
  messages: string[];
  sources: ISource[];
  responseEntity: string;
}

type EnsuredDataConnectorsProps = { queryClient: $TSFixMe };

const defaultConnectors = _.map(dataSourcesTypes, ({ name, displayName, image }) => ({
  name,
  displayName,
  image,
  type: "default" as const,
  isSelected: false
}));

export const getEnsuredDataConnectorsData = async (props: EnsuredDataConnectorsProps) => {
  const { queryClient } = props;

  const dataConnectorsData = await queryClient.ensureQueryData({
    queryKey: [UseGetDataConnectorsQueryKeys.Connectors],
    queryFn: () => {
      return getDataConnectors(true, DataConnectorErrorMessage);
    }
  });

  if (dataConnectorsData?.status === "SUCCESS") {
    if (Array.isArray(dataConnectorsData?.sources)) {
      return [
        ...defaultConnectors,
        ...dataConnectorsData?.sources?.map((eachConnector: $TSFixMe) => ({
          name: eachConnector?.id,
          displayName: eachConnector?.name,
          url: eachConnector?.iconUrl,
          type: "fivetran" as const,
          isSelected: false
        }))
      ];
    }
  }

  return [];
};

export const useGetDataConnectors = (
  options?: UseQueryOptions<IReturn>
): UseQueryResult<IReturn> => {
  return useQuery({
    queryKey: [UseGetDataConnectorsQueryKeys.Connectors],
    queryFn: () => {
      return getDataConnectors(false);
    },
    cacheTime: Infinity,
    enabled: false,
    ...options
  });
};

export const useDataConnectors = async () => {
  const queryClient = useQueryClient();
  const setConnectorsStore = useDataSourcesStore(connectorsSetter);

  const dataConnectorsData: $TSFixMe = await getEnsuredDataConnectorsData({ queryClient });

  if ((dataConnectorsData || [])?.length > 0) {
    setConnectorsStore(dataConnectorsData);
  }
};
