import React from "react";

import Box from "@material-ui/core/Box";

import { ArtifactIcon } from "src/icons/NewUX/ArtifactIcon";
import { ChartIcon } from "src/icons/NewUX";
import { DatasetIcon } from "src/icons/NewUX/DatasetIcon";
import { ModelIcon } from "src/icons/NewUX/ModelIcon";

export const images = [
  "preview_image1.png",
  "preview_image2.png",
  "preview_image3.png",
  "preview_image4.png",
  "preview_image5.png",
  "preview_image6.png",
  "preview_image7.png",
  "preview_image8.png",
  "preview_image9.png",
  "preview_image10.png",
  "preview_image11.png",
  "preview_image12.png",
  "preview_image13.png",
  "preview_image14.png",
  "preview_image15.png",
  "preview_image16.png",
  "preview_image17.png",
  "preview_image18.png",
  "preview_image19.png",
  "preview_image20.png",
  "preview_image21.png",
  "preview_image22.png",
  "preview_image23.png",
  "preview_image24.png",
  "preview_image25.png",
  "preview_image26.png",
  "preview_image27.png",
  "preview_image28.png",
  "preview_image29.png"
];

const publicPath = process.env.PUBLIC_PATH;
const environment = process.env.ENVIRONMENT;
export const cdnImagePath =
  !!publicPath && !!environment ? `${publicPath}/${environment}/images/projectImages` : "";

export const projectRegExpLiteralNotations = {
  jobs: /jobs/,
  jobCanvasCompare: /job-compare/,
  jobCanvas: /job-canvas/
};

export enum NodeTypes {
  Dataset = "ENTITY",
  Recipe = "DFSGROUP",
  Chart = "CHART",
  Artifact = "ARTIFACT",
  Model = "MODEL"
}

export enum NodeTypeNames {
  Dataset = "Dataset",
  Recipe = "Recipe",
  Chart = "Chart",
  Artifact = "Artifact",
  Model = "Model"
}

export const NodeTypesNameMapping: $TSFixMe = {
  [NodeTypes.Dataset]: NodeTypeNames.Dataset,
  [NodeTypes.Recipe]: NodeTypeNames.Recipe,
  [NodeTypes.Chart]: NodeTypeNames.Chart,
  [NodeTypes.Artifact]: NodeTypeNames.Artifact,
  [NodeTypes.Model]: NodeTypeNames.Model
};

export const NodeTypesSessionKeyMapping: $TSFixMe = {
  [NodeTypes.Dataset]: "selectedEntities",
  [NodeTypes.Artifact]: "selectedArtifacts",
  [NodeTypes.Model]: "selectedModels"
};

export const NodeTypesIconMapping: $TSFixMe = {
  [NodeTypes.Dataset]: <DatasetIcon width={18} height={18} viewBox="-1 0 20 20" />,
  [NodeTypes.Chart]: <ChartIcon width={18} height={20} viewBox="-2 -4 40 40" />,
  [NodeTypes.Artifact]: <ArtifactIcon width={18} height={18} viewBox="-2 0 17 17" />,
  [NodeTypes.Model]: <ModelIcon width={22} height={22} viewBox="-2 -2 20 20" />
};

export enum RecipeTypes {
  AiAssisted = "CODE",
  RapidModel = "AUTO_ML",
  Template = "STANDARD",
  ApiConnector = "API_CONNECTOR"
}

export enum RecipeTypeNames {
  AiAssisted = "AI-assisted",
  RapidModel = "Rapid Model",
  Template = "Template",
  ApiConnector = "API Connector"
}

export const RecipeTypesNameMapping = {
  [RecipeTypes.AiAssisted]: RecipeTypeNames.AiAssisted,
  [RecipeTypes.RapidModel]: RecipeTypeNames.RapidModel,
  [RecipeTypes.Template]: RecipeTypeNames.Template,
  [RecipeTypes.ApiConnector]: RecipeTypeNames.ApiConnector
};

export const RecipeTypesPathMapping = {
  [RecipeTypes.AiAssisted]: "add-code-recipe" as const,
  [RecipeTypes.Template]: "add-recipe" as const,
  [RecipeTypes.RapidModel]: "add-auto-ml-recipe" as const,
  [RecipeTypes.ApiConnector]: "api-connector-recipe" as const
};

export enum DatasetStatuses {
  UnBuilt = "UNBUILT",
  Pending = "PENDING",
  Empty = "EMPTY",
  Skipped = "SKIPPED",
  Built = "BUILT"
}

export enum RecipeStatuses {
  UnBuilt = "UNBUILT",
  Pending = "PENDING",
  Empty = "EMPTY",
  Running = "RUNNING",
  Success = "SUCCESS",
  Error = "ERROR",
  Skipped = "SKIPPED"
}

export enum ChartStatuses {
  UnBuilt = "UNBUILT",
  Pending = "PENDING",
  Empty = "EMPTY",
  Skipped = "SKIPPED",
  Built = "BUILT"
}

export enum ArtifactStatuses {
  UnBuilt = "UNBUILT",
  Empty = "EMPTY",
  Skipped = "SKIPPED",
  Built = "BUILT"
}

export enum ModelStatuses {
  UnBuilt = "UNBUILT",
  Empty = "EMPTY",
  Skipped = "SKIPPED",
  Built = "BUILT"
}

export const ConfirmRunWithReloadDatasetsPromptDetails = {
  title: "Confirm Run with Fresh Data",

  messageLine1: (
    <>
      If you proceed with this action, it will render the current runs of the associated recipe(s)
      with this input dataset invalid, marking them as{" "}
      <pre
        style={{
          display: "inline",
          margin: 0
        }}>
        UNBUILT
      </pre>{" "}
      and then re-run the recipe(s). Are you sure you want to proceed with this?"
    </>
  ),
  messageLine2: (
    <Box fontStyle="italic">
      Note: This action will overwrite the current dataset with latest data and schema from remote
      storage. Ensure that the schema in the remote storage matches that of the dataset; otherwise,
      linked recipes may encounter failures. Also the associated segment(if any) will be deleted,
      and any custom scenarios using it will default to the entire dataset instead of the segment.
    </Box>
  )
};

export enum ConfirmRunWithReloadDatasetsHelperText {
  CancelLabel = "No"
}
