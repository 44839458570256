import IconButton from "@material-ui/core/IconButton";
import React, { useMemo, useState } from "react";
import _, { capitalize } from "lodash";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useNavigate, useParams, generatePath } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import useDeleteModel from "src/hooks/useDeleteModel";
import ArtifactsTable from "./Artifacts/ArtifactsTable";
import CreateArtifact from "./CreateArtifact";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import ModelsTable from "./Models/ModelsTable";
import NoDataFoundDefault from "src/pages/common/NoDataFoundDefault";
import Search from "src/components/custom/Search/Search";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import styles from "./ArtifactsAndModels.module.scss";
import useDeleteArtifact from "src/hooks/useDeleteArtifact";
import { ArtifactDto } from "@rapidcanvas/rc-api-core";
import { PlusIcon } from "src/icons/NewUX/PlusIcon";
import { Tabs, Tab, TabPanel } from "src/components";
import { WebPaths } from "src/routing/routes";
import { handleResponse } from "src/utils/apiService";
import {
  QUERY_KEY_ARTIFACTS,
  QUERY_KEY_MODELS,
  useGetArtifacts,
  useGetModels
} from "src/hooks/api";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles({
  wrapperContainer: {
    height: "100%"
  }
});

const TABS = {
  artifacts: {
    key: "artifacts",
    singular: "artifact",
    label: "Artifacts"
  },
  models: {
    key: "models",
    singular: "model",
    label: "Models"
  }
};
const ArtifactsAndModels = () => {
  const { entityName } = useParams();
  const navigate = useNavigate();

  const classes = useStyles();
  const deleteArtifact = useDeleteArtifact();
  const deleteModel = useDeleteModel();
  const queryClient = useQueryClient();

  const isModelTab = entityName === TABS.models.key;
  const [value, setValue] = useState(isModelTab ? 2 : 1);
  const [targetName, setTargetName] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { selectedTab, tabName } = useMemo(() => {
    return {
      selectedTab: isModelTab ? TABS.models.key : TABS.artifacts.key,
      tabName: isModelTab ? TABS.models.singular : TABS.artifacts.singular
    };
  }, [isModelTab]);

  const {
    data: artifactsData,
    refetch: refetchArtifacts,
    isLoading: isArtifactsLoading
  } = useGetArtifacts({ keepPreviousData: true, refetchOnMount: true });

  const { data: modelsData, isLoading: isModelsLoading } = useGetModels(undefined, {
    keepPreviousData: true,
    refetchOnMount: true
  });

  const getUpdateValue = (artifact: ArtifactDto) =>
    artifact?.fileObjects?.reduce(
      (acc, item) => ((item?.updated as number) > acc! ? item?.updated : acc),
      artifact.created
    );

  const artifacts = useMemo(() => {
    const updated = _.map(artifactsData, (artifact) => ({
      ...artifact,
      updated: getUpdateValue(artifact)
    }));
    return _.filter(updated, ({ name }) => {
      return _.includes(_.toLower(name), _.toLower(searchValue));
    });
  }, [artifactsData, searchValue]);

  const models = useMemo(() => {
    return _.filter(
      modelsData,
      ({ name, predictionServiceDetails }) =>
        _.includes(_.toLower(name), _.toLower(searchValue)) ||
        _.includes(_.toLower(predictionServiceDetails?.displayName), _.toLower(searchValue))
    );
  }, [modelsData, searchValue]);

  const handleTabChange = (newValue: number) => {
    if (value === newValue) {
      return;
    }
    setValue(newValue);

    const tab = newValue === 1 ? TABS.artifacts.key : TABS.models.key;
    setSearchValue("");
    navigate(generatePath(WebPaths.ArtifactsAndModels, { entityName: tab }), { replace: true });
  };

  const handleSubmit = () => {
    selectedTab === TABS.artifacts.key ? handleArtifactDelete() : handleModelDelete();
  };

  const handleArtifactDelete = async () => {
    deleteArtifact.mutate(
      {
        name: targetName
      },
      {
        onSuccess: () => {
          queryClient.setQueryData([QUERY_KEY_ARTIFACTS], (prev: any) =>
            _.filter(prev, ({ name }) => name !== targetName)
          );
          handleResponse({
            successMessage: `Artifact - ${targetName} deleted successfully`
          });
          setTargetName("");
        }
      }
    );
  };

  const handleModelDelete = async () => {
    deleteModel.mutate(
      {
        name: targetName
      },
      {
        onSuccess: () => {
          queryClient.setQueryData([QUERY_KEY_MODELS], (prev: any) =>
            _.filter(prev, ({ name }) => name !== targetName)
          );
          handleResponse({ successMessage: `Model - ${targetName} deleted successfully` });
          setTargetName("");
        }
      }
    );
  };

  const handleOpenCreationModal = () => {
    setShowCreateModal(true);
  };

  const handleClose = () => {
    setShowCreateModal(false);
  };

  const handleCancelClose = () => {
    setTargetName("");
  };

  const handleDeleteProcessV2 = (id: string) => {
    setTargetName(id);
  };

  const handleSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
  };

  return (
    <Grid container className={classes.wrapperContainer}>
      {!!targetName && (
        <Modal
          isSubmitting={deleteArtifact.isLoading || deleteModel.isLoading}
          isSubmitDisabled={deleteArtifact.isLoading || deleteModel.isLoading}
          open={true}
          variant={ModalVariants.Delete}
          title={`Delete ${capitalize(tabName)}`}
          content={[
            `If you confirm, you will delete the ${tabName} completely`,
            `Do you really want to delete this ${tabName}?`,
            <Box fontStyle="italic" key="content">
              Note: Deleting this might impact associated DataApps (if any).
            </Box>
          ]}
          onClose={handleCancelClose}
          onSubmit={handleSubmit}
        />
      )}
      <SubTopNavBarWrapper
        subTopNavBarRightSection={{
          component: (
            <>
              <Search
                value={searchValue}
                placeholder={`Search ${selectedTab}`}
                onSearch={handleSearch}
              />
              {selectedTab === TABS.artifacts.key && (
                <IconButton color="primary" size="small" onClick={handleOpenCreationModal}>
                  <PlusIcon width={28} height={28} />
                </IconButton>
              )}
            </>
          )
        }}
      />

      <Grid item xs={12} className={styles.container}>
        {showCreateModal && (
          <CreateArtifact
            open={true}
            onClose={handleClose}
            artifactList={artifacts}
            toggleShouldArtifactsRefresh={() => refetchArtifacts()}
          />
        )}
        <div className={styles.tabsSection}>
          <div className={styles.tabsContainer}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              variant="scrollable">
              <Tab value={1} label={TABS.artifacts.label} />
              <Tab value={2} label={TABS.models.label} />
            </Tabs>
          </div>
          <div className={styles.tabPanelContainer}>
            <TabPanel value={value} index={1}>
              {isArtifactsLoading ? (
                <CommonLoader />
              ) : (
                <ArtifactsTable
                  data={artifacts}
                  onDelete={handleDeleteProcessV2}
                  openCreationModal={handleOpenCreationModal}>
                  {!_.isEmpty(artifactsData) ? (
                    <NoDataFoundDefault
                      title={`No artifact found with keyword "${searchValue}"`}
                      onClear={() => setSearchValue("")}
                    />
                  ) : null}
                </ArtifactsTable>
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {isModelsLoading ? (
                <CommonLoader />
              ) : (
                <ModelsTable data={models} onDelete={handleDeleteProcessV2}>
                  {_.isEmpty(models) ? (
                    <NoDataFoundDefault
                      title={
                        _.isEmpty(modelsData)
                          ? "No model found"
                          : `No model found with keyword "${searchValue}"`
                      }
                      subTitle={_.isEmpty(modelsData) ? "" : undefined}
                      onClear={() => setSearchValue("")}
                    />
                  ) : null}
                </ModelsTable>
              )}
            </TabPanel>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ArtifactsAndModels;
