import React from "react";

// Packages
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";

// Components
import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";

const FONT_SIZE = 14;

type Props = {
  project: $TSFixMe;
  isJobPath: boolean;
  jobData: $TSFixMe;
  jobRunId?: string;
  jobRunName?: string;
  isDefaultScenario: boolean;
  scenarioName?: string;
  recipeName?: string;
  updateRecipeName: $TSFixMeFunction;
  shouldResetRecipeName: number | boolean;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const {
    project,
    isJobPath,
    jobData,
    jobRunId,
    jobRunName,
    isDefaultScenario,
    scenarioName,
    recipeName,
    updateRecipeName,
    shouldResetRecipeName
  } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="project-name" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  if (isJobPath && !!project?.id) {
    breadcrumbs.push(
      <Link key="jobs" to={`/projects/${project?.id}/jobs`}>
        <RenderText>Schedulers</RenderText>
      </Link>
    );

    breadcrumbs.push(
      !!project?.id && !!jobData?.id ? (
        <Link key="jobs-name" to={`/projects/${project?.id}/jobs/${jobData?.id}`}>
          <RenderText>{jobData?.name || "Scheduler"}</RenderText>
        </Link>
      ) : (
        <RenderText key="jobs-name" color="textSecondary">
          {jobData?.name || "Scheduler"}
        </RenderText>
      )
    );

    breadcrumbs.push(
      project?.id && !!jobData?.id && !!jobRunId ? (
        <Link
          key="job-run-name"
          to={`/projects/${project?.id}/jobs/${jobData?.id}/job-runs/${jobRunId}/job-canvas`}>
          <RenderText>{`Run Name ${jobRunName || jobRunId || "Unknown"}`}</RenderText>
        </Link>
      ) : (
        <RenderText key="job-run-name" color="textSecondary">{`Run Name ${
          jobRunName || jobRunId || "Unknown"
        }`}</RenderText>
      )
    );

    breadcrumbs.push(
      <RenderText key="recipe-name" color="textSecondary">
        {recipeName}
      </RenderText>
    );
  } else {
    breadcrumbs.push(
      !isDefaultScenario ? (
        <RenderText key="scenario" color="textSecondary">
          {recipeName}
          {!!scenarioName && ` (${scenarioName})`}
        </RenderText>
      ) : (
        <EditName
          key="edit-name"
          inputValue={recipeName}
          fieldNameLabel="recipe name"
          onSave={updateRecipeName}
          isResetName={shouldResetRecipeName}
          containerProps={{
            style: {
              width: ((recipeName || "")?.length + 1) * (FONT_SIZE - 4) + 16
            }
          }}
        />
      )
    );
  }

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
