import React, { useState } from "react";
import { IconButton } from "@material-ui/core";

import CreateProject from "src/pages/Projects/CreateProject";
import DataAppProjectsModal from "./DataAppProjectsModal";
import { PlusIcon } from "src/icons/NewUX/PlusIcon";
import NewThemeWrapper from "src/styles/NewThemeWrapper";

const CreateDataAppButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [openCreateProject, setOpenCreateProject] = useState(false);

  const handleAddDataApp = () => {
    setOpen(true);
  };

  const handleCreateProject = () => {
    setOpen(false);
    setOpenCreateProject(true);
  };

  return (
    <>
      <IconButton
        color="primary"
        size="small"
        onClick={handleAddDataApp}
        data-testid="addDataAppIcon">
        <PlusIcon width={28} height={28} />
      </IconButton>
      {open && (
        <DataAppProjectsModal
          open={open}
          onClose={() => setOpen(false)}
          onOpenCreateProjects={handleCreateProject}
        />
      )}
      {openCreateProject && (
        <NewThemeWrapper>
          <CreateProject open={openCreateProject} onClose={() => setOpenCreateProject(false)} />
        </NewThemeWrapper>
      )}
    </>
  );
};

export default CreateDataAppButton;
