import React, { useCallback, useMemo } from "react";
import axios from "axios";
import _ from "lodash";
import { Box, Grid, IconButton, Tooltip, Typography, CircularProgress } from "@material-ui/core";

import { Download } from "src/icons/Download";
import { IModelReturn } from "src/hooks/usePredictionServiceByModel";
import { Table } from "src/components";
import { download } from "src/utils/fileUtils";
import { downloadModelSignedUrl } from "src/api";
import { useGetModelByName } from "src/hooks/api";
import CommonLoader from "src/components/CommonLoader";

type Props = {
  modelName: string;
  data?: IModelReturn;
  onModelDelete?: () => void;
  onModelDownload?: () => void;
  maxHeight?: string;
};

export const ModelTable = ({ data: initialData, modelName, onModelDownload, maxHeight }: Props) => {
  const [filesDownloading, setFilesDownloading] = React.useState<Array<string>>([]);

  const [data, setData] = React.useState<IModelReturn | undefined>(initialData);

  const { isLoading } = useGetModelByName(modelName, {
    enabled: !data,
    onSuccess: (response) => {
      setData(response);
    }
  });

  const onDownload = useCallback(
    async (path: string) => {
      setFilesDownloading((files) => [...files, path]);
      try {
        const signedUrl = await downloadModelSignedUrl(modelName, path);
        await axios
          .get(signedUrl?.signedUrl, {
            responseType: "arraybuffer",
            headers: { ...signedUrl.headers, "Access-Control-Allow-Origin": "*" }
          })
          .then((response) => {
            download(response.data, path, response.headers["content-type"]);
          });

        onModelDownload?.();
      } catch (error: $TSFixMe) {
      } finally {
        setFilesDownloading((files) => files?.filter((file) => file !== path));
      }
    },
    [modelName, onModelDownload]
  );

  const columns = useMemo(() => {
    return [
      {
        id: "File",
        accessor: "path",
        Header: "File",
        isSortable: true,
        Cell: ({ value }: { value: string }) => <Typography variant="body2">{value}</Typography>
      },
      {
        id: "Actions",
        accessor: "id",
        Header: "Actions",
        isSortable: false,
        Cell: ({ row }: $TSFixMe) => {
          const filePath = row?.original?.path;
          const isDownloadInProgress = !!filesDownloading.find((file) => file === filePath);

          return (
            <Grid container alignItems="center">
              <Tooltip title="Download File">
                {isDownloadInProgress ? (
                  <Box display="flex" px="12px">
                    <CircularProgress size="20px" />
                  </Box>
                ) : (
                  <IconButton
                    aria-label="download"
                    component="div"
                    onClick={() => onDownload(filePath)}>
                    <Download />
                  </IconButton>
                )}
              </Tooltip>
            </Grid>
          );
        }
      }
    ];
  }, [filesDownloading, onDownload]);

  return isLoading && _.isNil(data) ? (
    <CommonLoader />
  ) : (
    <Table
      {...(!!maxHeight
        ? {
            maxHeight
          }
        : {})}
      data={data?.files?.filter((file) => !!file)?.map((file) => ({ path: file })) || []}
      size="medium"
      columns={columns}
      emptyTableMessage="No files"
      skipPageReset
    />
  );
};
