import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import _, { isEmpty } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid, makeStyles, Typography, Box, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import LibraryAddCheckOutlinedIcon from "@material-ui/icons/LibraryAddCheckOutlined";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { Edit } from "@material-ui/icons";

import DataAppBrandingModal from "../Branding/DataAppBrandingModal";
import DataAppBrandingIcon from "src/icons/NewUX/DataAppBrandingIcon";
import DataAppConfigDrawer from "../common/DataAppConfigDrawer";
import DataAppLogsDrawer from "../DataAppLogs/DataAppLogsDrawer";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import api from "src/utils/AxiosClient";
import useCopyClipboard from "src/hooks/useCopyClipboard";
import useDataAppResourceUsage from "hooks/api/dataapps/useDataAppResourceUsage";
import useDataAppStatus from "hooks/api/dataapps/useDataAppStatus";
import useSaveDataAppMutation from "hooks/api/dataapps/useSaveDataAppMutation";
import { ConfigIcon } from "src/icons/ConfigIcon";
import {
  DataAppDtoAppTemplateTypeEnum,
  DataAppDtoDataAppTypeEnum,
  DataAppDtoLaunchStatusEnum
} from "@rapidcanvas/rc-api-core";
import { DataAppLaunchingView } from "./DataAppLaunchingView";
import { DataAppNotRunningView } from "./DataAppNotRunningView";
import { DataAppType } from "../DataApps.type";
import { DeleteDataAppModal } from "../DataAppsDashboard/DeleteDataAppModal";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { Spinner } from "src/components";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { deleteDisabled, deleteTooltip } from "../utils/DataApps.helpers";
import { handleResponse } from "src/utils/apiService";
import { openLogsModal } from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { useGetRole } from "src/hooks/useGetRole";
import { EditDataAppDrawer } from "../EditDataAppDrawer/EditDataAppDrawer";

const useStyles = makeStyles({
  dataAppWrapper: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    width: "100%",
    flexWrap: "nowrap"
  },
  headerWrapper: {
    height: "auto"
  },
  dataApp: {
    height: "100%",
    width: "100%"
  },
  alert: {
    borderRadius: "4px",
    padding: "0px 16px",
    alignItems: "center",
    height: "100%"
  },
  alertMessage: {
    whiteSpace: "pre",
    padding: "4px 0px"
  },
  btn: {
    "&:disabled": {
      opacity: 0.2
    }
  }
});

interface IProps {
  dataApp: DataAppType;
  dataAppDeepLink: string | null;
}

const DataAppDashboard: React.FC<IProps> = ({ dataApp, dataAppDeepLink }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  // Project context
  const { project } = useProjectContext() || {};

  const [searchParams, setSearchParams] = useSearchParams();
  const isOpenLogs = searchParams.get(openLogsModal);

  useEffect(() => {
    if (isOpenLogs) {
      setOpenLogs(true);
    }
  }, [isOpenLogs]);

  const iframeRef = useRef(null);
  const [shouldResetName, setShouldResetName] = React.useState<number | boolean>(false);
  const [isDeleteDataAppModalOpen, setIsDeleteDataAppModalOpen] = React.useState(false);
  const { isRoleYieldsDataAppView } = useGetRole();
  const token = api.getToken();

  const saveDataAppMutation = useSaveDataAppMutation();
  const [openLogs, setOpenLogs] = React.useState(false);
  const [isConfigOpen, setIsConfigOpen] = React.useState(false);
  const [openBranding, setOpenBranding] = React.useState(false);
  const [editing, setEditing] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);

  const { currentStatusInfo, status, loading } = useDataAppStatus(dataApp.id, dataApp.launchStatus);

  useDataAppResourceUsage(dataApp?.id, {
    enabled:
      !!dataApp?.id &&
      dataApp.launchStatus === DataAppDtoLaunchStatusEnum.Running &&
      dataApp.ttlInHrs !== 0
  });

  const accessUrl = dataApp?.accessUrl ? `${window.location.origin}${dataApp.accessUrl}` : null;
  const splitAccessUrl = dataApp?.accessUrl && accessUrl ? accessUrl?.split("?dlId=") : null;
  const accessUrlWithToken =
    dataApp?.accessUrl && splitAccessUrl
      ? `${splitAccessUrl[0]}?dlId=${splitAccessUrl[1]}&token=${token}`
      : null;

  const { handleCopyClick, isCopied, error } = useCopyClipboard(dataAppDeepLink);

  if (error) {
    handleResponse({ errorMessage: `Unable to copy Link` });
  }

  const openInNewTab = () => {
    accessUrlWithToken && window.open(accessUrlWithToken, "_blank");
  };

  const onDeleteSuccess = () => {
    navigate(-1);
  };

  const handleBrandingClick = () => {
    setOpenBranding(true);
  };

  useEffect(() => {
    const iframe: $TSFixMe = iframeRef.current;
    if (dataApp && accessUrlWithToken && status === DataAppDtoLaunchStatusEnum.Running && iframe) {
      iframe.src = accessUrlWithToken;
      iframe.onerror = () => {
        handleResponse({ errorMessage: "Error in loading dataApp" });
      };
    }
  }, [status]);

  const onUpdateName = (name: string) => {
    const cleanedName = name?.trim();
    dataApp &&
      saveDataAppMutation.mutate(
        {
          dataAppId: dataApp.id,
          dataApp: { ...dataApp, displayName: cleanedName }
        },
        {
          onSuccess: () => {
            handleResponse({ successMessage: `DataApp Name updated successfully` });
          },
          onError: () => {
            setShouldResetName(Date.now());
          }
        }
      );
  };

  const isCopyDisabled = useMemo(
    () => !dataAppDeepLink || !accessUrl || status !== DataAppDtoLaunchStatusEnum.Running,
    [accessUrl, dataAppDeepLink, status]
  );

  const isBrandingDisabled = useMemo(
    () =>
      dataApp.dataAppType === DataAppDtoDataAppTypeEnum.Custom ||
      dataApp.appTemplateType !== DataAppDtoAppTemplateTypeEnum.Reactjs ||
      status !== DataAppDtoLaunchStatusEnum.Running ||
      isRoleYieldsDataAppView,
    [dataApp.appTemplateType, status, dataApp.dataAppType]
  );

  const brandingTooltip = useMemo(() => {
    if (dataApp.appTemplateType !== DataAppDtoAppTemplateTypeEnum.Reactjs) {
      return "Branding is not available for streamlit DataApps";
    }

    if (dataApp.dataAppType === DataAppDtoDataAppTypeEnum.Custom) {
      return "Branding is not applicable for this DataApp";
    }

    if (status !== DataAppDtoLaunchStatusEnum.Running) {
      return "Ensure DataApp is running to enable this action";
    }

    if (isRoleYieldsDataAppView) {
      return "This action is disabled by admin";
    }
  }, [dataApp.appTemplateType, status, isRoleYieldsDataAppView, dataApp.dataAppType]);

  const isOpenInNewTabDisabled = useMemo(
    () => !accessUrl || status !== DataAppDtoLaunchStatusEnum.Running,
    [accessUrl, status]
  );

  const isNotRunning = useMemo(
    () =>
      _.includes(
        [
          DataAppDtoLaunchStatusEnum.Stopped,
          DataAppDtoLaunchStatusEnum.Unknown,
          DataAppDtoLaunchStatusEnum.Failure
        ],
        status
      ),
    [status]
  );

  return (
    <>
      {!isEmpty(currentStatusInfo) && isConfigOpen && (
        <DataAppConfigDrawer
          open={isConfigOpen}
          dataApp={dataApp}
          onClose={() => setIsConfigOpen(false)}
        />
      )}
      {editing && (
        <EditDataAppDrawer
          dataApp={dataApp}
          onClose={() => setEditing(false)}
          onReload={() => setIframeKey((prevKey) => prevKey + 1)}
        />
      )}
      <Grid container direction="column" className={clsx(classes.dataAppWrapper)}>
        <Grid className={classes.headerWrapper}>
          <SubTopNavBarWrapper
            subTopNavBarLeftSection={{
              component: (
                <SubTopNavBarBreadcrumbs
                  project={project}
                  dataAppName={dataApp?.displayName || dataApp?.name}
                  updateDataAppName={onUpdateName}
                  shouldResetDataAppName={shouldResetName}
                />
              )
            }}
            subTopNavBarRightSection={{
              moreOptions: [
                {
                  label: "Edit",
                  testId: "dataAppEdit",
                  disabled: false,
                  icon: <Edit fontSize="small" />,
                  action: () => setEditing(true)
                },
                {
                  label: "Config",
                  icon: <ConfigIcon />,
                  testId: "dataAppConfigIcon",
                  action: () => setIsConfigOpen(() => true)
                },
                {
                  label: "Branding",
                  testId: "dataAppBranding",
                  icon: <DataAppBrandingIcon />,
                  isDisabled: isBrandingDisabled,
                  tooltip: brandingTooltip,
                  action: handleBrandingClick
                },
                {
                  label: "Copy",
                  testId: "dataAppCopyIcon",
                  icon: isCopied ? (
                    <LibraryAddCheckOutlinedIcon fontSize="small" />
                  ) : (
                    <FileCopyOutlinedIcon fontSize="small" />
                  ),
                  tooltip:
                    status !== DataAppDtoLaunchStatusEnum.Running
                      ? "Ensure DataApp is running to enable this action"
                      : isCopyDisabled
                        ? ""
                        : "Click to copy URL to share",
                  isDisabled: isCopyDisabled,
                  action: handleCopyClick
                },
                {
                  label: "Open in New Tab",
                  testId: "dataAppOpenInNewTabBtn",
                  icon: <OpenInNewIcon fontSize="small" />,
                  tooltip:
                    status !== DataAppDtoLaunchStatusEnum.Running
                      ? "Ensure DataApp is running to enable this action"
                      : "",
                  isDisabled: isOpenInNewTabDisabled,
                  action: openInNewTab
                },

                {
                  label: "Logs",
                  testId: "dataAppLogsBtn",
                  icon: <LogsNewIconV2 />,
                  action: () => setOpenLogs(true)
                },
                {
                  label: "Delete",
                  testId: "dataAppDeleteBtn",
                  icon: (
                    <Box style={{ marginRight: 5 }}>
                      <TrashIcon viewBox="-3 0 20 20" />
                    </Box>
                  ),
                  tooltip: deleteTooltip(status),
                  isDisabled: deleteDisabled(status),
                  action: () => setIsDeleteDataAppModalOpen(true)
                }
              ],
              component: (
                <>
                  {currentStatusInfo && (
                    <Box py="6px">
                      <Alert
                        severity={currentStatusInfo.alertType}
                        className={classes.alert}
                        classes={{ message: classes.alertMessage }}
                        icon={false}
                        test-id="dataAppStatusAlert"
                        action={
                          currentStatusInfo?.buttonText && (
                            <Button
                              color="inherit"
                              size="small"
                              test-id="dataAppStatusUpdateBtn"
                              disabled={loading}
                              onClick={currentStatusInfo.onClick}
                              style={{ paddingTop: 0, paddingBottom: 0 }}>
                              {loading ? (
                                <Spinner size={18} noPadding />
                              ) : (
                                currentStatusInfo?.buttonText
                              )}
                            </Button>
                          )
                        }>
                        {currentStatusInfo.text}
                      </Alert>
                    </Box>
                  )}
                </>
              )
            }}
          />
        </Grid>
        <Grid item className={`${classes.dataAppWrapper} container-height`}>
          {isNotRunning || status === DataAppDtoLaunchStatusEnum.Deleting ? (
            <DataAppNotRunningView
              dataAppName={dataApp?.displayName || dataApp?.name}
              isLaunching={loading}
              stoppedBy={dataApp.stoppedBy}
              isDeleting={status === DataAppDtoLaunchStatusEnum.Deleting}
              onLaunch={() => currentStatusInfo?.onClick?.()}
            />
          ) : status === DataAppDtoLaunchStatusEnum.Launching ? (
            <DataAppLaunchingView />
          ) : accessUrlWithToken ? (
            <iframe
              key={iframeKey}
              title={dataApp.name}
              style={{
                // New UX change
                // Reducing 2% of height to accommodate other spacing or padding and avoid additional scrollbars.
                height: "98%",
                width: "100%",
                background: "white",
                border: "none",
                boxShadow: "none"
              }}
              src={accessUrlWithToken}
            />
          ) : (
            !accessUrlWithToken && (
              <Box ml="40px">
                <Typography color="primary" test-id="dataAppNotFoundText">
                  DataApp Not Found!
                </Typography>
              </Box>
            )
          )}
        </Grid>
        {dataApp && (
          <DeleteDataAppModal
            dataAppId={dataApp.id}
            dataAppName={dataApp.displayName || dataApp?.name}
            open={isDeleteDataAppModalOpen}
            onClose={() => setIsDeleteDataAppModalOpen(false)}
            onDeleteSuccess={onDeleteSuccess}
          />
        )}
        {openBranding && (
          <DataAppBrandingModal
            dataApp={dataApp}
            open={openBranding}
            onClose={() => setOpenBranding(false)}
            onSuccess={() => setIframeKey((prevKey) => prevKey + 1)}
          />
        )}
        {dataApp && openLogs && (
          <DataAppLogsDrawer
            open={openLogs}
            onClose={() => {
              setSearchParams({});
              setOpenLogs(false);
            }}
            dataApp={dataApp}
          />
        )}
      </Grid>
    </>
  );
};

export default DataAppDashboard;
