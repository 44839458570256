import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import MonacoEditor from "react-monaco-editor";
import { filter, includes, isEmpty, size, take } from "lodash";

import { Grid, Button, CircularProgress, Tooltip, Typography, makeStyles } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { toastWrapper } from "src/utils/toastWrapper";

import { capitalize } from "src/utils/capitalize";

import { useGetSchemaData, useGetSchemaOptions } from "src/hooks/api/projects/useEntityFeatures";
import { useEntityDataCustomColumns } from "src/hooks/api/projects/useEntityDataAndStats";

import {
  getSqlSampleDataWithRethrow,
  updateEntityWithRethrow,
  getDataSourcesSchemaWithRethrow
} from "src/api/projects";

import {
  DatasetSessionConfig,
  DatasetKeys,
  OntologyDatasetStatuses,
  DatasetHelperText,
  OntologyConfig
} from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";
import useDatasetsSession from "../../hooks/useDatasetsSession";
import useHelpers from "../../hooks/useHelpers";

import EditQueryContainer from "./EditQueryContainer";
import OntologyContainer from "./OntologyContainer/OntologyContainer";
import SqlConfiguration from "./SqlConfiguration";
import { useUploadContext } from "../../contexts/Upload/useUploadContext";
import DataFetchingContainer from "../DataFetchingContainer";
import useGetEntityData from "src/hooks/api/entities/useGetEntityData";

const useStyles = makeStyles((theme) => ({
  root: {
    rowGap: theme.spacing(2),
    padding: theme.spacing(2),
    overflow: "auto"
  },
  titleContainer: {
    gap: 10
  },
  editorQueryFieldsContainer: {
    "& > [class^='MuiGrid-root']": {
      width: "100%",
      rowGap: 16
    },
    "& .title": {
      marginBottom: theme.spacing(1)
    },
    "& .queryFieldInfo": {
      marginLeft: theme.spacing(1)
    }
  }
}));

const SqlInterface = () => {
  const { scenarioId } = useParams();

  const { createDataset, updateDatasetLite } = useUploadContext();

  const classes: $TSFixMe = useStyles();

  // Stores - STARTS >>
  const {
    datasetDatasetsStore,
    datasetIsFetchingOntologyDataStore,
    setDatasetIsFetchingOntologyDataStore,
    datasetIsOntologyStore,
    setDatasetIsOntologyStore,
    setDatasetIsFetchingOntologySchemaData
  } = useStoreSelectors();
  // << ENDS - Stores

  const { getDatasetDatasetsSession } = useDatasetsSession();

  const {
    source,
    updateDatasetsSession,
    removeDatasetOntologySchemaSession,
    isOntologyProcessing
  } = useHelpers({
    ontologyDatasetIndex: 0
  });

  // States - STARTS >>
  const [isFetchingSqlConfig, setIsFetchingSqlConfig] = useState<$TSFixMe>(false);

  const [editorQueryFields, setEditorQueryFields] = useState<$TSFixMe>({});
  const [values, setValues] = useState<$TSFixMe>({});

  const [ontologySampleData, setOntologySampleData] = useState<$TSFixMe>({});

  const [isFetchingOntologySampleData, setIsFetchingOntologySampleData] = useState<$TSFixMe>(false);

  const [isDatasetCreated, setIsDatasetCreated] = useState(false);
  // << ENDS - States

  // This is for dataset-fallback from session. Can be improved by removing it and depending only upon store.
  const getDatasetSession = useCallback(() => getDatasetDatasetsSession()?.[0] || {}, []);

  const dataset = useMemo(() => datasetDatasetsStore[0], [datasetDatasetsStore]);

  const { data: ontologySampleDataResponse } = useGetEntityData(
    dataset?.id || getDatasetSession()?.id,
    scenarioId,
    "",
    {
      enabled: !!dataset?.id || !!getDatasetSession()?.id
    }
  );

  const totalRowCount = useMemo(
    () => ontologySampleDataResponse?.entityDetails?.rows,
    [ontologySampleDataResponse]
  );

  // Query hooks - STARTS >>
  const { data: ontologySchemaOptionsData } = useGetSchemaOptions({
    entityId: dataset?.id || getDatasetSession()?.id,
    cacheTime: Infinity,
    enabled: !!datasetIsOntologyStore
  });

  const {
    isLoading: isLoadingOntologySchema,
    isRefetching: isFetchingOntologySchema,
    refetch: refetchOntologySchema,
    data: ontologySchemaData
  } = useGetSchemaData({
    entityId: dataset?.id || getDatasetSession()?.id,
    cacheTime: Infinity,
    enabled: !!datasetIsOntologyStore
  });

  const { mutateAsync: datasetDataCustomColumnsMutation } = useEntityDataCustomColumns({
    onMutate: () => setIsFetchingOntologySampleData(() => true),
    onSuccess: (datasetDataResponse) => {
      setOntologySampleData(() =>
        isEmpty(datasetDataResponse?.data) ? {} : datasetDataResponse?.data
      );
    },
    onSettled: () => setIsFetchingOntologySampleData(() => false)
  });

  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const getDatasetCustomColumnsData = async (columnNames: string[]) => {
    if (isEmpty(ontologySampleData)) {
      return;
    }

    setVisibleColumns(() => columnNames);

    const newColumns = filter(columnNames, (col) => !includes(ontologySampleData?.columns, col));

    if (isEmpty(newColumns)) {
      return;
    }

    const payload = {
      entityId: dataset?.id || getDatasetSession()?.id,
      payload: {
        scenarioId: scenarioId!,
        rowsStart: 0,
        rowsEnd: size(ontologySampleData?.rows),
        cols: columnNames
      }
    };

    await datasetDataCustomColumnsMutation(payload);
  };

  const refetchOntologySampleData = async () => {
    const payload = {
      entityId: dataset?.id || getDatasetSession()?.id,
      payload: {
        scenarioId: scenarioId!,
        rowsStart: 0,
        rowsEnd: size(ontologySampleData?.rows),
        cols: isEmpty(visibleColumns)
          ? take(
              ontologySchemaData?.map((item: $TSFixMe) => item?.name) || [],
              OntologyConfig.ColumnsLimit
            )
          : visibleColumns
      }
    };

    await datasetDataCustomColumnsMutation(payload);
  };

  useEffect(() => {
    if (!!datasetIsOntologyStore) {
      removeDatasetOntologySchemaSession();
    }
  }, [datasetIsOntologyStore]);

  useEffect(() => {
    setDatasetIsFetchingOntologyDataStore(isFetchingOntologySampleData);
  }, [isFetchingOntologySampleData]);

  useEffect(() => {
    setDatasetIsFetchingOntologySchemaData(isLoadingOntologySchema, isFetchingOntologySchema);
  }, [isLoadingOntologySchema, isFetchingOntologySchema]);
  // << ENDS - Query hooks

  // SQL configuration  - STARTS >>
  const formForm = async (thisDataSourceType: $TSFixMe) => {
    const dataSourceTypes = await getDataSourcesSchemaWithRethrow();

    const foundDataSourceType = dataSourceTypes?.find(
      (eachDataSourceType: $TSFixMe) =>
        eachDataSourceType?.type === thisDataSourceType?.dataSourceType
    );

    if (foundDataSourceType) {
      const allFields = [...foundDataSourceType?.datasetFields];

      const thisEditorQueryField: $TSFixMe = {};
      const formFormFields: $TSFixMe = {};

      allFields?.forEach((field: $TSFixMe) => {
        let formField = field;
        if (/query/i.test(field?.name)) {
          thisEditorQueryField[field?.name] = {
            ...field,
            value: ""
          };
        } else {
          if (thisDataSourceType[field?.name]) {
            formField.value = thisDataSourceType[field?.name];
          }

          if (thisDataSourceType?.options?.[field?.name]) {
            formField.value = thisDataSourceType?.options?.[field?.name];
          }

          formFormFields[field?.name] = { ...formFormFields[field?.name], ...formField };
        }
      });

      setEditorQueryFields(() => thisEditorQueryField);

      if (Object.keys(formFormFields)?.length > 0) {
        setValues(() => ({ ...formFormFields }));
      }
    }
  };

  // Test data source >>
  const getPayloadConfig = () => {
    let isValid = true;

    const requestJson: $TSFixMe = {
      id: source?.id,
      type: source?.dataSourceType,
      options: {}
    };

    if (Object.keys(editorQueryFields)?.length > 0) {
      Object.keys(editorQueryFields)?.forEach((fieldKey: $TSFixMe) => {
        const thisValue: $TSFixMe = (editorQueryFields?.[fieldKey]?.value || "")?.trim();

        if (editorQueryFields?.[fieldKey]?.required && !thisValue) {
          isValid = false;
          return;
        } else {
          if (thisValue) {
            requestJson.options[editorQueryFields?.[fieldKey]?.name] = thisValue;
          }
        }
      });
    }

    if (Object.keys(values)?.length > 0) {
      Object.keys(values)?.forEach((fieldKey: $TSFixMe) => {
        const thisValue: $TSFixMe = (values?.[fieldKey]?.value || "")?.trim();

        if (values?.[fieldKey]?.required && !thisValue) {
          isValid = false;
          return;
        } else {
          if (thisValue) {
            requestJson.options[values?.[fieldKey]?.name] = thisValue;
          }
        }
      });
    }

    return { isValid, requestJson };
  };

  // << Test data source

  useEffect(() => {
    const _ = async () => {
      setIsFetchingSqlConfig(() => true);
      // await testConnection();
      await formForm(source);
      setIsFetchingSqlConfig(() => false);
    };

    source?.id && _();
  }, []);

  const isInvalidConfig = useMemo(() => {
    const { isValid } = getPayloadConfig() || {};

    // Update datasets in session >>
    const fields: $TSFixMe = {};
    fields[DatasetKeys.OntologyConfig] = {};
    fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSqlConfigValid] = isValid;

    updateDatasetsSession({ index: 0, fields });
    // << Update datasets in session

    return !isValid;
  }, [source?.id, source?.dataSourceType, editorQueryFields, values]);
  // << ENDS - SQL configuration

  const manageDataset = async (requestJson: $TSFixMe) => {
    let fields: $TSFixMe = {};

    const isDatasetDirty = dataset?.[DatasetKeys.IsDirty];
    const isSqlFieldDirty = dataset?.[DatasetKeys.OntologyConfig][DatasetKeys.IsSqlFieldDirty];

    try {
      let thisRequestJson: $TSFixMe = {};

      if (dataset?.id) {
        thisRequestJson = {
          ...thisRequestJson,
          id: dataset?.id
        };

        // if (isDatasetDirty && dataset?.[DatasetKeys.Name]) {
        //   thisRequestJson = {
        //     ...thisRequestJson,
        //     displayName: dataset?.[DatasetKeys.Name]
        //   };
        // }

        if (isSqlFieldDirty) {
          thisRequestJson = {
            ...thisRequestJson,
            dataSourceId: requestJson?.id,
            dataSourceOptions: {
              ...requestJson.dsOptions
            }
          };
        }

        await updateEntityWithRethrow(thisRequestJson);

        // Update datasets in session >>
        if (isDatasetDirty) {
          fields[DatasetKeys.IsDirty] = false;
        }

        if (isSqlFieldDirty) {
          fields[DatasetKeys.OntologyConfig] = {};
          fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSqlFieldDirty] = false;
        }

        updateDatasetsSession({ index: 0, fields });
        // << Update datasets in session
      } else {
        if (dataset?.[DatasetKeys.Name]) {
          thisRequestJson = {
            ...thisRequestJson,
            name: dataset?.[DatasetKeys.Name],
            dataSourceId: requestJson?.id,
            dataSourceOptions: {
              ...requestJson.dsOptions
            }
          };
        }

        const datasetResponse = await createDataset(thisRequestJson);

        if (datasetResponse?.id) {
          setIsDatasetCreated(() => true);

          // Update datasets in session >>
          fields[DatasetKeys.Id] = datasetResponse?.id;
          fields[DatasetKeys.Name] = datasetResponse?.name;
          fields[DatasetKeys.IsDirty] = false;
          fields[DatasetKeys.OntologyConfig] = {};
          fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSqlFieldDirty] = false;
          fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Stage;

          updateDatasetsSession({ index: 0, fields });
          // << Update datasets in session
        }
      }
    } catch (error: $TSFixMe) {
      // Setting entity-details during error >>
      const errorEntries: $TSFixMe = error?.response?.data?.entries || [];
      if (errorEntries?.length > 0) {
        const errorEntry: $TSFixMe = errorEntries[0];
        if (errorEntry?.field === DatasetKeys.Id && !!errorEntry?.msg) {
          // Update datasets in session >>
          fields[DatasetKeys.Id] = errorEntry.msg;
          fields[DatasetKeys.IsDirty] = false;
          fields[DatasetKeys.OntologyConfig] = {};
          fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Stage;

          updateDatasetsSession({ index: 0, fields });
          // << Update datasets in session
        }
      }

      setDatasetIsFetchingOntologyDataStore(false);
      // << Setting entity-details during error

      throw error;
    }
  };

  const runQuery = async () => {
    setDatasetIsFetchingOntologyDataStore(true);

    try {
      const { isValid, requestJson } = getPayloadConfig() || {};

      if (isValid) {
        // await testConnection();

        const thisRequestJson: $TSFixMe = {
          id: source.id,
          type: source.dataSourceType,
          dsOptions: {
            ...requestJson.options
          }
        };

        const isSqlFieldDirty = dataset?.[DatasetKeys.OntologyConfig][DatasetKeys.IsSqlFieldDirty];

        if (isSqlFieldDirty) {
          setOntologySampleData(() => ({}));
        }

        await manageDataset(thisRequestJson);

        if (isSqlFieldDirty) {
          try {
            setIsFetchingOntologySampleData(() => true);

            const ontologySampleDataResponse = await getSqlSampleDataWithRethrow({
              data: thisRequestJson
            });

            if (ontologySampleDataResponse?.status === "SUCCESS") {
              if (Object.keys(ontologySampleDataResponse?.data || {})?.length > 0) {
                setOntologySampleData(() => ontologySampleDataResponse?.data);
              }
            } else {
              throw (
                Object.keys(ontologySampleDataResponse?.messages?.[0] || {})?.[0] ||
                "Something went wrong!"
              );
            }
          } catch (error: $TSFixMe) {
            toastWrapper({ type: "error", content: error });

            // Update datasets in session >>
            const fields: $TSFixMe = {};
            fields[DatasetKeys.OntologyConfig] = {};
            fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSqlFieldDirty] = true;

            updateDatasetsSession({ index: 0, fields });
            // << Update datasets in session

            setDatasetIsFetchingOntologyDataStore(false);
          } finally {
            setIsFetchingOntologySampleData(() => false);
          }
        }
      }
    } catch (error: $TSFixMe) {
      console.error(error);
    }

    const prevSqlConfigurationSession = {
      datasetName: dataset?.[DatasetKeys.Name],
      editorQueryFields,
      values
    };

    sessionStorage.setItem(
      DatasetSessionConfig.SqlConfigurationSessionKey,
      JSON.stringify(prevSqlConfigurationSession)
    );

    setDatasetIsFetchingOntologyDataStore(false);
  };

  useEffect(() => {
    const _ = async () => {
      // @TODO: To support selection overlay toggle for SQL ontology
      // setDatasetIsSelectionOverlayOpenStore(false);

      // Update datasets in session >>
      const fields: $TSFixMe = {};
      fields[DatasetKeys.OntologyConfig] = {};
      fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Active;

      updateDatasetsSession({ index: 0, fields });
      // << Update datasets in session
    };

    dataset?.id && datasetIsOntologyStore && _();
  }, [dataset?.id, datasetIsOntologyStore]);

  const isOntologyContainer = useMemo(() => {
    let isShow = false;

    isShow = isShow || Object.keys(ontologySampleData || {})?.length > 0;

    isShow =
      isShow ||
      (datasetIsOntologyStore &&
        (ontologySchemaOptionsData?.dataTypes || [])?.length > 0 &&
        // Hidden per RC-2508: Remove ontology columns
        // (ontologies || [])?.length > 0 &&
        (ontologySchemaData || [])?.length > 0);

    return isShow;
  }, [
    ontologySampleData,
    ontologySchemaOptionsData,
    // Hidden per RC-2508: Remove ontology columns
    // ontologies,
    ontologySchemaData,
    datasetIsOntologyStore
  ]);

  const onEditorQueryFieldChange = (value: $TSFixMe, fieldKey: string) => {
    const thisSqlEditorValue: $TSFixMe = editorQueryFields;
    thisSqlEditorValue[fieldKey].value = value;
    setEditorQueryFields(() => ({ ...thisSqlEditorValue }));

    let prevEditorQueryFields: $TSFixMe = {};
    const sqlConfigurationSession = sessionStorage.getItem(
      DatasetSessionConfig.SqlConfigurationSessionKey
    );

    if (sqlConfigurationSession) {
      prevEditorQueryFields = JSON.parse(sqlConfigurationSession)?.editorQueryFields || {};
    }

    // Update datasets in session >>
    const fields: $TSFixMe = {};
    fields[DatasetKeys.OntologyConfig] = {};
    fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSqlFieldDirty] =
      prevEditorQueryFields?.[fieldKey]?.value !== value;

    updateDatasetsSession({ index: 0, fields: fields });
    // << Update datasets in session
  };

  const isRunQueryDisabled = useMemo(() => {
    const datasetSession = getDatasetSession();

    let isDisabled = false;

    isDisabled = isDisabled || !source?.id;
    isDisabled = isDisabled || !source?.dataSourceType;

    isDisabled = isDisabled || isInvalidConfig;

    isDisabled = isDisabled || !dataset?.[DatasetKeys.Name];
    isDisabled =
      isDisabled || !(dataset?.[DatasetKeys.IsValid] || datasetSession?.[DatasetKeys.IsValid]);
    isDisabled =
      isDisabled ||
      dataset?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] ===
        OntologyDatasetStatuses.Deleting;

    isDisabled =
      isDisabled ||
      !(
        dataset?.[DatasetKeys.IsDirty] ||
        datasetSession?.[DatasetKeys.IsDirty] ||
        dataset?.[DatasetKeys.OntologyConfig][DatasetKeys.IsSqlFieldDirty]
      );

    isDisabled = isDisabled || datasetIsFetchingOntologyDataStore;

    return isDisabled;
  }, [
    source?.id,
    source?.dataSourceType,
    isInvalidConfig,
    getDatasetSession,
    dataset,
    datasetIsFetchingOntologyDataStore
  ]);

  const isSqlEditorDisabled = useMemo(() => {
    let isDisabled = false;

    isDisabled =
      isDisabled ||
      dataset?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] ===
        OntologyDatasetStatuses.Deleting;

    isDisabled = isDisabled || datasetIsFetchingOntologyDataStore;

    return isDisabled;
  }, [dataset, datasetIsFetchingOntologyDataStore]);

  const editorQueryFieldLabel = (fieldKey: string) => {
    if (!editorQueryFields[fieldKey]?.name) {
      return DatasetHelperText.Unknown;
    }

    let thisEditorQueryFieldLabel = capitalize(editorQueryFields[fieldKey]?.name);

    if (editorQueryFields[fieldKey]?.required) {
      thisEditorQueryFieldLabel = `${thisEditorQueryFieldLabel} *`;
    }

    return thisEditorQueryFieldLabel;
  };

  useEffect(
    () => () => {
      sessionStorage.removeItem(DatasetSessionConfig.SqlConfigurationSessionKey);
    },
    []
  );

  return (
    <Grid container direction="column" className={classes.root}>
      {!datasetIsOntologyStore ? (
        <>
          <Grid container direction="row" style={{ rowGap: 16 }}>
            <Grid item>
              <SqlConfiguration
                getDatasetSession={getDatasetSession}
                updateDatasetsSession={updateDatasetsSession}
                isFetchingSqlConfig={isFetchingSqlConfig}
                values={values}
                setValues={setValues}
                datasetIsFetchingOntologyDataStore={datasetIsFetchingOntologyDataStore}
                isDatasetCreated={isDatasetCreated}
              />
            </Grid>
            {isFetchingSqlConfig ? (
              <>
                <Grid container direction="row">
                  <CircularProgress size={23} color="primary" />
                </Grid>
              </>
            ) : (
              Object.keys(editorQueryFields)?.length > 0 && (
                <>
                  <Grid container direction="row" className={classes.editorQueryFieldsContainer}>
                    {(Object.keys(editorQueryFields) || [])?.map(
                      (fieldKey: $TSFixMe, fieldIndex: number) => {
                        return (
                          <>
                            <Grid item>
                              <Typography
                                key={`sqlConfigurationEditorQueryField_${fieldIndex}`}
                                id="sqlConfigurationEditorQueryFieldLabel"
                                variant="body2"
                                color="textSecondary"
                                className="title">
                                {editorQueryFieldLabel(fieldKey)}
                                {editorQueryFields[fieldKey]?.name?.trim()?.toLowerCase() ===
                                  "query" && (
                                  <Tooltip
                                    id="sqlConfigurationEditorQueryFieldInfoTooltip"
                                    title={
                                      DatasetHelperText.SqlConfigurationEditorQueryFieldInfo || ""
                                    }
                                    arrow>
                                    <InfoOutlinedIcon className="queryFieldInfo" fontSize="small" />
                                  </Tooltip>
                                )}
                              </Typography>
                              <MonacoEditor
                                language="sql"
                                height="200px"
                                width="100%"
                                theme="vs-dark"
                                value={editorQueryFields[fieldKey]?.value || ""}
                                onChange={(event: $TSFixMe) =>
                                  onEditorQueryFieldChange(event, fieldKey)
                                }
                                options={{
                                  readOnly: isSqlEditorDisabled,
                                  fontSize: 12,
                                  minimap: { enabled: false },
                                  renderLineHighlight: "none",
                                  lineNumbers: "off",
                                  scrollBeyondLastLine: false,
                                  padding: {
                                    top: 12
                                  }
                                }}
                              />
                            </Grid>
                          </>
                        );
                      }
                    )}
                  </Grid>
                </>
              )
            )}
            <Grid container justifyContent="flex-end">
              <Button
                id="sqlConfigurationRunQuery"
                variant="contained"
                color="primary"
                disabled={isRunQueryDisabled}
                onClick={() => runQuery()}>
                {datasetIsFetchingOntologyDataStore ? (
                  <CircularProgress size={23} color="primary" />
                ) : (
                  DatasetHelperText.RunQuery
                )}
              </Button>
            </Grid>
            {datasetIsFetchingOntologyDataStore && <DataFetchingContainer />}
          </Grid>
        </>
      ) : (
        <EditQueryContainer
          onEditQuery={() => {
            setDatasetIsOntologyStore(false);
          }}
          isOntologyProcessing={isOntologyProcessing}
        />
      )}

      {isOntologyContainer && (
        <OntologyContainer
          totalRowCount={totalRowCount}
          datasetIsOntologyStore={datasetIsOntologyStore}
          dataset={dataset}
          isOntologyProcessing={isOntologyProcessing}
          ontologySampleData={ontologySampleData}
          ontologySchemaOptionsData={ontologySchemaOptionsData}
          ontologySchemaData={ontologySchemaData}
          isFetchingOntologySchema={isLoadingOntologySchema || isFetchingOntologySchema}
          refetchOntologySchema={refetchOntologySchema}
          isFetchingOntologySampleData={isFetchingOntologySampleData}
          setIsFetchingOntologySampleData={setIsFetchingOntologySampleData}
          refetchOntologySampleData={refetchOntologySampleData}
          updateDatasetLite={updateDatasetLite}
          getDatasetCustomColumnsData={getDatasetCustomColumnsData}
          visibleColumns={visibleColumns}
        />
      )}
    </Grid>
  );
};

export default SqlInterface;
