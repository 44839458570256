import React, { ReactNode, useEffect, useState } from "react";

// Packages
import { isEmpty, map, toNumber } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

// Hooks
import { IEntity } from "src/hooks/api/entities/useEntities";
import { useGetDataSources } from "src/hooks/api";
import useConnectorsMap from "src/hooks/useConnectorsMap";
import useAppendFileName from "src/hooks/api/entities/useAppendFileName";

// Stores
import { useDataSourcesStore } from "src/store/store";
import { dataSourcesGetter, dataSourcesSetter } from "src/store/store.selectors";

// Components
import Table from "./Table";
import SqlInterface from "./SqlInterface";

// Styles
import useStyles from "./Source.styles";
import AuditHistory from "./AuditHistory";
import { Typography } from "@material-ui/core";
import FileName from "./FileName";
import { useLocation } from "react-router-dom";

type Props = {
  userId: string | null | undefined;
  isJobPath: boolean;
  isDefaultScenario: boolean;
  dataset: IEntity | null | undefined;
  isLoading: boolean;
};

export enum MODE {
  EDIT = "edit",
  AUDIT_HISTORY = "audit-history"
}

const Source = (props: Props) => {
  const { userId, isJobPath, dataset, isLoading, isDefaultScenario } = props || {};

  const classes = useStyles();
  const { state } = useLocation();

  const dataSourcesStore = useDataSourcesStore(dataSourcesGetter);
  const setDataSourcesStore = useDataSourcesStore(dataSourcesSetter);

  const [mode, setMode] = useState<MODE>();
  const [fileNames, setFileNames] = useState<ReactNode>("");

  useEffect(() => {
    const showAuditHistory = state?.showAuditHistory;
    if (showAuditHistory) {
      setMode(MODE.AUDIT_HISTORY);
    }
  }, []);

  const { isLoading: isFetchingGetConnectors } = useGetDataSources({
    cacheTime: Infinity,
    enabled: isEmpty(dataSourcesStore),
    onSuccess: (dataSourcesData) => setDataSourcesStore(dataSourcesData)
  });

  useAppendFileName(dataset?.id, {
    enabled: !!dataset?.id && !dataset?.dataSourceId,
    refetchOnMount: true,
    onSuccess: (data) => {
      setFileNames(
        <>
          {map(data, (item, index) => (
            <>
              <FileName item={item} />
              {toNumber(index) < data?.length - 1 && ", "}
            </>
          ))}
        </>
      );
    }
  });

  const connectorsMap = useConnectorsMap();

  return isLoading || isFetchingGetConnectors ? (
    <Box p={4}>
      <Grid container>
        <CircularProgress color="secondary" style={{ margin: "auto" }} />
      </Grid>
    </Box>
  ) : (
    <Grid container direction="column" wrap="nowrap" className={classes.root}>
      {!isEmpty(dataset) && (
        <Grid item>
          <Table
            isJobPath={isJobPath}
            isDefaultScenario={isDefaultScenario}
            dataset={dataset}
            fileNames={fileNames}
            connectorsMap={connectorsMap}
            mode={mode}
            setMode={setMode}
          />
        </Grid>
      )}
      {mode == MODE.EDIT && (
        <Grid item>
          <SqlInterface
            userId={userId}
            isDefaultScenario={isDefaultScenario}
            isJobPath={isJobPath}
            dataset={dataset}
          />
        </Grid>
      )}
      {mode == MODE.AUDIT_HISTORY && dataset && (
        <>
          <Grid item>
            <Typography>Audit History</Typography>
          </Grid>
          <Grid item>
            <AuditHistory
              datasetId={dataset.id}
              dataSourceId={dataset.dataSourceId}
              connectorsMap={connectorsMap}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Source;
