/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useMemo } from "react";
import StarIcon from "@material-ui/icons/Star";
import styles from "./TableHeader.module.scss";
import { Box, Checkbox, TableCell, TableSortLabel } from "@material-ui/core";
import { MoreOptions } from "../../MoreOptions/MoreOptions";
import Select from "src/components/Select/Select";
import OverflowTooltip from "src/components/OverflowTooltip";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";

const EditableSelectField = ({ column, updateMyData }: $TSFixMe) => {
  const {
    id: columnId,
    values,
    Header,
    hideSelectInputLabel = false,
    selectVariant = "filled",
    selectStyles = {},
    meta: initialValue
  } = column;

  const [value, setValue] = React.useState(initialValue);

  const onChange = (event: any) => {
    const updatedData = event?.target?.value;
    setValue(updatedData);
    updateMyData?.({ columnId, value: updatedData });
  };

  return (
    <Select
      id={`schemaDropDown-${uuidv4()}`}
      variant={selectVariant}
      size="small"
      hideInputLabel={hideSelectInputLabel}
      required
      fullWidth
      values={values || []}
      label={Header}
      value={value}
      onChange={onChange}
      fixedMenuHeight={true}
      className={selectStyles}
    />
  );
};

export const TableHeader = ({
  column,
  className,
  index,
  size,
  orderBy,
  headerSortActive,
  sortInverted = false,
  isLoading,
  columnOptionsCustomRender: ColumnOptionsCustomRender,
  updateMyData
}: $TSFixMe) => {
  const [sortState, setSortState] = useState("desc");
  const [checkboxState, setCheckboxState] = useState(false);

  const handleSort = useCallback(() => {
    // $FixMe, $ToBeRemoved
    // The Header with value "Actions" is only considered to be sort-disabled.
    // This is a limitation for data-upload > connector-files table,
    // where, the value of Header is a check-box & below condition doesn't support in the case.
    // Hence, adding condition for id here.
    // This is need to better configured
    if (column.Header === "Actions" || column?.id === "checkAll" || !column.canSort) return;
    if (sortState === "desc") {
      setSortState("asc");
      column.toggleSortBy(false, false);
    }
    if (sortState === "asc") {
      setSortState("desc");
      column.toggleSortBy(true, false);
    }
    headerSortActive(column.id);
  }, [column, sortState]);

  const isOrderByColumnId = useMemo(() => orderBy === column.id, [orderBy]);
  /* $FixMe: Cleanup to use initial sortstate & sortorder returned by react table.
  Current Flow: TableComponent -> Table Header. To fix Prop flow: TableComponent User -> React Table -> Table Header */
  React.useEffect(() => {
    if (sortInverted && isOrderByColumnId) {
      column.toggleSortBy(true, false);
      setSortState("desc");
    }
  }, [column, isOrderByColumnId, sortInverted]);

  const onSelectAllClick = () => {
    setCheckboxState(!checkboxState);
    column?.selectColumn(column.id);
  };

  const columnNotSortable = useMemo(() => {
    // $FixMe, $ToBeRemoved
    // The Header with value "Actions" is only considered to be sort-disabled.
    // This is a limitation for data-upload > connector-files table,
    // where, the value of Header is a check-box & below condition doesn't support in the case.
    // Hence, adding condition for id here.
    // This is need to better configured
    if (column.Header === "Actions" || column?.id === "checkAll") return true;
    if (column.isSortable || column.toggleSortBy) return false;
    return true;
  }, []);

  return (
    <TableCell
      {...column.getHeaderProps()}
      {...(!!column?.meta
        ? {
            className: `${styles.schemaHeader} ${index === 0 ? styles.schemaFirstColumn : ""}`
          }
        : {
            className: `${isOrderByColumnId ? styles.highlight : ""} ${className}`
          })}
      size={size}
      {...column.getHeaderProps({
        style: {
          position: "sticky",
          minWidth: column.width,
          width: column.width,
          maxWidth: column.width
        }
      })}>
      <div
        className={`${styles.headerContainer}  ${column?.meta ? styles.schemaHeaderContainer : ""}`}>
        {column.isFavorite && <StarIcon />}
        {column.checkboxSelection && (
          <Checkbox checked={checkboxState} onChange={onSelectAllClick} />
        )}
        {column.icon && column.icon}
        {column.id === "selection" ? (
          column.render("Header")
        ) : (
          <TableSortLabel
            active={isOrderByColumnId}
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            direction={sortState}
            onClick={handleSort}
            hideSortIcon={columnNotSortable}
            // Todo: decouple child svg styles here as any icon inside column is inheriting these styles.
            className={clsx([styles.sortLabel, column.isSortable ? styles.sortableArrows : null])}>
            <OverflowTooltip value={column.render("Header")} />
          </TableSortLabel>
        )}
        {(ColumnOptionsCustomRender || !!column.options?.length) && !isLoading && (
          <MoreOptions
            options={column.options}
            menuProps={{
              MenuListProps: {
                disablePadding: true
              }
            }}
            customMenuRender={<ColumnOptionsCustomRender columnId={column.id} name={column.name} />}
          />
        )}
      </div>
      {!!column?.meta && (
        <div className={styles.schemaControlContainer}>
          {column?.isEditable ? (
            <EditableSelectField column={column} updateMyData={updateMyData} />
          ) : (
            <Box className={column.selectStyles}>{column?.meta}</Box>
          )}
        </div>
      )}
      <div
        {...column.getResizerProps()}
        className={`${styles.resizer} ${column.isResizing ? styles.isResizing : ""} ${column?.meta ? styles.schemaHeaderResizer : ""}`}
      />
    </TableCell>
  );
};

TableHeader.defaultProps = {
  size: "large"
};
