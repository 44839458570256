import React from "react";

// Packages
import { map } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Components
import EnvironmentCard from "./EnvironmentCard/EnvironmentCard";

// Constants
import { EnvironmentsConfig } from "../utils/Environments.constants";

interface IProps {
  environments: EnvDto[];
  onEdit: (envId?: string) => void;
  onConfig: (env?: EnvDto) => void;
  onLogs: (env?: EnvDto) => void;
  onDelete: (env?: EnvDto) => void;
}

const EnvironmentCards: React.FC<IProps> = (props) => {
  const { environments, onEdit, onConfig, onLogs, onDelete } = props;

  const theme = useTheme();

  const availableWindowSize = window.innerWidth * (1 - EnvironmentsConfig.ExemptingWidth);

  return (
    <Grid
      container
      justifyContent="flex-start"
      style={{ gap: theme.spacing(3), width: availableWindowSize }}>
      {map(environments, (environment: EnvDto) => (
        <EnvironmentCard
          environment={environment}
          onEdit={onEdit}
          onConfig={onConfig}
          onLogs={onLogs}
          onDelete={onDelete}
        />
      ))}
    </Grid>
  );
};

export default EnvironmentCards;
