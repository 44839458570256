import React, { useMemo, PropsWithChildren } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { UseTableRowProps } from "react-table";

import DefaultButton from "components/Buttons/DefaultButton";
import Text from "components/Widget/Text";
import { Delete } from "src/icons/Delete";
import { LinkCellWithMenu } from "components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { TABS } from "./ModelDetails";
import { Table } from "src/components";
import { dateFormat } from "src/utils/dateFormat";
import { getSourceString, getSourceVal } from "../Artifacts/ArtifactsTable";
import { useParams } from "react-router";
import { generatePath } from "react-router";
import { WebPaths } from "src/routing/routes";

interface IProps {
  data: any[];
  onDelete: (name: string) => void;
}

const ModelsTable: React.FC<PropsWithChildren<IProps>> = ({ data, children, onDelete }) => {
  const { projectId } = useParams();
  const sourceSortType = useMemo(
    () => (rowA: UseTableRowProps<any>, rowB: UseTableRowProps<any>) => {
      const valA = getSourceString(_.get(rowA.original, "producer"));
      const valB = getSourceString(_.get(rowB.original, "producer"));

      if (_.lowerCase(valA) > _.lowerCase(valB)) {
        return 1;
      }
      if (_.lowerCase(valA) < _.lowerCase(valB)) {
        return -1;
      }

      return 0;
    },
    []
  );

  const psSortType = useMemo(
    () => (rowA: UseTableRowProps<any>, rowB: UseTableRowProps<any>) => {
      const valA = _.get(rowA.original, "predictionServiceDetails.name", "Add");
      const valB = _.get(rowB.original, "predictionServiceDetails.name", "Add");

      if (_.lowerCase(valA) > _.lowerCase(valB)) {
        return 1;
      }
      if (_.lowerCase(valA) < _.lowerCase(valB)) {
        return -1;
      }

      return 0;
    },
    []
  );

  const columns = [
    {
      id: "Name",
      accessor: "name",
      Header: "Name",
      isSortable: true,
      isTooltip: false,
      Cell: ({ row }: $TSFixMe) =>
        !row?.original?.producer && !projectId ? (
          <LinkCellWithMenu
            url={
              !!projectId
                ? generatePath(WebPaths.ProjectModelsDetails, {
                    projectId,
                    modelName: row.original?.name
                  })
                : `/artifacts-and-models/model/${row.original?.name}`
            }
            linkText={<Text value={row.original.name} />}
            menuButtons={[
              {
                label: "DELETE",
                icon: <Delete />,
                action: () => onDelete(row.original?.name)
              }
            ]}
          />
        ) : (
          <Link
            className="name-link"
            to={
              !!projectId
                ? generatePath(WebPaths.ProjectModelsDetails, {
                    projectId,
                    modelName: row.original?.name
                  })
                : `/artifacts-and-models/model/${row.original?.name}`
            }>
            {row.original?.name}
          </Link>
        )
    },
    {
      id: "Created",
      accessor: "created",
      Header: "Created",
      isSortable: true,
      Cell: ({ value }: $TSFixMe) =>
        // Created date is in epoch milliseconds
        dateFormat(value)
    },
    {
      id: "Prediction Service",
      accessor: "predictionServiceDetails",
      Header: "Prediction Service",
      isSortable: true,
      sortType: psSortType,
      Cell: ({ row, value }: any) => {
        return (
          <Link
            className="name-link"
            to={
              !!projectId
                ? generatePath(WebPaths.ProjectModelsDetails, {
                    projectId,
                    modelName: row.original?.name
                  })
                : `/artifacts-and-models/model/${row.original?.name}`
            }
            state={{ tab: TABS.predictionService.value, predictionServiceDetails: value }}>
            {value ? value.name : <DefaultButton>+ Add</DefaultButton>}
          </Link>
        );
      }
    },
    {
      id: "Source",
      accessor: "producer",
      Header: "Source",
      isSortable: true,
      sortType: sourceSortType,
      Cell: ({ value }: $TSFixMe) => getSourceVal(value)
    }
  ];

  if (children) {
    return <>{children}</>;
  }

  return (
    <Table
      data={data}
      size="small"
      columns={columns}
      isCellSortEnabled
      orderByDefault="Created"
      sortInverted
      emptyTableMessage="No models"
      skipPageReset
      maxHeight="calc(100vh - 248px)"
      isTheadSticky
    />
  );
};

export default ModelsTable;
