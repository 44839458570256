import React, { ReactElement, useRef, useState } from "react";

// Packages
import {
  ColumnDefResolved,
  ColumnDefTemplate,
  HeaderContext,
  VisibilityState
} from "@tanstack/react-table";
import { Virtualizer } from "@tanstack/react-virtual";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MuiTable from "@material-ui/core/Table";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

// Hooks
import useReactTable from "./hooks/useReactTable";
import useVirtualizer from "./hooks/useVirtualizer";
import useServerSideRendering from "./hooks/useServerSideRendering";
import useActions from "./hooks/useActions";

// Components
import Loader from "./components/Loader";
import SearchTable from "./components/Settings/SearchTable";
import SettingsIcon from "./components/Settings/Settings";
import TableBody from "./components/TableBody/TableBody";
import TableHead from "./components/TableHead/TableHead";

// Contexts
import TableContextProvider from "./context/TableContextProvider";

// Types
import {
  ServerSideRenderingProps,
  StoreUserPreferences,
  TData,
  TableSettingsProps
} from "./Table.types";

// Styles
import useStyles from "./Table.styles";

type Props = {
  data: TData[];
  columns: ColumnDefResolved<TData, any>[];
  size?: "small" | "medium";
  maxHeight?: string;
  isStickyHeader?: true | false | undefined;
  sortBy?: string;
  columnVisibility?: VisibilityState;
  columnOrder: string[];
  schemaComponent?: ReactElement;
  tableSettingsProps?: TableSettingsProps;
  serverSideRenderingProps?: ServerSideRenderingProps;
  toggleColumns: (columns: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]) => void;
  storeUserPreferences?: ({ columnVisibility, columnOrder }: StoreUserPreferences) => void;
};

const Table = (props: Props) => {
  const {
    data,
    columns: inputColumns,
    size,
    maxHeight,
    isStickyHeader = false,
    sortBy,
    columnVisibility,
    toggleColumns,
    tableSettingsProps,
    serverSideRenderingProps,
    storeUserPreferences,
    columnOrder,
    schemaComponent
  } = props || {};

  const classes = useStyles({ maxHeight });

  const [columns, setColumns] = useState(inputColumns || []);

  // The virtualizer needs to know the scrollable container element
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { onTableContainerScrolled } = useServerSideRendering({
    tableContainerRef,
    serverSideRenderingProps
  });

  const table = useReactTable({ columns, data, sortBy, columnVisibility });

  const { rows } = table?.getRowModel();

  const rowVirtualizer: Virtualizer<HTMLDivElement, Element> = useVirtualizer({
    rows,
    tableContainerRef
  });

  const resetColumns = (newColumns: ColumnDefResolved<TData, any>[]) => {
    // Reset resized column widths internally
    table.resetColumnSizing();
    setColumns(() => newColumns);
  };

  const { resizeColumns, isAllColumnsResized } = useActions({
    columns: inputColumns,
    resetColumns
  });

  return (
    <TableContextProvider
      table={table}
      columnOrder={columnOrder}
      toggleColumns={toggleColumns}
      resizeColumns={resizeColumns}
      storeUserPreferences={storeUserPreferences}>
      <Box className={classes.wrapper}>
        <Grid container className={classes.actionsContainer}>
          {!!tableSettingsProps?.resizeColumns && !isAllColumnsResized && (
            <Grid item>
              <Button variant="outlined" color="primary" size="small" onClick={resizeColumns}>
                Resize Columns
              </Button>
            </Grid>
          )}
          <Grid item>{schemaComponent}</Grid>
          <Grid item>
            <SearchTable />
          </Grid>
        </Grid>

        <SettingsIcon
          columns={columns}
          columnVisibility={columnVisibility}
          toggleColumns={toggleColumns}
          tableSettingsProps={tableSettingsProps}
          disabledApplyActionMessage={
            !!serverSideRenderingProps?.isLoading ||
            !!serverSideRenderingProps?.isFetchingSingleColumn
              ? "Please wait. Data is being fetched."
              : ""
          }
        />

        {!table?.getIsSomeColumnsVisible() ? (
          <Box p={4}>
            <Grid container alignItems="center" justifyContent="center">
              <Alert severity="info" style={{ width: "25%", justifyContent: "center" }}>
                No column selected!
              </Alert>
            </Grid>
          </Box>
        ) : (
          <Paper
            elevation={0}
            className={classes.root}
            ref={tableContainerRef}
            {...(!!serverSideRenderingProps ? { onScroll: onTableContainerScrolled } : {})}>
            <MuiTable className={classes.table} size={size}>
              <TableHead rowVirtualizer={rowVirtualizer} isStickyHeader={isStickyHeader} />
              <TableBody rowVirtualizer={rowVirtualizer} rows={rows} />
            </MuiTable>
            {!!serverSideRenderingProps?.isFetching && <Loader />}
          </Paper>
        )}
      </Box>
    </TableContextProvider>
  );
};

export default Table;
