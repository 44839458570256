import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getModelsByName } from "src/api";
import { IModelReturn } from "src/hooks/usePredictionServiceByModel";

export const useGetModelByName = (modelName: string, options?: UseQueryOptions<IModelReturn>) => {
  return useQuery<IModelReturn>({
    queryKey: ["models", modelName],
    queryFn: async () => {
      return await getModelsByName(modelName);
    },
    enabled: !!modelName,
    refetchOnMount: true,
    ...options
  });
};
