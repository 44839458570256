import { addTransformGroupWithRethrow } from "src/api/transforms";
import { RecipeTypes } from "pages/private/ProjectsModule/utils/Projects.constants";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { Entities, Entity, EntityTypeEnum, Recipe } from "src/types";
import { getAPIWithRethrow } from "src/utils/apiService";
import { extractNewRecipeName } from "src/pages/Projects/common/Recipe.utils";
import _, { map } from "lodash";

interface Output {
  group: Recipe;
}

export interface IParent extends Entity {
  type: EntityTypeEnum;
}
interface IProps {
  projectId?: string;
  inputDatasets: Entities;
  options?: Partial<UseQueryOptions<Output>>;
  entity?: IParent[];
}

const useCreateApiConnectorRecipe = ({ projectId, entity, options = {} }: IProps) => {
  return useQuery<Output>({
    queryKey: ["project", projectId, "allApiConnectorRecipes"],
    queryFn: async () => {
      if (!projectId) {
        throw "Project id is absent";
      }

      const groups: Array<Recipe> = await getAPIWithRethrow(
        `/v2/dfs-run-config-groups?projectId=${projectId}`
      );
      const updatedRecipeName = extractNewRecipeName({
        name: _.first(entity)?.name as string,
        groups
      });

      const transformGroupPayload = {
        name: updatedRecipeName,
        displayName: updatedRecipeName,
        parents: entity ? map(entity, (item) => ({ id: item.id, type: item.type })) : [],
        recipeType: RecipeTypes.ApiConnector
      };
      const createdGroup = await addTransformGroupWithRethrow(transformGroupPayload, projectId);
      const group = Array.isArray(createdGroup) ? createdGroup[0] : createdGroup;

      return { group };
    },
    cacheTime: 0,
    ...options
  });
};

export default useCreateApiConnectorRecipe;
