import React, { useMemo } from "react";
import _, { includes } from "lodash";
import { CircularProgress } from "@material-ui/core";

import styles from "../../Environments.module.scss";
import { Environment } from "../../Environments";
import { EnvironmentStatuses } from "src/constants/environments.constants";
import { capitalize } from "src/utils/capitalize";

type Props = {
  env: Environment | undefined;
  onRelaunchEnv?: Function;
  stoppingEnvironments: $TSFixMe;
  onStopEnv?: Function;
  subtopBarStyles?: boolean;
};

export const statusValues: $TSFixMe = {
  shutdown: { text: "Shutdown", buttonText: "START" },
  launching: { text: "Launching...", buttonText: "CHECK LOG" },
  stopping: { text: "Stopping..." },
  success: { text: "Running", buttonText: "STOP" },
  failed: { text: "Failed", buttonText: "RELAUNCH" }
};
export type StatusKey = keyof typeof statusValues;

export const StatusBar = ({
  env,
  onRelaunchEnv,
  stoppingEnvironments,
  onStopEnv,
  subtopBarStyles = false
}: Props) => {
  const getAction = (env: Environment | undefined) => {
    if (env?.launchStatus?.trim()?.toLowerCase() === EnvironmentStatuses.Active.toLowerCase()) {
      onStopEnv?.(env);
    } else {
      onRelaunchEnv?.(env);
    }
  };

  const statusStyle = useMemo(
    () =>
      stoppingEnvironments?.includes(env?.id)
        ? styles[EnvironmentStatuses.Stopping.toLowerCase()]
        : styles[env?.launchStatus?.trim()?.toLowerCase() as string],
    [stoppingEnvironments, env?.id, env?.launchStatus]
  );

  const statusText = useMemo(
    () =>
      stoppingEnvironments?.includes(env?.id)
        ? statusValues[EnvironmentStatuses.Stopping.toLowerCase()]?.text
        : env?.launchStatus
          ? statusValues[env?.launchStatus?.trim()?.toLowerCase()]?.text || env?.launchStatus
          : "Unknown",
    [stoppingEnvironments, env?.id, env?.launchStatus]
  );

  return (
    <div
      style={{ borderRadius: "6px", textTransform: "capitalize", fontWeight: 400 }}
      className={`${styles.envStatus} ${statusStyle} ${
        subtopBarStyles ? styles.subtopBarStyles : ""
      }`}>
      <div>{statusText}</div>
      {_.isFunction(onStopEnv) && _.isFunction(onRelaunchEnv) && (
        <>
          {includes(
            [
              EnvironmentStatuses.Launching.toLowerCase(),
              EnvironmentStatuses.Stopping.toLowerCase()
            ],
            env?.launchStatus?.trim()?.toLowerCase()
          ) || stoppingEnvironments?.includes(env?.id) ? (
            <CircularProgress size="20px" />
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                getAction(env);
              }}>
              {env?.launchStatus
                ? statusValues[env?.launchStatus?.trim()?.toLowerCase()]?.buttonText ||
                  capitalize(env?.launchStatus)
                : "Unknown"}
            </button>
          )}
        </>
      )}
    </div>
  );
};
