import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTable, usePagination, useSortBy, useResizeColumns, Row } from "react-table";

import Pagination from "components/Table/Pagination";
import Text from "components/Widget/Text";
import styles from "../../../common/Environments.module.scss";
import { IPredictionService } from "../../Environment";
import { OverflowTooltip } from "components/index";
import { TABS } from "pages/Library/ArtifactsAndModels/Models/ModelDetails";
import { TableHeader } from "components/Table/TableHeader/TableHeader";
import { dateFormat } from "src/utils/dateFormat";

interface IProps {
  data: IPredictionService[];
}
const PAGE_SIZE = 10;

const EnvironmentPredictionServiceTable: React.FC<IProps> = ({ data }) => {
  const [orderBy, setOrderBy] = useState();
  const columns = useMemo(
    () => [
      {
        id: "Name",
        accessor: "pdName",
        Header: "Name",
        isSortable: true,
        Cell: ({ row, value }: { row: Row<IPredictionService>; value: string }) => (
          <Link
            className={styles.nameLinkStyles}
            to={`/artifacts-and-models/model/${row.original?.pdModelName}`}
            state={{ tab: TABS.predictionService.value }}>
            <Text value={value} />
          </Link>
        )
      },
      {
        id: "Model",
        accessor: "pdModelName",
        Header: "Model",
        isSortable: true,
        Cell: ({ value }: { value: string }) => <Text value={value} />
      },
      {
        id: "Creation Date",
        accessor: "pdCreated",
        Header: "Creation Date",
        isSortable: true,
        Cell: ({ value }: { value: string }) => <span>{dateFormat(value)}</span>
      },
      {
        id: "Creator",
        accessor: "pdCreator",
        Header: "Creator",
        isSortable: true
      }
    ],
    []
  );

  const tableInstance = useTable(
    {
      // @ts-ignore
      columns,
      data,
      initialState: {
        pageSize: PAGE_SIZE
      },
      autoResetPage: false
    },
    useSortBy,
    usePagination,
    useResizeColumns
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    pageCount,
    state: { pageIndex }
  } = tableInstance;

  return (
    <section>
      <table className={styles.table} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key: groupKey, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr className={styles.theadRow} key={groupKey} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const key = column.id;
                  return (
                    <TableHeader
                      key={key}
                      className={styles.theadCell}
                      column={column}
                      orderBy={orderBy}
                      headerSortActive={setOrderBy}
                    />
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: Row<IPredictionService>) => {
            prepareRow(row);
            const { key: rowKey, ...restRowProps } = row.getRowProps();

            return (
              <tr className={styles.tbodyRow} key={rowKey} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key: cellKey, ...restCellProps } = cell.getCellProps();

                  return (
                    <td className={styles.tbodyCell} key={cellKey} {...restCellProps}>
                      <OverflowTooltip value={cell.render("Cell")} />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {rows.length > PAGE_SIZE && (
        <Pagination
          pageOptions={pageOptions}
          pageCount={pageCount}
          actualPage={pageIndex}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
        />
      )}
    </section>
  );
};

export default EnvironmentPredictionServiceTable;
