import React, { useState } from "react";
import shallow from "zustand/shallow";
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { LicenseDetails } from "./LicenseDetails";
import { LicenseQuotas } from "./LicenseQuotas";
import { Tabs, Tab, TabPanel } from "src/components";

import { Permissions } from "./Permissions";
import { useLicenseSyncMutation, useLicenseUpdateMutation } from "src/hooks/api";
import useTenantsStore from "src/stores/tenant-management.store";
import { handleResponse } from "src/utils/apiService";
import { LicenseDetailsType } from "src/types/License.type";
import { UpdateLicenseModal } from "./UpdateLicenseModal";
import { DataAppSettings } from "./DataAppSettings";
import Secrets from "./Secrets";
import ApiKeys from "./ApiKeys";

type TabValue = "license-permissions" | "dataapp-settings" | "secrets" | "api-keys";

export const TenantAdminSettings = ({
  licenseDetails,
  switchTab,
  licenseEnabled
}: {
  licenseEnabled?: boolean;
  licenseDetails: LicenseDetailsType;
  switchTab: () => void;
}) => {
  const [tabValue, setTabValue] = useState<TabValue>(
    licenseEnabled ? "license-permissions" : "dataapp-settings"
  );
  const [openUpdateLicenseModal, setOpenUpdateLicenseModal] = React.useState(false);
  const licenseSyncMutation = useLicenseSyncMutation();
  const licenseUpdateMutation = useLicenseUpdateMutation();
  const toggleTenantsRefresh = useTenantsStore((state) => state.toggleTenantsRefresh, shallow);

  const onLicenseSync = () => {
    licenseSyncMutation.mutate(undefined, {
      onSuccess: () => {
        toggleTenantsRefresh();
        handleResponse({ successMessage: `License Synced Successfully.` });
      },
      onError: () => {
        toggleTenantsRefresh();
        switchTab();
      }
    });
  };

  const onLicenseUpdate = (licenseKey: string) => {
    licenseUpdateMutation.mutate(
      { licenseKey },
      {
        onSuccess: async () => {
          await licenseSyncMutation.mutate(undefined, {
            onError: () => {
              switchTab();
              toggleTenantsRefresh();
            }
          });
          toggleTenantsRefresh();
          handleResponse({ successMessage: `License Updated Successfully.` });
          setOpenUpdateLicenseModal(false);
        }
      }
    );
  };

  const handleTabChange = (newValue: TabValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {licenseDetails.valid ? (
        <Grid direction="column" container style={{ gap: "24px" }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            {licenseEnabled && <Tab value="license-permissions" label="License & Permissions" />}
            <Tab value="dataapp-settings" label="DataApp Settings" />
            <Tab value="secrets" label="Secrets" />
            <Tab value="api-keys" label="API Keys" />
          </Tabs>

          {licenseEnabled && (
            <TabPanel
              value="license-permissions"
              index={tabValue}
              style={{ height: "calc(100vh - 224px)", overflow: "auto" }}
              boxProps={{ height: "100%", width: "100%" }}>
              <Grid container direction="column" style={{ gap: "24px" }}>
                <LicenseDetails
                  licenseDetails={licenseDetails}
                  onSync={onLicenseSync}
                  isSyncInProgress={licenseSyncMutation.isLoading}
                  onUpdate={() => setOpenUpdateLicenseModal(true)}
                />
                <Permissions metadata={licenseDetails.metadata} />

                <LicenseQuotas metadata={licenseDetails.metadata} />
                {openUpdateLicenseModal && (
                  <UpdateLicenseModal
                    open={openUpdateLicenseModal}
                    onClose={() => setOpenUpdateLicenseModal(false)}
                    onUpdate={onLicenseUpdate}
                    isSubmitInProgress={licenseUpdateMutation.isLoading}
                  />
                )}
              </Grid>
            </TabPanel>
          )}
          <TabPanel
            value="dataapp-settings"
            index={tabValue}
            style={{ height: "calc(100vh - 224px)", overflow: "auto" }}
            boxProps={{ height: "100%", width: "100%" }}>
            <DataAppSettings />
          </TabPanel>
          <TabPanel
            value="secrets"
            style={{ height: "calc(100vh - 224px)", overflow: "auto" }}
            index={tabValue}
            boxProps={{ height: "100%", width: "100%" }}>
            <Secrets />
          </TabPanel>
          <TabPanel
            value="api-keys"
            style={{ height: "calc(100vh - 224px)", overflow: "auto" }}
            index={tabValue}
            boxProps={{ height: "100%", width: "100%" }}>
            <ApiKeys />
          </TabPanel>
        </Grid>
      ) : (
        <Alert severity="error">License is not valid for this tenant</Alert>
      )}
    </>
  );
};
