import React, { useCallback, useMemo } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import _ from "lodash";

import AIGuideResponse from "src/pages/Projects/AIGuide/common/AIGuideResponse";

import { AIChatResponseDto } from "@rapidcanvas/rc-api-core";
import { AIChatRequestDtoOutputTypeEnum, InputIdDtoTypeEnum } from "@rapidcanvas/rc-api-core";
import { STOPPED_QUERY_ID } from "../GenerateCodeBar/GenerateCodeQueryInput";
import { checkCanDeleteAIGuideMessage, getAllDescendants } from "../askai.helper";

const useStyles = makeStyles({
  responsesWrap: {
    gap: "8px",
    flexWrap: "nowrap",
    overflowY: "auto"
  },
  promptSuggestions: {
    flexWrap: "nowrap"
  }
});

const AskAIResponsesNewFlow = ({
  responses,
  isGenerating,
  targetId,
  setIsRetryInProgress,
  autoGenerateCode,
  inputNames
}: {
  responses: AIChatResponseDto[];
  hoverId: any;
  setHoverId: (hoverId: any) => void;
  isGenerating: boolean;
  targetId: string | undefined;
  setIsRetryInProgress: (isRetryInProgress: boolean) => void;
  autoGenerateCode: (text: string) => void;
  inputNames: string[];
}) => {
  const classes = useStyles();

  const getExistingEntityNames = useCallback(
    (currentMessage: AIChatResponseDto) => {
      const currentDatasetsName = _.map(
        currentMessage.outputEntityList || currentMessage.outputEntityResponseList,
        "name"
      );
      const dateSetResponse = _.filter(
        responses.filter((response) => response.askAIMessageId !== currentMessage.askAIMessageId),
        (response: AIChatResponseDto) =>
          response.outputType === AIChatRequestDtoOutputTypeEnum.Dataset
      );
      const chartResponses = _.filter(
        responses.filter((response) => response.askAIMessageId !== currentMessage.askAIMessageId),
        (response: AIChatResponseDto) =>
          response.outputType === AIChatRequestDtoOutputTypeEnum.Chart
      );
      const modelResponses = _.filter(
        responses.filter((response) => response.askAIMessageId !== currentMessage.askAIMessageId),
        (response: AIChatResponseDto) =>
          response.outputType === AIChatRequestDtoOutputTypeEnum.Model
      );
      return {
        inputs: _.difference(inputNames, currentDatasetsName),
        existingCharts: _.flatMap(chartResponses, (response) => {
          return _.map(response.outputEntityList || response.outputEntityResponseList, "name");
        }),
        existingDatasets: _.flatMap(dateSetResponse, (response) => {
          return _.map(response.outputEntityList || response.outputEntityResponseList, "name");
        }),
        existingModels: _.flatMap(modelResponses, (response) => {
          return _.map(response.outputEntityList || response.outputEntityResponseList, "name");
        })
      };
    },
    [responses, inputNames]
  );

  const descendantsMap = useMemo(() => {
    return _.mapValues(_.keyBy(responses, "askAIMessageId"), (message) => {
      const descendants = getAllDescendants(responses, message);
      return _.map(descendants, "askAIMessageId") as string[];
    });
  }, [responses]);

  const canDeleteMessagesMap = useMemo(() => {
    return _.mapValues(_.keyBy(responses, "askAIMessageId"), (message) =>
      checkCanDeleteAIGuideMessage(responses, message)
    );
  }, [responses]);

  return responses?.length !== 0 ? (
    <Grid container direction="column" className={classes.responsesWrap}>
      {responses.map((response: AIChatResponseDto, index) => {
        const existingEntityNames = getExistingEntityNames(response);
        const { askAIMessageId, transformId, targetInputs } = response;
        const isLastMessage = index === responses?.length - 1;
        return (
          <Grid container key={index}>
            <AIGuideResponse
              message={response}
              autoGenerateCode={autoGenerateCode}
              canRetry={
                isLastMessage &&
                !isGenerating &&
                askAIMessageId !== STOPPED_QUERY_ID &&
                !transformId
              }
              hideFeedback={askAIMessageId === STOPPED_QUERY_ID}
              targetId={targetId}
              existingEntityNames={existingEntityNames}
              hasMarkDown={false}
              descendants={descendantsMap[askAIMessageId!] || []}
              inputIds={_.map(targetInputs || [], (targetInput) => {
                const { intermediateDataset, askAIInputId, entityName } = targetInput;
                if (intermediateDataset) {
                  return {
                    id: intermediateDataset.chatId,
                    name: intermediateDataset.entityName,
                    type: InputIdDtoTypeEnum.AskaiMessageId
                  };
                }
                return {
                  id: askAIInputId,
                  name: entityName,
                  type: InputIdDtoTypeEnum.InputId
                };
              })}
              canDelete={canDeleteMessagesMap[askAIMessageId!]}
              isGenerating={isGenerating}
              showActions
              setIsRetryInProgress={setIsRetryInProgress}
            />
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <React.Fragment />
  );
};

export default React.memo(AskAIResponsesNewFlow);
