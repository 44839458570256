import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";

import Alert from "@material-ui/lab/Alert";
import { useForm, Controller } from "react-hook-form";
import _, { find, get, size, toLower } from "lodash";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Grid, InputAdornment, MenuItem, Typography, makeStyles } from "@material-ui/core";

import { Field } from "src/components";

import {
  EnvironmentType,
  EnvironmentsTypes
} from "../../../../../../../constants/environments.constants";
import { EnvironmentsHelperText } from "../../../../utils/Environments.constants";

import { preventExponentialInput, snakeCaseToStartCase } from "src/utils/helpers";
import {
  DescriptionCharacterLimit,
  DescriptionCharacterLimitMessage
} from "src/pages/private/ProjectsModule/utils";

import EnvironmentTypeConfig from "../../../common/EnvironmentTypeConfig";
import { EnvConfig } from "../../Environment";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 142px)",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: 12
  },
  dropdown: {
    maxHeight: 200
  },
  diskSpaceInfo: {
    margin: 0,
    padding: 0,
    border: "none",
    "& [class^='MuiAlert-icon']": {
      marginRight: theme.spacing(1)
    },
    "& [class^='MuiAlert-message']": {
      paddingTop: theme.spacing(0.75)
    }
  },
  formWrap: {
    rowGap: 20
  }
}));

type Props = {
  setIsDirtyFields: Dispatch<SetStateAction<boolean>>;
  isEnvTypeDisabled: boolean;
  environmentsTypes: EnvironmentType[];
  setEnvironmentError: Dispatch<SetStateAction<string | undefined>>;
  environmentConfigSession: EnvConfig | null;
  setEnvironmentConfigSession: (config: EnvConfig) => void;
};

const validateCustomConfig = (label: string, value: any, limit: any) => {
  if (value <= 0 || value > limit) {
    return `${label} must be between 0 and ${limit}`;
  }
  return true;
};

export const EnvironmentInputs = ({
  setIsDirtyFields,
  isEnvTypeDisabled,
  environmentsTypes,
  setEnvironmentError,
  environmentConfigSession,
  setEnvironmentConfigSession
}: Props) => {
  const classes = useStyles();

  const {
    control,
    getValues,
    reset,
    formState: { errors },
    trigger
  } = useForm<EnvConfig>({
    mode: "onChange", // Validate onChange
    reValidateMode: "onChange", // Re-validate onChange
    defaultValues: environmentConfigSession || {}
  });

  const errorValues = Object.values(errors);
  useEffect(() => {
    reset(environmentConfigSession || {});
  }, [environmentConfigSession]);

  useEffect(() => {
    setEnvironmentError(errorValues?.[0]?.message);
  }, [errorValues?.length]);

  const handleChange = async (name: keyof EnvConfig, data: any) => {
    setIsDirtyFields(true);
    const updatedValues = { ...getValues(), [name]: data };
    setEnvironmentConfigSession(updatedValues);
  };

  const customEnvLimit = useMemo(
    () => find(environmentsTypes, (item: any) => item.name === EnvironmentsTypes.Custom),
    [environmentsTypes]
  );
  const envType = find(
    environmentsTypes,
    (env) => toLower(env.name) === toLower(getValues().envType)
  );
  const isCustomEnvironment = getValues().envType === EnvironmentsTypes.Custom;

  return (
    <Grid container alignContent="flex-start" className={classes.root}>
      <Grid container className={classes.formWrap}>
        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            rules={{
              validate: (value) =>
                size(value) > DescriptionCharacterLimit ? DescriptionCharacterLimitMessage : true
            }}
            render={({ field }) => (
              <Field
                {...field}
                id="description"
                data-testid="environment-description"
                label="Environment Description"
                variant="outlined"
                size="small"
                fullWidth
                error={!!errors.description}
                helperText={errors.description && <>{errors.description?.message}</>}
                onChange={(e) => handleChange("description", e.target.value)}
                multiline
                minRows={4}
                maxRows={4}
              />
            )}
          />
          <Typography variant="caption" color="textSecondary" data-testid="envDescriptionInfo">
            {EnvironmentsHelperText.DescriptionHelperText}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="envType"
            control={control}
            render={({ field }) => (
              <Field
                {...field}
                id="envType"
                data-testid="environment-type-field"
                label="Environment Type"
                variant="outlined"
                size="small"
                fullWidth
                required
                select
                onChange={(e) => handleChange("envType", e.target.value)}
                disabled={isEnvTypeDisabled}
                SelectProps={{
                  MenuProps: {
                    classes: { paper: `${classes.dropdown}` },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }
                }}>
                {environmentsTypes.map((currType) => (
                  <MenuItem
                    key={currType.name}
                    value={currType.name}
                    data-testid="environment-type-menu-item">
                    {snakeCaseToStartCase(currType.name, {
                      toUpperCase: ["cpu"]
                    })}
                  </MenuItem>
                ))}
              </Field>
            )}
          />

          {!isCustomEnvironment && envType && (
            <Typography variant="caption" color="textSecondary" data-testid="envTypeInfo">
              <EnvironmentTypeConfig
                cores={envType.cores}
                memInMbs={envType.memInMbs}
                diskInGbs={environmentConfigSession?.diskSpace}
              />
            </Typography>
          )}
        </Grid>

        {isCustomEnvironment && (
          <>
            <Grid container justifyContent="space-between">
              <Grid item style={{ width: "47.5%" }}>
                <Controller
                  name="cores"
                  control={control}
                  rules={{
                    required: "Cores is required",
                    validate: (value) =>
                      validateCustomConfig("Cores", value, get(customEnvLimit, "cores"))
                  }}
                  render={({ field, fieldState }) => {
                    const { error } = fieldState;
                    return (
                      <Field
                        {...field}
                        id="cores"
                        label="Cores"
                        type="number"
                        required
                        variant="outlined"
                        fullWidth
                        data-testid="environment-cores-input"
                        onKeyDown={preventExponentialInput}
                        error={!!error}
                        helperText={!!error?.message && <>{error?.message}</>}
                        onChange={(event) => {
                          field.onChange(event?.target?.value);

                          // Revalidate to ensure the key is not empty
                          trigger("cores");

                          handleChange("cores", event?.target?.value);
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item style={{ width: "47.5%" }}>
                <Controller
                  name="memory"
                  control={control}
                  rules={{
                    required: "Memory is required",
                    validate: (value) =>
                      validateCustomConfig("Memory", value, get(customEnvLimit, "memInGbs"))
                  }}
                  render={({ field, fieldState }) => {
                    const { error } = fieldState;
                    return (
                      <Field
                        {...field}
                        id="memory"
                        label="Memory"
                        type="number"
                        required
                        variant="outlined"
                        fullWidth
                        data-testid="environment-memory-input"
                        onKeyDown={preventExponentialInput}
                        error={!!error}
                        helperText={!!error?.message && <>{error?.message}</>}
                        onChange={(event) => {
                          field.onChange(event?.target?.value);

                          // Revalidate to ensure the key is not empty
                          trigger("cores");

                          handleChange("memory", event?.target?.value);
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">GB</InputAdornment>
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="diskInGbs"
                control={control}
                rules={{
                  required: "DiskSpace is required",
                  validate: (value) =>
                    validateCustomConfig("Disk Space", value, get(customEnvLimit, "diskInGbs"))
                }}
                render={({ field, fieldState }) => {
                  const { error } = fieldState;
                  return (
                    <Field
                      {...field}
                      id="diskSpace"
                      label="Disk Space"
                      type="number"
                      required
                      variant="outlined"
                      fullWidth
                      disabled
                      data-testid="environment-disk-space-input"
                      onKeyDown={preventExponentialInput}
                      error={!!error}
                      helperText={!!error?.message && <>{error?.message}</>}
                      onChange={(event) => {
                        field.onChange(event?.target?.value);

                        // Revalidate to ensure the key is not empty
                        trigger("cores");

                        handleChange("diskInGbs", event?.target?.value);
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">GB</InputAdornment>
                      }}
                    />
                  );
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Alert
        variant="outlined"
        severity="info"
        className={classes.diskSpaceInfo}
        icon={<InfoOutlinedIcon fontSize="small" color="action" />}>
        <Typography variant="caption" color="textSecondary" data-testid="envDiskSpaceInfo">
          {EnvironmentsHelperText.DiskSpaceInfo}
        </Typography>
      </Alert>
    </Grid>
  );
};
