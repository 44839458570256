import { Dispatch, SetStateAction } from "react";

// Packages
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { find, flatMap, get, keyBy, map, mapValues, merge, reduce, reverse, uniq } from "lodash";

// Utils
import { WebPaths } from "src/routing/routes";
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";

// Open API
import { CreateScenarioDto, ScenarioDto, UpdateScenarioDto } from "@rapidcanvas/rc-api-core";

// Components
import { useSaveScenario, useUpdateScenario } from "src/hooks/api";

// Contexts
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

// Types
import { Scenario } from "../Scenario.type";
import { DfsTemplate, Recipe } from "src/types";

// Constants
import { ScenarioFormFields, ScenarioHelperText } from "../utils/Scenario.constants";

type Props = {
  recipesData?: Recipe[];
  recipeTemplatesData?: DfsTemplate[];
  resetForm: (data: ScenarioDto) => void;
  getValues: UseFormGetValues<Scenario>;
  setValue: UseFormSetValue<Scenario>;
  setIsAttemptedSubmit: Dispatch<SetStateAction<boolean>>;
};

const useActions = (props: Props) => {
  const { recipesData, recipeTemplatesData, resetForm, getValues, setValue, setIsAttemptedSubmit } =
    props || {};

  const navigate = useNavigate();
  const { projectId, scenarioId } = useParams() || {};

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isSaving,
    mutateAsync: saveMutation,
    reset: resetSaveMutation
  } = useSaveScenario();

  const {
    isLoading: isUpdating,
    mutateAsync: updateMutation,
    reset: resetUpdateMutation
  } = useUpdateScenario();
  // << ENDS - Query hooks

  const getFormattedVariables = (
    variables: Record<string, any>,
    recipeName: string,
    runConfigName: string
  ) =>
    map(variables, (variableValue, variableName) => ({
      groupName: recipeName,
      runConfigName,
      variableName,
      variableValue
    }));

  const getVariables = () =>
    recipesData?.reduce((acc, { name, runConfigs }) => {
      runConfigs.forEach((runConfig) => {
        const appliedTransform = find(recipeTemplatesData, { id: runConfig?.templateId });

        const values = reduce(
          appliedTransform?.inputs,
          (valuesAcc, input) =>
            merge(valuesAcc, {
              [input.name]:
                get(runConfig, `variables[${input.name}]`) ||
                get(input, "display.default_value") ||
                get(input, "metadata.default_value")
            }),
          {}
        );

        // @ts-ignore
        acc.push(...getFormattedVariables(values || {}, name, runConfig?.name));
      });
      return acc;
    }, []);

  const scenarioVariables = () => mapValues(keyBy(reverse(getValues()?.variables), "key"), "value");

  const saveScenario = async () => {
    if (!projectId) {
      return;
    }

    const payload: CreateScenarioDto = {
      projectId,
      name: getValues()?.name,
      // @ts-ignore
      segmentIds: uniq(flatMap(getValues()?.datasets, "selectedSegments")),
      variables: getVariables(),
      sharedVariables: scenarioVariables()
    };

    await resetSaveMutation();
    await saveMutation(payload, {
      onSuccess: () => {
        toastWrapper({ type: ToastTypes.Success, content: ScenarioHelperText.ScenarioCreated });
        navigate(-1);
      },
      onSettled: () => setIsAttemptedSubmit(() => false)
    });
  };

  const updateScenarioName = async (name?: string) => {
    if (!scenarioId || !name) {
      return;
    }

    const payload: UpdateScenarioDto = {
      id: scenarioId,
      name
    };

    await resetUpdateMutation();
    await updateMutation(payload, {
      onSuccess: (data: ScenarioDto) => {
        toastWrapper({ type: ToastTypes.Success, content: ScenarioHelperText.ScenarioNameUpdated });
        setValue(ScenarioFormFields.Name, data?.name || "");
      }
    });
  };

  const updateScenario = async (name?: string) => {
    if (!scenarioId) {
      return;
    }

    if (!!name) {
      updateScenarioName(name);
      return;
    }

    const payload: UpdateScenarioDto = {
      id: scenarioId,
      name: getValues()?.name,
      // @ts-ignore
      segmentIds: uniq(flatMap(getValues()?.datasets, "selectedSegments")),
      sharedVariables: scenarioVariables()
    };

    await resetUpdateMutation();
    await updateMutation(payload, {
      onSuccess: async (data) => {
        await resetForm(data);
        toastWrapper({ type: ToastTypes.Success, content: ScenarioHelperText.ScenarioUpdated });
      },
      onSettled: () => setIsAttemptedSubmit(() => false)
    });
  };

  const onDisplayOnDag = () => {
    if (!projectId || !scenarioId) {
      return;
    }

    navigate(generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, { projectId, scenarioId }));
  };

  return {
    isSavingScenario: isSaving || isUpdating,
    saveScenario,
    updateScenario,
    onDisplayOnDag
  };
};

export default useActions;
