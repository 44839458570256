import React, { ReactNode } from "react";

// Packages
import { toast } from "react-toastify";

// Utils
import toastClient from "src/utils/ToastClient";

// Components
import ToastContent from "src/components/ToastifyAlert/ToastContent";

// Styles
import "react-toastify/dist/ReactToastify.css";

export const TOAST_TIMEOUT = 10000;

export type Action = {
  label: string;
  action: (event: any) => void;
};

export enum ToastTypes {
  Info = "info",
  Warn = "warn",
  Error = "error",
  Success = "success"
}

export interface ContentProps {
  title?: string | ReactNode;
  content: string | ReactNode;
  actions?: Action[];
  type?: "info" | "warn" | "error" | "success";
  autoClose?: number;
  onClose?: () => void;
}

export const addToast = ({
  type = ToastTypes.Info,
  title,
  content,
  actions,
  autoClose = 5000,
  onClose,
  ...rest
}: ContentProps) =>
  toast[type](<ToastContent {...{ title, content, actions, type }} />, {
    autoClose: type === "error" ? TOAST_TIMEOUT : autoClose,
    pauseOnFocusLoss: false,
    onClose: () => onClose?.(),
    ...rest
  });

export const toastWrapper = ({
  type,
  title,
  content,
  actions,
  autoClose = 5000,
  ...rest
}: ContentProps) => toastClient.emit({ type, title, content, actions, autoClose, ...rest });
