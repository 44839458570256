import { get, pick, uniq, without, forEach, filter } from "lodash";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";

import api from "utils/AxiosClient";
import { IData, IReturn, QUERY_KEY_RUN_DETAILS } from "./useRunDetails";
import { JobRunStatuses } from "pages/private/ProjectsModule/pages/Jobs/utils/Jobs.constants";
import { handleResponse } from "src/utils/apiService";
import {
  getFailedRecipeName,
  getPredictionJobOutputpathAndName
} from "pages/private/ProjectsModule/pages/PredictionJob/utils/PredictionJob.helpers";
import { useProjectsStore } from "src/store/store";
import { EntityDataDto } from "@rapidcanvas/rc-api-core";
import { QUERY_KEY_ALL_PROJECT_RUNS } from "./useGetAllProjectRuns";

interface IVariables {
  async: boolean;
  projectRunId: string;
  modelName: string;
  name: string;
  data?: IReturn & { lastRunEntryId: string };
  futurePeriod?: string;
}

interface IManualReturn extends IData {
  lastRunEntryId: string;
  path: string;
  status: JobRunStatuses;
  outputEntityName?: string;
}

const POLLING_INTERVAL = 5;

const useManualRunPrediction = (): UseMutationResult<
  IManualReturn,
  unknown,
  IVariables,
  unknown
> => {
  const queryClient = useQueryClient();
  const fetchingRef = useRef<boolean>(false);
  const [runningPredictionJobIds, setRunningPredictionjobIds] = useProjectsStore((state) => [
    state.runningPredictionJobIds,
    state.setRunningPredictionjobIds
  ]);

  return useMutation({
    mutationFn: async ({ async, projectRunId, modelName, data, name, futurePeriod }) => {
      setRunningPredictionjobIds(uniq([...runningPredictionJobIds, projectRunId]));
      queryClient.setQueryData([QUERY_KEY_RUN_DETAILS, projectRunId], {
        ...data,
        status: JobRunStatuses.Created
      });

      await api.fetchResponse(
        async () =>
          await api.ProjectRunControllerApi.run(
            projectRunId,
            {
              variables: futurePeriod
                ? { mode: "prediction_job", modelName, futurePeriod }
                : { mode: "prediction_job", modelName },
              runId: projectRunId
            },
            async
          )
      );

      const runDetailsResponse: any = await new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
          if (!fetchingRef.current) {
            try {
              fetchingRef.current = true;
              const { data: detailsResponse } =
                await api.ProjectRunControllerApi.findProjectRunDetailsById(projectRunId);
              fetchingRef.current = false;
              queryClient.invalidateQueries([QUERY_KEY_ALL_PROJECT_RUNS]);
              const status = get(detailsResponse, ["lastRunEntry", "status"]);
              const recipeName = getFailedRecipeName(detailsResponse);
              const recipeId = recipeName
                ? get(detailsResponse, ["lastRunEntry", "recipeNameToId", recipeName])
                : "";
              const errorMsg = get(detailsResponse, ["lastRunEntry", "error"]);
              let e = "";
              switch (status) {
                case JobRunStatuses.Success:
                case JobRunStatuses.SuccessWithWarn:
                  clearInterval(interval);
                  resolve(detailsResponse);
                  break;
                case JobRunStatuses.Failure:
                  clearInterval(interval);
                  queryClient.setQueryData([QUERY_KEY_RUN_DETAILS, projectRunId], {
                    ...data,
                    lastRunEntryId: get(detailsResponse, ["lastRunEntry", "id"]),
                    status: JobRunStatuses.Failure,
                    recipeId,
                    recipeName,
                    errorMsg: recipeId ? "" : errorMsg
                  });

                  forEach(
                    get(detailsResponse, ["lastRunEntry", "recipeRunInfo"]),
                    ({ results, status }) => {
                      if (status === "FAILURE") {
                        e =
                          get(filter(results, { status: "FAILURE" }), [
                            "0",
                            "msgList",
                            "0",
                            "msg"
                          ]) ?? "";
                      }
                    }
                  );

                  if (!recipeId && errorMsg) {
                    e = errorMsg;
                  }

                  handleResponse({
                    errorMessage: `Error in generating the prediction output for ${name}. ${e}`
                  });
                  reject("Error in generating the response");
                  break;
                case JobRunStatuses.Running:
                case JobRunStatuses.Started:
                case JobRunStatuses.Created:
                  queryClient.setQueryData([QUERY_KEY_RUN_DETAILS, projectRunId], {
                    ...data,
                    status,
                    lastRunEntryId: get(detailsResponse, ["lastRunEntry", "id"]),
                    errorMsg: ""
                  });
              }
            } catch (e: any) {
              clearInterval(interval);
              if (e?.response?.status !== 404) {
                handleResponse({
                  errorMessage: `Error in generating the prediction output for ${name}`
                });
              }
              reject("Error in generating the response");
            }
          }
        }, POLLING_INTERVAL * 1000);
      });

      const lastRunEntryId = get(runDetailsResponse, ["lastRunEntry", "id"]);
      if (!lastRunEntryId) {
        handleResponse({
          errorMessage: `Error in generating the prediction output for ${name}`
        });
        throw "Unable to generate prediction output";
      }

      const { name: entityName, path } = getPredictionJobOutputpathAndName(
        runDetailsResponse?.lastRunEntry
      );

      if (!path) {
        const recipeName = getFailedRecipeName(runDetailsResponse);
        const recipeId = recipeName
          ? get(runDetailsResponse, ["lastRunEntry", "recipeNameToId", recipeName])
          : "";
        const errorMsg = get(runDetailsResponse, ["lastRunEntry", "error"]);
        queryClient.setQueryData([QUERY_KEY_RUN_DETAILS, projectRunId], {
          ...data,
          lastRunEntryId,
          status: JobRunStatuses.Failure,
          recipeId,
          recipeName,
          outputEntityName: entityName,
          errorMsg: recipeId ? "" : errorMsg
        });
        handleResponse({
          errorMessage: `Error in generating the prediction output for ${name}`
        });
        throw "Unable to generate prediction output";
      }

      const outputResponse = await api.fetchResponse<EntityDataDto>(
        async () =>
          await api.EntityControllerApi.getEntityData(lastRunEntryId, {
            projectRunEntryId: lastRunEntryId,
            runEntityName: entityName,
            rowsEnd: 500,
            rowsStart: 0
          })
      );

      return {
        columns: outputResponse?.data?.columns ?? [],
        rows: outputResponse?.data?.rows ?? [],
        lastRunEntryId,
        path,
        status: JobRunStatuses.Success,
        outputEntityName: entityName
      };
    },
    onSuccess: (results, { name, data, projectRunId }) => {
      setRunningPredictionjobIds(without(runningPredictionJobIds, projectRunId));
      handleResponse({
        successMessage: `Prediction output for the prediction job ${name} has been generated successfully `
      });
      queryClient.setQueryData([QUERY_KEY_RUN_DETAILS, projectRunId], {
        ...data,
        data: pick(results, ["columns", "rows"]),
        lastRunEntryId: results.lastRunEntryId,
        path: results.path,
        outputEntityName: results?.outputEntityName,
        status: JobRunStatuses.Success
      });
      queryClient.invalidateQueries([QUERY_KEY_ALL_PROJECT_RUNS]);
    },
    onError: (__, { projectRunId }) => {
      setRunningPredictionjobIds(without(runningPredictionJobIds, projectRunId));
      queryClient.invalidateQueries([QUERY_KEY_ALL_PROJECT_RUNS]);
    }
  });
};

export default useManualRunPrediction;
