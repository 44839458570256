import { map } from "lodash";
import { useMemo, useState } from "react";
import { useProjectsStore } from "src/store/store";

export const useProjectNameSuggestion = ({
  currName,
  setSuggestion
}: {
  currName: string;
  setSuggestion: (currName: string) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [projectList] = useProjectsStore((state) => [state.projectList]);

  const projectNameList = useMemo(() => map(projectList, "name") as string[], [projectList]);

  const generateSuggestion = (name: string): string[] => {
    const suggestions: string[] = [];
    let counter = 1;
    const maxNameLength = 64 - counter.toString().length;
    const baseName = name.length > maxNameLength ? name.slice(0, maxNameLength) : name;
    while (suggestions.length < 3) {
      const newName = `${baseName}${counter}`;
      if (!projectNameList.includes(newName)) {
        suggestions.push(newName);
      }
      counter++;
    }

    return suggestions;
  };

  const suggestionsList = useMemo(() => {
    if (Boolean(anchorEl)) {
      return generateSuggestion(currName);
    }
    return [];
  }, [currName, anchorEl]);

  const handleSuggestionClose = () => {
    setAnchorEl(null);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSuggestion(suggestion);
    setAnchorEl(null);
  };
  return {
    handleSuggestionClick,
    handleSuggestionClose,
    suggestionsList,
    anchorEl,
    setAnchorEl
  };
};
