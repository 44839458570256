import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import { isEmpty, map, uniq, get, size, filter } from "lodash";

import CommonLoader from "src/components/CommonLoader";
import CreateDataApp from "./CreateDataApp/CreateDataApp";
import ReactException from "components/Errors/ReactException";
import useGetAppTemplates from "src/hooks/api/dataapps/useGetAppTemplates";
import useGetDataApps from "../../../../hooks/api/dataapps/useGetDataApps";
import useProjectDataAppsDetails from "src/hooks/api/dataapps/useProjectDataAppsDetails";
import { DataAppType } from "pages/DataApps/DataApps.type";
import { DataAppsHeader } from "pages/DataApps/DataAppsDashboard/DataAppsHeader/DataAppsHeader";
import {
  ADD_DATAAPP_TOOLTIP,
  DataAppsSplashSection,
  IMPORT_TOOLTIP
} from "pages/DataApps/utils/DataApps.constants";
import { DataAppsView } from "pages/DataApps/DataAppsDashboard/DataAppsView";
import { DeleteDataAppModal } from "pages/DataApps/DataAppsDashboard/DeleteDataAppModal";
import { SplashIcon } from "src/icons/NewUX";
import { SplashSection } from "src/components";
import { listViewPages, PagesViewConfiguration } from "src/constants";
import { InfoOutlined } from "@material-ui/icons";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "calc(100vh - 64px - 254px)",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey[700]
  },
  gridIcon: {
    height: 215,
    width: 215,
    marginRight: 140
  }
}));

const ProjectDataApps: React.FC = () => {
  const { data, isLoading, isError, refetch } = useGetDataApps();

  const { projectId } = useParams();
  const location = useLocation();

  const dataAppDetailsResponse = useProjectDataAppsDetails(projectId);
  const templateResponse = useGetAppTemplates(undefined, undefined, undefined, {
    enabled: !!projectId
  });

  const dataApps = useMemo(
    () => filter(data, (app) => app.projectId === projectId),
    [data, projectId]
  );

  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");
  const isTilesView = get(pagesViewConfiguration, listViewPages.DATAAPPS, true);
  const [tilesView, setTilesView] = useState(isTilesView);

  const [dataAppType, setDataAppType] = useState<"auto-create" | "import-zip" | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [dataAppToDelete, setDataAppToDelete] = useState<DataAppType | null>(null);
  const [selectedProjects, setSelectedProjects] = useState<string[]>(
    uniq(dataApps?.map((dataApp) => dataApp.projectId)) || []
  );

  useEffect(() => {
    if (location?.state?.type) {
      setDataAppType(location?.state?.type);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(selectedProjects)) {
      setSelectedProjects(uniq(map(dataApps, (dataApp) => dataApp.projectId)));
    }
  }, [dataApps]);

  const classes = useStyles();

  const searchedDataApps = React.useMemo(
    () =>
      dataApps?.filter((dataApp: DataAppType) =>
        (dataApp.displayName || dataApp.name)?.toLowerCase?.().includes?.(searchValue.toLowerCase())
      ),
    [dataApps, searchValue]
  );

  const filteredDataApps = React.useMemo(
    () =>
      selectedProjects.length !== 0
        ? _.orderBy(
            searchedDataApps,
            [(item) => (item.launchStatus === "RUNNING" ? 1 : 0), "updatedOn"],
            ["desc", "desc"]
          )?.filter((dataApp: DataAppType) => selectedProjects?.includes(dataApp.projectId))
        : [],
    [searchedDataApps, selectedProjects]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleDelete = (dataApp: DataAppType) => {
    setDataAppToDelete(dataApp);
  };

  const handleCreateClick = (type: "auto-create" | "import-zip") => {
    setDataAppType(type);
    setSearchValue("");
  };

  if (isLoading) {
    return <CommonLoader />;
  }

  if (isError) {
    return <ReactException onClick={refetch} />;
  }

  return (
    <>
      <DataAppsHeader
        searchValue={searchValue}
        isTilesView={tilesView}
        openCreateDataApp={!!dataAppType}
        onSearch={handleSearch}
        onViewChange={setTilesView}
        onClick={handleCreateClick}
      />

      {!!dataAppType ? (
        <CreateDataApp
          dataAppType={dataAppType}
          dataApps={dataApps}
          binaryClassRecipes={dataAppDetailsResponse.data}
          recipeLoading={dataAppDetailsResponse.isLoading}
          appTemplates={templateResponse.data}
          isLoading={dataAppDetailsResponse.isLoading || templateResponse.isLoading}
          onCancel={() => setDataAppType(null)}
        />
      ) : size(dataApps) === 0 ? (
        <SplashSection
          gridContainerStyle={classes.gridContainer}
          gridIconStyle={classes.gridIcon}
          buttonComponent={
            <div style={{ display: "flex", gap: "20px", alignItems: "flex-start" }}>
              <Tooltip title={ADD_DATAAPP_TOOLTIP}>
                <Button
                  color="primary"
                  variant="contained"
                  data-testid="createDataApp"
                  size="large"
                  onClick={() => handleCreateClick("auto-create")}>
                  {DataAppsSplashSection.actionLabel}
                </Button>
              </Tooltip>
              <span style={{ marginTop: "10px" }}>Or</span>
              <div>
                <Tooltip title={IMPORT_TOOLTIP}>
                  <Button
                    color="primary"
                    variant="contained"
                    data-testid="importDataApp"
                    size="large"
                    onClick={() => handleCreateClick("import-zip")}>
                    {DataAppsSplashSection.importLabel}
                  </Button>
                </Tooltip>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    fontSize: "12px",
                    color: "#8a8989",
                    marginTop: "6px"
                  }}>
                  <InfoOutlined style={{ width: "16px", height: "16px" }} />
                  <span>Upload Zip file</span>
                </div>
              </div>
            </div>
          }
          titleSection={{
            title: DataAppsSplashSection.title,
            subtitleLines: [
              DataAppsSplashSection.subTitleLine1,
              DataAppsSplashSection.subTitleLine2
            ]
          }}
          icon={<SplashIcon />}
        />
      ) : (
        <DataAppsView
          allDataApps={dataApps}
          searchValue={searchValue}
          dataApps={filteredDataApps}
          onDelete={handleDelete}
          setIsTilesView={setTilesView}
          isTilesView={tilesView}
          onSearch={handleSearch}
          isProjectDataApps={true}
          onClearSearch={() => setSearchValue("")}
          searchedDataApps={searchedDataApps}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
        />
      )}
      {dataAppToDelete && (
        <DeleteDataAppModal
          dataAppId={dataAppToDelete?.id}
          dataAppName={dataAppToDelete?.displayName || dataAppToDelete?.name}
          open={!!dataAppToDelete?.id}
          onClose={() => setDataAppToDelete(null)}
        />
      )}
    </>
  );
};

export default ProjectDataApps;
