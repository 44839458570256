import { EnvironmentsTypes } from "src/constants/environments.constants";
import { MachineLearningTask } from "../Projects/AddAutoMLRecipe/hooks/useGetAutoMLProblemTypes";
import { DataAppDto, DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

export enum DataAppAppTypeEnum {
  STREAMLIT = "STREAMLIT",
  REACTJS = "REACTJS"
}

export type DataAppType = {
  id: string;
  name: string;
  description: string;
  displayName: string;
  projectId: string;
  creator: string;
  createdOn: number;
  updater: string;
  updatedOn: number;
  iconUrl?: string;
  appTemplateId: string;
  accessUrl: string;
  tenantId: string;
  scenarioId: string;
  launchStatus: DataAppDtoLaunchStatusEnum;
  status?: keyof typeof DataAppDtoLaunchStatusEnum;
  stoppedBy?: string;
  inactivityTtlInHrs: number;
  ttlInHrs: number | null;
  envType?: keyof typeof EnvironmentsTypes;
  groupId?: string;
  groupDisplayName?: string;
  appTemplateType: keyof typeof DataAppAppTypeEnum;
  askAIConfig: DataAppDto["askAIConfig"];
  dataAppType: DataAppDto["dataAppType"];
  brandLogo: DataAppDto["brandLogo"];
  title: DataAppDto["title"];
  isPrivate?: DataAppDto["isPrivate"];
  showAICode?: DataAppDto["showAICode"];
  cores?: DataAppDto["cores"];
  memInMbs?: DataAppDto["memInMbs"];
  diskInGbs?: DataAppDto["diskInGbs"];
  metadata?: DataAppDto["metadata"];
  appType?: DataAppDto["appType"];
};

export type AppTemplate = {
  id: string;
  name: string;
  autoMlProblemType: MachineLearningTask;
};
