import React from "react";
import shallow from "zustand/shallow";
import { Box, Grid, TextField, Tooltip, Typography, makeStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import { usePatchTenantMutation } from "src/hooks/api";
import useAuthStore from "src/stores/auth.store";
import { handleResponse } from "src/utils/apiService";

const useStyles = makeStyles({
  dataAppSettings: {
    backgroundColor: "#ffffff",
    padding: "16px",
    gap: "16px",
    borderRadius: "4px"
  },
  permissionKey: {
    opacity: "50%",
    color: "#000000",
    fontSize: "14px"
  },
  dataappsTtlInHrs: {
    flexWrap: "nowrap",
    alignItems: "center"
  },
  textField: {
    paddingLeft: "8px",
    paddingRight: "8px",
    width: "116px"
  }
});

export const DataAppSettings = () => {
  const classes = useStyles();
  const [tenantName, initialValue] = useAuthStore(
    (state) => [state.tenantName, state.dataappsTtlInHrs],
    shallow
  );
  const [value, setValue] = React.useState(initialValue);

  const patchTenantMutation = usePatchTenantMutation();

  const handleChange = (event: $TSFixMe) => {
    const newValue = event.target.value;
    const maxDataappsTtlInHrs = 262800;
    if (
      !newValue ||
      (/^[0-9]*$/.test(newValue) &&
        Number(newValue) >= 0 &&
        Number(newValue) <= maxDataappsTtlInHrs)
    ) {
      setValue(newValue);
    } else if (
      Number(newValue) &&
      (Number(newValue) < 0 || Number(newValue) > maxDataappsTtlInHrs)
    ) {
      handleResponse({
        errorMessage: `Dataapp expiry time needs to be between 0 and ${maxDataappsTtlInHrs} hrs.`
      });
    }
  };

  const handleBlur = () => {
    value &&
      patchTenantMutation.mutate(
        { name: tenantName || "", dataappsTtlInHrs: value },
        {
          onSuccess: () => {
            setValue(value);
            handleResponse({ successMessage: `DataApp Settings saved successfully` });
          }
        }
      );
  };
  return (
    <Grid container direction="column" className={classes.dataAppSettings}>
      <Grid item>
        <Typography variant="h6" color="textPrimary">
          DataApp Settings
        </Typography>
      </Grid>
      <Grid container item className={classes.dataappsTtlInHrs} direction="row">
        Shutdown DataApp if inactive for{" "}
        <TextField
          variant="outlined"
          className={classes.textField}
          size="small"
          inputProps={{ type: "number", min: 0, step: 1 }}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={patchTenantMutation.isLoading}
        />{" "}
        Hr
        <Box pl="16px">
          <Tooltip
            arrow
            title="The set time applies to all DataApps within the workspace, but if configured within a DataApp, that specific expiry time will take precedence.">
            <InfoIcon fontSize="small" style={{ color: "#9e9e9e" }} />
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};
