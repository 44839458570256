import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";

export type EnvCleanCachePayload = {
  envId: string;
  isDeleteRecent?: boolean;
  isCleanCustomPackages?: boolean;
};

export const useCleanCache = (): UseMutationResult<void, unknown, EnvCleanCachePayload, unknown> =>
  useMutation({
    mutationFn: async (payload: EnvCleanCachePayload) => {
      if (!payload?.envId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () =>
          await api.EnvControllerApi.cleanupCache(
            payload.envId,
            !!payload?.isDeleteRecent,
            !!payload?.isCleanCustomPackages
          )
      );
    }
  });

export default useCleanCache;
