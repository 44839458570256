import React from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { Edit, GetApp } from "@material-ui/icons";

import DataAppTypeIcon from "../../common/DataAppTypeIcon";
import Text from "components/Widget/Text";
import { ConfigIcon } from "src/icons/ConfigIcon";
import { Delete } from "src/icons/Delete";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { deleteDisabled, deleteTooltip } from "../../utils/DataApps.helpers";
import { type DataAppType } from "../../DataApps.type";
import { DataAppDtoDataAppTypeEnum } from "@rapidcanvas/rc-api-core";

export const DataAppTableLinkCell = ({
  row,
  onDelete,
  onConfigOpen,
  onEdit,
  onLogsOpen,
  onDownloadCode
}: {
  row: $TSFixMe;
  onDelete: (data: DataAppType) => void;
  onEdit: (dataApp: DataAppType) => void;
  onConfigOpen: (dataApp: DataAppType) => void;
  onLogsOpen: (dataApp: DataAppType) => void;
  onDownloadCode: (dataApp: DataAppType) => void;
}) => {
  const location = useLocation();

  const dataAppName = row.original.displayName || row.original.name;
  return (
    <LinkCellWithMenu
      url={`${location.pathname === "/" ? "dataapps-ui" : location.pathname}/${row.original.name}`}
      title={dataAppName}
      extra={
        row.original?.appType && (
          <div className="hiddenDiv">
            <DataAppTypeIcon appType={row.original.appType} />
          </div>
        )
      }
      linkText={<Text value={dataAppName} style={{ whiteSpace: "pre" }} />}
      menuButtons={[
        {
          label: "Edit",
          icon: (
            <Typography color="textSecondary">
              <Edit />
            </Typography>
          ),
          action: () => onEdit(row.original)
        },
        {
          label: "Delete",
          icon: (
            <Typography color="textSecondary">
              <Delete />
            </Typography>
          ),
          tooltip: deleteTooltip(row.original?.launchStatus),
          disabled: deleteDisabled(row.original?.launchStatus),
          action: () => onDelete(row.original)
        },
        {
          label: "Logs",
          icon: <LogsNewIconV2 fill="#003656" width="24" />,
          action: () => onLogsOpen(row.original)
        },
        {
          label: "Configs",
          icon: <ConfigIcon />,
          disabled: false,
          action: () => onConfigOpen(row.original)
        },
        ...(row?.original?.dataAppType === DataAppDtoDataAppTypeEnum.Custom
          ? [
              {
                label: "Download Code",
                icon: <GetApp />,
                disabled: false,
                action: () => onDownloadCode(row.original)
              }
            ]
          : [])
      ]}
    />
  );
};
