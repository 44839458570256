import { useEffect } from "react";

// Packages
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";

// Open API
import { UpdateSegmentDto } from "@rapidcanvas/rc-api-core";

// Hooks
import { useUpdateSegment } from "src/hooks/api/segments";

// Contexts
import { useSegmentContext } from "../context/useSegmentContext";

// Constants
import { SegmentFormFields, SegmentHelperText } from "../utils/Segment.constants";

const useUpdateSegmentName = () => {
  const { segmentId } = useParams() || {};

  const { resetForm, setIsSegmentNameSaving } = useSegmentContext();
  const { getValues } = useFormContext();

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading,
    mutateAsync: updateSegmentNameMutation,
    reset: resetUpdateSegmentNameMutation
  } = useUpdateSegment();
  // << ENDS - Query hooks

  const updateSegmentName = async () => {
    const values = getValues();

    if (!segmentId || !values?.[SegmentFormFields.Name]) {
      return;
    }

    await resetUpdateSegmentNameMutation();

    const payload: UpdateSegmentDto = {
      id: segmentId,
      name: values?.[SegmentFormFields.Name]
    };

    await updateSegmentNameMutation(payload, {
      onSuccess: (data) => {
        toastWrapper({ type: ToastTypes.Success, content: SegmentHelperText.SegmentNameUpdated });
        resetForm(data);
      }
    });
  };

  useEffect(() => setIsSegmentNameSaving(() => !!isLoading), [isLoading]);

  return { updateSegmentName };
};

export default useUpdateSegmentName;
