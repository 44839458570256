import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { debounce, map, size, sortBy } from "lodash";
import { makeStyles } from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { useTableContext } from "../../context/useTableContext";
import EventBus from "src/utils/EventBus";
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";
import { toastWrapper } from "src/utils/toastWrapper";

const useStyles = makeStyles((theme) => ({
  search: {
    "& input::placeholder": {
      fontSize: theme.spacing(1.75)
    }
  }
}));

const SearchTable = () => {
  const { table } = useTableContext();
  const classes = useStyles();
  const [value, setValue] = useState(table?.getState()?.globalFilter ?? "");

  const debouncedHandleChange = useCallback(
    debounce((newValue) => {
      table?.setGlobalFilter(newValue);
    }, 300),
    [table]
  );

  useEffect(() => {
    EventBus.publish(EVENTBUS_EVENTS.TableSearchChanged, {
      value: value,
      size: size(table?.getRowModel().rows),
      filteredColumnNames: map(table?.getState()?.columnFilters, "id").join(", ")
    });
  }, [table.getRowModel()]);

  const downloadFilteredRows = () => {
    try {
      const headers = map(
        sortBy(table.getVisibleFlatColumns(), ({ id }) =>
          table?.getState()?.columnOrder?.indexOf(id as string)
        ),
        (column) => `"${String(column.columnDef.header || "").replace(/"/g, '""')}"`
      );

      const rows = table
        .getFilteredRowModel()
        .rows.map((row) =>
          row
            .getVisibleCells()
            .map((cell) => `"${String(cell.getValue() || "").replace(/"/g, '""')}"`)
        );

      let csvContent =
        "data:text/csv;charset=utf-8," + [headers, ...rows].map((e) => e.join(",")).join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "filtered_table_data.csv");
      document.body.appendChild(link);
      link.click();
      EventBus.publish(EVENTBUS_EVENTS.DownloadFilteredTableComplete);
    } catch {
      toastWrapper({
        type: "error",
        content: "Something went wrong!!"
      });
      EventBus.publish(EVENTBUS_EVENTS.DownloadFilteredTableComplete);
    }
  };

  useEffect(() => {
    EventBus.subscribe(EVENTBUS_EVENTS.ClearTableSearch, () => {
      table?.setGlobalFilter("");
      table?.setColumnFilters([]);
      setValue("");
    });
    EventBus.subscribe(EVENTBUS_EVENTS.DownloadFilteredTable, () => {
      downloadFilteredRows();
    });
    return () => {
      EventBus.unsubscribe(EVENTBUS_EVENTS.ClearTableSearch);
      EventBus.unsubscribe(EVENTBUS_EVENTS.DownloadFilteredTable);
    };
  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event?.target?.value;
    setValue(newValue);
    debouncedHandleChange(newValue);
  };

  return (
    <TextField
      id="searchTable"
      className={classes.search}
      variant="outlined"
      size="small"
      placeholder="Search..."
      InputProps={{
        startAdornment: <SearchRoundedIcon fontSize="small" color="action" />,
        style: { height: "28px" }
      }}
      value={value}
      onChange={onChange}
    />
  );
};

export default SearchTable;
