import React, { useMemo } from "react";

import Text from "src/components/Widget/Text";
import { Table } from "src/components";
import { dateFormat } from "src/utils/dateFormat";
import { formTableCells } from "src/components/Table/TableUtils";
import { ArtifactDto } from "@rapidcanvas/rc-api-core";
import {
  getDestinationValList,
  getSourceVal
} from "src/pages/Library/ArtifactsAndModels/Artifacts/ArtifactsTable";

import { Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import _ from "lodash";

const TABLE_SIZE = "small";

export const AddArtifactTable = ({
  data,
  globalFilter,
  selectedRowIds,
  onSelectedRowsChange,
  shouldDisableInitialSelectedRows
}: {
  data: ArtifactDto[];
  selectedRowIds: $TSFixMe;
  globalFilter?: string | undefined;
  // eslint-disable-next-line no-unused-vars
  onSelectedRowsChange: (rows: Array<any>) => void;
  shouldDisableInitialSelectedRows?: boolean;
}) => {
  const getUpdateValue = (artifact: ArtifactDto) =>
    artifact?.fileObjects?.reduce(
      (acc, item) => ((item?.updated as number) > acc! ? item?.updated : acc),
      artifact.updated ?? artifact.created
    );
  const artifacts = useMemo(() => {
    return _.map(data, (artifact) => ({
      ...artifact,
      updated: getUpdateValue(artifact)
    }));
  }, [data]);

  const artifactColumns = React.useMemo(() => {
    const columns = [
      {
        id: "Name",
        accessor: "name",
        Header: "Name",
        isSortable: true,
        Cell: ({ value }: $TSFixMe) => <Text value={value} />
      },
      {
        id: "Updated on",
        accessor: "updated",
        Header: "Updated on",
        isSortable: true,
        Cell: ({ value }: $TSFixMe) => <span>{dateFormat(value, false)}</span>
      },
      {
        id: "Source",
        accessor: "producer",
        Header: (
          <span style={{ fontSize: "14px", gap: "5px", display: "flex", alignItems: "center" }}>
            Source
            <Tooltip arrow title="Recipe where artifact is the output">
              <InfoOutlined color="disabled" fontSize="small" />
            </Tooltip>
          </span>
        ),
        isSortable: true,

        Cell: ({ value }: $TSFixMe) => getSourceVal(value)
      },
      {
        id: "Destinations",
        accessor: "consumers",
        Header: (
          <span style={{ fontSize: "14px", gap: "5px", display: "flex", alignItems: "center" }}>
            Destinations
            <Tooltip arrow title="Recipes where artifact is the input.">
              <InfoOutlined color="disabled" fontSize="small" />
            </Tooltip>
          </span>
        ),
        isSortable: true,

        Cell: ({ value }: $TSFixMe) => getDestinationValList(value)
      }
    ];
    return formTableCells({ inputColumns: columns, size: TABLE_SIZE, isFilterable: true });
  }, []);

  const handleSelectedRowsChange = (rows: any) => {
    onSelectedRowsChange(Object.keys(rows));
  };
  return (
    <Table
      data={artifacts}
      size={TABLE_SIZE}
      columns={artifactColumns}
      isCellSortEnabled
      isSelectable
      orderByDefault="Updated on"
      sortInverted
      maxHeight="calc(100vh - 337px)"
      onSelectedRowsChange={handleSelectedRowsChange}
      globalFilter={globalFilter}
      selectedRowIds={selectedRowIds}
      getRowId={(row: $TSFixMe) => {
        return row.name;
      }}
      emptyTableMessage="No Artifacts"
      shouldDisableInitialSelectedRows={shouldDisableInitialSelectedRows}
      skipPageReset
    />
  );
};
