import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "inherit",
    rowGap: theme.spacing(1)
  },
  messageContainer: {
    margin: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`
  },
  headerContainer: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  tableContainer: {
    flex: 1,
    padding: "0px 8px"
  },
  checkboxAll: {
    "& svg": {
      width: 23,
      height: 23
    }
  },
  breadcrumbContainerWrapper: { display: "flex", alignItems: "flex-start" },
  breadcrumbContainer: {
    margin: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
    "& .breadcrumbText": {
      fontSize: "0.875rem",
      fontWeight: 500,
      "& [class^='MuiAvatar-root']": {
        display: "inline-flex",
        marginLeft: theme.spacing(1),
        width: 20,
        height: 20,
        backgroundColor: "#d9d9d9",
        color: "initial",
        fontSize: "small"
      }
    },
    "& .breadcrumbLink": {
      minWidth: 0,
      padding: 0,
      "& span": {
        textTransform: "none"
      }
    }
  },
  breadcrumbTypeLink: {
    "& span": {
      textTransform: "none"
    }
  },
  breadcrumbTypeText: {
    padding: theme.spacing(1),
    "& svg": { marginRight: theme.spacing(0.5) }
  },
  breadcrumbIcon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },

  // @REFACTOR
  // The below styles are added only to support New UX. It shall be removed soon.
  searchField: {
    "& [class^='MuiInputBase-root']": {
      background: "none",
      border: "1px solid #ccc",
      borderRadius: 4
    },
    "& input": {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: "small"
    }
  }
}));

export default useStyles;
