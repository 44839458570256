import React, { useEffect, useMemo, useState } from "react";

import { generatePath, Link, useSearchParams } from "react-router-dom";

import { Tooltip, Typography } from "@material-ui/core";

import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import { Delete } from "src/icons/Delete";

import { dateFormat, timestampsDiff } from "src/utils/dateFormat";
import { WebPaths } from "src/routing/routes";

import { getJobRunStatusTemplate } from "../../utils/Jobs.helpers";
import { JobRunStatuses, JobRunTriggerTypes, JobsHelperText } from "../../utils/Jobs.constants";

import { Table } from "src/components/Table/Table";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";

import JobRunLogsDrawer from "../JobRunLogs/JobRunLogsDrawer";

import { useStyles } from "./JobRunsTable.styles";
import {
  lastRunEntryId,
  openLogsModal
} from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";
import { ProjectRunEntryDtoStatusEnum } from "@rapidcanvas/rc-api-core";
import { includes } from "lodash";

interface IData {
  jobRunId: string;
  name?: string;
}

const JobRunsTable = (props: $TSFixMe) => {
  const {
    projectId,
    jobId,
    data,
    isRunning,
    isReRunning,
    isDeleting,
    reRunJobRun,
    onDeleteJobRun,
    onViewOutputOpen,
    onJobRunParametersViewOpen,
    hideJobRunCanvas = false,
    hideJobRunParameters = false
  } = props || {};

  const [logsData, setLogsData] = useState<IData | null>(null);
  const [isJobRunning, setIsJobRunning] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const isOpenLogs = searchParams.get(openLogsModal);
  const jobRunIdFromUrl = searchParams.get(lastRunEntryId);

  const classes = useStyles();

  const handleLogsClick = (jobRunId: string, name: string, status: string) => {
    setLogsData({ jobRunId, name });
    setIsJobRunning(
      includes(
        [
          ProjectRunEntryDtoStatusEnum.ComputeVariables,
          ProjectRunEntryDtoStatusEnum.Created,
          ProjectRunEntryDtoStatusEnum.EntityLoading,
          ProjectRunEntryDtoStatusEnum.RecipeRunning,
          ProjectRunEntryDtoStatusEnum.Started
        ],
        status
      )
    );
  };

  useEffect(() => {
    if (isOpenLogs && jobRunIdFromUrl) {
      setLogsData({ jobRunId: jobRunIdFromUrl });
    }
  }, [isOpenLogs, jobRunIdFromUrl]);

  const currentJobRunReRunMessage = (status: string) => {
    if (isRunning(status)) {
      return "Please wait until the in progress run is complete.";
    } else {
      return "";
    }
  };

  const handleClose = () => {
    setLogsData(null);
    setSearchParams({});
  };

  const disabledReRunActionMessage = useMemo(() => {
    if (isDeleting) {
      return "There is another action in progress. Please wait.";
    } else if (isReRunning) {
      return "Re-run action is in progress. Please wait.";
    } else {
      return "";
    }
  }, [isDeleting, isReRunning]);

  const disabledDeleteActionMessage = useMemo(() => {
    if (isReRunning) {
      return "There is another action in progress. Please wait.";
    } else if (isDeleting) {
      return "Delete action is in progress. Please wait.";
    } else {
      return "";
    }
  }, [isReRunning, isDeleting]);

  const columns = useMemo(
    () => [
      {
        id: "Run Name",
        accessor: "runId",
        Header: "Run Name",
        isSortable: true,
        Cell: ({ row }: $TSFixMe) => {
          const { id, runId, status } = row.original || {};

          return (
            <LinkCellWithMenu
              linkText={runId}
              menuButtons={[
                {
                  label: "Re-Run",
                  icon: <PlayArrowOutlinedIcon />,
                  action: () => reRunJobRun(row.original),
                  disabled: !!currentJobRunReRunMessage(status) || !!disabledReRunActionMessage,
                  tooltip:
                    currentJobRunReRunMessage(status) ||
                    disabledReRunActionMessage ||
                    JobsHelperText.JobRunReRunInfo
                },
                {
                  label: "DELETE",
                  icon: <Delete />,
                  action: () => id && onDeleteJobRun(id),
                  disabled: !!disabledDeleteActionMessage,
                  tooltip: disabledDeleteActionMessage
                }
              ]}
            />
          );
        }
      },
      {
        id: "Triggered by",
        accessor: "triggeredBy",
        Header: "Triggered by",
        isSortable: true,
        Cell: ({ row }: $TSFixMe) => {
          const { trigger, creator } = row.original || {};

          return trigger === JobRunTriggerTypes.Scheduler ? "Scheduler" : creator || "N/A";
        }
      },
      {
        id: "Started at",
        accessor: "created",
        Header: "Started at",
        isSortable: true,
        Cell: ({ value }: $TSFixMe) => <span>{dateFormat(value)}</span>
      },
      {
        id: "Runtime",
        accessor: "runtime",
        Header: "Runtime",
        isSortable: true,
        Cell: ({ row }: $TSFixMe) => {
          const { created, endTime } = row.original || {};

          let runtime: $TSFixMe = "";
          if (!!created && !!endTime) {
            const diffMap: $TSFixMe = timestampsDiff(created, endTime);
            // diffMap = { day: #, hour: #, minute: #, second: # };

            if (Object.keys(diffMap || {})?.length > 0) {
              runtime = `${diffMap?.day || 0}d ${diffMap?.hour || 0}h ${diffMap?.minute || 0}m ${
                diffMap?.second || 0
              }s`;
            }
          }

          return <>{runtime || ""}</>;
        }
      },
      {
        id: "Status",
        accessor: "status",
        Header: "Status",
        isSortable: true,
        Cell: ({ row }: $TSFixMe) => {
          const { status } = row.original || {};

          return <>{status ? getJobRunStatusTemplate(status) : "Progress"}</>;
        }
      },
      {
        id: "Output",
        accessor: "output",
        Header: "Output",
        isSortable: false,
        Cell: ({ row }: $TSFixMe) => {
          const { id, status } = row.original || {};

          return !!id ? (
            [
              JobRunStatuses.Success,
              JobRunStatuses.SuccessWithWarn,
              JobRunStatuses.Failure,
              JobRunStatuses.TimedOut,
              JobRunStatuses.RecipeTimedOut
            ].includes(status) ? (
              <button className={classes.link} onClick={() => onViewOutputOpen(row?.original)}>
                Output
              </button>
            ) : (
              <Tooltip title="The run is currently in progress. Please check the output once the execution is complete.">
                <span className={classes.linkDisabled}>View</span>
              </Tooltip>
            )
          ) : (
            "N/A"
          );
        }
      },
      {
        id: "Log",
        accessor: "log",
        Header: "Log",
        isSortable: false,
        Cell: ({ row }: $TSFixMe) => {
          const { id, status, runId } = row.original || {};

          return !!id ? (
            ![JobRunStatuses.Created].includes(status) ? (
              <button className={classes.link} onClick={() => handleLogsClick(id, runId, status)}>
                View
              </button>
            ) : (
              <Tooltip title="Kindly wait for the run to start before checking the logs.">
                <span className={classes.linkDisabled}>View</span>
              </Tooltip>
            )
          ) : (
            "N/A"
          );
        }
      },
      ...(!hideJobRunCanvas
        ? [
            {
              id: "Canvas",
              accessor: "canvas",
              Header: "Canvas",
              isSortable: false,
              Cell: ({ row }: $TSFixMe) => {
                const { id, status } = row?.original || {};

                // eslint-disable-next-line no-extra-boolean-cast
                return !!projectId && !!jobId && !!id ? (
                  ![
                    JobRunStatuses.Created,
                    JobRunStatuses.Started,
                    JobRunStatuses.EntityLoading,
                    JobRunStatuses.Running
                  ].includes(status) ? (
                    <Link
                      className={classes.link}
                      to={generatePath(`${WebPaths.JobRoutes}${WebPaths.JobCanvas}`, {
                        projectId,
                        jobId,
                        jobRunId: id
                      })}>
                      <Typography variant="subtitle2">Canvas</Typography>
                    </Link>
                  ) : (
                    <Tooltip title="Kindly wait for the run to complete before visiting the canvas.">
                      <span className={classes.linkDisabled}>Canvas</span>
                    </Tooltip>
                  )
                ) : (
                  "N/A"
                );
              }
            }
          ]
        : []),
      ...(!hideJobRunParameters
        ? [
            {
              id: "Global Variables",
              accessor: "parameters",
              Header: "Global Variables",
              isSortable: false,
              Cell: ({ row }: $TSFixMe) => {
                const { id, status } = row.original || {};

                return !!id ? (
                  ![
                    JobRunStatuses.Created,
                    JobRunStatuses.Started,
                    JobRunStatuses.EntityLoading,
                    JobRunStatuses.Running
                  ].includes(status) ? (
                    <button
                      className={classes.link}
                      onClick={() => onJobRunParametersViewOpen(row?.original)}>
                      View
                    </button>
                  ) : (
                    <Tooltip title="Kindly wait for the run to get completed.">
                      <span className={classes.linkDisabled}>View</span>
                    </Tooltip>
                  )
                ) : (
                  "N/A"
                );
              }
            }
          ]
        : [])
    ],
    [projectId, jobId, disabledReRunActionMessage, disabledDeleteActionMessage]
  );

  return (
    <>
      <Table
        data={data}
        size="small"
        columns={columns}
        isCellSortEnabled
        orderByDefault="Started at"
        maxHeight="calc(100vh - 128px)"
      />
      {logsData && (
        <JobRunLogsDrawer
          isJobRunning={isJobRunning}
          name={logsData?.name ?? ""}
          open={!!logsData}
          projectId={projectId}
          jobId={jobId}
          jobRunId={logsData?.jobRunId}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default JobRunsTable;
