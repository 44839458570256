import React, { useEffect, useState } from "react";
import shallow from "zustand/shallow";
import { useParams } from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";

import { Tabs, Tab, TabPanel } from "src/components";

import { handleResponse } from "../../utils/apiService";
import useTenantManagementStore from "../../stores/tenant-management.store";
import { TenantSettings } from "./TenantSettings/TenantSettings";
import useAuthStore from "src/stores/auth.store";
import { TenantAdminSettings } from "./TenantAdminSettings/TenantAdminSettings";
import { dateDiffFromNow } from "src/utils/dateFormat";
import { useFetchCurrentTenant } from "src/hooks/api";
import { isEmpty } from "lodash";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles((theme) => ({
  // @REFACTOR
  // The below fixed-position should be removed.
  wrapperContainer: {
    position: "fixed",
    padding: theme.spacing(2),
    rowGap: theme.spacing(2)
  },
  tenantManagementWrap: {
    width: "100%"
  }
}));

type TabValue = "tenant" | "admin";

const TenantManagement = () => {
  const styles = useStyles();
  const { tenantId } = useParams<$TSFixMe>();
  const firstUpdate = React.useRef(true);
  const [tabValue, setTabValue] = useState<TabValue>("tenant");
  const [tenantDetails, shouldTenantsRefresh, userTenants, setShouldTenantsRefresh] =
    useTenantManagementStore(
      (state) => [
        state.selectedTenant,
        state.shouldTenantsRefresh,
        state.userTenants,
        state.setShouldTenantsRefresh
      ],
      shallow
    );

  const currentTenant = userTenants?.find((tenant: any) => tenant.id === tenantId);
  const [hasPrivileges, licenseEnabled] = useAuthStore((state) => [
    state.hasPrivileges,
    state.licenseEnabled
  ]);

  const { isLoading, refetch } = useFetchCurrentTenant();

  useEffect(() => {
    //License Expiry Notification
    if (firstUpdate.current && tenantDetails.name) {
      var durationTillExpiry = dateDiffFromNow(
        (tenantDetails?.licenseDetails as $TSFixMe)?.expiryTime,
        true,
        "days"
      );
      if (durationTillExpiry && durationTillExpiry > 0 && durationTillExpiry <= 30) {
        handleResponse({
          errorMessage: `Your license will expire in ${durationTillExpiry} day(s). Please contact support to extend usage.`
        });
      }
      firstUpdate.current = false;
    }
  }, [tenantDetails]);

  useEffect(() => {
    shouldTenantsRefresh &&
      refetch().finally(() => {
        setShouldTenantsRefresh(false);
      });
  }, [shouldTenantsRefresh]);

  const handleTabChange = (newValue: TabValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid
      container
      className={`${styles.wrapperContainer} ${styles.tenantManagementWrap} container-height-no-subtopbar`}>
      {isLoading ? (
        <CommonLoader />
      ) : (
        <>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab value="tenant" label="Workspace Settings" />
            {hasPrivileges && <Tab value="admin" label="Admin Settings" />}
          </Tabs>
          <TabPanel
            value="tenant"
            index={tabValue}
            className={styles.tenantManagementWrap}
            boxProps={{ height: "100%", width: "100%" }}>
            <TenantSettings tenantDetails={tenantDetails} currentTenant={currentTenant} />
          </TabPanel>
          {!isEmpty(tenantDetails.licenseDetails) && (
            <TabPanel
              value="admin"
              index={tabValue}
              className={styles.tenantManagementWrap}
              boxProps={{ height: "100%", width: "100%" }}>
              <TenantAdminSettings
                licenseEnabled={licenseEnabled}
                switchTab={() => setTabValue("tenant")}
                licenseDetails={tenantDetails.licenseDetails}
              />
            </TabPanel>
          )}
        </>
      )}
    </Grid>
  );
};

export default TenantManagement;
