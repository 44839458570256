import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";
import { Data, TrySegmentDto } from "@rapidcanvas/rc-api-core";

import { areAllKeysPresentAndNotNil } from "src/utils/helpers";

const useTestSegment = (): UseMutationResult<Data, unknown, TrySegmentDto, unknown> =>
  useMutation({
    mutationFn: async (payload: TrySegmentDto) => {
      if (!areAllKeysPresentAndNotNil(payload, ["entityId", "condition"])) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.SegmentControllerApi.trySegment({ ...payload })
      );
    }
  });

export default useTestSegment;
