import React, { useMemo, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import _, { size, trim } from "lodash";
import { Grid, makeStyles, TextField, InputAdornment, Button } from "@material-ui/core";

import { EntityFeaturesResponse } from "src/types/Entity.type";

import { OverflowTooltip, Table } from "src/components";
import { Modal } from "src/components/custom";

interface IProps {
  dataset: any;
  entityFeaturesMap: EntityFeaturesResponse;
}

const useStyles = makeStyles({
  entity: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    padding: "4px 6px",
    width: "inherit",
    justifyContent: "space-between",
    borderBottom: "0.5px solid #D1D1D1",
    height: "35px"
  },
  entityText: {
    fontWeight: 400,
    fontSize: "14px",
    width: "180px",
    whiteSpace: "nowrap"
  },
  tableWrap: {
    padding: "1px",
    overflow: "auto",
    maxWidth: "500px"
  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: "6px"
  },
  header: {
    padding: "10px 26px",
    borderBottom: "0.5px solid #D1D1D1",
    fontWeight: 600,
    maxWidth: "350px"
  },
  search: {
    color: "#7c7c7c",
    minHeight: "34px",
    height: "100%",
    margin: "0px",

    "& div[class*='MuiInputBase-root']": {
      height: "34px",
      width: "100%",
      color: "#7c7c7c"
    },
    "& input[class*='MuiInputBase-input']": {
      height: "34px",
      fontSize: "14px",
      width: "100%"
    }
  }
});

const Schema: React.FC<IProps> = (props) => {
  const { entityFeaturesMap, dataset } = props;
  const classes = useStyles();

  const columns = useMemo(() => {
    return [
      {
        id: `Column Names(${size(entityFeaturesMap)})`,
        accessor: "name",
        Header: `Column Names(${size(entityFeaturesMap)})`,
        flex: 1
      },
      {
        id: "Datatype",
        accessor: "fieldSchema.rcDataType",
        Header: "Datatype",
        flex: 1
      }
    ];
  }, [entityFeaturesMap]);

  const [open, setOpen] = useState(false);

  const [value, setValue] = useState("");

  const data = useMemo(() => {
    return _.filter(
      entityFeaturesMap,
      ({ name, fieldSchema: { rcDataType } }) =>
        _.includes(_.toLower(name), _.toLower(trim(value))) ||
        _.includes(_.toLower(rcDataType), _.toLower(trim(value)))
    );
  }, [value, entityFeaturesMap]);

  const handleSearch = (val: string) => {
    setValue(val);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleOpen}
        // style={{
        //   position: "absolute",
        //   top: -34,
        //   right: 270
        // }}
      >
        Schema
      </Button>
      <Modal
        title={
          <Grid container justifyContent="space-between" style={{ width: "calc(100% - 30px)" }}>
            <span style={{ width: "calc(100% - 214px)" }}>
              <OverflowTooltip value={dataset.name} />
            </span>
            <TextField
              onChange={(e) => handleSearch(e.target.value)}
              InputProps={{
                onKeyDown: (e) => e.stopPropagation(),
                startAdornment: (
                  <InputAdornment position="start" data-testid="searchEntityIcon">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
                type: "search"
              }}
              data-testid="searchEntityColumn"
              placeholder="Search"
              className={classes.search}
              id="column-search-input"
              name="column-search-input"
              variant="outlined"
            />
          </Grid>
        }
        open={open}
        hideCancelAction
        contentStyle={{ background: "#f2f2f2" }}
        hideSubmitAction
        onClose={() => setOpen(false)}>
        <Table
          data={data}
          columns={columns}
          hideCount
          hideSettings
          size="small"
          maxHeight="calc(100vh - 200px)"
          unsorted
        />
      </Modal>
    </>
  );
};

export default React.memo(Schema);
