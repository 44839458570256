import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

// Packages
import { isArray } from "lodash";

// Open API
import api from "utils/AxiosClient";
import { SegmentDto } from "@rapidcanvas/rc-api-core";

export const enum UseGetSegmentQueryKeys {
  Segment = "segment"
}

interface ISegmentProps extends UseQueryOptions<unknown, unknown, SegmentDto> {
  segmentId?: string;
}

const useGetSegment = (props: ISegmentProps): UseQueryResult<SegmentDto> => {
  const { segmentId, ...options } = props;

  return useQuery({
    queryKey: [UseGetSegmentQueryKeys.Segment],
    queryFn: async () => {
      if (!segmentId) {
        throw "segmentId is null!";
      }

      return await api.fetchResponse(async () => await api.SegmentControllerApi.get1(segmentId));
    },
    onSuccess: (data) => (isArray(data) ? data[0] : {}),
    enabled: !!segmentId,
    ...options
  });
};

export default useGetSegment;
