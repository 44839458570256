import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";
import useAddPredictionService from "src/hooks/useAddPredictionService";
import { DEFAULT_NAME, EnvironmentsTypes } from "src/constants/environments.constants";
import { DEFAULT_PRE_POST_PROCESS_CODE } from "pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.constants";
import { QUERY_KEY_DATA_APPS } from "src/hooks/api/dataapps/useGetDataApps";
import { getFormattedPythonCode } from "pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.helpers";
import {
  DataappAskAIConfig,
  DataAppDto,
  EnvDto,
  CreateDataAppRequestDtoDataAppTypeEnum
} from "@rapidcanvas/rc-api-core";

type Props = {
  dataAppName: string;
  appTemplateId: string;
  projectId: string;
  showAICode?: boolean;
  recipeId?: string;
  description: string;
  isPrivate?: boolean;
  memInMbs?: number;
  diskInGbs?: number;
  cores?: number;
  metadata?: {
    [key: string]: string;
  };
  envType: EnvironmentsTypes;
  iconUrl?: string;
  dataAppType: CreateDataAppRequestDtoDataAppTypeEnum;
  askAIConfig?: DataappAskAIConfig;
  onSuccess: (response: DataAppDto) => void;
};

export const useCreateDataAppMutation = (): UseMutationResult<
  DataAppDto,
  unknown,
  Props,
  unknown
> => {
  const queryClient = useQueryClient();
  const addPredictionService = useAddPredictionService();

  return useMutation({
    mutationFn: async ({
      dataAppName,
      appTemplateId,
      projectId,
      recipeId,
      description,
      metadata,
      askAIConfig,
      showAICode,
      dataAppType,
      isPrivate,
      memInMbs,
      diskInGbs,
      cores,
      onSuccess,
      iconUrl,
      envType
    }) => {
      const response = await api.fetchResponse(
        async () =>
          await api.DataAppControllerApi.createDataApp({
            name: dataAppName,
            displayName: dataAppName,
            appTemplateId,
            envType,
            projectId,
            description,
            params: {},
            iconUrl,
            groupId: recipeId,
            metadata,
            askAIConfig,
            dataAppType,
            isPrivate,
            showAICode,
            diskInGbs,
            cores,
            memInMbs
          })
      );

      try {
        await api.fetchResponse(
          async () => await api.DataAppControllerApi.launch(response.id as string)
        );
        return response;
      } catch {
        return response;
      } finally {
        await queryClient.invalidateQueries([QUERY_KEY_DATA_APPS]);
        onSuccess(response);
      }
    },
    onSuccess: async (data: DataAppDto) => {
      // Default create Prediction Service
      const { modelName, predictionSvcName, shouldCreatePredictionSvc } =
        data?.predictionSvcMetadata ?? {};
      if (modelName && predictionSvcName && shouldCreatePredictionSvc) {
        const response = await api.fetchResponse(async () => await api.EnvControllerApi.findEnvs());
        const defaultEnv = response?.find(
          (env: EnvDto) => env?.name === DEFAULT_NAME && env?.defaultFlag
        );

        if (defaultEnv) {
          const formattedPythonCode = getFormattedPythonCode(
            DEFAULT_PRE_POST_PROCESS_CODE,
            predictionSvcName
          );
          const pythonFile = new File([formattedPythonCode], `${predictionSvcName}.py`, {
            type: "text/plain"
          });
          const payload = {
            modelName,
            name: predictionSvcName,
            displayName: predictionSvcName,
            envId: defaultEnv?.id,
            description: "",
            file: pythonFile,
            shouldDispatchEvent: false
          };
          addPredictionService.mutate(payload);
        }
      }
    }
  });
};
