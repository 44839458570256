import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  at,
  constant,
  delay,
  dropRight,
  filter,
  flatMap,
  forEach,
  get,
  has,
  includes,
  indexOf,
  isEmpty,
  map,
  size,
  some,
  times
} from "lodash";
import { Typography, Box, makeStyles, Grid, Paper, Button } from "@material-ui/core";
import { useNavigate, useLocation, useParams, generatePath } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDefTemplate, HeaderContext } from "@tanstack/react-table";

import ComponentNotFound from "components/Errors/ComponentNotFound";
import Eda from "../private/ProjectsModule/pages/Eda/Eda";
// import ViewDataData from "./ViewDataData/ViewDataData";
import TableData from "./TableData/TableData";
import ViewDataHeader from "./ViewDataHeader";
import ViewDataSchema from "./ViewDataSchema/ViewDataSchema";
import Segments from "../private/ProjectsModule/pages/ViewDataset/Segments/Segments";
import Source from "./Source/Source";
// @ts-ignore
import useEntityDataAndStats, {
  UseGetDatasetDataInfiniteQueryKeys,
  useEntityDataCustomColumns,
  // SSR change
  useEntityDataInfinite
} from "hooks/api/projects/useEntityDataAndStats";
import AdvanceAnalysis from "../private/ProjectsModule/pages/Eda/AdvanceAnalysis";
import useEntities from "hooks/api/entities/useEntities";
import useEntityDetails from "hooks/api/entities/useEntityDetails";
import {
  getLocalStorageItem,
  setLocalStorageItem
} from "src/services/LocalStorage/LocalStorage.service";
import { DataAnalysisIcon } from "src/icons/NewUX/DataAnalysisIcon";
import { SourceIcon } from "src/icons/NewUX";
import { PreviewIconNew } from "src/icons/PreviewIconNew";
import { Roles } from "src/types";
// import { SchemaIcon } from "src/icons/NewUX/SchemaIcon";
import { SegmentsIcon } from "src/icons/NewUX/SegmentsIcon";
import { Tabs, Tab, SearchField } from "src/components";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";
import { toastWrapper } from "src/utils/toastWrapper";
import { useGetJob, useGetJobRun } from "src/hooks/api";
import useAuthStore from "src/stores/auth.store";
import { useGetRole } from "src/hooks/useGetRole";
import { useGetScenario } from "src/hooks/api";
import { useScenariosStore } from "../../store/store";
// SSR change
import { getColumnsFromStorage } from "src/components/custom/Table/utils/Table.helpers";
// @ts-ignore
import { TableConfig } from "./TableData/TableData.constants";
import { WebPaths } from "src/routing/routes";
import { TableSessionConfig } from "src/constants/table.constants";
import { Skeleton } from "@material-ui/lab";
import { useSetAIGuideMessagesCount } from "../Projects/common/hooks/useSetAIGuideMessagesCount";
import CommonLoader from "src/components/CommonLoader";
import EventBus from "src/utils/EventBus";
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";

export enum Section {
  data = "data",
  schema = "schema",
  segments = "segments",
  analysis = "analysis",
  advanceAnalysis = "advanceAnalysis",
  source = "source"
}

const useStyles = makeStyles({
  viewDataWrap: {
    background: "#f5f5f5",
    padding: "20px",
    height: "calc(100vh - 94px)"
  },
  viewDataContentContainer: {
    // New UX change
    // The value 139px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px + Tabs 45px).
    height: "calc(100vh - 139px)",
    overflowY: "auto"
  },
  viewDataTableOptions: {
    flexBasis: "50%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "absolute",
    right: 0
  },
  tabHeader: {
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: ({ hidePadding }: any) => (hidePadding ? undefined : "20px")
  },
  dataTableHeader: {
    height: "72px",
    padding: "24px",
    background: "#fff",
    borderBottom: "1px solid #0000001a",
    borderRadius: "6px 6px 0px 0px"
  },
  dataTable: {
    borderRadius: "4px"
  },
  searchField: {
    backgroundColor: "white",
    borderRadius: 32,
    border: "1px solid #ccc",
    "& button": {
      marginRight: "2px !important",
      "& svg": {
        fontSize: 12
      }
    },
    "& input": {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: "small"
    }
  }
});

const allSections = [
  {
    title: "Data",
    value: Section.data as string,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    icon: <PreviewIconNew />
  },
  // {
  //   title: "Schema",
  //   value: Section.schema as string,
  //   allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
  //   icon: <SchemaIcon />
  // },
  {
    title: "Segments",
    value: Section.segments as string,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name],
    icon: <SegmentsIcon />
  },
  {
    title: "Data Analysis",
    value: Section.analysis as string,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    icon: <DataAnalysisIcon />
  },
  {
    title: "Source",
    value: Section.source as string,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    icon: <SourceIcon viewBox="0 0 20 20" />
  }
];

const ViewDataRoutes = (props: $TSFixMe) => {
  const { jobProps } = props || {};

  const { section, projectId, entityId, scenarioId, jobId, jobRunId } = useParams<$TSFixMe>();
  const classes = useStyles({ hidePadding: section === Section.advanceAnalysis });

  const userId = useAuthStore((state) => state.userId);

  const { canAccessWithRole } = useGetRole();
  const queryClient = useQueryClient();

  useSetAIGuideMessagesCount({ projectId: projectId! });

  // States - STARTS >>
  const [isFetchNextPageInitiated, setIsFetchNextPageInitiated] = useState(false);
  // << ENDS - States

  // SSR changes - STARTS >>
  const [viewDataData, setViewDataData] = useState<{ columns: $TSFixMe[]; rows: $TSFixMe[] }>({
    columns: [],
    rows: []
  });
  const [viewDataEntityFeatures, setViewDataEntityFeatures] = useState<string[]>([]);
  // << ENDS - SSR changes

  // const { data, isLoading, refetch, isError } = useEntityDataAndStats({
  //   projectId,
  //   entityId,
  //   scenarioId,
  //   jobRunId,
  //   payload: {
  //     rowsStart: TableConfig.DefaultStartingRowIndex,
  //     rowsEnd: TableConfig.DefaultEndingRowIndex
  //   }
  // });

  // SSR changes - STARTS >>
  // Mapping column index for input and base data
  const mapColumns = (columns: $TSFixMe[]) => {
    return columns?.reduce((acc, col, index) => {
      acc[col] = index;
      return acc;
    }, {});
  };

  const checkForError = async (data: $TSFixMe) => {
    const isError = some(data?.pages?.[0]?.messages, (item) =>
      some(item, (value) => value === "ERROR")
    );

    if (!!isError) {
      setViewDataEntityFeatures(() => []);

      setViewDataData(() => ({
        columns: [],
        rows: []
      }));

      if (!!userId && !!entityId) {
        const userPreferencesInLocalStorage =
          getLocalStorageItem({ key: TableSessionConfig.TablePreferencesSessionKey }) || {};

        if (!isEmpty(userPreferencesInLocalStorage?.[userId]?.[entityId]?.columnVisibility)) {
          userPreferencesInLocalStorage[userId][entityId].columnVisibility = {};

          setLocalStorageItem({
            key: TableSessionConfig.TablePreferencesSessionKey,
            data: userPreferencesInLocalStorage
          });

          await queryClient.invalidateQueries([
            UseGetDatasetDataInfiniteQueryKeys.InfiniteDatasetData
          ]);

          return { isError: true };
        }
      }
    }

    return { isError: false };
  };

  const saveLastPageIndex = (lastPageIndex: number) => {
    if (!!isJobPath || !userId || !entityId) {
      return;
    }

    const userPreferencesInLocalStorage =
      getLocalStorageItem({ key: TableSessionConfig.TablePreferencesSessionKey }) || {};

    let thisUserPreferences: $TSFixMe = userPreferencesInLocalStorage || {};

    if (!has(userPreferencesInLocalStorage, userId)) {
      thisUserPreferences[userId] = {};
    }

    if (!has(thisUserPreferences[userId], entityId)) {
      // @ts-ignore
      thisUserPreferences[userId][entityId] = {};
    }

    thisUserPreferences[userId][entityId]["lastPageIndex"] = lastPageIndex ?? 0;

    setLocalStorageItem({
      key: TableSessionConfig.TablePreferencesSessionKey,
      data: thisUserPreferences
    });
  };

  const onSucceedDatasetDataFetch = async (data: $TSFixMe) => {
    const lastPage = (data?.pages?.length ?? 0) - 1;
    saveLastPageIndex(lastPage);

    const { isError } = await checkForError(data);

    if (isError) {
      return;
    }

    setViewDataEntityFeatures(() => data?.pages?.[0]?.entityFeatures);
    const columnsFromStorage = getColumnsFromStorage({ userId, datasetId: entityId });

    if (size(viewDataData?.columns) === 0 || size(columnsFromStorage) === 0) {
      const rows = flatMap(data?.pages, (page) => page?.viewData?.rows) ?? [];

      setViewDataData(() => ({
        columns: data?.pages?.[0].viewData?.columns ?? [],
        rows
      }));
    } else {
      let outputRows: { cells: string[] }[] = [];

      // Process each page in input data
      forEach(data.pages, (page) => {
        const inputColumnsMap = mapColumns(page?.viewData?.columns);

        forEach(page?.viewData?.rows, (row) => {
          const baseRow = viewDataData?.rows?.[outputRows?.length || 0];
          const baseColumnsMap = mapColumns(viewDataData?.columns);

          // Create new row cells by merging input and base cells
          const newRowCells: string[] = viewDataData?.columns?.map((col) => {
            if (inputColumnsMap?.[col] !== undefined) {
              return row?.cells?.[inputColumnsMap[col]];
            } else if (baseColumnsMap?.[col] !== undefined) {
              return baseRow?.cells?.[baseColumnsMap?.[col]];
            }
            return null;
          });

          outputRows.push({ cells: newRowCells });
        });
      });

      // Find the indices of the columns in columnsOrder that match the columns in columnsFromStorage
      const indices = map(columnsFromStorage, (col) => indexOf(viewDataData?.columns, col));

      // Filter the rows in data to only include the cells at the found indices
      const filteredRows = map(outputRows, (row) => ({
        cells: at(row.cells, indices)
      }));

      setViewDataData(() => ({
        columns: columnsFromStorage || [],
        rows: filteredRows
      }));
    }

    setIsFetchNextPageInitiated(() => false);
  };

  const {
    fetchNextPage,
    // Need to check use-cases of the below
    isFetching,
    isLoading
    // refetch,
    // isError
  } = useEntityDataInfinite({
    projectId,
    entityId,
    scenarioId,
    jobRunId,
    payload: {
      columnLimit: TableConfig.maxColumnsLimitFromBackEnd
    },
    onSuccess: onSucceedDatasetDataFetch,
    enabled: false
  });

  const {
    isLoading: isFetchingSingleColumn,
    mutateAsync: datasetDataCustomColumnsMutation,
    reset: resetDatasetDataCustomColumnsMutation
  } = useEntityDataCustomColumns({
    onMutate: (payload) => {
      setViewDataData(() => ({
        columns: [...(viewDataData?.columns || []), ...(payload?.payload?.cols ?? [])],
        rows: viewDataData?.rows?.map((row: any) => ({
          cells: [
            ...(row?.cells || []),
            ...times(size(payload?.payload?.cols), constant(<Skeleton />))
          ]
        }))
      }));
    },
    onSuccess: (datasetDataResponse, payload) => {
      setViewDataData(() => ({
        columns: [
          ...dropRight(viewDataData?.columns || [], size(payload?.payload?.cols)),
          ...(datasetDataResponse?.data?.columns ?? [])
        ],
        rows: viewDataData?.rows?.map((row: any, index: number) => ({
          cells: [
            ...dropRight(row?.cells || [], size(payload?.payload?.cols)),
            ...(datasetDataResponse?.data?.rows?.[index]?.cells || [])
          ]
        }))
      }));
    },
    onError: (__, payload) => {
      setViewDataData(() => ({
        columns: [...dropRight(viewDataData?.columns || [], size(payload?.payload?.cols))],
        rows: viewDataData?.rows?.map((row: any) => ({
          cells: [...dropRight(row?.cells || [], size(payload?.payload?.cols))]
        }))
      }));
    }
  });

  useEffect(() => {
    const fetchPreviousPages = async () => {
      for (let i = 0; i <= lastPageIndex; i++) {
        await fetchNextPage();
      }
    };

    const userPreferencesInLocalStorage =
      getLocalStorageItem({ key: TableSessionConfig.TablePreferencesSessionKey }) || {};

    let lastPageIndex = 0;
    if (!!userId && !!entityId) {
      lastPageIndex = userPreferencesInLocalStorage?.[userId]?.[entityId]?.["lastPageIndex"];
    }

    lastPageIndex > 0 ? fetchPreviousPages() : fetchNextPage();
  }, []);
  // << ENDS - SSR changes

  // const viewDataEntityFeatures = data?.entityFeatures ?? [];

  const navigate = useNavigate();

  const location = useLocation();

  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);
  const entityDetailsResponse = useEntityDetails(
    entityId,
    scenarioId,
    isJobPath ? jobRunId : undefined,
    {
      refetchOnMount: true
    }
  );

  const { data: entity, isLoading: isEntityLoading } = useEntities({
    id: entityId!,
    projectId,
    scenarioId,
    jobRunId,
    options: {
      onError: () => {
        toastWrapper({
          type: "error",
          content: "No entity was found"
        });
      }
    }
  });

  const sections = useMemo(() => {
    return allSections.filter((section) => {
      if (section.value === Section.segments) {
        return canAccessWithRole(section.allowedRoles) && entityDetailsResponse.data?.rootEntity;
      } else if (section?.value === Section.source) {
        return !!entityDetailsResponse.data?.rootEntity;
      }

      return canAccessWithRole(section.allowedRoles);
    });
  }, [allSections, entityDetailsResponse.data?.rootEntity]);

  const sectionValues = useMemo(() => sections.map((sec) => sec.value), [sections]);
  const [tabValue, setTabValue] = useState(sectionValues.indexOf(section!));

  const [isAddSegmentOpen, setIsAddSegmentOpen] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [isAddSegmentAction] = useState(true);

  const [filterDetails, setFilterDetails] = useState<any>({});

  const scenarios = useScenariosStore((state) => state.scenarios);

  // Query hooks - STARTS >>
  const { data: jobData } = useGetJob({ projectId, jobId });
  const { data: jobRunData } = useGetJobRun({ jobRunId, isApiWithRethrow: false });
  // << ENDS - Query hooks

  const segmentsIndex = useMemo(() => sectionValues?.indexOf(Section.segments), [sections]);

  useEffect(() => {
    if (section) {
      setTabValue(sectionValues.indexOf(section));
    }
  }, [section, sectionValues]);

  useEffect(() => {
    EventBus.subscribe(EVENTBUS_EVENTS.TableSearchChanged, (payload: any) => {
      setFilterDetails(() => payload ?? {});
    });

    return () => {
      EventBus.unsubscribe(EVENTBUS_EVENTS.TableSearchChanged);
    };
  }, []);

  const { data: scenarioData } = useGetScenario({
    scenarioId,
    onError: () => {
      toastWrapper({
        type: "error",
        content: "No scenario was found"
      });
    }
  });

  const handleTabChange = (newValue: number) => {
    const shouldResetShouldAddSegment = newValue !== segmentsIndex;
    shouldResetShouldAddSegment && setIsAddSegmentOpen(false);
    setTabValue(newValue);

    let path = generatePath(WebPaths.ViewData, {
      projectId: projectId as string,
      entityId: entityId as string,
      scenarioId: scenarioId as string,
      section: get(sectionValues, newValue)
    });

    if (jobProps?.path) {
      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`, {
        projectId: projectId as string,
        entityId: entityId as string,
        jobId: jobId as string,
        jobRunId: jobRunId as string,
        scenarioId: scenarioId as string,
        section: get(sectionValues, newValue)
      });
    }

    // if (isError || isDatasetDataError) {
    //   refetch();
    //   refetchDatasetData();
    // }
    navigate(path, { replace: true });
  };

  const handleOpenSegmentSection = () => {
    // setIsAddSegmentOpen(!isAddSegmentOpen);
    // if (tabValue !== segmentsIndex) handleTabChange(segmentsIndex);

    if (!projectId || !scenarioId || !entityId) {
      return;
    }

    navigate(
      generatePath(`${WebPaths.Segment}`, {
        projectId,
        scenarioId,
        entityId,
        segmentId: ""
      })
    );
  };

  const onSearch = ({ target: { value } }: $TSFixMe) => {
    setSearchValue(value);
  };

  const handleColumnChange = async (
    columnNames: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]
  ) => {
    const newColumns = filter(columnNames, (col) => !includes(viewDataData?.columns, col));

    if (isEmpty(newColumns)) {
      return;
    }

    await resetDatasetDataCustomColumnsMutation();

    const payload = {
      entityId,
      payload: {
        scenarioId: scenarioId!,
        rowsStart: 0,
        rowsEnd: size(viewDataData?.rows),
        cols: newColumns
      }
    };

    await datasetDataCustomColumnsMutation(payload);
  };

  const filteredEntityFeatures = useMemo(() => {
    const entityDataFormatted = viewDataEntityFeatures.filter((feature: any) => {
      return Object.values(feature).some((featureValue: $TSFixMe) => {
        const isFeatureValueObject =
          typeof featureValue === "object" && !Array.isArray(featureValue) && featureValue !== null;

        if (isFeatureValueObject) {
          return Object.values(featureValue).some((value: $TSFixMe) =>
            JSON.stringify(value)?.toLowerCase?.().includes?.(searchValue)
          );
        } else if (!isNaN(featureValue)) {
          return featureValue === searchValue;
        } else {
          return featureValue?.includes(searchValue);
        }
      });
    });

    return entityDataFormatted;
  }, [searchValue, viewDataEntityFeatures, viewDataData]);

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(scenarioData ?? null, scenarios, scenarioId),
    [scenarioData, scenarios, scenarioId]
  );

  const allColumns = useMemo(
    () => [...(viewDataEntityFeatures?.map((item: $TSFixMe) => item?.name) || [])],
    [viewDataEntityFeatures]
  );

  const checkFetchNextPage: $TSFixMe = useCallback(() => {
    setIsFetchNextPageInitiated(() => true);

    if (!isFetchingSingleColumn) {
      // Delaying a second for local-storage gets updated with newer columns, by previously executed data-api.
      // The fetchNextPage() is highly dependent upon columns in local-storage.
      delay(async () => {
        await fetchNextPage?.();
      }, 1000);
    }
  }, [isFetchingSingleColumn]);

  const getChildComponent = () => {
    switch (section) {
      case Section.data:
        return isEntityLoading ||
          !entity ||
          entityDetailsResponse.isLoading ||
          (isEmpty(viewDataData.columns) && isEmpty(viewDataData.rows)) ? (
          <CommonLoader />
        ) : (
          <Paper elevation={0} style={{ borderRadius: 12 }}>
            <Grid container direction="column" wrap="nowrap" className={classes.dataTable}>
              <Grid item>
                <TableData
                  isJobPath={isJobPath}
                  userId={userId}
                  datasetId={entityId}
                  datasetName={entity?.displayName}
                  isLoading={isLoading}
                  data={viewDataData}
                  schema={viewDataEntityFeatures}
                  allColumns={allColumns}
                  showSettingIconOutside
                  toggleColumns={handleColumnChange}
                  // SSR changes - STARTS >>
                  serverSideRenderingProps={{
                    isLoading: isLoading || isFetching,
                    isFetching: isFetching || isFetchNextPageInitiated,
                    isFetchingSingleColumn,
                    fetchNextPage: checkFetchNextPage,
                    totalNoOfRowsAtServerSide: entityDetailsResponse.data?.rows || 0,
                    totalNoOfRowsFetched: size(viewDataData?.rows || [])
                  }}
                  // << ENDS - SSR changes
                />
                {/* <Box maxHeight="60vh">
                <ViewDataData
                  isLoadingData={isLoading}
                  responseData={{ ...viewDataData, rows: searchedViewDataDataRows }}
                  entityFeatures={viewDataEntityFeatures}
                  onColumnChange={handleColumnChange}
                  maxHeight="60vh"
                />
              </Box> */}
              </Grid>
            </Grid>
          </Paper>
        );

      case Section.schema:
        return <ViewDataSchema isLoading={isLoading} entityFeatures={filteredEntityFeatures} />;

      case Section.segments:
        return <Segments />;

      case Section.analysis:
        return <Eda />;

      case Section.advanceAnalysis:
        return <AdvanceAnalysis title={entity?.displayName} />;

      case Section.source:
        return (
          <Source
            userId={userId}
            isJobPath={isJobPath}
            isDefaultScenario={isDefaultScenario}
            dataset={entity}
            isLoading={isEntityLoading}
          />
        );

      default:
        return <ComponentNotFound />;
    }
  };

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.viewDataWrap}>
      <ViewDataHeader
        entityDetails={entityDetailsResponse.data}
        handleAddSegment={handleOpenSegmentSection}
        scenarioData={scenarioData}
        section={section}
        jobData={jobData}
        jobRunId={jobRunId}
        jobRunName={jobRunData?.entryDto?.runId}
        isEntityLoading={isEntityLoading}
        entity={entity}
      />
      <Grid container direction="row" wrap="nowrap" className={classes.tabHeader}>
        {section !== Section.advanceAnalysis && (
          <Tabs value={tabValue} onChange={handleTabChange}>
            {sections.map(({ title, value, icon }) => (
              <Tab key={value} label={title} icon={icon} />
            ))}
          </Tabs>
        )}
        <div className={classes.viewDataTableOptions}>
          {section === Section.schema && (
            <Box px={2}>
              <SearchField
                size="small"
                placeholder="Search..."
                onChange={onSearch}
                className={classes.searchField}
                InputProps={{
                  style: { backgroundColor: "initial", borderRadius: "32px" }
                }}
              />
            </Box>
          )}
          {section === Section.data && (
            <div>
              {!!entityDetailsResponse.data?.rows && entityDetailsResponse.data?.rows > 0 && (
                <Box px={2} display="inline-block">
                  <Typography variant="overline">
                    {!!filterDetails?.value || !!filterDetails?.filteredColumnNames
                      ? `${filterDetails?.size ?? "-"} of ${viewDataData?.rows ? size(viewDataData?.rows) : "-"} loaded rows (out of ${entityDetailsResponse.data?.rows ?? "-"} total)`
                      : `${viewDataData?.rows ? size(viewDataData?.rows) : "-"} of ${entityDetailsResponse.data?.rows ?? "-"} rows`}
                  </Typography>
                </Box>
              )}
            </div>
          )}
        </div>
        {section === Section.segments &&
          !isJobPath &&
          !isAddSegmentOpen &&
          isAddSegmentAction &&
          !!isDefaultScenario && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenSegmentSection}
              data-testid="segmentsAddSegmentAction">
              Add Segment
            </Button>
          )}
      </Grid>
      <div
        id="viewDataContainer"
        className={classes.viewDataContentContainer}
        // $FixMe: Below work-around should be removed.
        style={{ overflowY: section === Section.data ? "hidden" : "auto" }}>
        {getChildComponent()}
      </div>
    </Grid>
  );
};

export default ViewDataRoutes;
