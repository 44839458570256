import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";

interface EmailRequest {
  roleId: string;
  recipientEmail: string;
}

interface IVariables {
  requests: EmailRequest[];
  isEmail?: boolean;
}

export const useInviteUserMutation = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  return useMutation({
    mutationFn: async ({ requests, isEmail = true }) => {
      if (isEmail) {
        const response = await api.fetchResponse(
          async () => await api.InvitationControllerApi.inviteToTenantByEmail(requests as any)
        );

        return response;
      }
      return Promise.all(
        requests.map(async (request) => {
          const invitation = await api.fetchResponse(
            async () => await api.InvitationControllerApi.inviteToTenant([request as any])
          );

          return invitation;
        })
      );
    }
  });
};
