import React, { useEffect, useMemo, useState } from "react";

import { useParams } from "react-router-dom";

import { Grid, Tooltip, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { capitalize } from "src/utils/capitalize";

import { useGetScenarios, useGetJob, useGetJobRun } from "src/hooks/api";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import { Spinner } from "src/components";
import { DagFlow } from "src/pages/private/ProjectsModule/pages/Dag/components";

import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";

import { JobsHelperText, JobRunStatuses } from "../../utils/Jobs.constants";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles(() => ({
  canvasInfoIcon: {
    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 5,
    opacity: 0.5,
    cursor: "help"
  }
}));

const JobCanvas = () => {
  const { projectId, jobId, jobRunId }: $TSFixMe = useParams() || {};

  const classes = useStyles();

  // Project context
  const { project } = useProjectContext() || {};

  const [scenario, setScenario] = useState<$TSFixMe>({});

  // Query hooks - STARTS >>
  const { data: jobData } = useGetJob({ projectId, jobId });

  const {
    isLoading: isFetchingScenarios,
    isFetched: isScenariosFetched,
    data: scenariosData
  } = useGetScenarios({ projectId });

  const {
    isLoading: isFetchingJobRun,
    isFetched: isJobRunFetched,
    data: jobRunData
  } = useGetJobRun({ jobRunId, isApiWithRethrow: false });
  // << ENDS - Query hooks

  useEffect(() => {
    if (isScenariosFetched) {
      if ((scenariosData || [])?.length > 0) {
        if (isJobRunFetched) {
          const thisScenario = scenariosData?.find(
            (scenario: $TSFixMe) => scenario?.id === jobRunData?.entryDto?.scenarioId
          );

          Object.keys(thisScenario || {})?.length > 0 && setScenario(() => thisScenario || {});
        }
      }
    }
  }, [isJobRunFetched, isScenariosFetched, jobRunData, scenariosData]);

  const datasetsForCanvasInfo = useMemo(() => {
    // Term entity below refers to nodes.
    // Term dataset below refers to nodes that are of type ENTITY.
    let invisibleEntityNames: $TSFixMe = [];
    let invisibleDatasetNames: $TSFixMe = [];

    if ((jobRunData?.entryDto?.outEntityInfo || [])?.length > 0) {
      const entityNames: string[] = [];
      const datasetNames: string[] = [];

      Object.values(jobRunData?.entityDtos || {})?.forEach((eachEntity: $TSFixMe) => {
        entityNames.push(eachEntity?.name);
        eachEntity?.type === "ENTITY" && datasetNames.push(eachEntity?.name);
      });

      (jobRunData?.entryDto?.outEntityInfo || [])?.forEach((eachEntity: $TSFixMe) => {
        !entityNames.includes(eachEntity?.name) && invisibleEntityNames.push(eachEntity?.name);

        if (eachEntity?.viewType === "NONE") {
          !datasetNames.includes(eachEntity?.name) && invisibleDatasetNames.push(eachEntity?.name);
        }
      });
    }

    return {
      invisibleEntityNames: invisibleEntityNames?.join(", "),
      invisibleDatasetNames: invisibleDatasetNames?.join(", ")
    };
  }, [isJobRunFetched]);

  const canvasInfo = useMemo(() => {
    if (jobRunData?.entryDto?.status !== JobRunStatuses?.SuccessWithWarn) {
      return;
    }

    let message = <></>;

    if (!!datasetsForCanvasInfo?.invisibleDatasetNames) {
      message = (
        <Grid container direction="column" style={{ gap: 10 }}>
          <Grid item>
            {`${JobsHelperText.InvisibleOutputDatasetsInfoPart1}${
              datasetsForCanvasInfo?.invisibleEntityNames
                ? ` (${datasetsForCanvasInfo?.invisibleEntityNames})`
                : ``
            } ${JobsHelperText.InvisibleOutputDatasetsInfoPart2} ${
              JobsHelperText.InvisibleOutputDatasetsInfoPart3
            }`}
          </Grid>
          <Grid item>{datasetsForCanvasInfo?.invisibleDatasetNames}</Grid>
        </Grid>
      );
    } else {
      message = (
        <>
          {`${JobsHelperText.InvisibleOutputDatasetsInfoPart1}${
            datasetsForCanvasInfo?.invisibleEntityNames
              ? ` (${datasetsForCanvasInfo?.invisibleEntityNames})`
              : ``
          } ${JobsHelperText.InvisibleOutputDatasetsInfoPart2}`}
        </>
      );
    }

    return (
      <Tooltip title={message} placement="right">
        <InfoOutlinedIcon fontSize="small" className={classes.canvasInfoIcon} />
      </Tooltip>
    );
  }, [datasetsForCanvasInfo]);

  return (
    <>
      <Grid container style={{ height: "100%" }}>
        {isFetchingJobRun || isFetchingScenarios || !scenario?.id ? (
          <CommonLoader />
        ) : (
          <>
            <SubTopNavBarWrapper
              subTopNavBarLeftSection={{
                component: (
                  <SubTopNavBarBreadcrumbs
                    project={project}
                    jobData={jobData}
                    jobRunName={jobRunData?.entryDto?.runId}
                  />
                )
              }}
              subTopNavBarRightSection={{
                component: isFetchingScenarios ? (
                  <Spinner size={18} />
                ) : (
                  <Typography variant="body2" style={{ width: "max-content" }}>
                    Scenario:{" "}
                    <Typography variant="body2" component="span" color="textPrimary">
                      {capitalize(scenario?.name)}
                    </Typography>
                  </Typography>
                )
              }}
            />

            <DagFlow
              jobProps={{
                scenarioId: scenario?.id,
                jobId,
                jobRunId,
                ...(!canvasInfo ? {} : { canvasInfo })
              }}
            />
          </>
        )}
      </Grid>
    </>
  );
};

export default JobCanvas;
