import React, { useCallback, useMemo } from "react";

// Packages
import { isEmpty } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Icons
import LaunchRoundedIcon from "@material-ui/icons/LaunchRounded";
import { ExportIcon } from "src/icons/NewUX/ExportIcon";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Hooks
import { usePollEnvironment } from "src/hooks/api/environments/usePollEnvironments";
import useExportEnvLogs from "src/pages/Projects/common/useExportEnvLogs";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { OverflowTooltip, SearchField } from "src/components";
import StatusBar from "../StatusBar/StatusBar";

// Constants
import { EnvironmentsHelperText } from "../../Environments/utils/Environments.constants";

const useStyles = makeStyles(() => ({
  searchStyles: {
    width: 195,
    borderRadius: 4,
    "& > div": { height: 30 }
  }
}));

interface IProps {
  open: boolean;
  environment: EnvDto;
  onClose: () => void;
  searchValue: string;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Header: React.FC<IProps> = (props) => {
  const { open = false, environment, onClose, searchValue, onSearch } = props;

  const theme = useTheme();
  const classes = useStyles();

  const { data: pollingEnvResponse, isFetchedAfterMount: isPollingEnvFetchedAfterMount } =
    usePollEnvironment({
      id: environment?.id,
      status: environment?.launchStatus,
      isPollEnvEnabled: !!open
    });

  const env = useMemo(() => {
    if (!!isPollingEnvFetchedAfterMount && !isEmpty(pollingEnvResponse?.data?.[0])) {
      return pollingEnvResponse?.data?.[0];
    }

    return environment;
  }, [isPollingEnvFetchedAfterMount, pollingEnvResponse?.data?.[0], environment]);

  const {
    isLoading: isExporting,
    mutateAsync: exportEnvLogsMutation,
    reset: resetExportEnvLogsMutation
  } = useExportEnvLogs();

  const exportEnvLogs = useCallback(async () => {
    if (!environment?.id || !environment?.name) {
      return;
    }

    resetExportEnvLogsMutation();
    await exportEnvLogsMutation({ envId: environment?.id, name: environment?.name });
  }, [environment?.id, environment?.name]);

  const navigateToLogsPage = useCallback(() => {
    window.open(`/environments/${environment?.id}/logs`, "_blank", "noopener,noreferrer");
  }, [environment?.id]);

  return (
    <SubTopNavBarWrapper
      variant="drawer"
      onDrawerClose={onClose}
      subTopNavBarLeftSection={{
        component: (
          <Grid container alignItems="center" style={{ columnGap: theme.spacing(1) }}>
            <Typography variant="body2" color="textSecondary">
              <OverflowTooltip
                style={{ whiteSpace: "nowrap", maxWidth: 135 }}
                value={`Logs - ${environment?.name}`}
                data-testid="envLogsTitleOverflowTooltip"
              />
            </Typography>
          </Grid>
        )
      }}
      subTopNavBarRightSection={{
        moreOptions: [
          {
            label: EnvironmentsHelperText.Export,
            icon: !!isExporting ? (
              <CircularProgress size={16} />
            ) : (
              <ExportIcon viewBox="0 0 22 24" />
            ),
            action: exportEnvLogs
          },
          {
            label: EnvironmentsHelperText.OpenInNewTab,
            icon: <LaunchRoundedIcon fontSize="small" />,
            action: navigateToLogsPage,
            isCloseMoreAction: true
          }
        ],
        component: (
          <Grid container wrap="nowrap" style={{ columnGap: theme.spacing(1) }}>
            <SearchField
              placeholder="Search"
              value={searchValue}
              onChange={onSearch}
              size="small"
              className={classes.searchStyles}
            />
            <StatusBar
              environment={env}
              styleProps={{
                style: { width: "auto", margin: "auto", borderRadius: 4 }
              }}
            />
          </Grid>
        )
      }}
    />
  );
};

export default Header;
