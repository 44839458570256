import _, { compact } from "lodash";
import React, { useState } from "react";
import { Button, Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";

import AddRemoveToRecipeButton from "./AddRemoveToRecipeButton";
import AutoSelectDataset from "../AskAIInputDatasets/AutoSelectDataset";
import PinnedDatasets from "../AskAIInputDatasets/PinnedDatasets";
import {
  AIChatRequestDtoOutputTypeEnum,
  AIChatResponseDto,
  AddOutputRequestDtoFlowTypeEnum,
  OutputEntity,
  OutputEntityDto
} from "@rapidcanvas/rc-api-core";
import { ShowUpdateNameDialog } from "../AskAIResponseContainer/ShowUpdateNameDialog";
import { Spinner } from "src/components";
import {
  QUERY_KEY_RECIPE,
  useAddMessageToOutputMutation,
  useRemoveMessageFromOutputMutation
} from "src/hooks/api";
import { ViewCodeIcon } from "src/icons/NewUX/ViewCodeIcon";
import { ViewOutputIcon } from "src/icons/NewUX/ViewOutputIcon";
import { handleResponse } from "src/utils/apiService";
import { useQueryClient } from "@tanstack/react-query";
import { hasDuplicateItems } from "src/utils/arrayUtils";

const useStyles = makeStyles({
  recipeBtn: {
    backgroundColor: "#fff",
    height: "26px"
  },
  actions: {
    gap: "16px"
  },
  queryBtn: {
    borderRadius: "4px",
    background: "#fff",
    padding: "4px"
  }
});

export const AskAIHeaderActions = ({
  message,
  isOutputDataset,
  setShouldShowCode,
  shouldShowCode,
  existingEntityNames,
  explainIsloading,
  onExplainCode,
  isRetryInProgress,
  explainCodeSuccess,
  isOutputText,
  isOutputChart
}: {
  message: AIChatResponseDto;
  isOutputDataset: boolean;
  setShouldShowCode: React.Dispatch<React.SetStateAction<boolean>>;
  shouldShowCode: boolean;
  targetId: string | undefined;
  isOutputChart: boolean;
  isOutputModel: boolean;
  existingEntityNames?: {
    inputs: (string | undefined)[];
    existingCharts: (string | undefined)[];
    existingDatasets: (string | undefined)[];
    existingModels: (string | undefined)[];
  };
  onExplainCode: () => void;
  explainIsloading: boolean;
  explainCodeSuccess: boolean;
  isRetryInProgress: boolean;
  isOutputText: boolean;
}) => {
  const classes = useStyles();
  const {
    transformId,
    outputEntityList,
    outputEntityResponseList,
    threadId,
    askAIMessageId,
    error
  } = message;
  const isAddedToRecipe = !!transformId;
  const [showUpdateNameDialog, setShowUpdateNameDialog] = useState(false);
  const addMessageToOutputMutation = useAddMessageToOutputMutation(threadId!);
  const removeMessageFromOutputMutation = useRemoveMessageFromOutputMutation(threadId!);

  const onRemoveFromRecipe = () => {
    removeMessageFromOutputMutation.mutate(
      {
        messageId: askAIMessageId!
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEY_RECIPE] });
        }
      }
    );
  };

  const queryClient = useQueryClient();

  const onAddToRecipe = (data: { string: string }) => {
    const outputNames = Object.values(data);
    const currentOutputNames = _.map(
      message.outputEntityList || message.outputEntityResponseList,
      "name"
    ).filter(Boolean) as string[];
    const normalize = (arr: string[]) => arr.map((name) => name.toLowerCase());

    const { inputs, existingCharts, existingDatasets } = existingEntityNames ?? {
      inputs: [],
      existingCharts: [],
      existingDatasets: [],
      existingModels: []
    };

    const normalizedOutputNames = normalize(outputNames);
    if (isOutputDataset) {
      const duplicateNames = _.intersection(
        _.difference(
          normalize(compact([...inputs, ...existingDatasets])),
          normalize(currentOutputNames)
        ),
        normalizedOutputNames
      );

      if (duplicateNames.length !== 0 || hasDuplicateItems(normalizedOutputNames)) {
        handleResponse({
          errorMessage: `The entity name(s) ${duplicateNames?.length ? `- ${duplicateNames.join(", ")}` : "provided"} are already in use in either the input or output dataset. Please ensure each name is unique before saving.`
        });
        return;
      }
    }
    if (isOutputChart) {
      const duplicateNames = _.intersection(
        _.difference(normalize(compact(existingCharts)), normalize(currentOutputNames)),
        normalizedOutputNames
      );

      if (duplicateNames.length !== 0 || hasDuplicateItems(normalizedOutputNames)) {
        handleResponse({
          errorMessage: `The chart name(s) ${duplicateNames?.length ? `- ${duplicateNames.join(", ")}` : "provided"} are already in use in this recipe. Please ensure that all chart names are unique.`
        });
        return;
      }
    }

    const nameChanges = _.map(_.entries(data), ([oldOutputName, newOutputName]) => ({
      oldName: oldOutputName,
      newName: newOutputName
    }));
    addMessageToOutputMutation.mutate(
      {
        messageId: askAIMessageId!,
        request: {
          flowType: AddOutputRequestDtoFlowTypeEnum.Recipe,
          nameChanges
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEY_RECIPE] });
          setShowUpdateNameDialog(false);
        }
      }
    );
  };

  const datasetName = _.first(_.map(outputEntityResponseList || outputEntityList, "name"));
  const isRecipeUpdating = removeMessageFromOutputMutation.isLoading;

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="flex-end"
      className={classes.actions}
      onClick={(e) => e.stopPropagation()}>
      {isOutputDataset && datasetName && (
        <>
          <PinnedDatasets name={datasetName} />
          <AutoSelectDataset name={datasetName} />
        </>
      )}

      {!!message.code && (
        <Tooltip title={shouldShowCode ? "View Output" : "View Code"}>
          <div>
            <IconButton
              className={classes.queryBtn}
              data-testid={`ask-ai-view-${shouldShowCode ? "output" : "code"}-btn`}
              onClick={(event: any) => {
                event.stopPropagation();
                setShouldShowCode((show) => !show);
              }}>
              {shouldShowCode ? <ViewOutputIcon /> : <ViewCodeIcon />}
            </IconButton>
          </div>
        </Tooltip>
      )}
      {shouldShowCode && (
        <Tooltip
          title={
            isRetryInProgress
              ? "Please wait until retry query succeeds"
              : explainCodeSuccess
                ? "Code explanation is already done"
                : ""
          }>
          <span>
            <Button
              color="primary"
              size="small"
              className={classes.recipeBtn}
              variant="outlined"
              data-testid={`ask-ai-view-explain-code-btn`}
              disabled={explainIsloading || explainCodeSuccess || isRetryInProgress}
              onClick={(event: any) => {
                event.stopPropagation();
                onExplainCode();
              }}>
              {explainIsloading ? <Spinner size={18} noPadding /> : "Explain"}
            </Button>
          </span>
        </Tooltip>
      )}

      {_.isEmpty(error) && !isOutputText && (
        <AddRemoveToRecipeButton
          isRetryInProgress={isRetryInProgress}
          isRecipeUpdating={isRecipeUpdating}
          isAddedToRecipe={isAddedToRecipe}
          disabled={isRecipeUpdating || addMessageToOutputMutation.isLoading || isRetryInProgress}
          onClick={() => {
            isAddedToRecipe ? onRemoveFromRecipe() : setShowUpdateNameDialog(true);
          }}
        />
      )}
      {showUpdateNameDialog && (
        <ShowUpdateNameDialog
          isSubmitLoading={addMessageToOutputMutation.isLoading}
          isSubmitDisabled={addMessageToOutputMutation.isLoading}
          onSubmit={onAddToRecipe}
          title={"Update Name"}
          onClose={() => {
            setShowUpdateNameDialog(false);
          }}
          label={isOutputDataset ? "Dataset Name" : "Chart Name"}
          queryOutputs={_.map(
            outputEntityResponseList || outputEntityList,
            (entity: OutputEntityDto | OutputEntity) => {
              return {
                name: entity.name as string,
                isModel: entity.outputType === AIChatRequestDtoOutputTypeEnum.Model
              };
            }
          )}
        />
      )}
    </Grid>
  );
};

export default React.memo(AskAIHeaderActions);
