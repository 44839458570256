import React, { useEffect, useMemo, useState } from "react";
import _, { ceil, get, includes, isEmpty, map } from "lodash";
import { Grid, Typography, makeStyles, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import { useQueryClient } from "@tanstack/react-query";

import Nodata from "./NoData";
import Pagination from "src/components/Table/Pagination";
import SampleData from "./SampleData";
import SynchingDatasource from "./SynchingDatasource";
import TablesComparisionPopover from "./TablesComparisionPopover";
import useGetSampleData from "src/hooks/api/dataSources/useGetSampleData";
import useGetSynchedData from "src/hooks/api/dataSources/useGetSynchedData";
import { DataSourcesHelperText } from "../../utils/DataSources.constants";
import { FivetranStatus } from "src/pages/private/ProjectsModule/pages/Dataset/utils/Dataset.constants";
import { QUERY_KEY_DATA_SOURCE_SAMPLE_DATA } from "src/hooks/api/dataSources/useGetSampleData";
import { SynchOutlined } from "src/icons/NewUX/SynchOutlined";
import { Table } from "src/components";
import CommonLoader from "src/components/CommonLoader";

interface IProps {
  id: string;
  details: any;
  status: FivetranStatus | null;
}

const StyledBox = styled("div")(() => ({
  "& table tbody tr:hover": {
    cursor: "pointer"
  },
  borderBottom: "1px solid #f5f5f5"
}));

const useStyles = makeStyles(() => ({
  synchDataContainer: {
    gap: "12px"
  },
  tableFetched: {
    background: "#F2F2F2",
    borderRadius: "12px",
    fontSize: "14px",
    padding: "8px",
    display: "inline-block"
  },
  tableTitle: {
    gap: "5px"
  },
  syncTable: {
    tr: {
      "&:hover": {
        cursor: "pointer"
      }
    }
  },
  actionContainer: {
    gap: "10px"
  },
  message: {
    color: "#7C7C7C",
    fontSize: "14px"
  }
}));
const columns = [
  {
    id: "name",
    accessor: "name",
    Header: "Table Name",
    isSortable: true
  },
  {
    id: "records",
    accessor: "records",
    Header: "Records",
    isSortable: true
  }
];

const syncMessages = {
  [FivetranStatus.active]: "Data sync completed",
  [FivetranStatus.inActive]: "",
  [FivetranStatus.syncIssues]: "Data sync Issue",
  [FivetranStatus.syncPaused]: "Data sync paused",
  [FivetranStatus.syncing]: "Data Sync is in progress"
};
const pageSize = 100;
const ThirdPartySynchedData: React.FC<IProps> = ({ details, status }) => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const [refetchTimeout, setRefetchTimeout] = useState(false);

  const classes = useStyles();
  const [selectedTable, setSelectedTable] = useState<string>();
  const synchDataResult = useGetSynchedData(
    get(details, ["options", "connectorId"]),
    currentPage * pageSize,
    (currentPage + 1) * pageSize,
    {
      refetchInterval: status === FivetranStatus.syncing && !refetchTimeout ? 15000 : false
    }
  );

  useEffect(() => {
    const refetchTimeOut = setTimeout(() => {
      setRefetchTimeout(true);
    }, 3600000);

    return () => {
      clearTimeout(refetchTimeOut);
    };
  }, []);

  const sampleDataResult = useGetSampleData(
    get(details, ["options", "connectorId"]),
    selectedTable,
    { enabled: !!selectedTable }
  );

  const { dataSource, totalPages } = useMemo(() => {
    let dataSource: any[];
    let totalPages: number;
    if (synchDataResult.data) {
      dataSource = map(synchDataResult.data.tables, (value, key) => ({
        name: key,
        records: value
      }));
      totalPages = ceil(synchDataResult.data.actualTableCount / pageSize);
    } else {
      dataSource = [];
      totalPages = 0;
    }
    return { dataSource, totalPages };
  }, [synchDataResult.data]);

  if (
    status === FivetranStatus.syncing &&
    (isEmpty(synchDataResult.data) ||
      (!isEmpty(synchDataResult.data) && isEmpty(get(synchDataResult, ["data", "tables"]))))
  ) {
    return <SynchingDatasource />;
  }

  return (
    <Grid container className={classes.synchDataContainer} direction="column">
      <Grid container item alignItems="center" justifyContent="space-between">
        <Grid xs={4} item>
          {!_.isEmpty(dataSource) && (
            <div className={classes.tableFetched}>
              <Grid alignContent="center" container className={classes.tableTitle}>
                <Grid>{`Tables fetched ${synchDataResult.data.actualTableCount}/${synchDataResult.data.sourceTableCount}`}</Grid>

                <Grid>
                  <TablesComparisionPopover
                    connectorId={get(details, ["options", "connectorId"])}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
        <Grid xs={8} container item className={classes.actionContainer} justifyContent="flex-end">
          {status && (
            <>
              <SynchOutlined color="#4646B5" />
              <Typography className={classes.message}>{get(syncMessages, status)}</Typography>
            </>
          )}
          {includes([FivetranStatus.syncIssues, FivetranStatus.syncPaused], status) && (
            <Tooltip arrow title="Please resync using manual sync option">
              <InfoOutlined color="disabled" fontSize="small" />
            </Tooltip>
          )}
        </Grid>
      </Grid>
      {synchDataResult.isLoading && <CommonLoader />}
      {(synchDataResult.data && isEmpty(dataSource)) || synchDataResult.isError ? (
        <Nodata />
      ) : (
        !isEmpty(dataSource) && (
          <>
            <StyledBox>
              <Table
                skipPageReset
                data={dataSource}
                size="small"
                isTheadSticky={true}
                columns={columns}
                isCellSortEnabled
                hideCount
                inheritHeight
                hideSettings
                unsorted
                maxHeight="300px"
                onRowClicked={(rowData) => {
                  if (status === FivetranStatus.syncing) {
                    queryClient.invalidateQueries([
                      QUERY_KEY_DATA_SOURCE_SAMPLE_DATA,
                      get(details, ["options", "connectorId"]),
                      selectedTable
                    ]);
                  }
                  setSelectedTable(get(rowData, ["original", "name"]));
                }}
              />
            </StyledBox>
            <Pagination
              pageCount={totalPages}
              actualPage={currentPage}
              canNextPage={currentPage < totalPages - 1}
              canPreviousPage={currentPage > 1}
              gotoPage={handlePageChange}
              nextPage={() => handlePageChange(currentPage + 1)}
              previousPage={() => {
                handlePageChange(currentPage - 1);
              }}
            />
          </>
        )
      )}
      {selectedTable &&
        (sampleDataResult.isLoading ? (
          <CommonLoader />
        ) : (sampleDataResult.data && (sampleDataResult.data?.data?.columns || [])?.length === 0) ||
          (sampleDataResult.data?.data?.rows || [])?.length === 0 ? (
          <span id="noSampleDataFound">{DataSourcesHelperText.NoSampleDataFound}</span>
        ) : (
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography id="sampleDataTitle" variant="subtitle2">
                  {`${DataSourcesHelperText.SampleData} (${selectedTable})`}
                  <Tooltip arrow title="Shows Max 50 rows and 50 columns">
                    <InfoOutlined color="disabled" fontSize="small" />
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ width: "inherit" }}>
              <SampleData sampleData={sampleDataResult.data.data} unsorted />
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default ThirdPartySynchedData;
