import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";

interface IVariables {
  name: string;
}

const useDeleteModel = (): UseMutationResult<void, unknown, IVariables, unknown> => {
  return useMutation({
    mutationFn: async ({ name }) => {
      const response = await api.fetchResponse(
        async () => await api.PredictionServiceControllerApi.deleteModel(name)
      );
      return response;
    }
  });
};

export default useDeleteModel;
