import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CalendarToday, Description, Edit, Person, PowerSettingsNew } from "@material-ui/icons";
import { Grid, Card, Typography, makeStyles, Tooltip } from "@material-ui/core";

import DataAppTileStatus from "./DataAppTileStatus";
import { ConfigIcon } from "src/icons/ConfigIcon";
import { DataAppDtoDataAppTypeEnum } from "@rapidcanvas/rc-api-core";
import { DataAppType } from "../../DataApps.type";
import { Delete } from "src/icons/Delete";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { MoreOptions, OverflowTooltip } from "src/components";
import { askAITypesInfoList } from "../../utils/DataApps.constants";
import { dateFormat } from "src/utils/dateFormat";
import { deleteDisabled, deleteTooltip } from "../../utils/DataApps.helpers";
import { AiAssistedIcon } from "src/icons/NewUX/AiAssistedRecipeIcon";
import RocketIcon from "src/icons/NewUX/RocketIcon";
import { InputIcon } from "src/icons/NewUX/InputIcon";

const useStyles = makeStyles({
  root: {
    borderRadius: 12
  },
  containerWrap: {
    display: "flex",
    flexDirection: "row",
    padding: "16px 16px 0px 16px"
  },
  img: {
    width: "160px",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    height: "232px"
  },
  containerDataWrap: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 160px)",
    paddingLeft: "16px"
  },
  containerFirstDataItem: {
    paddingTop: "8px"
  },
  containerDataItem: {
    display: "flex",
    paddingTop: "16px",
    whiteSpace: "pre"
  },
  container: {
    height: "100%"
  },
  dataAppTileWrap: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "0px"
  },
  alert: {
    padding: "0px 16px",
    marginTop: "8px",
    borderRadius: "0px 0px 4px 4px"
  },
  alertMessage: {
    padding: "6px 0"
  },
  link: {
    color: "#003656",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  }
});

interface DataApp extends DataAppType {
  imgComponent?: React.ReactNode;
  projectName?: string;
}
type Props = {
  data: DataApp;
  onDelete: (data: DataApp) => void;
  onConfigOpen: (dataApp: DataAppType) => void;
  onLogsOpen: (dataApp: DataAppType) => void;
  onEdit: (dataApp: DataAppType) => void;
  isRoleYieldsDataAppView: boolean;
};

interface LoadableImageIProps {
  src?: string;
  fallbackSrc?: string;
  title: string;
}

const LoadableImage: React.FC<LoadableImageIProps> = ({ src, fallbackSrc, title }) => {
  const classes = useStyles();

  let base64Src = src?.split(";base64,")?.pop() || src;
  base64Src = base64Src ? `data:image/jpeg;base64,${base64Src}` : fallbackSrc;

  const [isLoadable, setIsLoadable] = useState<boolean>(true);

  useEffect(() => {
    const img = new Image();

    // Check if the image loads successfully
    img.onload = () => {
      setIsLoadable(true);
    };

    // If the image fails to load, fallback to the alternative image
    img.onerror = () => {
      setIsLoadable(false);
    };

    img.src = base64Src ?? ""; // Start loading the image

    // Cleanup
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [base64Src]);

  return (
    <Grid
      item
      component="img"
      className={classes.img}
      src={isLoadable ? base64Src : src}
      alt={title}
    />
  );
};

export const DataAppTile = ({
  data,
  isRoleYieldsDataAppView,
  onConfigOpen,
  onDelete,
  onLogsOpen,
  onEdit
}: Props) => {
  const location = useLocation();
  const classes = useStyles();
  const {
    name,
    displayName,
    description,
    projectId,
    updater,
    updatedOn,
    iconUrl,
    id,
    launchStatus,
    imgComponent,
    projectName,
    stoppedBy,
    groupDisplayName,
    scenarioId,
    dataAppType,
    askAIConfig,
    groupId
  } = data;

  const [isHovered, setIsHovered] = useState(false);
  const [more, setMore] = useState(false);

  const navigate = useNavigate();

  const inputType = useMemo(() => {
    switch (dataAppType) {
      case DataAppDtoDataAppTypeEnum.RapidModel: {
        return "Rapid Model";
      }

      case DataAppDtoDataAppTypeEnum.Askai: {
        return _.find(askAITypesInfoList, { value: askAIConfig?.inputType })?.label ?? "-";
      }

      default: {
        return "-";
      }
    }
  }, [askAIConfig, dataAppType]);

  const options = [
    {
      label: "Edit",
      id: "dataAppEdit",
      disabled: false,
      icon: (
        <Typography color="textSecondary">
          <Edit />
        </Typography>
      ),
      click: () => onEdit(data)
    },
    {
      id: "dataAppDelete",
      label: "Delete",
      click: () => onDelete(data),
      tooltip: deleteTooltip(launchStatus),
      disabled: deleteDisabled(launchStatus),
      icon: (
        <Typography color="textSecondary">
          <Delete />
        </Typography>
      )
    },
    {
      label: "Logs",
      id: "dataAppLogs",
      icon: <LogsNewIconV2 fill="#003656" width="24" height="24" />,
      click: () => onLogsOpen(data)
    },
    {
      label: "Configs",
      id: "dataAppConfig",
      disabled: false,
      icon: <ConfigIcon />,
      click: () => onConfigOpen(data)
    }
  ];

  return (
    <Card
      style={{
        cursor: isHovered ? "pointer" : undefined,
        background: isHovered ? "#F4F4FF" : undefined,
        border: isHovered ? "1px solid #4646B5" : undefined
      }}
      className={classes.root}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() =>
        navigate(`${location.pathname === "/" ? "dataapps-ui" : location.pathname}/${name}`)
      }>
      <Grid className={classes.dataAppTileWrap}>
        <Grid className={classes.containerWrap}>
          {imgComponent ? (
            <Grid item>{imgComponent}</Grid>
          ) : !!iconUrl && /(http|https):\/\//.test(iconUrl) ? (
            <Grid item component="img" className={classes.img} src={iconUrl} alt={description} />
          ) : (
            <LoadableImage src={iconUrl} title={description} />
          )}
          <Grid className={classes.containerDataWrap}>
            <Grid container justifyContent="space-between">
              <Grid item style={{ maxWidth: "calc(100% - 45px)" }}>
                <Typography component="span" variant="h6">
                  <Link
                    className={classes.link}
                    to={`${location.pathname === "/" ? "dataapps-ui" : location.pathname}/${name}`}
                    data-testid="dataAppNameLink"
                    onClick={(e) => e.stopPropagation()}>
                    <OverflowTooltip value={displayName || name} style={{ whiteSpace: "pre" }} />
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <MoreOptions options={options} testId="dataAppMoreOptionsAction" />
              </Grid>
            </Grid>
            <Grid style={{ height: "200px", overflowY: "auto" }}>
              <Grid className={classes.containerFirstDataItem}>
                <Grid container alignItems="flex-end" style={{ columnGap: 10 }}>
                  <Grid item>
                    <Tooltip title="Project">
                      <Typography
                        component="span"
                        variant="body2"
                        color="textSecondary"
                        style={{ marginLeft: "3px" }}>
                        <RocketIcon
                          height={16}
                          width={16}
                          fill="currentColor"
                          color="currentColor"
                        />
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{ maxWidth: "calc(100% - 60px)" }}>
                    <Typography component="span" variant="body2" data-testid="dataAppProject">
                      {isRoleYieldsDataAppView ? (
                        <OverflowTooltip
                          value={projectName || projectId}
                          style={{ whiteSpace: "pre" }}
                        />
                      ) : (
                        <Link
                          className={classes.link}
                          to={`/projects/${projectId}`}
                          data-testid="dataAppProjectLink"
                          onClick={(e) => e.stopPropagation()}>
                          <OverflowTooltip
                            value={projectName || projectId}
                            style={{ whiteSpace: "pre" }}
                          />
                        </Link>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.containerFirstDataItem}>
                <Grid container alignItems="flex-end" style={{ columnGap: 10 }}>
                  <Grid item>
                    <Tooltip title="DataApp Input">
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ marginLeft: "4px" }}
                        color="textSecondary">
                        <InputIcon />
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    style={{ maxWidth: "calc(100% - 60px)", maxHeight: 20, overflowY: "hidden" }}>
                    <Typography component="span" variant="body2" data-testid="dataAppInputType">
                      <OverflowTooltip
                        value={inputType}
                        tooltipProps={{
                          placement: "top"
                        }}
                        style={{ whiteSpace: "nowrap" }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.containerFirstDataItem}>
                <Grid container alignItems="flex-end" style={{ columnGap: 10 }}>
                  <Grid item>
                    <Tooltip title="Updated by">
                      <Person style={{ fontSize: 20 }} />
                    </Tooltip>
                  </Grid>
                  <Grid item style={{ maxWidth: "calc(100% - 60px)" }}>
                    <OverflowTooltip
                      style={{ maxWidth: 300, whiteSpace: "nowrap" }}
                      value={
                        <Typography component="span" variant="body2" data-testid="dataAppUpdater">
                          <OverflowTooltip value={updater} style={{ whiteSpace: "pre" }} />
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.containerFirstDataItem}>
                <Grid container alignItems="flex-end" style={{ columnGap: 10 }}>
                  <Grid item>
                    <Tooltip title="Last Updated on">
                      <CalendarToday style={{ fontSize: 20 }} />
                    </Tooltip>
                  </Grid>
                  <Grid item style={{ maxWidth: "calc(100% - 60px)" }}>
                    <Typography component="span" variant="body2" data-testid="dataAppStoppedBy">
                      <OverflowTooltip
                        value={dateFormat(updatedOn)}
                        style={{ whiteSpace: "pre" }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.containerFirstDataItem}>
                <Grid container alignItems="flex-end" style={{ columnGap: 10 }}>
                  <Grid item>
                    <Tooltip title="Recipe">
                      <Typography component="span" variant="body2" color="textSecondary">
                        <AiAssistedIcon height={20} width={20} viewBox="0 0 20 20" />
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{ maxWidth: "calc(100% - 60px)" }}>
                    <Typography component="span" variant="body2">
                      {groupDisplayName ? (
                        <Link
                          className={classes.link}
                          to={`/projects/${projectId}/scenario/${scenarioId}/add-auto-ml-recipe/${groupId}`}
                          data-testid="dataAppRecipeLink"
                          onClick={(e) => e.stopPropagation()}>
                          <OverflowTooltip value={groupDisplayName} style={{ whiteSpace: "pre" }} />
                        </Link>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {more && (
                <>
                  <Grid className={classes.containerFirstDataItem}>
                    <Grid container alignItems="flex-end" style={{ columnGap: 10 }}>
                      <Grid item>
                        <Tooltip title="Stopped By">
                          <Typography component="span" variant="body2" color="textSecondary">
                            <PowerSettingsNew style={{ fontSize: 20 }} />
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item style={{ maxWidth: "calc(100% - 60px)" }}>
                        <Typography component="span" variant="body2" data-testid="dataAppStoppedBy">
                          <OverflowTooltip value={stoppedBy} style={{ whiteSpace: "pre" }} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className={classes.containerFirstDataItem}>
                    <Grid container alignItems="flex-end" style={{ columnGap: 10 }}>
                      <Grid item>
                        <Tooltip title="Description">
                          <Typography component="span" variant="body2" color="textSecondary">
                            <Description style={{ fontSize: 20 }} />
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        style={{
                          maxWidth: "calc(100% - 60px)",
                          maxHeight: 20,
                          overflowY: "hidden"
                        }}>
                        <Typography
                          component="span"
                          variant="body2"
                          data-testid="dataAppDescription">
                          <OverflowTooltip
                            value={description || "-"}
                            tooltipProps={{
                              placement: "top"
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid className={classes.containerFirstDataItem}>
                <Grid
                  item
                  style={{ maxWidth: "calc(100% - 60px)", maxHeight: 20, overflowY: "hidden" }}>
                  <Typography
                    component="span"
                    variant="body2"
                    data-testid="dataAppInputType"
                    onClick={(event) => {
                      event.stopPropagation();
                      setMore((prev) => !prev);
                    }}>
                    {more ? "less..." : "more..."}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DataAppTileStatus id={id} launchStatus={launchStatus} />
      </Grid>
    </Card>
  );
};
