import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import {
  Grid,
  IconButton,
  InputProps,
  List,
  ListItem,
  Typography,
  makeStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { OverflowTooltip, SearchField, Spinner } from "src/components";

import _, { filter, includes, isEmpty, toLower } from "lodash";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import usePredictionServices from "src/hooks/api/projects/usePredictionServices";
import PredictRecipeIcon from "src/icons/NewUX/PredictRecipeIcon";
import { PredictionServiceDto } from "@rapidcanvas/rc-api-core";
import CopyNameButton from "./CodeRecipeCanvasEntites/CopyNameButton";
import { TABS } from "src/pages/Library/ArtifactsAndModels/Models/ModelDetails";
import NoData from "./NoData";

const useStyles = makeStyles({
  codeRecipeHeader: {
    padding: "0px 16px",
    height: "44px",
    flexDirection: "row",
    flexWrap: "nowrap",
    background: "#d9d9d933",
    borderRadius: "11px 11px 0px 0px"
  },

  inputWrap: {
    height: "calc(100% - 79px)",
    flexWrap: "nowrap",
    overflow: "auto",
    borderRadius: "0px 0px 11px 11px",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#003656",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },

  btnContainer: {
    flexWrap: "nowrap",
    gap: "8px",
    flex: 0
  },
  secret: {
    padding: "4px 8px",
    height: "35px",
    fontSize: "14px",
    borderBottom: "0.5px solid #D1D1D1",
    "&:last-child": {
      border: "none"
    },
    "&:hover $actionButton": {
      visibility: "visible"
    }
  },
  actionButton: {
    visibility: "hidden"
  },
  customScrollbar: {
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#003656",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  }
});

const PredictionServices = ({
  projectId
}: {
  projectId: string | undefined;
  selectedPredictionService: PredictionServiceDto[];
  setSelectedPredictionService: Dispatch<SetStateAction<PredictionServiceDto[]>>;
  setValue: Dispatch<SetStateAction<string>>;
}) => {
  const [expanded, setExpanded] = useState(false);
  const { isLoading, data } = usePredictionServices(projectId ?? "", {
    enabled: !!projectId
  });
  const [searchValue, setSearchValue] = useState("");

  const onCollapse = () => {
    setExpanded((prev) => !prev);
  };
  const handleSearch: InputProps["onChange"] = (event) => {
    setSearchValue(event?.target.value);
  };

  const filteredEntities = useMemo(() => {
    if (data) {
      return filter(data, (item) =>
        !!searchValue ? includes(toLower(item.name), toLower(searchValue)) : true
      );
    } else {
      return [];
    }
  }, [data, searchValue]);

  const classes = useStyles();

  return (
    <div
      style={{
        height: "100%",
        border: "1px solid #BDBDBD",
        borderRadius: "11px",
        background: "#FFFFFF"
      }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={onCollapse}
        className={classes.codeRecipeHeader}>
        <Typography variant="subtitle1">Prediction Services</Typography>
        <Grid container direction="row" className={classes.btnContainer}>
          <IconButton data-testid="entities-on-canvas-collapse" size="small">
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Grid>
      </Grid>
      {expanded && (
        <>
          {isLoading ? (
            <Spinner size={20} />
          ) : isEmpty(data) ? (
            <NoData content="No Prediction Services" />
          ) : (
            <>
              <SearchField
                test-id="entities-on-canvas-search-field"
                onChange={handleSearch}
                style={{ borderRadius: "0px", width: "100%" }}
                value={searchValue}
                placeholder="Search"
              />
              {isEmpty(filteredEntities) ? (
                <NoData content="No search result" />
              ) : (
                <List
                  style={{ padding: 0, height: "calc(100% - 78px)", overflow: "auto" }}
                  className={classes.customScrollbar}>
                  {_.map(data, (item) => (
                    <ListItem className={classes.secret}>
                      <Grid
                        container
                        style={{
                          width: "calc(100% - 30px)",
                          gap: "5px"
                        }}
                        alignItems="center">
                        <Grid container alignItems="center" style={{ gap: "6px" }}>
                          <PredictRecipeIcon height={14} width={14} />
                          <div
                            style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              width: "180px"
                            }}
                            data-testid="code-recipe-input-dataset-name">
                            <Link
                              target="_blank"
                              style={{ color: "#003656" }}
                              rel="noopener noreferrer"
                              to={`/artifacts-and-models/model/${item.modelName}?isPredictionService=true`}
                              state={{
                                tab: TABS.predictionService.value,
                                predictionServiceDetails: item
                              }}>
                              <OverflowTooltip value={item.name} style={{ whiteSpace: "nowrap" }} />
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
                      <CopyNameButton className={classes.actionButton} name={item.name ?? ""} />
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(PredictionServices);
