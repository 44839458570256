import React, { useState } from "react";

// Packages
import { Link } from "react-router-dom";
import { Control, Controller, ControllerRenderProps, useFormContext } from "react-hook-form";
import { includes, isEmpty, map, toString } from "lodash";
import clsx from "clsx";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

// Utils
import { formatStringsWithAnd } from "src/helpers/helpers";
import { ITypes } from "src/pages/private/EnvironmentsModule/utils/Environments.helpers";
import { envMetadataFind } from "src/pages/Projects/helpers/projects.helpers";

// Open API
import { EnvDto, ProjectDto } from "@rapidcanvas/rc-api-core";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";
import Text from "src/components/Widget/Text";
import { statusValues } from "src/pages/private/EnvironmentsModule/utils/Environments.constants";
import EnvironmentTypeConfig from "src/pages/private/EnvironmentsModule/pages/common/EnvironmentTypeConfig";
import PreviewPlaceholder from "./PreviewPlaceholder";

// Constants
import {
  ProjectSettingsFormFields,
  ProjectSettingsFormFieldsNameMapping
} from "../utils/ProjectSettings.constants";

// Types
import { ProjectSettingsFormFieldsType } from "../ProjectSettings.types";

// Styles
import styles from "src/pages/private/EnvironmentsModule/pages/common/Environments.module.scss";

export interface EnvAction {
  types: ITypes;
  action: "stopping" | "modifying" | "changing";
}

const useStyles = makeStyles((theme) => ({
  inputField: {
    backgroundColor: theme.palette.common.white
  },
  link: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.text.primary,
      textDecoration: "underline"
    }
  },
  roundEnvStatus: {
    minWidth: "auto !important",
    width: "105px",
    borderRadius: "14px !important",
    padding: "0px 14px !important",
    height: "28px !important",
    justifyContent: "center !important"
  },
  envTypeSpan: {
    fontSize: "12px",
    color: "#838383"
  },
  envMenuItem: {
    display: "flex",
    justifyContent: "space-between",
    gridGap: "16px",
    width: "100%",
    alignItems: "center",
    "& .MuiGrid-root": {
      rowGap: 5
    }
  }
}));

type Props = {
  control: Control<ProjectSettingsFormFieldsType>;
  isLoadingEnvTypeMetaData: boolean;
  environmentTypes?: any[];
  environments?: EnvDto[];
  project?: ProjectDto;
  isReadOnly: boolean;
};

type EnvironmentMenuItemProps = {
  environmentTypes?: any[];
  environment: EnvDto;
  isEnvLink?: boolean;
};

const EnvironmentMenuItem = (props: EnvironmentMenuItemProps) => {
  const { environmentTypes, environment, isEnvLink = true } = props || {};

  const classes = useStyles();

  const envMetaData = envMetadataFind(environmentTypes, environment);
  const envType = envMetaData?.name;
  const envName = environment?.name || "Unknown";

  const handleLinkClick = (event: React.MouseEvent) => {
    event?.stopPropagation();
  };

  const renderEnvName = () =>
    !!isEnvLink && !!environment?.id ? (
      <Link
        className={classes.link}
        to={`/environments/${environment?.id}`}
        onClick={handleLinkClick}
        data-testid="projectSettingsEnvironmentTypeLink">
        <Text value={envName} data-testid="projectSettingsEnvironmentTypeText" />
      </Link>
    ) : (
      <Text value={envName} data-testid="projectSettingsEnvironmentTypeText" />
    );

  const getEnvStatusClass = () => {
    const launchStatus = environment?.launchStatus?.trim()?.toLowerCase() || "unknown";
    return clsx(classes.roundEnvStatus, styles.envStatus, styles?.[launchStatus]);
  };

  const getEnvStatusText = () => {
    const launchStatus = environment?.launchStatus?.trim()?.toLowerCase() || "unknown";
    return statusValues?.[launchStatus]?.text || environment?.launchStatus || "Unknown";
  };

  return (
    <Box className={classes.envMenuItem}>
      <Grid container direction="column">
        <Box>{renderEnvName()}</Box>

        <span className={classes.envTypeSpan}>
          (
          <EnvironmentTypeConfig
            envType={envType}
            cores={environment?.cores}
            memInMbs={environment?.memInMbs}
            diskInGbs={environment?.diskInGbs}
          />
          )
        </span>
      </Grid>

      <div className={getEnvStatusClass()}>{getEnvStatusText()}</div>
    </Box>
  );
};

const EnvironmentType = (props: Props) => {
  const { control, isLoadingEnvTypeMetaData, environmentTypes, environments, project, isReadOnly } =
    props || {};

  const classes = useStyles();

  const { setValue } = useFormContext();

  // States - STARTS >>
  const [confirmEnvAction, setConfirmEnvAction] = useState<EnvAction | null>(null);
  const [newEnvType, setNewEnvType] = useState<string>("");
  // << ENDS - States

  const onEnvTypeChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    field: ControllerRenderProps<
      ProjectSettingsFormFieldsType,
      ProjectSettingsFormFields.EnvironmentType
    >
  ) => {
    const types: ITypes = [];

    if ((project?.runningJobCount ?? 0) > 0) {
      types.push("running job(s)");
    }

    if ((project?.runningPredictionJobCount ?? 0) > 0) {
      types.push("prediction job(s)");
    }

    if (isEmpty(types)) {
      field?.onChange(event?.target?.value);
    } else {
      setNewEnvType(() => toString(event?.target?.value));
      setConfirmEnvAction({ types, action: "changing" });
    }
  };

  const resetChangeEnvType = () => {
    setConfirmEnvAction(null);
  };

  const changeEnvType = () => {
    setConfirmEnvAction(null);
    setValue(ProjectSettingsFormFields.EnvironmentType, newEnvType, {
      shouldDirty: true
    });
  };

  return (
    <>
      {!!confirmEnvAction && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title="Are you sure?"
          content={[
            `Please note that there are active processes within this environment, such as ${
              !!confirmEnvAction ? formatStringsWithAnd(confirmEnvAction?.types) : ""
            }. Continuing with ${
              !!confirmEnvAction ? confirmEnvAction?.action : ""
            } the environment may affect these ongoing operations.`
          ]}
          onClose={(_, reason: string) => {
            if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
              resetChangeEnvType();
            }
          }}
          onSubmit={changeEnvType}
          cancelLabel="No"
          submitLabel="Yes"
          hideCloseIcon
        />
      )}

      {!!isLoadingEnvTypeMetaData ? (
        <PreviewPlaceholder
          height={75}
          label={`${ProjectSettingsFormFieldsNameMapping[ProjectSettingsFormFields.EnvironmentType]} *`}
          data-testid="projectSettingsEnvironmentTypePreview"
        />
      ) : (
        <Controller
          control={control}
          name={ProjectSettingsFormFields.EnvironmentType}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth>
              <InputLabel shrink data-testid="projectSettingsEnvironmentTypeInputLabel">
                {`${ProjectSettingsFormFieldsNameMapping[ProjectSettingsFormFields.EnvironmentType]} *`}
              </InputLabel>
              <Select
                {...field}
                variant="outlined"
                fullWidth
                label={`${ProjectSettingsFormFieldsNameMapping[ProjectSettingsFormFields.EnvironmentType]} *`}
                disabled={!!isReadOnly}
                onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                  onEnvTypeChange(event, field);
                }}
                inputProps={{
                  className: classes.inputField,
                  style: {
                    minHeight: 75
                  }
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                  style: {
                    maxHeight: 385
                  }
                }}
                data-testid="projectSettingsEnvironmentType">
                {map(environments, (environment: EnvDto) => (
                  <MenuItem
                    key={environment?.id}
                    value={environment?.id}
                    data-testid="projectSettingsEnvironmentTypeMenuItem">
                    <EnvironmentMenuItem
                      environmentTypes={environmentTypes}
                      environment={environment}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      )}
    </>
  );
};

export default EnvironmentType;
