import React, { useMemo, useState } from "react";
import { InfoOutlined, Search } from "@material-ui/icons";
import {
  Popover,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { filter, includes, isEmpty, keys, map, sortBy, ceil } from "lodash";

import { Table } from "src/components";
import useGetSynchedData from "src/hooks/api/dataSources/useGetSynchedData";
import Pagination from "src/components/Table/Pagination";
import CommonLoader from "src/components/CommonLoader";

interface IProps {
  connectorId: string;
}

const useStyles = makeStyles({
  entity: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    padding: "4px 6px",
    width: "inherit",
    justifyContent: "space-between",
    borderBottom: "0.5px solid #D1D1D1"
  },
  entityText: {
    fontWeight: 400,
    fontSize: "14px",
    width: "174px"
  },
  tableWrap: {
    padding: "1px",
    overflow: "auto",
    minWidth: "300px",
    maxWidth: "500px",
    maxHeight: "300px"
  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: "6px"
  },
  header: {
    padding: "10px 26px",
    fontWeight: 600,
    maxWidth: "350px"
  },
  infoIcon: {
    fontSize: "18px",
    marginBottom: "2px",
    "&:hover": {
      cursor: "pointer"
    }
  },
  search: {
    color: "#7c7c7c",
    minHeight: "34px",
    height: "100%",
    margin: "0px",
    width: "100%",
    borderTop: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "& fieldset": {
      border: "none"
    },
    "& div[class*='MuiInputBase-root']": {
      height: "34px",
      width: "100%",
      color: "#7c7c7c"
    },
    "& input[class*='MuiInputBase-input']": {
      height: "34px",
      fontSize: "14px",
      width: "100%"
    }
  }
});

const tableColumns = [
  {
    id: "fetched",
    accessor: "fetched",
    Header: "Fetched"
  },
  {
    id: "source",
    accessor: "source",
    Header: "Source"
  }
];
const pageSize = 100;

const TablesComparisionPopover: React.FC<IProps> = (props) => {
  const { connectorId } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const synchDataResult = useGetSynchedData(
    connectorId,
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchPhrase, setSearchPhrase] = useState<string>();
  const classes = useStyles();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const { data, totalPages } = useMemo(() => {
    let totalPages: number;
    let data: any[];
    if (synchDataResult.data) {
      const sourceTables = synchDataResult.data.sourceTables;
      const fetchedTables = keys(synchDataResult.data.tables);
      const filteredSourcetables = sortBy(
        filter(sourceTables, (item) => (searchPhrase ? includes(item, searchPhrase) : true))
      );
      data = map(filteredSourcetables, (item) => ({
        source: item,
        fetched: includes(fetchedTables, item) ? item : undefined
      }));
      totalPages = ceil(synchDataResult.data.actualTableCount / pageSize);
    } else {
      data = [];
      totalPages = 0;
    }
    return { data, totalPages };
  }, [synchDataResult.data, searchPhrase]);

  const handleSearch = (value: string) => {
    if (value) {
      setSearchPhrase(value);
    } else {
      setSearchPhrase(undefined);
    }
  };

  const handleOpen = (target: any) => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <InfoOutlined
        className={classes.infoIcon}
        fontSize="small"
        onClick={(event) => handleOpen(event.currentTarget)}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handleClose}>
        <div className={classes.header}>
          <Typography>Tables</Typography>
        </div>
        <TextField
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            onKeyDown: (e) => e.stopPropagation(),
            startAdornment: (
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            )
          }}
          placeholder="Search tables(Applicable for data shown in each page)"
          className={classes.search}
          id="column-search-input"
          name="column-search-input"
          variant="outlined"
        />

        <Grid container justifyContent="center" className={classes.tableWrap}>
          {synchDataResult.isLoading ? (
            <CommonLoader />
          ) : isEmpty(data) && searchPhrase ? (
            <Typography>No matching result Found</Typography>
          ) : (
            <>
              <Table
                data={data}
                columns={tableColumns}
                hideSettings
                hideCount
                size="small"
                maxHeight="240px"
                unsorted
              />
              <Pagination
                pageCount={totalPages}
                actualPage={currentPage}
                canNextPage={currentPage < totalPages - 1}
                canPreviousPage={currentPage > 1}
                gotoPage={handlePageChange}
                nextPage={() => handlePageChange(currentPage + 1)}
                previousPage={() => {
                  handlePageChange(currentPage - 1);
                }}
              />
            </>
          )}
        </Grid>
      </Popover>
    </>
  );
};

export default TablesComparisionPopover;
