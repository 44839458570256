import React from "react";
import { useParams } from "react-router";
import { Grid, makeStyles } from "@material-ui/core";

import { useGetEnvironments, useGetEnvironmentTypes, useGetEnvironmentById } from "src/hooks/api";

import { CommonLoader } from "src/components";
import SomethingWentWrong from "src/components/Errors/SomethingWentWrong";
import Environment from "./Environment";

const useStyles = makeStyles({
  root: {
    minHeight: "calc(100vh - 94px)"
  }
});

export const EnvironmentDataWrapper = () => {
  const classes = useStyles();

  const { envId } = useParams();

  // Query hooks - STARTS >>
  const { data: environmentsTypes, isLoading: isEnvironmentsTypesLoading } =
    useGetEnvironmentTypes();

  const {
    isLoading: isEnvironmentLoading,
    data: environment,
    refetch
  } = useGetEnvironmentById(envId!, { refetchOnMount: true });

  const { data: environments, isLoading: isEnvironmentsLoading } = useGetEnvironments({
    refetchOnMount: true
  });
  // << ENDS - Query hooks

  const fetchEnvironmentData = async () => {
    const result = await refetch();
    return result.data;
  };

  return (
    <Grid container direction="column" alignItems="stretch" className={classes.root}>
      {isEnvironmentLoading || isEnvironmentsTypesLoading ? (
        <CommonLoader />
      ) : !environment || !environmentsTypes?.length ? (
        <SomethingWentWrong heading="Error in fetching the environment details" />
      ) : (
        <Environment
          environment={environment}
          environments={environments}
          environmentsTypes={environmentsTypes}
          fetchEnvironmentData={fetchEnvironmentData}
          isEnvironmentsLoading={isEnvironmentsLoading}
        />
      )}
    </Grid>
  );
};

export default EnvironmentDataWrapper;
