import React from "react";
import { Box, makeStyles } from "@material-ui/core";

import { AddModelsTable } from "./AddModelsTable";
import { Modal } from "src/components/custom";
import { SearchField } from "src/components";
import { Model } from "src/types/Model.type";
import { handleResponse } from "src/utils/apiService";
import { useGetModels } from "hooks/api/models/useGetModels";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import CommonLoader from "src/components/CommonLoader";

type Props = {
  open: boolean;
  onAddModels: (modelNames: Array<string>) => void;
  selectedModels?: Array<Model>;
  shouldDisableInitialSelectedRows?: boolean;
  isSubmitInProgress?: boolean;
  onClose: () => void;
};

// $FixMe: Scope to be refactored.
// The below styles are added only to support New UX. It shall be removed soon.
const useStyles = makeStyles(() => ({
  searchField: {
    "& [class^='MuiInputBase-root']": {
      background: "none",
      border: "1px solid #ccc",
      borderRadius: 4
    },
    "& input": {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: "small"
    }
  }
}));

export const AddModelsModal: React.FC<Props> = (props) => {
  const {
    open,
    selectedModels = [],
    shouldDisableInitialSelectedRows,
    isSubmitInProgress,
    onAddModels,
    onClose
  } = props;

  const classes = useStyles();

  const [selectedModelNames, setSelectedModelNames] = React.useState<Array<any>>([]);
  const [searchValue, setSearchValue] = React.useState("");

  const { data, isLoading } = useGetModels(
    {},
    {
      refetchOnMount: true,
      onError: (error: any) => {
        handleResponse({
          errorMessage: error.response?.data?.msg || error.message || "Error in fetching Models"
        });
      },
      enabled: open
    }
  );

  const handleSubmit = () => {
    onAddModels(selectedModelNames);
  };

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const selectedRowIds = React.useMemo(() => {
    const modelNames = selectedModels.map((model: Model) => model.name);
    return data?.reduce((acc, model: Model) => {
      return modelNames.includes(model.name) ? { ...acc, [model.name]: true } : acc;
    }, {});
  }, [data, selectedModels]);

  return (
    <NewThemeWrapper>
      {open ? (
        <Modal
          open={open}
          size="lg"
          title="Add Models"
          submitLabel="Add Model"
          isSubmitDisabled={selectedModelNames.length === 0 || isSubmitInProgress}
          onClose={onClose}
          onSubmit={handleSubmit}>
          {isLoading || !data ? (
            <CommonLoader />
          ) : (
            <>
              <Box pb="16px">
                <SearchField
                  size="small"
                  placeholder="Search Model"
                  value={searchValue}
                  onChange={handleSearchValueChange}
                  className={classes.searchField}
                />
              </Box>
              <AddModelsTable
                data={data}
                globalFilter={searchValue}
                onSelectedRowsChange={setSelectedModelNames}
                selectedRowIds={selectedRowIds}
                shouldDisableInitialSelectedRows={shouldDisableInitialSelectedRows}
              />
            </>
          )}
        </Modal>
      ) : (
        <></>
      )}
    </NewThemeWrapper>
  );
};
