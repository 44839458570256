import React, { useState } from "react";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

// Icons
import { PlusIcon } from "src/icons/NewUX/PlusIcon";

// Hooks
import useRecipesStatus from "./hooks/useRecipesStatus";
import useActions from "./hooks/useActions";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./components/SubTopNavBarBreadcrumbs";
import CommonLoader from "src/components/CommonLoader";
import Search from "src/components/custom/Search/Search";
import ScenariosTable from "./components/ScenariosTable";
import ConfirmRunWithReloadDatasets from "../../components/ConfirmRunWithReloadDatasets";
import DeleteScenario from "./components/DeleteScenario";

// Contexts
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";

const Scenarios = () => {
  const { project } = useProjectContext() || {};

  // States - STARTS >>
  const [searchValue, setSearchValue] = useState("");
  const [currentScenarioId, setCurrentScenarioId] = useState<undefined | null | string>("");
  const [showConfirmRunWithReloadDatasetsModal, setShowConfirmRunWithReloadDatasetsModal] =
    useState(false);
  const [showConfirmDeleteScenarioModal, setShowConfirmDeleteScenarioModal] = useState(false);
  // << ENDS - States

  const {
    isLoading,
    isRefetchingProjectCanvas,

    isRecipesInProject,
    isRecipesRunningAcrossScenariosStore,

    isConnectorDatasets,

    scenariosData,
    refetchScenarios
  } = useRecipesStatus();
  const { onAdd, onEdit, onDisplayOnDag, onRun } = useActions();

  // Delete scenario - STARTS >>
  const promptConfirmDeleteScenario = (scenarioId?: string) => {
    setCurrentScenarioId(() => scenarioId);
    setShowConfirmDeleteScenarioModal(() => true);
  };

  const resetDeleteScenario = () => {
    setCurrentScenarioId(() => "");
    setShowConfirmDeleteScenarioModal(() => false);
  };
  // << ENDS - Delete scenario

  // Confirm run with reload datasets - STARTS >>
  const promptConfirmRunWithReloadDatasets = (scenarioId?: string) => {
    setCurrentScenarioId(() => scenarioId);
    setShowConfirmRunWithReloadDatasetsModal(() => true);
  };

  const resetConfirmRunWithReloadDatasets = () => {
    setCurrentScenarioId(() => "");
    setShowConfirmRunWithReloadDatasetsModal(() => false);
  };

  const confirmRunWithReloadDatasets = () => {
    onRun(currentScenarioId, true);
    resetConfirmRunWithReloadDatasets();
  };
  // << ENDS - Confirm run with reload datasets

  const handleRun = (scenarioId?: string, reloadDatasets = false) => {
    if (!!reloadDatasets) {
      promptConfirmRunWithReloadDatasets(scenarioId);
      return;
    }

    onRun(scenarioId);
  };

  return (
    <>
      {showConfirmRunWithReloadDatasetsModal && !!currentScenarioId && (
        <ConfirmRunWithReloadDatasets
          resetConfirmRunWithReloadDatasets={resetConfirmRunWithReloadDatasets}
          runWithReloadDatasets={confirmRunWithReloadDatasets}
        />
      )}

      {showConfirmDeleteScenarioModal && !!currentScenarioId && (
        <DeleteScenario
          deletingScenarioId={currentScenarioId}
          resetDeleteScenario={resetDeleteScenario}
          refetch={refetchScenarios}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: <SubTopNavBarBreadcrumbs project={project} />
        }}
        {...(!isLoading
          ? {
              subTopNavBarRightSection: {
                component: (
                  <>
                    <Search
                      onSearch={(event) => setSearchValue(() => event?.target?.value)}
                      placeholder="Search scenario"
                      data-testid="segmentsSearch"
                    />

                    <IconButton
                      color="primary"
                      size="small"
                      onClick={onAdd}
                      data-testid="segmentsAddSegmentAction">
                      <PlusIcon width={28} height={28} />
                    </IconButton>
                  </>
                )
              }
            }
          : {})}
      />

      <Box ml="60px" p={2} height="calc(100% - 44px)">
        <Grid container>
          {isLoading ? (
            <CommonLoader />
          ) : (
            <ScenariosTable
              data={scenariosData}
              isRefetchingProjectCanvas={isRefetchingProjectCanvas}
              isRecipesInProject={isRecipesInProject}
              isRecipesRunningAcrossScenariosStore={isRecipesRunningAcrossScenariosStore}
              isConnectorDatasets={isConnectorDatasets}
              onEdit={onEdit}
              onDelete={promptConfirmDeleteScenario}
              onRun={handleRun}
              onDisplayOnDag={onDisplayOnDag}
              searchValue={searchValue}
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Scenarios;
