import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import { useGetRecipe } from "src/hooks/api";
import CodeRecipeContainer from "./CodeRecipeContainer";
import { CodeRecipeContextProvider } from "./CodeRecipeContext/CodeRecipeContextProvider";
import CommonLoader from "src/components/CommonLoader";

const CodeRecipeDataContainer = () => {
  const { groupId, scenarioId, jobRunId } = useParams<$TSFixMe>();
  const [key, setKey] = useState("");
  const { isLoading, data, isSuccess } = useGetRecipe({
    recipeId: groupId,
    scenarioId,
    jobRunId,
    options: { refetchOnMount: true }
  });

  useEffect(() => {
    if (isSuccess) {
      setKey(Date.now().toString());
    }
  }, [isSuccess]);

  const { entityIds, artifactsIds, modelsIds, outputs, charts } = useMemo(() => {
    return {
      entityIds: data?.recipe?.parents
        ?.filter((parent) => parent.type === "ENTITY")
        ?.map((parent) => parent.id),
      artifactsIds: data?.recipe?.parents
        ?.filter((parent) => parent.type === "ARTIFACT")
        ?.map((parent) => parent.id),
      modelsIds: data?.recipe?.parents
        ?.filter((parent) => parent.type === "MODEL")
        ?.map((parent) => parent.id),
      outputs: data?.recipe?.children
        ?.filter((child) => child.type === "ENTITY")
        ?.map((output) => output.name),
      charts: data?.recipe?.children
        ?.filter((child) => child.type === "CHART")
        ?.map((output) => output.name)
    };
  }, [data?.recipe]);

  if (!groupId) {
    return (
      <CodeRecipeContextProvider>
        <CodeRecipeContainer />
      </CodeRecipeContextProvider>
    );
  }

  return isLoading ? (
    <CommonLoader />
  ) : (
    <div key={key}>
      <CodeRecipeContextProvider
        templates={data?.templates}
        recipe={data?.recipe}
        outputs={outputs}
        isLoading={isLoading}>
        <CodeRecipeContainer
          entityIds={entityIds}
          outputs={outputs}
          artifactsIds={artifactsIds}
          modelsIds={modelsIds}
          charts={charts}
        />
      </CodeRecipeContextProvider>
    </div>
  );
};

export default CodeRecipeDataContainer;
