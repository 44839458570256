import React from "react";
import { Button, TextField, Typography, makeStyles, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";

import { useGetUserSettings, useSaveUserSettingsMutation } from "src/hooks/api";
import { handleResponse } from "src/utils/apiService";
import { Spinner } from "src/components";
import { isEmpty } from "lodash";
import CommonLoader from "src/components/CommonLoader";

const emailPattern =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = makeStyles({
  subTitle: {
    fontSize: "14px",
    color: "#000000",
    opacity: 0.5,
    paddingBottom: "20px"
  },
  githubProfile: {
    flexWrap: "nowrap",
    gap: "20px"
  }
});

export const GithubProfile = () => {
  const styles = useStyles();
  const saveUserSettingsMutation = useSaveUserSettingsMutation();
  const [savedUserName, setSavedUserName] = React.useState("");
  const [savedUserEmail, setSavedUserEmail] = React.useState("");
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm();

  const currUserName = watch("ghUserFullName");
  const currUserEmail = watch("ghUserEmail");

  const { isLoading, isFetching } = useGetUserSettings({
    refetchOnMount: true,
    onSuccess: (settings) => {
      if (settings) {
        setSavedUserName(settings.ghUserFullName);
        setSavedUserEmail(settings.ghUserEmail);
        setValue("ghUserFullName", settings.ghUserFullName);
        setValue("ghUserEmail", settings.ghUserEmail);
      }
    }
  });

  const onSubmit = async (data: $TSFixMe) => {
    saveUserSettingsMutation.mutate(
      {
        ghUserFullName: data.ghUserFullName,
        ghUserEmail: data.ghUserEmail
      },
      {
        onSuccess: (settings) => {
          setSavedUserName(settings.ghUserFullName);
          setSavedUserEmail(settings.ghUserEmail);
          handleResponse({ successMessage: "Github Settings saved successfully" });
        }
      }
    );
  };

  return isLoading || isFetching ? (
    <CommonLoader />
  ) : (
    <Grid container direction="column">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography className={styles.subTitle}>Github Settings</Typography>
        <Grid container direction="column" className={styles.githubProfile}>
          <TextField
            size="small"
            required
            {...register("ghUserFullName", {
              required: "The user name cannot be blank.",
              maxLength: {
                value: 255,
                message: "User name needs to be less than 255 characters"
              },
              setValueAs: (val: string) => val?.trim()
            })}
            error={!!errors.ghUserFullName}
            helperText={errors.ghUserFullName && <>{errors.ghUserFullName?.message}</>}
            label="Full Name"
            variant="outlined"
          />
          <TextField
            size="small"
            required
            {...register("ghUserEmail", {
              required: "The user email cannot be blank.",
              validate: {
                isValidPassword: (value) => {
                  if (value && !emailPattern.test(value)) {
                    return "Email format invalid";
                  }
                  return true;
                }
              },
              setValueAs: (val: string) => val?.trim()
            })}
            error={!!errors.ghUserEmail}
            helperText={errors.ghUserEmail && <>{errors.ghUserEmail?.message}</>}
            label="Email"
            variant="outlined"
          />
          <Grid container direction="row-reverse">
            <Button
              size="small"
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                saveUserSettingsMutation.isLoading ||
                isLoading ||
                !isEmpty(errors) ||
                !currUserEmail ||
                !currUserName ||
                (savedUserEmail === currUserEmail && savedUserName === currUserName)
              }>
              {saveUserSettingsMutation.isLoading ? (
                <Spinner noPadding size={22} />
              ) : savedUserEmail && savedUserName ? (
                "Update"
              ) : (
                "Save"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
