import React, { useMemo, useState } from "react";

// Packages
import { v4 as uuidv4 } from "uuid";

// MUI
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Typography,
  useTheme,
  makeStyles
} from "@material-ui/core";

// Icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Publish from "@material-ui/icons/Publish";
import { ArrowDownIcon } from "src/icons/NewUX/ArrowDownIcon";
import { WorkflowIcon } from "src/icons/NewUX/WorkflowIcon";

// Utils
import { capitalizeFirstLetter } from "src/utils/capitalize";

// Components
import OverflowTooltip from "src/components/OverflowTooltip";
import ScenariosMenu from "./ScenariosMenu";

const useStyles = makeStyles({
  root: {
    display: "flex",
    marginLeft: "auto",
    columnGap: 10,
    alignItems: "center",
    justifyContent: "flex-end"
  },
  moreAction: {
    minHeight: 28,
    fontWeight: 400
  },
  menuItemIcon: {
    minWidth: "auto",
    marginRight: 8
  }
});

const MoreOptionsMenu = (props: $TSFixMe) => {
  const { menuActionLabel, projectId, scenarios, currentScenario, isPublishJob, publishJob } =
    props || {};

  const theme = useTheme();
  const classes = useStyles();

  // States - STARTS >>
  const [isScenariosMenuOpen, setIsScenariosMenuOpen] = useState(false);
  // ENDS - States

  // Drop-down code - STARTS >>
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event: $TSFixMe) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
    setIsScenariosMenuOpen(false);
  };
  // ENDS - Drop-down

  const menuItems = useMemo(
    () =>
      !!isScenariosMenuOpen
        ? [
            <MenuItem key={uuidv4()} onClick={() => setIsScenariosMenuOpen(false)}>
              <ListItemIcon className={classes.menuItemIcon}>
                <ChevronLeftIcon />
              </ListItemIcon>
              <ListItemText
                primary="Scenarios"
                primaryTypographyProps={{
                  variant: "body2",
                  style: { opacity: 0.5 }
                }}
              />
            </MenuItem>
          ]
        : [
            <MenuItem key={uuidv4()} disabled>
              <ListItemText
                primary="Scenario"
                primaryTypographyProps={{
                  variant: "body2"
                }}
              />
            </MenuItem>,
            <MenuItem key={uuidv4()} onClick={() => setIsScenariosMenuOpen(true)}>
              <ListItemIcon className={classes.menuItemIcon}>
                <WorkflowIcon />
              </ListItemIcon>
              <OverflowTooltip
                value={
                  <Typography variant="body2" component="span">
                    {capitalizeFirstLetter(currentScenario?.name)}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <ChevronRightIcon fontSize="small" />
              </ListItemSecondaryAction>
            </MenuItem>,
            ...(!!isPublishJob
              ? [
                  <MenuItem
                    key={uuidv4()}
                    onClick={() => {
                      publishJob();
                      onClose();
                    }}>
                    <ListItemIcon className={classes.menuItemIcon}>
                      <Publish fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Publish to Jobs"
                      primaryTypographyProps={{
                        variant: "body2"
                      }}
                    />
                  </MenuItem>
                ]
              : [])
          ],
    [isScenariosMenuOpen, currentScenario?.name, isPublishJob]
  );

  return (
    <>
      {!!menuActionLabel ? (
        <Button
          color="default"
          size="small"
          className={classes.moreAction}
          endIcon={<ArrowDownIcon color={theme.palette.text.primary} opacity={0.7} />}
          onClick={onOpen}>
          {menuActionLabel}
        </Button>
      ) : (
        <Button color="primary" size="small" onClick={onOpen}>
          <ArrowDownIcon color={theme.palette.text.primary} opacity={0.3} style={{ margin: 9 }} />
        </Button>
      )}
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={onClose}
        PaperProps={{
          style: {
            marginTop: 6,
            width: 225,
            borderRadius: 12
          }
        }}>
        {menuItems}
        {isScenariosMenuOpen && (
          <ScenariosMenu projectId={projectId} scenarios={scenarios} onClose={onClose} />
        )}
      </Menu>
    </>
  );
};

export default MoreOptionsMenu;
