import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: 110,
    left: 15,
    zIndex: 10
  },
  menuItem: {
    justifyContent: "center",
    padding: 0
  },
  menuItemButton: {
    margin: 4,
    padding: 4,
    borderRadius: "25%",
    "&.size": {
      marginBottom: 0,
      paddingBottom: 0
    },
    "&.active": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      paddingBottom: 4
    }
  },
  loader: {
    margin: 4
  }
}));
