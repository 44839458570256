import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";

import NewModal from "components/Modal/NewModal";
import useAddPredictionOutputToCanvas from "hooks/api/projects/useAddPredictionOutputToCanvas";
import { validateNameField } from "utils/formFieldUtils";

interface IProps {
  disabled: boolean;
  projectId: string;
  path: string;
  predictionJobName: string;
}

const unique = Date.now().toString().slice(-6);

const AddOutputToCanvas: React.FC<IProps> = (props) => {
  const { predictionJobName, projectId, path, disabled } = props;
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(`${predictionJobName}_output_${unique}`);
  }, [predictionJobName]);

  const { isValid, error } = validateNameField({
    fieldName: value.trim(),
    fieldNameLabel: `prediction output`,
    maxFieldLength: 64
  });

  const addToCanvas = useAddPredictionOutputToCanvas();

  const handleClick = () => {
    setOpen(true);
  };

  const handleAddToCanvas = () => {
    addToCanvas.mutate(
      {
        projectId,
        name: value,
        path
      },
      {
        onSuccess: () => setOpen(false)
      }
    );
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        data-testid="predict-job-add-to-canvas"
        disabled={disabled || addToCanvas.isLoading}
        size="small"
        onClick={handleClick}>
        Add to canvas
      </Button>
      <NewModal
        open={open}
        header="Update Name"
        loading={addToCanvas.isLoading}
        width="465px"
        height={350}
        background="#fff"
        isDefaultButtonCustomTheming
        submitButtonLabel="Add to Canvas"
        submitDisabled={disabled || !isValid || addToCanvas.isLoading}
        onFinish={handleAddToCanvas}
        onClose={() => setOpen(false)}>
        <TextField
          fullWidth
          data-testid="output-dataset-name"
          name="dataset"
          label="Prediction Output Dataset Name"
          value={value}
          error={!isValid}
          helperText={error}
          size="small"
          variant="outlined"
          onChange={(e) => setValue(e.target.value)}
        />
      </NewModal>
    </>
  );
};

export default AddOutputToCanvas;
