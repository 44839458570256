import React from "react";
import shallow from "zustand/shallow";
import { Grid, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import SearchField from "src/components/Inputs/SearchField";
import useTenantsStore from "src/stores/tenant-management.store";
import InviteUsersModal from "./InviteUsersModal";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";

interface Props {
  filterValue: string;
  // eslint-disable-next-line no-unused-vars
  setFilterValue: (_: string) => void;
}

const useStyles = makeStyles({
  subHeading: {
    fontSize: "14px"
  },
  actionsWrap: {
    gap: "20px"
  },
  buttonIcon: {
    marginRight: "8px"
  },

  // @REFACTOR
  // The below styles are added only to support New UX. It shall be removed soon.
  searchField: {
    "& [class^='MuiInputBase-root']": {
      background: "none",
      border: "1px solid #ccc",
      borderRadius: 4
    },
    "& input": {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: "small"
    }
  }
});

const UsersSearch = ({ filterValue, setFilterValue }: Props) => {
  const classes = useStyles();

  const inputGA = React.useRef(false);

  const handleUserSearch = React.useCallback(
    (event: React.ChangeEvent) => {
      const target = event.target as HTMLButtonElement;
      setFilterValue(target.value);
      if (!inputGA) {
        (inputGA as $TSFixMe).current = true;
      }
    },
    [setFilterValue]
  );
  return (
    <SearchField
      placeholder="Search Users"
      value={filterValue}
      onChange={handleUserSearch}
      className={classes.searchField}
    />
  );
};

const AddUsers = () => {
  const [showAddUsersModal, setShowAddUsersModal] = React.useState(false);
  const { canAddUsers } = useAccessControlContext();
  const [roles] = useTenantsStore((state) => [state.roles], shallow);

  const styles = useStyles();

  const handleInviteUsersModalOpen = React.useCallback(() => {
    setShowAddUsersModal(true);
  }, []);

  return (
    <>
      <Button
        onClick={handleInviteUsersModalOpen}
        variant="contained"
        color="primary"
        disabled={!canAddUsers}>
        <Add className={styles.buttonIcon} />
        Add users
      </Button>
      {showAddUsersModal && (
        <InviteUsersModal roleById={roles} onClose={() => setShowAddUsersModal(false)} />
      )}
    </>
  );
};

const TableActions = ({ filterValue, setFilterValue }: Props) => {
  const styles = useStyles();
  return (
    <Grid container className={styles.actionsWrap}>
      <UsersSearch filterValue={filterValue} setFilterValue={setFilterValue} />
      <AddUsers />
    </Grid>
  );
};

export default TableActions;
