import { makeStyles } from "@material-ui/core/styles";

type StyleProps = {
  maxHeight?: string;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative"
  },
  root: {
    overflowX: "auto",
    maxHeight: (props: StyleProps) => props?.maxHeight || "100%"
  },
  table: {
    width: "auto",
    borderCollapse: "collapse" // Collapse border spacing
  },
  actionsContainer: {
    width: "auto",
    position: "absolute",
    top: -66,
    right: 44,
    columnGap: theme.spacing(2)
  }
}));

export default useStyles;
