import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";

export const enum UsePublishJobsQueryKeys {
  PublishJobs = "publishJobs"
}

interface IPublishJobProps {
  projectId?: string;
}

const usePublishJob = (props?: IPublishJobProps): UseMutationResult<any, unknown, any, unknown> => {
  const { projectId } = props || {};

  return useMutation({
    mutationKey: [UsePublishJobsQueryKeys.PublishJobs, projectId],
    mutationFn: async (jobId: string) =>
      await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.publishProjectRunMeta(jobId)
      )
  });
};

export default usePublishJob;
