// Packages
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { find } from "lodash";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

type EnvironmentsState = {
  environments: EnvDto[];
};

const initialState: EnvironmentsState = {
  environments: []
};

const environmentsSlice = createSlice({
  name: "environments",
  initialState,
  reducers: {
    setEnvironments(state, action: PayloadAction<EnvDto[]>) {
      state.environments = action.payload;
    },
    updateEnvironmentStatus(state, action: PayloadAction<EnvDto>) {
      const environment = find(state.environments, { id: action.payload.id });
      if (environment) {
        environment.launchStatus = action.payload.launchStatus;
      }
    }
  }
});

export const { setEnvironments, updateEnvironmentStatus } = environmentsSlice.actions;
export default environmentsSlice.reducer;
