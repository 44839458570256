import React, { PropsWithChildren } from "react";
import { LockOutlined } from "@material-ui/icons";
import { AxiosError } from "axios";

import AppLoadingScreen from "./components/Screens/AppLoadingScreen";
import Result from "./components/Errors/Result";
import api from "./utils/AxiosClient";
import useAuthStore from "./stores/auth.store";
import useCheckTokenValidation from "./hooks/api/auth/useCheckTokenValidation";
import { useAuthSetAuthResponse } from "./hooks/useAuthSetAuthResponse";
import { useQuery } from "./hooks";
import { useValidateSSOToken } from "./hooks/api/auth/useValidateSSOToken";

const AuthProvider: React.FC<PropsWithChildren<{}>> = (props) => {
  const queryParameters = useQuery();
  const hasInviteTenantRequestParams = queryParameters.get("invitation_details");
  const details = queryParameters.get("details");
  const { setAuthResponse } = useAuthSetAuthResponse();
  const [setIsNonSSOFlow] = useAuthStore((state) => [state.setIsNonSSOFlow]);

  const token = api.getToken();

  const {
    isFetching: isLoadingSignInType,
    isSuccess,
    data: tokenResponse,
    isError,
    error
  } = useValidateSSOToken({
    enabled: !token,
    onSuccess: (response) => {
      if (response.status === 203 && response.headers["location"]) {
        window.location.href = response.headers["location"];
      } else if (response.status === 204) {
        setIsNonSSOFlow(true);
      } else if (response.status === 200) {
        response.data.token &&
          setAuthResponse({
            data: response.data
          });
      }
    },
    onError: (error: any) => {
      const errorCode = (error as AxiosError)?.response?.status;
      const isSSOEnabledFromStorage = localStorage.getItem("isSSOEnabled");
      if (errorCode && isSSOEnabledFromStorage && errorCode >= 500 && errorCode < 600) {
        const isUserSSOEnabled = isSSOEnabledFromStorage === "true";
        setIsNonSSOFlow(!isUserSSOEnabled);
      }
    }
  });

  const { isFetching } = useCheckTokenValidation({
    onSuccess: (data) => {
      if (data) {
        setAuthResponse({
          data
        });
      }
    },
    enabled:
      !!token ||
      (isSuccess && !tokenResponse?.data?.ssoEnabled && !hasInviteTenantRequestParams && !details)
  });
  const errorCode = (error as AxiosError)?.response?.status;

  if (isError && errorCode === 403) {
    return (
      <Result
        icon={<LockOutlined fontSize="large" />}
        heading="403 Forbidden"
        subtitle1="You don't have permission to access this resource."
      />
    );
  }

  if (isFetching || isLoadingSignInType) {
    return <AppLoadingScreen requestPending />;
  }

  return <>{props.children}</>;
};

export default AuthProvider;
