import React from "react";

import ReactExceptionIcon from "src/icons/ReactExceptionIcon";
import Result from "./Result";

interface IProps {
  heading: string;
  subtitle1?: string;
  extra?: JSX.Element;
  hideMargin?: boolean;
}

const SomethingWentWrong: React.FC<IProps> = (props) => {
  return <Result icon={<ReactExceptionIcon />} {...props} />;
};

export default SomethingWentWrong;
