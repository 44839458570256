import React, { useMemo, useState } from "react";

// Packages
import { includes, isEmpty, isEqual, isNil, map, size } from "lodash";

// Icons
import { FilterIcon } from "src/icons/FilterIcon";

// MUI
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Checkbox from "@material-ui/core/Checkbox";

// Constants
import { EnvironmentsHelperText } from "../utils/Environments.constants";
import { snakeCaseToStartCase } from "src/utils/helpers";

interface IProps {
  environmentTypesData?: any[];
  selectedEnvTypes: string[];
  onEnvTypeSelect: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedEnvType?: string
  ) => void;
}

const EnvironmentTypeFilter: React.FC<IProps> = (props) => {
  const { environmentTypesData, selectedEnvTypes, onEnvTypeSelect } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const toggleEnvTypeFilter = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl((prevEl) => (isNil(prevEl) ? event?.currentTarget : null));

  const envTypeNames = useMemo(() => map(environmentTypesData, "name"), [environmentTypesData]);

  const isSelectedAll = useMemo(
    () => isEqual(size(envTypeNames), size(selectedEnvTypes)),
    [envTypeNames, selectedEnvTypes]
  );

  const handleEnvTypeSelection = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedEnvType?: string
  ) => {
    onEnvTypeSelect(event, selectedEnvType);
  };

  const close = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!!Boolean(anchorEl) && (
        <Menu
          open
          anchorEl={anchorEl}
          onClose={close}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}>
          <MenuItem data-testid="envsFilterSelectAllMenuItem">
            <Checkbox color="default" checked={isSelectedAll} onClick={handleEnvTypeSelection} />
            {EnvironmentsHelperText.SelectAll}
          </MenuItem>
          {map(envTypeNames, (envTypeName: string, index: number) => (
            <MenuItem
              key={`envTypeName_${index}`}
              data-testid={`envsFilterEnvTypeNameMenuItem_${index}`}>
              <Checkbox
                checked={includes(selectedEnvTypes, envTypeName)}
                color="default"
                onClick={(event) => {
                  handleEnvTypeSelection(event, envTypeName);
                }}
                data-testid={`envsFilterEnvTypeNameCheckbox_${index}`}
              />
              <Box data-testid={`envsFilterEnvTypeName_${index}`}>
                {snakeCaseToStartCase(envTypeName, { toUpperCase: ["cpu"] })}
              </Box>
            </MenuItem>
          ))}
        </Menu>
      )}

      <IconButton
        onClick={toggleEnvTypeFilter}
        disabled={isEmpty(envTypeNames)}
        data-testid="envsFilterAction">
        <Badge color="error" variant="dot" overlap="rectangular" invisible={isSelectedAll}>
          <FilterIcon data-testid="envsFilterIcon" />
        </Badge>
      </IconButton>
    </>
  );
};

export default EnvironmentTypeFilter;
