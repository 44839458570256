import { CodeCheckpointDto } from "@rapidcanvas/rc-api-core";
import { toLower } from "lodash";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Field } from "src/components";
import { Modal } from "src/components/custom";
import { useUpdateCodeCheckpoint } from "src/hooks/api/codeCheckpoints/useUpdateCodeCheckpoint";
import { handleResponse } from "src/utils/apiService";
import { validateNameField } from "src/utils/formFieldUtils";

export const EditCheckpointNameModal = ({
  value,
  open,
  data,
  recipeId,
  onClose
}: {
  open: boolean;
  data: CodeCheckpointDto[];
  value: string;
  onClose: () => void;
  recipeId: string;
}) => {
  const currCheckpoint = data.find((checkpoint) => checkpoint.name! === value);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues: { checkpointName: currCheckpoint?.name || "" }
  });

  const updateCodeCheckpoint = useUpdateCodeCheckpoint();

  const onSubmit = (data: { checkpointName: string }) => {
    updateCodeCheckpoint.mutate(
      {
        checkpointId: currCheckpoint?.id!,
        request: { name: data.checkpointName },
        recipeId
      },
      {
        onSuccess: () => {
          handleResponse({
            successMessage: `Checkpoint ${data.checkpointName} updated successfully`
          });
        },
        onSettled: () => {
          onClose();
        }
      }
    );
  };

  const disableSubmit = !!errors.checkpointName;

  return (
    <Modal
      open={open}
      size="sm"
      title="Edit Checkpoint Name"
      cancelLabel="Close"
      submitLabel="Save"
      isSubmitDisabled={disableSubmit}
      isSubmitting={updateCodeCheckpoint.isLoading}
      onClose={onClose}
      hideCloseIcon
      onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="checkpointName"
        control={control}
        rules={{
          required: "Checkpoint Name is required",
          validate: (value) => {
            if (
              Boolean(
                data?.find((checkpoint) => toLower(checkpoint.name) === toLower(value.trim()))
              )
            ) {
              return "This checkpoint name already exists";
            }
            const { isValid: isNameValid, error } = validateNameField({
              fieldName: value,
              fieldNameLabel: `checkpoint name`
            });
            if (!isNameValid && error) {
              return error;
            }
            return true;
          }
        }}
        render={({ field }) => (
          <Field
            {...field}
            fullWidth
            data-testid="checkpointName"
            label="Checkpoint Name"
            required
            variant="outlined"
            size="small"
            error={!!errors.checkpointName}
            helperText={errors.checkpointName ? (errors.checkpointName.message as string) : ""}
          />
        )}
      />
    </Modal>
  );
};
