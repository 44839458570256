import create from "zustand";
import { devtools } from "zustand/middleware";
import _, { now } from "lodash";

import {
  RecipeRunData,
  ThreadResponseDto,
  ThreadResponseDtoDatasetContextEnum,
  ThreadResponseDtoTargetTypeEnum
} from "@rapidcanvas/rc-api-core";

import { IConnector } from "pages/DataSources/utils/DataSources.constants";
import {
  SourceType as DatasetSourceType,
  Criteria as DatasetCriteria
} from "src/pages/private/ProjectsModule/pages/Dataset/utils/Dataset.constants";
import { EnvironmentsTypes } from "src/constants/environments.constants";

interface IDataSourceState {
  connectors: IConnector[];
  setConnectors: (connectors: IConnector[]) => void;
}

const inputEntitiesStore = (set: $TSFixMe) => ({
  inputEntities: [],
  setInputEntities: (newInputEntities: $TSFixMe) =>
    set(() => ({ inputEntities: newInputEntities }), false, "SET_INPUT_ENTITITES"),
  selectedInputEntity: {},
  setSelectedInputEntity: (newSelection: $TSFixMe) =>
    set(() => ({ selectedInputEntity: newSelection })),
  crossProjectInputEntities: [],

  setCrossProjectInputEntities: (newCrossProjectInputEntities: $TSFixMe) =>
    set(
      (state: $TSFixMe) => ({
        crossProjectInputEntities: [
          ...state.crossProjectInputEntities,
          newCrossProjectInputEntities
        ]
      }),
      false,
      "SET_CROSS_PROJECT_INPUT_ENTITIES"
    ),

  segments: [],
  setSegments: (newSegments: $TSFixMe) => set(() => ({ segments: newSegments })),
  entityFeatures: [],
  setEntityFeatures: (newEntityFeatures: $TSFixMe) =>
    set(() => ({ entityFeatures: newEntityFeatures })),
  segmentData: [],
  setSegmentData: (newSegmentData: $TSFixMe) => set(() => ({ segmentData: newSegmentData })),
  visibleColumns: [],
  setVisibleColumns: (newVisibleColumns: $TSFixMe) =>
    set(() => ({ visibleColumns: newVisibleColumns }))
});

export interface DataApp {
  id: string;
  ttlInHrs: number | null;
  envType?: keyof typeof EnvironmentsTypes;
  checked: boolean;
  loading: boolean;
}

const dataAppStore = (set: any) => ({
  updatingDataApps: [] as DataApp[],
  setUpdatingDataApps: (updatingDataApps: DataApp[]) => set(() => ({ updatingDataApps }))
});

const useDataAppStore = create(devtools(dataAppStore, { name: "dataapps_store" }));

const projectsStore = (set: $TSFixMe) => ({
  isDirty: false,
  updatingProjectIds: [],
  generatingSnippetsProjects: [],
  setGeneratingSnippetsProjects: (generatingSnippetsProjects: string[]) =>
    set(() => ({ generatingSnippetsProjects })),
  generatingEdaChartsEntities: [],
  setUpdatingProjectIds: (updatingProjectIds: string[]) => set(() => ({ updatingProjectIds })),
  setGeneratingEdaChartsEntities: (generatingEdaChartsEntities: string[]) =>
    set(() => ({ generatingEdaChartsEntities })),
  shouldProjectsRefresh: false,
  reloadTrigger: null,
  setReloadTrigger: () => set(() => ({ reloadTrigger: Date.now() })),
  toggleDirty: (isDirty: $TSFixMe) => set(() => ({ isDirty }), false, "TOGGLE_DIRTY"),
  activeStep: 0,
  setActiveStep: (activeStep: $TSFixMe) => set(() => ({ activeStep }), false, "SET_ACTIVE_STEP"),
  canStep: true,
  setCanStep: (canStep: $TSFixMe) => set(() => ({ canStep }), false, "SET_CAN_STEP"),
  projectList: [],
  setProjectList: (newProjectList: $TSFixMe) =>
    set(() => ({ projectList: newProjectList }), false, "SET_PROJECT_LIST"),
  outputPipelines: [],
  setOutputPipelines: (newOutputPipelines: $TSFixMe) =>
    set(() => ({ outputPipelines: newOutputPipelines }), false, "SET_OUTPUT_PIPELINES"),
  toggleShouldRefresh: () => set(() => ({ shouldProjectsRefresh: Date.now() })),
  setShouldProjectsRefresh: (shouldProjectsRefresh: $TSFixMe) =>
    set(() => ({ shouldProjectsRefresh })),
  generatingAboutProjects: [],
  setGeneratingAboutProjects: (generatingAboutProjects: string[]) =>
    set(() => ({ generatingAboutProjects })),
  modifiedDatasetId: "",
  setModifiedDatasetId: (modifiedDatasetId: string) => set(() => ({ modifiedDatasetId })),
  runningPredictionJobIds: [],
  setRunningPredictionjobIds: (runningPredictionJobIds: string[]) =>
    set(() => ({ runningPredictionJobIds }))
});

const jobsStore = (set: $TSFixMe) => ({
  jobParameters: {
    isValid: false,
    values: []
  },

  shouldJobsRefresh: null,
  watchingJobs: [],
  watchingJobsIntervalId: null,

  setJobParameters: (data: $TSFixMe) => set(() => ({ jobParameters: data })),

  toggleShouldRefresh: () => set(() => ({ shouldJobsRefresh: Date.now() })),
  setWatchingJobs: (data: $TSFixMe) => set(() => ({ watchingJobs: data })),
  setWatchingJobsIntervalId: (data: $TSFixMe) => set(() => ({ watchingJobsIntervalId: data })),

  resetJobParameters: () =>
    set(() => ({
      jobParameters: {
        isValid: false,
        values: []
      }
    }))
});

const dataSourcesStore = (set: $TSFixMe) => ({
  dataSources: [],
  connectors: [],
  dataSourceType: "",
  isLoading: false,
  isCreateDataSource: false,
  newConnectorToSync: null,
  setConnectors: (connectors: $TSFixMe) => set(() => ({ connectors })),
  setIsLoading: (isLoading: $TSFixMe) => set(() => ({ isLoading })),
  setNewConnectorToSync: (newConnectorToSync: $TSFixMe) => set(() => ({ newConnectorToSync })),
  setDataSources: (data: $TSFixMe) => set(() => ({ dataSources: data })),
  setDataSourceType: (data: $TSFixMe) => set(() => ({ dataSourceType: data })),
  setIsCreateDataSource: (data: $TSFixMe) => set(() => ({ isCreateDataSource: data }))
});

const drawerStore = (set: $TSFixMe) => ({
  sideComponent: null,

  setSideComponent: ({ sideComponent, sideComponentProps }: $TSFixMe) =>
    set(() => ({ sideComponent, sideComponentProps }))
});

const runConfigStore = (set: $TSFixMe) => ({
  runConfigs: [],
  configGroup: {},
  setRunConfigs: (runConfigs: $TSFixMe) => set(() => ({ runConfigs }), false, "SET_RUN_CONFIGS"),
  setConfigGroup: (configGroup: $TSFixMe) =>
    set(() => ({ configGroup }), false, "SET_CONFIG_GROUP"),
  previewTabs: [],
  setPreviewTabs: (previewTabs: $TSFixMe) =>
    set(() => ({ previewTabs }), false, "SET_PREVIEW_TABS"),
  entitiesFeatures: {},
  setEntitiesFeatures: (entitiesFeatures: $TSFixMe) =>
    set(() => ({ entitiesFeatures }), false, "SET_ENTITIES_FEATURES")
});

const canvasStore = (set: $TSFixMe) => ({
  nodes: [],
  nodeToFocus: "",
  canvasNodes: [],
  setCanvasNodes: (nodes: $TSFixMe) => set(() => ({ nodes })),
  setNodes: (nodes: $TSFixMe) => set(() => ({ nodes })),
  setNodeToFocus: (nodeToFocus: string) => set(() => ({ nodeToFocus })),
  edges: [],
  setEdges: (edges: $TSFixMe) => set(() => ({ edges })),
  groups: [],
  setGroups: (groups: $TSFixMe) => set(() => ({ groups })),
  isRecipesRunningAcrossScenarios: undefined,
  setIsRecipesRunningAcrossScenarios: (value: boolean | null | undefined) =>
    set({ isRecipesRunningAcrossScenarios: value }),
  openRecipeRunsQueue: false,
  setOpenRecipeRunsQueue: (value: boolean) => set({ openRecipeRunsQueue: value }),
  isPendingRecipeRunsInQueue: undefined,
  setIsPendingRecipeRunsInQueue: (value: boolean) => set({ isPendingRecipeRunsInQueue: value }),
  pendingRecipeRunsInQueue: [],
  setPendingRecipeRunsInQueue: (value: RecipeRunData[]) => set({ pendingRecipeRunsInQueue: value }),
  isRunRequestPending: false,
  setIsRunRequestPending: (isRunRequestPending: $TSFixMe) => set({ isRunRequestPending }),
  reloadTrigger: null,
  reloadTriggerWithDelay: null,
  setReloadTrigger: () => set(() => ({ reloadTrigger: Date.now() })),
  setReloadTriggerWithDelay: () => set(() => ({ reloadTriggerWithDelay: Date.now() })),
  shouldBlockClickHandlerTrigger: false,
  setShouldBlockClickHandlerTrigger: (shouldBlockClickHandlerTrigger: $TSFixMe) =>
    set({ shouldBlockClickHandlerTrigger })
});

type ViewPort = { zoom: number; x: number; y: number };
type ViewPortStore = {
  viewPortInfo: { stringId: ViewPort } | {};
  setViewPortInfo: (projectId: string, newViewPortInfo: ViewPort) => void;
};

export const useViewPortStore = create<ViewPortStore>(
  devtools((set) => ({
    viewPortInfo: {},
    setViewPortInfo: (projectId: string, newViewPortInfo: ViewPort) =>
      set((state: any) => ({
        viewPortInfo: {
          ...state.viewPortInfo,
          [projectId]: newViewPortInfo
        }
      }))
  }))
);

export type GeneratingState = {
  isGenerating: boolean;
  generatingQuery: string;
  timestamp: string;
  isDisabled: boolean;
};

interface AIGuideStore {
  generatingState: Record<string, GeneratingState>;
  setGeneratingState: (
    threadId: string,
    generatingQuery: string,
    timestamp: string,
    isGenerating?: boolean,
    isDisabled?: boolean
  ) => void;
  setIsGenerated: (threadId: string) => void;
  getAllGeneratingThreads: () => void;
  getLatestGeneratingThreadId: () => string | undefined;
}

export const useAIGuideStore = create<AIGuideStore>(
  devtools((set, get) => ({
    generatingState: {},
    setGeneratingState: (
      threadId,
      generatingQuery,
      timestamp,
      isGenerating = true,
      isDisabled = true
    ) => {
      set((state: any) => {
        return _.set(state.generatingState, threadId, {
          isGenerating,
          isDisabled,
          generatingQuery,
          timestamp
        });
      });
    },
    setIsGenerated: (threadId) => {
      const state = get();
      const updatedState = _.unset(state.generatingState, threadId);
      return updatedState;
    },
    getAllGeneratingThreads: () => {
      const state = get();
      return Object.keys(state.generatingState);
    },
    getLatestGeneratingThreadId: () => {
      const state = get();

      const latestThreadId = _.maxBy(Object.keys(state.generatingState), (threadId) =>
        new Date(state.generatingState[threadId].timestamp).getTime()
      );

      return latestThreadId || undefined;
    }
  }))
);

const scenariosStore = (set: $TSFixMe) => ({
  scenarios: [],
  setScenarios: (scenarios: $TSFixMe) => set({ scenarios }),
  isScenariosLoading: false,
  setIsScenariosLoading: (value: $TSFixMe) => set({ isScenariosLoading: value }),
  scenariosReloadTrigger: null,
  triggerScenariosReload: () => set({ scenariosReloadTrigger: Date.now() })
});

const variablesStore = (set: $TSFixMe) => ({
  areVariablesLoading: false,
  setAreVariablesLoading: (value: $TSFixMe) => set({ areVariablesLoading: value }),
  variablesReloadTrigger: null,
  triggerVariablesReload: () => set({ variablesReloadTrigger: Date.now() })
});

const datasetStore = (set: $TSFixMe, get: $TSFixMe) => ({
  defaultDataset: {},
  existingDatasets: [],
  criteria: [],

  isSelectionSectionOpen: true,
  isSelectionOverlayOpen: true,

  project: {
    value: "",
    isDisabled: true
  },
  source: {
    value: DatasetSourceType.FileUpload,
    isDisabled: false
  },
  shouldResetDataset: true,
  criterion: {
    value: DatasetCriteria.SingleFileUpload,
    isDisabled: false
  },

  files: [],
  selectedDataSourceFiles: [],
  datasets: [],
  isDeletingEntities: false,
  dataSourceFileBreadCrumb: [],

  isOntology: false,
  isFetchingOntologyData: false,
  datasetIsFetchingOntologySchemaData: false,

  footerActions: {
    cancel: { isHidden: false, isDisabled: false },
    upload: { isHidden: false, isDisabled: true },
    next: { isHidden: true, isDisabled: true },

    dataSourcesPrevious: { isHidden: true, isDisabled: true },
    dataSourcesNext: { isHidden: true, isDisabled: true },

    ontologyPrevious: { isHidden: true, isDisabled: true },
    ontologyNext: { isHidden: true, isDisabled: true },
    ontologyClose: { isHidden: true, isDisabled: true }
  },

  watchOntologySchemaSet: now(),
  isResetting: false,

  setDefaultDataset: (value: $TSFixMe) => set({ defaultDataset: value }),
  setExistingDatasets: (value: $TSFixMe) => set({ existingDatasets: value }),
  setCriteria: (value: $TSFixMe) => set({ criteria: value }),

  setIsSelectionSectionOpen: (value: $TSFixMe) => set({ isSelectionSectionOpen: value }),
  setIsSelectionOverlayOpen: (value: $TSFixMe) => set({ isSelectionOverlayOpen: value }),

  setProject: (value: $TSFixMe) => set({ project: value }),
  setSource: (value: $TSFixMe) => set({ source: value }),
  setCriterion: (value: $TSFixMe) => set({ criterion: value }),
  setShouldResetDataset: (value: boolean) => set({ shouldResetDataset: value }),
  setDataSourceFileBreadCrumb: (value: []) => set({ dataSourceFileBreadCrumb: value }),

  setFiles: (value: $TSFixMe) => set({ files: value }),
  setSelectedDataSourceFiles: (value: $TSFixMe) => set({ selectedDataSourceFiles: value }),
  setDatasetIsFetchingOntologySchemaData: (value: boolean) =>
    set({ datasetIsFetchingOntologySchemaData: value }),
  setDatasets: (value: $TSFixMe) => set({ datasets: value }),
  setIsDeletingEntities: (value: $TSFixMe) => set({ isDeletingEntities: value }),

  setIsOntology: (value: $TSFixMe) => set({ isOntology: value }),
  setIsFetchingOntologyData: (value: $TSFixMe) => set({ isFetchingOntologyData: value }),

  setFooterActions: (value: $TSFixMe) => set({ footerActions: value }),

  setWatchOntologySchemaSet: (value: $TSFixMe) => set({ watchOntologySchemaSet: value }),
  setIsResetting: (value: $TSFixMe) => set({ isResetting: value }),

  resetInitData: () =>
    set({
      defaultDataset: {},
      existingDatasets: [],
      criteria: []
    }),

  resetSelectionCriteria: () =>
    set({
      isSelectionSectionOpen: true,
      isSelectionOverlayOpen: true,

      project: {
        value: "",
        isDisabled: true
      },
      source: {
        value: DatasetSourceType.FileUpload,
        isDisabled: false
      },
      criterion: {
        value: DatasetCriteria.SingleFileUpload,
        isDisabled: false
      }
    }),

  resetDataset: () =>
    set({
      files: [],
      selectedDataSourceFiles: [],
      datasets: [],
      isDeletingEntities: false,
      isOntology: false,
      isFetchingOntologyData: false,

      footerActions: {
        cancel: { isHidden: false, isDisabled: false },
        upload: { isHidden: false, isDisabled: true },
        next: { isHidden: true, isDisabled: true },

        dataSourcesPrevious: { isHidden: true, isDisabled: true },
        dataSourcesNext: { isHidden: true, isDisabled: true },

        ontologyPrevious: { isHidden: true, isDisabled: true },
        ontologyNext: { isHidden: true, isDisabled: true },
        ontologyClose: { isHidden: true, isDisabled: true }
      }
    }),

  resetDatasetPage: () => {
    get().resetInitData();
    get().resetSelectionCriteria();
    get().resetDataset();
  }
});

const useInputEntitiesStore = create(
  devtools(inputEntitiesStore, { name: "input_entities_store" })
);

const toastsStore = (set: $TSFixMe) => ({
  toastContent: null,
  setToastContent: (data?: string | null | undefined) => set(() => ({ toastContent: data }))
});

const useDrawerStore = create(devtools(drawerStore, { name: "drawer_store" }));
const useProjectsStore = create(devtools(projectsStore, { name: "projects_store" }));
const useDatasetStore = create(devtools(datasetStore, { name: "dataset_store" }));
const useJobsStore = create(devtools(jobsStore, { name: "jobs_store" }));
const useDataSourcesStore = create<IDataSourceState & $TSFixMe>(
  devtools(dataSourcesStore, { name: "data_sources_store" })
);
const useRunConfigStore = create(devtools(runConfigStore, { name: "run_config_store" }));
const useCanvasStore = create(devtools(canvasStore, { name: "canvas_store" }));
const useScenariosStore = create(devtools(scenariosStore, { name: "scenarios_store" }));
const useVariablesStore = create(devtools(variablesStore, { name: "variables_store" }));

const useToastsStore = create(devtools(toastsStore, { name: "toasts_store" }));

// Define the ThreadsStore interface
interface ThreadsStore {
  projectTargetMap: Map<string, Map<string, number>>; // Map<projectId, Map<entityId, messagesCount>>
  chartIdMessagesCountMap: Map<string, Map<string, Map<string, number>>>; // Map<projectId, Map<diamondId, Map<chartId, messagesCount>>>
  setProjectTargetMapFromThreads: (threads: ThreadResponseDto[]) => void;
  checkIfProjectHasMessages: (projectId: string) => boolean;
}

const useThreadsStore = create<ThreadsStore>((set, get) => ({
  projectTargetMap: new Map(),
  chartIdMessagesCountMap: new Map(),
  checkIfProjectHasMessages: (projectId) => {
    const state = get();
    const { projectTargetMap, chartIdMessagesCountMap } = state;

    const projectMessages = projectTargetMap.get(projectId) || new Map<string, number>();
    const hasProjectMessages = Array.from(projectMessages.values()).some((count) => count > 0);

    const entityMaps =
      chartIdMessagesCountMap.get(projectId) || new Map<string, Map<string, number>>();
    const hasChartMessages = Array.from(entityMaps.values()).some(
      (chartMap) =>
        chartMap instanceof Map && Array.from(chartMap.values()).some((count) => count > 0)
    );

    return hasProjectMessages || hasChartMessages;
  },
  setProjectTargetMapFromThreads: (threads: ThreadResponseDto[]) => {
    set(() => {
      const newProjectTargetMap = new Map<string, Map<string, number>>();

      threads.forEach((thread) => {
        const { projectId, entityId, messagesCount, targetType, datasetContext } = thread;
        const isDatasetType = targetType === ThreadResponseDtoTargetTypeEnum.Dataset;

        if (
          projectId &&
          entityId &&
          messagesCount !== undefined &&
          targetType !== ThreadResponseDtoTargetTypeEnum.Chart && // Ignore charts
          (isDatasetType ? datasetContext === ThreadResponseDtoDatasetContextEnum.Dataset : true)
        ) {
          const entityMap = newProjectTargetMap.get(projectId) || new Map<string, number>();
          entityMap.set(entityId, messagesCount);
          newProjectTargetMap.set(projectId, entityMap);
        }
      });

      return { projectTargetMap: newProjectTargetMap };
    });
    set(() => {
      const newChartIdMessagesCountMap = new Map<string, Map<string, Map<string, number>>>();

      threads.forEach((thread) => {
        const { projectId, entityId, messagesCount, targetInputs, targetType } = thread;
        if (
          targetType === ThreadResponseDtoTargetTypeEnum.Chart &&
          projectId &&
          entityId &&
          messagesCount !== undefined
        ) {
          targetInputs?.forEach((input) => {
            const { chartId } = input;
            if (chartId) {
              const projectMap =
                newChartIdMessagesCountMap.get(projectId) || new Map<string, Map<string, number>>();
              const entityMap = projectMap.get(entityId) || new Map<string, number>();
              entityMap.set(chartId, messagesCount);
              projectMap.set(entityId, entityMap);
              newChartIdMessagesCountMap.set(projectId, projectMap);
            }
          });
        }
      });

      return { chartIdMessagesCountMap: newChartIdMessagesCountMap };
    });
  }
}));

export {
  useInputEntitiesStore,
  useProjectsStore,
  useDatasetStore,
  useJobsStore,
  useDataSourcesStore,
  useRunConfigStore,
  useCanvasStore,
  useScenariosStore,
  useDrawerStore,
  useVariablesStore,
  useToastsStore,
  useDataAppStore,
  useThreadsStore
};
