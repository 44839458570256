import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import _, { includes } from "lodash";

import EventBus from "./EventBus";
import {
  AiControllerApi,
  ArtifactsControllerApi,
  DataAppControllerApi,
  DataConversationControllerApi,
  DataSourceControllerApi,
  DfsRunConfigGroupControllerV2Api,
  DfsTemplateControllerV2Api,
  EntityControllerApi,
  FeatureControllerApi,
  PredictionServiceControllerApi,
  ProjectControllerApi,
  ProjectControllerV2Api,
  ProjectRunControllerApi,
  ScenarioControllerApi,
  SecurityControllerApi,
  SignedUrlControllerApi,
  DeploymentPropertyControllerApi,
  DataFileControllerApi,
  DfsRunConfigControllerV2Api,
  EnvControllerApi,
  GlobalVariableControllerApi,
  InvitationControllerApi,
  PredictControllerApi,
  SegmentControllerApi,
  SsoSecurityControllerApi,
  ApiAccessKeyControllerApi,
  AppTemplateControllerApi,
  CodeCheckpointControllerApi
} from "@rapidcanvas/rc-api-core";
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";

import { toastWrapper } from "./toastWrapper";

const BASE_URL = `${window.location.origin}/api`;

const config = {
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true
};

interface IError {
  msg: string;
}

const WATCH_NOTIFICATION_METHODS = ["POST", "PUT", "PATCH"];
const NO_RECORD_FOUND_METHODS = ["GET"];
export const TOKEN = "token";

const getDefaultToken = () => {
  try {
    return localStorage.getItem(TOKEN) || sessionStorage.getItem(TOKEN) || null;
  } catch {
    return null;
  }
};
class AxiosClient {
  token: string | null = getDefaultToken();
  client: AxiosInstance = axios.create(config);

  AiControllerApi!: AiControllerApi;
  ArtifactsControllerApi!: ArtifactsControllerApi;
  DataAppControllerApi!: DataAppControllerApi;
  DataConversationControllerApi!: DataConversationControllerApi;
  DataFileControllerApi!: DataFileControllerApi;
  DataSourceControllerApi!: DataSourceControllerApi;
  DeploymentPropertyControllerApi!: DeploymentPropertyControllerApi;
  DfsRunConfigControllerV2Api!: DfsRunConfigControllerV2Api;
  DfsRunConfigGroupControllerV2Api!: DfsRunConfigGroupControllerV2Api;
  DfsTemplateControllerV2Api!: DfsTemplateControllerV2Api;
  EntityControllerApi!: EntityControllerApi;
  EnvControllerApi!: EnvControllerApi;
  FeatureControllerApi!: FeatureControllerApi;
  GlobalVariableControllerApi!: GlobalVariableControllerApi;
  InvitationControllerApi!: InvitationControllerApi;
  PredictControllerApi!: PredictControllerApi;
  PredictionServiceControllerApi!: PredictionServiceControllerApi;
  ProjectControllerApi!: ProjectControllerApi;
  ProjectRunControllerApi!: ProjectRunControllerApi;
  ProjectsControllerV2Api!: ProjectControllerV2Api;
  ScenarioControllerApi!: ScenarioControllerApi;
  SecurityControllerApi!: SecurityControllerApi;
  SegmentControllerApi!: SegmentControllerApi;
  SignedUrlControllerApi!: SignedUrlControllerApi;
  SsoSecurityControllerApi!: SsoSecurityControllerApi;
  ApiAccessKeyControllerApi!: ApiAccessKeyControllerApi;
  AppTemplateControllerApi!: AppTemplateControllerApi;
  CodeCheckpointControllerApi!: CodeCheckpointControllerApi;

  init = (token: string): void => {
    this.setToken(token);
    this.client.interceptors.request.use(this.requestInterceptor, this.rejectInterceptor);
    this.client.interceptors.response.use(this.responseInterceptor, this.rejectInterceptor);

    const client = this.client;
    this.ArtifactsControllerApi = new ArtifactsControllerApi(undefined, BASE_URL, client);
    this.AppTemplateControllerApi = new AppTemplateControllerApi(undefined, BASE_URL, client);
    this.DataAppControllerApi = new DataAppControllerApi(undefined, BASE_URL, client);
    this.EntityControllerApi = new EntityControllerApi(undefined, BASE_URL, client);
    this.EnvControllerApi = new EnvControllerApi(undefined, BASE_URL, client);
    this.DeploymentPropertyControllerApi = new DeploymentPropertyControllerApi(
      undefined,
      BASE_URL,
      client
    );
    this.FeatureControllerApi = new FeatureControllerApi(undefined, BASE_URL, client);
    this.InvitationControllerApi = new InvitationControllerApi(undefined, BASE_URL, client);
    this.PredictControllerApi = new PredictControllerApi(undefined, BASE_URL, client);
    this.SecurityControllerApi = new SecurityControllerApi(undefined, BASE_URL, client);
    this.SsoSecurityControllerApi = new SsoSecurityControllerApi(undefined, BASE_URL, client);
    this.ProjectsControllerV2Api = new ProjectControllerV2Api(undefined, BASE_URL, client);
    this.GlobalVariableControllerApi = new GlobalVariableControllerApi(undefined, BASE_URL, client);
    this.SegmentControllerApi = new SegmentControllerApi(undefined, BASE_URL, client);
    this.ScenarioControllerApi = new ScenarioControllerApi(undefined, BASE_URL, client);
    this.DfsRunConfigGroupControllerV2Api = new DfsRunConfigGroupControllerV2Api(
      undefined,
      BASE_URL,
      client
    );
    this.DfsTemplateControllerV2Api = new DfsTemplateControllerV2Api(undefined, BASE_URL, client);
    this.SignedUrlControllerApi = new SignedUrlControllerApi(undefined, BASE_URL, client);
    this.DataConversationControllerApi = new DataConversationControllerApi(
      undefined,
      BASE_URL,
      client
    );
    this.ProjectControllerApi = new ProjectControllerApi(undefined, BASE_URL, client);
    this.ProjectRunControllerApi = new ProjectRunControllerApi(undefined, BASE_URL, client);
    this.AiControllerApi = new AiControllerApi(undefined, BASE_URL, client);
    this.PredictionServiceControllerApi = new PredictionServiceControllerApi(
      undefined,
      BASE_URL,
      client
    );
    this.DataSourceControllerApi = new DataSourceControllerApi(undefined, BASE_URL, client);
    this.DfsRunConfigControllerV2Api = new DfsRunConfigControllerV2Api(undefined, BASE_URL, client);
    this.DataFileControllerApi = new DataFileControllerApi(undefined, BASE_URL, client);
    this.ApiAccessKeyControllerApi = new ApiAccessKeyControllerApi(undefined, BASE_URL, client);
    this.CodeCheckpointControllerApi = new CodeCheckpointControllerApi(undefined, BASE_URL, client);
  };

  setToken = (token: string): void => {
    try {
      this.token = token;
      localStorage.setItem(TOKEN, token);
      sessionStorage.setItem(TOKEN, token);
    } catch {
      toastWrapper({
        type: "error",
        content: "Failed to set the token"
      });
      this.removeToken();
    }
  };

  removeToken = () => {
    localStorage.removeItem(TOKEN);
    this.token = null;
    sessionStorage.removeItem(TOKEN);
  };

  getToken = (): string | null => {
    return this.token;
  };

  responseInterceptor = (
    response: AxiosResponse<any, any>
  ): Promise<AxiosResponse<any, any>> | AxiosResponse<any, any> => {
    return response;
  };

  requestInterceptor = async (
    requestConfig: InternalAxiosRequestConfig<any>
  ): Promise<InternalAxiosRequestConfig<any>> => {
    if (_.isString(this.token)) {
      _.setWith(requestConfig, "headers.Authorization", `Bearer ${this.token}`, Object);
    }
    return requestConfig;
  };

  rejectInterceptor = ({ response, message }: AxiosError<IError>): Promise<never> => {
    if (response && response?.status > 500) {
      if (response?.data?.msg) {
        const modifiedResponse = {
          ...response,
          data: {
            ...response.data,
            msg: "Oops, something went wrong. Please try again."
          }
        };
        return Promise.reject({ response: modifiedResponse, message });
      } else {
        const modifiedMessage = "Oops, something went wrong. Please try again.";
        return Promise.reject({ response, message: modifiedMessage });
      }
    }
    return Promise.reject({ response, message });
  };

  fetchResponse = async <T>(
    apiCall: () => Promise<AxiosResponse<T>>,
    shouldDispatchEvent = true,
    shouldHideNotifications?: boolean
  ): Promise<T> => {
    try {
      const response = await apiCall();
      const method = response.config.method?.toUpperCase();
      if (method && WATCH_NOTIFICATION_METHODS.includes(method)) {
        this.watchNotifications(shouldHideNotifications);
      }

      return response.data;
    } catch (error: any) {
      const message = this.getErrorMessage(error);

      const method = (error as AxiosError<IError>).response?.config.method?.toUpperCase();

      if (axios.isCancel(error) || error?.message === "canceled") {
        console.log("Request canceled", error.message);
        throw error;
      }

      if (
        includes(
          message,
          "You do not have access to this resource. Please contact platform admin"
        ) &&
        (error as AxiosError<IError>)?.response?.status === 401
      ) {
        EventBus.publish(EVENTBUS_EVENTS.AccessDenied);
        throw error;
      }

      if (
        includes(message, "No record found for the given") &&
        shouldDispatchEvent &&
        includes(NO_RECORD_FOUND_METHODS, method)
      ) {
        EventBus.publish(EVENTBUS_EVENTS.RecordNotFound);
      } else {
        shouldDispatchEvent && this.handleErrorWithRedirectToLogs(message);
      }

      if (method && WATCH_NOTIFICATION_METHODS.includes(method)) {
        this.watchNotifications(shouldHideNotifications);
      }

      throw error;
    }
  };

  watchNotifications = (shouldHideNotifications?: boolean) => {
    // This dispatched event will be listed in TopNavBarNotifications to trigger & manage polling.
    if (!shouldHideNotifications) {
      window.dispatchEvent(new Event("notificationTimerEvent"));
    }
  };

  handleErrorWithRedirectToLogs = (
    error: string | React.ReactNode,
    action?: (event: any) => void,
    extraProps = {}
  ) => {
    toastWrapper({
      ...extraProps,
      type: "error",
      content: error || "Something went wrong!",
      ...(action ? { actions: [{ label: "View Log", action }] } : {})
    });
  };

  getErrorMessage = (error: any) => {
    const baseErrorMsg = error?.response?.data?.msg || error?.message;
    const errorEntries = error?.response?.data?.entries;
    let messageToReturn: string = baseErrorMsg;
    if (
      errorEntries &&
      !errorEntries?.every((entry: any) => entry?.extraInfo?.length === 0 || !entry?.extraInfo)
    ) {
      messageToReturn = errorEntries?.reduce(
        (baseError: string, errorEntry: $TSFixMe) =>
          errorEntry?.extraInfo?.length > 0
            ? `${baseErrorMsg}${errorEntry?.field ? ` - ${errorEntry.field}` : ""}\n`
                .concat("\nExtra Info:\n")
                .concat(
                  typeof errorEntry?.extraInfo === "string"
                    ? errorEntry?.extraInfo
                    : errorEntry?.extraInfo?.reduce(
                        (allEntries: string, info: $TSFixMe) =>
                          [allEntries, ...Object.keys(info)].join("\n"),
                        ""
                      ) ?? ""
                )
            : baseError,
        baseErrorMsg
      );
    }
    return messageToReturn;
  };
}

const api = new AxiosClient();

export default api;
