import api from "utils/AxiosClient";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import { handleResponse } from "src/utils/apiService";
import { AI_GUIDE_MESSAGES_KEY } from "../aiguide/useGetAIGuideMessages";
import { INPUT_ENTITIES_QUERY_KEY } from "../transforms/useGetInputEntities";

interface IVariable {
  threadId: string;
}

export const RELOAD_MUTATION_KEY = "reload-thread";

const useReloadThread = (id: string): UseMutationResult<any, unknown, IVariable, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [RELOAD_MUTATION_KEY, id],
    mutationFn: async ({ threadId }) => {
      const data = await api.fetchResponse(() => api.AiControllerApi.reload(threadId));

      await queryClient.invalidateQueries([INPUT_ENTITIES_QUERY_KEY]);
      await queryClient.invalidateQueries([AI_GUIDE_MESSAGES_KEY, threadId]);
      return data;
    },
    onSuccess: () => {
      handleResponse({
        successMessage:
          "Refresh Chat action has been completed. Please review the conversation history to check the status of individual queries"
      });
    }
  });
};

export default useReloadThread;
