import FlashOnIcon from "@material-ui/icons/FlashOn";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import _ from "lodash";
import { Clear } from "@material-ui/icons";
import { makeStyles, Grid, Box, IconButton, Tooltip, Button } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";

import Chart from "../../Charts/Chart";
import ChartFrame from "src/pages/Projects/Charts/ChartFrame";
import CodeRecipeCodesTab from "./CodeRecipeCodeTabs";
import PlotlyCharts from "src/components/PlotlyCharts";
import RecipeInputDatasetTable from "../../common/RecipeInputDatasetTable";
import RefreshThreadButton from "./AskAIContainer/RefreshThread/RefreshThreadButton";
import SyntaxButton from "../../AddApiConnectorRecipe/ApiConnectorRecipeTabContainer/SyntaxButton";
import UnPinnedIcon from "src/icons/NewUX/UnPinnedIcon";
import ViewDataData from "src/pages/ViewData/ViewDataData/ViewDataData";
import useGetEntityData from "src/hooks/api/entities/useGetEntityData";
import useSaveCode from "src/hooks/useSaveCode";
import useTestAndRunRecipeUsingKeyboard from "src/hooks/useActionsWithKeyboardKeys";
import { AskAIContainer } from "./AskAIContainer/AskAIContainer";
import { GenerateQueryLimitsDropdown } from "./AskAIContainer/GenerateQueryLimitsDropdown/GenerateQueryLimitsDropdown";
import { PreviewIconNew } from "src/icons/PreviewIconNew";
import { READONLY_ENTITY } from "src/constants";
import { SaveButton } from "../../AddApiConnectorRecipe/ApiConnectorRecipeTabContainer/SaveButton";
import { Tabs, Tab, MoreOptions, TabPanel } from "src/components";
import { useCodeRecipeContext } from "../CodeRecipeContext/useCodeRecipeContext";

const useStyles = makeStyles({
  header: {
    flexWrap: "nowrap",
    borderBottom: "1px solid rgba(53,96,121,0.26)",
    padding: "8px 16px",
    justifyContent: "space-between"
  },
  tabRoot: {
    minHeight: "44px",
    flex: 1,
    borderRadius: "4px"
  },
  tabDetails: {
    padding: "16px",
    height: "100%"
  },
  codeEditorWrap: {
    border: "1px solid #C5C5C5",
    borderRadius: "4px",
    height: "100%"
  },
  headerButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 16px",
    gap: "16px"
  },
  tabHeader: {
    flexWrap: "nowrap",
    gap: "10px",
    display: "flex !important",
    alignItems: "center"
  },
  testBtn: {
    width: "64px"
  },
  viewDataContentContainer: {
    padding: "16px",
    height: "calc(100vh - 159px)",
    overflowY: "auto"
  },
  inputTabWrap: {
    flexWrap: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  chartOutput: {
    borderRadius: "4px",
    background: "#FFF",
    border: "1px solid #D1D1D1"
  },
  chartName: {
    margin: "4px 12px 0px 12px",
    color: "#133553",
    borderBottom: "1px solid #D1D1D1"
  },
  saveCodeGrid: {
    flex: 0,
    justifyContent: "center",
    whiteSpace: "nowrap"
  }
});

const CodeRecipeTabContainer = ({
  isDefaultScenario,
  value,
  setValue
}: {
  isDefaultScenario: boolean;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}) => {
  const classes = useStyles();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const [showGenerateApiLimits, setShowGenerateApiLimits] = React.useState<boolean>(false);
  const [insertedCode, setInsertedCode] = React.useState<string | null>(null);

  // Need for jobs.
  const [currentEntityId, setCurrentEntityId] = React.useState<string | null>(null);

  const {
    isTestInProgress,
    entityFeaturesMap,
    pinnedNames,
    inputDatasets,
    threadId,
    recipe,
    previewTabs,
    setPinnedNames,
    handleReloadSuccess,
    setInputNames,
    setPreviewTabs,
    saveToolTip,
    isSaveDisabled,
    handleSave,
    handleRun,
    handleTest,
    isRunInProgress,
    isTestDisabled,
    isRunDisabled,
    isAutoSaving,
    isSaveInProgress
  } = useCodeRecipeContext();
  const { scenarioId, jobRunId } = useParams();

  useSaveCode({
    handleSave,
    isSaveDisabled: isSaveDisabled || !!isJobPath,
    isTestInProgress
  });

  useTestAndRunRecipeUsingKeyboard({
    isRunDisabled,
    isRunInProgress,
    isTestDisabled,
    isTestInProgress,
    onRun: handleRun,
    onTest: handleTest
  });

  const { data, isLoading } = useGetEntityData(currentEntityId!, scenarioId, jobRunId, {
    enabled: !!currentEntityId
  });

  const entityData = useMemo(() => {
    if (!isJobPath || !currentEntityId) {
      return { columns: [], rows: [] };
    }

    return {
      ...data?.data,
      numCols: data?.entityDetails?.columns,
      numRows: data?.entityDetails?.rows,
      entityId: currentEntityId
    };
  }, [data, currentEntityId]);

  const handleChange = React.useCallback(
    (newValue: string) => {
      if (value === newValue) return;
      setValue(newValue);

      // Need for jobs.
      if (!!isJobPath) {
        const isEntityTab = Boolean(inputDatasets?.find((dataset) => dataset.id === newValue));
        isEntityTab && setCurrentEntityId(newValue);
      }
    },
    [inputDatasets, value, isJobPath]
  );

  React.useEffect(() => {
    if (previewTabs.length) {
      const lastPreviewTab = previewTabs[previewTabs.length - 1];
      setValue(lastPreviewTab.id);
    }
  }, [previewTabs]);

  // Need for jobs.
  React.useEffect(() => {
    if (!!isJobPath) {
      if ((inputDatasets || [])?.length > 0) {
        setValue(inputDatasets[0].id);
        setCurrentEntityId(inputDatasets[0].id);
      }
    }
  }, [inputDatasets, isJobPath]);

  const moreOptions = useMemo(
    () => [
      {
        label: "Generate Query Limit",
        dataTestId: "codeRecipeGenQueryLimitBtn",
        click: () => {
          setShowGenerateApiLimits(true);
        }
      }
    ],
    []
  );

  const handleUnpinAll = () => {
    setPinnedNames([]);
    setInputNames([]);
  };

  // const handleEditRecipe = async () => {
  //   await handleAsyncSave();
  //   queryClient.removeQueries([QUERY_KEY_RECIPE]);
  //   navigate("notebook");
  // };

  return (
    <>
      <Grid container direction="row" className={classes.header}>
        <Tabs value={value} onChange={handleChange}>
          {!isJobPath && (
            <Tab test-id="code-recipe-ask-ai-editor-tab" key="askAI" value="askAI" label="Ask AI" />
          )}
          <Tab
            test-id="code-recipe-code-editor-tab"
            key="codeEditor"
            value="codeEditor"
            label="Code"
          />
          {!!isJobPath &&
            inputDatasets?.map(({ displayName, id }) => (
              <Tab
                key={id}
                value={id}
                test-id="code-recipe-selected-input-datasets-tab"
                icon={<PreviewIconNew />}
                label={displayName}
              />
            ))}
          {previewTabs.length
            ? previewTabs?.map((item: $TSFixMe) => (
                <Tab
                  key={item.id}
                  test-id={`code-recipe-output-${
                    item.type === "DATASET" ? "dataset" : "chart"
                  }-tab`}
                  value={item.id}
                  icon={<FlashOnIcon color="secondary" style={{ fontSize: "16px" }} />}
                  label={item.name}
                  clearIcon={
                    <IconButton
                      size="small"
                      data-testid="closeTabBtn"
                      onClick={(e) => {
                        e.stopPropagation();
                        setPreviewTabs((previewTabs: any) =>
                          previewTabs.filter((tab: any) => tab.id !== item.id)
                        );
                        setValue("askAI");
                      }}>
                      <Clear style={{ fontSize: 14 }} />
                    </IconButton>
                  }
                />
              ))
            : null}
        </Tabs>
        {!isJobPath && (
          <Grid className={classes.headerButtons}>
            {/* {value === "codeEditor" && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                disabled={isSaveInProgress}
                style={{ padding: "3px 9px", border: "1px solid #4646B5" }}
                onClick={handleEditRecipe}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ gap: "5px", flexWrap: "nowrap" }}>
                  <span>Edit in Notebook</span>
                  <Tooltip
                    title={
                      "This allows you to modify the underlying code of this Recipe and customize it as per your needs."
                    }>
                    {isSaveInProgress ? (
                      <CircularProgress color="secondary" size={16} />
                    ) : (
                      <InfoOutlined style={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.54)" }} />
                    )}
                  </Tooltip>
                </Grid>
              </Button>
            )} */}
            {showGenerateApiLimits && <GenerateQueryLimitsDropdown />}
            <Box display="flex" flexDirection="row" flexWrap="nowrap" gridGap="16px">
              {value === "askAI" && threadId && recipe?.isCopied && (
                <RefreshThreadButton threadId={threadId} onReloadFinish={handleReloadSuccess} />
              )}
              {value === "codeEditor" && <SyntaxButton isCodeRecipe />}
              {pinnedNames.length > 0 && value === "askAI" && (
                <Tooltip title="Unpin all pinned datasets">
                  <Button
                    color="primary"
                    style={{ padding: "8px" }}
                    onClick={handleUnpinAll}
                    data-testid="codeRecipeUnpinAllEntities">
                    <UnPinnedIcon color="#0f0f0f" />
                  </Button>
                </Tooltip>
              )}
              <MoreOptions
                options={moreOptions}
                testId="code-recipe-header-more-options"
                menuProps={{
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                  getContentAnchorEl: null
                }}
                btnProps={{
                  style: {
                    background: "#fff",
                    border: "1px solid #4646B5",
                    borderRadius: "4px"
                  }
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      {!isJobPath && (
        <TabPanel
          key="askAI"
          value={value}
          index="askAI"
          boxProps={{ height: "100%" }}
          style={{ height: "calc(100% - 65px)" }}>
          <AskAIContainer readonly={!isDefaultScenario} />
        </TabPanel>
      )}
      <TabPanel
        key="codeEditor"
        value={value}
        index="codeEditor"
        boxProps={{ height: "100%" }}
        style={{ height: "calc(100% - 65px)" }}>
        <CodeRecipeCodesTab
          isDefaultScenario={isDefaultScenario}
          insertedCode={insertedCode}
          setInsertedCode={setInsertedCode}
          readonly={!!isJobPath}
          actionsContainer={
            !!isJobPath ? (
              <React.Fragment />
            ) : (
              <Grid
                container
                direction="column"
                alignItems="center"
                className={classes.saveCodeGrid}>
                <SaveButton
                  onClick={handleSave}
                  tooltip={isDefaultScenario ? saveToolTip || "" : READONLY_ENTITY}
                  disabled={isSaveDisabled || !isDefaultScenario || isSaveInProgress}
                  loading={isSaveInProgress}
                  isAutoSaving={isAutoSaving}
                />
              </Grid>
            )
          }
        />
      </TabPanel>
      {!!isJobPath &&
        inputDatasets?.map((dataset, index) => {
          const entityFeatures = entityFeaturesMap?.[dataset.name];
          return (
            <TabPanel key={index} value={value} index={dataset.id}>
              <Box width="100%">
                {dataset.id === value ? (
                  <RecipeInputDatasetTable
                    isLoading={isLoading}
                    currentEntityData={entityData}
                    entityFeatures={entityFeatures}
                  />
                ) : null}
              </Box>
            </TabPanel>
          );
        })}
      {previewTabs?.map((item: $TSFixMe) => {
        if (item.type === "DATASET") {
          return (
            <TabPanel value={value} index={item.id} key={item.id}>
              <div
                id="viewDataContainer"
                className={classes.viewDataContentContainer}
                data-testid="codeRecipeOutputEntityTable">
                <ViewDataData
                  isLoadingData={false}
                  hideCount={false}
                  responseData={item.data || { columns: [], rows: [] }}
                  entityFeatures={[]}
                  maxHeight="calc(-200px + 100vh)"
                />
              </div>
            </TabPanel>
          );
        } else {
          const chartJson =
            item.chartJson && typeof item.chartJson === "string"
              ? { ...JSON.parse(item.chartJson) }
              : null;
          const cleanedChartJson = chartJson
            ? { ...chartJson, layout: { ...chartJson.layout, title: null } }
            : {};
          return (
            <TabPanel key={item.id} value={value} index={item.id}>
              <Box p="16px" width="100%">
                <Grid container direction="column" className={classes.chartOutput}>
                  {item.chartJson && typeof item.chartJson === "string" ? (
                    <Grid container direction="column" spacing={1}>
                      <Grid
                        item
                        className={classes.chartName}
                        data-testid="codeRecipeOutputChartName">
                        {item.name}
                      </Grid>
                      <Grid item data-testid="codeRecipeOutputChart">
                        <PlotlyCharts {...cleanedChartJson} />
                      </Grid>
                    </Grid>
                  ) : item.chartType ? (
                    <Chart data={item} expanded />
                  ) : (
                    <ChartFrame data={item} size={12} />
                  )}
                </Grid>
              </Box>
            </TabPanel>
          );
        }
      })}
    </>
  );
};

export default React.memo(CodeRecipeTabContainer);
