import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import { Delete } from "../../icons/Delete";
import { LinkCellWithMenu } from "../../components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { Settings } from "../../icons/Settings";
import { OverflowTooltip, Table, TitleText } from "../../components";
import { capitalize } from "../../utils/capitalize";
import { dateFormat } from "src/utils/dateFormat";
import { DataAppIcon } from "src/icons/NewUX/DataAppIcon";
import { JobIcon } from "src/icons/NewUX/JobIcon";
import { ImageIcon } from "src/icons/NewUX/ImageIcon";
import CopyDownloadIcon from "src/icons/NewUX/CopyDownloadIcon";
import { useProjectsStore } from "src/store/store";
import { includes } from "lodash";
import MarkFavorite from "./MarkFavorite";
import CommonLoader from "src/components/CommonLoader";
import _ from "lodash";

const useStyles = makeStyles({
  gap: {
    gap: "8px"
  },
  linkText: {
    color: "#008FE4"
  }
});

interface IProps {
  list: any[];
  isLoading: boolean;
  onEdit: (id: string) => void;
  onImageOpen: (id: string) => void;
  onCopyOpen: (id: string) => void;
  onDelete: (id: string) => void;
  selectedProjects: string[];
  setSelectedProjects: Dispatch<SetStateAction<string[]>>;
}
export const ProjectsList = ({
  list,
  onEdit,
  onDelete,
  onImageOpen,
  onCopyOpen,
  isLoading,
  selectedProjects,
  setSelectedProjects
}: IProps) => {
  const classes = useStyles();
  const [updatingProjectIds] = useProjectsStore((state) => [state.updatingProjectIds]);

  const handleProjectSelection = (ids: Record<string, boolean>) => {
    setSelectedProjects(_.keys(ids));
  };

  const [key, setKey] = useState("");

  const columns = [
    {
      id: "Name",
      accessor: "name",
      Header: "Name",
      isSortable: true,
      isTooltip: false,
      Cell: ({ row, value }: $TSFixMe) => (
        <LinkCellWithMenu
          url={`/projects/${row?.original?.id}`}
          extra={
            <MarkFavorite
              isHovered={!!row?.original?.isFavorite}
              className={row?.original?.isFavorite ? undefined : "hiddenDiv"}
              projectId={row?.original?.id}
              value={row?.original?.isFavorite}
            />
          }
          linkText={
            <OverflowTooltip style={{ width: "100%", whiteSpace: "nowrap" }} value={value} />
          }
          menuButtons={[
            {
              icon: <ImageIcon />,
              label: "Project Image",
              action: () => row?.original?.id && onImageOpen(row?.original?.id)
            },
            {
              icon: <Settings />,
              label: "Settings",
              action: () => row?.original?.id && onEdit(row?.original?.id)
            },
            {
              icon: <CopyDownloadIcon />,
              label: "Copy Project",
              action: () => row?.original?.id && onCopyOpen(row?.original?.id)
            },
            {
              icon: <Delete />,
              label: "Delete",
              disabled: includes(updatingProjectIds, row?.original?.id),
              tooltip: includes(updatingProjectIds, row?.original?.id)
                ? "Please wait until the project is getting updated"
                : "",
              action: () => row?.original?.id && onDelete(row?.original?.id)
            }
          ]}
        />
      )
    },
    {
      id: "Description",
      accessor: "description",
      Header: "Description",
      isSortable: true,
      Cell: ({ value }: { value: string }) => capitalize(value)
    },
    {
      id: "DataApp Count",
      accessor: "dataAppCount",
      Header: "DataApp Count",
      isSortable: true,
      Cell: ({ row, value }: { row: any; value: number }) => {
        return (
          <Grid item container direction="row" alignItems="center" className={classes.gap}>
            <DataAppIcon />
            <TitleText
              variant="inherit"
              className={!value ? classes.linkText : undefined}
              colorCode={value ? "#4A4A4A" : undefined}
              title={value ? `${value} DataApp${value !== 1 ? "s" : ""}` : "Create"}
              link={`/projects/${row?.original?.id}/project-dataapps`}
            />
          </Grid>
        );
      }
    },
    {
      id: "Scheduler Count",
      accessor: "jobCount",
      Header: "Scheduler Count",
      isSortable: true,
      Cell: ({ row, value }: { value: number; row: any }) => {
        return (
          <Grid item container direction="row" alignItems="center" className={classes.gap}>
            <JobIcon />
            <TitleText
              colorCode={value ? "#4A4A4A" : undefined}
              className={!value ? classes.linkText : undefined}
              variant="inherit"
              title={value ? `${value} Scheduler${value !== 1 ? "s" : ""}` : "Create Scheduler"}
              link={
                value
                  ? `/projects/${row?.original?.id}/jobs`
                  : `/projects/${row?.original?.id}/jobs/create-job`
              }
            />
          </Grid>
        );
      }
    },
    {
      id: "Updated by",
      accessor: "updater",
      Header: "Updated by",
      isSortable: true
    },
    {
      id: "Updated on",
      accessor: "updatedAt",
      Header: "Updated on",
      isSortable: true,
      Cell: ({ value }: { value: number }) => <span>{dateFormat(value)}</span>
    }
  ];

  useEffect(() => {
    setKey(Date.now().toString());
  }, [list]);

  return (
    <>
      {isLoading ? (
        <CommonLoader />
      ) : (
        !!list.length && (
          <Table
            key={key}
            isSelectable
            orderByDefault="Updated on"
            data={list}
            size="small"
            hideCount
            getRowId={(item) => item.id}
            columns={columns}
            isCellSortEnabled
            maxHeight="calc(100vh - 339px)"
            isTheadSticky
            selectedRowIds={_.mapValues(_.keyBy(selectedProjects), () => true)}
            onSelectedRowsChange={handleProjectSelection}
          />
        )
      )}
    </>
  );
};
