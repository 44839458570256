import React, { useState } from "react";

// Packages
import { useQueryClient } from "@tanstack/react-query";
import { includes, isEmpty } from "lodash";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";
import { UseGetEnvironmentsQueryKeys } from "src/hooks/api/environments/useGetEnvironments";

// Hooks
import { useDeleteEnvironment } from "src/hooks/api";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import {
  EnvironmentDeletePromptDetails,
  EnvironmentsHelperText
} from "../utils/Environments.constants";

interface IProps {
  environment: EnvDto;
  resetDeleteEnvironment: () => void;
}

const DeleteEnvironment: React.FC<IProps> = (props) => {
  const { environment, resetDeleteEnvironment } = props;

  const queryClient = useQueryClient();

  const [isEnvironmentDeleting, setIsEnvironmentDeleting] = useState(false);

  const { mutateAsync: deleteEnvironmentMutation, reset: resetDeleteEnvironmentMutation } =
    useDeleteEnvironment();

  const hasProjects = () => {
    if (!isEmpty(environment?.projects)) {
      toastWrapper({
        type: ToastTypes.Error,
        content: `Cannot delete this environment because it is currently used in other project(s) [${environment?.projects?.join(
          ", "
        )}]`
      });

      resetDeleteEnvironment();

      return true;
    }

    return false;
  };

  const deleteEnvironment = async () => {
    if (hasProjects()) return;

    setIsEnvironmentDeleting(() => true);

    resetDeleteEnvironmentMutation();
    await deleteEnvironmentMutation(
      { id: environment?.id },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([UseGetEnvironmentsQueryKeys.Environments]);
          toastWrapper({
            type: ToastTypes.Success,
            content: EnvironmentsHelperText.EnvironmentDeleted
          });

          setIsEnvironmentDeleting(() => false);
          resetDeleteEnvironment();
        },
        onError: () => {
          setIsEnvironmentDeleting(() => false);
          resetDeleteEnvironment();
        }
      }
    );
  };

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={EnvironmentDeletePromptDetails.title}
      content={[
        EnvironmentDeletePromptDetails.messageLine1,
        EnvironmentDeletePromptDetails.messageLine2
      ]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetDeleteEnvironment();
        }
      }}
      onSubmit={deleteEnvironment}
      cancelLabel={EnvironmentDeletePromptDetails.cancelLabel}
      isCancelDisabled={isEnvironmentDeleting}
      isSubmitDisabled={isEnvironmentDeleting}
      isSubmitting={isEnvironmentDeleting}
      hideCloseIcon
    />
  );
};

export default DeleteEnvironment;
