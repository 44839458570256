import React, { useMemo } from "react";

// Packages
import { useLocation } from "react-router";
import { find } from "lodash";

// Stores
import { useProjectsStore } from "src/store/store";
import { projectsGetter } from "src/store/store.selectors";

// Utils
import { VIEW_DATA } from "src/layout/NavBars/components/TopNavBar/helpers/TopNavBar.helpers";

// Hooks
import useRecipeRunsQueue from "./useRecipeRunsQueue";

// Context
import { ProjectContext } from "./ProjectContext";

type Props = {
  children: React.ReactNode;
};

const ProjectContextProvider = (props: Props) => {
  const { children } = props || {};

  const location = useLocation();

  // Stores - STARTS >>
  const projectsStore = useProjectsStore(projectsGetter);
  // << ENDS - Stores

  // @REFACTOR
  const { projectId } = useMemo(() => {
    const [section, sectionId, viewDataId] = location.pathname
      .split("/")
      .filter((part: string) => Boolean(part));

    return { projectId: section === VIEW_DATA ? viewDataId : sectionId };
  }, [location.pathname]);

  const project = useMemo(
    () => find(projectsStore || [], (eachProject: $TSFixMe) => eachProject?.id === projectId),
    [projectsStore, projectId]
  );

  const { recipeRunsQueue } = useRecipeRunsQueue({ projectId });

  const value = useMemo(() => ({ project, recipeRunsQueue }), [project, recipeRunsQueue]);

  return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>;
};

export default ProjectContextProvider;
