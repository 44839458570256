import React, { useCallback, useEffect, useState, useMemo } from "react";

import { FormControl, Select, MenuItem } from "@material-ui/core";
import { Image } from "@material-ui/icons";
import TransformItem from "./components/transform-list-item";
import Field from "../../Inputs/Field";
import { addTransformGroupWithRethrow, getEntitiesByProjectIdWithRethrow } from "../../../api";
import { useInputEntitiesStore, useDrawerStore, useRunConfigStore } from "../../../store/store";
import { useFetchWithZustand } from "../../../utils/useFetchWithZustand";
import {
  inputEntitiesSetter,
  getInputEntities,
  getSideComponent,
  getSideComponentProps,
  selectedInputEntitySetter,
  getSelectedInputEntity,
  sideComponentSetter,
  configGroupSetter
} from "../../../store/store.selectors";
import styles from "./entity-modal.module.scss";
import { Alert } from "@material-ui/lab";
import { toastWrapper } from "src/utils/toastWrapper";
import CommonLoader from "src/components/CommonLoader";

export default function AddTransformModal({
  projectId,
  history,
  lockedSelectedEntityId
}: $TSFixMe) {
  const entities = useInputEntitiesStore(getInputEntities);
  const setEntities = useInputEntitiesStore(inputEntitiesSetter);
  const updateSelection = useInputEntitiesStore(selectedInputEntitySetter);
  const selectedInputEntity = useInputEntitiesStore(getSelectedInputEntity);
  const sideComponent = useDrawerStore(getSideComponent);
  const sideComponentProps = useDrawerStore(getSideComponentProps);
  const setSideComponent = useDrawerStore(sideComponentSetter);
  const setConfigGroup = useRunConfigStore(configGroupSetter);

  const [transformName, setTransformName] = useState("");
  const [isTransformNameRequiredErrorShow, setIsTransformNameRequiredErrorShow] = useState(false);

  const handleTransformChange = (event: $TSFixMe) => {
    setIsTransformNameRequiredErrorShow(false);
    setTransformName(event.target.value);
    setSideComponent({
      sideComponent,
      sideComponentProps: {
        ...sideComponentProps,
        handleCreateAction: createCreateTransformClickHandler(
          selectedInputEntity,
          event.target.value
        ),
        disabled: Object.keys(selectedInputEntity).length === 0
      }
    });
  };

  useEffect(() => {
    if (lockedSelectedEntityId) {
      const selectedLockedInputEntity = entities.find(
        (entity: $TSFixMe) => entity.id === lockedSelectedEntityId
      );
      if (selectedLockedInputEntity) {
        handleChange(selectedLockedInputEntity);
      } else {
        updateSelection({});
      }
    } else {
      updateSelection({});
    }
    setTransformName("");
    // @ts-expect-error TS(2448) FIXME: Block-scoped variable 'handleChange' used before i... Remove this comment to see the full error message
  }, [updateSelection, entities, setTransformName, lockedSelectedEntityId, handleChange]);

  const handleCreateTransformClick = useCallback(
    async (item: $TSFixMe, transformNameInternal: $TSFixMe) => {
      if (!transformNameInternal) {
        setIsTransformNameRequiredErrorShow(true);
        return;
      }
      try {
        const response = await addTransformGroupWithRethrow(
          {
            parents: [{ id: item?.id, type: "ENTITY" }],
            children: [],
            name: transformNameInternal
          },
          projectId
        );
        setConfigGroup(response);
        sessionStorage.setItem("configGroupId", response?.id);
        setSideComponent({
          sideComponent: null,
          sideComponentProps: null
        });
        history.push(`/projects/${projectId || "0"}/entities/${item.id || "0"}/add-transform`);
      } catch (error) {
        toastWrapper({
          type: "error",
          content: "Transform could not be created"
        });
      }
    },
    [history, projectId, setConfigGroup, setSideComponent, setIsTransformNameRequiredErrorShow]
  );

  const createCreateTransformClickHandler = useCallback(
    (inputEntity: $TSFixMe, transformNameInternal: $TSFixMe) => {
      return () => {
        return handleCreateTransformClick(inputEntity, transformNameInternal);
      };
    },
    [handleCreateTransformClick]
  );

  const handleChange = useCallback(
    (item: $TSFixMe) => {
      updateSelection(item);
      setSideComponent({
        sideComponent,
        sideComponentProps: {
          ...sideComponentProps,
          handleCreateAction: () => handleCreateTransformClick(item, transformName),
          disabled: false
        }
      });
    },
    [
      updateSelection,
      setSideComponent,
      sideComponent,
      sideComponentProps,
      handleCreateTransformClick,
      transformName
    ]
  );

  const { isLoading } = useFetchWithZustand({
    fetchCallback: getEntitiesByProjectIdWithRethrow,
    fetchingParams: projectId,
    setApiData: setEntities
  });

  const validEntities = useMemo(
    () => entities.filter((entity: $TSFixMe) => entity?.entityMeta?.entityViewType !== "CHART"),
    [entities]
  );

  return isLoading ? (
    <div className={styles.addTransformModal}>
      <CommonLoader />
    </div>
  ) : (
    <div className={styles.addTransformModal}>
      <TransformItem
        isActive={true}
        title="Data preparation"
        subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna quis sed vitae "
        Icon={Image}
      />
      <hr />
      <div className={styles.inputContainer}>
        <p>Transform Name</p>
        <Field value={transformName} onChange={handleTransformChange} />
        {isTransformNameRequiredErrorShow && (
          <Alert className={styles.transformNameRequiredError} severity="error">
            Transform name is required
          </Alert>
        )}
      </div>
      <hr />
      <div className={styles.inputContainer}>
        <p>Dataset</p>
        <FormControl variant="outlined" className={styles.selectMenu}>
          <Select
            id="input-entity-select"
            onChange={(e) => handleChange(e.target.value)}
            value={selectedInputEntity}
            disabled={Boolean(lockedSelectedEntityId)}
            renderValue={(selected) => (selected as $TSFixMe).name}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}>
            {validEntities.map((item: $TSFixMe) => (
              <MenuItem key={item.id} value={item}>
                {item.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
