import React, { useEffect } from "react";
import MonacoEditor from "react-monaco-editor";
import * as monaco from "monaco-editor";

interface IProps {
  key: string;
  code?: string;
}

const ReadOnlyCodeEditor: React.FC<IProps> = ({ key, code }) => {
  useEffect(() => {
    monaco.editor.defineTheme("custom-theme", {
      base: "vs",
      inherit: true,
      rules: [],
      colors: {
        "editor.background": "#FFFFEE"
      }
    });
    monaco.editor.setTheme("custom-theme");
  }, []);

  return (
    <MonacoEditor
      key={key}
      height="100%"
      value={code}
      width="100%"
      language="python"
      theme="custom-theme"
      options={{
        readOnly: true,
        wordWrap: "bounded",
        wordWrapColumn: 79,
        autoIndent: "full",
        tabSize: 4,
        minimap: { enabled: false },
        renderLineHighlight: "none",
        scrollbar: {
          vertical: "hidden",
          horizontal: "hidden"
        },
        overviewRulerLanes: 0,
        padding: {
          top: 16
        }
      }}
    />
  );
};

export default ReadOnlyCodeEditor;
