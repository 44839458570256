import { getProjectById } from "../api/projects";
import { EnvironmentStatuses } from "src/constants/environments.constants";
import { toastWrapper } from "./toastWrapper";
import { getEnvironmentById } from "src/api";

export const getEnvRelaunchMessageForToaster = (timeInhrs: number) =>
  `Please note that the environment is relaunching${
    timeInhrs !== 0 ? ` as it has been idle for more than ${timeInhrs ?? 2} hours` : ""
  }. You will likely experience delay in some screens till the environment finishes relaunching`;

export const envRelaunchMessage = "Environment relaunching. This might take a few minutes.";

const envRelaunchNotification = (shutdownTimeInHrs: number) => {
  // returns toastId.
  return toastWrapper({
    type: "info",
    content: getEnvRelaunchMessageForToaster(shutdownTimeInHrs)
  });
};

export const checkEnvRelaunch = async (projectId: $TSFixMe) => {
  try {
    const projects = await getProjectById(projectId);

    const project = projects?.[0];
    const envStatus = project?.launchStatus;
    // For checking default-env associate to the project use defaultEnvFlag in the project's response.

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!envStatus) {
      if (
        [
          EnvironmentStatuses.Inactive.toLowerCase(),
          EnvironmentStatuses.Failed.toLowerCase()
        ].includes(envStatus?.trim()?.toLowerCase())
      ) {
        const response = await getEnvironmentById(project?.envId);
        const shutdownTimeInHrs = response?.[0].shutdownStrategy?.inactivityInHours;
        return envRelaunchNotification(shutdownTimeInHrs);
      }
    }
  } catch (error: $TSFixMe) {
    console.error(error);
  }
};

export const checkEnvRelaunchByEnvId = async (envId?: string) => {
  if (!envId) {
    return;
  }

  try {
    const response = await getEnvironmentById(envId);
    if (
      [EnvironmentStatuses.Inactive, EnvironmentStatuses.Failed].includes(
        response?.[0]?.launchStatus
      )
    ) {
      const shutdownTimeInHrs = response?.[0].shutdownStrategy?.inactivityInHours;
      return envRelaunchNotification(shutdownTimeInHrs);
    }
  } catch (e: any) {
    console.error(e);
  }
};

export default envRelaunchNotification;
