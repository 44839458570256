import React, { useCallback, useEffect, useState } from "react";

// Packages
import { size, truncate } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Icons
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

// Utils
import EventBus from "src/utils/EventBus";

// Constants
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";
import { TableDataHelperText } from "./TableData.constants";

const useStyles = makeStyles((theme) => ({
  tableInfoContainer: {
    columnGap: theme.spacing(0.5),
    fontStyle: "italic",
    "& svg": {
      opacity: 0.5
    }
  }
}));

const FilterInfo: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();

  const [filterDetails, setFilterDetails] = useState<any>({});

  const handleClearSearch = () => {
    EventBus.publish(EVENTBUS_EVENTS.ClearTableSearch);
    setFilterDetails({});
  };

  useEffect(() => {
    EventBus.subscribe(EVENTBUS_EVENTS.TableSearchChanged, (payload: any) => {
      setFilterDetails(payload);
    });

    return () => {
      EventBus.unsubscribe(EVENTBUS_EVENTS.TableSearchChanged);
    };
  }, []);

  const getFilteredValue = useCallback(
    ({ value, maxLength }: { value: string; maxLength: number }) => (
      <Tooltip title={size(value) > maxLength ? value : ""}>
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: size(value) > maxLength ? "pointer" : "default"
          }}>
          {`"${truncate(value, {
            length: maxLength
          })}"`}
        </span>
      </Tooltip>
    ),
    []
  );

  return (
    <Grid
      container
      style={{
        columnGap: theme.spacing(2),
        padding: theme.spacing(1)
      }}>
      <Grid item>
        <Grid
          container
          alignItems="flex-end"
          justifyContent="center"
          className={classes.tableInfoContainer}>
          <Grid item>
            <InfoOutlinedIcon fontSize="small" />
          </Grid>
          <Grid item>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {TableDataHelperText.TableInfo}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {(filterDetails?.value || filterDetails?.filteredColumnNames) && (
        <Grid item>
          <Grid container alignItems="center" style={{ columnGap: theme.spacing(1) }}>
            <Grid item>
              <Typography variant="body2" style={{ color: theme.palette.secondary.main }}>
                <span style={{ color: "#FF5C00", fontSize: "12px" }}>
                  {!!filterDetails?.value && !filterDetails?.filteredColumnNames ? (
                    <>
                      {`Showing ${filterDetails?.size ?? 0} search results for`}{" "}
                      {getFilteredValue({ value: filterDetails?.value, maxLength: 80 })}
                    </>
                  ) : !filterDetails?.value && !!filterDetails?.filteredColumnNames ? (
                    <>
                      {`Showing ${filterDetails?.size ?? 0} search results with filters applied for column(s):`}{" "}
                      {getFilteredValue({
                        value: filterDetails?.filteredColumnNames,
                        maxLength: 60
                      })}
                    </>
                  ) : !!filterDetails?.value && !!filterDetails?.filteredColumnNames ? (
                    <>
                      {`Showing ${filterDetails?.size ?? 0} search results for`}{" "}
                      {getFilteredValue({ value: filterDetails?.value, maxLength: 30 })}{" "}
                      {`along with applied filters for column(s):`}{" "}
                      {getFilteredValue({
                        value: filterDetails?.filteredColumnNames,
                        maxLength: 30
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                style={{ padding: "0px 9px" }}
                color="primary"
                onClick={handleClearSearch}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FilterInfo;
