import React from "react";

import Text from "src/components/Widget/Text";
import { Table } from "src/components";
import { formTableCells, InputColumn } from "src/components/Table/TableUtils";
import { dateFormat } from "src/utils/dateFormat";
import { ModelDto } from "@rapidcanvas/rc-api-core";

const TABLE_SIZE = "small";

export const AddModelsTable = ({
  data,
  globalFilter,
  selectedRowIds,
  onSelectedRowsChange,
  shouldDisableInitialSelectedRows
}: {
  data: ModelDto[];
  selectedRowIds: $TSFixMe;
  globalFilter?: string | undefined;
  // eslint-disable-next-line no-unused-vars
  onSelectedRowsChange: (rows: Array<any>) => void;
  shouldDisableInitialSelectedRows?: boolean;
}) => {
  const artifactColumns = React.useMemo(() => {
    const columns: InputColumn[] = [
      {
        id: "Name",
        Header: "Model Name",
        accessor: "name",
        Cell: ({ value }: $TSFixMe) => <Text value={value} />
      },
      {
        id: "Date Created",
        Header: "Date Created",
        accessor: "created",
        Cell: ({ value }: $TSFixMe) => dateFormat(value)
      },
      {
        id: "Source Recipe",
        accessor: (row: $TSFixMe) => {
          const value = row["producer"];
          return value
            ? `${value?.recipeName} ${value?.projectName ? `(${value?.projectName})` : ""}`
            : "Manual addition";
        },
        Header: "Source Recipe"
      }
    ];
    return formTableCells({ inputColumns: columns, size: TABLE_SIZE });
  }, []);

  const handleSelectedRowsChange = (rows: any) => {
    onSelectedRowsChange(Object.keys(rows));
  };
  return (
    <Table
      data={data}
      size={TABLE_SIZE}
      columns={artifactColumns}
      isCellSortEnabled
      isSelectable
      orderByDefault="Date Created"
      sortInverted
      onSelectedRowsChange={handleSelectedRowsChange}
      selectedRowIds={selectedRowIds}
      maxHeight="calc(100vh - 337px)"
      getRowId={(row: $TSFixMe) => {
        return row.name;
      }}
      emptyTableMessage="No Models"
      globalFilter={globalFilter}
      shouldDisableInitialSelectedRows={shouldDisableInitialSelectedRows}
      skipPageReset
    />
  );
};
