import React, { useEffect } from "react";
import _ from "lodash";
import clsx from "clsx";
import marked from "marked";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import ChatBox from "./ChatBox";
import markdownToTxt from "markdown-to-txt";
import { CopyTextIcon } from "../../AddCodeRecipe/CopyButton/CopyTextIcon";
import { Delete } from "src/icons/Delete";
import { EntityIcon } from "src/icons/EntityIcon";
import { OverflowTooltip } from "src/components";
import { PersonIconV3 } from "src/icons/NewUX/PersonIconV3";
import { SampleRows } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/SampleRows";

marked.setOptions({
  gfm: true,
  breaks: false,
  pedantic: false
});

type Props = {
  userInput?: string;
  canDelete?: boolean;
  canCopy?: boolean;
  setShowDeleteModal?: (showDeleteModal: boolean) => void;
  isError?: boolean;
  width?: string;
  isMarkDown?: boolean;
  icon?: React.ReactNode;
  maxHeight?: string;
  onCollapse?: () => void;
  onExpand?: () => void;
  copyTitle?: string;
  className?: any;
  noPadding?: boolean;
  color?: string;
  textColor?: string;
  hoverBgColor?: string;
  disableMarkdownPadding?: boolean;
  queryInputs?: {
    name: string;
  }[];
  sampleRows?: number;
  tags?: string[];
  children?: React.ReactNode;
  disableDelete?: boolean;
  deleteTooltip?: string;
  rightContainer?: React.ReactNode;
  borderColor?: string;
  title?: string;
  titleColor?: string;
  isCollapsed?: boolean;
  queryAsTitle?: boolean;
};

type StyleProps = {
  isError: boolean;
  width?: string;
  maxHeight?: string;
  isHovered: boolean;
  canCopy: boolean;
  canDelete: boolean;
  canClick: boolean;
  isMarkDown: boolean;
  noPadding?: boolean;
  textColor?: string;
  disableMarkdownPadding?: boolean;
  isCollapsed: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>({
  userInputColumn: {
    padding: ({ noPadding }) => (noPadding ? "0px 32px 0px 16px" : "12px 32px 16px 16px"),
    width: "calc(100% - 28px)",
    gap: "24px",
    flexWrap: "nowrap"
  },
  profileBtnLarge: {
    display: "flex",
    justifyContent: "center",
    width: "24px",
    height: "32px",
    flexWrap: "nowrap"
  },
  askAIUserInput: {
    position: "relative",
    maxWidth: ({ width }) => (width ? "none" : "750px")
  },
  queryInputGroup: {
    flexWrap: "nowrap",
    overflow: "auto",
    height: "100%",
    justifyContent: "center"
  },
  queryContainer: {
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
    borderRadius: "0px 4px 4px 4px",
    gap: "16px"
  },
  userInputText: {
    borderRadius: "2px",
    color: "#515151",
    whiteSpace: ({ isMarkDown }) => (isMarkDown ? "normal" : "pre"),
    textWrap: "wrap !important",
    wordBreak: "break-word",
    fontWeight: 400,
    "& *": {
      listStyle: "none",
      marginBottom: ({ disableMarkdownPadding }) =>
        disableMarkdownPadding ? "0px !important" : "default"
    },
    "& p": {
      marginBottom: 0
    }
  },
  queryContainerWrap: {
    maxHeight: ({ maxHeight }) => maxHeight || "inherit",
    overflow: ({ maxHeight }) => (maxHeight ? "auto" : "none"),
    width: "inherit",
    cursor: ({ canClick }) => (canClick ? "pointer" : "default")
  },
  copyIcon: {
    opacity: ({ isHovered, isCollapsed }) => (!isCollapsed ? 1 : isHovered ? 1 : 0),
    borderRadius: "4px",
    background: "#fff",
    display: ({ canCopy }) => (canCopy ? "block" : "none")
  },
  deleteIcon: {
    visibility: ({ isHovered, isCollapsed }) =>
      !isCollapsed ? "visible" : isHovered ? "visible" : "hidden",
    display: ({ canDelete }) => (canDelete ? "block" : "none")
  },
  rightBar: {
    flexGrow: 0,
    padding: "0px 6px 0px 6px"
  },
  collapsedText: {
    color: ({ textColor }) => textColor || "#000",
    fontWeight: 400,
    background: "#fff",
    padding: "0px 12px 0px 12px",
    fontSize: "14px"
  },
  btn: {
    padding: 0
  },
  queryInputName: {
    fontSize: "14px",
    color: "#FF653D",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: "6px",
    maxWidth: "100%",
    marginLeft: "-2px"
  },
  queryInputs: {
    display: "inline-flex",
    width: "auto",
    flexWrap: "nowrap"
  },
  rightContainer: {
    paddingLeft: "8px",
    paddingRight: "8px"
  },
  deleteBtn: {
    color: ({ isCollapsed }) => (isCollapsed ? "#4646B5" : "#003656")
  }
});

const AIGuideTextRow = ({
  userInput,
  canDelete = false,
  setShowDeleteModal,
  canCopy = true,
  isError = false,
  isMarkDown = false,
  width,
  icon = <PersonIconV3 />,
  maxHeight,
  onCollapse,
  onExpand,
  copyTitle,
  className,
  noPadding = false,
  disableMarkdownPadding = false,
  color,
  textColor,
  hoverBgColor,
  borderColor,
  title: initialTitle,
  queryInputs,
  sampleRows,
  tags,
  children,
  disableDelete,
  deleteTooltip,
  titleColor,
  rightContainer,
  isCollapsed = true,
  queryAsTitle = false
}: Props) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [htmlContent, setHtmlContent] = React.useState<string>("");

  const classes = useStyles({
    isError,
    width,
    maxHeight,
    isHovered,
    noPadding,
    canClick: !!onExpand,
    canCopy,
    canDelete,
    isCollapsed,
    isMarkDown,
    textColor,
    disableMarkdownPadding
  });

  const openDeleteModal = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setShowDeleteModal?.(true);
  };
  const isQueryInputsWithTags = !_.isEmpty(queryInputs);
  useEffect(() => {
    const fetchHtmlContent = async () => {
      if (userInput) {
        const htmlString = await marked.parse(userInput);
        setHtmlContent(htmlString);
      }
    };

    fetchHtmlContent();
  }, [userInput]);

  const boxProps = onExpand ? { onClick: onExpand } : {};
  const isMarkDownLoading = isMarkDown && !htmlContent;

  const title = !isQueryInputsWithTags ? (
    initialTitle || null
  ) : (
    <Typography component={"div"} className={classes.queryInputName}>
      <EntityIcon width="12px" height="12px" fill="#FF653D" />
      <OverflowTooltip
        tooltipProps={{ placement: "top" }}
        style={{
          maxWidth: "600px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
        value={_.first(queryInputs)?.name}
      />{" "}
      {_.size(queryInputs) > 1 && (
        <Tooltip
          placement="top"
          title={
            <div>
              {_.map(
                _.filter(queryInputs, (__, index) => index !== 0),
                (val, index) => (
                  <div key={index}>{val.name}</div>
                )
              )}
            </div>
          }>
          <span>(+{_.size(queryInputs) - 1})</span>
        </Tooltip>
      )}
    </Typography>
  );

  return (
    <Grid container direction="row" alignItems="flex-start" className={classes.userInputColumn}>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
        data-testid="ai-query-icon"
        className={classes.profileBtnLarge}>
        {icon}
      </Grid>
      <ChatBox
        border={
          isError
            ? "#FFDBDD"
            : borderColor || isHovered
              ? hoverBgColor || "rgba(255, 92, 0, 1)"
              : "#FFF"
        }
        color={color || (isError ? "#FFDBDD" : "#FFF8F3")}
        width={width || "auto"}
        childWidth="100%"
        noPadding>
        <Grid
          container
          className={classes.askAIUserInput}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <Grid container direction="row" className={classes.queryContainerWrap} {...boxProps}>
            <Grid container direction="row" className={classes.queryContainer}>
              <Grid item xs container direction="column" className={classes.queryInputGroup}>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  style={{
                    padding: !isQueryInputsWithTags ? "4px 12px" : "4px 12px 4px 12px",
                    alignItems: "center"
                  }}>
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    style={{
                      flex: 1,
                      fontSize: "14px",
                      lineHeight: "14px",
                      color: titleColor
                    }}>
                    {title}
                    {(tags || [])?.length > 0 && (
                      <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        style={{ gap: "4px", flex: 0, marginLeft: "6px" }}>
                        {tags?.map((tag) => (
                          <Chip
                            data-testid="ai-query-tags"
                            variant="outlined"
                            size="small"
                            key={tag}
                            label={tag}
                          />
                        ))}
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs
                    container
                    direction="row"
                    wrap="nowrap"
                    className={classes.rightBar}>
                    {(userInput || (queryAsTitle && initialTitle)) && (
                      <Box className={classes.copyIcon}>
                        <CopyTextIcon
                          data-testid="ai-query-copy-icon"
                          text={markdownToTxt((queryAsTitle ? initialTitle : userInput) ?? "")}
                          title={canCopy ? copyTitle : ""}
                          color={isCollapsed ? "#003656" : "#000"}
                        />
                      </Box>
                    )}
                    {rightContainer && (
                      <Box className={classes.rightContainer}>{rightContainer}</Box>
                    )}
                    <Box className={classes.deleteIcon}>
                      <Tooltip title={deleteTooltip || "Delete Query"} placement="top" arrow>
                        <div>
                          <IconButton
                            size="small"
                            color="primary"
                            data-testid="ai-query-delete-icon"
                            className={clsx([classes.btn, classes.deleteBtn])}
                            disabled={disableDelete}
                            onClick={openDeleteModal}>
                            <Delete width={16} height={16} />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </Box>
                    {!!onCollapse && (
                      <IconButton
                        size="small"
                        className={classes.btn}
                        onClick={onCollapse}
                        data-testid="ai-query-collapse-icon">
                        <ExpandLess fontSize="small" />
                      </IconButton>
                    )}
                    {!!onExpand && (
                      <Tooltip
                        title={isMarkDownLoading ? "Please wait until text is fetched.." : ""}>
                        <div>
                          <IconButton
                            size="small"
                            className={classes.btn}
                            disabled={isMarkDownLoading}
                            data-testid="ai-query-expand-icon">
                            <ExpandMore fontSize="small" />
                          </IconButton>
                        </div>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
                <Typography
                  className={clsx(classes.userInputText, className, {
                    [classes.collapsedText]: true
                  })}
                  data-testid="ai-guide-text-content"
                  variant="subtitle2">
                  {children ? (
                    <Grid
                      item
                      xs
                      container
                      direction="column"
                      className={classes.queryInputGroup}
                      data-testid="ai-query-children">
                      {children}
                    </Grid>
                  ) : isMarkDown ? (
                    htmlContent &&
                    userInput && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                  ) : (
                    userInput
                  )}
                </Typography>

                {sampleRows && (
                  <Box pt="8px">
                    <SampleRows sampleRows={sampleRows} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ChatBox>
    </Grid>
  );
};

export default React.memo(AIGuideTextRow);
