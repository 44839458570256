import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import useInvalidateCache from "./useInvalidateCache";
import { PS_SUCCESS_UPDATE_MESSAGE } from "../pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.constants";
import { QUERY_KEY_MODELS } from "src/hooks/api";
import { QUERY_KEY_PREDICTION_SERVICE_BY_MODEL } from "./usePredictionServiceByModel";
import { getAPIWithRethrow, handleResponse, putAPIWithRethrow } from "src/utils/apiService";
import { QUERY_KEY_DOWNLOAD_SIGNED_URL } from "./useDownloadSignedUrl";

interface IVariables {
  id?: string;
  name?: string;
  displayName?: string;
  description?: string;
  modelName?: string;
  envId?: string;
  file?: File;
  logCalls?: boolean;
  timeoutInMins?: number;
  concurrency?: number;
  isAutoSaving?: boolean;
}

const useUpdatePredictionService = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  const queryClient = useQueryClient();
  const invalidateCache = useInvalidateCache();

  return useMutation({
    mutationFn: async ({
      id,
      name,
      displayName,
      description,
      modelName,
      envId,
      file,
      logCalls,
      timeoutInMins,
      concurrency
    }) => {
      const results = await putAPIWithRethrow(`/v2/prediction-services/${id}`, {
        name,
        displayName,
        description,
        modelName,
        envId,
        concurrency,
        logCalls,
        timeoutInMins
      });

      const { signedUrl, headers } = await getAPIWithRethrow(
        `/v2/prediction-services/${id}/signed-url?path=${name}.py`
      );
      await putAPIWithRethrow(signedUrl, file, {
        headers,
        signedUrl: true
      });

      return results;
    },
    onSuccess: async (__, { name }) => {
      if (name) {
        invalidateCache.mutate({ name, showMessage: false });
      }
      await queryClient.invalidateQueries([QUERY_KEY_PREDICTION_SERVICE_BY_MODEL]);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_MODELS], refetchType: "all" });
      await queryClient.invalidateQueries([QUERY_KEY_DOWNLOAD_SIGNED_URL, name]);
      handleResponse({ successMessage: PS_SUCCESS_UPDATE_MESSAGE });
    }
  });
};

export default useUpdatePredictionService;
