import React, { useMemo, useState } from "react";

// Packages
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import { map, camelCase, size } from "lodash";

// MUI
import {
  Paper,
  List,
  ListItem,
  IconButton,
  CircularProgress,
  Tooltip,
  Typography,
  Grid
} from "@material-ui/core";

// Icons
import PredictRecipeIcon from "src/icons/NewUX/PredictRecipeIcon";
import { CanvasIcon } from "src/icons/NewUX/CanvasIcon";
import { ClockIcon } from "src/icons/NewUX/ClockIcon";
import { DataappsIcon } from "src/icons/NewUX/DataappsIcon";
import { InfoIcon } from "src/icons/NewUX/InfoIcon";
import { SettingsIcon } from "src/icons/NewUX/SettingsIcon";
import { WorkflowIcon } from "src/icons/NewUX/WorkflowIcon";

// Utils
import Analytics from "src/utils/analytics";
import { toastWrapper } from "src/utils/toastWrapper";
import { IProjectMenuItem, ProjectMeuItemLabels } from "../../utils/NavBars.constants";

// Stores
import { useProjectsStore } from "src/store/store";
import { shouldRefreshProjectsToggler } from "src/store/store.selectors";

// Hooks
import { useDeleteProject } from "src/hooks/api";
import { useGetRole } from "src/hooks/useGetRole";

// Helpers
import { deleteProjectMessageLines } from "src/pages/private/ProjectsModule/utils/Projects.helpers";

// Components
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import ProjectSettings from "src/pages/private/ProjectsModule/pages/ProjectSettings/ProjectSettings";

// Constants
import { EVENTS } from "src/constants/events";

// Styles
import { useStyles } from "./ProjectNavBar.styles";
import { Roles } from "src/types";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { WebPaths } from "src/routing/routes";
import useProjectDetails from "src/hooks/api/projects/useProjectDetails";

const ProjectNavBar = () => {
  const classes = useStyles();
  const { projectId: projectIdFromUrl } = useParams<$TSFixMe>();

  const location = useLocation();
  const navigate = useNavigate();

  // Project context
  const { project } = useProjectContext() || {};
  const projectId = useMemo(() => project?.id, [project]);
  const { data } = useProjectDetails(projectIdFromUrl, {
    refetchOnMount: true,
    enabled: !!projectIdFromUrl
  });

  // Stores - STARTS >>
  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);
  // << ENDS - Stores

  // States - STARTS >>
  const [isDeleteProjectConfirmModalOpen, setIsDeleteProjectConfirmModalOpen] = useState(false);
  const [projectSettingsOpen, setProjectSettingsOpen] = useState(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Mutations
  const { canAccessWithRole } = useGetRole();
  const {
    isLoading: isProjectDeleting,
    mutateAsync: deleteProjectMutation,
    reset: resetDeleteProjectMutation
  } = useDeleteProject();
  // << ENDS - Query hooks

  const projectRoutePaths: IProjectMenuItem[] = useMemo(
    () => [
      {
        pathName: "/canvas",
        path: `/projects/${projectId}/canvas`,
        label: ProjectMeuItemLabels.Canvas,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => <CanvasIcon viewBox="0 0 16 12" />
      },
      {
        pathName: "/scenarios",
        path: `/projects/${projectId}/scenarios`,
        label: ProjectMeuItemLabels.Scenarios,
        allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name],
        icon: () => <WorkflowIcon />
      },
      {
        pathName: "/prediction-job",
        path: `/projects/${projectId}/prediction-job`,
        label: ProjectMeuItemLabels.PredictionJob,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => <PredictRecipeIcon />,
        size: size(data?.predictionJobs)
      },
      {
        pathName: "/jobs",
        path: `/projects/${projectId}/jobs`,
        label: ProjectMeuItemLabels.Jobs,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => <ClockIcon viewBox="0 0 14 14" />,
        size: size(data?.projectJobs)
      },
      {
        pathName: "/project-dataapps",
        path: `/projects/${projectId}/project-dataapps`,
        label: ProjectMeuItemLabels.DataApps,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => <DataappsIcon width={14} height={14} />,
        size: size(data?.dataApps)
      },
      {
        pathName: "",
        path: "",
        label: ProjectMeuItemLabels.Settings,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => <SettingsIcon />
      },
      {
        pathName: "/about",
        path: `/projects/${projectId}/about`,
        label: ProjectMeuItemLabels.About,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => <InfoIcon />
      }
    ],
    [projectId, data]
  );

  const filteredProjectRoutePaths = useMemo(
    () =>
      projectRoutePaths
        ?.filter((path: IProjectMenuItem) => !!path?.pathName)
        ?.map((path: IProjectMenuItem) => path?.path),
    [projectRoutePaths]
  );

  const allowedProjectRoutes = useMemo(
    () => projectRoutePaths.filter((path) => canAccessWithRole(path.allowedRoles)),
    [projectRoutePaths]
  );

  if (
    !filteredProjectRoutePaths?.includes(location.pathname) &&
    // $FixMe: Scope to be refactored.
    // The below condition must be removed and handled with routes.
    !location.pathname?.includes("/canvas")
  ) {
    return <></>;
  }

  const onProjectMenuItemClick = (item: IProjectMenuItem) => {
    Analytics.track({
      category: EVENTS.internalNavigation.category,
      action: EVENTS.internalNavigation.action,
      label: item?.label
    });

    if (item?.label === ProjectMeuItemLabels.Settings) {
      setProjectSettingsOpen(() => true);
    } else {
      navigate(item?.path);
    }
  };

  const isActive = (item: IProjectMenuItem) => {
    return !!item?.pathName && !!location.pathname?.includes(item?.pathName);
  };

  const onDeleteProjectConfirmModalClose = () => {
    setIsDeleteProjectConfirmModalOpen(() => false);
  };

  const onDeleteProject = async () => {
    resetDeleteProjectMutation();

    await deleteProjectMutation(
      { projectId },
      {
        onSuccess: () => {
          toastWrapper({ type: "success", content: "Project deleted successfully!" });
          navigate(WebPaths.Projects);
          toggleShouldProjectsRefresh();
        },
        onSettled: () => {
          onDeleteProjectConfirmModalClose();
        }
      }
    );

    onDeleteProjectConfirmModalClose();
  };

  return (
    <>
      {!!projectId && !!projectSettingsOpen && (
        <ProjectSettings
          projectId={projectId}
          onClose={() => {
            setProjectSettingsOpen(() => false);
          }}
        />
      )}

      {isDeleteProjectConfirmModalOpen && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Delete Project"
          content={deleteProjectMessageLines(project?.name)}
          isSubmitting={isProjectDeleting}
          onClose={onDeleteProjectConfirmModalClose}
          onSubmit={onDeleteProject}
        />
      )}

      <Paper elevation={4} className={classes.root}>
        <List component="nav">
          {map(allowedProjectRoutes, (item) => (
            <ListItem key={uuidv4()} disableGutters dense className={classes.menuItem}>
              {!item?.pathName && isProjectDeleting ? (
                <CircularProgress size={16} color="secondary" className={classes.loader} />
              ) : (
                <Tooltip title={item.label} placement="right">
                  <Typography color="textPrimary">
                    <Grid container direction="column" alignItems="center">
                      <IconButton
                        data-testid={camelCase(`project${item.label}`)}
                        className={clsx(
                          classes.menuItemButton,
                          { ["active"]: isActive(item) },
                          { ["size"]: item.size && item.size > 0 }
                        )}
                        disabled={!projectId}
                        color="inherit"
                        onClick={() => onProjectMenuItemClick(item)}>
                        {item.icon(isActive(item))}
                      </IconButton>
                      {!!item.size && item.size > 0 && (
                        <span style={{ fontSize: "9px" }}>{item.size}</span>
                      )}
                    </Grid>
                  </Typography>
                </Tooltip>
              )}
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  );
};

export default ProjectNavBar;
