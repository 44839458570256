import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Button, Card, CardContent, CardHeader, IconButton, Tooltip } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { useQueryClient } from "@tanstack/react-query";

import ConnectorDetailsForm from "./ConnectorDetailsForm";
import DeleteSyncedConnectorModal from "./DeleteSyncedConnectorModal";
import NoAvailableConnector from "./NoAvailableConnector";
import useExportEntityConnectors from "hooks/api/entities/useExportEntityConnectors";
import useUpdateEntities from "hooks/api/entities/useUpdateEntities";
import {
  CONNECTOR_KEYS,
  DESTINATION_DETAILS_INFO,
  EXPORT_IN_PROGRESS_MSG,
  EXPORT_MSG,
  INCORRECT_STATUS_MSG,
  MANDATORY_FIELDS_SAVE_MSG,
  MANDATORY_FIELDS_UPDATE_MSG,
  MAX_LONG_LENGTH,
  NO_CHANGE_MSG,
  UPDATE_UNSAVED_CHANGES_MSG
} from "../utils/OutputDataset.constants";
import { QUERY_KEY_ENTITY_DETAILS } from "src/hooks/api/entities/useEntityDetails";
import {
  DataConnectorNames,
  dataSourcesTypes
} from "pages/DataSources/utils/DataSources.constants";
import { IEntity } from "hooks/api/entities/useEntities";
import { IThirdPartyDataSourceStatus } from "src/utils/fivetran";
import { Spinner } from "src/components";
import { useForm } from "src/utils/useForm";
import { useGetDataSources } from "hooks/api/dataSources/useGetDataSources";
import { useStyles } from "components/Canvas/CanvasDrawerItems/components/styling";
import { isInvalidConnectionInputs } from "../utils/OutputDataset.helpers";
import CommonLoader from "src/components/CommonLoader";

export interface IData {
  dataSourceType: string;
  iconUrl: string | null;
  id: string;
  name: string;
  thirdPartyDataSourceStatus: IThirdPartyDataSourceStatus;
  tpConnectorType: string;
}

interface IProps {
  entity: IEntity;
  loading: boolean;
  scenarioId: string;
  disableExport: boolean;
}

export interface IConnectorType extends IData {
  image: JSX.Element;
}

const DestinationDetails: React.FC<IProps> = (props) => {
  const { entity, loading, scenarioId, disableExport } = props;

  const [open, setOpen] = useState(false);
  const { destinationCard, flexSpaceBetween, flexContainer, bluebtn } = useStyles();
  const { values, resetForm, setValues, handleInputChange } = useForm({
    [CONNECTOR_KEYS.connectorType]: ""
  });

  const queryClient = useQueryClient();
  const { data, isLoading } = useGetDataSources({});
  const updateSyncDetails = useUpdateEntities();
  const exportEntityConnectors = useExportEntityConnectors();

  const initialValues = useMemo(() => {
    let initialValue = null;
    if (entity.syncDataSourceId) {
      initialValue = { [CONNECTOR_KEYS.connectorType]: entity.syncDataSourceId };
    }
    if (entity.syncDataSourceOptions) {
      initialValue = { ...initialValue, ...entity.syncDataSourceOptions };
    }

    return initialValue;
  }, [entity.syncDataSourceId, entity.syncDataSourceOptions]);

  useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
  }, [entity.syncDataSourceId, entity.syncDataSourceOptions]);

  const connectors = useMemo(() => {
    const keyBy = _.keyBy(dataSourcesTypes, "name");
    const connectorList: IConnectorType[] = [];

    _.forEach(data, (item: IData) => {
      const dataSourceType = _.get(keyBy, item.dataSourceType);
      if (
        dataSourceType &&
        _.includes(
          [
            DataConnectorNames.S3_STORAGE,
            DataConnectorNames.AZURE_BLOB,
            DataConnectorNames.GCP_STORAGE,
            DataConnectorNames.MYSQL,
            DataConnectorNames.REDSHIFT,
            DataConnectorNames.SNOWFLAKE,
            DataConnectorNames.MONGO
          ],
          dataSourceType.name
        )
      ) {
        connectorList.push({ ...item, image: _.get(dataSourceType, "image") });
      }
    });

    return connectorList;
  }, [data]);

  const selectedConnectorType = useMemo(() => {
    const connector = _.get(values, CONNECTOR_KEYS.connectorType);

    return _.find(connectors, { id: connector })?.dataSourceType;
  }, [connectors, values]);

  const disableUpdate = useMemo(() => {
    if (!entity.syncDataSourceId) {
      return false;
    }

    return _.isEqual(initialValues, values);
  }, [initialValues, values]);

  const disableSave = useMemo(() => {
    if (_.isEmpty(_.get(values, CONNECTOR_KEYS.connectorType))) {
      return true;
    }

    switch (selectedConnectorType) {
      case DataConnectorNames.AZURE_BLOB:
      case DataConnectorNames.GCP_STORAGE:
      case DataConnectorNames.S3_STORAGE:
        return (
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.fileCategory.destinationFolder.key)
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.fileCategory.destinationFileName.key)
          )
        );

      case DataConnectorNames.MYSQL:
      case DataConnectorNames.REDSHIFT:
        return (
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.sqlRedshiftCategory.tableName.key),
            CONNECTOR_KEYS.sqlRedshiftCategory.tableName.regex
          ) || isInvalidConnectionInputs(_.get(values, CONNECTOR_KEYS.sqlRedshiftCategory.type.key))
        );

      case DataConnectorNames.MONGO:
        return (
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.mongoCateogry.collection.key),
            CONNECTOR_KEYS.mongoCateogry.collection.regex
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.mongoCateogry.database.key),
            CONNECTOR_KEYS.mongoCateogry.database.regex
          )
        );

      case DataConnectorNames.SNOWFLAKE:
        return (
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.databaseName.key),
            CONNECTOR_KEYS.snowFlakeCategory.databaseName.regex
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.schema.key),
            CONNECTOR_KEYS.snowFlakeCategory.schema.regex
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.tableName.key),
            CONNECTOR_KEYS.snowFlakeCategory.tableName.regex
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.warehouse.key),
            undefined,
            MAX_LONG_LENGTH
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.role.key),
            undefined,
            undefined,
            true
          ) ||
          isInvalidConnectionInputs(_.get(values, CONNECTOR_KEYS.snowFlakeCategory.type.key))
        );

      default:
        return false;
    }
  }, [values, selectedConnectorType]);

  const handleConnectorChange = (
    e: React.ChangeEvent<{
      name: string;
      value: string;
    }>
  ) => {
    const { name, value } = e.target;
    if (name) {
      setValues({ [name]: value });
    }
  };

  const handleDelete = () => {
    setOpen(true);
  };

  const handleSave = () => {
    const syncDataSourceId = _.get(values, CONNECTOR_KEYS.connectorType);
    const syncDataSourceOptions = _.mapValues(
      _.omit(values, CONNECTOR_KEYS.connectorType),
      (value) => _.trim(value)
    );

    if (syncDataSourceId && !_.isEmpty(syncDataSourceOptions)) {
      updateSyncDetails.mutate(
        {
          id: entity.id,
          syncDataSourceId,
          syncDataSourceOptions
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([QUERY_KEY_ENTITY_DETAILS]);
          }
        }
      );
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    updateSyncDetails.mutate(
      {
        id: entity.id,
        syncDataSourceId: ""
      },
      {
        onSuccess: () => {
          setOpen(false);
          resetForm();
          queryClient.invalidateQueries([QUERY_KEY_ENTITY_DETAILS]);
        }
      }
    );
  };

  const handleExport = () => {
    exportEntityConnectors.mutate({
      id: entity.id,
      scenarioId
    });
  };

  const subheader = (
    <div className={flexSpaceBetween}>
      <div className={flexContainer}>
        <span>Destination Details</span>
        <Tooltip title={DESTINATION_DETAILS_INFO}>
          <InfoOutlinedIcon />
        </Tooltip>
      </div>
      {entity.syncDataSourceId ? (
        <div className={flexContainer}>
          <Tooltip
            title={
              exportEntityConnectors.isLoading
                ? EXPORT_IN_PROGRESS_MSG
                : disableUpdate
                  ? NO_CHANGE_MSG
                  : disableSave
                    ? MANDATORY_FIELDS_UPDATE_MSG
                    : ""
            }>
            <div>
              <Button
                variant="text"
                color="primary"
                className={bluebtn}
                disabled={
                  disableUpdate ||
                  updateSyncDetails.isLoading ||
                  exportEntityConnectors.isLoading ||
                  disableSave
                }
                onClick={handleSave}>
                {updateSyncDetails.isLoading && !open ? (
                  <Spinner padding="0px 10px" size={18} />
                ) : (
                  "Update"
                )}
              </Button>
            </div>
          </Tooltip>
          <Tooltip
            title={
              disableExport
                ? INCORRECT_STATUS_MSG
                : disableUpdate
                  ? EXPORT_MSG
                  : UPDATE_UNSAVED_CHANGES_MSG
            }>
            <div>
              <Button
                variant="text"
                color="primary"
                className={bluebtn}
                disabled={
                  updateSyncDetails.isLoading ||
                  disableExport ||
                  exportEntityConnectors.isLoading ||
                  !disableUpdate
                }
                onClick={handleExport}>
                {exportEntityConnectors.isLoading ? (
                  <Spinner padding="0px 10px" size={18} />
                ) : (
                  "Export"
                )}
              </Button>
            </div>
          </Tooltip>
          <Tooltip title={exportEntityConnectors.isLoading ? EXPORT_IN_PROGRESS_MSG : ""}>
            <div>
              <IconButton
                disabled={updateSyncDetails.isLoading || exportEntityConnectors.isLoading}
                onClick={handleDelete}>
                <DeleteOutline />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      ) : (
        <Tooltip title={disableSave ? MANDATORY_FIELDS_SAVE_MSG : ""}>
          <div>
            <Button
              variant="text"
              color="primary"
              className={bluebtn}
              disabled={disableSave || updateSyncDetails.isLoading}
              onClick={handleSave}>
              {updateSyncDetails.isLoading ? <Spinner padding="0px 10px" size={18} /> : "Save"}
            </Button>
          </div>
        </Tooltip>
      )}
    </div>
  );

  return (
    <>
      <Card className={destinationCard}>
        <CardHeader subheader={subheader} subheaderTypographyProps={{ color: "initial" }} />
        <CardContent>
          {isLoading || loading ? (
            <CommonLoader />
          ) : _.isEmpty(connectors) ? (
            <NoAvailableConnector />
          ) : (
            <ConnectorDetailsForm
              selectedConnectorType={selectedConnectorType}
              connectors={connectors}
              values={values}
              onChange={handleInputChange}
              onConnectorChange={handleConnectorChange}
            />
          )}
        </CardContent>
      </Card>
      <DeleteSyncedConnectorModal
        open={open}
        loading={updateSyncDetails.isLoading}
        onCancel={handleCancel}
        onDelete={handleRemove}
      />
    </>
  );
};

export default DestinationDetails;
