import React from "react";

import { PlayArrow, Stop, Refresh } from "@material-ui/icons";
import { EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

export const EnvironmentStatusValuesMapping = {
  [EnvDtoLaunchStatusEnum.Shutdown]: {
    displayName: "Shutdown",
    icon: <PlayArrow fontSize="small" />,
    buttonText: "Start",
    cssClassName: "shutdown"
  },
  [EnvDtoLaunchStatusEnum.Launching]: { displayName: "Launching...", cssClassName: "launching" },
  [EnvDtoLaunchStatusEnum.Stopping]: { displayName: "Stopping...", cssClassName: "stopping" },
  [EnvDtoLaunchStatusEnum.Success]: {
    displayName: "Running",
    icon: <Stop fontSize="small" />,
    buttonText: "Stop",
    cssClassName: "success"
  },
  [EnvDtoLaunchStatusEnum.Failed]: {
    displayName: "Failed",
    icon: <Refresh fontSize="small" />,
    buttonText: "Relaunch",
    cssClassName: "failed"
  }
};
