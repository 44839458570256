import React, { useEffect, useRef } from "react";
import MonacoEditor from "react-monaco-editor";
import { editor } from "monaco-editor";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import "./CodeRecipeCodeEditor.css";
import { useParams } from "react-router-dom";
import { useGetProjectCanvas } from "src/hooks/api";
import _, { get, map } from "lodash";
import {
  addImportStatement,
  helperFunctionSignatures,
  helperSuggestions
} from "src/utils/monacoSuggestionsHelper";
import CommonLoader from "src/components/CommonLoader";

interface IProps {
  editorValue: string;
  showConstant?: boolean;
  setEditorValue: (value: string) => void;
  insertedCode?: string | null;
  resetInsertedCode?: () => void;
  disabled?: boolean;
  onControlT?: (editVal: string) => void;
  errDetails?: {
    lineNo: number;
    line: string;
    errorExplanation?: string;
  } | null;
  onEditorBlur?: (editVal: string) => void;
}

export const CodeRecipeCodeEditor = ({
  editorValue,
  showConstant,
  setEditorValue,
  insertedCode,
  resetInsertedCode,
  disabled,
  errDetails,
  onControlT,
  onEditorBlur
}: IProps) => {
  const editorRef: $TSFixMe = React.useRef(null);
  const [decorationIds, setDecorationIds] = React.useState([]);
  const { projectId, scenarioId } = useParams();
  const completionProviderRef = useRef<monaco.IDisposable | null>(null);
  const { data: projectCanvasData, isLoading } = useGetProjectCanvas({
    projectId: projectId,
    scenarioId: scenarioId!,
    cacheTime: Infinity,
    refetchOnMount: true
  });

  const onInsertCode = (code: string) => {
    const editor = editorRef.current;
    const model = editor.getModel();
    if (model && !model.isDisposed()) {
      const currentPosition = editor.getPosition();
      const lineNumber = currentPosition.lineNumber;
      const column = currentPosition.column;

      const shouldIndentFirstLine = column <= 5;
      const columnPosition = column < 5 ? 4 : column;
      const position = {
        lineNumber,
        column
      };

      const indentSpaces = "    "; // Add Four spaces for indentation
      const indentCodeSkipFirst = code.replace(/\n/g, "\n" + indentSpaces);
      const indentedCode = `${
        shouldIndentFirstLine ? " ".repeat(5 - column) : ""
      }${indentCodeSkipFirst}`;

      const op = {
        range: new monaco.Range(lineNumber, columnPosition, lineNumber, columnPosition),
        text: indentedCode + "\n",
        forceMoveMarkers: true
      };

      model.pushEditOperations([], [op], () => []);
      editor.setPosition(position);
      resetInsertedCode?.();
    }
  };

  useEffect(() => {
    insertedCode && onInsertCode?.(insertedCode);
  }, [insertedCode]);

  // Helper function to determine the active parameter
  function getActiveParameter(model: any, position: any) {
    const textUntilPosition = model.getValueInRange({
      startLineNumber: position.lineNumber,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: position.column
    });

    const parameterIndex = textUntilPosition.split(",").length - 1;
    return parameterIndex;
  }

  function getFunctionName(model: any, position: any) {
    const textUntilPosition = model.getValueInRange({
      startLineNumber: position.lineNumber,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: position.column
    });

    const matchFunction = /(\w+)\s*\(([^)]*)$/.exec(textUntilPosition);
    return matchFunction ? matchFunction[1] : null;
  }

  useEffect(() => {
    if (projectCanvasData) {
      monaco.languages.registerSignatureHelpProvider("python", {
        signatureHelpTriggerCharacters: ["(", ","],
        signatureHelpRetriggerCharacters: [",", " "],

        provideSignatureHelp: (model, position) => {
          const functionName = getFunctionName(model, position);
          const signature = get(
            helperFunctionSignatures,
            functionName ? functionName.replace("Helpers.", "") : ""
          );

          if (!signature) {
            return null; // No signature found for the current function
          }

          return {
            value: {
              signatures: [signature],
              activeSignature: 0,
              activeParameter: getActiveParameter(model, position)
            },
            dispose: () => {}
          };
        }
      });

      monaco.languages.setLanguageConfiguration("python", {
        wordPattern: /([a-zA-Z_0-9]+)/g // Treat numbers and underscores as part of words
      });

      if (completionProviderRef.current) {
        completionProviderRef.current.dispose();
      }

      completionProviderRef.current = monaco.languages.registerCompletionItemProvider("python", {
        triggerCharacters: ["_", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
        provideCompletionItems: (model, position) => {
          const word = model.getWordUntilPosition(position);
          const range = {
            startLineNumber: position.lineNumber,
            endLineNumber: position.lineNumber,
            startColumn: position.column - word.word.length,
            endColumn: position.column
          };

          const textBeforeCursor = model.getValueInRange({
            startLineNumber: position.lineNumber,
            startColumn: 1,
            endLineNumber: position.lineNumber,
            endColumn: position.column
          });

          const match = textBeforeCursor.match(/Helpers\.(\w*)$/);
          const ModifiedHelperSuggestion = map(helperSuggestions, (item) => ({
            ...item,
            insertText: match ? item.label : item.insertText
          }));

          const suggestions = [
            ...(match
              ? []
              : [
                  ...map(projectCanvasData.nodes, (item) => ({
                    label: item.name,
                    kind: monaco.languages.CompletionItemKind.Snippet,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    insertText: item.name,
                    detail: `Insert ${item.name}`
                  })),
                  {
                    label: "Helpers",
                    kind: monaco.languages.CompletionItemKind.Function,
                    insertText: `Helpers`,
                    documentation: `Helpers class`,
                    command: {
                      id: "auto-import",
                      title: "Add Helper import",
                      arguments: ["from utils.notebookhelpers.helpers import Helpers"]
                    }
                  }
                ]),

            ...ModifiedHelperSuggestion
          ];

          const newSuggestion = map(suggestions, (item) => ({ ...item, range }));
          return { suggestions: newSuggestion };
        }
      });
    }

    return () => {
      if (completionProviderRef.current) {
        completionProviderRef.current.dispose();
      }
    };
  }, [projectCanvasData]);

  const onEditorBlurRef = useRef(onEditorBlur);

  useEffect(() => {
    onEditorBlurRef.current = onEditorBlur;
  }, [onEditorBlur]);

  const handleEditorDidMount = (editor: editor.IStandaloneCodeEditor) => {
    editorRef.current = editor;

    editor?.onDidBlurEditorWidget(() => {
      const activeElement = document.activeElement;
      if (activeElement?.tagName?.toLowerCase() === "button") {
        return;
      }
      const editVal = editor.getModel()?.getValue();
      onEditorBlurRef?.current?.(editVal ?? editorValue);
    });
    editor?.onKeyDown((event) => {
      if (event.ctrlKey && event.keyCode === monaco.KeyCode.KeyT) {
        event.preventDefault();

        const editVal = editor.getModel()?.getValue();
        if (typeof onControlT === "function") {
          onControlT(editVal ?? editorValue);
        }
      }
    });
    monaco.editor.setTheme("vs");
    monaco.editor.registerCommand("auto-import", (__, statement) => {
      const currentPosition = editor.getPosition();
      const currentValue = editorRef.current.getValue();
      const { updatedCode, linesAdded } = addImportStatement(currentValue, statement);
      editorRef.current.setValue(updatedCode);
      if (currentPosition) {
        const newPosition = {
          lineNumber: currentPosition.lineNumber + (linesAdded > 0 ? linesAdded : 0),
          column: currentPosition.column
        };

        setTimeout(() => {
          editor.setPosition(newPosition);
          editor.revealPositionInCenter(newPosition);
          editor.focus();
        }, 0);
      }
    });
  };

  React.useEffect(() => {
    const editor = editorRef.current;

    if (editor) {
      //clear existing decorations
      decorationIds?.length && editor.deltaDecorations(decorationIds, []);
    }
    if (editor && errDetails && !(errDetails.lineNo === 0 && !errDetails.line)) {
      const lineNumber = errDetails.lineNo;
      const hoverMessage = errDetails.errorExplanation
        ? `${errDetails.line}\n\nError Explanation: ${errDetails.errorExplanation}`
        : errDetails.line;

      const currDecorationIds = editor.deltaDecorations(
        [],
        [
          {
            range: new monaco.Range(lineNumber, 1, lineNumber, 1),
            options: {
              isWholeLine: true,
              className: "my-line-highlight",
              hoverMessage: { value: hoverMessage }
            }
          }
        ]
      );
      setDecorationIds(currDecorationIds);
    }
  }, [errDetails, errDetails?.line, errDetails?.lineNo]);

  if (isLoading && !showConstant) {
    return <CommonLoader />;
  }

  return (
    <MonacoEditor
      key="code-recipe-code-editor"
      editorDidMount={handleEditorDidMount}
      height="100%"
      value={editorValue}
      width="100%"
      language="python"
      onChange={(value) => setEditorValue(value)}
      theme="vs"
      options={{
        readOnly: disabled,
        wordWrap: "bounded",
        wordWrapColumn: 79,
        theme: "vs",
        tabSize: 4,
        minimap: { enabled: false },
        renderLineHighlight: "none",
        scrollbar: {
          vertical: "hidden",
          horizontal: "hidden"
        },
        autoDetectHighContrast: false,
        overviewRulerLanes: 0,
        padding: {
          top: 16
        }
      }}
    />
  );
};
