import { useMutation, UseMutationResult } from "@tanstack/react-query";

// Open API
import api from "utils/AxiosClient";
import { UpdateEnvDto } from "@rapidcanvas/rc-api-core";

export interface IUpdateEnvironmentPayload {
  id: string;
  payload: UpdateEnvDto;
}

const useUpdateEnvironment = (): UseMutationResult<
  unknown,
  unknown,
  IUpdateEnvironmentPayload,
  unknown
> =>
  useMutation({
    mutationFn: async (payload: IUpdateEnvironmentPayload) => {
      if (!payload?.id || !payload?.payload) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.EnvControllerApi.updateEnv(payload?.id, payload?.payload)
      );
    }
  });

export default useUpdateEnvironment;
