import React, { useState } from "react";
import _ from "lodash";
import { Button, Tooltip } from "@material-ui/core";

import SampleSyntaxIcon from "src/icons/NewUX/SampleSyntaxIcon";
import SampleSyntaxModal from "./SyntaxModal";

interface IProps {
  tooltip?: string;
}

const SyntaxButton: React.FC<IProps> = ({ tooltip }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Tooltip title={tooltip ?? "Refer to this Syntax for API Key usage"}>
        <Button
          size="small"
          startIcon={<SampleSyntaxIcon />}
          style={{ background: "#fff", color: "#4646B5", textTransform: "capitalize" }}
          variant="contained"
          color="primary"
          onClick={handleClick}>
          Syntax
        </Button>
      </Tooltip>
      {open && <SampleSyntaxModal open={!!open} onClose={handleClose} />}
    </>
  );
};

export default SyntaxButton;
