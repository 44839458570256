// Packages
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Id, toast } from "react-toastify";

// Open API
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Utils
import api from "utils/AxiosClient";
import envRelaunchNotification from "src/utils/envRelaunchNotification";

// Hooks
import { updateEnvsQueryDataById } from "./usePollEnvironments";

interface IPayload {
  id?: string;
  environment?: EnvDto;
}

const useRelaunchEnvironment = (): UseMutationResult<EnvDto, unknown, IPayload, unknown> => {
  const queryClient = useQueryClient();

  let toastId: Id | undefined;

  return useMutation({
    mutationFn: async ({ id, environment }: IPayload) => {
      if (!id || !environment) {
        throw "Invalid payload!";
      }

      toastId = envRelaunchNotification(Number(environment?.shutdownStrategy?.inactivityInHours));

      return await api.fetchResponse(
        async () => await api.EnvControllerApi.updateEnv(id, environment, true, true, true)
      );
    },
    onSuccess: (__1, payload) => {
      !!payload?.id &&
        updateEnvsQueryDataById(queryClient, payload?.id, EnvDtoLaunchStatusEnum.Launching);
    },
    onError: () => !!toastId && toast.dismiss(toastId)
  });
};

export default useRelaunchEnvironment;
