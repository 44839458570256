import Alert from "@material-ui/lab/Alert";
import React from "react";
import { Box } from "@material-ui/core";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import { Spinner } from "src/components";
import { TOKEN } from "src/utils/AxiosClient";
import { useGetDataAppDeepLink } from "./useGetDataAppDeepLink";

const DataAppDeepLink = () => {
  const { dataAppName, tenantName } = useParams<$TSFixMe>();
  const [searchParams] = useSearchParams();
  const token = searchParams.get(TOKEN);

  const { data, isLoading, error } = useGetDataAppDeepLink({
    dataAppName: decodeURIComponent(dataAppName!),
    tenantName: tenantName ? decodeURIComponent(tenantName) : "",
    token
  });

  if (data && "accessUrl" in data) {
    window.location.replace(data?.accessUrl);
  }

  const errorMessage =
    data && "errorMsg" in data
      ? data?.errorMsg
      : (error as $TSFixMe)?.response?.data?.msg || (error as $TSFixMe)?.message;

  return isLoading ? (
    <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
      <Spinner />
    </Box>
  ) : errorMessage ? (
    <Alert severity="error">{errorMessage}</Alert>
  ) : (
    <React.Fragment />
  );
};

export default DataAppDeepLink;
