import React, { useMemo } from "react";

// Packages
import shallow from "zustand/shallow";
import { useQueryClient } from "@tanstack/react-query";
import { filter, includes } from "lodash";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";

// Hooks
import { useDeleteProject } from "src/hooks/api";
import { UseGetProjectsQueryKeys } from "src/hooks/api/projects/useGetProjects";

// Stores
import useTenantsStore from "src/stores/tenant-management.store";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import { ProjectSettingsHelperText } from "../utils/ProjectSettings.constants";

type Props = {
  projectId: string;
  projectName?: string;
  resetDeleteProject: () => void;
  onDelete: () => void;
};

const DeleteProject = (props: Props) => {
  const { projectId, projectName, resetDeleteProject, onDelete } = props || {};

  const queryClient = useQueryClient();

  const [toggleUserTenantsRefreshStore] = useTenantsStore(
    (state) => [state.toggleUserTenantsRefresh],
    shallow
  );

  const {
    isLoading: isProjectDeleting,
    mutateAsync: deleteProjectMutation,
    reset: resetDeleteProjectMutation
  } = useDeleteProject();

  const deleteProject = async () => {
    resetDeleteProjectMutation();
    await deleteProjectMutation(
      { projectId },
      {
        onSuccess: () => {
          toastWrapper({
            type: ToastTypes.Success,
            content: ProjectSettingsHelperText.ProjectDeleted
          });

          queryClient.setQueryData([UseGetProjectsQueryKeys.Projects], (prevData: any) =>
            filter(prevData, (item) => item.id !== projectId)
          );

          onDelete();
          toggleUserTenantsRefreshStore();
        },
        onSettled: () => resetDeleteProject()
      }
    );
  };

  const projectDeletePromptDetails = useMemo(
    () => ({
      title: "Delete Project",

      messageLine1: (
        <>
          Deleting the project{!!projectName && <b>&nbsp;&quot;{projectName}&quot;</b>} removes the
          project permanently from the Projects list and will also delete the associated prediction
          service(s) linked to the model(s) within this project(if any).
        </>
      ),
      messageLine2: "Are you sure you want to continue?"
    }),
    [projectName]
  );

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={projectDeletePromptDetails.title}
      content={[projectDeletePromptDetails.messageLine1, projectDeletePromptDetails.messageLine2]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetDeleteProject();
        }
      }}
      cancelLabel="No"
      onSubmit={deleteProject}
      isCancelDisabled={!!isProjectDeleting}
      isSubmitDisabled={!!isProjectDeleting}
      isSubmitting={!!isProjectDeleting}
      hideCloseIcon
    />
  );
};

export default DeleteProject;
