import { useEffect } from "react";
import _ from "lodash";

interface IProps {
  isTestDisabled?: boolean;
  isTestInProgress?: boolean;
  onTest?: () => void;
  isRunDisabled: boolean;
  isRunInProgress: boolean;
  onRun: () => void;
}

const useActionsWithKeyboardKeys = (props: IProps) => {
  const { isTestDisabled, isRunDisabled, isRunInProgress, isTestInProgress, onRun, onTest } = props;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (_.isFunction(onTest) && (event.ctrlKey || event.metaKey) && event.key === "t") {
        event.preventDefault();
        if (!isTestDisabled && !isTestInProgress) {
          onTest();
        }
      }

      if ((event.ctrlKey || event.metaKey) && (event.key === "r" || event.key === "R")) {
        event.preventDefault(); // Prevent default reload action
        if (!isRunInProgress && !isRunDisabled) {
          onRun();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isTestDisabled, isTestInProgress, onTest, isRunDisabled, isRunInProgress, onRun]);
};

export default useActionsWithKeyboardKeys;
