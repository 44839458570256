// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Hooks
import { usePollEnvironment } from "src/hooks/api/environments/usePollEnvironments";

// Styles
import { useRelaunchEnvironment, useStopEnvironment } from "src/hooks/api";

interface IProps {
  environment: EnvDto;
}

const useStatus = (props: IProps) => {
  const { environment } = props;
  const { id, launchStatus: status } = environment;

  usePollEnvironment({ id, status, notifyOnRelaunchFailed: true });

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isRelaunching,
    mutateAsync: relaunchMutation,
    reset: resetRelaunchMutation
  } = useRelaunchEnvironment();

  const {
    isLoading: isStopping,
    mutateAsync: stopMutation,
    reset: resetStopMutation
  } = useStopEnvironment();
  // << ENDS - Query hooks

  // Relaunch environment - STARTS >>
  const onRelaunch = async (envId?: string) => {
    const id = envId;

    if (!environment || !id) return;

    resetRelaunchMutation();
    await relaunchMutation({
      id,
      environment
    });
  };
  // << ENDS - Relaunch environment

  // Stop environment - STARTS >>
  const onStop = async (envId?: string) => {
    const id = envId;

    if (!id) return;

    resetStopMutation();
    await stopMutation({ id });
  };
  // << ENDS - Stop environment

  return { onRelaunch, onStop, isStopping, isRelaunching };
};

export default useStatus;
