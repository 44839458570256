import { includes } from "lodash";

import {
  DELETING_DATAAPP_MSG,
  LAUNCHING_DATAAPP_MSG,
  STOPPING_DATAAPP_MSG
} from "./DataApps.constants";
import { DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

const deleteTooltip = (status?: DataAppDtoLaunchStatusEnum) => {
  switch (status) {
    case DataAppDtoLaunchStatusEnum.Deleting:
      return DELETING_DATAAPP_MSG;
    case DataAppDtoLaunchStatusEnum.Launching:
      return LAUNCHING_DATAAPP_MSG;
    case DataAppDtoLaunchStatusEnum.Stopping:
      return STOPPING_DATAAPP_MSG;
    default:
      return "";
  }
};

const deleteDisabled = (status?: DataAppDtoLaunchStatusEnum) => {
  return includes(
    [
      DataAppDtoLaunchStatusEnum.Stopping,
      DataAppDtoLaunchStatusEnum.Launching,
      DataAppDtoLaunchStatusEnum.Deleting
    ],
    status
  );
};

export { deleteTooltip, deleteDisabled };
