import { useCallback, useState } from "react";

// Packages
import { Node as ReactFlowNode, Edge as ReactFlowEdge } from "react-flow-renderer";
import { assign, isEqual, keyBy, map, uniqWith } from "lodash";

// Utils
import { postAPIWithRethrow } from "src/utils/apiService";
import { toastWrapper } from "src/utils/toastWrapper";

// Contexts
import { useDagFlowContext } from "../context/useDagFlowContext";
import { Node } from "@rapidcanvas/rc-api-core";

// Types
import { NodeType } from "../../../Dag.types";

type Props = {
  projectId?: string;
  nodesStore: Node[];
  nodes: ReactFlowNode[];
  edges: ReactFlowEdge[];
  formatNodesToCanvas: (nodes: NodeType[]) => NodeType[];
  saveNodes: ({
    payloadNodes,
    onSettled,
    onSuccess
  }: {
    payloadNodes: ReactFlowNode[];
    onSettled?: () => void;
    onSuccess?: () => void;
  }) => void;
};

const useAutoLayout = (props: Props) => {
  const { projectId, nodesStore, nodes, edges, formatNodesToCanvas, saveNodes } = props || {};
  const [autoLayoutLoading, setAutoLayoutLoading] = useState(false);

  const { initialNodes, setInitialNodes, initialEdges, setNodeActions } = useDagFlowContext();

  const autoLayout = useCallback(async () => {
    setAutoLayoutLoading(true);
    setNodeActions((prev) => ({
      ...prev,
      autoLayout: true
    }));

    const formattedNodes = formatNodesToCanvas(initialNodes);

    // @REFACTOR
    try {
      const minimalNodes = uniqWith(
        map(formattedNodes, (node: Node) => ({
          id: node.id,
          column: node.column,
          row: node.row
        })),
        isEqual
      );

      const minimalEdges = uniqWith(
        initialEdges
          ?.filter((edge) => !!edge?.source && !!edge?.target)
          ?.map((edge) => ({
            id: edge.id,
            source: edge.source,
            target: edge.target
          })),
        isEqual
      );

      const updatedLayout = await postAPIWithRethrow(`/v2/rearrange-graph/${projectId}`, {
        nodes: minimalNodes,
        edges: minimalEdges
      });

      // @ts-ignore
      const updatedNodes: ReactFlowNode[] = map(updatedLayout?.nodes, (node) => ({
        id: node?.id,
        position: {
          x: !node?.column ? 0 : node?.column * 180,
          y: !node?.row ? 0 : node?.row * 180
        }
      }));

      await saveNodes({
        payloadNodes: updatedNodes,
        onSuccess: () => {
          // Create a lookup map for the nodes
          const updatedNodeMap = keyBy(updatedNodes, "id");

          setInitialNodes(() =>
            map(initialNodes, (thisNode: NodeType) =>
              assign({}, thisNode, {
                position: updatedNodeMap?.[thisNode?.id]?.position ?? thisNode?.position
              })
            )
          );
        }
      });
    } catch (e) {
      toastWrapper({
        type: "error",
        content: "Canvas element position update failed"
      });
    } finally {
      setNodeActions((prev) => ({
        ...prev,
        autoLayout: false
      }));
      setAutoLayoutLoading(false);
    }
  }, [initialNodes, initialEdges, nodesStore, nodes, edges, projectId]);

  return { autoLayout, autoLayoutLoading };
};

export default useAutoLayout;
