import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import shallow from "zustand/shallow";
import _ from "lodash";

import AIGuideContainer from "../common/AIGuideContainer";
import { useGetAIGuideRecipeCharts, useGetCanvas } from "src/hooks/api";
import { useAIGuideStore, useCanvasStore } from "src/store/store";
import GroupedCharts from "../common/GroupedCharts";
import ComponentNotFound from "src/components/Errors/ComponentNotFound";
import { useAIGuideContext } from "../common/useAIGuideContext";
import { RecipeTypes } from "src/pages/private/ProjectsModule/utils";
import { ThreadResponseDto, AIChatResponseDto } from "@rapidcanvas/rc-api-core";

type IProps = {
  thread: ThreadResponseDto;
  messages: AIChatResponseDto[];
};

export const AIGuide = ({ messages, thread }: IProps) => {
  const { projectId, scenarioId, modelId: modelIdInContext } = useAIGuideContext();
  const { threadId, entityId } = thread;
  const targetId = entityId!;

  const modelId = targetId || modelIdInContext;

  const [nodes, edges] = useCanvasStore((state: $TSFixMe) => [state.nodes, state.edges], shallow);

  const [query, setQuery] = useState("");
  const [canvas, setCanvas] = useState(
    nodes?.length !== 0 && edges?.length !== 0 ? { nodes, edges } : null
  );

  const selectedNode = canvas?.nodes?.find(({ id }: { id: string }) => id === modelId);
  const { data: canvasData, isFetching } = useGetCanvas({
    projectId,
    scenarioId,
    enabled: !canvas,
    cacheTime: Infinity
  });
  const { isFetching: isFetchingRapidCharts, data: rapidRecipeCharts } =
    useGetAIGuideRecipeCharts();
  const [generatingState] = useAIGuideStore((state) => [state.generatingState]);
  const generateState = _.get(generatingState, threadId!);

  useEffect(() => {
    if (!isFetching && !canvas && canvasData) {
      setCanvas(canvasData);
    }
  }, [canvasData, isFetching]);

  const chartList = useMemo(() => {
    if (!rapidRecipeCharts) {
      return [];
    }
    const recipeId = canvas?.edges?.find(
      ({ target }: { target: string }) => target === modelId
    )?.source;
    const recipeNode = canvas?.nodes?.find(({ id }: { id: string }) => id === recipeId);
    const recipeChildrenIds = canvas?.edges
      ?.filter(({ source }: { source: string }) => source === recipeId)
      ?.map(({ target }: { target: string }) => target);
    const chartNode = canvas?.nodes?.find(
      ({ type, id }: { type: string; id: string }) =>
        type === "CHART" && recipeChildrenIds?.includes(id)
    );
    const shouldAllowAllCharts = recipeNode?.recipeType === RecipeTypes.AiAssisted;
    return (
      eval(chartNode?.viewData)?.filter(
        ({ name, chartType }: { name: string; chartType: string }) => {
          const isJsonTypeChart = chartType === "JSON";
          if (shouldAllowAllCharts) {
            return isJsonTypeChart;
          }
          return isJsonTypeChart && rapidRecipeCharts.includes(name);
        }
      ) || []
    );
  }, [canvas, rapidRecipeCharts]);

  const isLoading = isFetching || !selectedNode || !canvas || isFetchingRapidCharts;

  if (!scenarioId || !projectId) {
    return <ComponentNotFound />;
  }

  if (!isLoading && chartList.length === 0) {
    return (
      <Box p="16px">
        <Typography>No supported charts found!</Typography>
      </Box>
    );
  }

  return (
    <AIGuideContainer
      query={query}
      projectId={projectId!}
      messages={messages}
      thread={thread}
      isLoading={isLoading}
      setQuery={setQuery}
      generateState={generateState}
      threadId={threadId!}>
      <GroupedCharts
        chartList={chartList}
        isGenerating={generateState?.isGenerating}
        title={selectedNode?.name || ""}
        isAiGuide
      />
    </AIGuideContainer>
  );
};

export default React.memo(AIGuide);
