import { filter, map, replace } from "lodash";

import { GroupLogDto, GroupLogDtoStatusEnum } from "@rapidcanvas/rc-api-core";
import { envRelaunchMessage } from "src/utils/envRelaunchNotification";

const getRecipeLogs = (
  isRunRecipeLogs: boolean,
  response: GroupLogDto,
  showAllLogs: boolean,
  isEnvAlreadyRelaunched: boolean,
  isTesting?: boolean
): string[] => {
  const dbValidationLogs = isRunRecipeLogs
    ? map(
        filter(
          response?.results,
          ({ status, type, msgList }) =>
            status === "FAILURE" && type === "RECIPE" && msgList?.[0]?.msg
        ),
        ({ msgList }: any) =>
          replace(msgList[0].msg, " Please check recipe run logs for more details.", "")
      )
    : [];

  const recipeLogs: string[] = [
    ...(!showAllLogs && isEnvAlreadyRelaunched ? [envRelaunchMessage] : []),
    ...(response?.status === GroupLogDtoStatusEnum.Running || isTesting
      ? response?.transformLogs?.[0]?.logs ?? []
      : response?.transformLogs?.reduce(
          (acc: string[], item) =>
            !!item?.logs && item?.logs?.length > 0
              ? [...acc, `>>>>> ${item?.name} <<<<<`, ...item?.logs]
              : acc,
          []
        ) ?? []),
    ...dbValidationLogs
  ];

  return recipeLogs;
};

export { getRecipeLogs };
