import React, { useEffect, useState } from "react";

import NewModal from "src/components/Modal/NewModal";
import { Grid, TextField, Tooltip } from "@material-ui/core";
import EventBus from "src/utils/EventBus";
import { addApiKey, editApiKey } from "./ApiKeys";
import { Controller, useForm } from "react-hook-form";
import { validateNameField } from "src/utils/formFieldUtils";

import dayjs from "dayjs";
import useCreateApiKey from "src/hooks/api/api-keys/useCreateApiKey";
import { ApiAccessKeyDto } from "@rapidcanvas/rc-api-core";
import useUpdateApiKey from "src/hooks/api/api-keys/useUpdateApiKey";
import { isEmpty, map } from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_API_KEYS } from "src/hooks/api/api-keys/useApiKeys";
import { handleResponse } from "src/utils/apiService";

import isBetween from "dayjs/plugin/isBetween";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomDatePicker from "./CustomDatePicker";
import { CalendarToday, InfoOutlined } from "@material-ui/icons";
import { QUERY_KEY_CURRENT_TENANT } from "src/hooks/api/tenant/useFetchCurrentTenant";

dayjs.extend(customParseFormat);

dayjs.extend(isBetween);

export const isUpForRenual = (expiry: number) => {
  const inputDate = dayjs(expiry).startOf("day");
  const oneMonthFromNow = dayjs().add(1, "M").startOf("day");
  const after2Years = dayjs().add(2, "y").startOf("day");
  return inputDate.isBetween(oneMonthFromNow, after2Years, null, "[]");
};

const ApiKeyModal: React.FC = ({}) => {
  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState<ApiAccessKeyDto>();
  const createApiKey = useCreateApiKey();
  const updateApiKey = useUpdateApiKey();
  const queryClient = useQueryClient();
  const [isFirst, setIsFirst] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    watch
  } = useForm({
    mode: "onChange",
    defaultValues: {
      expiryDate: dayjs().add(1, "y").format("YYYY-MM-DDTHH:mm"),
      name: ""
    },
    reValidateMode: "onChange"
  });

  const nameValue = watch("name");

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    EventBus.subscribe(addApiKey, (isFirst: boolean) => {
      setOpen(true);
      setRecord(undefined);
      setIsFirst(!!isFirst);
      setTimeout(() => {
        setValue("name", "");
        setValue("expiryDate", dayjs().add(1, "y").format("YYYY-MM-DDTHH:mm"), {
          shouldValidate: true
        });
      }, 0);
    });
    EventBus.subscribe(editApiKey, (payload: { record: ApiAccessKeyDto }) => {
      setIsFirst(false);
      setOpen(true);
      setRecord(payload.record);
      setTimeout(() => {
        setValue("name", payload.record?.name ?? "");
        setValue("expiryDate", dayjs(payload.record.keyExpiryTs).format("YYYY-MM-DDTHH:mm") ?? "", {
          shouldValidate: true
        });
      }, 0);
    });
    return () => {
      EventBus.unsubscribe(addApiKey);
      EventBus.unsubscribe(editApiKey);
    };
  }, []);

  const onSubmit = (data: any) => {
    if (!!record) {
      updateApiKey.mutate(
        {
          id: record.id ?? "",
          updateApikeyPayload: {
            keyExpiryTs: dayjs(data.expiryDate).endOf("day").valueOf(),
            name: data.name
          }
        },
        {
          onSuccess: (data) => {
            queryClient.setQueryData([QUERY_KEY_API_KEYS], (prevData: any) =>
              map(prevData, (item) => (item.id === data.id ? data : item))
            );
            handleResponse({ successMessage: `Api key ${data.name} updated successfully.` });
            setOpen(false);
          }
        }
      );
    } else {
      createApiKey.mutate(
        {
          createApikeyPayload: {
            keyExpiryTs: dayjs(data.expiryDate).endOf("day").valueOf(),
            name: data.name
          }
        },
        {
          onSuccess: (data) => {
            if (isFirst) {
              queryClient.invalidateQueries([QUERY_KEY_CURRENT_TENANT]);
            }
            setOpen(false);
            EventBus.publish("SUCCESS_API_KEY", { apiKey: data.apiKey, name: data.name });
            queryClient.setQueryData([QUERY_KEY_API_KEYS], (prevData: any) => {
              return isEmpty(prevData) ? [data] : [...prevData, data];
            });
          }
        }
      );
    }
  };

  return (
    <NewModal
      header={!!record ? "Edit API Key" : "Create API Key"}
      width="550px"
      contentWidth="100%"
      submitButtonLabel={!!record ? "Update" : "Save"}
      loading={updateApiKey.isLoading || createApiKey.isLoading}
      open={open}
      modelBg="#FFFFFF"
      submitDisabled={
        !isEmpty(errors) || isEmpty(nameValue) || updateApiKey.isLoading || createApiKey.isLoading
      }
      headerStyle={{ borderBottom: "1px solid rgb(224, 224, 224)" }}
      onFinish={handleSubmit(onSubmit)}
      onClose={handleClose}>
      <Grid container direction="column" style={{ padding: "12px", gap: "24px" }}>
        <TextField
          required
          fullWidth
          size="small"
          {...register("name", {
            validate: (value) => {
              const { error } = validateNameField({
                fieldName: value,
                fieldNameLabel: "API Key name"
              });
              return error;
            }
          })}
          label="API Key Name"
          variant="outlined"
          error={!!errors.name}
          helperText={errors.name && <>{errors.name?.message}</>}
        />
        <Grid container style={{ gap: "5px", flexWrap: "nowrap" }}>
          <Controller
            name="expiryDate"
            control={control}
            rules={{
              required: "Expiry date is required",
              validate: (value) => {
                const expiryTimestamp = dayjs(value).valueOf();
                if (!isUpForRenual(expiryTimestamp)) {
                  return "The expiry date should be minimum 1 month and max 2 years from current date";
                }
                return true;
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <CustomDatePicker
                  required
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={!!error}
                  format="YYYY-MM-DD"
                  label="Expiry Date"
                  helperText={error ? error.message : undefined}
                  minDate={dayjs().add(1, "M")}
                  maxDate={dayjs().add(2, "year")}
                  InputLabelProps={{ shrink: true }}
                  inputVariant="outlined"
                  InputProps={{ endAdornment: <CalendarToday fontSize={"small"} /> }}
                  inputProps={{ style: { paddingTop: "10.5px", paddingBottom: "10.5px" } }}
                />
              );
            }}
          />
          <Tooltip
            title={"The expiry date should be minimum 1 month and max 2 years from current date"}>
            <InfoOutlined
              style={{
                cursor: "help",
                marginTop: "8px",
                opacity: 0.5
              }}
            />
          </Tooltip>
        </Grid>
        {!record && (
          <span style={{ fontSize: "14px", opacity: 0.5 }}>API Key will be auto generated</span>
        )}
      </Grid>
    </NewModal>
  );
};

export default ApiKeyModal;
