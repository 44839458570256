import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { Typography, makeStyles } from "@material-ui/core";

import { Table } from "src/components";
import { dateFormat } from "src/utils/dateFormat";

import { DataSourceTabKeys } from "../utils/DataSources.constants";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles(() => ({
  root: {},
  link: {
    color: "#003656",
    background: "none",
    border: "none",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  }
}));

const Datasets = (props: $TSFixMe) => {
  const { title, isDataSourceUsageLoading, usageDatasets, tab } = props || {};

  const classes = useStyles();

  const columns = useMemo(
    () => [
      ...(tab === DataSourceTabKeys.Jobs
        ? [
            {
              id: "Scheduler Name",
              accessor: "jobName",
              Header: "Scheduler Name",
              isSortable: true,
              Cell: ({ row }: $TSFixMe) => {
                const { projectId, jobId, jobName } = row?.original || {};

                return !!projectId && !!jobId ? (
                  <Link className={classes.link} to={`/projects/${projectId}/jobs/${jobId}`}>
                    <Typography variant="subtitle2">{jobName}</Typography>
                  </Link>
                ) : (
                  jobName
                );
              }
            }
          ]
        : []),
      {
        id: "Dataset Name",
        accessor: "entityName",
        Header: "Dataset Name",
        isSortable: true,
        Cell: ({ row }: $TSFixMe) => {
          const { projectId, jobId, defaultScenarioId, entityId, entityName } = row?.original || {};

          let path = "";

          if (tab === DataSourceTabKeys.Datasets) {
            if (!!projectId && !!defaultScenarioId && !!entityId) {
              path = `/view-data/projects/${projectId}/entity/${entityId}/scenario/${defaultScenarioId}/data`;
            }
          }

          if (tab === DataSourceTabKeys.Jobs) {
            if (!!projectId && !!jobId) {
              path = `/projects/${projectId}/jobs/${jobId}`;
            }
          }

          return !!path ? (
            <Link className={classes.link} to={path}>
              <Typography variant="subtitle2">{entityName}</Typography>
            </Link>
          ) : (
            entityName
          );
        }
      },
      {
        id: "Project Name",
        accessor: "projectName",
        Header: "Project Name",
        isSortable: true,
        Cell: ({ row }: $TSFixMe) => {
          const { projectId, projectName } = row?.original;

          // eslint-disable-next-line no-extra-boolean-cast
          return !!projectId ? (
            <Link className={classes.link} to={`/projects/${projectId}`}>
              <Typography variant="subtitle2">{projectName}</Typography>
            </Link>
          ) : (
            projectName
          );
        }
      },
      ...(tab === DataSourceTabKeys.Datasets
        ? [
            {
              id: "Dataset Created on",
              accessor: "entityCreated",
              Header: "Dataset Created on",
              isSortable: true,
              Cell: (row: $TSFixMe) => <span>{dateFormat(row.value)}</span>
            }
          ]
        : []),
      ...(tab === DataSourceTabKeys.Jobs
        ? [
            {
              id: "Scheduler Created on",
              accessor: "jobCreated",
              Header: "Scheduler Created on",
              isSortable: true,
              Cell: (row: $TSFixMe) => <span>{dateFormat(row.value)}</span>
            }
          ]
        : [])
    ],
    [tab]
  );

  return (
    <>
      {isDataSourceUsageLoading ? (
        <CommonLoader />
      ) : (
        <Table
          data={usageDatasets}
          skipPageReset
          size="small"
          columns={columns}
          isCellSortEnabled
          orderByDefault={
            tab === DataSourceTabKeys.Jobs ? "Scheduler Created on" : "Dataset Created on"
          }
          actionHeader={{
            title,
            actions: <></>
          }}
          showPagination={usageDatasets?.length > 0}
          rowsPerPage={5}
          isTheadSticky
        />
      )}
    </>
  );
};

export default Datasets;
