import React, { useMemo, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { isEmpty, forEach, includes, toLower, some } from "lodash";
import { useParams } from "react-router-dom";

import NewThemeWrapper from "src/styles/NewThemeWrapper";
import PredictionButton from "./components/CreatePredictionJobButton";
import PredictionJobDefault from "./components/PredictionJobDefault";
import PredictionJobLogsDrawer from "./components/PredictionJobLogs/PredictionJobLogsDrawer";
import PredictionRunsTable from "./components/PredictionRunsTable";
import SubTopNavBarBreadcrumbs from "./components/SubTopNavbarBreadcrumbs";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import useGetAllModels from "hooks/api/projects/useGetAllModels";
import useGetAllPredictionRuns from "hooks/api/projects/useGetAllProjectRuns";
import { PredictionJobConstants } from "./utils/PredictionJob.constants";
import { useProjectContext } from "../../context/useProjectContext";
import { JobRunStatuses } from "../Jobs/utils/Jobs.constants";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles({
  pageContainer: {
    marginLeft: 50,
    padding: 16,
    position: "relative"
  }
});

const PredictionJob: React.FC = () => {
  const classes = useStyles();
  const { project } = useProjectContext();
  const { projectId } = useParams();
  const [logsRunDetails, setLogsRunDetails] = useState<{
    runId: string;
    jobName: string;
    runEntryId: string;
    recipeId?: string;
    recipeName?: string;
    errorMsg: string;
  } | null>(null);
  const [isJobRunning, setIsJobRunning] = useState(false);

  const modelResponse = useGetAllModels(projectId);
  const { data, isLoading } = useGetAllPredictionRuns(projectId);

  const name = useMemo(() => {
    let count = 1;
    forEach(data, ({ dto }) => {
      if (
        includes(toLower(dto.name), toLower(PredictionJobConstants.UntitledName)) ||
        dto.name === `${PredictionJobConstants.UntitledName} ${count + 1}`
      ) {
        count += 1;
      }
    });

    while (
      some(data, ({ dto }) => dto.name === `${PredictionJobConstants.UntitledName} ${count}`)
    ) {
      count += 1;
    }

    return `${PredictionJobConstants.UntitledName} ${count}`;
  }, [data]);

  const handleLogsOpen = ({
    runId,
    runEntryId,
    recipeId,
    recipeName,
    errorMsg,
    jobName,
    status
  }: {
    runId: string;
    runEntryId: string;
    recipeId: string;
    recipeName?: string;
    errorMsg: string;
    jobName: string;
    status: string;
  }) => {
    setLogsRunDetails({ runId, runEntryId, recipeId, recipeName, errorMsg, jobName });
    setIsJobRunning(
      includes(
        [
          JobRunStatuses.Created,
          JobRunStatuses.EntityLoading,
          JobRunStatuses.Running,
          JobRunStatuses.Started
        ],
        status
      )
    );
  };

  return (
    <NewThemeWrapper>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: <SubTopNavBarBreadcrumbs project={project} />
        }}
        subTopNavBarRightSection={{
          component: (
            <PredictionButton projectId={projectId!} models={modelResponse.data} name={name} />
          )
        }}
      />
      {isLoading || modelResponse.isLoading ? (
        <CommonLoader />
      ) : (
        <Grid item xs={12} className={classes.pageContainer}>
          {isEmpty(data) ? (
            <PredictionJobDefault
              data-testid="predict-job-default"
              extra={
                <PredictionButton projectId={projectId!} models={modelResponse.data} name={name} />
              }
            />
          ) : (
            <PredictionRunsTable
              data={data ?? []}
              projectId={projectId!}
              models={modelResponse.data}
              onLogsOpen={handleLogsOpen}
            />
          )}
        </Grid>
      )}
      {logsRunDetails && (
        <PredictionJobLogsDrawer
          isJobRunning={isJobRunning}
          open={!!logsRunDetails}
          projectId={projectId!}
          logRunDetails={logsRunDetails}
          onClose={() => setLogsRunDetails(null)}
        />
      )}
    </NewThemeWrapper>
  );
};

export default PredictionJob;
