import React, { useCallback, useMemo, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { useNavigate, useParams, useLocation, generatePath } from "react-router-dom";
import { Handle, Position } from "react-flow-renderer";
import _ from "lodash";

import StorageIcon from "@material-ui/icons/Storage";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import WarningIcon from "@material-ui/icons/Warning";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import BlockIcon from "@material-ui/icons/Block";

import { EntityModalV2 } from "../CanvasDrawerItems";
import { addEllipsis, handleClickClosure } from "../../../helpers/helpers";

import { useDataSourcesStore, useCanvasStore, useDrawerStore } from "../../../store/store";
import { sideComponentSetter, connectorsGetter } from "../../../store/store.selectors";

import styles from "./EntityBlock.module.scss";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";
import { shouldHighlight } from "../helpers/CanvasFlow.helpers";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { WebPaths } from "src/routing/routes";

const EntityBlockV2 = ({ data }: $TSFixMe) => {
  const { scenarioId } = useParams<$TSFixMe>();

  const location = useLocation();
  const isJobCanvasPath = useMemo(() => /job-canvas/.test(location.pathname), [location.pathname]);

  const [isElementHovered, setIsElementHovered] = useState(false);
  const setSideComponent = useDrawerStore(sideComponentSetter);
  const navigate = useNavigate();
  const shouldBlockClickHandlerTrigger = useCanvasStore(
    (state) => state.shouldBlockClickHandlerTrigger
  );
  const nodeToFocus = useCanvasStore((state) => state.nodeToFocus);
  const connectorsStore = useDataSourcesStore(connectorsGetter);
  const { canAddStandardRecipe } = useAccessControlContext();

  const disabledBlockMessage = useMemo(() => {
    if (!!data?.isJobCanvas && ["UNBUILT", "EMPTY", "SKIPPED"].includes(data?.status)) {
      return `Certain click actions are restricted as supplementary details for the specified entity ${data?.label} are not accessible in the context of this scheduler run entry.`;
    }

    return "";
  }, [data?.isJobCanvas, data?.status]);

  const handleSingleClick = () => {
    if (!!disabledBlockMessage) {
      return;
    }

    if (!!data?.jobProps) {
      if (!isJobCanvasPath) {
        return;
      }
    }

    const dataToSet = {
      ...data,
      connector,
      scenarioId: scenarioId || data?.scenarioId
    };
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
    setSideComponent({
      sideComponent: EntityModalV2,
      sideComponentProps: { noHeader: true, data: dataToSet, canAddStandardRecipe }
    });
  };

  const handleDoubleClick = () => {
    if (!!disabledBlockMessage) {
      return;
    }

    let path = generatePath(WebPaths.ViewData, {
      projectId: data.projectId,
      scenarioId: scenarioId || data?.scenarioId,
      entityId: data.itemId,
      section: "data"
    });

    if (!!data?.jobProps) {
      if (!isJobCanvasPath) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        entityId: data.itemId,
        section: "data"
      });
    }

    navigate(path);

    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  const handleMouseEnter = useCallback(() => {
    setIsElementHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsElementHovered(false);
  }, []);

  const connector = useMemo(
    () =>
      _.find(connectorsStore, {
        name:
          data?.entityDSDetails?.type === thirdPartyTypeName
            ? data?.entityDSDetails?.tpConnectorType
            : data?.entityDSDetails?.type
      }) || {},
    [connectorsStore, data?.entityDSDetails?.type]
  );

  const blockIcon = useMemo(() => {
    if (isElementHovered) {
      return <StorageIcon />;
    }

    if (data.image) {
      return <img src={data.image} alt="recipe-icon" />;
    }

    if (!!data?.entityDSDetails?.id) {
      let icon = <DoneAllIcon />;

      if (data?.entityDSDetails?.purpose === "IMPORT") {
        icon =
          connector?.type === "default" && connector?.image ? (
            connector?.image || <CircularProgress size={20} />
          ) : connector?.url ? (
            <img src={connector?.url} alt={connector?.displayName} />
          ) : (
            <CircularProgress size={20} />
          );
      }

      return icon;
    } else {
      if (data.status === "PENDING") {
        return <HourglassEmptyIcon />;
      }
      if (data.status === "BUILT") {
        return <DoneAllIcon />;
      }
      if (data.status === "UNBUILT") {
        return <BlockIcon />;
      }
      if (data.status === "EMPTY") {
        return <WarningIcon />;
      }
      if (data.status === "SKIPPED") {
        return <SkipNextIcon />;
      }
    }

    return <StorageIcon />;
  }, [data.status, data?.entityDSDetails, connector, isElementHovered]);

  const blockStatus = useMemo(() => {
    if (data.status === "PENDING") {
      return "Pending...";
    }
    if (data.status === "UNBUILT") {
      return "Unbuilt";
    }
    if (data.status === "BUILT") {
      return "Built";
    }
    if (data.status === "EMPTY") {
      return "Empty";
    }
    if (data.status === "SKIPPED") {
      return "Skipped";
    }
    return "";
  }, [data.status]);

  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [, setIsEntitySelected] = useState(false);

  React.useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Meta" || e.key === "Control") {
        setIsCtrlPressed(true);
      }
    });

    document.addEventListener("keyup", (e) => {
      if (e.key === "Meta" || e.key === "Control") {
        setIsCtrlPressed(false);
      }
    });
  }, []);

  const getSelectedEntities = () => {
    let selectedEntities = sessionStorage.getItem("selectedEntities");

    selectedEntities = !selectedEntities ? [] : JSON.parse(selectedEntities);

    return selectedEntities;
  };

  const isEntitySelected = () => {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    return getSelectedEntities().includes(data?.itemId);
  };

  const handleMultiSelection = () => {
    let selectedEntities = getSelectedEntities();

    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    if (selectedEntities.includes(data?.itemId)) {
      selectedEntities = (selectedEntities as $TSFixMe).filter(
        (entity: $TSFixMe) => entity !== data?.itemId
      );
      setIsEntitySelected(false);
    } else {
      (selectedEntities as $TSFixMe).push(data?.itemId);
      setIsEntitySelected(true);
    }

    sessionStorage.setItem("selectedEntities", JSON.stringify(selectedEntities));
  };

  const entityBlockContainerAdditionalStyles = useMemo(() => {
    let thisStyles = styles.defaultEntityBlockContainer;

    if ((data?.entityDSDetails?.id && data?.entityDSDetails?.purpose === "IMPORT") || data.image) {
      thisStyles = styles.dataSourceEntityBlockContainer;
    }

    return thisStyles;
  }, [styles, data?.entityDSDetails, data.image]);

  return (
    <>
      <Handle type="target" position={Position.Left} />
      <Tooltip title={disabledBlockMessage}>
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          role="button"
          tabIndex={0}
          className={clsx(styles.entityBlockContainer, entityBlockContainerAdditionalStyles, {
            [styles.highlighted]: shouldHighlight(data?.label, nodeToFocus),
            [styles.entityBlockContainerUnbuilt]: data.status === "UNBUILT",
            [styles.entityBlockContainerPending]: data.status === "PENDING",
            [styles.entityBlockContainerBuilt]: data.status === "BUILT",
            [styles.entityBlockContainerEmpty]: data.status === "EMPTY",
            [styles.entityBlockContainerSkipped]: data.status === "SKIPPED"
          })}
          onClick={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTrigger || data.shouldDisableBlockInteraction,
            isCtrlPressed,
            handleDoubleClick,
            handleSingleClick,
            handleMultiSelection
          })}
          onKeyPress={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTrigger || data.shouldDisableBlockInteraction,
            isCtrlPressed,
            handleDoubleClick,
            handleSingleClick,
            handleMultiSelection
          })}>
          <div className={styles.relativeContainer}>
            <div
              className={clsx(styles.blockStatus, {
                [styles.blockStatusActive]: isEntitySelected()
              })}>
              {blockStatus}
            </div>
            <div
              className={clsx(styles.iconContainer, {
                [styles.iconContainerActive]: isEntitySelected()
              })}>
              {blockIcon}
            </div>
            <div
              className={clsx(styles.label, {
                [styles.labelActive]: isEntitySelected()
              })}>
              {addEllipsis(data.label)}
            </div>
          </div>
        </div>
      </Tooltip>
      <Handle type="source" position={Position.Right} />
    </>
  );
};

export default EntityBlockV2;
