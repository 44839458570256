import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";
import { ProjectRunDto, UpdateProjectRunDto } from "@rapidcanvas/rc-api-core";
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";

const useUpdateProjectRun = (): UseMutationResult<
  ProjectRunDto,
  unknown,
  UpdateProjectRunDto,
  unknown
> =>
  useMutation({
    mutationFn: async (payload: UpdateProjectRunDto) => {
      if (!areAllKeysPresentAndNotNil(payload, ["id", "name"])) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.updateProjectRun(payload)
      );
    }
  });

export default useUpdateProjectRun;
