import { AppTemplateDto, GetAppTemplatesSourceEnum } from "@rapidcanvas/rc-api-core";
import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";

const QUERY_KEY_APP_TEMPLATES = "query-key-app-templates";

const useGetAppTemplates = (
  source?: GetAppTemplatesSourceEnum,
  name?: string,
  sample?: string,
  options?: UseQueryOptions<AppTemplateDto[]>
): UseQueryResult<AppTemplateDto[]> => {
  return useQuery<AppTemplateDto[]>({
    queryKey: [QUERY_KEY_APP_TEMPLATES, source, name, sample],
    queryFn: async () => {
      const data = await api.fetchResponse(
        async () => await api.AppTemplateControllerApi.getAppTemplates(source, name, sample)
      );

      return data;
    },
    refetchOnMount: true,
    ...options
  });
};

export default useGetAppTemplates;
