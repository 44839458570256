import Papa from "papaparse";
import { get, find, startsWith, forOwn } from "lodash";
import { handleResponse } from "src/utils/apiService";
import * as xlsx from "xlsx";

interface IOutput {
  path?: string;
  name?: string;
}

const getPredictionJobOutputpathAndName = (lastRunEntry: any): IOutput => {
  const output = find(
    get(lastRunEntry, "outEntityInfo"),
    ({ name }) => startsWith(name, "prediction_output_") || startsWith(name, "OutputDataset_")
  );

  return { name: output?.name, path: output?.path };
};

const getFailedRecipeName = (detailsResponse: any) => {
  let recipeName = "";
  const recipeRunInfo = get(detailsResponse, ["lastRunEntry", "recipeRunInfo"]);
  forOwn(recipeRunInfo, ({ status }, key) => {
    if (status === "FAILURE") {
      recipeName = key;
      return false;
    }
  });

  return recipeName;
};

const xlsxToJson = (file: File): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof File)) {
      handleResponse({ errorMessage: "The input is not a valid File object." });
      reject(new Error("The input is not a valid File object."));
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const result = e.target?.result;
      if (!result) {
        handleResponse({ errorMessage: "Failed to read file." });
        reject(new Error("Failed to read file."));
        return;
      }

      try {
        const data = new Uint8Array(result as ArrayBuffer);
        const workbook = xlsx.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });

        resolve(jsonData);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (e) => {
      handleResponse({ errorMessage: "Error while reading file: " + e.type });
      reject(new Error("Error reading file: " + e.type));
    };

    reader.readAsArrayBuffer(file);
  });
};

const csvtoJson = (uploadedFile: string | File): Promise<string[][]> => {
  return new Promise((resolve, reject) => {
    Papa.parse(uploadedFile, {
      skipEmptyLines: true,
      preview: 500,
      complete: (results: any) => {
        resolve(results.data);
      },
      error: (err: any) => {
        reject(err);
      }
    });
  });
};

const getNumberSuffix = (number: number): string => {
  const suffixes: string[] = ["th", "st", "nd", "rd"];
  const remainder10: number = number % 10;
  const remainder100: number = number % 100;

  if (remainder10 === 1 && remainder100 !== 11) {
    return number + suffixes[1];
  } else if (remainder10 === 2 && remainder100 !== 12) {
    return number + suffixes[2];
  } else if (remainder10 === 3 && remainder100 !== 13) {
    return number + suffixes[3];
  } else {
    return number + suffixes[0];
  }
};

export {
  getPredictionJobOutputpathAndName,
  csvtoJson,
  getNumberSuffix,
  getFailedRecipeName,
  xlsxToJson
};
