// Retaining commented redux logic as a reference for implementing future features.
// Packages
import {
  QueryClient,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from "@tanstack/react-query";
// import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { includes, map } from "lodash";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";

// Open API
import api from "utils/AxiosClient";
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Hooks
import { updateEnvironmentsQueryData, UseGetEnvironmentsQueryKeys } from "./useGetEnvironments";

// Stores
// import { updateEnvironmentStatus } from "src/stores/environmentsSlice";

// Constants
import { EnvironmentsHelperText } from "src/pages/private/EnvironmentsModule/pages/Environments/utils/Environments.constants";
import { EnvironmentActionsStatusEnum } from "src/stores/environmentsSlice";
import { environmentActionStatusSelector } from "src/pages/private/EnvironmentsModule/Environment.selectors";
import { QUERY_KEY_ENV_BY_ID } from "./useGetEnvironmentById";

export const enum UseGetPollEnvironmentQueryKeys {
  PollEnvironments = "pollEnvironments",
  PollEnvironment = "pollEnvironment"
}

type Props = {
  poll?: boolean;
  environments?: EnvDto[];
};

interface IPollEnvironmentProps extends UseQueryOptions<unknown, unknown, any> {
  id?: string | undefined;
  status?: EnvDtoLaunchStatusEnum | undefined;
  isPollEnvEnabled?: boolean;
  notifyOnRelaunchFailed?: boolean;
}

export const updateEnvsQueryDataById = (
  queryClient: QueryClient,
  id: string,
  status: EnvDtoLaunchStatusEnum
) => {
  queryClient.setQueryData([QUERY_KEY_ENV_BY_ID, id], (env?: EnvDto) =>
    env
      ? {
          ...(env || {}),
          launchStatus: status
        }
      : undefined
  );
  queryClient.setQueryData(
    [UseGetEnvironmentsQueryKeys.Environments],
    (prevEnvironments?: EnvDto[]) =>
      map(prevEnvironments, (prevEnvironment: EnvDto) => {
        if (prevEnvironment?.id === id) {
          return {
            ...prevEnvironment,
            launchStatus: status
          };
        }

        return prevEnvironment;
      })
  );
};

const canPollEnvironment = (
  id?: string,
  status?: EnvDtoLaunchStatusEnum,
  statusStore?: EnvironmentActionsStatusEnum
) =>
  !!id && !!status
    ? includes([EnvDtoLaunchStatusEnum.Launching, EnvDtoLaunchStatusEnum.Stopping], status) &&
      statusStore !== EnvironmentActionsStatusEnum.Updating
    : false;

export const usePollEnvironment = (props: IPollEnvironmentProps) => {
  const {
    id,
    status,
    isPollEnvEnabled = true,
    notifyOnRelaunchFailed = false,
    ...options
  } = props || {};

  const queryClient = useQueryClient();
  const environmentStatus = useSelector(environmentActionStatusSelector(id!));

  return useQuery({
    queryKey: [UseGetPollEnvironmentQueryKeys.PollEnvironment, id],
    queryFn: async () => {
      if (!id) {
        throw "Invalid id!";
      }

      return await api.EnvControllerApi.findEnvById(id);
    },
    enabled: !!isPollEnvEnabled && !!canPollEnvironment(id, status, environmentStatus),
    refetchInterval: (data?: any) =>
      includes(
        [EnvDtoLaunchStatusEnum.Launching, EnvDtoLaunchStatusEnum.Stopping],
        data?.data?.[0]?.launchStatus || ""
      )
        ? 2000
        : 0,
    onSuccess: (data?: any) => {
      const currentEnvironment = data?.data?.[0];

      updateEnvironmentsQueryData(queryClient, currentEnvironment);

      !!notifyOnRelaunchFailed &&
        includes([EnvDtoLaunchStatusEnum.Failed], currentEnvironment?.launchStatus) &&
        toastWrapper({
          type: ToastTypes.Error,
          content: EnvironmentsHelperText.EnvironmentLaunchFailed
        });
    },
    ...options
  });
};

export const usePollEnvironments = (props: Props) => {
  const { poll = true, environments } = props || {};

  const queryClient = useQueryClient();

  // const dispatch = useDispatch();

  return useQueries({
    queries: !poll
      ? []
      : map(environments, (environment: EnvDto) => ({
          queryKey: [UseGetPollEnvironmentQueryKeys.PollEnvironments, environment?.id],
          queryFn: async () => {
            if (!environment?.id) {
              throw "Invalid payload!";
            }

            return await api.EnvControllerApi.findEnvById(environment?.id);
          },
          enabled: !!environment?.launchStatus
            ? includes(
                [EnvDtoLaunchStatusEnum.Launching, EnvDtoLaunchStatusEnum.Stopping],
                environment?.launchStatus
              )
            : false,
          refetchInterval: (data?: any) =>
            includes(
              [EnvDtoLaunchStatusEnum.Launching, EnvDtoLaunchStatusEnum.Stopping],
              data?.data?.[0]?.launchStatus || ""
            )
              ? 2000
              : 0,
          onSuccess: (data?: any) => {
            // dispatch(
            //   updateEnvironmentStatus({
            //     id: data?.data?.[0]?.id,
            //     launchStatus: data?.data?.[0]?.launchStatus
            //   })
            // )

            updateEnvironmentsQueryData(queryClient, data?.data?.[0]);
          }
        }))
  });
};

export default usePollEnvironments;
