import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";
import { AI_GUIDE_MESSAGES_KEY } from "./useGetAIGuideMessages";
import { AI_GUIDE_THREADS_KEY } from "./useGetAIGuideThreads";
import { ThreadResponseDto } from "@rapidcanvas/rc-api-core";
import { map } from "lodash";

interface IVariables {
  threadId: string;
}

export const useDeleteAllAIGuideMessages = (
  projectId: string
): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ threadId }) => {
      await api.fetchResponse(() => api.AiControllerApi.deleteMessages(threadId));
      queryClient.setQueryData([AI_GUIDE_MESSAGES_KEY, threadId!], []);
      queryClient.setQueryData(
        ["projects", projectId, AI_GUIDE_THREADS_KEY],
        (prevData: ThreadResponseDto[] | undefined) => {
          if (!prevData) return [];

          return map(prevData, (thread) =>
            thread.threadId === threadId ? { ...thread, messagesCount: 0 } : thread
          );
        }
      );
    }
  });
};
