import React from "react";

// Packages
import { isNil, isEmpty, toLower, map, indexOf } from "lodash";

// Open API
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Utils
import { snakeCaseToStartCase } from "src/utils/helpers";

interface IProps {
  environmentType?: string;
  cores: number;
  memInGbs: number;
  diskInGbs: number;
}

export const EnvironmentTypeConfig: React.FC<IProps> = (props) => {
  const { environmentType, cores, memInGbs, diskInGbs } = props;

  return (
    <>
      {!!environmentType
        ? `${snakeCaseToStartCase(environmentType, { toUpperCase: ["cpu"] })} - `
        : ""}
      {`Core: ${cores} | Memory: ${memInGbs} GB | Disk Space: ${diskInGbs} GB`}
    </>
  );
};

export const sortEnvironmentsByDateAndStatus = (environments?: EnvDto[]) => {
  if (!environments) return environments;

  // Sorting by latest date, handling null or undefined values
  const sortByDate = (a: EnvDto, b: EnvDto) => {
    if (isNil(a.updated)) return 1;
    if (isNil(b.updated)) return -1;
    return b.updated - a.updated;
  };

  // Creating status order map in lowercase for comparison
  const statusOrder = map(
    [
      EnvDtoLaunchStatusEnum.Launching,
      EnvDtoLaunchStatusEnum.Stopping,
      EnvDtoLaunchStatusEnum.Success,
      EnvDtoLaunchStatusEnum.Shutdown,
      EnvDtoLaunchStatusEnum.Failed
    ],
    toLower
  );

  // Sorting by status order, handling missing or empty launch statuses
  const sortByStatus = (a: EnvDto, b: EnvDto) => {
    if (isEmpty(a.launchStatus)) return 1;
    if (isEmpty(b.launchStatus)) return -1;
    return (
      indexOf(statusOrder, toLower(a.launchStatus)) - indexOf(statusOrder, toLower(b.launchStatus))
    );
  };

  return environments.slice().sort(sortByDate).sort(sortByStatus);
};
