import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

// Open API
import api from "utils/AxiosClient";
import { DeploymentPropertyDto } from "@rapidcanvas/rc-api-core";

const enum UseGetReleaseNotesQueryKeys {
  ReleaseNotes = "release-notes"
}

interface IReleaseNotesProps extends UseQueryOptions<unknown, unknown, DeploymentPropertyDto[]> {}

const useGetReleaseNotes = (
  props?: IReleaseNotesProps
): UseQueryResult<DeploymentPropertyDto[]> => {
  const { ...options } = props || {};

  return useQuery({
    queryKey: [UseGetReleaseNotesQueryKeys.ReleaseNotes],
    queryFn: async () =>
      await api.fetchResponse(async () => await api.DeploymentPropertyControllerApi.all1()),
    ...options
  });
};

export default useGetReleaseNotes;
