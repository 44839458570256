import React, { useCallback, useMemo, useState } from "react";

// Packages
import clsx from "clsx";
import { capitalize, get, includes, isEmpty } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

// Utils
import { getEnvironmentUsageDetails } from "../../utils/Environments.helpers";

// Open API
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Hooks
import useStatus from "../../hooks/useStatus";

// Components
import StopEnvironment from "../StopEnvironment";

// Constants
import { EnvironmentStatusValuesMapping } from "src/pages/private/EnvironmentsModule/utils/Environments.constants";

// Styles
import useStyles from "./EnvironmentsTable.styles";

interface IProps {
  environment: EnvDto;
}

const Status: React.FC<IProps> = (props) => {
  const { environment } = props;
  const { id, launchStatus: status } = environment;

  const classes: any = useStyles();

  const [showConfirmStopEnvModal, setShowConfirmStopEnvModal] = useState<any>(null);
  const [isFetchingUsage, setIsFetchingUsage] = useState(false);

  const { onRelaunch, onStop, isRelaunching, isStopping } = useStatus({ environment });

  const { statusClass, statusText, showProgress } = useMemo(() => {
    let currentStatus;
    if (!!isRelaunching) currentStatus = EnvDtoLaunchStatusEnum.Launching;
    else if (!!isStopping || !!isFetchingUsage) currentStatus = EnvDtoLaunchStatusEnum.Stopping;
    else currentStatus = status;

    if (!currentStatus)
      return {
        statusClass: "",
        statusText: "",
        showProgress: false
      };

    const statusClass =
      classes[get(EnvironmentStatusValuesMapping, [currentStatus, "cssClassName"])] || "";

    const statusText = get(
      EnvironmentStatusValuesMapping,
      [currentStatus, "displayName"],
      capitalize(currentStatus) || "Unknown"
    );

    const showProgress = includes(
      [EnvDtoLaunchStatusEnum.Launching, EnvDtoLaunchStatusEnum.Stopping],
      currentStatus
    );

    return {
      statusClass,
      statusText,
      showProgress
    };
  }, [status, isRelaunching, isStopping, isFetchingUsage]);

  // Stop environment - STARTS >>
  const promptConfirmStopEnvironment = useCallback(async () => {
    if (!id || !status) return;

    setIsFetchingUsage(() => true);
    const types = await getEnvironmentUsageDetails(id);
    setIsFetchingUsage(() => false);

    if (isEmpty(types)) {
      onStop(id);
    } else {
      setShowConfirmStopEnvModal(() => ({ types, action: "stopping" }));
    }
  }, [id, status]);

  const resetStopEnvironment = useCallback(() => setShowConfirmStopEnvModal(() => null), []);

  const confirmStopEnvironment = useCallback(() => {
    resetStopEnvironment();
    onStop(id);
  }, [id]);
  // << ENDS - Stop environment

  const onClick = () => {
    status === EnvDtoLaunchStatusEnum.Success ? promptConfirmStopEnvironment() : onRelaunch(id);
  };

  return (
    <>
      {!!showConfirmStopEnvModal && (
        <StopEnvironment
          data={showConfirmStopEnvModal}
          confirmStopEnvironment={confirmStopEnvironment}
          resetStopEnvironment={resetStopEnvironment}
        />
      )}
      <Grid
        container
        className={clsx(classes.statusContainer, statusClass)}
        alignItems="center"
        justifyContent="space-between"
        spacing={1}>
        <Grid item>
          <Box fontWeight="bold" data-testid="envListStatusText">
            {statusText}
          </Box>
        </Grid>
        <Grid item>
          {showProgress ? (
            <CircularProgress size={12} style={{ marginRight: 4 }} />
          ) : (
            !!status &&
            !!get(EnvironmentStatusValuesMapping, [status, "icon"]) && (
              <Tooltip
                title={get(EnvironmentStatusValuesMapping, [status, "buttonText"], "")}
                data-testid="envListStatusTooltip">
                <IconButton
                  size="small"
                  style={{ padding: 0 }}
                  onClick={onClick}
                  data-testid="envListStatusAction">
                  {get(EnvironmentStatusValuesMapping, [status, "icon"])}
                </IconButton>
              </Tooltip>
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Status;
