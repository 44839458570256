import { get } from "lodash";

import Analytics from "../utils/analytics";
import env from "../utils/environment";

import { TRACKING_INFO } from "../constants/events";

export const initAnalytics = () => {
  const trackingId = get(TRACKING_INFO, env.getEnvironment());
  if (trackingId) {
    Analytics.initialize(atob(trackingId));
  }
};
