import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { includes } from "lodash";

import Grid from "@material-ui/core/Grid";

import { toastWrapper } from "src/utils/toastWrapper";

import { getJobRunHistoryDetails } from "src/api/projects";
import {
  useGetJob,
  useGetJobRuns,
  useManualJobRun,
  useDeleteJobRun,
  getEnsuredDataConnectorsData
} from "src/hooks/api";

import { useDataSourcesStore, useJobsStore } from "src/store/store";
import {
  connectorsGetter,
  connectorsSetter,
  shouldRefreshJobsGetter,
  shouldRefreshJobsToggler
} from "src/store/store.selectors";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";
import { JobRunsTable, JobRunOutputModal, JobGlobalVariables } from "..";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";

import {
  JobsHelperText,
  JobRunStatuses,
  JobRunDeletePromptDetails,
  JobsStatuses,
  JobRunTriggerTypes
} from "../../utils/Jobs.constants";
import CommonLoader from "src/components/CommonLoader";

const JobRuns = () => {
  const { projectId, jobId }: $TSFixMe = useParams() || {};

  const queryClient = useQueryClient();

  // Project context
  const { project } = useProjectContext() || {};

  const interval = 2000;

  const timeoutRef = useRef(0);
  const watchingJobRunsRef = useRef<$TSFixMe>([]);
  const jobRunsInterval = useRef<$TSFixMe>(null);

  // @TODO: Using below timer as watchingJobRunsRef.current in a dependency array is not called upon changing.
  // Fixing the above issue, can this timer be removed.
  const [watchingJobRunsTimer, setWatchingJobRunsTimer] = useState<$TSFixMe>(Date.now());

  // Stores - STARTS >>
  const connectorsStore = useDataSourcesStore(connectorsGetter);
  const setConnectorsStore = useDataSourcesStore(connectorsSetter);

  const toggleShouldJobsRefresh = useJobsStore(shouldRefreshJobsToggler);
  // << ENDS - Stores

  useEffect(() => {
    const _ = async () => {
      const dataConnectorsData = await getEnsuredDataConnectorsData({ queryClient });

      if ((dataConnectorsData || [])?.length > 0) {
        setConnectorsStore([...dataConnectorsData]);
      }
    };

    _();
  }, []);

  // States - STARTS >>
  const [jobRuns, setJobRuns] = useState<$TSFixMe>([]);

  const [deletingJobRunId, setDeletingJobRunId] = useState<$TSFixMe>("");
  const [showConfirmScreen, setShowConfirmScreen] = useState<$TSFixMe>(false);

  const [isJobOutputModalOpen, setIsJobOutputModalOpen] = useState<$TSFixMe>(false);
  const [lastJobRun, setLastJobRun] = useState<$TSFixMe>({});

  const [isJobRunParametersOpen, setIsJobRunParametersOpen] = useState<boolean>(false);
  const [jobRunParametersData, setJobRunParametersData] = useState<$TSFixMe>([]);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isReRunning,
    mutateAsync: manualJobRunMutation,
    reset: resetManualJobRunMutation
  } = useManualJobRun();

  const {
    isLoading: isDeleting,
    mutateAsync: deleteJobRunMutation,
    reset: resetDeleteJobRunMutation
  } = useDeleteJobRun();

  // Queries
  const { data: jobData } = useGetJob({ jobId });
  const { isFetching, data: jobRunsQueryData, refetch: refetchJobRuns } = useGetJobRuns({ jobId });

  useEffect(() => {
    setJobRuns(() => jobRunsQueryData);
  }, [jobRunsQueryData]);
  // << ENDS - Query hooks

  useEffect(() => {
    jobId && !!shouldRefreshJobsGetter && refetchJobRuns();
  }, [jobId, shouldRefreshJobsGetter]);

  // Polling job runs - STARTS >>
  const resetWatchingJobRuns = () => {
    jobRunsInterval.current !== null && clearTimeout(jobRunsInterval.current);
    watchingJobRunsRef.current = [];
    setWatchingJobRunsTimer(() => Date.now());
  };

  const isRunning = (status: $TSFixMe) =>
    ![
      JobRunStatuses.Success,
      JobRunStatuses.SuccessWithWarn,
      JobRunStatuses.Failure,
      JobRunStatuses.TimedOut,
      JobRunStatuses.RecipeTimedOut
    ].includes(status);

  const getFilteredWatchingJobRuns = (thisJobRuns: $TSFixMe) =>
    thisJobRuns?.filter((eachJobRun: $TSFixMe) => {
      if (eachJobRun?.trigger === JobRunTriggerTypes.Scheduler) {
        return includes([JobsStatuses.Active], jobData?.status) && isRunning(eachJobRun?.status);
      }

      return isRunning(eachJobRun?.status);
    });

  const watchJobRuns = async () => {
    Promise.all(
      (watchingJobRunsRef.current || []).map(
        async (eachJobRun: $TSFixMe) => await getJobRunHistoryDetails(eachJobRun?.id, false)
      )
    )
      .then(async (thisJobRuns) => {
        if (thisJobRuns?.length > 0) {
          // Checking if status of any job-run is changed compared to previous status of respective job-runs.
          const isStatusChanged = thisJobRuns?.reduce((acc, eachThisJobRun) => {
            const previousJobRun = jobRuns.find(
              (eachJobRun: $TSFixMe) => eachJobRun?.id === eachThisJobRun?.entryDto?.id
            );
            return acc || previousJobRun?.status !== eachThisJobRun?.entryDto?.status;
          }, false);

          if (isStatusChanged) {
            const thisJobRunsMap: $TSFixMe = {};
            const runningJobRunIds: $TSFixMe = [];

            thisJobRuns?.forEach((eachThisJobRun: $TSFixMe) => {
              thisJobRunsMap[eachThisJobRun?.entryDto?.id] = eachThisJobRun?.entryDto;

              if (isRunning(eachThisJobRun?.entryDto?.status)) {
                runningJobRunIds.push(eachThisJobRun?.entryDto?.id);
              }
            });

            const thisJobRunIds = Object.keys(thisJobRunsMap) || [];

            // jobRuns have more details of job-runs than the ones in current API's response.
            // Hence, by mapping the current APIs' IDs to jobRuns IDs,
            // getting all the details of this current API's job-runs from jobRuns-data.
            const thisJobRunDetails = jobRuns
              ?.filter((eachJobRun: $TSFixMe) => thisJobRunIds?.includes(eachJobRun?.id))
              ?.map((eachJobRun: $TSFixMe) => ({
                ...eachJobRun,
                ...(thisJobRunsMap[eachJobRun?.id] || {})
              }));

            // Filtering job-runs out to get polling-completed job-runs.
            const filteredJobRuns = jobRuns?.filter(
              (eachJobRun: $TSFixMe) => !thisJobRunIds?.includes(eachJobRun?.id)
            );

            // Merging the job-runs.
            setJobRuns(() => [...thisJobRunDetails, ...filteredJobRuns]);

            watchingJobRunsRef.current = jobRuns?.filter((eachJobRun: $TSFixMe) =>
              runningJobRunIds?.includes(eachJobRun?.id)
            );

            if (watchingJobRunsRef.current?.length === 0) {
              resetWatchingJobRuns();
            } else {
              setWatchingJobRunsTimer(() => Date.now());
            }
          }
        }
      })
      .catch((error: $TSFixMe) => {
        console.error(error);

        refetchJobRuns();
        toggleShouldJobsRefresh();
      });
  };

  useEffect(() => {
    if (jobRunsInterval.current !== null) {
      clearTimeout(jobRunsInterval.current);
    }

    const _ = () => {
      // Call getJobRun every n seconds that is defined for ${interval} above.
      jobRunsInterval.current = setTimeout(async () => {
        const beforeTimeInMillis = Date.now();
        await watchJobRuns();
        const afterTimeInMillis = Date.now();

        if ((watchingJobRunsRef.current || [])?.length > 0) {
          const timeoutLeft = interval - (afterTimeInMillis - beforeTimeInMillis);
          timeoutRef.current = timeoutLeft <= 0 ? 0 : timeoutLeft;

          _();
        } else {
          resetWatchingJobRuns();
        }
      }, timeoutRef.current);
    };

    (watchingJobRunsRef.current || [])?.length > 0 && _();
  }, [watchingJobRunsRef.current, watchingJobRunsTimer]);

  useEffect(() => {
    if ((jobRuns || [])?.length > 0) {
      const filteredWatchingJobRuns = getFilteredWatchingJobRuns(jobRuns) || [];

      if (filteredWatchingJobRuns?.length === 0) {
        resetWatchingJobRuns();
      } else {
        watchingJobRunsRef.current = filteredWatchingJobRuns;
        setWatchingJobRunsTimer(() => Date.now());
      }
    }
  }, [jobRuns]);

  useEffect(() => () => resetWatchingJobRuns(), []);
  // << ENDS - Polling job runs

  const onReRunJobRunSucceed = (data: $TSFixMe, jobRun: $TSFixMe) => {
    let toastDetails: $TSFixMe = {
      message: JobsHelperText.ManualJobRunStartedMessage,
      type: "success"
    };

    let runJobResponseStatus: $TSFixMe = "";
    if (Object.keys(data || {})?.length > 0) {
      runJobResponseStatus = data?.status;
      if (runJobResponseStatus === "FAILURE") {
        toastDetails = {
          message: data?.error || "Scheduler run failed!",
          type: "error"
        };
      }
    }

    toastWrapper({ type: toastDetails?.type, content: toastDetails?.message });

    if (runJobResponseStatus !== "FAILURE") {
      // Resetting polling >>
      resetWatchingJobRuns();

      // Each manual run generates new id, and also status changes.
      // Hence using the common runId in job-runs data updating id & status in it.
      const thisJobRunDetails = jobRuns
        ?.filter((eachJobRun: $TSFixMe) => jobRun?.runId === eachJobRun?.runId)
        ?.map((eachJobRun: $TSFixMe) => ({
          ...eachJobRun,
          id: data?.id,
          status: data?.status,
          trigger: data?.trigger,
          created: data?.created,
          creator: data?.creator,
          updated: data?.updated,
          updater: data?.updater,
          endTime: data?.endTime
        }));

      // Slicing the current job-run so that to push it to top of the list.
      const filteredJobRuns = jobRuns?.filter(
        (eachJobRun: $TSFixMe) => jobRun?.runId !== eachJobRun?.runId
      );

      // Pushing it to top of the list. Merging the job-runs.
      setJobRuns(() => [...thisJobRunDetails, ...filteredJobRuns]);

      // Filtering current running job-runs.
      const runningJobRuns = filteredJobRuns?.filter((eachJobRun: $TSFixMe) =>
        isRunning(eachJobRun?.status)
      );

      // @ts-ignore
      watchingJobRunsRef.current = [...thisJobRunDetails, ...runningJobRuns];
      setWatchingJobRunsTimer(() => Date.now());
      // << Resetting polling
    }
  };

  const reRunJobRun = async (jobRun: $TSFixMe) => {
    if (!jobId || !jobRun?.runId) {
      return;
    }

    resetManualJobRunMutation();

    const payload = {
      jobId,
      runId: jobRun?.runId,
      deleteOld: true,
      useOldMetadata: true
      // variables: jobRun?.computedVariables || {}
    };

    await manualJobRunMutation(payload, {
      onSuccess: (data: $TSFixMe) => {
        onReRunJobRunSucceed(data, jobRun);
      }
    });
  };

  // Delete job run - STARTS >>
  const onDeleteJobRunSucceed = () => {
    toastWrapper({
      type: "success",
      content: "Scheduler run entry deleted successfully!"
    });

    // Resetting polling >>
    resetWatchingJobRuns();

    // Filtering this deleted job-run out of job-runs.
    const filteredJobRuns = jobRuns?.filter(
      (eachJobRun: $TSFixMe) => deletingJobRunId !== eachJobRun?.id
    );

    // Resetting filtered job-runs.
    setJobRuns(() => [...filteredJobRuns]);

    // Filtering current running job-runs.
    const runningJobRuns = filteredJobRuns?.filter((eachJobRun: $TSFixMe) =>
      isRunning(eachJobRun?.status)
    );

    // @ts-ignore
    watchingJobRunsRef.current = [...runningJobRuns];
    setWatchingJobRunsTimer(() => Date.now());
    // << Resetting polling
  };

  const deleteJobRun = async () => {
    if (!deletingJobRunId) {
      setShowConfirmScreen(() => false);
      return;
    }

    resetDeleteJobRunMutation();

    await deleteJobRunMutation(
      { jobRunId: deletingJobRunId },
      {
        onSuccess: onDeleteJobRunSucceed,
        onSettled: () => {
          setDeletingJobRunId(() => "");
          setShowConfirmScreen(() => false);
        }
      }
    );
  };

  const promptConfirmDeleteJobRun = (jobRunId: string) => {
    setDeletingJobRunId(() => jobRunId);
    setShowConfirmScreen(() => true);
  };

  const cancelDeleteJobRun = () => {
    setShowConfirmScreen(() => false);
  };

  const confirmDeleteJobRun = () => {
    deleteJobRun();
  };
  // << ENDS - Delete job run

  const onViewOutputOpen = (runData: $TSFixMe) => {
    setIsJobOutputModalOpen(true);
    setLastJobRun(runData);
  };

  const onViewOutputClose = () => {
    setIsJobOutputModalOpen(false);
    setLastJobRun({});
  };

  const onJobRunParametersViewOpen = (runData: $TSFixMe) => {
    setIsJobRunParametersOpen(() => true);
    setJobRunParametersData(
      () =>
        Object.entries(runData?.computedVariables || {})?.map(([key, value]: $TSFixMe) => ({
          ["key"]: key,
          ["value"]: value
        })) || []
    );
  };

  const onJobRunParametersViewClose = () => {
    setIsJobRunParametersOpen(() => false);
    setJobRunParametersData(() => ({}));
  };

  return (
    <>
      {showConfirmScreen && (
        <Modal
          open={showConfirmScreen}
          variant={ModalVariants.Delete}
          title="Delete Scheduler Run Entry"
          content={[JobRunDeletePromptDetails.messageLine1, JobRunDeletePromptDetails.messageLine2]}
          onClose={cancelDeleteJobRun}
          onSubmit={confirmDeleteJobRun}
          isCancelDisabled={isDeleting}
          isSubmitDisabled={isDeleting}
          isSubmitting={isDeleting}
        />
      )}

      {isJobOutputModalOpen && (
        <JobRunOutputModal
          connectorsStore={connectorsStore}
          jobData={jobData}
          lastRunData={lastJobRun}
          onViewOutputClose={onViewOutputClose}
        />
      )}

      {isJobRunParametersOpen && (
        <JobGlobalVariables
          close={onJobRunParametersViewClose}
          jobParametersData={jobRunParametersData}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: <SubTopNavBarBreadcrumbs project={project} jobData={jobData} />
        }}
      />

      <Grid container style={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          style={{
            padding: 16,
            height: "100%"
          }}>
          {isFetching ? (
            <CommonLoader />
          ) : (jobRuns || [])?.length === 0 ? (
            <>No run history found!</>
          ) : (
            <JobRunsTable
              projectId={projectId}
              jobId={jobId}
              data={jobRuns}
              isRunning={isRunning}
              isReRunning={isReRunning}
              isDeleting={isDeleting}
              reRunJobRun={reRunJobRun}
              onDeleteJobRun={promptConfirmDeleteJobRun}
              onViewOutputOpen={onViewOutputOpen}
              onJobRunParametersViewOpen={onJobRunParametersViewOpen}
              hideJobRunCanvas={false}
              hideJobRunParameters={false}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default JobRuns;
