import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { EnvDto } from "@rapidcanvas/rc-api-core";
import api from "src/utils/AxiosClient";
import { updateEnvironmentsQueryData } from "./useGetEnvironments";

const QUERY_KEY_ENV_BY_ID = "query-env-by-id";

export const useGetEnvironmentById = (
  envId?: string,
  options?: UseQueryOptions<EnvDto>
): UseQueryResult<EnvDto> => {
  const queryClient = useQueryClient();
  return useQuery<EnvDto>({
    queryKey: [QUERY_KEY_ENV_BY_ID, envId],
    queryFn: async () => {
      const response = await api.fetchResponse(
        async () => await api.EnvControllerApi.findEnvById(envId!)
      );

      const environment = response?.[0];
      updateEnvironmentsQueryData(queryClient, environment);
      return response?.[0];
    },
    ...options
  });
};

export default useGetEnvironmentById;
export { QUERY_KEY_ENV_BY_ID };
