import { useMutation } from "@tanstack/react-query";
import { CreateChatbotPropertyDto, UpdateChatbotPropertyDto } from "@rapidcanvas/rc-api-core";

import { postAPIWithRethrow, putAPIWithRethrow } from "src/utils/apiService";

export const useSaveChatbotPropertiesMutation = () => {
  return useMutation({
    mutationFn: ({
      id,
      request,
      isNewRequest
    }: {
      id: string;
      request: UpdateChatbotPropertyDto | CreateChatbotPropertyDto;
      isNewRequest: boolean;
    }) => {
      return isNewRequest
        ? postAPIWithRethrow(`/chatbot-property`, request)
        : putAPIWithRethrow(`/chatbot-property/${id}`, request);
    }
  });
};
