import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import TenantEditForm from "./TenantEditForm";

import shallow from "zustand/shallow";
import TenantStats from "./TenantStats";

import BasicTenantDetails from "./BasicTenantDetails";
import UserTokenV2 from "src/components/UserToken/UserTokenV2";
import useAuthStore from "src/stores/auth.store";

const useStyles = makeStyles({
  flexGap: {
    gap: "20px"
  },
  cardWrap: {
    padding: "16px",
    background: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0px 2px 2px rgba(152, 152, 152, 0.25)"
  },
  flexWidth: {
    flex: 1
  },
  tokenWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    gap: "21px"
  },
  subHeading: {
    fontSize: "14px",
    color: "#000000",
    opacity: 0.5
  }
});

const TenantDetails = ({
  name,
  description,
  userCount,
  projectCount
}: {
  name: string;
  description: string;
  userCount: number;
  projectCount: number | undefined;
}) => {
  const classes = useStyles();

  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [userAdmin] = useAuthStore((state) => [state.hasPrivileges], shallow);
  const isEditing = useMemo(() => isEditModeOn && userAdmin, [isEditModeOn, userAdmin]);

  const handleOpenEditMode = useCallback(() => {
    setIsEditModeOn(true);
  }, []);

  const handleCloseEditMode = useCallback(() => {
    setIsEditModeOn(false);
  }, []);

  return (
    <Grid container direction="row" alignItems="flex-start" className={classes.flexGap}>
      <Grid item className={clsx(classes.cardWrap, classes.flexWidth)}>
        {isEditing ? (
          <TenantEditForm name={name} description={description} onClose={handleCloseEditMode} />
        ) : (
          <BasicTenantDetails
            name={name}
            description={description}
            onEdit={handleOpenEditMode}
            canEdit={!!userAdmin}
          />
        )}
      </Grid>
      {projectCount !== undefined && (
        <Grid item xs={3} className={classes.cardWrap}>
          <TenantStats projectCount={projectCount} userCount={userCount} />
        </Grid>
      )}
      <Grid item xs={3} className={classes.cardWrap}>
        <Grid className={classes.tokenWrap}>
          <Typography className={classes.subHeading}>Workspace Auth Token</Typography>
          <UserTokenV2 />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TenantDetails;
