import React from "react";

// Packages
import { isNil, isEmpty, toLower, map, indexOf } from "lodash";

// Open API
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Utils
import { snakeCaseToStartCase } from "src/utils/helpers";

import { getEnvironmentUsage } from "src/api/environments";

export type ITypes = ("running job(s)" | "prediction job(s)" | "active recipe(s)")[];

interface EnvUsage {
  pdCreated: number;
  pdUpdated: number;
  projectCreated: number;
  projectCreator: string;
  projectId: string;
  projectName: string;
  projectRunList: any[];
  projectUpdated: number;
  projectUpdater: string;
  recipeCreated: number;
  recipeUpdated: number;
  runningJobCount: number;
  runningPredictionJobCount: number;
  runningRecipeCount: number;
  type: string;
}
interface IProps {
  environmentType?: string;
  cores: number;
  memInGbs: number;
  diskInGbs: number;
}

export const EnvironmentTypeConfig: React.FC<IProps> = (props) => {
  const { environmentType, cores, memInGbs, diskInGbs } = props;

  return (
    <>
      {!!environmentType
        ? `${snakeCaseToStartCase(environmentType, { toUpperCase: ["cpu"] })} - `
        : ""}
      {`Core: ${cores} | Memory: ${memInGbs} GB | Disk Space: ${diskInGbs} GB`}
    </>
  );
};

export const sortEnvironmentsByDateAndStatus = (environments?: EnvDto[]) => {
  if (!environments) return environments;

  // Sorting by latest date, handling null or undefined values
  const sortByDate = (a: EnvDto, b: EnvDto) => {
    if (isNil(a.updated)) return 1;
    if (isNil(b.updated)) return -1;
    return b.updated - a.updated;
  };

  // Creating status order map in lowercase for comparison
  const statusOrder = map(
    [
      EnvDtoLaunchStatusEnum.Launching,
      EnvDtoLaunchStatusEnum.Stopping,
      EnvDtoLaunchStatusEnum.Success,
      EnvDtoLaunchStatusEnum.Shutdown,
      EnvDtoLaunchStatusEnum.Failed
    ],
    toLower
  );

  // Sorting by status order, handling missing or empty launch statuses
  const sortByStatus = (a: EnvDto, b: EnvDto) => {
    if (isEmpty(a.launchStatus)) return 1;
    if (isEmpty(b.launchStatus)) return -1;
    return (
      indexOf(statusOrder, toLower(a.launchStatus)) - indexOf(statusOrder, toLower(b.launchStatus))
    );
  };

  return environments.slice().sort(sortByDate).sort(sortByStatus);
};

export const getEnvironmentUsageDetails = async (id?: string): Promise<ITypes> => {
  if (!id) {
    return [];
  }

  const usageResponse: EnvUsage[] = await getEnvironmentUsage(id);
  return extractEnvUsageTypes(usageResponse);
};

export const extractEnvUsageTypes = (usageResponse: EnvUsage[]) => {
  const projectType = (usageResponse || [])?.filter(
    (eachEntry: EnvUsage) => eachEntry?.type === "PROJECT"
  );
  const recipeType = (usageResponse || [])?.filter(
    (eachEntry: EnvUsage) => eachEntry?.type === "RECIPE"
  );

  const areRunningJobs = projectType?.some((eachEntry: EnvUsage) => eachEntry?.runningJobCount > 0);
  const areRunningPredictionJobs = projectType?.some(
    (eachEntry: EnvUsage) => eachEntry?.runningPredictionJobCount > 0
  );
  const areRunningRecipes = recipeType?.some(
    (eachEntry: EnvUsage) => eachEntry?.runningRecipeCount > 0
  );

  const types: ITypes = [];

  if (areRunningJobs) {
    types.push("running job(s)");
  }
  if (areRunningPredictionJobs) {
    types.push("prediction job(s)");
  }
  if (areRunningRecipes) {
    types.push("active recipe(s)");
  }
  return types;
};
