import React, { useMemo, useState } from "react";

// Packages
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";

// Icons
import { EyeIcon } from "src/icons/NewUX";
import PredictionServiceIcon from "src/icons/NewUX/PredictionServiceIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { WebPaths } from "src/routing/routes";
import { TemplateRecipeIcon } from "src/icons/NewUX/TemplateRecipeIcon";
import ApiConnectorIcon from "src/icons/NewUX/ApiConnectorIcon";
import { AiAssistedIcon } from "src/icons/NewUX/AiAssistedRecipeIcon";

// Components
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import usePredictionServiceByModel from "src/hooks/usePredictionServiceByModel";
import { TABS } from "pages/Library/ArtifactsAndModels/Models/ModelDetails";
import AIGuideMiniButton from "src/components/Buttons/AIGuideMiniButton";

// Constants
import { newTheme as theme } from "src/styles";
import { RecipeTypeNames } from "src/pages/private/ProjectsModule/utils";

// Types
import { NodeData } from "src/types";
import { useContextStyles } from "../useContextMenuStyles";

type Props = {
  open?: boolean;
  closeContextMenu: () => void;
  isDefaultScenario: boolean;
  data: NodeData;
  children?: React.ReactNode;
  openAIGuideDialog: () => void;
};

const ContextMenu = (props: Props) => {
  const { closeContextMenu, isDefaultScenario, data, children, openAIGuideDialog, open } =
    props || {};

  const location = useLocation();
  const navigate = useNavigate();

  const classes = useContextStyles();

  const isBuilt = useMemo(() => ["BUILT"].includes(data?.status), [data?.status]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { isLoading, data: predictionService } = usePredictionServiceByModel(
    data?.name,
    data?.scenarioId,
    data?.jobProps?.jobRunId,
    { enabled: !!open && !!data.name }
  );

  const onView = () => {
    if (!data?.name) {
      return;
    }

    let path = generatePath(WebPaths.ModelDetails, {
      modelName: data?.name
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobModels}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        modelName: data?.name
      });
    }

    !!path && navigate({ pathname: path }, { state: { prevPath: location.pathname } });
  };

  const addAiAssistedRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-code-recipe?model=${data?.id}`
    );
  };

  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?model=${data?.id}`;
    navigate(
      generatePath(url, {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const handleAddTemplateRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-recipe?model=${data?.id}`
    );
  };

  const onPredictionServiceView = () => {
    if (!data?.name) {
      return;
    }

    navigate(
      generatePath(WebPaths.ModelDetails, {
        modelName: data?.name
      }),
      {
        state: {
          prevPath: location.pathname,
          tab: TABS.predictionService.value,
          predictionServiceDetails: predictionService?.predictionServiceDetails
        }
      }
    );
  };

  const navigateToAIGuide = () => {
    openAIGuideDialog();
    closeContextMenu();
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    items.push(
      <Button
        key="view"
        className="context-menu-border"
        data-testid="Preview"
        startIcon={<EyeIcon viewBox="0 0 20 20" />}
        size="small"
        onClick={onView}>
        Preview
      </Button>
    );

    if (!data?.isJobCanvas && !!isDefaultScenario) {
      items.push(
        <Button
          key="ps"
          size="small"
          disabled={isLoading}
          data-testid="Prediction Service"
          className="context-menu-border"
          startIcon={
            isLoading ? <CircularProgress size={theme.spacing(1.75)} /> : <PredictionServiceIcon />
          }
          onClick={onPredictionServiceView}>
          Prediction Service
        </Button>
      );

      if (!data?.isJobCanvas && !!isDefaultScenario && isBuilt) {
        items.push(
          <AIGuideMiniButton
            key="ai-guide"
            width={16}
            className="context-menu-border"
            height={12}
            viewBox="4 -2 48 39"
            badgeStyleProps={{
              marginTop: -2,
              marginRight: 4,
              marginLeft: 4
            }}
            onClick={navigateToAIGuide}
            targetId={data?.itemId!}
            projectId={data?.projectId!}
          />
        );
      }

      if (isBuilt && isDefaultScenario) {
        items.push(
          <Button
            key={RecipeTypeNames.AiAssisted}
            size="small"
            data-testid={`${RecipeTypeNames.AiAssisted} Recipe`}
            startIcon={<AiAssistedIcon viewBox="0 1 20 20" />}
            onClick={addAiAssistedRecipe}>
            {RecipeTypeNames.AiAssisted} Recipe
          </Button>
        );

        items.push(
          <Button
            key={RecipeTypeNames.Template}
            size="small"
            data-testid={`${RecipeTypeNames.Template} Recipe`}
            startIcon={<TemplateRecipeIcon width={16} height={16} viewBox="0 0 20 20" />}
            onClick={handleAddTemplateRecipe}>
            {RecipeTypeNames.Template} Recipe
          </Button>
        );

        items.push(
          <Button
            key={RecipeTypeNames.ApiConnector}
            size="small"
            data-testid={`${RecipeTypeNames.ApiConnector} Recipe`}
            className="context-menu-border"
            startIcon={<ApiConnectorIcon />}
            onClick={handleAddApiConnectorRecipe}>
            {RecipeTypeNames.ApiConnector} Recipe
          </Button>
        );
      }

      items.push(
        <Button
          size="small"
          key="delete"
          data-testid="Delete"
          startIcon={<TrashIcon viewBox="0 0 20 22" />}
          onClick={onDelete}>
          Delete
        </Button>
      );
    }

    return items;
  }, [data?.isJobCanvas, data?.isJobCanvasPath, isDefaultScenario, isLoading]);

  return (
    <>
      {!!isDeleteModalOpen && (
        <DeleteNodeModal
          open
          nodeId={data?.id}
          nodeName={data?.label}
          nodeType="model"
          deleteNote={
            <div>
              <span>Note: Deleting this might impact associated DataApps (if any). </span>
              {psMessage}
            </div>
          }
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onAfterSubmit={closeContextMenu}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" orientation="vertical" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}
    </>
  );
};

export default ContextMenu;
