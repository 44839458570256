import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React from "react";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { head } from "lodash";

import FileInputField from "src/pages/private/ProjectsModule/pages/Dataset/components/LocalFilesSelection/FileInputField";
import { CloudUploadIcon } from "src/icons/Dataset/CloudUploadIcon";
import { handleResponse } from "utils/apiService";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    background: "#F9FDFF",
    border: "1px dashed #415F80",
    height: "100%",
    marginBottom: 0,
    borderRadius: 4,
    "& .MuiTypography-root": {
      fontWeight: "lighter"
    },
    "& .dropFieldContainer": {
      margin: "auto",
      alignItems: "center",
      width: "auto",
      rowGap: 10,
      "& svg": {
        margin: "0 auto"
      }
    },
    "& .dropInfoContainer": {
      position: "absolute",
      bottom: 0,
      textAlign: "center",
      "& svg": {
        opacity: 0.5
      },
      "& .MuiTypography-root": {
        fontStyle: "italic",
        opacity: 0.5
      }
    }
  }
}));

interface IProps {
  onUpload: (file: File) => void;
}

const FILE_SIZE_IN_MB = 50;
const MAX_FILE_SIZE = FILE_SIZE_IN_MB * 1024 * 1024;

const UploadFileContainer: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = head(e.target.files);

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        handleResponse({
          errorMessage: `File size exceeds the maximum limit of ${FILE_SIZE_IN_MB} MB.`
        });
        return;
      }
      props.onUpload(file);
      e.target.value = "";
    }
  };

  return (
    <FileInputField accept="text/csv" onChange={handleChange}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.root}
        component="label"
        htmlFor="fileInput">
        <Grid container direction="column" className="dropFieldContainer">
          <CloudUploadIcon />
          <Button id="uploadFilesFromLocal" variant="contained" color="primary" component="span">
            Upload file from local
          </Button>
          <span style={{ fontSize: 12 }}>
            <i>( Maximum 50MB allowed )</i>
          </span>
        </Grid>
        <div className="dropInfoContainer">
          <InfoOutlinedIcon fontSize="small" />
          <Typography id="dropInfoContentLine1" display="block" variant="caption" gutterBottom>
            Duplicate column names will be appended with a sequence number
          </Typography>
          <Typography id="dropInfoContentLine2" display="block" variant="caption" gutterBottom>
            Spaces in column names will be replaced by underscore
          </Typography>
        </div>
      </Grid>
    </FileInputField>
  );
};

export default UploadFileContainer;
