import { UseQueryResult, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { SignedUrlResult } from "@rapidcanvas/rc-api-core";
import api from "src/utils/AxiosClient";
import { get } from "lodash";
import {
  MAX_ALLOWED_SIZE_FOR_PREVIEW,
  MAX_LIMIT_EXCEEDED_MSG
} from "src/pages/Library/ArtifactsAndModels/Artifacts/ArtifactPreviewModal";

const QUERY_KEY_DOWNLOAD_DOCUMENTS = "query-key-download-documents";

export const getContentType = (fileType: any) => {
  const mimeTypes = {
    html: "text/html",
    css: "text/css",
    json: "application/json",
    png: "image/png",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    gif: "image/gif",
    log: "text/plain",
    svg: "image/svg+xml",
    pdf: "application/pdf",
    txt: "text/plain",
    csv: "text/csv",
    mp4: "video/mp4",
    webm: "video/webm"
  };

  return get(mimeTypes, fileType.toLowerCase());
};

const usePreviewArtifactFile = (name: string, type: string): UseQueryResult<string> => {
  return useQuery({
    queryKey: [QUERY_KEY_DOWNLOAD_DOCUMENTS, name],
    queryFn: async () => {
      let response: SignedUrlResult;

      const { data } = await api.ArtifactsControllerApi.getDownloadSignedUrl1(name);
      response = data;

      const results = await axios.get(response.signedUrl as string, {
        headers: response.headers,
        responseType: "blob"
      });
      const size = results.data?.size;

      if (size > MAX_ALLOWED_SIZE_FOR_PREVIEW * 1024 * 1024) {
        const blob = new Blob([results.data]);
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", name);
        a.click();

        return MAX_LIMIT_EXCEEDED_MSG;
      }

      const contentType = getContentType(type);
      if (!type) {
        return "";
      } else if (type === "pdf") {
        const blob = new Blob([results.data], { type: contentType });
        return URL.createObjectURL(blob);
      } else if (name.endsWith(".md")) {
        return await results.data.text();
      } else if (contentType.startsWith("text/html")) {
        const blob = new Blob([results.data], { type: contentType });
        return URL.createObjectURL(blob);
      } else if (contentType.startsWith("text/")) {
        return await results.data.text();
      } else if (contentType.startsWith("image/") || contentType.startsWith("video/")) {
        const blob = new Blob([results.data], { type: contentType });
        return URL.createObjectURL(blob);
      } else if (type === "json") {
        return await results.data.text();
      } else {
        return "";
      }
    },
    enabled: !!name,
    refetchOnMount: true
  });
};

export default usePreviewArtifactFile;
