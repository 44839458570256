import _ from "lodash";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { DataAppType } from "pages/DataApps/DataApps.type";
import { getAPIWithRethrow } from "src/utils/apiService";
import { DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

const QUERY_KEY_DATAAPP_STATUS_BY_ID = "query-key-dataapp-status-by-id";

interface IReturn {
  launchStatus: DataAppDtoLaunchStatusEnum;
}

export const fetchDataAppStatus = async (id: string) => {
  const response: DataAppType = await getAPIWithRethrow(`/dataapps/by-id/${id}`);
  return response?.launchStatus;
};

export const isInProgress = (status: DataAppDtoLaunchStatusEnum) => {
  return _.includes(
    [DataAppDtoLaunchStatusEnum.Launching, DataAppDtoLaunchStatusEnum.Stopping],
    status
  );
};

export const DATAAPP_INTERVAL_IN_SEC = 5;

const useDataAppStatusById = (
  id: string,
  options?: UseQueryOptions<IReturn>
): UseQueryResult<IReturn> => {
  return useQuery({
    queryKey: [QUERY_KEY_DATAAPP_STATUS_BY_ID, id],
    queryFn: async () => {
      try {
        const launchStatus = await fetchDataAppStatus(id);

        return { launchStatus };
      } catch {
        return { launchStatus: DataAppDtoLaunchStatusEnum.Failure };
      }
    },
    ...options,
    refetchOnMount: true
  });
};

export default useDataAppStatusById;
export { QUERY_KEY_DATAAPP_STATUS_BY_ID };
