import React from "react";
import { IconButton, InputAdornment } from "@material-ui/core";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import LibraryAddCheckOutlinedIcon from "@material-ui/icons/LibraryAddCheckOutlined";

import api from "src/utils/AxiosClient";
import useCopyClipboard from "src/hooks/useCopyClipboard";
import Field from "src/components/Inputs/Field";

const UserTokenV2 = () => {
  const token = api.getToken();
  const { handleCopyClick, isCopied } = useCopyClipboard(token);

  return (
    <Field
      id="user-auth-token"
      variant="outlined"
      label="Auth Token"
      value={token}
      type="password"
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton
              aria-label="copy auth token"
              onClick={handleCopyClick}
              onMouseDown={handleCopyClick}
              edge="end">
              {isCopied ? (
                <LibraryAddCheckOutlinedIcon fontSize="small" />
              ) : (
                <FilterNoneIcon fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default UserTokenV2;
