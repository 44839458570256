import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Grid, List, Tooltip, Typography } from "@material-ui/core";
import Rule from "./Rule";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Add from "@material-ui/icons/Add";
import { ToggleButton, ToggleButtonGroup, Alert } from "@material-ui/lab";
import styles from "./ConditionBuilder.module.scss";
import { setUniqueKeys } from "src/utils/setUniqueKeys";
import { READONLY_ENTITY } from "src/constants";

export const operators = [
  "Equal To",
  "Is In List",
  "Contains",
  "Not Equal To",
  "Reg EX",
  "Is Null",
  "Is Not Null"
];
export const supportedOperatorValues = operators.map((operator) => operator.replace(/\s/g, ""));
type Props = {
  isJobPath?: boolean;
  onChangeRules?: $TSFixMeFunction;
  initialRules: $TSFixMe;
  error: $TSFixMe;
  globalVariables: $TSFixMe;
  readonly: boolean;
};

const ConditionBuilder = ({
  isJobPath = false,
  onChangeRules = () => null,
  initialRules,
  error,
  globalVariables,
  readonly
}: Props) => {
  const [rules, setRules] = useState(
    setUniqueKeys(
      initialRules?.length > 0
        ? initialRules?.map((rule: $TSFixMe) => ({
            ...rule,
            groupElements: setUniqueKeys(rule?.groupElements)
          }))
        : [
            {
              type: "group",
              groupType: "AND",
              groupElements: [
                {
                  id: uuidv4(),
                  type: "item",
                  operator: operators[0].replace(/\s/g, ""),
                  field: {
                    type: "field",
                    name: "Field",
                    isEmpty: true
                  },
                  value: {
                    type: "lit",
                    value: ""
                  }
                }
              ]
            }
          ]
    )
  );

  const handleAddRule = () => {
    const newGroupElements = [
      {
        id: uuidv4(),
        type: "item",
        field: {
          type: "field",
          name: "Field",
          isEmpty: true
        },
        operator: operators[0].replace(/\s/g, ""),
        value: {
          type: "lit",
          value: ""
        }
      }
    ];
    //For last rule with no groups, add group elements instead of rules
    if (rules?.length === 1 && rules?.find((rule: $TSFixMe) => rule?.groupElements?.length === 0)) {
      const newRules = [{ ...rules[0], groupElements: newGroupElements }];
      setRules(newRules);
      onChangeRules(newRules);
      return;
    }
    const newRules = [
      ...rules,
      {
        id: uuidv4(),
        type: "group",
        groupType: "AND",
        groupElements: newGroupElements
      }
    ];
    setRules(newRules);
    onChangeRules(newRules);
  };

  const handleRuleOpChange = (ruleId: $TSFixMe, operator: $TSFixMe) => {
    const newRules = rules?.map((rule: $TSFixMe) =>
      rule.id === ruleId ? { ...rule, groupType: operator } : rule
    );
    setRules(newRules);
    onChangeRules(newRules);
  };

  const handleDeleteRule = (ruleId: $TSFixMe) => {
    const newRules = rules?.filter((rule: $TSFixMe) => rule.id !== ruleId);
    setRules(newRules);
    onChangeRules(newRules);
  };

  const handleAddCondition = (e: $TSFixMe, ruleId: string) => {
    e.preventDefault();
    const newRules = rules?.map((rule: $TSFixMe) => {
      if (rule.id !== ruleId) {
        return rule;
      }
      return {
        ...rule,
        groupElements: [
          ...rule?.groupElements,
          {
            id: uuidv4(),
            type: "item",
            field: {
              type: "field",
              name: "Field",
              isEmpty: true
            },
            operator: operators[0].replace(/\s/g, ""),
            value: {
              type: "lit",
              value: ""
            }
          }
        ]
      };
    });
    setRules(newRules);
    onChangeRules(newRules);
  };

  const handleOperatorChange = (ruleId: string, conditionId: string, newValue: $TSFixMe) => {
    const operator = newValue?.replace(/\s/g, "");
    const newRules = rules?.map((rule: $TSFixMe) => {
      if (rule.id !== ruleId) {
        return rule;
      }
      return {
        ...rule,
        groupElements: rule?.groupElements?.map((element: $TSFixMe) =>
          element.id === conditionId
            ? {
                ...element,
                operator,
                value: ["IsNull", "IsNotNull"].includes(operator) ? null : element.value
              }
            : element
        )
      };
    });
    setRules(newRules);
    onChangeRules(newRules);
  };

  const handleFieldChange = (ruleId: string, conditionId: string, newValue: $TSFixMe) => {
    const newRules = rules?.map((rule: $TSFixMe) => {
      if (rule.id !== ruleId) {
        return rule;
      }
      return {
        ...rule,
        groupElements: rule?.groupElements?.map((element: $TSFixMe) =>
          element.id === conditionId
            ? {
                ...element,
                field: {
                  ...element.field,
                  isEmpty: false,
                  name: newValue,
                  operator: operators[0].replace(/\s/g, "")
                }
              }
            : element
        )
      };
    });
    setRules(newRules);
    onChangeRules(newRules);
  };

  const handleValueChange = (ruleId: $TSFixMe, conditionId: $TSFixMe, newValue: $TSFixMe) => {
    const newRules = rules?.map((rule: $TSFixMe) => {
      if (rule.id !== ruleId) {
        return rule;
      }
      return {
        ...rule,
        groupElements: rule?.groupElements?.map((element: $TSFixMe) =>
          element.id === conditionId
            ? { ...element, value: { ...element.value, type: "lit", value: newValue } }
            : element
        )
      };
    });
    setRules(newRules);
    onChangeRules(newRules);
  };

  const handleDeleteCondition = (ruleId: $TSFixMe, conditionId: $TSFixMe, ruleIndex: number) => {
    const newRules = rules?.reduce((acc: $TSFixMe, rule: $TSFixMe) => {
      if (rule.id !== ruleId) {
        return [...acc, rule];
      }
      const updatedGroupElements = rule?.groupElements?.filter(
        (element: $TSFixMe) => element.id !== conditionId
      );
      if (
        (ruleIndex !== 0 && updatedGroupElements?.length === 0) ||
        (ruleIndex === 0 && rules?.length !== 1 && updatedGroupElements?.length === 0)
      ) {
        return acc;
      }
      return [
        ...acc,
        {
          ...rule,
          groupElements: updatedGroupElements
        }
      ];
    }, []);
    setRules(newRules);
    onChangeRules(newRules);
  };

  return (
    <Grid container item xs={12} className={styles.container}>
      <Grid item xs={12}>
        <div className={styles.conditionsContainer}>
          <div className={`${styles.header}  ${error ? styles.headerError : ""}`}>
            <Typography variant="subtitle1" className={`${styles.subheaderTitle}`}>
              Conditions *
            </Typography>
          </div>
          <div className={styles.rulesContainer}>
            {globalVariables?.length === 0 ? (
              <Alert severity="info" style={{ margin: 16 }} test-id="no-variables-found-message">
                No Project Variables found. Please add Project Variables under Project Settings.
              </Alert>
            ) : !!isJobPath && initialRules?.length === 0 ? (
              <Alert severity="info" style={{ margin: 16 }} test-id="no-variables-found-message">
                No condition found!
              </Alert>
            ) : (
              <Tooltip title={readonly ? READONLY_ENTITY : ""}>
                <List disablePadding>
                  {rules?.map((rule: $TSFixMe, ruleIndex: $TSFixMe) => (
                    <li key={rule.id}>
                      <Grid
                        container
                        className={ruleIndex !== 0 ? styles.nestedRule : ""}
                        style={{ paddingLeft: ruleIndex * 25 }}>
                        <Grid
                          item
                          container
                          xs={12}
                          alignItems="center"
                          className={styles.ruleGroupHeader}>
                          {ruleIndex !== 0 && (
                            <span
                              className={styles.line}
                              style={{
                                left: ruleIndex * 24
                              }}
                            />
                          )}
                          <ToggleButtonGroup
                            value={rule.groupType}
                            exclusive
                            onChange={(__, newOperator) => handleRuleOpChange(rule.id, newOperator)}
                            aria-label={`rule-${ruleIndex}-operators`}>
                            <ToggleButton
                              disableRipple
                              className={styles.toggleButton}
                              value="AND"
                              aria-label={`${ruleIndex}-and`}
                              test-id="conditions-builder-add-btn"
                              disabled={!!isJobPath || readonly}>
                              <Typography variant="caption">AND</Typography>
                            </ToggleButton>
                            <ToggleButton
                              className={styles.toggleButton}
                              value="OR"
                              aria-label={`${ruleIndex}-or`}
                              test-id="conditions-builder-or-btn"
                              disabled={!!isJobPath || readonly}>
                              <Typography variant="caption">OR</Typography>
                            </ToggleButton>
                          </ToggleButtonGroup>
                          <div className={styles.subheaderButtonContainer}>
                            <Button
                              className={styles.subheaderButton}
                              size="small"
                              onClick={handleAddRule}
                              test-id="conditions-builder-add-rule-btn"
                              disabled={!!isJobPath || ruleIndex !== rules?.length - 1 || readonly}>
                              <Typography variant="overline">
                                <Add /> ADD GROUP
                              </Typography>
                            </Button>
                            {ruleIndex !== 0 && (
                              <Button
                                className={styles.subheaderButton}
                                disabled={readonly}
                                size="small"
                                onClick={() => handleDeleteRule(rule.id)}
                                test-id="conditions-builder-delete-rule-btn">
                                <DeleteOutline className={styles.subheaderButtonIcon} />
                                <Typography variant="overline">DELETE</Typography>
                              </Button>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <ul className={styles.rulesBlock}>
                        {rule?.groupElements.map(
                          (condition: $TSFixMe, conditionIndex: $TSFixMe) => (
                            <Rule
                              key={`condition-${ruleIndex}-${condition?.id}`}
                              isJobPath={isJobPath}
                              conditionIndex={conditionIndex}
                              ruleIndex={ruleIndex}
                              condition={condition}
                              error={error}
                              rule={rule}
                              globalVariables={globalVariables}
                              onFieldChange={handleFieldChange}
                              onOperatorChange={handleOperatorChange}
                              onDeleteCondition={handleDeleteCondition}
                              onValueChange={handleValueChange}
                              onAddCondition={handleAddCondition}
                              readonly={readonly}
                            />
                          )
                        )}
                      </ul>
                    </li>
                  ))}
                </List>
              </Tooltip>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ConditionBuilder;
