// Retaining commented redux logic as a reference for implementing future features.
// Packages
import { QueryClient, useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
// import { useDispatch } from "react-redux";
import {
  filter,
  isArray,
  map,
  some
  //  sortBy, toLower
} from "lodash";

// Utils
import api from "utils/AxiosClient";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Stores
// import { setEnvironments } from "src/stores/environmentsSlice";

import { QUERY_KEY_ENV_BY_ID } from "./useGetEnvironmentById";

export const enum UseGetEnvironmentsQueryKeys {
  Environments = "environments"
}

interface IEnvironmentsProps extends UseQueryOptions<unknown, unknown, EnvDto[]> {}

const useGetEnvironments = (props: IEnvironmentsProps): UseQueryResult<EnvDto[]> => {
  const { ...options } = props;

  // const dispatch = useDispatch();

  return useQuery({
    queryKey: [UseGetEnvironmentsQueryKeys.Environments],
    queryFn: async () => await api.fetchResponse(async () => await api.EnvControllerApi.findEnvs()),
    select: (data) => (isArray(data) ? data : []),
    // onSuccess: (data) => dispatch(setEnvironments(sortBy(data, (item) => toLower(item?.name)))),
    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely
    ...options
  });
};

export const updateEnvironmentsQueryData = (
  queryClient: QueryClient,
  newEnvironment: EnvDto,
  action: "create" | "update" | "delete" = "update"
) => {
  if (action === "update") {
    queryClient.setQueryData([QUERY_KEY_ENV_BY_ID, newEnvironment.id], newEnvironment);
  }

  queryClient.setQueryData(
    [UseGetEnvironmentsQueryKeys.Environments],
    (prevEnvironments?: EnvDto[]) => {
      if (!prevEnvironments) return prevEnvironments;

      switch (action) {
        case "create":
          // Add environment only if it doesn't already exist
          if (!some(prevEnvironments, { id: newEnvironment?.id })) {
            return [...prevEnvironments, newEnvironment];
          }
          return prevEnvironments;

        case "delete":
          // Remove environment with matching id
          return filter(
            prevEnvironments,
            (prevEnvironment) => prevEnvironment?.id !== newEnvironment?.id
          );

        case "update":
        default:
          // Replace environment with matching id or keep the previous environment
          return map(prevEnvironments, (prevEnvironment) =>
            prevEnvironment?.id === newEnvironment?.id ? newEnvironment : prevEnvironment
          );
      }
    }
  );
};

export default useGetEnvironments;
