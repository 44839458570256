import React, { useMemo } from "react";

// MUI
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Icons
import { MemoryIcon } from "src/icons/MemoryIcon";

// Open API
import { EnvDto, ResourceUsageDto } from "@rapidcanvas/rc-api-core";

// Components
import { HorseShoeProgressBar } from "src/components";

interface IStyleProps {
  dialSize: number;
}

const useStyles = makeStyles(() => ({
  memoryDetailsContainer: {
    position: "absolute",
    width: ({ dialSize }: IStyleProps) => `${dialSize}px`,
    height: ({ dialSize }: IStyleProps) => `${dialSize - 10}px`,
    "& .title": {
      marginTop: "15%",
      color: "#008FE4",
      columnGap: 5
    },
    "& .percentage": { marginTop: "auto" }
  }
}));

interface IProps {
  environment?: EnvDto;
  data?: ResourceUsageDto;
  isLoading?: boolean;
}

const MemoryUsage: React.FC<IProps> = (props) => {
  const { environment, data, isLoading } = props;

  const theme = useTheme();
  const classes = useStyles({ dialSize: 180 });

  const formattedPercentage = useMemo(
    () => (data?.memoryPercentage ? Number(parseFloat("" + data?.memoryPercentage).toFixed(2)) : 0),
    [data?.memoryPercentage]
  );

  const formattedMemoryInGbs = useMemo(
    () => (environment?.memInMbs ? parseInt(environment.memInMbs as unknown as string) / 1024 : 0),
    [environment?.memInMbs]
  );

  return (
    <HorseShoeProgressBar
      isNewTheme
      percentage={formattedPercentage || 0}
      disabled={!formattedPercentage}
      wrapStyleProps={{
        backgroundColor: theme.palette.common.white
      }}
      radialWrapperPseudoStyleProps={{
        background: theme.palette.common.white
      }}>
      {!!isLoading ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : (
        <Grid
          container
          direction="row"
          alignContent="space-around"
          className={classes.memoryDetailsContainer}>
          <Grid container justifyContent="center" className="title">
            <MemoryIcon color="#4646b5" data-testid="envConfigMemoryIcon" />
            <Typography variant="body2" color="primary" data-testid="envConfigMemory">
              Memory
            </Typography>
          </Grid>
          <Grid container justifyContent="center" direction="column">
            <Typography
              variant="h6"
              color="textPrimary"
              align="center"
              data-testid="envConfigMemoryValue1">
              {(formattedMemoryInGbs * formattedPercentage * 0.01).toFixed(2)} GB
            </Typography>
            <Typography
              variant="caption"
              color="textPrimary"
              align="center"
              data-testid="envConfigMemoryValue2">
              of {formattedMemoryInGbs} GB used
            </Typography>
          </Grid>
          <Grid container justifyContent="center" className="percentage">
            <Typography variant="body2" color="textPrimary" data-testid="envConfigMemoryUsage">
              {formattedPercentage.toFixed(2)}% used
            </Typography>
          </Grid>
        </Grid>
      )}
    </HorseShoeProgressBar>
  );
};

export default MemoryUsage;
