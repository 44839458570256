// Packages
import { QueryClient, useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { filter, isArray, map, some } from "lodash";

// Utils
import api from "utils/AxiosClient";

// Open API
import { DataSourceDashBoardDto } from "@rapidcanvas/rc-api-core";

export const enum UseGetConnectorsQueryKeys {
  Connectors = "connectors"
}

interface IConnectorsProps extends UseQueryOptions<unknown, unknown, DataSourceDashBoardDto[]> {}

const useGetConnectors = (props: IConnectorsProps): UseQueryResult<DataSourceDashBoardDto[]> => {
  const { ...options } = props;

  return useQuery({
    queryKey: [UseGetConnectorsQueryKeys.Connectors],
    queryFn: async () =>
      await api.fetchResponse(
        async () => await api.DataSourceControllerApi.getCurrentTenantDataSources()
      ),
    select: (data) => (isArray(data) ? data : []),
    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely
    ...options
  });
};

export const updateConnectorsQueryData = (
  queryClient: QueryClient,
  newConnector: DataSourceDashBoardDto,
  action: "create" | "update" | "delete" = "update"
) => {
  queryClient.setQueryData(
    [UseGetConnectorsQueryKeys.Connectors],
    (prevConnectors?: DataSourceDashBoardDto[]) => {
      if (!prevConnectors) return prevConnectors;

      switch (action) {
        case "create":
          // Add connector only if it doesn't already exist
          if (!some(prevConnectors, { id: newConnector?.id })) {
            return [...prevConnectors, newConnector];
          }
          return prevConnectors;

        case "delete":
          // Remove connector with matching id
          return filter(prevConnectors, (prevConnector) => prevConnector?.id !== newConnector?.id);

        case "update":
        default:
          // Replace connector with matching id or keep the previous connector
          return map(prevConnectors, (prevConnector) =>
            prevConnector?.id === newConnector?.id ? newConnector : prevConnector
          );
      }
    }
  );
};

export default useGetConnectors;
