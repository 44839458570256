import React, { useMemo, useState } from "react";
import { Grid, IconButton, Tooltip, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

import ChartFrame from "pages/Projects/Charts/ChartFrame";
import ArrowExpandIcon from "src/icons/NewUX/ArrowExpandIcon";
import NewModal from "components/Modal/NewModal";
import ArrowCollapseIcon from "src/icons/NewUX/ArrowCollapseIcon";
import PlotlyCharts from "src/components/PlotlyCharts";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles({
  chartOutput: {
    background: "#fff",
    borderRadius: "2px",
    border: "1px solid #D1D1D1"
  },
  chartName: {
    width: "100%",
    padding: "4px 12px 0px !important",
    color: "#133553",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #D1D1D1"
  },
  chart: {
    width: "100%",
    overflow: "auto",
    maxWidth: "80vw",
    maxHeight: "80vh",

    "& .js-plotly-plot .plotly .modebar": {
      right: "12px"
    }
  },
  chartWrap: {
    maxWidth: "80vw",
    maxHeight: "80vh",
    overflow: "auto"
  }
});

interface IProps {
  chart: any;
}

const AskAIChart: React.FC<IProps> = (props) => {
  const { chart } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isXl = useMediaQuery(theme.breakpoints.down("xl"));

  const zoomFactor = isXs ? 1.1 : isSm ? 1.2 : isMd ? 1.3 : isLg ? 1.4 : isXl ? 1.5 : 1;

  const { chartJson, cleanedChartJson } = useMemo(() => {
    const cJson =
      chart?.chartJson && typeof chart.chartJson === "string"
        ? { ...JSON.parse(chart.chartJson) }
        : null;
    const cleanedJson = cJson
      ? {
          ...cJson,
          layout: {
            ...cJson.layout,
            title: null
          }
        }
      : {};

    const expandedJson = cJson
      ? {
          ...cJson,
          layout: {
            ...cJson.layout,
            title: null,
            width: cJson.layout.width * zoomFactor,
            height: cJson.layout.height * zoomFactor
          }
        }
      : {};

    return { chartJson: cJson, cleanedChartJson: cleanedJson, expandedJson };
  }, [chart]);

  const expandedJson = useMemo(() => {
    if (!open) {
      return null;
    }
    return chartJson
      ? {
          ...chartJson,
          layout: {
            ...chartJson.layout,
            title: null,
            width: chartJson.layout.width * zoomFactor,
            height: chartJson.layout.height * zoomFactor
          }
        }
      : {};
  }, [chartJson, zoomFactor, open]);

  const handleExpand = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const children = (
    <Grid container direction="column" className={classes.chartOutput}>
      {chartJson ? (
        <Grid container direction="column" style={{ width: "auto", gap: "4px" }}>
          <Grid item className={classes.chartName}>
            <span data-testid="chartName">{chart.name}</span>
            <Tooltip title={open ? "Collapse" : "Expand"}>
              <IconButton size="small" onClick={handleExpand} data-testid="chartToggleBtn">
                {open ? <ArrowCollapseIcon /> : <ArrowExpandIcon />}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item container className={classes.chartWrap} data-testid="chartContainer">
            <Grid className={classes.chart}>
              {open && !expandedJson ? (
                <CommonLoader />
              ) : (
                <PlotlyCharts {...(open ? expandedJson : cleanedChartJson)} />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <ChartFrame data={chart} size={12} />
      )}
    </Grid>
  );

  return (
    <>
      {children}
      <NewModal
        width="auto"
        contentWidth="auto"
        open={open}
        hideCloseButton
        hideFooter
        background="#fff"
        data-testid="chartExpandedModal"
        keepMounted={false}
        onClose={handleClose}>
        {children}
      </NewModal>
    </>
  );
};

export default AskAIChart;
