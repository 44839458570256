import React, { useEffect, useMemo, useState } from "react";
import usePredictionServiceLogs from "../../../../../../hooks/usePredictionServiceLogs";
import { Table } from "src/components";

import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core";
import { dateFormat } from "src/utils/dateFormat";
import EventBus from "src/utils/EventBus";
import LogDetailsModal from "./LogDetailsModal";
import { filter, includes, isEmpty, isEqual, orderBy, size, toFinite } from "lodash";
import { ToastifyAlert } from "src/components/ToastifyAlert/ToastifyAlert";
import { PredictLogDtoStatusEnum } from "@rapidcanvas/rc-api-core";
import { FilterIcon } from "src/icons/FilterIcon";
import { DataSourcesHelperText } from "src/pages/DataSources/utils/DataSources.constants";
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";
import CommonLoader from "src/components/CommonLoader";

interface IProps {
  id: string;
  open: boolean;
}

const useStyles = makeStyles({
  wrapper: {
    padding: "16px",
    minHeight: "calc(100vh - 165px)"
  },

  details: {
    fontSize: "16px",
    fontWeight: 500
  },
  message: {
    fontSize: "12px"
  },
  successStatus: {
    color: " #3b873e"
  },
  failureStatus: {
    color: " #c77700"
  }
});

const statusList = [PredictLogDtoStatusEnum.Success, PredictLogDtoStatusEnum.Failure];

const PredictionLogsTable: React.FC<IProps> = ({ id, open }) => {
  const {
    data: logsData,
    isLoading,
    isFetching,
    refetch
  } = usePredictionServiceLogs(id, { refetchOnMount: true });
  const classes = useStyles();
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [statusChecked, setStatusChecked] = useState(statusList);

  const showDetails = (rowDetails: any) => {
    EventBus.publish(EVENTBUS_EVENTS.ShowLogDetails, { serviceId: id, ...rowDetails });
  };

  const toggleFilter = (event: any) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const closeFilter = () => {
    setFilterAnchorEl(null);
  };

  const handleCheckAll = (event: any) => {
    if (event.target.checked) {
      setStatusChecked(statusList);
    } else {
      setStatusChecked([]);
    }
  };

  const isFiltered = useMemo(
    () => !isEqual(size(statusChecked), size(statusList)),
    [statusChecked]
  );

  const handleCheck = (event: any, name: PredictLogDtoStatusEnum) => {
    if (event.target.checked) {
      setStatusChecked((prev) => [...prev, name]);
    } else {
      setStatusChecked((prev) => filter(prev, (item) => item !== name));
    }
  };

  useEffect(() => {
    if (open && logsData) {
      refetch();
    }
  }, [open]);

  const columns = useMemo(
    () => [
      {
        id: "Request Id",
        accessor: "id",
        Header: "Request Id",
        isSortable: true,
        sortType: "alphanumeric"
      },
      {
        id: "Start Time",
        accessor: "start-time",
        Header: "Start Time",
        isSortable: true,
        Cell: (row: $TSFixMe) => <span>{dateFormat(toFinite(row.value))}</span>
      },

      {
        id: "End Time",
        accessor: "end-time",
        Header: "End Time",
        isSortable: true,
        Cell: (row: $TSFixMe) => <span>{dateFormat(toFinite(row.value))}</span>
      },
      {
        id: "Tracking ID",
        accessor: "trackingId",
        Header: "Tracking ID",
        isSortable: true,
        width: 100,
        Cell: (row: $TSFixMe) => <span>{row.value !== "nan" ? row.value : "-"}</span>
      },
      {
        id: "User",
        accessor: "userEmail",
        Header: "User",
        isSortable: true,
        Cell: (row: $TSFixMe) => <span>{row.value !== "nan" ? row.value : "-"}</span>
      },
      {
        id: "Status",
        accessor: "status",
        Header: "Status",
        isSortable: true,
        width: 100,
        sortType: "alphanumeric",
        Cell: (row: $TSFixMe) => (
          <span
            className={
              row?.value === PredictLogDtoStatusEnum.Success
                ? classes.successStatus
                : classes.failureStatus
            }>
            {row?.value}
          </span>
        )
      },

      {
        id: "Info",
        accessor: "id",
        Header: "Info",
        width: 100,
        Cell: ({ row }: $TSFixMe) => {
          return (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => showDetails(row?.original)}>
              View
            </Button>
          );
        }
      }
    ],
    []
  );

  const data = useMemo(() => {
    const columns = logsData?.columns || [];
    const rows = logsData?.rows || [];

    if (columns?.length === 0 || rows?.length === 0) {
      return [];
    } else {
      const outputRows = [
        ...rows?.reduce((rowAcc: $TSFixMe, row: $TSFixMe) => {
          rowAcc.push(
            (row?.cells || [])?.reduce((cellAcc: $TSFixMe, cell: $TSFixMe, cellIndex: $TSFixMe) => {
              cellAcc[columns[cellIndex]] =
                !!cell && typeof cell === "string" && ["nan"].includes(cell?.trim()?.toLowerCase())
                  ? ""
                  : cell;
              return cellAcc;
            }, {})
          );

          return rowAcc;
        }, [])
      ];

      return orderBy(
        filter(outputRows, (item) => includes(statusChecked, item.status)),
        (item) => toFinite(item["end-time"])
      );
    }
  }, [logsData, statusChecked]);

  if (isLoading) {
    return <CommonLoader />;
  }

  const tableExtra =
    !isEmpty(logsData?.columns) && !isEmpty(logsData?.columns) ? (
      <Tooltip title={"Filter By Status"}>
        <IconButton
          size="small"
          style={{ marginLeft: "auto" }}
          data-testid="environmentsFilterIcon"
          onClick={toggleFilter}>
          <Badge color="error" variant="dot" overlap="rectangular" invisible={!isFiltered}>
            <FilterIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    ) : null;

  return (
    <Paper className={classes.wrapper}>
      <Menu
        id="logsStatusFilter"
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={closeFilter}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}>
        <MenuItem key="log_types_select_all">
          <Checkbox
            data-testid="filterSelectAll"
            color="default"
            checked={isEqual(size(statusChecked), size(statusList))}
            onClick={handleCheckAll}
          />
          {DataSourcesHelperText.SelectAll}
        </MenuItem>
        {statusList?.map((item, index: number) => (
          <MenuItem key={`log_status_type_${index}`}>
            <Checkbox
              data-testid={`dataConnector${index + 1}`}
              checked={includes(statusChecked, item)}
              color="default"
              onClick={(event) => {
                handleCheck(event, item);
              }}
            />
            <div>{item}</div>
          </MenuItem>
        ))}
      </Menu>

      <Grid container alignItems="center">
        <Grid className={classes.details}> Prediction Service History</Grid>
      </Grid>
      <Grid container>
        <span className={classes.message}>Showing history from past 30 days</span> {tableExtra}
      </Grid>

      <Grid style={{ position: "relative" }}>
        <Grid>
          {size(data) > 0 ? (
            <Table
              columns={columns}
              data={data}
              hiddenColumns={["Request Id", "Tracking ID"]}
              maxHeight="calc(100vh - 180px)"
            />
          ) : (
            <Box p={"20px 0"} width="100%">
              {!isFetching && <ToastifyAlert type="info" message={"No history found!"} />}
            </Box>
          )}
        </Grid>
        {isFetching && (
          <div
            style={{
              position: "absolute",
              top: 20,
              left: 0,
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 1000
            }}>
            <CircularProgress size="40px" color="secondary" />
            <Typography style={{ marginLeft: "10px" }}>Fetching latest history...</Typography>
          </div>
        )}
      </Grid>

      <LogDetailsModal />
    </Paper>
  );
};

export default PredictionLogsTable;
