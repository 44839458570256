import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  rotatingSvg: {
    animation: "$rotate 1.2s linear infinite",
    transformOrigin: "center"
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)"
    },
    "100%": {
      transform: "rotate(360deg)"
    }
  }
});
interface IProps {
  size?: number;
  padding?: string | number;
  noPadding?: boolean;
}

const CommonLoader: React.FC<IProps> = ({ size = 40, padding, noPadding }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      style={{ padding: padding ? padding : noPadding ? 0 : 40 }}>
      <svg
        style={{ height: size, width: size }}
        className={classes.rotatingSvg}
        viewBox={"0 0 80 80"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g>
          <rect width={80} height={80} fill="none"></rect>
          <g>
            <path
              d="M22.1143 70.6748C34.3063 69.4588 40.9297 62.6425 45.696 55.4394L48.578 51.0839L61.4019 62.8738L55.3708 69.4002L50.0804 64.5363C44.4991 71.5541 36.263 78.1838 22.9997 79.5067L22.1143 70.6748Z"
              fill="#FF6550"></path>
            <path
              d="M6.02049 48.8537C15.4976 56.5929 25.0072 56.4443 33.4785 54.7126L38.601 53.6654L39.3101 71.0469L30.4245 71.4081L30.1318 64.2375C21.2158 65.2633 10.6987 64.1423 0.389115 55.7234L6.02049 48.8537Z"
              fill="#FF6550"></path>
            <path
              d="M70.8607 58.681C69.6415 46.5202 62.8076 39.9139 55.586 35.1598L51.2193 32.2852L63.0396 19.4943L69.5828 25.5099L64.7065 30.7868C71.7422 36.3538 78.389 44.5686 79.7153 57.7978L70.8607 58.681Z"
              fill="#FF6550"></path>
            <path
              d="M48.9912 74.7569C56.7478 65.3011 56.5991 55.8127 54.8633 47.3606L53.8138 42.2498L71.2344 41.5421L71.5964 50.4079L64.4097 50.6998C65.4378 59.5957 64.3144 70.0892 55.8764 80.3757L48.9912 74.7569Z"
              fill="#FF6550"></path>
            <path
              d="M58.8335 10.0631C46.6413 11.2792 40.0181 18.0955 35.2518 25.2986L32.3698 29.6541L19.5458 17.8642L25.577 11.3377L30.8674 16.2017C36.4488 9.18393 44.6849 2.55417 57.9479 1.23129L58.8335 10.0631Z"
              fill="#FF6550"></path>
            <path
              d="M74.9329 31.8727C65.4527 24.1361 55.9401 24.2845 47.4659 26.0157L42.3419 27.0625L41.6325 9.6867L50.5211 9.32561L50.8137 16.4938C59.7327 15.4686 70.2531 16.589 80.5662 25.0053L74.9329 31.8727Z"
              fill="#FF6550"></path>
            <path
              d="M10.0852 22.0704C11.3043 34.2311 18.1383 40.8375 25.3599 45.5916L29.7266 48.4662L17.9063 61.2571L11.3632 55.2415L16.2395 49.9646C9.20369 44.3976 2.55677 36.1826 1.23054 22.9536L10.0852 22.0704Z"
              fill="#FF6550"></path>
            <path
              d="M31.9565 5.99452C24.2 15.4502 24.3489 24.9386 26.0845 33.3908L27.134 38.5018L9.71345 39.2093L9.35148 30.3435L16.5381 30.0517C15.5102 21.1557 16.6336 10.6622 25.0714 0.375693L31.9565 5.99452Z"
              fill="#FF6550"></path>
          </g>
        </g>
      </svg>
    </Grid>
  );
};

export default CommonLoader;
