// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import _ from "lodash";

// Utils
import api from "utils/AxiosClient";

// Open API
import { ProjectDashboardDto } from "@rapidcanvas/rc-api-core";

export const enum UseGetProjectsQueryKeys {
  Projects = "projects"
}

const useGetDashboardProjects = (
  props: UseQueryOptions<unknown, unknown, ProjectDashboardDto[]>
): UseQueryResult<ProjectDashboardDto[]> => {
  const { ...options } = props;

  return useQuery({
    queryKey: [UseGetProjectsQueryKeys.Projects],
    queryFn: async () => {
      const projects = await api.fetchResponse(
        async () => await api.ProjectsControllerV2Api.getDashboard()
      );

      return _.orderBy(projects, "updated", "desc");
    },
    ...options
  });
};

export default useGetDashboardProjects;
