import React, { useCallback, useMemo, useState } from "react";
import DeleteUserModal from "./DeleteUserModal";
import _ from "lodash";
import shallow from "zustand/shallow";
import { Button } from "@material-ui/core";
import { UserDataDto } from "@rapidcanvas/rc-api-core";

import TableActions from "./TableActions";
import useAuthStore from "src/stores/auth.store";
import useTenantsStore from "src/stores/tenant-management.store";
import { Table } from "src/components";
import { formTableCells } from "src/components/Table/TableUtils";
import { getUserColumns, Status } from "./UserColumns";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { TenantUser } from "src/types";
import { useInviteUserMutation, useUpdateUserMutation } from "src/hooks/api";
import { handleResponse } from "src/utils/apiService";
import InviteUsersModal from "./InviteUsersModal";

const TABLE_SIZE = "small";

type IProps = { users: TenantUser[] };
const TenantUsersManagement = ({ users }: IProps) => {
  const [filterValue, setFilterValue] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [rowToBeEdited, setRowToBeEdited] = useState(null);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [editedRow, setEditedRow] = React.useState<any>();

  const [currentUser, setCurrentUser] = useState<undefined | null | UserDataDto>(null);
  const [showInviteUsersModal, setShowInviteUsersModal] = useState(false);

  const [userId, userEmail] = useAuthStore((state) => [state.userId, state.userEmail, state]);
  const [roles, toggleTenantsRefresh] = useTenantsStore(
    (state) => [state.roles, state.toggleTenantsRefresh],
    shallow
  );
  const { canEditUsers } = useAccessControlContext();
  const inviteUserMutation = useInviteUserMutation();
  const updateUserMutation = useUpdateUserMutation();

  const roleValues = useMemo(() => {
    return _.sortBy(
      _.map(roles, ({ name, id }) => ({
        label: name,
        value: id
      })),
      "label"
    );
  }, [roles]);

  const filteredUsers = useMemo(() => {
    return users.filter((user: any) => {
      return user?.email.toLowerCase().includes(filterValue.toLowerCase());
    });
  }, [filterValue, users]);

  const handleEditRoleSave = useCallback(async () => {
    const { rowId, value, original } = editedRow;

    updateUserMutation.mutate(
      {
        userId: rowId,
        userType: original.userType,
        roleId: value
      },
      {
        onSuccess: () => {
          setEditedRow(null);
          setRowToBeEdited(null);
        }
      }
    );
  }, [editedRow]);

  const isSaveInProgress = updateUserMutation.isLoading;

  const inputColumns = getUserColumns(roleValues);
  const columns = formTableCells({
    inputColumns,
    size: TABLE_SIZE,
    editProps: {
      isEditable: true,
      isInviteExpired: (cell) => {
        const { status } = cell.row.original;
        return status === Status.EXPIRED;
      },
      isLoggedInUser: (cell) => {
        const { email } = cell.row.original;
        return email === userEmail;
      },
      canEdit: (cell) => {
        const { status } = cell.row.original;
        const isCurrentUser = userId === cell.row.original.id;
        return canEditUsers && !isCurrentUser && !(status === Status.EXPIRED);
      },
      handleEditClick: (_: any, cell) => {
        setRowToBeEdited(cell.row.original.id);
        setEditedRow(null);
      },
      isRowInEditMode: (cell) => cell.row.original.id === rowToBeEdited,
      handleEditSave: () => handleEditRoleSave(),
      isSaveDisabled: (cell) => {
        return cell.row.original.id === rowToBeEdited && !editedRow;
      },
      isSaveInProgress: (cell) => {
        const isEditing = cell.row.original.id === editedRow?.rowId;
        return isEditing && isSaveInProgress;
      },
      handleEditCancel: () => {
        setRowToBeEdited(null);
        setEditedRow(null);
      }
    },
    deleteProps: {
      isDeletable: true,
      isLoggedInUser: (cell) => {
        const { email } = cell.row.original;
        return email === userEmail;
      },
      canDelete: (cell) => {
        const isCurrentUser = userId === cell.row.original.id;
        return canEditUsers && !isCurrentUser;
      },
      handleDeleteClick: (_: any, cell) => {
        setUserToDelete(cell.row.original);
      }
    },
    renderExtraActions: ({ cell }) => {
      // $FixMe, $ToBeRemoved
      // @ts-ignore
      const { email, status, roleId } = cell.row.original;
      return status === Status.EXPIRED ? (
        <Button
          size="small"
          disabled={!canEditUsers}
          onClick={() => promptInviteUsers(cell?.row?.original)}>
          REINVITE
        </Button>
      ) : (
        <React.Fragment />
      );
    }
  });

  // $FixMe, $ToBeRemoved
  // @ts-ignore
  const reInviteUser = async (roleId: string, email: string) => {
    inviteUserMutation.mutate(
      {
        requests: [
          {
            roleId,
            recipientEmail: email
          }
        ]
      },
      {
        onSuccess: (responses: any) => {
          const errorMessage = responses?.[0].errorMessage;
          if (errorMessage) {
            handleResponse({ errorMessage });
            return;
          }
          toggleTenantsRefresh();
          handleResponse({ successMessage: "Email Invitation sent successfully." });
        }
      }
    );
  };

  const updateMyData = ({ rowId, columnId, value, original }: any) => {
    setSkipPageReset(true);
    setEditedRow({ rowId, columnId, value, original });
  };

  const onDeleteSuccess = () => {
    setUserToDelete(null);
    toggleTenantsRefresh();
  };

  // Confirm run with reload datasets - STARTS >>
  const promptInviteUsers = (user?: UserDataDto) => {
    setCurrentUser(() => user);
    setShowInviteUsersModal(() => true);
  };

  const resetPromptInviteUsers = () => {
    setCurrentUser(() => null);
    setShowInviteUsersModal(() => false);
    toggleTenantsRefresh();
  };
  // << ENDS - Confirm run with reload datasets

  return (
    <>
      {showInviteUsersModal && !!currentUser && (
        <InviteUsersModal user={currentUser} onClose={resetPromptInviteUsers} />
      )}

      <Table
        data={filteredUsers}
        rowToBeEdited={rowToBeEdited}
        size={TABLE_SIZE}
        columns={columns}
        isCellSortEnabled
        skipPageReset={skipPageReset}
        updateMyData={updateMyData}
        actionHeader={{
          title: "Users",
          actions: <TableActions filterValue={filterValue} setFilterValue={setFilterValue} />
        }}
        orderByDefault="Invited On"
        sortInverted
        hideSettings
        disableVirtualization
      />
      <DeleteUserModal
        user={userToDelete}
        visible={!!userToDelete}
        onDeleteSuccess={onDeleteSuccess}
        onClose={() => setUserToDelete(null)}
      />
    </>
  );
};

export default TenantUsersManagement;
