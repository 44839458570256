import { Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import _, { isEmpty } from "lodash";

import { AIChatResponseDto } from "@rapidcanvas/rc-api-core";
import {
  AIChatRequestDtoOutputTypeEnum,
  OutputEntity,
  OutputEntityDto
} from "@rapidcanvas/rc-api-core";
import { ModelIcon } from "src/icons/NewUX/ModelIcon";

import { DeleteQueryModal } from "src/pages/Projects/AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/DeleteQueryModal";
import { useDeleteAIGuideMessages } from "src/hooks/api";
import { useParams } from "react-router";
import { handleResponse } from "src/utils/apiService";
import GroupedCharts from "../../common/GroupedCharts";
import AIGuideTextRow from "../../common/AIGuideTextRow";
import AIGuideTextResponse from "../../common/AIGuideTextResponse";
import { ViewOutputIcon } from "src/icons/NewUX/ViewOutputIcon";
import { ViewCodeIcon } from "src/icons/NewUX/ViewCodeIcon";
import EditChartNonChartOutput from "./EditChartNonChartOutput";

const useStyles = makeStyles({
  chart: {
    padding: "0px 0px 0px 16px",
    width: "100%"
  },
  outputIconWrap: {
    width: "24px",
    minWidth: "24px",
    background: "#fff",
    borderRadius: "50%",
    height: "24px",
    flexWrap: "nowrap",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  queryBtn: {
    borderRadius: "4px",
    background: "#fff",
    padding: "4px"
  }
});

const EditChartChat = ({
  message,
  subThreadId,
  onClose,
  removeQuery,

  askAIMessageId: parentAskAIMessageID,
  parentThreadId,
  isSaveDisabled
}: {
  message: AIChatResponseDto;
  subThreadId: string | undefined;
  onClose: () => void;
  removeQuery: (askAIMessageId: string) => void;
  disableDelete: boolean;
  askAIMessageId: string | undefined;
  parentThreadId: string | undefined;
  isSaveDisabled: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(!!(message as any).isExpanded);
  const { projectId } = useParams();
  const [shouldShowCode, setShouldShowCode] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const classes = useStyles();
  const {
    outputEntityResponseList,
    outputEntityList,
    transformId,
    error,
    outputType,
    sampleRows,
    askAIMessageId,
    query,
    code
  } = message;

  const isOutputError = !isEmpty(error);
  const hasResponse = outputEntityResponseList && outputEntityResponseList?.length !== 0;
  const isOutputModel = outputType === AIChatRequestDtoOutputTypeEnum.Model;

  const deleteQueriesMutation = useDeleteAIGuideMessages();

  const onDeleteQuery = (messageId: string) => {
    deleteQueriesMutation.mutate(
      {
        messageIds: [messageId],
        threadId: message.threadId!,
        projectId
      },
      {
        onSuccess: () => {
          setShowDeleteModal(false);
          handleResponse({ successMessage: "Query deleted successfully" });
          removeQuery(messageId);
        }
      }
    );
  };

  const charts = useMemo(
    () =>
      (
        (outputEntityResponseList as OutputEntityDto[]) ||
        (outputEntityList as OutputEntity[]) ||
        []
      )?.filter(
        (entity: OutputEntity) => entity.outputType === AIChatRequestDtoOutputTypeEnum.Chart
      ),
    [outputEntityResponseList, outputEntityList]
  );

  const chartList = useMemo(
    () =>
      hasResponse
        ? charts?.map((outputEntityResponse: any) => ({
            plotlyJson: JSON.parse(outputEntityResponse.plotlyJson!),
            name: outputEntityResponse?.name || ""
          }))
        : charts?.map((outputEntity: any) => ({
            name: outputEntity?.name || "",
            dataFilePath: outputEntity?.plotlyChartFilePath!,
            chartType: "JSON",
            shouldFetchData: false
          })),
    [hasResponse, charts]
  );

  const groupedChartsTitle = useMemo(
    () =>
      isOutputModel
        ? (
            _.find(outputEntityList || outputEntityResponseList, [
              "outputType",
              AIChatRequestDtoOutputTypeEnum.Model
            ]) as any
          )?.name
        : hasResponse
          ? `${outputEntityResponseList?.[0]?.name}${outputEntityResponseList?.length && outputEntityResponseList?.length !== 1 ? ` (+${outputEntityResponseList?.length - 1})` : ""}`
          : `${outputEntityList?.[0]?.name}${outputEntityList?.length && outputEntityList?.length !== 1 ? ` (+${outputEntityList?.length - 1})` : ""}`,
    [isOutputModel, outputEntityList, outputEntityResponseList, hasResponse]
  );

  const rightContainer = isExpanded ? (
    <Tooltip title={shouldShowCode ? "View Output" : "View Code"}>
      <div>
        <IconButton
          className={classes.queryBtn}
          data-testid={`ask-ai-view-${shouldShowCode ? "output" : "code"}-btn`}
          onClick={(event: any) => {
            event.stopPropagation();
            setShouldShowCode((show) => !show);
          }}>
          {shouldShowCode ? <ViewOutputIcon /> : <ViewCodeIcon />}
        </IconButton>
      </div>
    </Tooltip>
  ) : (
    <React.Fragment />
  );

  return (
    <>
      <AIGuideTextRow
        title={query!}
        width="750px"
        canDelete={true}
        setShowDeleteModal={setShowDeleteModal}
        maxHeight="132px"
        disableDelete={deleteQueriesMutation.isLoading}
        queryInputs={[]}
        color={"#fff"}
        hoverBgColor="#fff"
        queryAsTitle
        deleteTooltip={
          deleteQueriesMutation.isLoading ? "Please wait until existing query is complete" : ""
        }
      />

      {outputType === "CHART" ? (
        isOutputError && code ? (
          <AIGuideTextResponse
            message={message}
            isExpanded={isExpanded}
            shouldShowCode={shouldShowCode}
            hasMarkDown={false}
            rightContainer={rightContainer}
            isOutputDataset={false}
            isOutputChart={true}
            isOutputModel={false}
            setIsExpanded={(expanded: boolean) => {
              isOutputError && code && setShouldShowCode(expanded);
              setIsExpanded(expanded);
            }}
          />
        ) : !isOutputError ? (
          <Grid className={classes.chart}>
            <GroupedCharts
              isSaveDisabled={isSaveDisabled}
              title={groupedChartsTitle}
              askAIMessageId={askAIMessageId}
              parentAskAIMessageID={parentAskAIMessageID}
              isCollapsed={!isExpanded}
              errDetails={error ? { lineOfCode: (error as any)?.lineInCode } : {}}
              chartList={chartList || []}
              onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
              isAddedToRecipe={!!transformId}
              sampleRows={sampleRows}
              subThreadId={subThreadId}
              parentThreadId={parentThreadId}
              isEditChart={true}
              onClose={onClose}
              icon={
                isOutputModel ? (
                  <Grid
                    test-id="ask-ai-modal-rc-icon-container"
                    container
                    alignItems="center"
                    justifyContent="center"
                    data-testid="ai-model-icon"
                    className={classes.outputIconWrap}>
                    <ModelIcon width={14} height={14} viewBox="-3 -3 24 24" color="#7C7C7C" />
                  </Grid>
                ) : undefined
              }
            />
          </Grid>
        ) : (
          <AIGuideTextResponse
            message={message}
            shouldShowCode={false}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            hasMarkDown={false}
            isOutputDataset={false}
            isOutputChart={true}
            isOutputModel={false}
          />
        )
      ) : (
        <EditChartNonChartOutput />
      )}

      {showDeleteModal && (
        <DeleteQueryModal
          userInput={query}
          handleSubmit={() => onDeleteQuery(askAIMessageId!)}
          onClose={() => setShowDeleteModal(false)}
          loading={deleteQueriesMutation.isLoading}
        />
      )}
    </>
  );
};

export default React.memo(EditChartChat);
