import { Table as MaUTable, TableRow } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core";

interface TableStylesProps {
  size?: string;
  fixedLayout?: boolean;
}

export const useStyles = makeStyles((theme) => ({
  relativeContainer: {
    position: "relative",
    width: "100%"
  },
  inheritHeightContainer: {
    height: "inherit"
  },
  selectedRow: {
    "& td.bodyCell": {
      backgroundColor: "#F2F2FF !important"
    },
    "& td.bodyCell:first-child": {
      background: "#F2F2FF !important"
    }
  },
  tableFlatContainer: {
    borderRadius: 0,
    "& .headerCell": {
      backgroundColor: "#FFFFFF",
      maxWidth: "25vw",
      minWidth: 180,
      height: 48,
      color: "#415F80",
      fontSize: (props: TableStylesProps) =>
        props?.size === "medium"
          ? theme.typography.body1.fontSize
          : theme.typography.body2.fontSize,
      position: "relative",
      padding: "0px 12px",
      "& span": {
        width: "100%",
        justifyContent: "space-between"
      },
      "&:not(:last-child)": {
        borderRight: "1px solid rgba(203, 210, 214, 0.6)"
      }
    },
    "& .headerCell.customHeader": {
      padding: 0,
      verticalAlign: "top"
    },

    "& .bodyCell": {
      fontSize: 14,
      transition: "all 1s",
      overflowX: "auto",
      borderBottom: "1px solid rgba(203, 210, 214, 0.6)",
      padding: "6px 12px",
      height: 48,
      maxWidth: "25vw",
      color: "#828282",
      "&:not(:last-child)": {
        borderRight: "1px solid rgba(203, 210, 214, 0.6)"
      }
    },
    "& .showHeaderPadding.customHeader": {
      paddingTop: 20,
      "&::before": {
        content: "''",
        display: "block",
        height: 20,
        borderBottom: "1px solid rgba(203, 210, 214, 0.6)",
        width: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        padding: "2px 12px",
        color: "#FFFFFF",
        lineHeight: "16px",
        fontSize: 12
      }
    },
    "& .sourceColumn.customHeader": {
      "&:not(td)": {
        backgroundColor: "rgba(185, 201, 219, 0.1)"
      },
      "&:not(td)::before": {
        content: "'SOURCE'",
        backgroundColor: "#123553"
      },
      "&:not(th)": {
        backgroundColor: "rgba(185, 201, 219, 0.1)"
      }
    },
    "& .newColumn.customHeader": {
      "&:not(td)": {
        backgroundColor: "rgba(229, 153, 130, 0.1)"
      },
      "&:not(th)": {
        backgroundColor: "rgba(229, 153, 130, 0.1)"
      },
      "&:not(td)::before": {
        content: "'NEW COLUMN'",
        backgroundColor: "#f1957d"
      }
    }
  },
  tableDivContainer: {
    overflowY: "auto",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#003656",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  highlightRow: {
    "& td.bodyCell": {
      backgroundColor: "#F2F9FF"
    },
    "& td.bodyCell:first-child": {
      background: "#F2F9FF !important"
    }
  },
  tableContainer: {
    borderLeft: "8px solid white",
    borderRight: "8px solid white",
    borderBottom: "8px solid white",
    "& table": {
      tableLayout: (props: TableStylesProps) => (props?.fixedLayout ? "fixed" : "auto"),
      minHeight: "50px"
    },
    "& .headerCell:hover": {
      background: "#F2F9FF"
    },
    "& .headerCell:first-child:hover": {
      background: "#F2F9FF"
    },
    "& .headerSticky": {
      background: "#FFFFFF",
      zIndex: 3,
      position: "sticky !important",
      top: 0
    },
    "& .headerCell:first-child": {
      background: "#FFFFFF",
      zIndex: 4,
      position: "sticky !important",
      left: 0,
      top: 0
    },
    "& tr .bodyCell:first-child": {
      background: "#FFFFFF",
      zIndex: 2,
      position: "sticky",
      left: 0
    },
    "& .headerCell": {
      maxWidth: "25vw",
      background: "#FFFFFF",
      color: "#2C2E2F",
      fontSize: (props: TableStylesProps) => {
        return props?.size === "medium"
          ? theme.typography.body1.fontSize
          : theme.typography.body2.fontSize;
      },
      padding: (props: TableStylesProps) => {
        return props?.size === "small" ? "12px 24px 11px 16px !important" : "default";
      },
      "&:not(:last-child)": {
        borderRight: "1px solid rgba(203, 210, 214, 0.6)"
      }
    },
    "& tbody tr": {
      "&:last-child .bodyCell": {
        borderBottom: "none"
      },
      "&:hover td.bodyCell": {
        backgroundColor: "#F2F9FF",
        "& .bodyCell:first-child": {
          background: "#F2F9FF"
        },
        "& .cellOptionsIcon": {
          visibility: "visible"
        }
      }
    },
    "& .bodyCell": {
      maxWidth: "25vw",
      fontSize: 14,
      overflowX: "hidden",
      color: "#4A4A4A",
      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      "&:not(:last-child)": {
        borderRight: "1px solid rgba(203, 210, 214, 0.6)"
      },
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      height: "34px",
      padding: (props: TableStylesProps) => {
        return props?.size === "small" ? "4px 16px !important" : "6px 16px";
      }
    }
  },
  recordCount: {
    fontSize: "12px",
    color: "#7a7a7b",
    fontStyle: "italic",
    padding: "2px 0px 8px 0px"
  },
  emptyMessage: {
    display: "flex",
    justifyContent: "center",
    padding: "5px 0",
    fontSize: 14,
    color: theme.palette.primary.light
  },
  actionHeader: {
    width: "100%",
    display: "table-caption",
    borderBottom: "1px solid #cbd2d699",
    padding: "14px",
    borderRadius: "4px 4px 0px 0px"
  },
  maxWidth: {
    flex: 1
  },
  "@keyframes shimmer": {
    "0%": {
      backgroundPosition: "-1000px 0"
    },
    "100%": {
      backgroundPosition: "1000px 0"
    }
  },
  shimmerTdCell: {
    height: "34px",
    width: "100%",
    animation: "$shimmer 2s infinite linear",
    background: "linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)",
    backgroundSize: "1000px 100%",
    borderRight: "1px solid rgba(203, 210, 214, 0.6)",
    borderBottom: "1px solid rgba(203, 210, 214, 0.6)",
    "&:last-child": {
      borderRight: "none"
    }
  },
  paddingCell: {
    fontSize: 14,
    padding: (props: TableStylesProps) => {
      return props?.size === "small" ? "4px 16px !important" : "6px 16px";
    }
  },
  paddingBottomCell: {
    verticalAlign: "top"
  },
  paddingTopCell: {
    verticalAlign: "bottom"
  }
}));

export const StyledMaUTable = withStyles((theme) => ({
  root: {
    maxWidth: "100%",
    "& caption": {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
    }
  }
}))(MaUTable);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    transition: "all .5s",
    "& td": {
      whiteSpace: "pre-wrap"
    },
    "& th span": {
      minHeight: 24,
      verticalAlign: "middle"
    },
    "& th span[class~='MuiTableSortLabel-root']": {
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  },
  selected: {
    "& td": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText
    },
    "& a": {
      color: theme.palette.primary.contrastText,
      pointerEvents: "none"
    }
  }
}))(TableRow);
