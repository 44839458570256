import { ProjectRunDetailDto } from "@rapidcanvas/rc-api-core";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getJobsDashboardWithRethrow, getJobsWithRethrow } from "src/api/projects";

export const enum UseGetJobsDashboardQueryKeys {
  JobsDashboard = "jobs-dashboard"
}
interface JobsProps extends UseQueryOptions<$TSFixMe> {
  projectId?: string;
  shouldRefreshJobsGetter?: $TSFixMe;

  // useQuery options
  cacheTime?: number;
  enabled?: boolean;
  refetchOnMount?: boolean;
}

export const useGetJobsDashboard = (
  options?: UseQueryOptions<ProjectRunDetailDto[]>
): UseQueryResult<ProjectRunDetailDto[]> =>
  useQuery({
    queryKey: [UseGetJobsDashboardQueryKeys.JobsDashboard],
    queryFn: async () => await getJobsDashboardWithRethrow(),
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    ...options
  });

export const useGetJobs = (props: JobsProps): UseQueryResult<$TSFixMe> => {
  const {
    projectId,
    shouldRefreshJobsGetter,

    // useQuery options
    cacheTime = 0,
    ...options
  } = props;

  return useQuery({
    queryKey: ["jobs", projectId],
    queryFn: async () => await getJobsWithRethrow(projectId),
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    cacheTime,
    enabled: !!projectId || (!!projectId && !!shouldRefreshJobsGetter),
    ...options
  });
};
