import React, { useCallback, useEffect, useMemo, useState } from "react";

// Packages
import { useParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { Id, toast } from "react-toastify";
import shallow from "zustand/shallow";
import { delay, isEmpty } from "lodash";

// MUI
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import { RunIcon } from "src/icons/NewUX/RunIcon";
import { ArrowDownIcon } from "src/icons/NewUX/ArrowDownIcon";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { isConnectorDatasets as isConnectorDatasetsHelper } from "../../utils";

// Open API
import { ProjectCanvasDto } from "@rapidcanvas/rc-api-core";

// Store
import { useCanvasStore } from "src/store/store";

// Hooks
import {
  clearRunTransformGroupCache,
  useGetProjectCanvasLite,
  UseGetProjectCanvasQueryKeys,
  useRunProject,
  useRunProjectWithReloadDatasets
} from "src/hooks/api";
import { UseGetRecipeRunsQueueQueryKeys } from "src/hooks/api/recipes";
import { UseRunProjectQueryKeys } from "src/hooks/api/projects/useRunProject";

// Components
import ConfirmRunWithReloadDatasets from "../ConfirmRunWithReloadDatasets";

// Constants
import { RunOptionsHelperText } from "./RunOptions.constants";

interface IProps {
  disabledMenuActionMessage?: string;
  disabledMenuItemActionMessage?: string;
}

const RunOptions: React.FC<IProps> = (props) => {
  const {
    disabledMenuActionMessage: inputDisabledMenuActionMessage,
    disabledMenuItemActionMessage: inputDisabledMenuItemActionMessage
  } = props;

  const { projectId, scenarioId } = useParams();

  const queryClient = useQueryClient();

  // States - STARTS >>
  const [isConnectorDatasets, setIsConnectorDatasets] = useState(false);
  const [showConfirmRunWithReloadDatasetsModal, setShowConfirmRunWithReloadDatasetsModal] =
    useState(false);
  // << ENDS - States

  // Stores - STARTS >>
  const [
    isRecipesRunningAcrossScenariosStore,
    setIsRunRequestPendingStore,
    setReloadTriggerWithDelayStore,
    setReloadTriggerStore
  ] = useCanvasStore(
    (state) => [
      state.isRecipesRunningAcrossScenarios,
      state.setIsRunRequestPending,
      state.setReloadTriggerWithDelay,
      state.setReloadTrigger
    ],
    shallow
  );
  // ENDS - Stores

  // Query hooks - STARTS >>
  // Queries
  const { isLoading: isFetchingProjectCanvas, refetch: refetchProjectCanvas } =
    useGetProjectCanvasLite({
      projectId,
      scenarioId,
      onSuccess: (data) => {
        setIsConnectorDatasets(() => isConnectorDatasetsHelper(data));
      },
      enabled: false,
      initialData: null
    });

  // Mutations
  const {
    isLoading: isRunInProgress,
    mutateAsync: runMutation,
    reset: resetRunMutation
  } = useRunProject({ projectId, scenarioId });

  const pendingRunMutations = useIsMutating({
    mutationKey: [UseRunProjectQueryKeys.RunProject, projectId, scenarioId],
    exact: true,
    fetching: true
  });

  const isRunning = useMemo(
    () => !!isRunInProgress || pendingRunMutations > 0,
    [isRunInProgress, pendingRunMutations]
  );

  const {
    isLoading: isRunWithReloadDatasetsInProgress,
    mutateAsync: runWithReloadDatasetsMutation,
    reset: resetRunWithReloadDatasetsMutation
  } = useRunProjectWithReloadDatasets({ projectId, scenarioId });

  const pendingRunWithReloadDatasetsMutations = useIsMutating({
    mutationKey: [UseRunProjectQueryKeys.RunProjectWithReloadDatasets, projectId, scenarioId],
    exact: true,
    fetching: true
  });

  const isRunningWithReloadDatasets = useMemo(
    () => !!isRunWithReloadDatasetsInProgress || pendingRunWithReloadDatasetsMutations > 0,
    [isRunWithReloadDatasetsInProgress, pendingRunWithReloadDatasetsMutations]
  );
  // << ENDS - Query hooks

  useEffect(() => {
    if (!!projectId && !!scenarioId) {
      const data: ProjectCanvasDto | undefined = queryClient.getQueryData([
        UseGetProjectCanvasQueryKeys.ProjectCanvasLite,
        projectId,
        scenarioId
      ]);

      if (isEmpty(data)) {
        refetchProjectCanvas();
      } else {
        setIsConnectorDatasets(() => isConnectorDatasetsHelper(data));
      }
    }
  }, [projectId, scenarioId]);

  // Drop-down code - STARTS >>
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event: $TSFixMe) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  // ENDS - Drop-down

  const runProjectWithReloadDatasets = async (toastId: Id | undefined) => {
    await resetRunWithReloadDatasetsMutation();
    runWithReloadDatasetsMutation(
      { projectId, scenarioId, reloadDatasets: true },
      {
        onSuccess: async () => {
          await clearRunTransformGroupCache(queryClient);
          setReloadTriggerStore();
        },
        onError: () => {
          toast.dismiss(toastId);
        },
        onSettled: () => {
          setIsRunRequestPendingStore(false);
        }
      }
    );
  };

  const onRun = async (reloadDatasets = false) => {
    if (!projectId || !scenarioId) {
      return;
    }

    checkEnvRelaunch(projectId);
    setIsRunRequestPendingStore(true);

    const toastId = toastWrapper({
      type: ToastTypes.Info,
      content: RunOptionsHelperText.RecipesRunningToasterInfo
    });

    delay(
      // This is to update a flag in canvas-store for any recipe running in the project across the scenarios.
      // Delayed execution as this is dependent upon execution of projects-run.
      () => queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]),
      250
    );

    setReloadTriggerWithDelayStore();

    if (!!reloadDatasets) {
      runProjectWithReloadDatasets(toastId);
      return;
    }

    await resetRunMutation();
    runMutation(
      { projectId, scenarioId, reloadDatasets },
      {
        onSuccess: async () => {
          await clearRunTransformGroupCache(queryClient);
          setReloadTriggerStore();
        },
        onError: () => {
          toast.dismiss(toastId);
        },
        onSettled: () => {
          setIsRunRequestPendingStore(false);
        }
      }
    );
  };

  // Confirm run with reload datasets - STARTS >>
  const promptConfirmRunWithReloadDatasets = () => {
    setShowConfirmRunWithReloadDatasetsModal(() => true);
  };

  const resetConfirmRunWithReloadDatasets = () => {
    setShowConfirmRunWithReloadDatasetsModal(() => false);
  };

  const confirmRunWithReloadDatasets = () => {
    resetConfirmRunWithReloadDatasets();
    onRun(true);
  };
  // << ENDS - Confirm run with reload datasets

  const disabledMenuActionMessage = useMemo(() => {
    if (!!inputDisabledMenuActionMessage) {
      return inputDisabledMenuActionMessage;
    }

    // if (!!isRunning || !!isRunningWithReloadDatasets) {
    //   return "";
    // }

    if (!!isRecipesRunningAcrossScenariosStore) {
      return RunOptionsHelperText.RecipesRunningInfo;
    }

    return "";
  }, [
    inputDisabledMenuActionMessage,
    // isRunning,
    // isRunningWithReloadDatasets,
    isRecipesRunningAcrossScenariosStore
  ]);

  const disabledRunActionMessage = useMemo(() => {
    if (!!inputDisabledMenuItemActionMessage) {
      return inputDisabledMenuItemActionMessage;
    }

    if (!!isRunning || !!isRunningWithReloadDatasets) {
      return RunOptionsHelperText.RecipesRunningInfo;
    }

    return "";
  }, [inputDisabledMenuItemActionMessage, isRunning, isRunningWithReloadDatasets]);

  const disabledRunWithReloadDatasetsActionMessage = useMemo(() => {
    if (!!inputDisabledMenuItemActionMessage) {
      return inputDisabledMenuItemActionMessage;
    }

    if (!!isFetchingProjectCanvas) {
      return RunOptionsHelperText.DataFetchingInfo;
    }

    if (!isConnectorDatasets) {
      return RunOptionsHelperText.ConnectorDatasetsInfo;
    }

    if (!!isRunning || !!isRunningWithReloadDatasets) {
      return RunOptionsHelperText.RecipesRunningInfo;
    }

    return "";
  }, [
    inputDisabledMenuItemActionMessage,
    isFetchingProjectCanvas,
    isConnectorDatasets,
    isRunning,
    isRunningWithReloadDatasets
  ]);

  const menuItems = useMemo(
    () => [
      <Tooltip key="run" title={disabledRunActionMessage || RunOptionsHelperText.RunActionInfo}>
        <span>
          <MenuItem
            disabled={!!disabledRunActionMessage}
            onClick={() => {
              onRun();
            }}>
            <ListItemText
              primary={RunOptionsHelperText.RunMenuItemActionLabel}
              primaryTypographyProps={{
                variant: "body2"
              }}
            />
            {!!isRunning && (
              <ListItemSecondaryAction>
                <CircularProgress size={16} />
              </ListItemSecondaryAction>
            )}
          </MenuItem>
        </span>
      </Tooltip>,

      <Tooltip
        key="runWithReloadDatasets"
        title={
          disabledRunWithReloadDatasetsActionMessage ||
          RunOptionsHelperText.RunWithReloadDatasetsActionInfo
        }>
        <span>
          <MenuItem
            disabled={!!disabledRunWithReloadDatasetsActionMessage}
            onClick={() => {
              promptConfirmRunWithReloadDatasets();
            }}>
            <ListItemText
              primary={RunOptionsHelperText.RunWithReloadDatasetsMenuItemActionLabel}
              primaryTypographyProps={{
                variant: "body2"
              }}
            />
            {!!isRunningWithReloadDatasets && (
              <ListItemSecondaryAction>
                <CircularProgress size={16} />
              </ListItemSecondaryAction>
            )}
          </MenuItem>
        </span>
      </Tooltip>
    ],
    [
      projectId,
      scenarioId,
      disabledRunActionMessage,
      disabledRunWithReloadDatasetsActionMessage,
      isRunning,
      isRunningWithReloadDatasets
    ]
  );

  return (
    <>
      {showConfirmRunWithReloadDatasetsModal && (
        <ConfirmRunWithReloadDatasets
          resetConfirmRunWithReloadDatasets={resetConfirmRunWithReloadDatasets}
          runWithReloadDatasets={confirmRunWithReloadDatasets}
        />
      )}

      <Tooltip title={disabledMenuActionMessage || ""}>
        <span>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={!!disabledMenuActionMessage}
            onClick={onOpen}
            startIcon={<RunIcon />}
            endIcon={<ArrowDownIcon />}>
            <Box fontSize="small">{RunOptionsHelperText.RunMenuActionLabel}</Box>
          </Button>
        </span>
      </Tooltip>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={onClose}
        PaperProps={{
          style: {
            marginTop: 6,
            width: 225,
            borderRadius: 12
          }
        }}>
        {menuItems}
      </Menu>
    </>
  );
};

export default RunOptions;
