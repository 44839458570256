import React, { useState, useEffect } from "react";
import _ from "lodash";
import { TextField, Typography, makeStyles } from "@material-ui/core";

import DefaultButton from "src/components/Buttons/DefaultButton";
import NewModal from "src/components/Modal/NewModal";
import { CodeEditor } from "src/components";
import { CodeIcon } from "src/icons/CodeIcon";
import { PSFields } from "../utils/PredictionService.constants";
import { handleResponse } from "src/utils/apiService";

interface IProps {
  value: string;
  onSubmit: (value: string) => void;
}

const useStyles = makeStyles({
  codeWrapper: {
    width: "100%",
    border: "1px solid rgba(199, 199, 199, 1)",
    borderRadius: "6px",
    overflow: "hidden"
  },
  input: {
    "& input": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    }
  }
});

const PrePostProcess: React.FC<IProps> = (props) => {
  const { value, onSubmit } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [editorValue, setEditorValue] = useState(value);

  useEffect(() => {
    if (!_.isEqual(editorValue, value)) {
      setEditorValue(value);
    }
  }, [value]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onSubmit(editorValue);
    setOpen(false);
  };

  const handleReset = () => {
    setEditorValue(value);
    handleResponse({ successMessage: "Code restored to its previous state" });
  };

  const handleChange = (__: string, val: string) => {
    setEditorValue(val);
  };

  return (
    <>
      <TextField
        disabled
        fullWidth
        id={PSFields.prePostProcess.id}
        label={PSFields.prePostProcess.label}
        name={PSFields.prePostProcess.id}
        size="small"
        className={classes.input}
        test-id="pre-post-process"
        value={value}
        variant="outlined"
        InputLabelProps={{
          shrink: !!value
        }}
        InputProps={{
          endAdornment: (
            <DefaultButton
              variant="outlined"
              color="primary"
              size="small"
              test-id="pre-post-process-code-btn"
              startIcon={
                <Typography color="primary">
                  <CodeIcon />
                </Typography>
              }
              onClick={handleClick}
              // New UX change
              // Can be removed soon.
              isCustomTheming={false}
              style={{
                minWidth: 100
              }}>
              {value ? "Edit Code" : "Add Code"}
            </DefaultButton>
          )
        }}
      />
      {open && (
        <NewModal
          header="Code Editor"
          open={open}
          width={980}
          height={605}
          keepMounted
          cancelButtonLabel="Reset"
          submitButtonLabel="Close"
          cancelTooltip="Restores the code to its previous state"
          onFinish={handleClose}
          onClose={handleClose}
          onCancel={handleReset}
          // New UX change
          // Can be removed soon.
          isDefaultButtonCustomTheming={false}>
          <div className={classes.codeWrapper}>
            <CodeEditor
              name="editor"
              defaultScriptType="PYTHON"
              scriptType="PYTHON"
              theme="vs"
              height="calc(100vh - 300px)"
              hideTestCode={true}
              value={editorValue}
              onChange={handleChange}
            />
          </div>
        </NewModal>
      )}
    </>
  );
};

export default PrePostProcess;
