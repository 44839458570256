import api from "utils/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { CreateApiAccessKeyDto } from "@rapidcanvas/rc-api-core";

interface IVariable {
  createApikeyPayload: CreateApiAccessKeyDto;
}

const useCreateApiKey = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ createApikeyPayload }) => {
      return await api.fetchResponse(() =>
        api.ApiAccessKeyControllerApi.createApiAccessKey(createApikeyPayload)
      );
    }
  });
};

export default useCreateApiKey;
