import React from "react";
import _ from "lodash";
import { InfoOutlined, OpenInNew } from "@material-ui/icons";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  makeStyles
} from "@material-ui/core";

import {
  DataappAskAIConfigInputTypeEnum,
  DataappAskAIConfigLlmTypeEnum
} from "@rapidcanvas/rc-api-core";
import { dataAppConfigFields } from "./CreateDataAppForm";

const useStyles = makeStyles({
  link: {
    color: ({ disabled }: { disabled?: boolean }) => (disabled ? "#00000061" : "#003656"),

    "&:hover": {
      textDecoration: "none"
    }
  },
  flex: {
    display: "flex",
    gap: "8px",
    alignItems: "center"
  },
  icons: {
    fontSize: "1rem",
    marginBottom: "2px",
    color: "#8a8989"
  }
});

interface IProps {
  readOnly?: boolean;
  value: DataappAskAIConfigLlmTypeEnum;
  inputType?: DataappAskAIConfigInputTypeEnum;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectAskAILLMModel: React.FC<IProps> = (props) => {
  const { link, flex, icons } = useStyles({ disabled: props.readOnly });

  return (
    <FormControl component="fieldset" disabled={props.readOnly}>
      <FormLabel component="legend">Models</FormLabel>
      <RadioGroup
        row={props.readOnly}
        aria-label={dataAppConfigFields.llmType}
        name={dataAppConfigFields.llmType}
        value={props.value}
        data-testid="dataAppConfigLlmType"
        onChange={props.onChange}>
        <FormControlLabel
          value={DataappAskAIConfigLlmTypeEnum.OpenaiGpt4O}
          control={<Radio />}
          label={
            <div className={flex}>
              <span>OpenAI GPT-4o</span>
              <a
                className={link}
                href="https://platform.openai.com/docs/models/gpt-4o"
                target="_blank"
                rel="noreferrer">
                <OpenInNew className={icons} />
              </a>
              <Tooltip title="This is recommended for better accuracy of responses">
                <InfoOutlined className={icons} />
              </Tooltip>
            </div>
          }
        />
        <FormControlLabel
          value={DataappAskAIConfigLlmTypeEnum.OpenaiGpt4Turbo}
          control={<Radio />}
          label={
            <div className={flex}>
              <span>OpenAI GPT-4 Turbo</span>
              <a
                className={link}
                href="https://platform.openai.com/docs/models/gpt-4-turbo-and-gpt-4"
                rel="noreferrer"
                target="_blank">
                <OpenInNew className={icons} />
              </a>
            </div>
          }
        />
        {props.inputType !== DataappAskAIConfigInputTypeEnum.RagFiles && (
          <FormControlLabel
            value={DataappAskAIConfigLlmTypeEnum.AzureOpenaiGpt4O}
            control={<Radio />}
            label={
              <div className={flex}>
                <span>Azure OpenAI GPT-4o</span>
                <a
                  className={link}
                  href="https://learn.microsoft.com/en-us/azure/ai-services/openai/concepts/models#gpt-4o-and-gpt-4-turbo"
                  rel="noreferrer"
                  target="_blank">
                  <OpenInNew className={icons} />
                </a>
              </div>
            }
          />
        )}
        {props.inputType !== DataappAskAIConfigInputTypeEnum.RagFiles && (
          <FormControlLabel
            value={DataappAskAIConfigLlmTypeEnum.AnthropicClaude35Sonnet}
            control={<Radio />}
            label={
              <div className={flex}>
                <span>Anthropic Claude 3.5 Sonnet</span>
                <i>(beta)</i>
                <a
                  className={link}
                  href="https://docs.anthropic.com/en/docs/intro-to-claude"
                  rel="noreferrer"
                  target="_blank">
                  <OpenInNew className={icons} />
                </a>
                <Tooltip title="Integration with Claude is currently in beta stage. Responses might be slower and incorrect">
                  <InfoOutlined className={icons} />
                </Tooltip>
              </div>
            }
          />
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default SelectAskAILLMModel;
