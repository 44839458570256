import { EnvUsageDto, ProjectDashboardDto } from "@rapidcanvas/rc-api-core";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useGetProjects } from "src/hooks/api";
import { useProjectsStore } from "src/store/store";
import { projectsGetter, projectsSetter } from "src/store/store.selectors";
import { Project } from "./EnvironmentTabs";

interface IProps {
  envUsage: EnvUsageDto[];
  isFetchedEnvUsage: boolean;
}

export const useExtractProjects = ({ isFetchedEnvUsage, envUsage }: IProps) => {
  const [envProjects, setEnvProjects] = useState<
    Record<string, { jobsCount: number; predictionJobsCount: number }>
  >({});

  const projects = useProjectsStore(projectsGetter) as ProjectDashboardDto[];
  const setProjects = useProjectsStore(projectsSetter);

  const { data, isLoading: isLoadingProjects } = useGetProjects({
    enabled: !projects
  });

  useEffect(() => {
    if (data) {
      setProjects(data);
    }
  }, [data]);

  useEffect(() => {
    if (!isFetchedEnvUsage || _.isEmpty(envUsage)) return;

    // Extract projects from envUsage
    const thisProjects: Record<string, { jobsCount: number; predictionJobsCount: number }> = {};

    envUsage?.forEach((thisEnv: any) => {
      if (!!thisEnv?.projectName) {
        const predictionJobsCount = _.size(
          _.filter(thisEnv?.projectRunList, ({ jobType }: any) => jobType === "PREDICTION_JOB")
        );
        const jobsCount = (thisEnv?.projectRunList?.length || 0) - predictionJobsCount;

        if (_.has(thisProjects, thisEnv?.projectName)) {
          const old = _.get(thisProjects, [thisEnv?.projectName, "jobsCount"]) as number;
          thisProjects[thisEnv?.projectName] = {
            jobsCount: Math.max(old, jobsCount),
            predictionJobsCount: Math.max(
              _.get(thisProjects, [thisEnv?.projectName, "predictionJobsCount"]) as number,
              predictionJobsCount
            )
          };
        } else {
          thisProjects[thisEnv?.projectName] = { jobsCount, predictionJobsCount };
        }
      }
    });
    setEnvProjects(thisProjects);
  }, [isFetchedEnvUsage, envUsage]);

  const filteredProjects: Project[] = useMemo(() => {
    const projectNames = Object.keys(envProjects);

    return _.map(
      _.filter(projects, (project) => projectNames.includes(project.name!)),
      (project) => ({
        ...project,
        jobCount: envProjects[project.name!]?.jobsCount,
        predictionJobCount: envProjects[project.name!]?.predictionJobsCount
      })
    );
  }, [projects, envProjects]);

  return {
    projects: filteredProjects,
    isLoadingProjects
  };
};
