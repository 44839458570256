import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import _, { last } from "lodash";
import { Badge, Button, Tooltip, Typography } from "@material-ui/core";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import shallow from "zustand/shallow";
import { StackIcon } from "src/icons/NewUX";

import AddMenu from "./AddMenu";
import CanvasSearch from "./CanvasSearch";
import MoreOptionsMenu from "./MoreOptionsMenu";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import useRelaunchEnvironment from "hooks/api/environments/useRelaunchEnvironment";
import { AddArtifactsModal } from "../common/AddArtifactsModal";
import { AddModelsModal } from "../common/AddModelsModal";
import { PublishJobsModal } from "./PublishJobsModal";
import { ScenarioSettings } from "../../../icons/ScenarioSettings";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";
import { createEntityWithRethrow } from "src/api";
import { handleResponse } from "../../../utils/apiService";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { useScenariosStore, useCanvasStore } from "../../../store/store";
import { WebPaths } from "src/routing/routes";
import { RunOptions } from "src/pages/private/ProjectsModule/components";

const CanvasFlowHeader = (props: $TSFixMe) => {
  const {
    project,
    publishJobsProps,
    onAddDatasetAction,
    onRecipeRunsPopoverAction,
    setIsFromArtifactsModelModal
  } = props || {};
  const {
    hidePublishJobs = false,
    jobsData,
    showPublishJobsModal,
    setShowPublishJobsModal,
    publishJobs,
    scenarioId
  } = publishJobsProps || {};

  const { projectId } = useParams();
  const relaunch = useRelaunchEnvironment();

  useEffect(() => {
    if (project?.envId) {
      relaunch.mutate({ envId: project?.envId });
    }
  }, [project?.envId]);

  const [
    setNodeToFocus,
    setReloadTrigger,
    openRecipeRunsQueueStore,
    isPendingRecipeRunsInQueueStore
  ] = useCanvasStore(
    (state) => [
      state.setNodeToFocus,
      state.setReloadTrigger,
      state.openRecipeRunsQueue,
      state.isPendingRecipeRunsInQueue
    ],
    shallow
  );

  const { canRunProject, canViewScenarios, canAddStandardRecipe, canAddArtifacts, canAddModels } =
    useAccessControlContext();
  const navigate = useNavigate();

  const recipeRunsPopoverActionRef = useRef<HTMLButtonElement>(null);
  const [isAddArtifactModalOpen, setIsAddArtifactModalOpen] = useState(false);
  const [isAddModelsModalOpen, setIsAddModelsModalOpen] = useState(false);
  // const [searchValue, setSearchValue] = useState("");

  const [scenarios] = useScenariosStore((state) => [state.scenarios]);
  const nodes = useCanvasStore((state) => state.nodes);

  const { dfsGroupNodes, entityNodes, artifactNodes, modelNodes } = useMemo(() => {
    const groupBy = _.groupBy(nodes, (node) => (node as $TSFixMe)?.type?.toLowerCase());

    return {
      dfsGroupNodes: _.get(groupBy, "dfsgroup", []),
      entityNodes: _.get(groupBy, "entity", []),
      artifactNodes: _.get(groupBy, "artifact", []),
      modelNodes: _.get(groupBy, "model", [])
    };
  }, [nodes]);

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(null, scenarios, scenarioId),
    [scenarioId, scenarios]
  );

  const handleAddRecipeClick = useCallback(() => {
    sessionStorage.removeItem("configGroupId");
    navigate(`/projects/${projectId}/scenario/${scenarioId}/add-recipe`);
  }, [scenarioId, projectId]);

  const handleAddCodeRecipeClick = useCallback(() => {
    sessionStorage.removeItem("configGroupId");
    navigate(`/projects/${projectId}/scenario/${scenarioId}/add-code-recipe`);
  }, [scenarioId, projectId]);

  const handleAddAutoAiRecipeClick = useCallback(() => {
    sessionStorage.removeItem("configGroupId");
    navigate(`/projects/${projectId}/scenario/${scenarioId}/add-auto-ml-recipe`);
  }, [scenarioId, projectId]);

  const handleAddApiConnectorRecipe = () => {
    if (projectId && scenarioId) {
      navigate(generatePath(WebPaths.APIConnectorRecipeContainer, { projectId, scenarioId }));
    }
  };

  const handleScenarioSettings = useCallback(() => {
    if (!projectId || !scenarioId) {
      return;
    }

    navigate(generatePath(WebPaths.Scenario, { projectId, scenarioId }));
  }, [scenarioId, projectId]);

  const selectedEntitiesStr = sessionStorage.getItem("selectedEntities");
  const selectedEntities = selectedEntitiesStr ? JSON.parse(selectedEntitiesStr) : null;

  const createEntities = React.useCallback(
    async (entityNames: Array<string>, entityViewType: string) => {
      const entityBody = {
        entityMeta: {
          entityViewType,
          entityType: "EVENT"
        },
        projectId
      };
      try {
        const response = await Promise.all(
          entityNames.map(async (artifactName: string) => {
            return await createEntityWithRethrow({
              ...entityBody,
              name: artifactName
            });
          })
        );

        setReloadTrigger();

        const lastCreatedEntityName = last(response)?.name;
        if (!!lastCreatedEntityName) {
          setNodeToFocus(lastCreatedEntityName);
          setIsFromArtifactsModelModal(() => true);
        }
      } catch (error: $TSFixMe) {
        handleResponse({
          errorMessage:
            error.response?.data?.msg ||
            error.message ||
            `Error in adding ${entityViewType === "ARTIFACT" ? "artifacts" : "models"}`
        });
      }
    },
    [projectId, setReloadTrigger]
  );

  const onAddArtifacts = React.useCallback(
    async (artifactNames: Array<string>) => {
      const existingArtifactNames = artifactNodes?.map((artifact: $TSFixMe) => artifact.name);
      const newArtifacts = artifactNames.filter((name) => !existingArtifactNames.includes(name));
      await createEntities(newArtifacts, "ARTIFACT");
      setIsAddArtifactModalOpen(false);
    },
    [artifactNodes, createEntities]
  );

  const onAddModels = React.useCallback(
    async (modelNames: Array<string>) => {
      const existingModelNames = modelNodes?.map((model: $TSFixMe) => model.name);
      const newModels = modelNames.filter((name) => !existingModelNames.includes(name));
      await createEntities(newModels, "MODEL");
      setIsAddModelsModalOpen(false);
    },
    [createEntities, modelNodes]
  );

  useLayoutEffect(() => {
    if (!!openRecipeRunsQueueStore && !!recipeRunsPopoverActionRef?.current) {
      const event = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      });

      recipeRunsPopoverActionRef?.current?.dispatchEvent(event);
    }
  }, [openRecipeRunsQueueStore, recipeRunsPopoverActionRef.current]);

  React.useEffect(() => () => setNodeToFocus(""), []);

  const currentScenario = useMemo(
    () => scenarios?.find((scenario) => (scenario as $TSFixMe).id === scenarioId) || {},
    [scenarioId, scenarios]
  );

  return (
    <>
      <AddArtifactsModal
        open={isAddArtifactModalOpen}
        selectedArtifacts={artifactNodes}
        shouldDisableInitialSelectedRows
        onAddArtifacts={onAddArtifacts}
        onClose={() => setIsAddArtifactModalOpen(false)}
      />

      <AddModelsModal
        open={isAddModelsModalOpen}
        onAddModels={onAddModels}
        selectedModels={modelNodes}
        shouldDisableInitialSelectedRows
        onClose={() => setIsAddModelsModalOpen(false)}
      />

      {showPublishJobsModal && (
        <PublishJobsModal
          handleClose={() => setShowPublishJobsModal(() => false)}
          handleSubmit={() => publishJobs()}
          publishJobsProps={publishJobsProps}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: (
            <Typography variant="body2" color="textPrimary">
              {project?.name}
            </Typography>
          )
        }}
        subTopNavBarRightSection={{
          component: (
            <>
              <CanvasSearch setNodeToFocus={setNodeToFocus} nodes={nodes} />

              <Tooltip title="Recipe Queue">
                <Button
                  size="small"
                  color="primary"
                  ref={recipeRunsPopoverActionRef}
                  onClick={onRecipeRunsPopoverAction}>
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={!isPendingRecipeRunsInQueueStore}
                    overlap="circular"
                    badgeContent=" ">
                    <Typography color="textPrimary">
                      <StackIcon />
                    </Typography>
                  </Badge>
                </Button>
              </Tooltip>

              {canRunProject && (
                <RunOptions
                  disabledMenuActionMessage={dfsGroupNodes.length < 1 ? "No recipes to run" : ""}
                />
              )}

              {isDefaultScenario ? (
                <AddMenu
                  // Datasets
                  canAddArtifacts={canAddArtifacts}
                  canAddModels={canAddModels}
                  addDataset={onAddDatasetAction}
                  addArtifacts={() => setIsAddArtifactModalOpen(true)}
                  addModel={() => setIsAddModelsModalOpen(true)}
                  // Recipes
                  isAddTemplateRecipeDisabled={entityNodes.length < 1}
                  isAddAiAssistedRecipeDisabled={entityNodes.length < 1}
                  isAddRapidModelRecipeDisabled={
                    entityNodes.length < 1 || selectedEntities?.length > 1
                  }
                  canAddStandardRecipe={canAddStandardRecipe}
                  addTemplateRecipe={handleAddRecipeClick}
                  addAiAssistedRecipe={handleAddCodeRecipeClick}
                  addRapidModelRecipe={handleAddAutoAiRecipeClick}
                  onAddApiConnectorRecipe={handleAddApiConnectorRecipe}
                />
              ) : (
                <Button
                  color="primary"
                  size="small"
                  startIcon={<ScenarioSettings width={18} height={18} viewBox="0 0 24 24" />}
                  onClick={handleScenarioSettings}>
                  Scenario Settings
                </Button>
              )}

              {canViewScenarios && (
                <MoreOptionsMenu
                  menuActionLabel="Actions"
                  projectId={projectId}
                  scenarios={scenarios}
                  currentScenario={currentScenario}
                  isPublishJob={!hidePublishJobs && (jobsData || [])?.length > 0}
                  publishJob={() => setShowPublishJobsModal(true)}
                />
              )}
            </>
          )
        }}
      />
    </>
  );
};

export default CanvasFlowHeader;
