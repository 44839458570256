// Retaining commented redux logic as a reference for implementing future features.
// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
// import { useDispatch } from "react-redux";
import {
  isArray
  //  sortBy, toLower
} from "lodash";

// Utils
import api from "utils/AxiosClient";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Stores
// import { setEnvironments } from "src/stores/environmentsSlice";

export const enum UseGetEnvironmentsQueryKeys {
  Environments = "environments"
}

interface IEnvironmentsProps extends UseQueryOptions<unknown, unknown, EnvDto[]> {}

const useGetEnvironments = (props: IEnvironmentsProps): UseQueryResult<EnvDto[]> => {
  const { ...options } = props;

  // const dispatch = useDispatch();

  return useQuery({
    queryKey: [UseGetEnvironmentsQueryKeys.Environments],
    queryFn: async () => await api.fetchResponse(async () => await api.EnvControllerApi.findEnvs()),
    select: (data) => (isArray(data) ? data : []),
    // onSuccess: (data) => dispatch(setEnvironments(sortBy(data, (item) => toLower(item?.name)))),
    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely
    ...options
  });
};

export default useGetEnvironments;
