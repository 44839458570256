// Packages
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

// Open API
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Hooks
import {
  updateEnvsQueryDataById,
  usePollEnvironment
} from "src/hooks/api/environments/usePollEnvironments";

// Stores
import {
  EnvironmentActionsStatusEnum,
  resetEnvironmentStatus,
  setEnvironmentStatus
} from "src/stores/environmentsSlice";

// Styles
import { useRelaunchEnvironment, useStopEnvironment } from "src/hooks/api";

interface IProps {
  environment: EnvDto;
}

const useStatus = (props: IProps) => {
  const { environment } = props;
  const { id, launchStatus: status } = environment;

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  usePollEnvironment({ id, status, notifyOnRelaunchFailed: true });

  const resetEnvStopping = ({ id, status }: { id: string; status: EnvDtoLaunchStatusEnum }) => {
    updateEnvsQueryDataById(queryClient, id, status);
    dispatch(resetEnvironmentStatus({ id: id! }));
  };

  const dispatchEnvStopping = ({ id, status }: { id: string; status: EnvDtoLaunchStatusEnum }) => {
    updateEnvsQueryDataById(queryClient, id, status);
    dispatch(setEnvironmentStatus({ id: id!, status: EnvironmentActionsStatusEnum.Updating }));
  };

  // Query hooks - STARTS >>
  // Mutations
  const { mutateAsync: relaunchMutation, reset: resetRelaunchMutation } = useRelaunchEnvironment();
  const { mutateAsync: stopMutation, reset: resetStopMutation } = useStopEnvironment();
  // << ENDS - Query hooks

  // Relaunch environment - STARTS >>
  const onRelaunch = async (envId?: string) => {
    const id = envId;

    if (!environment || !id) return;

    dispatch(setEnvironmentStatus({ id: envId!, status: EnvironmentActionsStatusEnum.Updating }));
    updateEnvsQueryDataById(queryClient, id, EnvDtoLaunchStatusEnum.Launching);

    resetRelaunchMutation();
    await relaunchMutation({ id, environment });
  };
  // << ENDS - Relaunch environment

  // Stop environment - STARTS >>
  const onStop = async (envId?: string) => {
    const id = envId;

    if (!id) return;

    resetStopMutation();
    await stopMutation({ id });
  };

  // << ENDS - Stop environment

  return { onRelaunch, onStop, dispatchEnvStopping, resetEnvStopping };
};

export default useStatus;
