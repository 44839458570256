import {
  AIChatRequestDtoOutputTypeEnum,
  AIChatRequestDtoUseCaseEnum,
  AIChatResponseDto,
  AIChatResponseDtoUseCaseEnum,
  InputIdDtoTypeEnum,
  ThreadResponseDto,
  ThreadResponseDtoDatasetContextEnum,
  ThreadResponseDtoTargetTypeEnum
} from "@rapidcanvas/rc-api-core";
import { useQueryClient } from "@tanstack/react-query";
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";

import { Spinner } from "src/components";
import {
  AI_GUIDE_MESSAGES_KEY,
  useAutoGenerateSuggestionsMutation,
  useSaveAIGuideChatMutation
} from "src/hooks/api";
import { GeneratingState } from "src/store/store";
import AiGuideConvoGrid from "./AIGuideConvoGrid";
import { STOPPED_QUERY_ID } from "./AIGuideResponse";

export const ERRORED_QUERY_ID = "errored_query";

type IProps = {
  isLoading: boolean;
  query: string;
  setQuery: (query: string) => void;
  targetType?: keyof typeof ThreadResponseDtoTargetTypeEnum | null;
  threadId: string;
  messages: AIChatResponseDto[];
  thread: ThreadResponseDto;
  generateState: GeneratingState;
  columns?: string[];
  projectId: string;
};

const AIGuideContainer: React.FC<PropsWithChildren<IProps>> = ({
  isLoading,
  query,
  setQuery,
  children,
  thread,
  messages,
  generateState,
  columns,
  projectId
}) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const { threadId, targetInputs, datasetContext, targetType } = thread;
  const saveAIGuideChatMutation = useSaveAIGuideChatMutation(projectId);
  const isStopped = useRef<boolean>(false);
  const controllerRef = useRef<AbortController | null>(null);
  const useCase =
    datasetContext === ThreadResponseDtoDatasetContextEnum.Dataset
      ? AIChatRequestDtoUseCaseEnum.ConversationUseCase
      : AIChatRequestDtoUseCaseEnum.TargetColumnUseCase;

  const isGenerating = generateState?.isGenerating || false;
  const generatingQuery = generateState?.generatingQuery || "";
  const isDisabled = generateState?.isDisabled || false;

  const inputIds = useMemo(
    () =>
      targetInputs?.map((target) => ({
        id: target.askAIInputId,
        type: InputIdDtoTypeEnum.InputId
      })) || [],
    [targetInputs]
  );
  const autoGenerateSuggestionsMutation = useAutoGenerateSuggestionsMutation(threadId!);

  useEffect(() => {
    // useCase !== AIChatRequestDtoUseCaseEnum.TargetColumnUseCase &&
    autoGenerateSuggestionsMutation.mutate(
      {
        inputIds
      },
      {
        onSuccess: setSuggestions
      }
    );
  }, [messages]);
  const handleGenerateCode = (suggestion?: string) => {
    const newController = new AbortController();
    controllerRef.current = newController;
    saveAIGuideChatMutation.mutate(
      {
        threadId: threadId!,
        request: {
          query: suggestion || query?.trim(),
          useCase,
          inputIds,
          controller: newController,
          outputType:
            targetType === ThreadResponseDtoTargetTypeEnum.Dataset
              ? AIChatRequestDtoOutputTypeEnum.AutoInfer
              : AIChatRequestDtoOutputTypeEnum.Text
        }
      },
      {
        onSuccess: (data) => {
          if (isStopped.current || !data) {
            isStopped.current = false;
            return;
          }
        },
        onError: (error) => {
          if (isStopped.current || !error) {
            isStopped.current = false;
            return;
          }
        }
      }
    );
  };

  const queryClient = useQueryClient();

  const handleGenerateStop = () => {
    isStopped.current = true;
    controllerRef?.current?.abort();
    queryClient.setQueryData(
      [AI_GUIDE_MESSAGES_KEY, threadId!],
      (prevData: AIChatResponseDto[] | undefined) => [
        ...(prevData || []),
        {
          threadId: threadId!,
          query: generatingQuery,
          askAIMessageId: STOPPED_QUERY_ID,
          outputType: AIChatRequestDtoOutputTypeEnum.Text,
          useCase,
          error: {
            explanation: "Query run stopped",
            errorType: "errorType"
          }
        } as any
      ]
    );
  };

  const filteredMessages = useMemo(
    () =>
      messages?.filter(
        (message) => message.useCase !== AIChatResponseDtoUseCaseEnum.PromptBubblesUseCase
      ),
    [messages]
  );

  useEffect(() => {
    if (
      thread.datasetContext === ThreadResponseDtoDatasetContextEnum.RapidModelRecipe &&
      messages?.length === 0
    ) {
      handleGenerateCode("Recommend a target column");
    }
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <AiGuideConvoGrid
      isGenerating={isGenerating}
      isDisabled={isDisabled}
      query={query}
      projectId={projectId}
      setQuery={setQuery}
      generatingQuery={generatingQuery}
      messages={filteredMessages}
      inputIds={inputIds}
      handleGenerateCode={handleGenerateCode}
      handleGenerateStop={handleGenerateStop}
      columns={columns}
      suggestions={suggestions}>
      {React.cloneElement(children as React.JSX.Element, {
        isCollapsed: filteredMessages?.length !== 0
      })}
    </AiGuideConvoGrid>
  );
};

export default React.memo(AIGuideContainer);
