import _ from "lodash";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";
import { QUERY_KEY_DATA_APPS } from "src/hooks/api/dataapps/useGetDataApps";
import { UpdateDataAppRequestDto } from "@rapidcanvas/rc-api-core";
import { QUERY_KEY_DATAAPPS_ACROSS_TENANTS } from "./useGetDataAppAcrossTenants";

interface IVariables {
  payload: UpdateDataAppRequestDto;
  id: string;
}

export const useUpdateDataAppMutation = (): UseMutationResult<
  any,
  unknown,
  IVariables,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, payload }) => {
      const response = await api.fetchResponse(
        async () => await api.DataAppControllerApi.updateDataApp(id, payload)
      );
      await queryClient.invalidateQueries([QUERY_KEY_DATA_APPS]);
      await queryClient.invalidateQueries([QUERY_KEY_DATAAPPS_ACROSS_TENANTS]);

      return response;
    }
  });
};
