// Import Sentry configuration
import { initSentry } from "./app-configs/sentry.config";

// React and ReactDOM imports
import React from "react";
import ReactDOM from "react-dom";

// React Router imports
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Import styles
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Import Material-UI styles
import { StylesProvider } from "@material-ui/core/styles";

// Import application components
import App from "./App";

// Import React Redux configuration files
import { Provider as ReduxProvider } from "react-redux";
import { store as reduxStore } from "./stores/store";

// Import React Query configuration files
import { queryClient } from "./app-configs/react-query.config";
import { QueryClientProvider } from "@tanstack/react-query";

// Import Analytics configuration
import { initAnalytics } from "./app-configs/analytics.config";

// Initialize Sentry
initSentry();

// Initialize Analytics
initAnalytics();

// const router = createBrowserRouter(createRoutesFromElements(), {
//   basename: "/"
// });

// Render application
ReactDOM.render(
  <StylesProvider injectFirst>
    <ReduxProvider store={reduxStore}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename="/">
          <Routes>
            <Route path="*" element={<App />} />
          </Routes>
        </BrowserRouter>

        {/* <RouterProvider router={router} /> */}
      </QueryClientProvider>
    </ReduxProvider>
  </StylesProvider>,
  document.querySelector("#root")
);
