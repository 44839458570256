import { Grid, makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import _ from "lodash";

import GroupedCharts from "./GroupedCharts";

import {
  AIChatResponseDto,
  AIChatRequestDtoOutputTypeEnum,
  OutputEntity,
  OutputEntityDto
} from "@rapidcanvas/rc-api-core";
import { ModelIcon } from "src/icons/NewUX/ModelIcon";

const useStyles = makeStyles({
  chart: {
    padding: "0px 32px 0px 16px",
    width: "calc(100% - 28px)"
  },
  outputIconWrap: {
    width: "24px",
    minWidth: "24px",
    background: "#fff",
    borderRadius: "50%",
    height: "24px",
    flexWrap: "nowrap",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  }
});

const AIGuideChartResponse = ({
  message,
  isExpanded,
  setIsExpanded,
  rightContainer,
  shouldShowCode,
  explainedCode,
  isAIGuide
}: {
  message: AIChatResponseDto;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  rightContainer: React.ReactNode;
  shouldShowCode?: boolean;
  explainedCode?: string;
  isAIGuide?: boolean;
}) => {
  const classes = useStyles();
  const {
    outputEntityResponseList,
    outputEntityList,
    code,
    transformId,
    error,
    outputType,
    sampleRows,
    askAIMessageId
  } = message;
  const hasResponse = outputEntityResponseList && outputEntityResponseList?.length !== 0;
  const isOutputModel = outputType === AIChatRequestDtoOutputTypeEnum.Model;

  const charts = useMemo(
    () =>
      (
        (outputEntityResponseList as OutputEntityDto[]) ||
        (outputEntityList as OutputEntity[]) ||
        []
      )?.filter(
        (entity: OutputEntity) => entity.outputType === AIChatRequestDtoOutputTypeEnum.Chart
      ),
    [outputEntityResponseList, outputEntityList]
  );

  const chartList = useMemo(
    () =>
      hasResponse
        ? charts?.map((outputEntityResponse: any) => ({
            plotlyJson: JSON.parse(outputEntityResponse.plotlyJson!),
            name: outputEntityResponse?.name || "",
            codeName: outputEntityResponse?.codeName
          }))
        : charts?.map((outputEntity: any) => ({
            name: outputEntity?.name || "",
            dataFilePath: outputEntity?.plotlyChartFilePath!,
            chartType: "JSON",
            shouldFetchData: false,
            codeName: outputEntity?.codeName
          })),
    [hasResponse, charts]
  );

  const groupedChartsTitle = useMemo(
    () =>
      isOutputModel
        ? (
            _.find(outputEntityList || outputEntityResponseList, [
              "outputType",
              AIChatRequestDtoOutputTypeEnum.Model
            ]) as any
          )?.name
        : hasResponse
          ? `${outputEntityResponseList?.[0]?.name}${outputEntityResponseList?.length && outputEntityResponseList?.length !== 1 ? ` (+${outputEntityResponseList?.length - 1})` : ""}`
          : `${outputEntityList?.[0]?.name}${outputEntityList?.length && outputEntityList?.length !== 1 ? ` (+${outputEntityList?.length - 1})` : ""}`,
    [isOutputModel, outputEntityList, outputEntityResponseList, hasResponse]
  );

  return (
    <Grid className={classes.chart}>
      <GroupedCharts
        parentThreadId={message.threadId}
        title={groupedChartsTitle}
        askAIMessageId={askAIMessageId}
        isCollapsed={!isExpanded}
        errDetails={error ? { ...error, lineOfCode: (error as any)?.lineInCode } : {}}
        chartList={chartList || []}
        onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
        rightContainer={rightContainer}
        shouldShowCode={shouldShowCode}
        code={explainedCode ?? code}
        isAddedToRecipe={!!transformId}
        sampleRows={sampleRows}
        isAiGuide={isAIGuide}
        icon={
          isOutputModel ? (
            <Grid
              test-id="ask-ai-modal-rc-icon-container"
              container
              alignItems="center"
              justifyContent="center"
              data-testid="ai-model-icon"
              className={classes.outputIconWrap}>
              <ModelIcon width={14} height={14} viewBox="-3 -3 24 24" color="#7C7C7C" />
            </Grid>
          ) : undefined
        }
      />
    </Grid>
  );
};

export default React.memo(AIGuideChartResponse);
