import React from "react";
import { makeStyles } from "@material-ui/core";
import NoJobFoundIcon from "src/icons/NewUX/NoJobFoundIcon";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    minHeight: "300px"
  }
});

interface IProps {
  msg?: string;
}

const NoJobsFound: React.FC<IProps> = (props) => {
  const { root } = useStyles();

  return (
    <div data-testid="noJobsFound" className={root}>
      <NoJobFoundIcon />
      <span>{props.msg ?? "No Job Runs Yet"}</span>
    </div>
  );
};

export default NoJobsFound;
