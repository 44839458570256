import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPI } from "src/utils/apiService";
import { RecipeTypes } from "src/pages/private/ProjectsModule/utils";
import api from "src/utils/AxiosClient";
import { Condition } from "@rapidcanvas/rc-api-core";

const QUERY_KEY_TRANSFORM_GROUP = "query-key-transform-group";

export interface ITransformGroupData {
  id: string;
  parents: { id: string; name: string; type: string }[];
  timeout: number;
  recipeType: RecipeTypes;
  runConfigs: {
    id: string;
    inputEntityId: number;
    name: string;
    projectId: string;
    templateId: string;
  }[];
  children: {
    displayName: string;
    id: string;
    name: string;
    type: string;
  }[];
  condition?: Condition;
}

const useTransformGroup = (
  groupId?: string,
  scenarioId?: string,
  jobRunId?: string,
  options?: UseQueryOptions<ITransformGroupData>
): UseQueryResult<ITransformGroupData> => {
  return useQuery({
    queryKey: [QUERY_KEY_TRANSFORM_GROUP, groupId, scenarioId, jobRunId],
    queryFn: async () => {
      const response = await getAPI(
        `/v2/dfs-run-config-groups?id=${groupId}${
          !!scenarioId ? `&scenarioId=${scenarioId}` : ``
        }${!!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``}`
      );
      api.DfsRunConfigGroupControllerV2Api.findGroups;

      return Array.isArray(response) ? response[0] : response;
    },
    ...options
  });
};

export default useTransformGroup;
export { QUERY_KEY_TRANSFORM_GROUP };
