import React, { useState } from "react";
import _, { includes } from "lodash";
import { Grid, makeStyles } from "@material-ui/core";

import NewModal from "components/Modal/NewModal";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import SampleSyntaxIcon from "src/icons/NewUX/SampleSyntaxIcon";
import SyntaxList from "./SyntaxList";
import MonacoEditor from "react-monaco-editor";
import { getSnippets } from "./utils/ApiKey.constant";

interface IProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  gridItem: {
    width: "300px",
    maxHeight: "calc(100vh - 200px)",
    overflow: "auto",
    paddingRight: "8px",
    borderRight: "1px solid #dedada",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",

    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  curlCommandBox: {
    "& .Mui-disabled": {
      color: "#003656 !important"
    }
  }
});

interface ISelected {
  title: string;
  key: string;
  code: string;
  tooltip: string;
}

const SyntaxModal: React.FC<IProps> = (props) => {
  const { open, onClose } = props;
  const SNIPPETS = getSnippets();
  const [selected, setSelected] = useState<ISelected>({
    ..._.get(SNIPPETS, "API Key Usage")[0],
    key: "usage-curl"
  });
  const [isCopied, setIsCopied] = useState(false);

  const classes = useStyles();

  const handleCopy = () => {
    navigator.clipboard.writeText(selected.code);
    setIsCopied(true);

    setTimeout(() => setIsCopied(false), 5000);
  };

  const handleChange = (newVal: ISelected) => {
    setSelected(newVal);
    setIsCopied(false);
  };

  return (
    <NewThemeWrapper>
      <NewModal
        header={
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <SampleSyntaxIcon color="black" width={17} height={17} /> Sample Syntax
          </div>
        }
        open={open}
        data-testid="api-connectorsample-syntax"
        width={980}
        background="#fff"
        keepMounted={false}
        cancelButtonLabel="Close"
        submitDisabled={isCopied}
        submitButtonLabel={isCopied ? "Copied" : "Copy"}
        onClose={onClose}
        onFinish={handleCopy}>
        <Grid container>
          <Grid item xs="auto" className={classes.gridItem}>
            <SyntaxList value={selected.key} onChange={handleChange} />
          </Grid>
          <Grid
            item
            xs
            style={{ height: "calc(100vh - 300px)", overflowX: "hidden", overflowY: "auto" }}>
            <MonacoEditor
              key="code-recipe-code-editor"
              height="100%"
              value={selected.code}
              width="100%"
              language={includes(selected.key, "curl") ? "shell" : "python"}
              theme="vs"
              options={{
                wordWrap: "on",
                readOnly: true,
                theme: "vs",
                minimap: { enabled: false },
                renderLineHighlight: "none",
                scrollbar: {
                  vertical: "hidden",
                  horizontal: "hidden"
                },
                overviewRulerLanes: 0,
                padding: {
                  top: 16
                }
              }}
            />
          </Grid>
        </Grid>
      </NewModal>
    </NewThemeWrapper>
  );
};

export default SyntaxModal;
