import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { CreateProjectDto, ProjectDto } from "@rapidcanvas/rc-api-core";

import api from "src/utils/AxiosClient";
import { handleResponse } from "src/utils/apiService";
import { CREATE_PROJECT_SUCCESS_MSG } from "src/pages/Projects/CreateProject/CreateProject.constants";
import useRelaunchEnvironment from "hooks/api/environments/useRelaunchEnvironment";
import { useProjectsStore } from "src/store/store";

const useCreateProject = (): UseMutationResult<ProjectDto, unknown, CreateProjectDto, unknown> => {
  const relaunch = useRelaunchEnvironment();
  const [projectList, setProjects, toggleShouldProjectsRefresh] = useProjectsStore((state) => [
    state.projectList,
    state.setProjectList,
    state.toggleShouldRefresh
  ]);
  return useMutation({
    mutationFn: async (body) => {
      const response = await api.fetchResponse(
        async () => await api.ProjectControllerApi.createProject(body)
      );

      response.envId && relaunch.mutate({ envId: response.envId! });
      Object.keys(response)?.length > 0 && setProjects([...projectList, response]);
      toggleShouldProjectsRefresh();
      return response;
    },
    onSuccess: () => {
      handleResponse({ successMessage: CREATE_PROJECT_SUCCESS_MSG });
    }
  });
};

export default useCreateProject;
