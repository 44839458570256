import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";
import { ModelDto } from "@rapidcanvas/rc-api-core";

export const QUERY_KEY_MODELS = "models";

export const useGetModels = (options?: UseQueryOptions<ModelDto[]>) => {
  return useQuery({
    queryKey: [QUERY_KEY_MODELS],
    queryFn: async () => {
      const response = await api.fetchResponse(
        async () => await api.PredictionServiceControllerApi.getModels()
      );
      return response.models ?? [];
    },
    refetchOnMount: true,
    keepPreviousData: true,
    ...options
  });
};
