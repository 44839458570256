import React, { useMemo, useState } from "react";
import CachedIcon from "@material-ui/icons/Cached";

// Packages
import { generatePath, useNavigate } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import { capitalize, includes, isEmpty } from "lodash";

// MUI
import AddIcon from "@material-ui/icons/Add";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Button, CircularProgress } from "@material-ui/core";

// Icons
import { EyeIcon } from "src/icons/NewUX";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { AiAssistedIcon } from "src/icons/NewUX/AiAssistedRecipeIcon";
import { RapidModelRecipeIcon } from "src/icons/NewUX/RapidModelRecipeIcon";
import { DatasetIcon } from "src/icons/NewUX/DatasetIcon";
import ApiConnectorIcon from "src/icons/NewUX/ApiConnectorIcon";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { WebPaths } from "src/routing/routes";
import { RecipeTypeNames, DatasetStatuses } from "src/pages/private/ProjectsModule/utils";

// Hooks
import { UseReloadDatasetsQueryKeys } from "src/hooks/api/entities/useReloadDatasets";

// Components
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import NodeActionWrapper from "../NodeActionWrapper";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import ReloadDatasetModal from "src/pages/ViewData/ViewDataData/ReloadDatasetModal";

// Constants
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";

// Types
import { NodeData } from "src/types";
import AIGuideMiniButton from "src/components/Buttons/AIGuideMiniButton";
import { useContextStyles } from "../useContextMenuStyles";
import { ExportIcon } from "src/icons/NewUX/ExportIcon";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { downloadEntity } from "src/api";
import { toastWrapper } from "src/utils/toastWrapper";
import { TemplateRecipeIcon } from "src/icons/NewUX/TemplateRecipeIcon";
import useEntityDetails from "src/hooks/api/entities/useEntityDetails";

type Props = {
  open: boolean;
  closeContextMenu: () => void;
  isDefaultScenario: boolean;
  data: NodeData;
  children?: React.ReactNode;
  openAIGuideDialog: () => void;
  onAddDestination: () => void;
};

const ContextMenu = (props: Props) => {
  const { closeContextMenu, isDefaultScenario, data, open, children, openAIGuideDialog } =
    props || {};
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isRawDownloading, setIsRawDownloading] = React.useState(false);
  const navigate = useNavigate();

  const classes = useContextStyles();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [reloadOpen, setReloadOpen] = useState(false);

  const { data: entityDetails } = useEntityDetails(
    data.itemId,
    data.scenarioId,
    data.jobProps?.jobRunId,
    {
      enabled: !!data.itemId && open,
      refetchOnMount: true
    }
  );

  const isBuilt = useMemo(() => ["BUILT"].includes(data?.status), [data?.status]);

  const pendingReloadDatasetsMutations = useIsMutating({
    mutationKey: [UseReloadDatasetsQueryKeys.ReloadDatasets, data?.id]
  });

  const isReloadingDatasets = useMemo(
    () => pendingReloadDatasetsMutations > 0,
    [pendingReloadDatasetsMutations]
  );

  const onView = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    let path = generatePath(WebPaths.ViewData, {
      projectId: data?.projectId,
      scenarioId: data?.scenarioId,
      entityId: data?.id,
      section: "data"
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`, {
        projectId: data?.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        entityId: data?.id,
        section: "data"
      });
    }

    !!path && navigate(path);
  };

  const isAddRecipeDisabled = useMemo(() => {
    return !data?.status || !includes([DatasetStatuses.Built], data?.status);
  }, [data?.status]);

  const isNavigableToRecipePage = useMemo(
    () => areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"]),
    [data?.projectId, data?.scenarioId, data?.id]
  );

  const addAiAssistedRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-code-recipe?entity=${data?.id}`
    );
  };

  const onAddDatasetAction = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(`/projects/${data?.projectId}/scenario/${data?.scenarioId}/dataset/${data?.id}`);
  };

  const addRapidModelRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-auto-ml-recipe?entity=${data?.id}`
    );
  };

  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?entity=${data?.id}`;
    navigate(
      generatePath(url, {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const handleAddTemplateRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-recipe?entity=${data?.id}`
    );
  };

  const navigateToAIGuide = () => {
    openAIGuideDialog();
    closeContextMenu();
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleAddFile = () => {
    setOpenConfirmModal(true);
  };

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  const handleReload = () => {
    setReloadOpen(true);
  };

  const downloadCSV = async (raw: boolean = true) => {
    if (raw) {
      setIsRawDownloading(() => true);
      if (!data?.itemId) {
        return;
      }

      data?.projectId && checkEnvRelaunch(data?.projectId);

      try {
        let jobPayload = {};
        if (!!data?.isJobCanvas) {
          jobPayload = !!data?.jobProps?.jobRunId
            ? { projectRunEntryId: data?.jobProps?.jobRunId }
            : {};
        }

        const downloadEntityResponse = await downloadEntity({
          scenarioId: data?.scenarioId || "",
          entityId: data.itemId || "",
          downloadRaw: true,
          ...jobPayload
        });

        !!downloadEntityResponse &&
          window.open(downloadEntityResponse.replace(": //", "://"), "_blank");
      } catch (error: any) {
        toastWrapper({
          type: "error",
          content: "No file connected to this dataset!"
        });
      }

      setIsRawDownloading(() => false);
    } else {
      setIsDownloading(() => true);

      if (!data?.itemId) {
        return;
      }

      data?.projectId && checkEnvRelaunch(data?.projectId);

      try {
        let jobPayload: $TSFixMe = {};
        if (!!data?.isJobCanvas) {
          jobPayload = !!data?.jobProps?.jobRunId
            ? { projectRunEntryId: data?.jobProps?.jobRunId }
            : {};
        }

        const downloadEntityResponse: $TSFixMe = await downloadEntity({
          scenarioId: data?.scenarioId || "",
          entityId: data.itemId || "",
          downloadRaw: false,
          ...jobPayload
        });

        !!downloadEntityResponse &&
          window.open(downloadEntityResponse.replace(": //", "://"), "_blank");
      } catch (error: $TSFixMe) {
        toastWrapper({
          type: "error",
          content: "No file connected to this dataset!"
        });
      }

      setIsDownloading(() => false);
    }
  };

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    items.push(
      <Button
        key="view"
        data-testid="Preview"
        className="context-menu-border"
        startIcon={<EyeIcon viewBox="0 0 20 20" />}
        size="small"
        onClick={onView}>
        Preview
      </Button>
    );

    if (!!isDefaultScenario && isBuilt) {
      items.push(
        <Button
          key={RecipeTypeNames.AiAssisted}
          size="small"
          data-testid={`${RecipeTypeNames.AiAssisted} Recipe`}
          startIcon={<AiAssistedIcon viewBox="0 1 20 20" />}
          disabled={isAddRecipeDisabled}
          {...(!!isNavigableToRecipePage
            ? {
                onClick: addAiAssistedRecipe
              }
            : {})}>
          {RecipeTypeNames.AiAssisted} Recipe
        </Button>
      );

      items.push(
        <Button
          key={RecipeTypeNames.RapidModel}
          size="small"
          data-testid={`${RecipeTypeNames.RapidModel} Recipe`}
          startIcon={<RapidModelRecipeIcon viewBox="0.5 0.5 22 22" />}
          disabled={isAddRecipeDisabled}
          {...(!!isNavigableToRecipePage
            ? {
                onClick: addRapidModelRecipe
              }
            : {})}>
          {RecipeTypeNames.RapidModel} Recipe
        </Button>
      );

      items.push(
        <Button
          key={RecipeTypeNames.Template}
          size="small"
          data-testid={`${RecipeTypeNames.Template} Recipe`}
          startIcon={<TemplateRecipeIcon width={16} height={16} viewBox="0 0 20 20" />}
          {...(!!isNavigableToRecipePage
            ? {
                onClick: handleAddTemplateRecipe
              }
            : {})}>
          {RecipeTypeNames.Template} Recipe
        </Button>
      );

      items.push(
        <Button
          key={RecipeTypeNames.ApiConnector}
          size="small"
          data-testid={`${RecipeTypeNames.ApiConnector} Recipe`}
          className="context-menu-border"
          startIcon={<ApiConnectorIcon />}
          {...(!!isNavigableToRecipePage
            ? {
                onClick: handleAddApiConnectorRecipe
              }
            : {})}>
          {RecipeTypeNames.ApiConnector} Recipe
        </Button>
      );

      items.push(
        <AIGuideMiniButton
          key="ai-guide"
          width={16}
          height={12}
          className="context-menu-border"
          viewBox="4 -2 48 39"
          badgeStyleProps={{
            marginTop: -2
          }}
          onClick={navigateToAIGuide}
          targetId={data?.itemId!}
          projectId={data?.projectId!}
        />
      );
    }

    if (!data?.isOutputDataset) {
      if (!data?.entityDSDetails?.type) {
        items.push(
          <Button
            key="addFile"
            data-testid="Add File"
            size="small"
            startIcon={<DatasetIcon viewBox="0 0 20 20" />}
            onClick={handleAddFile}>
            Add File
          </Button>
        );
      } else {
        items.push(
          <NodeActionWrapper
            key="reload"
            title={
              isReloadingDatasets ? "Please wait. The reload dataset action is in progress." : ""
            }>
            <Button
              size="small"
              data-testid="Reload"
              startIcon={
                isReloadingDatasets ? (
                  <CircularProgress size={16} />
                ) : (
                  <CachedIcon style={{ width: "16px", height: "16px" }} />
                )
              }
              onClick={handleReload}
              disabled={!!isReloadingDatasets}>
              Reload
            </Button>
          </NodeActionWrapper>
        );
      }
    } else {
      items.push(
        <Button
          key="addDestination"
          data-testid="Add Destination"
          size="small"
          startIcon={<AddIcon />}
          onClick={props.onAddDestination}>
          Add Destination
        </Button>
      );
    }

    if (isBuilt) {
      if (!!entityDetails?.rows) {
        if (entityDetails.fileExtension === "csv") {
          items.push(
            <Button
              key="Export"
              className="context-menu-border"
              data-testid="Export"
              size="small"
              disabled={isRawDownloading}
              startIcon={
                isRawDownloading ? (
                  <CircularProgress size={16} />
                ) : (
                  <ExportIcon viewBox="0 0 22 24" />
                )
              }
              onClick={() => downloadCSV(true)}>
              Export
            </Button>
          );
        } else {
          items.push(
            <Button
              key="Export-as-csv"
              data-testid="ExportAsCsv"
              size="small"
              disabled={isDownloading}
              startIcon={
                isDownloading ? <CircularProgress size={16} /> : <ExportIcon viewBox="0 0 22 24" />
              }
              onClick={() => downloadCSV(false)}>
              Export as Csv
            </Button>
          );

          items.push(
            <Button
              key="Export-as-other"
              className="context-menu-border"
              data-testid="ExportAsCsv"
              size="small"
              disabled={isRawDownloading}
              startIcon={
                isRawDownloading ? (
                  <CircularProgress size={16} />
                ) : (
                  <ExportIcon viewBox="0 0 22 24" />
                )
              }
              onClick={() => downloadCSV(true)}>
              {`Export as ${capitalize(entityDetails.fileExtension) ?? "Parquet"}`}
            </Button>
          );
        }
      }
    }

    if (!!isDefaultScenario) {
      items.push(
        <Button
          size="small"
          key="delete"
          data-testid="Delete"
          startIcon={<TrashIcon viewBox="0 0 20 22" />}
          onClick={onDelete}>
          Delete
        </Button>
      );
    }

    return items;
  }, [
    data?.isJobCanvas,
    data?.isJobCanvasPath,
    isDefaultScenario,
    isBuilt,
    isAddRecipeDisabled,
    isNavigableToRecipePage,
    data?.isOutputDataset,
    data?.entityDSDetails?.type,
    isDownloading,
    isRawDownloading,
    isReloadingDatasets,
    entityDetails
  ]);

  return (
    <>
      {!!isDeleteModalOpen && (
        <DeleteNodeModal
          open
          nodeId={data?.id}
          nodeName={data?.label}
          nodeType="entity"
          deleteNote={
            <div>
              <span>Note: </span>
              {psMessage}
            </div>
          }
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onAfterSubmit={closeContextMenu}
        />
      )}
      {reloadOpen && (
        <ReloadDatasetModal
          id={data?.itemId}
          name={data?.label}
          projectId={data?.projectId}
          open={reloadOpen}
          isFiveTran={data?.entityDSDetails?.type === thirdPartyTypeName}
          onCancel={() => setReloadOpen(false)}
          onSuccess={() => setReloadOpen(false)}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" orientation="vertical" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}

      {openConfirmModal && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title="Add File"
          content={[
            "If you make any changes to the dataset, it will render the current runs of the associated recipe(s) with this input dataset invalid, marking them as UNBUILT. To implement the changes, it is necessary to rerun the linked recipe(s). Also the associated segment(if any) will be deleted, and any custom scenarios using it will default to the entire dataset instead of the segment.",
            "Are you sure you want to proceed with this?"
          ]}
          submitLabel="Yes, Proceed"
          onClose={handleCancel}
          onSubmit={onAddDatasetAction}
        />
      )}
    </>
  );
};

export default ContextMenu;
