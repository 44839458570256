import React, { useEffect, useMemo, useState } from "react";

import { delay, filter, includes, isEmpty, uniq } from "lodash";
import { makeStyles } from "@material-ui/core";

import { capitalize } from "src/utils/capitalize";

import useHelpers from "../../../hooks/useHelpers";

import { OverflowTooltip, Table } from "src/components";

import { DatasetHelperText, DatasetKeys, OntologyConfig } from "../../../utils/Dataset.constants";

const useStyles = makeStyles((theme) => ({
  cell: {
    display: "inline-block",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle"
  },
  select: {
    padding: theme.spacing(0.5),
    fontSize: "small",
    fontStyle: "italic",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#7c7c7c",
    "& svg": {
      display: "none",
      fill: "#7c7c7c"
    },
    "&:hover": {
      backgroundColor: "#f5f5f5",
      "& svg": {
        display: "initial"
      },
      "&:before, &:after": {
        borderBottom: "none"
      }
    },
    "&:before, &:after": {
      borderBottom: "none"
    }
  }
}));

const OntologySampleData = (props: $TSFixMe) => {
  const {
    datasetDefaultDatasetStore,
    isConfigContainerExpanded,
    ontologyDatasetIndex,
    ontologySampleData,
    searchTerm,
    ontologySchemaOptionsData,
    ontologySchemaData,
    datasetWatchOntologySchemaSetStore,
    getDatasetCustomColumnsData,
    visibleColumns,
    totalRowCount
  } = props || {};

  const classes: $TSFixMe = useStyles();

  const {
    updateDatasetsSession,
    setDatasetOntologySchemaSession,
    getDatasetOntologySchemaSession
  } = useHelpers();

  const [data, setData] = useState<$TSFixMe>([]);
  const [dataTypeMap, setDataTypeMap] = useState<$TSFixMe>({});

  const getElementWithTooltip = ({ value = "" }: $TSFixMe) => (
    <OverflowTooltip style={{ flexGrow: 1, width: 150, whiteSpace: "nowrap" }} value={value} />
  );

  const getOntologyDatatypes = (data: $TSFixMe) => {
    let thisOntologyDataTypes = [];
    if ((data?.dataTypes || [])?.length > 0) {
      thisOntologyDataTypes = data?.dataTypes
        ?.filter((eachDataType: $TSFixMe) => !!eachDataType)
        ?.map((eachDataType: $TSFixMe) => ({
          label: capitalize(eachDataType),
          value: eachDataType
        }));
    }

    return thisOntologyDataTypes;
  };

  useEffect(() => {
    setDataTypeMap(() => ({}));

    const _ = () => {
      let thisOntologySchema = [];
      if ((ontologySchemaData || [])?.length > 0) {
        thisOntologySchema = ontologySchemaData?.map((eachField: $TSFixMe) => ({
          name: eachField?.fieldSchema?.fieldName || "",
          idLikeColumn: eachField?.fieldSchema?.fieldProperties?.idLikeColumn,
          detectedDataType: capitalize(
            eachField?.fieldSchema?.rcDataType || DatasetHelperText.Unknown
          ),
          newDataType:
            eachField?.fieldSchema?.rcDataType || DatasetHelperText.Unknown.toUpperCase(),
          detectedOntology: capitalize(
            eachField?.fieldSchema?.rcOntology || DatasetHelperText.Unknown
          ),
          newOntology: eachField?.fieldSchema?.rcOntology || DatasetHelperText.Unknown.toUpperCase()
        }));

        // setOntologySchema(() => thisOntologySchema);
        setDatasetOntologySchemaSession(thisOntologySchema);
      }

      const dataTypesRow: $TSFixMe = {};

      thisOntologySchema?.forEach((eachColumn: $TSFixMe) => {
        // Replacing ".", "[", "]" with "_".
        const columnKey = eachColumn?.name?.replace(/[.[\]]/gi, "_");
        dataTypesRow[columnKey] = eachColumn?.newDataType;
      });

      setDataTypeMap(() => dataTypesRow);
    };

    // Need to reset data-types on update-dataset API failure.
    // Hence, keeping set-data-type-map logic within a callback.
    delay(_, 0);
  }, [ontologySchemaData, ontologySampleData, datasetWatchOntologySchemaSetStore]);

  const allColumns = useMemo(
    () =>
      uniq([
        ...(ontologySchemaData?.map((item: $TSFixMe) => item?.name) || []),
        ...(ontologySampleData?.columns || [])
      ]),
    [ontologySchemaData, ontologySampleData?.columns]
  );

  const columns = useMemo(
    () =>
      (allColumns || [])?.map((column: $TSFixMe, index: number) => {
        // Replacing ".", "[", "]" with "_".
        const columnKey = column ? ("" + column).replace(/[.[\]]/gi, "_") : "";

        return {
          id: column || `column_${index + 1}`,
          name: columnKey,
          accessor: columnKey,
          Header: column || DatasetHelperText.Unknown,
          isSortable: true,
          sortingFn: "alphanumeric",
          Cell: ({ cell }: $TSFixMe) => {
            return getElementWithTooltip({ value: cell.value });
          },
          ...(!datasetDefaultDatasetStore?.id
            ? {
                type: "select",
                values: getOntologyDatatypes(ontologySchemaOptionsData),
                isEditable: true,
                hideSelectInputLabel: true,
                selectVariant: "standard",
                selectStyles: classes.select,
                meta: dataTypeMap[columnKey]
              }
            : {})
        };
      }),
    [datasetDefaultDatasetStore, allColumns, ontologySchemaOptionsData, dataTypeMap]
  );

  useEffect(() => {
    const columns = ontologySampleData?.columns || [];
    const rows = ontologySampleData?.rows || [];

    setData(() => []);

    const _ = () => {
      const outputRows = rows?.reduce((rowAcc: $TSFixMe, row: $TSFixMe) => {
        rowAcc.push(
          (row?.cells || [])?.reduce(
            (cellAcc: $TSFixMe, cell: $TSFixMe, cellIndex: $TSFixMe) => {
              // Replacing ".", "[", "]" with "_".
              const columnKey = ("" + columns[cellIndex]).replace(/[.[\]]/gi, "_");

              cellAcc["id"] = `row${cellIndex + 1}`;
              cellAcc[columnKey] =
                !!cell && typeof cell === "string" && ["nan"].includes(cell?.trim()?.toLowerCase())
                  ? ""
                  : cell;
              return cellAcc;
            },
            { ontologyOption: "" }
          )
        );

        return rowAcc;
      }, []);

      setData(() => [...outputRows]);
    };

    if (!isEmpty(columns) && !isEmpty(rows)) {
      // Need to reset data-types on update-dataset API failure.
      // Hence, keeping set-data logic within a callback.
      delay(_, 0);
    }
  }, [ontologySampleData, datasetWatchOntologySchemaSetStore]);

  const filteredValues = useMemo(() => {
    return (data || [])?.filter((eachField: $TSFixMe) => {
      return Object.values(eachField || {})
        ?.join(" ")
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase());
    });
  }, [searchTerm, data]);

  const updateMyData = ({ columnId, value }: any) => {
    // @TODO: Need to improve this (updating one row of rows)
    const thisOntologySchema = // getOntologySchema(ontologySchemaData)
      getDatasetOntologySchemaSession()?.map((eachColumn: $TSFixMe) => {
        if (eachColumn?.name === columnId) {
          eachColumn["newDataType"] = value;
        }

        return eachColumn;
      });

    setDatasetOntologySchemaSession(thisOntologySchema);

    // Update datasets in session >>
    const datasetFields: $TSFixMe = {};
    datasetFields[DatasetKeys.OntologyConfig] = {};
    datasetFields[DatasetKeys.OntologyConfig][DatasetKeys.IsSchemaOrOntologyDirty] = true;

    updateDatasetsSession({ index: ontologyDatasetIndex, fields: datasetFields });
    // << Update datasets in session
  };

  const hiddenColumns = useMemo(
    () =>
      isEmpty(visibleColumns)
        ? allColumns.slice(OntologyConfig.ColumnsLimit)
        : filter(allColumns, (columnName: string) => !includes(visibleColumns, columnName)),
    [visibleColumns, allColumns]
  );

  return !isEmpty(columns) && !isEmpty(data) ? (
    <Table
      size="small"
      columns={columns}
      data={filteredValues}
      countMessage={totalRowCount ? ` out of ${totalRowCount}` : ""}
      showSample
      maxHeight={`calc(100vh - ${isConfigContainerExpanded ? "460px" : "397px"})`}
      isTheadSticky
      fixedLayout={false}
      unsorted
      hiddenColumns={hiddenColumns}
      onSelectedColumnChange={getDatasetCustomColumnsData}
      {...(!datasetDefaultDatasetStore?.id
        ? {
            updateMyData
          }
        : {})}
    />
  ) : (
    <></>
  );
};

export default OntologySampleData;
