import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import {
  Grid,
  FormControl,
  MenuItem,
  makeStyles,
  Typography,
  InputLabel,
  OutlinedInput,
  Box,
  Menu,
  Button
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { find, isEmpty, map, sortBy, toLower } from "lodash";

import { EnvDto } from "@rapidcanvas/rc-api-core";
import Text from "src/components/Widget/Text";
import { useForm } from "src/utils/useForm";
import { useEnvironments } from "src/hooks";
import useRelaunchEnvironment from "hooks/api/environments/useRelaunchEnvironment";
import { validateNameField } from "src/utils/formFieldUtils";
import { toastWrapper } from "src/utils/toastWrapper";
import { Field, Modal, OverflowTooltip } from "src/components";
import { useProjectsStore } from "src/store/store";
import { shouldRefreshProjectsToggler, projectsSetter } from "src/store/store.selectors";
import { getEnvironmentsTypes, createProjectWithRethrow } from "src/api";
import { envMetadataFind, imageToBase64 } from "./helpers/projects.helpers";
import { DEFAULT_NAME } from "src/constants/environments.constants";
import { statusValues } from "src/pages/private/Environments/components/StatusBar/StatusBar";
import EnvironmentTypeConfig from "src/pages/private/Environments/components/EnvironmentTypeConfig";
import { useStyles as useEnvStyles } from "src/pages/Projects/ProjectSettings/styling";
import styles from "src/pages/private/Environments/Environments.module.scss";
import default_image from "src/assets/images/projectImages/default_project_thumbnail1.svg";
import PreviewImageSelectorNew from "./ProjectSettings/PreviewImageSelectorNew";
import { offlineImages } from "src/pages/Projects/ProjectSettings/PreviewImageModal";
import { Environment } from "../private/Environments/Environments";
import { WebPaths } from "src/routing/routes";
import ConfirmCloseWindow from "./ConfirmCloseWindow";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import SelectWithLoading from "src/components/Select/SelectWithLoading";

const initialFormValues = {
  description: "",
  name: ""
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    gap: 24,
    "& > .MuiGrid-item": {
      paddingTop: 8
    }
  },
  helperText: {
    color: "#828282",
    marginBottom: 24,
    display: "inline-block"
  },
  envTypeSpan: {
    fontSize: 12,
    color: "#838383"
  },
  inputsContainer: {
    width: "calc(50% + 30px)",
    "& div[class^='MuiFormControl-root']": {
      width: "100%"
    },
    "& div[class^='MuiSelect-root']": {
      paddingTop: 10.5,
      paddingBottom: 10.5
    }
  },
  submitBtn: {
    height: "36px !important"
  },
  imageSelectionGrid: {
    width: "calc(50% - 30px)"
  },
  link: {
    color: "#003656 !important",
    "&:hover": {
      color: "#003656 !important",
      textDecoration: "underline"
    }
  }
}));

type Props = {
  open: boolean;
  onClose?: () => void;
};

const CreateProject = ({ open, onClose }: Props) => {
  const classes = useStyles();
  const { envMenuItem, roundEnvStatus } = useEnvStyles();

  const navigate = useNavigate();
  const relaunch = useRelaunchEnvironment();
  const [environmentsTypes, setEnvironmentsTypes] = useState<$TSFixMe>([]);
  const [environments, setEnvironments] = useState<$TSFixMe>([]);

  const { values, handleInputChange, resetForm } = useForm({ ...initialFormValues });
  const [showProjectNameError, setShowProjectNameError] = useState<[boolean, string]>([false, ""]);
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [selectedEnv, setSelectedEnv] = useState<EnvDto | null>(null);
  const [imageBase64, setImageBase64] = useState<string | undefined>();
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);

  const projectList = useProjectsStore((state) => state.projectList);
  const setProjects = useProjectsStore(projectsSetter);

  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);
  const isDirty = useProjectsStore((state) => state.isDirty);
  const toggleDirty = useProjectsStore((state) => state.toggleDirty);

  const { isLoading: isEnvironmentsLoading, data: environmentsData } = useEnvironments();

  useEffect(() => {
    setEnvironments(() => sortBy(environmentsData, (environment) => toLower(environment?.name)));

    if (environmentsData) {
      const defaultEnv = environmentsData?.find(
        (env: EnvDto) => env?.name === DEFAULT_NAME && env?.defaultFlag
      );

      defaultEnv && setSelectedEnv(defaultEnv);
    }
  }, [environmentsData]);

  useEffect(() => {
    const _ = async () => {
      const environmentsTypesResponse = await getEnvironmentsTypes();
      setEnvironmentsTypes(() =>
        Array.isArray(environmentsTypesResponse) ? environmentsTypesResponse : []
      );
    };

    _();
  }, []);

  const validFields = () => {
    let isValid = true;
    const { isValid: isNameValid, error } = validateNameField({
      fieldName: (values as $TSFixMe)?.name,
      fieldNameLabel: `project name`
    });
    if (!isNameValid && error) {
      setShowProjectNameError([true, error]);
      isValid = false;
    } else if (nameExist()) {
      setShowProjectNameError([true, "This project name exists"]);
      isValid = false;
      setAnchorEl(inputRef?.current);
    } else {
      setShowProjectNameError([false, ""]);
      setAnchorEl(null);
    }

    return isValid;
  };

  useEffect(() => {
    showProjectNameError[0] && setShowProjectNameError([false, ""]);
  }, [open]);

  const nameExist = () => {
    return Boolean(
      projectList?.find(
        (project) =>
          (project as $TSFixMe).name.toLowerCase() ===
          (values as $TSFixMe).name.toLowerCase().trim()
      )
    );
  };

  const projectNameList = useMemo(() => map(projectList, "name") as string[], [projectList]);

  const handleOnClose = () => {
    resetForm();
    onClose?.();
  };

  const handleSubmit = async (e: $TSFixMe) => {
    setIsCreatingProject(true);

    e.preventDefault();
    if (validFields()) {
      if (!imageBase64) {
        const randomImageIndex = Math.floor(Math.random() * offlineImages.length);
        imageToBase64(
          offlineImages[randomImageIndex],
          (base64String: string) => {
            handlePostImageSubmit(base64String);
          },
          543,
          1084
        );
      } else {
        handlePostImageSubmit();
      }
    } else {
      setIsCreatingProject(false);
    }
  };

  const handlePostImageSubmit = async (updatedImage?: string) => {
    if (!selectedEnv?.id) return;

    if (selectedEnv) {
      const valuesToSend = {
        ...values,
        envId: selectedEnv.id,
        image: updatedImage ? updatedImage : imageBase64?.split(";base64,")?.pop()
      };

      try {
        const response = await createProjectWithRethrow(valuesToSend);

        toastWrapper({
          type: "success",
          content: "Project created successfully!"
        });

        relaunch.mutate({ envId: selectedEnv.id });

        if (response) {
          Object.keys(response)?.length > 0 && setProjects([...projectList, response]);
        }

        toggleShouldProjectsRefresh();
        response?.id ? navigate(`/projects/${response?.id}/canvas`) : navigate(WebPaths.Projects);
      } catch (error) {
        console.error("error", error);
      }

      setIsCreatingProject(false);
      onClose?.();
      toggleDirty(false);
    }
  };

  const generateSuggestion = (name: string): string[] => {
    const suggestions: string[] = [];
    let counter = 1;
    const maxNameLength = 64 - counter.toString().length;
    const baseName = name.length > maxNameLength ? name.slice(0, maxNameLength) : name;
    while (suggestions.length < 3) {
      const newName = `${baseName}${counter}`;
      if (!projectNameList.includes(newName)) {
        suggestions.push(newName);
      }
      counter++;
    }

    return suggestions;
  };

  const suggestionsList = useMemo(() => {
    if (Boolean(anchorEl)) {
      return generateSuggestion((values as any).name);
    }
    return [];
  }, [(values as any).name, anchorEl]);

  const handleSuggestionClose = () => {
    setAnchorEl(null);
  };

  const handleSuggestionClick = (suggestion: string) => {
    handleChange(
      { target: { name: "name", value: suggestion } } as React.ChangeEvent<
        HTMLTextAreaElement | HTMLInputElement
      >,
      "name"
    );
    setAnchorEl(null);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    id?: string
  ) => {
    if (id === "name") {
      const { isValid: isNameValid, error } = validateNameField({
        fieldName: event.target.value,
        fieldNameLabel: `project name`
      });
      if (!isNameValid && error) {
        setShowProjectNameError([true, error]);
      } else {
        setShowProjectNameError([false, ""]);
      }
    }

    handleInputChange(event);
    toggleDirty(true);
  };

  const handleCloseModal = () => {
    toggleDirty(false);
    setShowConfirmScreen(false);

    handleOnClose();
  };

  const handleCloseAttempt = () => {
    if (isDirty) {
      return setShowConfirmScreen(true);
    }

    handleOnClose();
  };

  const handleCancelClose = () => {
    setShowConfirmScreen(false);
  };

  const handleEnvironmentChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const env = environments.find((item: Environment) => item.id === e.target.value) || {};
    setSelectedEnv(env);
  };

  const disabledCreateActionMessage = useMemo(() => {
    if (!!isEnvironmentsLoading) {
      return "Please wait. Fetching environment types under Advanced Settings.";
    }

    if (!!isCreatingProject) {
      return "Please wait. Creating the project.";
    }

    let isInvalidFields = false;
    isInvalidFields = isInvalidFields || !(values as $TSFixMe)?.name?.trim();
    isInvalidFields = isInvalidFields || !selectedEnv?.id;

    if (!!isInvalidFields) {
      return "Please enter all mandatory fields to proceed with this action";
    }

    return "";
  }, [isEnvironmentsLoading, isCreatingProject, values, selectedEnv?.id]);

  const environmentMenuItem = ({ env, isEnvLink = true }: { env: any; isEnvLink?: boolean }) => {
    const { name: type } = envMetadataFind(environmentsTypes, env) || {};
    return (
      <Box className={envMenuItem}>
        <Grid container direction="column">
          <Box>
            {!!isEnvLink && !!env?.id ? (
              <Link
                className={classes.link}
                to={`/environments/${env?.id}`}
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                }}>
                <Text value={env?.name} />
              </Link>
            ) : (
              <Text value={env?.name} />
            )}
          </Box>

          <span className={classes.envTypeSpan}>
            <EnvironmentTypeConfig
              envType={type}
              cores={env?.cores}
              memInMbs={env?.memInMbs}
              diskInGbs={env?.diskInGbs}
            />
          </span>
        </Grid>
        <div
          className={clsx(
            roundEnvStatus,
            styles.envStatus,
            styles[env?.launchStatus?.trim()?.toLowerCase()]
          )}>
          {statusValues[env?.launchStatus?.trim()?.toLowerCase()]?.text ||
            env?.launchStatus ||
            "Unknown"}
        </div>
      </Box>
    );
  };

  return (
    <>
      {showConfirmScreen && isDirty && (
        <NewThemeWrapper>
          <ConfirmCloseWindow onConfirm={handleCloseModal} onCancel={handleCancelClose} />
        </NewThemeWrapper>
      )}

      <Modal
        isNewTheme
        title="Create a new project"
        open={open}
        height={596}
        onClose={handleCloseAttempt}
        onCancel={handleCloseAttempt}
        onSubmit={handleSubmit}
        cancelButtonLabel="Cancel"
        submitButtonId="createProject"
        tooltipTitle={!!disabledCreateActionMessage ? disabledCreateActionMessage : undefined}
        submitButtonLabel="Create Project"
        submitDataTestId="submitProjectButton"
        cancelDataTestId="cancelProjectButton"
        submitButtonClassName={classes.submitBtn}
        isSubmitLoading={isCreatingProject}
        submitDisabled={!!disabledCreateActionMessage || showProjectNameError[0]}>
        <div className={classes.container}>
          <Grid
            container
            item
            style={{ width: "calc(50% + 30px)" }}
            className={classes.inputsContainer}>
            <Grid item xs={12}>
              <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                PaperProps={{
                  style: {
                    width: inputRef.current ? inputRef.current.clientWidth : undefined
                  }
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                open={Boolean(anchorEl)}
                onClose={handleSuggestionClose}>
                <Typography style={{ padding: "6px 16px", opacity: 0.5 }}>
                  Auto Suggestions
                </Typography>
                {map(suggestionsList, (suggestion) => (
                  <MenuItem
                    key={suggestion}
                    id={suggestion}
                    value={suggestion}
                    onClick={() => handleSuggestionClick(suggestion)}>
                    <OverflowTooltip value={suggestion} title={suggestion} />
                  </MenuItem>
                ))}
              </Menu>
              <Field
                id="name"
                inputProps={{ ref: inputRef }}
                data-testid="projectName"
                label="Project Name"
                value={(values as $TSFixMe).name}
                onChange={(event) => {
                  handleChange(event, "name");
                }}
                error={showProjectNameError[0]}
                helperText={showProjectNameError[0] && showProjectNameError[1]}
                required
                variant="outlined"
                size="small"
              />
              <Typography variant="caption" className={classes.helperText}>
                This name will be the project identifier.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                id="description"
                label="Project description"
                data-testid="projectDescription"
                onChange={handleChange}
                multiline
                minRows={3}
                maxRows={3}
                value={(values as $TSFixMe).description}
                variant="outlined"
              />
              <Typography
                style={{ marginBottom: 0 }}
                variant="caption"
                className={classes.helperText}>
                A short description is useful to add context to your project.
              </Typography>
            </Grid>
            <NewThemeWrapper>
              <Button
                style={{ marginBottom: "10px", height: "36px", fontWeight: 400 }}
                color="primary"
                size="small"
                endIcon={
                  showAdvanced ? (
                    <ExpandLess style={{ color: "black" }} />
                  ) : (
                    <ExpandMore style={{ color: "black" }} />
                  )
                }
                onClick={() => setShowAdvanced(!showAdvanced)}>
                Advanced Settings
              </Button>
            </NewThemeWrapper>
            {showAdvanced && (
              <Grid item xs={12}>
                <FormControl variant="outlined">
                  <InputLabel shrink htmlFor="environmentId">
                    Environment Type
                  </InputLabel>

                  <SelectWithLoading
                    label="Environment Type"
                    required
                    isSorted
                    isLoading={isEnvironmentsLoading}
                    data-testid="projectEnvironment"
                    id="environmentId"
                    name="environmentId"
                    defaultValue={(selectedEnv as $TSFixMe)?.id || ""}
                    render={(item) =>
                      environmentMenuItem({
                        env: find(environments, { id: item.value }),
                        isEnvLink: false
                      })
                    }
                    {...(!isEnvironmentsLoading && !isEmpty(environments)
                      ? {
                          renderValue: (value) => {
                            return environmentMenuItem({
                              env: find(environments, { id: value }),
                              isEnvLink: false
                            });
                          }
                        }
                      : {})}
                    options={map(environments, (item) => ({
                      value: item.id,
                      label: item.name
                    }))}
                    value={(selectedEnv as $TSFixMe)?.id || ""}
                    onChange={handleEnvironmentChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                    input={<OutlinedInput notched label="Environment Type" />}
                    displayEmpty
                  />
                </FormControl>
                <Typography
                  variant="caption"
                  className={classes.helperText}
                  style={{ marginBottom: 0 }}>
                  Optionally choose the environment this project will use.
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.imageSelectionGrid}>
            <PreviewImageSelectorNew
              isNewTheme
              previewType="project"
              onChange={setImageBase64}
              defaultImage={default_image}
            />
          </Grid>
        </div>
      </Modal>
    </>
  );
};

export default CreateProject;
