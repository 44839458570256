import React, { useEffect, useMemo, useState } from "react";

// Packages
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { isEmpty, keyBy, map, mapValues, trim } from "lodash";

// MUI
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

// Icons
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { RenderText } from "src/utils";

// Hooks
import { useManualJobRun } from "src/hooks/api";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { useStyles as useSubtopBarStyles } from "src/components/SubtopBar/styling";

// Constants
import { JobsHelperText } from "../../utils/Jobs.constants";

// Styles
import { useStyles } from "./ManualJobRun.styles";
import { validateNameField } from "src/utils/formFieldUtils";

enum JobRunFormFields {
  JobRunName = "jobRunName",
  JobParameters = "jobParameters"
}

type JobParameter = {
  key: string;
  value: string;
};

type JobRun = {
  [JobRunFormFields.JobRunName]: string;
  [JobRunFormFields.JobParameters]: JobParameter[];
};

const defaultValues = {
  [JobRunFormFields.JobRunName]: "",
  [JobRunFormFields.JobParameters]: []
};

const ManualJobRun = (props: $TSFixMe) => {
  const { close, projectId, jobId, jobParametersData, navigateToJobRunsPage } = props || {};

  const { barMainButton, actionButton } = useSubtopBarStyles();
  const classes = useStyles();

  const inputProps = { classes: { root: classes.inputField } };

  const inputLabelProps = {
    classes: { root: classes.inputLabel, shrink: classes.inputLabelShrink }
  };

  // Form - STARTS >>
  const {
    reset,
    getValues,
    control,
    trigger,
    formState: { isValid, errors }
    // setError,
    // clearErrors
  } = useForm<JobRun>({
    mode: "onChange", // Validate onChange
    reValidateMode: "onChange", // Re-validate onChange
    defaultValues
  });

  const { fields } = useFieldArray({ control, name: JobRunFormFields.JobParameters });

  useEffect(() => {
    reset({
      ...getValues(),
      [JobRunFormFields.JobParameters]: map(jobParametersData, (value, key) => ({ key, value }))
    });
  }, [jobParametersData]);
  // << ENDS - Form

  // States - STARTS >>
  const [isAttemptedSubmit, setIsAttemptedSubmit] = useState(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isRunning,
    mutateAsync: manualJobRunMutation,
    reset: resetManualJobRunMutation
  } = useManualJobRun();
  // << ENDS - Query hooks

  const runJob = async () => {
    setIsAttemptedSubmit(true);
    trigger();

    if (!isValid) {
      return;
    }

    projectId && checkEnvRelaunch(projectId);

    const jobRunName = getValues()?.[JobRunFormFields.JobRunName];
    const jobParameters = mapValues(
      keyBy(getValues()?.[JobRunFormFields.JobParameters], "key"),
      "value"
    );

    const payload = {
      jobId,
      deleteOld: false,
      variables: jobParameters,
      useOldMetadata: true,
      ...(!!jobRunName ? { runId: trim(jobRunName) } : {})
    };

    await resetManualJobRunMutation();
    await manualJobRunMutation(payload, {
      onSuccess: (data) => {
        let toastDetails = {
          message: JobsHelperText.ManualJobRunStartedMessage,
          type: ToastTypes.Success
        };

        let runDetailsResponseStatus = "";
        if (!isEmpty(data)) {
          runDetailsResponseStatus = data?.status;
          if (runDetailsResponseStatus === "FAILURE") {
            toastDetails = {
              message: data?.error || "Scheduler run failed!",
              type: ToastTypes.Error
            };
          }
        }

        toastWrapper({ type: toastDetails.type, content: toastDetails.message });

        if (runDetailsResponseStatus !== "FAILURE") {
          navigateToJobRunsPage();
        }
      }
    });
  };

  const validateNameChange = (value: any) => {
    const { error } = validateNameField({
      fieldName: value,
      fieldNameLabel: `scheduler-run name`
    });
    if (error && error !== "The scheduler-run name cannot be blank.") {
      return error;
    } else {
      return true;
    }
  };

  const disabledRunActionMessage = useMemo(() => {
    if (isRunning) {
      return "The run action is in progress. Please wait.";
    }

    if (!isValid) {
      return "Invalid project variables.";
    }

    return "";
  }, [isRunning, isValid]);

  return (
    <Drawer
      open
      anchor="right"
      variant="temporary"
      onClose={close}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      disableEscapeKeyDown={isRunning}>
      <SubTopNavBarWrapper
        variant="drawer"
        onDrawerClose={close}
        subTopNavBarLeftSection={{
          component: (
            <RenderText color="textSecondary" isOverflowTooltip>
              Manual Run Configuration
            </RenderText>
          )
        }}
      />

      <Toolbar />
      <Grid container direction="column" wrap="nowrap" className={classes.container}>
        <Grid container>
          <Controller
            control={control}
            name={JobRunFormFields.JobRunName}
            rules={{ validate: validateNameChange }}
            render={({ field }) => (
              <TextField
                {...field}
                id="jobRunName"
                type="text"
                variant="outlined"
                size="small"
                label="Run Name (Optional)"
                fullWidth
                error={!!errors[JobRunFormFields.JobRunName]}
                helperText={errors[JobRunFormFields.JobRunName]?.message}
                onChange={(event) => {
                  field.onChange(event);
                }}
                InputProps={{
                  ...inputProps,
                  endAdornment: (
                    <Tooltip title="System will generates a name, if this is left blank.">
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ marginLeft: 15, cursor: "help", opacity: 0.5 }}
                      />
                    </Tooltip>
                  )
                }}
                InputLabelProps={inputLabelProps}
                data-testid="jobRunNameField"
              />
            )}
          />
        </Grid>

        {!isEmpty(fields) && (
          <Grid container>
            <Paper variant="outlined" square className={classes.subContainer}>
              <Typography variant="body1" style={{ padding: "12px 14px " }}>
                Project Variables
              </Typography>

              {map(fields, (jobParameter, jobParameterIndex) => (
                <Grid
                  container
                  key={jobParameter?.id}
                  alignItems="center"
                  wrap="nowrap"
                  className={classes.inputRow}>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={`${JobRunFormFields.JobParameters}.${jobParameterIndex}.key`}
                      rules={{ required: true }}
                      render={({ field, fieldState }) => {
                        const { error } = fieldState;

                        return (
                          <TextField
                            {...field}
                            id={`jobParameterKey-${jobParameterIndex}`}
                            type="text"
                            variant="outlined"
                            size="small"
                            label="Key"
                            fullWidth
                            disabled
                            error={!!isAttemptedSubmit && !!error}
                            InputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            data-testid={`jobParameterKey${jobParameterIndex}Field`}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={`${JobRunFormFields.JobParameters}.${jobParameterIndex}.value`}
                      rules={{ required: true }}
                      render={({ field, fieldState }) => {
                        const { error } = fieldState;

                        return (
                          <TextField
                            {...field}
                            id={`jobParameterValue-${jobParameterIndex}`}
                            type="text"
                            variant="outlined"
                            size="small"
                            label="Value"
                            fullWidth
                            error={!!isAttemptedSubmit && !!error}
                            InputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            data-testid={`jobParameterValue${jobParameterIndex}Field`}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Paper>
          </Grid>
        )}
      </Grid>
      <footer className={classes.footerContainer}>
        <Tooltip title={disabledRunActionMessage}>
          <div>
            <Button
              variant="outlined"
              color="primary"
              onClick={close}
              disabled={!!disabledRunActionMessage}
              className={`${barMainButton} ${actionButton}`}>
              Cancel
            </Button>
          </div>
        </Tooltip>
        <Tooltip title={disabledRunActionMessage}>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => runJob()}
              disabled={!!disabledRunActionMessage || !!errors[JobRunFormFields.JobRunName]}>
              {isRunning ? <CircularProgress color="secondary" size={20} /> : "Run"}
            </Button>
          </div>
        </Tooltip>
      </footer>
    </Drawer>
  );
};

export default ManualJobRun;
