import { useMutation, UseMutationResult } from "@tanstack/react-query";

// Open API
import api from "utils/AxiosClient";
import { EnvDto, UpdateEnvDto } from "@rapidcanvas/rc-api-core";

export interface IUpdateEnvironmentPayload {
  id: string;
  payload: UpdateEnvDto;
  async?: boolean;
}

const useUpdateEnvironment = (): UseMutationResult<
  EnvDto,
  unknown,
  IUpdateEnvironmentPayload,
  unknown
> =>
  useMutation({
    mutationFn: async (payload: IUpdateEnvironmentPayload) => {
      if (!payload?.id || !payload?.payload) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () =>
          await api.EnvControllerApi.updateEnv(payload?.id, payload?.payload, payload.async)
      );
    }
  });

export default useUpdateEnvironment;
