import React, { useMemo, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import PreviewImageModal from "./PreviewImageModal";
import LoadableImage from "src/pages/private/ProjectsModule/pages/ProjectSettings/components/ImageSelectionModal/LoadableImage";

const useStyles = makeStyles({
  title: {
    color: "#123553",
    marginBottom: "12px"
  },
  chooseImageBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "24px",
    gap: "12px",
    width: ({ previewType }: any) =>
      previewType === "project" ? "400px" : previewType === "mini-project" ? "240px" : "164px",
    height: ({ previewType }: any) =>
      previewType === "project" ? "200px" : previewType === "mini-project" ? "120px" : "204px",
    background: "#f5f7f9",
    border: "1px dashed #415f80",
    cursor: "pointer",
    backgroundPosition: "center center",
    backgroundSize: ({ previewType }: any) =>
      previewType === "project"
        ? "400px 200px"
        : previewType === "mini-project"
          ? "240px 120px"
          : "164px 204px",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px",
    backgroundClip: "padding-box",
    "&::after": {
      content: '"Choose Image"',
      display: "block",
      position: "absolute",
      top: "calc(80% - 14px)",
      left: "calc(50% - 58px)",
      padding: "4px 12px",
      width: "116px",
      height: "28px",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      color: "#ffffff",
      textTransform: "none",
      backgroundColor: "#4646B5",
      boxShadow:
        "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
      borderRadius: "14px",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    "&:hover": {
      "&::after": {
        boxShadow:
          "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
      }
    },
    "&$chooseImageBoxSelected": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "10px",
      "&::after": {
        display: "none"
      },
      "&:hover": {
        "&::after": {
          display: "block",
          content: '"CHANGE"',
          backgroundColor: "rgba(0, 54, 86, 0.92)",
          border: "2px solid #f1957d",
          boxShadow: "none",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "-1px",
          left: "-1px",
          paddingTop: "25%",
          fontSize: "20px",
          fontWeight: 500
        }
      }
    }
  },
  chooseImageBoxSelected: {},
  chooseImageBoxMargin: {
    marginTop: "14px"
  }
});

type Props = {
  onChange?: $TSFixMe;
  prefix?: string;
  defaultImage?: string;
  noText?: boolean;
  images?: any[];
  isNewTheme?: boolean;
  previewType?: "project" | "mini-project" | "default";
};

const PreviewImageSelectorNew = ({
  onChange,
  prefix = "Project",
  defaultImage,
  noText = false,
  previewType = "default"
}: Props) => {
  const styles = useStyles({ previewType });
  const [open, setOpen] = useState(false);
  const [imageSubmitted, setImageSubmitted] = useState<string>();

  const src = useMemo(() => imageSubmitted || defaultImage || "", [imageSubmitted, defaultImage]);

  return (
    <Box display="flex" flexDirection="column">
      {!noText && (
        <>
          <Typography variant="h6" className={styles.title}>
            {prefix} Preview Image
          </Typography>
          <Typography variant="body2" style={{ color: "#396083" }}>
            Choose an image for this {prefix.toLowerCase()}. It will appear as a preview on the
            dashboard and {prefix.toLowerCase()}s list.
          </Typography>
        </>
      )}

      {!!src &&
        (/(http|https):\/\//.test(src) ? (
          <Button
            id="preview-image-selector-button"
            data-testid="projectPreviewImage"
            style={{ backgroundImage: `url(${src})` }}
            className={clsx(styles.chooseImageBox, {
              [styles.chooseImageBoxSelected]: imageSubmitted,
              [styles.chooseImageBoxMargin]: !noText
            })}
            onClick={() => setOpen(true)}
          />
        ) : (
          <LoadableImage
            styles={styles}
            src={src}
            imageSubmitted={imageSubmitted}
            noText={noText}
            setOpen={setOpen}
          />
        ))}

      {open ? (
        <PreviewImageModal
          previewType={previewType}
          onSubmit={(img) => {
            setImageSubmitted(img);
            onChange(img?.split(";base64,")?.[1] || img);
            setOpen(false);
          }}
          handleClose={() => setOpen(false)}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PreviewImageSelectorNew;
