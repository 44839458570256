import { isNil } from "lodash";
import {
  getAPI,
  postAPI,
  deleteAPIWithRethrow,
  putAPIWithRethrow,
  postAPIWithRethrow,
  getAPIWithRethrow
} from "../utils/apiService";

type DataByColumnBody = {
  scenarioId: string;
  rowsStart: number;
  rowsEnd: number;
  cols: string[];
};

enum DownloadEntityDefaultOptions {
  FileType = "CSV",
  Separator = ",",
  Encoding = "utf-8"
}

// Projects
export const getDashboardProjects = async () => await getAPI("/v2/projects/dashboard");

export const getProjectById = async (id: $TSFixMe, includeJobCount = false) =>
  await getAPI(`/v2/projects/${id}${includeJobCount ? "?includeJobCount=true" : ""}`);

// Entities
export const createEntityWithRethrow = async (entityData: $TSFixMe, config = {}) =>
  await postAPIWithRethrow("/v2/entities", entityData, config);

export const saveExpandableNodesWithRethrow = async ({
  projectId,
  payload
}: {
  projectId: string;
  payload: $TSFixMe;
}) => await putAPIWithRethrow(`/v2/project-canvas/${projectId}/update-collapsed`, payload);

export const getEntitiesByProjectId = async (projectId: $TSFixMe) =>
  await getAPI(`/v2/entities?projectId=${projectId}`);

export const getEntitiesByProjectIdWithRethrow = async (
  projectId: $TSFixMe,
  scenarioId?: string,
  jobRunId?: string,
  datasetId?: string
) =>
  await getAPIWithRethrow(
    `/v2/entities?projectId=${projectId}${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}${
      !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
    }${!!datasetId ? `&id=${datasetId}` : ``}`
  );

export const updateEntityWithRethrow = async (body: $TSFixMe, config = {}) =>
  await putAPIWithRethrow(`/v2/entities`, body, config);

export const getEntityFeatures = async (id: $TSFixMe, scenarioId?: string, jobRunId?: string) =>
  await getAPI(
    `/v2/features?entityId=${id}${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}${
      !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
    }`
  );

export const getEntityFeaturesWithRethrow = async (id: $TSFixMe) =>
  await getAPIWithRethrow(`/v2/features?entityId=${id}`);

export const getEntityDataByPost = async (
  entityId: $TSFixMe,
  scenarioId?: string,
  projectRunId?: string
) =>
  await postAPIWithRethrow(
    `/v2/entities/${entityId}/data?${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}${
      !!projectRunId ? `&projectRunEntryId=${projectRunId}` : ``
    }`,
    { scenarioId, rowsStart: 0, rowsEnd: 100, cols: [], projectRunEntryId: projectRunId }
  );

export const getEntityData = async (
  entityId: $TSFixMe,
  scenarioId?: string,
  projectRunId?: string,
  limit = -1
) =>
  await getAPI(
    `/v2/entities/${entityId}/data?${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}${
      !!projectRunId ? `&projectRunEntryId=${projectRunId}` : ``
    }${limit !== -1 ? `&limit=${limit}` : ""}`
  );

export const getEntityDataByColumn = async (entityId?: string, body?: DataByColumnBody) =>
  await postAPI(`/v2/entities/${entityId}/data`, body);

export const deleteEntityWithRethrow = async (entityId: $TSFixMe) =>
  await deleteAPIWithRethrow(`/v2/project-step?type=ENTITY&id=${entityId}`, {});

// Segments

// Files
export const createFilesWithRethrow = async (
  fileData: $TSFixMe,
  id: $TSFixMe,
  options?: $TSFixMe
) => await postAPIWithRethrow(`/v2/data-files/ENTITY/${id}`, fileData, options);

// Sources
// Scenarios code starts >>
export const getScenarioById = async (scenarioId: $TSFixMe) =>
  await getAPIWithRethrow(`/v2/scenarios/${scenarioId}`);

export const getScenarios = async ({ projectId, jobId }: $TSFixMe = {}) =>
  await getAPI(
    `/v2/scenarios?${!!projectId ? `&projectId=${projectId}` : ``}${
      !!jobId ? `&projectRunId=${jobId}` : ``
    }`
  );

export const getScenariosWithRethrow = async ({ projectId, jobId }: $TSFixMe = {}) =>
  await getAPIWithRethrow(
    `/v2/scenarios?${!!projectId ? `&projectId=${projectId}` : ``}${
      !!jobId ? `&projectRunId=${jobId}` : ``
    }`
  );
// << Scenarios code ends

// Jobs APIs - STARTS >>
export const getProjectCanvas = async ({ projectId, scenarioId, jobId, jobRunId }: $TSFixMe = {}) =>
  await getAPI(
    `/v2/project-canvas/${projectId}?chartsEnabled=true${
      !!scenarioId ? `&scenarioId=${scenarioId}` : ""
    }${!!jobId ? `&projectRunId=${jobId}` : ""}${
      !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ""
    }`
  );

export const getJobsWithRethrow = async (projectId: $TSFixMe) =>
  await getAPIWithRethrow(`/v2/project-runs?projectId=${projectId}`);

export const getJobByIdWithRethrow = async (jobId: $TSFixMe) =>
  await getAPIWithRethrow(`/v2/project-runs/${jobId}`);

export const saveJobWithRethrow = async (payload: $TSFixMe) =>
  await postAPIWithRethrow(`/v2/project-runs`, payload);

export const updateJobWithRethrow = async (payload: $TSFixMe) =>
  await putAPIWithRethrow(`/v2/project-runs`, payload);

export const publishJobWithRethrow = async (jobId: string, shouldDispatchEvent = true) =>
  await postAPIWithRethrow(`/v2/project-runs/publish/${jobId}`, {}, {}, shouldDispatchEvent);

export const runJobWithRethrow = async (payload: $TSFixMe = {}) =>
  await postAPIWithRethrow(`/v2/project-runs/${payload?.jobId}/manual-run`, payload);

export const getJobsDashboardWithRethrow = async () =>
  await getAPIWithRethrow(`/v2/project-runs/dashboard`);

export const deleteJobWithRethrow = async (jobId: $TSFixMe) =>
  await deleteAPIWithRethrow(`/v2/project-runs/${jobId}`, {});

export const getJobRunHistory = async (jobId: $TSFixMe) =>
  await getAPI(`/v2/project-runs/${jobId}/entries`);

export const getJobRunHistoryDetails = async (jobRunId: $TSFixMe, shouldDispatchEvent = true) =>
  await getAPI(`/v2/project-runs/${jobRunId}/entry-details`, {}, shouldDispatchEvent);

export const getJobRunHistoryDetailsWithRethrow = async (jobRunId: $TSFixMe) =>
  await getAPIWithRethrow(`/v2/project-runs/${jobRunId}/entry-details`);

export const downloadEntity = async (payload: $TSFixMe = {}) => {
  const thisPayload: $TSFixMe = {
    fileType: payload?.fileType || DownloadEntityDefaultOptions.FileType,
    separator: payload?.separator || DownloadEntityDefaultOptions.Separator,
    encoding: payload?.encoding || DownloadEntityDefaultOptions.Encoding,
    downloadRaw: isNil(payload?.downloadRaw) ? true : payload?.downloadRaw,
    ...(!!payload?.scenarioId ? { scenarioId: payload?.scenarioId } : {}),
    ...(!!payload?.projectRunEntryId ? { projectRunEntryId: payload?.projectRunEntryId } : {}),
    ...(!!payload?.entityName ? { name: payload?.entityName } : {}),
    ...(!!payload?.dataFileId ? { dataFileId: payload?.dataFileId } : {})
  };

  return await postAPI(`/v2/entities/${payload?.entityId}/download`, thisPayload);
};

export const getJobRunHistoryLogsWithRethrow = async (jobRunId: $TSFixMe) =>
  await getAPIWithRethrow(`/v2/project-runs/${jobRunId}/logs`);

export const deleteJobRunWithRethrow = async (jobRunId: $TSFixMe) =>
  await deleteAPIWithRethrow(`/v2/project-runs/${jobRunId}/entry`, {});

export const getDestinationsWithRethrow = async (jobId: $TSFixMe) =>
  await getAPIWithRethrow(`/v2/project-run-syncs?projectRunId=${jobId}`);

export const createDestinationWithRethrow = async (payload: $TSFixMe = {}) =>
  await postAPIWithRethrow(`/v2/project-run-syncs`, payload);

export const updateDestinationWithRethrow = async (payload: $TSFixMe = {}) =>
  await putAPIWithRethrow(`/v2/project-run-syncs`, payload);

export const deleteDestinationWithRethrow = async (destinationId: $TSFixMe) =>
  await deleteAPIWithRethrow(`/v2/project-run-syncs/${destinationId}`, {});
// << ENDS - Jobs APIs

// Data upload APIs - STARTS >>
export const getDataSourceFilesWithRethrow = async ({ data = {}, options = {} }: $TSFixMe) =>
  await postAPIWithRethrow("/v2/data-source/list-files", data, options);

export const getSqlSampleDataWithRethrow = async ({ data = {} }: $TSFixMe) =>
  await postAPIWithRethrow("/v2/data-source/sample-data", data);

export const getSignedUrlWithRethrow = async ({ params = {}, options = {} }: $TSFixMe) =>
  await postAPIWithRethrow(
    `/v2/entities/${params?.entityId}/generate-file-upload-url`,
    {},
    options
  );

export const executeSignedUrlWithRethrow = async ({
  url = "",
  data = null,
  options = {},
  shouldDispatchEvent = true
}: $TSFixMe) => await putAPIWithRethrow(url, data, options, shouldDispatchEvent);

export const processFilesWithRethrow = async ({ params = {}, options = {} }: $TSFixMe) =>
  await postAPIWithRethrow(`/v2/entities/${params?.entityId}/process-uploaded-file`, {}, options);

export const applyConfigWithRethrow = async (data: $TSFixMe = {}) =>
  await putAPIWithRethrow(`/v2/entities`, data);

export const getDataSourcesSchemaWithRethrow = async () =>
  await getAPIWithRethrow(`/v2/data-source/schema`);
// << ENDS - Data upload APIs
