import React, { useMemo, useState } from "react";
import _, { includes, isEmpty, map, pickBy, size } from "lodash";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  makeStyles
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { useForm as useCustomForm } from "src/utils/useForm";
import DataAppModelControls from "../DataAppsDashboard/DataApps/CreateDataApp/DataAppModelControls";
import Drawer from "components/Drawer/CustomDrawer";
import EnableResponseCache from "../DataAppsDashboard/DataApps/CreateDataApp/EnableResponseCache";
import PreviewImageSelector from "pages/Projects/ProjectSettings/PreviewImageSelector";
import SelectAskAIInputType from "../DataAppsDashboard/DataApps/CreateDataApp/SelectAskAIInputType";
import SelectAskAILLMModel from "../DataAppsDashboard/DataApps/CreateDataApp/SelectAskAILLMModel";
import SelectDataAppSecurity from "../DataAppsDashboard/DataApps/CreateDataApp/SelectDataAppSecurity";
import SelectEnvironment from "src/pages/private/ProjectsModule/pages/PredictionService/components/SelectEnvironment";
import {
  CreateDataAppRequestDtoDataAppTypeEnum,
  DataAppDtoDataAppTypeEnum,
  DataappAskAIConfigInputTypeEnum,
  DataappAskAIConfigLlmTypeEnum,
  PublishAppTemplateRequestDtoSourceEnum,
  UpdateDataAppRequestDto
} from "@rapidcanvas/rc-api-core";
import { DataAppAppTypeEnum, DataAppType } from "../DataApps.type";
import { Spinner } from "src/components";
import { dataAppConfigFields } from "../DataAppsDashboard/DataApps/CreateDataApp/CreateDataAppForm";
import { offlineImages, cdnImages } from "src/pages/DataApps/common/DataAppImages";
import { handleResponse } from "src/utils/apiService";
import { useUpdateDataAppMutation } from "src/hooks/api";
import { validateNameField } from "src/utils/formFieldUtils";
import DataAppAccess from "../DataAppsDashboard/DataApps/CreateDataApp/DataAppAccess";
import DataAppSelectJob from "../DataAppsDashboard/DataApps/CreateDataApp/DataAppSelectJob";
import {
  DescriptionCharacterLimit,
  DescriptionCharacterLimitMessage,
  DescriptionInfo
} from "src/pages/private/ProjectsModule/utils";
import DataAppViewCode from "../DataAppsDashboard/DataApps/CreateDataApp/DataAppViewCode";
import DataAppEnvironment, {
  validateDataAppEnvConfig
} from "../DataAppsDashboard/DataApps/CreateDataApp/DataAppEnvironment";
import DataAppMetaData from "../DataAppsDashboard/DataApps/CreateDataApp/DataAppMetaData";
import DataAppUploadZip from "../DataAppsDashboard/DataApps/CreateDataApp/DataAppUploadZip";
import useUpdateDataAppWithZip from "src/hooks/api/dataapps/UseUpdateDataAppWithZip";
import DataAppSelectType from "../DataAppsDashboard/DataApps/CreateDataApp/DataAppSelectType";
import ColumnHyperlinks from "../DataAppsDashboard/DataApps/CreateDataApp/ColumnHyperlinks";

const useStyles = makeStyles({
  drawerPaper: {
    width: "40%",
    overflowX: "hidden",
    minWidth: "785px"
  },
  btnList: {
    height: "70px",
    backgroundColor: "#F5F5F5",
    padding: "20px",
    alignItems: "center",
    gap: "20px"
  },

  outlinedBtn: {
    color: "#008FE4",
    borderColor: "#008FE4"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "capitalize",
    fontSize: "14px"
  },
  titleString: {
    fontSize: "18px"
  },
  previewImage: {
    width: "auto"
  },
  rightInputs: {
    flexWrap: "nowrap",
    gap: "30px",
    width: "100%"
  },
  inputsWrap: {
    flexWrap: "nowrap",
    gap: "16px",
    padding: "16px"
  }
});

export const EditDataAppDrawer = ({
  dataApp,
  onClose,
  onReload
}: {
  dataApp: DataAppType;
  onClose: () => void;
  onReload?: () => void;
}) => {
  const classes = useStyles();
  const images = !!process.env.ENVIRONMENT ? map(cdnImages, (image) => `/${image}`) : offlineImages;
  const [image, setImage] = useState(dataApp.iconUrl);
  const { askAIConfig, dataAppType, appTemplateType } = dataApp;
  const [hyperlinkError, setHyperlinkError] = useState<string>("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    setError,
    clearErrors
  } = useForm({
    defaultValues: {
      name: dataApp.displayName || dataApp.name,
      description: dataApp.description,
      [dataAppConfigFields.customEnvId]: dataApp.askAIConfig?.customEnvId
    }
  });

  const {
    values,
    handleInputChange,
    setValues: setCustomValues
  } = useCustomForm({
    [dataAppConfigFields.llmType]: askAIConfig?.llmType,
    [dataAppConfigFields.inputType]: askAIConfig?.inputType,
    [dataAppConfigFields.dataSharingStrategy]: askAIConfig?.dataSharingStrategy,
    [dataAppConfigFields.enableCache]: askAIConfig?.enableCache,
    [dataAppConfigFields.systemMessage]: askAIConfig?.systemMessage,
    [dataAppConfigFields.isPrivate]: dataApp.isPrivate,
    [dataAppConfigFields.projectRunId]: askAIConfig?.projectRunId,
    [dataAppConfigFields.showAICode]: dataApp.showAICode ?? true,
    [dataAppConfigFields.appType]: dataApp.appType,
    [dataAppConfigFields.zipEnv]: {
      name: dataApp.envType,
      cores: dataApp.cores,
      diskInGbs: dataApp.diskInGbs,
      memInGbs: dataApp.memInMbs ? dataApp.memInMbs / 1024 : undefined
    },
    [dataAppConfigFields.metadata]: dataApp.metadata ? JSON.stringify(dataApp.metadata) : "",
    [dataAppConfigFields.allowColumnHyperLink]: askAIConfig?.allowColumnHyperLink || false,
    [dataAppConfigFields.columnsHyperlinksMapping]:
      dataApp.metadata?.columns_hyperlinks_mapping || ""
  });

  const dataAppName = watch("name");
  const dataAppDescription = watch("description");
  const customEnvId = watch(dataAppConfigFields.customEnvId);
  const updateDataAppMutation = useUpdateDataAppMutation();
  const updateDataAppWithZip = useUpdateDataAppWithZip();

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    const { error } = validateNameField({
      fieldName: value,
      fieldNameLabel: `dataApp name`
    });
    if (error) {
      setError("name", {
        type: "manual",
        message: error
      });
    } else {
      clearErrors("name");
    }
  };

  const getAskAIConfigValues = () => {
    const withoutPrivate = _.omit(values, dataAppConfigFields.isPrivate);

    switch (_.get(values, dataAppConfigFields.inputType)) {
      case DataappAskAIConfigInputTypeEnum.PredictionService:
        return _.pick(withoutPrivate, dataAppConfigFields.inputType);

      case DataappAskAIConfigInputTypeEnum.RagFiles:
        return _.omit(withoutPrivate, dataAppConfigFields.dataSharingStrategy);

      default:
        return withoutPrivate;
    }
  };

  const onSubmit = (data: { name: string; description: string; customEnvId?: string }) => {
    const { error } = validateNameField({
      fieldName: data.name.trim(),
      fieldNameLabel: `dataApp name`
    });
    if (error) {
      handleResponse({ errorMessage: error });
      return;
    }

    let md = _.get(values, dataAppConfigFields.metadata);
    let metadata: any;
    const hyperlinksStr = dataAppConfigFields.allowColumnHyperLink
      ? _.get(values, dataAppConfigFields.columnsHyperlinksMapping)
      : "";
    const payload = {
      ...(dataApp as UpdateDataAppRequestDto),
      displayName: data.name,
      description: data.description,
      iconUrl: image,
      showAICode: _.get(values, dataAppConfigFields.showAICode),
      askAIConfig: {
        ...dataApp.askAIConfig,
        ...getAskAIConfigValues(),
        customEnvId: data.customEnvId,
        allowColumnHyperLink: _.get(values, dataAppConfigFields.allowColumnHyperLink)
      },
      metadata: _.merge(
        {},
        metadata,
        hyperlinksStr ? { columns_hyperlinks_mapping: hyperlinksStr } : {}
      ),
      cores: _.toNumber(_.get(values, [dataAppConfigFields.zipEnv, "cores"])),
      diskInGbs: _.toNumber(_.get(values, [dataAppConfigFields.zipEnv, "diskInGbs"])),
      memInMbs: _.toNumber(_.get(values, [dataAppConfigFields.zipEnv, "memInGbs"])) * 1024,
      envType: _.get(values, [dataAppConfigFields.zipEnv, "name"]),
      isPrivate: _.includes([true, "true"], _.get(values, dataAppConfigFields.isPrivate))
    };

    if (dataAppType === CreateDataAppRequestDtoDataAppTypeEnum.Custom) {
      if (md) {
        try {
          metadata = JSON.parse(md);
        } catch {
          handleResponse({ errorMessage: "Unable to parse JSON metadata. Please use valid JSON" });
          return;
        }
      }
      metadata = _.merge(
        {},
        metadata,
        hyperlinksStr ? { columns_hyperlinks_mapping: hyperlinksStr } : {}
      );
      const newZipFile = _.get(values, dataAppConfigFields.zipFile);
      if (newZipFile) {
        updateDataAppWithZip.mutate(
          {
            payload: {
              ...payload,
              metadata
            },
            templateId: dataApp.appTemplateId,
            id: dataApp.id,
            source: PublishAppTemplateRequestDtoSourceEnum.Tenant,
            appType: _.get(values, dataAppConfigFields.appType),
            file: _.get(values, [dataAppConfigFields.zipFile, "file"])
          },
          {
            onSuccess: () => {
              handleResponse({ successMessage: "DataApp updated successfully." });
              onReload?.();
              onClose();
            }
          }
        );
        return;
      }
    }

    updateDataAppMutation.mutate(
      {
        id: dataApp.id,
        payload: {
          ...payload,
          metadata: _.merge(
            {},
            metadata,
            hyperlinksStr ? { columns_hyperlinks_mapping: hyperlinksStr } : {}
          )
        }
      },
      {
        onSuccess: () => {
          handleResponse({ successMessage: "DataApp updated successfully." });
          if (
            dataApp.isPrivate !== _.get(values, dataAppConfigFields.isPrivate) ||
            dataApp.showAICode !== _.get(values, dataAppConfigFields.showAICode) ||
            dataApp.metadata?.columns_hyperlinks_mapping !==
              _.get(values, dataAppConfigFields.columnsHyperlinksMapping)
          ) {
            onReload?.();
          }
          onClose();
        }
      }
    );
  };

  const customEnvDisability = useMemo(
    () =>
      dataAppType !== CreateDataAppRequestDtoDataAppTypeEnum.Custom &&
      appTemplateType === DataAppAppTypeEnum.REACTJS &&
      !customEnvId,
    [dataAppType, appTemplateType, customEnvId]
  );

  const isInvalid = useMemo(() => {
    return dataAppType === CreateDataAppRequestDtoDataAppTypeEnum.Custom
      ? !!validateDataAppEnvConfig(
          "Cores",
          _.get(values, [dataAppConfigFields.zipEnv, "cores"]),
          12
        ) ||
          !!validateDataAppEnvConfig(
            "Memory",
            _.get(values, [dataAppConfigFields.zipEnv, "memInGbs"]),
            48
          ) ||
          !!validateDataAppEnvConfig(
            "Disk Space",
            _.get(values, [dataAppConfigFields.zipEnv, "diskInGbs"]),
            100
          )
      : false;
  }, [dataAppType, values]);

  const isAllEqual = useMemo(() => {
    const isCustomEnvEqual = _.isEqual(_.get(values, dataAppConfigFields.zipEnv), {
      name: dataApp.envType,
      cores: dataApp.cores,
      diskInGbs: dataApp.diskInGbs,
      memInGbs: dataApp.memInMbs ? dataApp.memInMbs / 1024 : undefined
    });

    const isHyperlinkEqual =
      (dataApp.metadata?.columns_hyperlinks_mapping || "") ===
      _.get(values, dataAppConfigFields.columnsHyperlinksMapping);

    const isMetaDataEqual =
      (JSON.stringify(dataApp.metadata) ?? "") === _.get(values, dataAppConfigFields.metadata);

    const isZipEqual = !_.get(values, dataAppConfigFields.zipFile);

    const isAllowHyperLinksEqual =
      askAIConfig?.allowColumnHyperLink === _.get(values, dataAppConfigFields.allowColumnHyperLink);
    if (!askAIConfig) {
      return (
        isCustomEnvEqual &&
        isMetaDataEqual &&
        isZipEqual &&
        isHyperlinkEqual &&
        isAllowHyperLinksEqual
      );
    }

    const { enableCache, llmType, inputType, dataSharingStrategy, systemMessage, projectRunId } =
      askAIConfig;

    return (
      enableCache === _.get(values, dataAppConfigFields.enableCache) &&
      llmType === _.get(values, dataAppConfigFields.llmType) &&
      inputType === _.get(values, dataAppConfigFields.inputType) &&
      dataSharingStrategy === _.get(values, dataAppConfigFields.dataSharingStrategy) &&
      systemMessage === _.get(values, dataAppConfigFields.systemMessage) &&
      dataApp.isPrivate === _.get(values, dataAppConfigFields.isPrivate) &&
      projectRunId === _.get(values, dataAppConfigFields.projectRunId) &&
      dataApp.showAICode === _.get(values, dataAppConfigFields.showAICode) &&
      isMetaDataEqual &&
      isCustomEnvEqual &&
      isZipEqual &&
      isHyperlinkEqual &&
      isAllowHyperLinksEqual
    );
  }, [values, dataApp]);

  const handleInputValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomValues({
      ...values,
      [e.target.name]: e.target.value,
      [dataAppConfigFields.llmType]:
        DataappAskAIConfigInputTypeEnum.RagFiles === e.target.value &&
        _.includes(
          [
            DataappAskAIConfigLlmTypeEnum.AzureOpenaiGpt4O,
            DataappAskAIConfigLlmTypeEnum.AnthropicClaude35Sonnet
          ],
          _.get(values, dataAppConfigFields.llmType)
        )
          ? DataappAskAIConfigLlmTypeEnum.OpenaiGpt4O
          : _.get(values, dataAppConfigFields.llmType)
    });
  };

  const handleEnvZipChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    handleInputValChange({
      target: { value: e.target.value, name: dataAppConfigFields.zipFile }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const footer = (
    <Grid container direction="row-reverse" className={classes.btnList}>
      <Tooltip
        title={
          customEnvDisability
            ? "Please wait until the environment is loaded for this DataApp's AskAI"
            : ""
        }>
        <span>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={
              isInvalid ||
              !dataAppName ||
              !!errors.description ||
              !!errors.name ||
              !!hyperlinkError ||
              customEnvDisability ||
              updateDataAppMutation.isLoading ||
              updateDataAppWithZip.isLoading ||
              (dataAppName === (dataApp.displayName || dataApp.name) &&
                dataAppDescription === dataApp.description &&
                customEnvId === dataApp.askAIConfig?.customEnvId &&
                dataApp.iconUrl === image &&
                isAllEqual)
            }
            data-testid="dataapp-edit-save-btn">
            {updateDataAppMutation.isLoading || updateDataAppWithZip.isLoading ? (
              <Spinner size={24} noPadding />
            ) : (
              "Save"
            )}
          </Button>
        </span>
      </Tooltip>
      <Button
        color="primary"
        variant="outlined"
        onClick={onClose}
        data-testid="dataapp-edit-cancel-btn">
        Cancel
      </Button>
    </Grid>
  );

  const title = (
    <div className={classes.title}>
      <div className={classes.titleString}>Edit DataApp</div>
      <IconButton onClick={onClose} color="primary">
        <Close />
      </IconButton>
    </div>
  );

  const updateHyperLinks = (parsedMapping: { [key: string]: string }) => {
    setCustomValues({
      ...values,
      [dataAppConfigFields.columnsHyperlinksMapping]: !isEmpty(
        pickBy(parsedMapping, (value, key) => key && value)
      )
        ? JSON.stringify(parsedMapping)
        : ""
    });
  };

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      data-testid="dataapp-config-drawer"
      hideCloseButton
      open
      width="40%"
      title={title}
      classes={{
        paper: classes.drawerPaper
      }}
      footer={footer}
      onClose={onClose}>
      <div style={{ maxHeight: "calc(100vh - 138px)", overflow: "auto" }}>
        <Grid container direction="row" className={classes.inputsWrap}>
          <Grid item className={classes.previewImage}>
            <PreviewImageSelector
              images={images}
              noText
              onChange={(img: $TSFixMe) => setImage(img)}
              defaultImage={image}
            />
          </Grid>
          <Grid container item direction="column" className={classes.rightInputs}>
            <TextField
              required
              fullWidth
              size="small"
              data-testid="dataAppName"
              {...register("name", {
                required: "The dataApp name cannot be blank.",
                setValueAs: (val: string) => val?.trim(),
                onChange: handleNameChange
              })}
              error={!!errors.name}
              helperText={errors.name && <>{errors.name?.message}</>}
              label="DataApp Name"
              variant="outlined"
            />
            <Grid container direction="column">
              <Tooltip title={dataAppDescription ?? ""}>
                <TextField
                  fullWidth
                  multiline
                  maxRows={6}
                  minRows={6}
                  data-testid="dataAppDescription"
                  size="small"
                  {...register("description", {
                    setValueAs: (val: string) => val?.trim(),
                    onChange: (event) => {
                      if (size(event.target.value) > DescriptionCharacterLimit) {
                        setError("description", {
                          type: "manual",
                          message: DescriptionCharacterLimitMessage
                        });
                      } else {
                        clearErrors("description");
                      }
                    }
                  })}
                  error={!!errors.description}
                  helperText={errors.description && <>{errors.description?.message}</>}
                  label="DataApp Description"
                  variant="outlined"
                />
              </Tooltip>
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  fontStyle: "italic",
                  opacity: 0.7
                }}>
                {DescriptionInfo}
              </span>
            </Grid>
          </Grid>
        </Grid>
        {dataAppType === CreateDataAppRequestDtoDataAppTypeEnum.Custom && (
          <Grid style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "12px" }}>
            <DataAppEnvironment
              readOnly
              value={_.get(values, dataAppConfigFields.zipEnv)}
              onChange={handleInputValChange as any}
            />
            <DataAppUploadZip
              placeholder="Upload a New Zip File"
              value={_.get(values, dataAppConfigFields.zipFile)}
              onChange={handleEnvZipChange}
            />
            <DataAppSelectType
              readOnly
              value={_.get(values, dataAppConfigFields.appType)}
              onChange={handleInputValChange}
            />
            <DataAppMetaData
              value={_.get(values, dataAppConfigFields.metadata)}
              onChange={handleInputValChange}
            />
          </Grid>
        )}
        {dataAppType !== CreateDataAppRequestDtoDataAppTypeEnum.Custom &&
          appTemplateType === DataAppAppTypeEnum.REACTJS && (
            <Grid style={{ padding: "16px" }}>
              <SelectEnvironment
                tooltipInfoMsg="The chosen environment will be used for queries in AskAI within this DataApp"
                value={customEnvId ?? ""}
                name={dataAppConfigFields.customEnvId}
                label="Environment"
                projectId={dataApp.projectId}
                shouldSetDefaultValue
                {...(register(dataAppConfigFields.customEnvId),
                {
                  onChange: (event) => {
                    const selectedValue = event.target.value;
                    setValue(dataAppConfigFields.customEnvId, selectedValue as string);
                  }
                })}
              />
            </Grid>
          )}
        {dataAppType === DataAppDtoDataAppTypeEnum.Askai && askAIConfig && (
          <Grid style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "10px" }}>
            <SelectAskAIInputType
              readOnly
              value={_.get(values, dataAppConfigFields.inputType)}
              onChange={handleInputValChange}
            />
            {_.get(values, dataAppConfigFields.inputType) ===
              DataappAskAIConfigInputTypeEnum.JobCanvas && (
              <DataAppSelectJob
                readOnly
                projectId={dataApp.projectId}
                value={_.get(values, dataAppConfigFields.projectRunId)}
                onChange={handleInputChange}
              />
            )}
            {_.get(values, dataAppConfigFields.inputType) !==
              DataappAskAIConfigInputTypeEnum.PredictionService && (
              <>
                <SelectAskAILLMModel
                  value={_.get(values, dataAppConfigFields.llmType)}
                  inputType={_.get(values, dataAppConfigFields.inputType)}
                  onChange={handleInputChange}
                />
                {_.get(values, dataAppConfigFields.inputType) !==
                  DataappAskAIConfigInputTypeEnum.RagFiles && (
                  <SelectDataAppSecurity
                    value={_.get(values, dataAppConfigFields.dataSharingStrategy)}
                    onChange={handleInputChange}
                  />
                )}
                <EnableResponseCache
                  value={_.get(values, dataAppConfigFields.enableCache)}
                  onChange={(e) =>
                    setCustomValues({
                      ...values,
                      [e.target.name]: e.target.checked
                    })
                  }
                />
              </>
            )}
          </Grid>
        )}
        {dataAppType === CreateDataAppRequestDtoDataAppTypeEnum.Askai &&
          appTemplateType === DataAppAppTypeEnum.REACTJS && (
            <Grid
              style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "10px" }}>
              <DataAppAccess
                value={_.get(values, dataAppConfigFields.isPrivate)}
                onChange={handleInputChange}
              />
              <DataAppViewCode
                value={_.get(values, dataAppConfigFields.showAICode)}
                onChange={(e) =>
                  setCustomValues({
                    ...values,
                    [e.target.name]: e.target.checked
                  })
                }
              />
            </Grid>
          )}
        {dataAppType === DataAppDtoDataAppTypeEnum.Askai &&
          askAIConfig &&
          _.get(values, dataAppConfigFields.inputType) !==
            DataappAskAIConfigInputTypeEnum.PredictionService && (
            <Grid
              style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "10px" }}>
              <DataAppModelControls values={values} onChange={handleInputChange} />
            </Grid>
          )}

        {dataAppType === DataAppDtoDataAppTypeEnum.RapidModel && (
          <Grid
            style={{
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "10px"
            }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Inputs</FormLabel>
              <RadioGroup
                row={true}
                aria-label={dataAppConfigFields.inputType}
                name={dataAppConfigFields.inputType}
                value={"Rapid Model"}>
                <FormControlLabel value={"Rapid Model"} control={<Radio />} label="Rapid Model" />
              </RadioGroup>
            </FormControl>
            <DataAppModelControls values={values} onChange={handleInputChange} />
          </Grid>
        )}
        {!includes(
          [
            DataappAskAIConfigInputTypeEnum.RagFiles,
            DataappAskAIConfigInputTypeEnum.PredictionService
          ],
          _.get(values, dataAppConfigFields.inputType)
        ) &&
          dataAppType === CreateDataAppRequestDtoDataAppTypeEnum.Askai && (
            <Grid
              style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "10px" }}>
              <ColumnHyperlinks
                value={_.get(values, dataAppConfigFields.allowColumnHyperLink)}
                onChange={(e) =>
                  setCustomValues({
                    ...values,
                    [e.target.name]: e.target.checked
                  })
                }
                mapping={_.get(values, dataAppConfigFields.columnsHyperlinksMapping)}
                updateHyperLinks={updateHyperLinks}
                updateHyperLinkError={setHyperlinkError}
              />
            </Grid>
          )}
      </div>
    </Drawer>
  );
};
