import React, { useMemo, useState } from "react";

import { Drawer, Grid, Paper, Box, Button, Tooltip, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { toastWrapper } from "src/utils/toastWrapper";
import { RenderText } from "src/utils";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { Field } from "src/components";

import { useJobContext } from "../Job/context/useJobContext";

import { useStyles } from "./JobGlobalVariables.styles";

const JobGlobalVariables = (props: $TSFixMe) => {
  const {
    close,
    isReadOnly = true,
    jobId,
    isUpdatingJob,
    updateJobMutation,
    resetUpdateJobMutation,
    refetchJob,
    jobParametersData
  } = props || {};

  const classes = useStyles();

  // Job context
  const { jobParametersContextState, setJobParametersContextState } = useJobContext() || {};

  // States - STARTS >>
  const [jobParameters, setJobParameters] = useState<$TSFixMe>(
    jobParametersData || jobParametersContextState
  );
  // << ENDS - States

  const onValueChange = (e: $TSFixMe, index: number, type: string) => {
    const thisJobParameters: $TSFixMe = [...jobParameters];
    thisJobParameters[index][type] = e?.target?.value;
    setJobParameters(() => [...thisJobParameters]);
  };

  const isValidJobParameters = () =>
    (jobParameters || [])?.every(
      (eachParameter: $TSFixMe) => !!eachParameter?.key && !!eachParameter?.value
    );

  const save = async () => {
    if (!isValidJobParameters()) {
      return;
    }

    resetUpdateJobMutation();

    let payload: $TSFixMe = { id: jobId };
    const thisJobParameters: $TSFixMe = {};
    (jobParameters || [])?.forEach((eachParameter: $TSFixMe) => {
      if (!!eachParameter?.key && !!eachParameter?.value) {
        thisJobParameters[eachParameter.key] = eachParameter.value;
      }
    });

    payload = { ...payload, variables: thisJobParameters };

    await updateJobMutation(payload, {
      onSuccess: () => {
        refetchJob();

        setJobParametersContextState(() => [...jobParameters]);

        toastWrapper({
          type: "success",
          content: "Project Variables saved successfully!"
        });

        close();
      }
    });
  };

  const disabledSaveActionMessage = useMemo(() => {
    if (isUpdatingJob) {
      return "The save action is in progress. Please wait.";
    }

    const isValidJobParameters = (jobParameters || [])?.every(
      (eachParameter: $TSFixMe) => !!eachParameter?.key && !!eachParameter?.value
    );

    if (!isValidJobParameters) {
      return "Invalid project variables.";
    }

    return "";
  }, [isUpdatingJob, jobParameters]);

  return (
    <Drawer
      open
      anchor="right"
      variant="temporary"
      onClose={close}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}
      disableEscapeKeyDown={isUpdatingJob}>
      <SubTopNavBarWrapper
        variant="drawer"
        onDrawerClose={close}
        subTopNavBarLeftSection={{
          component: (
            <RenderText color="textSecondary" isOverflowTooltip>
              Project Variables
            </RenderText>
          )
        }}
      />
      <Box p={2} className={classes.container}>
        {(jobParameters || [])?.length === 0 ? (
          <Alert severity="info">No project variables found!</Alert>
        ) : (
          <Paper variant="outlined" square className={classes.subContainer}>
            {(jobParameters || [])?.map((eachJobParameter: $TSFixMe, index: number) => {
              return (
                <Grid
                  container
                  alignItems="center"
                  key={index}
                  wrap="nowrap"
                  className={classes.inputRow}>
                  <Grid item xs={6}>
                    <Field
                      id={`parameterKeyLabel_${index}`}
                      label="Key"
                      // @ts-ignore
                      value={eachJobParameter?.key}
                      variant="outlined"
                      size="small"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      id={`parameterValue_${index}`}
                      label="Value"
                      // @ts-ignore
                      value={eachJobParameter?.value}
                      onChange={(e: $TSFixMe) => onValueChange(e, index, "value")}
                      error={!eachJobParameter?.value}
                      variant="outlined"
                      size="small"
                      disabled={isReadOnly}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Paper>
        )}
      </Box>
      {!isReadOnly && (
        <footer className={classes.footerContainer}>
          <Tooltip title={disabledSaveActionMessage}>
            <div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => save()}
                disabled={!!disabledSaveActionMessage}>
                {isUpdatingJob ? <CircularProgress color="secondary" size={20} /> : "Save"}
              </Button>
            </div>
          </Tooltip>
        </footer>
      )}
    </Drawer>
  );
};

export default JobGlobalVariables;
