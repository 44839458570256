import React from "react";
import { isNil } from "lodash";

import { Button, makeStyles } from "@material-ui/core";

import NewThemeWrapper from "src/styles/NewThemeWrapper";
import noDataAppImgSrc from "src/assets/images/no-dataApps-screen.png";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    flexDirection: "column",
    color: "#4c4c4c"
  },
  btn: {
    display: "flex",
    gap: "8px",
    alignItems: "center",

    "& button": {
      textTransform: "lowercase"
    }
  },
  noDataAppImg: {
    height: "90px",
    width: "200px"
  },
  root: {
    display: "flex",
    gap: "16px",
    marginBottom: "64px",
    alignItems: "center",
    justifyContent: "center",
    width: "inherit"
  }
});

interface IProps {
  title: string | React.JSX.Element;
  subTitle?: string | React.JSX.Element;
  onClear?: () => void;
  minHeight?: string;
}

const NoDataFoundDefault: React.FC<IProps> = (props) => {
  const { title, subTitle, onClear, minHeight } = props;
  const { wrapper, btn, noDataAppImg, root } = useStyles();

  return (
    <NewThemeWrapper>
      <div style={{ minHeight: minHeight ?? "calc(100vh - 350px)" }} className={root}>
        <img src={noDataAppImgSrc} className={noDataAppImg} />
        <div className={wrapper}>
          <span>{title}</span>
          {subTitle ?? (
            <div className={btn}>
              <span>Please refine your search</span>
              {!isNil(onClear) && (
                <>
                  <span> or </span>
                  <Button variant="contained" size="small" color="primary" onClick={onClear}>
                    clear search
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </NewThemeWrapper>
  );
};

export default NoDataFoundDefault;
