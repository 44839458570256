import { CodeCheckpointDto } from "@rapidcanvas/rc-api-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import api from "src/utils/AxiosClient";
import { CODE_CHECKPOINTS_KEY } from "./useGetCodeCheckpoints";

export const useDeleteCodeCheckpoint = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, recipeId }: { id: string; recipeId: string }) => {
      const response = await api.fetchResponse(() => api.CodeCheckpointControllerApi.delete9(id));
      queryClient.setQueryData<CodeCheckpointDto[]>(
        ["recipe", recipeId, CODE_CHECKPOINTS_KEY],
        (prevData = []) => prevData.filter((checkpoint) => checkpoint.id !== id)
      );
      return response;
    }
  });
};
