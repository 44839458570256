import api from "utils/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

interface IVariable {
  id: string;
}

const useDeleteApiKey = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ id }) => {
      return await api.fetchResponse(() => api.ApiAccessKeyControllerApi.deleteApiKey(id));
    }
  });
};

export default useDeleteApiKey;
