import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import { filter, find, map, size } from "lodash";

import CommonLoader from "src/components/CommonLoader";
import CreateDataAppForm from "./CreateDataAppForm";
import { AppTemplate, DataAppType } from "src/pages/DataApps/DataApps.type";
import { DFSRunConfigGroupDto } from "@rapidcanvas/rc-api-core";
import { QUERY_KEY_PROJECT_DETAILS } from "src/hooks/api/projects/useProjectDetails";
import { useQueryClient } from "@tanstack/react-query";

export interface IRecipes extends DFSRunConfigGroupDto {
  allowed: boolean;
  helpText: string;
}

interface IProps {
  isLoading: boolean;
  binaryClassRecipes?: DFSRunConfigGroupDto[];
  appTemplates?: AppTemplate[];
  dataApps: DataAppType[];
  onCancel: () => void;
  recipeLoading: boolean;
}

const CreateDataApp: React.FC<IProps> = (props) => {
  const queryClient = useQueryClient();
  const { dataApps, binaryClassRecipes, appTemplates, isLoading, onCancel, recipeLoading } = props;

  const dataAppRecipeIds = useMemo(
    () => dataApps?.map((dataApp) => dataApp.groupId).filter((id) => !!id),
    [dataApps]
  );

  const askAIDataAppTemplateId = useMemo(
    () => find(appTemplates, { name: "askai" })?.id,
    [appTemplates]
  );

  const allowedRecipes = useMemo(
    () =>
      map(binaryClassRecipes, (recipe) => {
        const isAllowed = !dataAppRecipeIds?.includes(recipe.id);
        return {
          ...recipe,
          allowed: isAllowed,
          helpText: !isAllowed ? "Recipe already has dataApp associated with it" : ""
        };
      }),
    [binaryClassRecipes, dataAppRecipeIds]
  );

  const { askAIDataApps } = useMemo(() => {
    const filtered = filter(dataApps, { appTemplateId: askAIDataAppTemplateId });

    return {
      askAIDataApps: filtered
    };
  }, [dataApps, askAIDataAppTemplateId]);

  const disabledAskAICreateActionMessage = useMemo(() => {
    if (!askAIDataAppTemplateId) {
      return "It seems that the associated appTemplate for this data app does not exist. Please contact the administrator for assistance";
    }

    return "";
  }, [askAIDataAppTemplateId, askAIDataApps]);

  const disabledAddDataAppActionMessage = useMemo(() => {
    if (size(binaryClassRecipes) === 0) {
      return "This is applicable only for successfully built Binary Classification/Regression/Binary Experimental/Multiclass Classification types of Rapid Model Recipes.";
    }

    return "";
  }, [binaryClassRecipes]);

  const handleSuccess = () => {
    queryClient.invalidateQueries([QUERY_KEY_PROJECT_DETAILS]);
    onCancel();
  };

  if (isLoading) {
    return <CommonLoader />;
  }

  return (
    <Box
      style={{
        padding: "30px 50px",
        maxWidth: "100%",
        margin: "0px 0px 0px 50px"
      }}>
      <CreateDataAppForm
        appTemplates={appTemplates}
        askAIDataAppTemplateId={askAIDataAppTemplateId}
        recipes={allowedRecipes}
        disabledAddDataAppActionMessage={disabledAddDataAppActionMessage}
        disabledAskAICreateActionMessage={disabledAskAICreateActionMessage}
        onBack={onCancel}
        onSuccess={handleSuccess}
        recipeLoading={recipeLoading}
      />
    </Box>
  );
};

export default CreateDataApp;
