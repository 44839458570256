import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";

interface IVariables {
  name: string;
}

const useDeleteArtifact = (): UseMutationResult<void, unknown, IVariables, unknown> => {
  return useMutation({
    mutationFn: async ({ name }) => {
      const { data } = await api.ArtifactsControllerApi.deleteArtifact(name);
      return data;
    }
  });
};

export default useDeleteArtifact;
