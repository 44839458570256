import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Color } from "@material-ui/lab/Alert/Alert";
import { PlayArrow, Stop } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";

import { DataAppType } from "pages/DataApps/DataApps.type";
import { DATAAPP_INTERVAL_IN_SEC, isInProgress } from "./useDataAppStatusById";
import {
  invalidateDataAppQueries,
  useLaunchDataAppMutation
} from "hooks/api/dataapps/useLaunchDataAppMutation";
import { useStopDataAppMutation } from "hooks/api/dataapps/useStopDataAppMutation";
import {
  getAPIWithRethrow,
  getErrorMessage,
  handleErrorWithRedirectToLogs
} from "src/utils/apiService";
import { useParams } from "react-router-dom";
import { DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

export interface IStatusInfo {
  text: string;
  alertType: Color;
  buttonText?: string;
  className: string;
  iconClassName?: string;
  icon?: React.JSX.Element;
  onClick?: () => void;
}

interface IReturn {
  loading: boolean;
  currentStatusInfo: IStatusInfo | null;
  status?: DataAppDtoLaunchStatusEnum;
  refetch: () => Promise<void>;
  setStatus: (status: DataAppDtoLaunchStatusEnum) => void;
  handleLaunchDataApp: () => void;
  handleStopDataApp: () => void;
}

const useStyles = makeStyles({
  runningStatus: {
    color: "#3b873e"
  },
  notRunningStatus: {
    color: "#c77700"
  },
  launchingStatus: {
    color: "#2196f3"
  },
  stopIcon: {
    color: "#1d4620"
  },
  runningIcon: {
    color: "#663d00"
  }
});

const useDataAppStatus = (
  id: string,
  initialLaunchStatus?: DataAppDtoLaunchStatusEnum
): IReturn => {
  const [status, setStatus] = useState(initialLaunchStatus);
  const statusRef = useRef<DataAppDtoLaunchStatusEnum | undefined>(status);
  const queryClient = useQueryClient();
  const { dataAppName } = useParams();

  useEffect(() => {
    setStatus(initialLaunchStatus);
  }, [initialLaunchStatus]);

  useEffect(() => {
    if (status !== statusRef.current) {
      statusRef.current = status;
    }
  }, [status]);

  const fetchStatus = async () => {
    const response: DataAppType = await getAPIWithRethrow(
      `/dataapps/by-id/${id}`,
      undefined,
      false
    );
    const latestStatus = response?.launchStatus;

    if (latestStatus !== statusRef.current) {
      invalidateDataAppQueries(queryClient, id, latestStatus, dataAppName);
    }
    setStatus(latestStatus);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!initialLaunchStatus || isInProgress(initialLaunchStatus)) {
      interval = setInterval(async () => {
        if (!statusRef.current || isInProgress(statusRef.current)) {
          try {
            await fetchStatus();
          } catch (e: any) {
            setStatus(DataAppDtoLaunchStatusEnum.Failure);
            clearInterval(interval);

            if (e?.response?.status !== 404) {
              handleErrorWithRedirectToLogs(getErrorMessage(e));
            }
          }
        } else {
          clearInterval(interval);
        }
      }, DATAAPP_INTERVAL_IN_SEC * 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  const classes = useStyles();
  const launchDataAppMutation = useLaunchDataAppMutation();
  const stopDataAppMutation = useStopDataAppMutation();

  const handleLaunchDataApp = () => {
    setStatus(DataAppDtoLaunchStatusEnum.Launching);
    invalidateDataAppQueries(queryClient, id, DataAppDtoLaunchStatusEnum.Launching, dataAppName);
    launchDataAppMutation.mutate(
      { id },
      {
        onSuccess: (response) => setStatus(response.launchStatus),
        onError: () => setStatus(DataAppDtoLaunchStatusEnum.Failure)
      }
    );
  };

  const handleStopDataApp = () => {
    setStatus(DataAppDtoLaunchStatusEnum.Stopping);
    invalidateDataAppQueries(queryClient, id, DataAppDtoLaunchStatusEnum.Stopping, dataAppName);
    stopDataAppMutation.mutate(
      { id },
      {
        onSuccess: () => setStatus(DataAppDtoLaunchStatusEnum.Stopped)
      }
    );
  };

  const statusMap = {
    [DataAppDtoLaunchStatusEnum.Running]: {
      text: "Running",
      alertType: "success" as Color,
      buttonText: "SHUTDOWN",
      icon: <Stop fontSize="small" />,
      className: classes.runningStatus,
      iconClassName: classes.stopIcon,
      onClick: handleStopDataApp
    },
    [DataAppDtoLaunchStatusEnum.Failure]: {
      text: "Not Running",
      alertType: "error" as Color,
      buttonText: "RELAUNCH",
      icon: <PlayArrow fontSize="small" />,
      className: classes.notRunningStatus,
      iconClassName: classes.runningIcon,
      onClick: handleLaunchDataApp
    },
    [DataAppDtoLaunchStatusEnum.Unknown]: {
      text: "Not Running",
      alertType: "error" as Color,
      buttonText: "RELAUNCH",
      icon: <PlayArrow fontSize="small" />,
      className: classes.notRunningStatus,
      iconClassName: classes.runningIcon,
      onClick: handleLaunchDataApp
    },
    [DataAppDtoLaunchStatusEnum.Stopped]: {
      text: "Not Running",
      alertType: "error" as Color,
      buttonText: "RELAUNCH",
      icon: <PlayArrow fontSize="small" />,
      className: classes.notRunningStatus,
      iconClassName: classes.runningIcon,
      onClick: handleLaunchDataApp
    },
    [DataAppDtoLaunchStatusEnum.Launching]: {
      text: "Launching...",
      alertType: "info" as Color,
      className: classes.launchingStatus
    },
    [DataAppDtoLaunchStatusEnum.Stopping]: {
      text: "Stopping...",
      alertType: "info" as Color,
      className: classes.launchingStatus
    },
    [DataAppDtoLaunchStatusEnum.Deleting]: {
      text: "Deleting...",
      alertType: "info" as Color,
      className: classes.launchingStatus
    }
  };

  return {
    handleLaunchDataApp,
    handleStopDataApp,
    currentStatusInfo: status ? _.get(statusMap, status) : null,
    loading: launchDataAppMutation.isLoading || stopDataAppMutation.isLoading,
    status,
    refetch: fetchStatus,
    setStatus
  };
};

export default useDataAppStatus;
