import React, { useMemo, useState } from "react";

// Packages
import { useNavigate, useParams } from "react-router-dom";
import { some } from "lodash";

// MUI
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import { CanvasIcon } from "src/icons/NewUX/CanvasIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Stores
import { useCanvasStore } from "src/store/store";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "../components/SubTopNavBarBreadcrumbs";
import ConfirmLeavePage from "./ConfirmLeavePage";
import UpdateScenario from "./UpdateScenario";
import DeleteScenario from "./DeleteScenario";

// Contexts
import { useScenarioContext } from "../context/useScenarioContext";

// Constants
import { ScenarioFormFields, ScenarioHelperText } from "../utils/Scenario.constants";
import { RunOptions } from "../../../components";

const Header = () => {
  const navigate = useNavigate();
  const { scenarioId } = useParams() || {};

  const {
    isReadOnly,

    project,
    isRecipeInProject,

    formMethods,

    isSavingScenario,

    saveScenario,
    updateScenario,
    onDisplayOnDag,

    setIsAttemptedSubmit
  } = useScenarioContext();

  const {
    trigger,
    formState: { dirtyFields, isDirty, isValid }
  } = formMethods;

  // Stores - STARTS >>
  const isRecipesRunningAcrossScenariosStore = useCanvasStore(
    (state) => state?.isRecipesRunningAcrossScenarios
  );
  // << ENDS - Stores

  // States - STARTS >>
  const [showLeavePageConfirmScreen, setShowLeavePageConfirmScreen] = useState<boolean | string>(
    false
  );
  const [showUpdateConfirmScreen, setShowUpdateConfirmScreen] = useState(false);
  const [showDeleteConfirmScreen, setShowDeleteConfirmScreen] = useState(false);
  // << ENDS - States

  const onSave = () => {
    setIsAttemptedSubmit(true);
    trigger();

    if (!isValid) {
      return;
    }

    saveScenario();
  };

  // Confirm leave page - STARTS >>
  const promptConfirmLeavePage = (action: string) => {
    setShowLeavePageConfirmScreen(() => action);
  };

  const resetLeavePage = () => {
    setShowLeavePageConfirmScreen(() => false);
  };

  const onLeavePage = () => {
    switch (showLeavePageConfirmScreen) {
      case "cancel":
        navigate(-1);
        resetLeavePage();
        break;

      case "displayOnDag":
        onDisplayOnDag();
        resetLeavePage();
        break;
    }
  };

  // << ENDS - Confirm leave page

  // Update scenario - STARTS >>
  const onUpdate = async () => {
    await updateScenario();
    resetUpdateScenario();
  };

  const promptConfirmUpdateScenario = () => {
    setShowUpdateConfirmScreen(() => true);
  };

  const resetUpdateScenario = () => {
    setShowUpdateConfirmScreen(() => false);
  };

  const onUpdateAttempted = async () => {
    setIsAttemptedSubmit(true);
    trigger();

    if (!isValid) {
      return;
    }

    some(dirtyFields?.[ScenarioFormFields.Datasets], (dataset: {} | boolean) => dataset === true)
      ? promptConfirmUpdateScenario()
      : onUpdate();
  };
  // << ENDS - Update scenario

  // Delete scenario - STARTS >>
  const promptConfirmDeleteScenario = () => {
    setShowDeleteConfirmScreen(() => true);
  };

  const resetDeleteScenario = () => {
    setShowDeleteConfirmScreen(() => false);
  };
  // << ENDS - Delete scenario

  const disabledMenuActionMessage = useMemo(() => {
    if (!isRecipeInProject) {
      return "Add recipes to the project to enable this action.";
    }

    if (!!isDirty) {
      return "Update the scenario to enable this action.";
    }

    if (!!isSavingScenario) {
      return "Please wait. Updating the scenario.";
    }

    return "";
  }, [isRecipeInProject, isDirty, isSavingScenario]);

  const disabledDisplayOnDagActionMessage = useMemo(() => {
    if (!!isSavingScenario) {
      return "Please wait. Updating the scenario.";
    }

    return "";
  }, [isSavingScenario]);

  const disabledCancelActionMessage = useMemo(() => {
    if (!!isSavingScenario) {
      return `Please wait. ${!!scenarioId ? "Updating" : "Saving"} the scenario.`;
    }

    return "";
  }, [scenarioId, isSavingScenario]);

  const disabledSaveActionMessage = useMemo(() => {
    if (!!isSavingScenario) {
      return "Please wait. Saving the scenario.";
    }

    return "";
  }, [isSavingScenario]);

  const disabledUpdateActionMessage = useMemo(() => {
    if (!!isRecipesRunningAcrossScenariosStore) {
      return "Please wait. Scenario is running..";
    }

    if (!!isSavingScenario) {
      return "Please wait. Updating the scenario.";
    }

    if (!isDirty) {
      return "Apply changes to enable this action.";
    }

    return "";
  }, [isDirty, isSavingScenario, isRecipesRunningAcrossScenariosStore]);

  const disabledDeleteActionMessage = useMemo(() => {
    if (!!isRecipesRunningAcrossScenariosStore) {
      return "Please wait. Scenario is running.";
    }

    if (!!isSavingScenario) {
      return "Please wait. Updating the scenario.";
    }

    return "";
  }, [isRecipesRunningAcrossScenariosStore, isSavingScenario]);

  return (
    <>
      {showLeavePageConfirmScreen && (
        <ConfirmLeavePage onLeavePage={onLeavePage} resetLeavePage={resetLeavePage} />
      )}

      {showUpdateConfirmScreen && !!scenarioId && (
        <UpdateScenario
          onUpdate={onUpdate}
          isScenarioUpdating={isSavingScenario}
          resetUpdateScenario={resetUpdateScenario}
        />
      )}

      {showDeleteConfirmScreen && !!scenarioId && (
        <DeleteScenario
          deletingScenarioId={scenarioId}
          resetDeleteScenario={resetDeleteScenario}
          onDelete={() => navigate(-1)}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          // @HACK
          // Passing empty method as there is some limitation at SubTopNavBarWrapper component.
          backNavAction: () => {},
          component: (
            <SubTopNavBarBreadcrumbs
              isReadOnly={isReadOnly}
              scenarioId={scenarioId}
              project={project}
              updateScenario={updateScenario}
            />
          )
        }}
        subTopNavBarRightSection={{
          ...(!!scenarioId
            ? {
                moreOptions: [
                  {
                    label: ScenarioHelperText.Delete,
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    disabled: !!disabledDeleteActionMessage,
                    tooltip: disabledDeleteActionMessage,
                    action: () => {
                      !disabledDeleteActionMessage && promptConfirmDeleteScenario();
                    }
                  }
                ]
              }
            : {}),
          component: (
            <>
              {!!scenarioId && (
                <>
                  <RunOptions disabledMenuActionMessage={disabledMenuActionMessage} />
                  <Tooltip title={disabledDisplayOnDagActionMessage}>
                    <div>
                      <Button
                        size="small"
                        color="primary"
                        disabled={!!disabledDisplayOnDagActionMessage}
                        startIcon={<CanvasIcon width={14} height={14} viewBox="0 0 16 12" />}
                        onClick={() =>
                          !!isDirty ? promptConfirmLeavePage("displayOnDag") : onDisplayOnDag()
                        }
                        data-testid="scenarioDisplayOnDagAction">
                        {ScenarioHelperText.CanvasView}
                      </Button>
                    </div>
                  </Tooltip>
                </>
              )}

              <Tooltip title={disabledCancelActionMessage}>
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    disabled={!!disabledCancelActionMessage}
                    onClick={() => (!!isDirty ? promptConfirmLeavePage("cancel") : navigate(-1))}
                    data-testid="scenarioCancelAction">
                    {ScenarioHelperText.Cancel}
                  </Button>
                </div>
              </Tooltip>

              {!!scenarioId ? (
                <Tooltip title={disabledUpdateActionMessage}>
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      disabled={!!disabledUpdateActionMessage || !isDirty}
                      onClick={onUpdateAttempted}
                      data-testid="scenarioUpdateAction">
                      {ScenarioHelperText.Update}
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title={disabledSaveActionMessage}>
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      disabled={!!disabledSaveActionMessage}
                      onClick={onSave}
                      data-testid="scenarioSaveAction">
                      {ScenarioHelperText.Save}
                    </Button>
                  </div>
                </Tooltip>
              )}
            </>
          )
        }}
      />
    </>
  );
};

export default Header;
