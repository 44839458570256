import React, { useMemo, useState } from "react";
import { Box, Typography, Card, CardContent, Tooltip, Button, Grid } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useNavigate, useLocation, generatePath } from "react-router-dom";

import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { RenderText } from "src/utils";

import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import DrawerAbout from "./components/DrawerAbout";
import DrawerAccordion from "./components/DrawerAccordion";
import Text from "components/Widget/Text";
import useEntities from "hooks/api/entities/useEntities";
import usePredictionServiceByModel from "src/hooks/usePredictionServiceByModel";
import { getErrorMessage } from "src/utils/apiService";
import { TABS } from "pages/Library/ArtifactsAndModels/Models/ModelDetails";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";
import { dateFormat } from "src/utils/dateFormat";
import { getUniqueEndpoint } from "pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.helpers";
import { handleResponse } from "src/utils/apiService";
import { sideComponentSetter } from "src/store/store.selectors";
import { useScenariosStore, useDrawerStore } from "src/store/store";
import { useStyles } from "./components/styling";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import ModelReadonly from "src/pages/private/ProjectsModule/pages/Dag/components/Nodes/Model/ModelReadonly";
import { WebPaths } from "src/routing/routes";
import { EyeIcon } from "src/icons/NewUX";
import AIGuideDialog from "src/pages/Projects/AIGuide/common/AIGuideDialog";
import MoreOptions from "src/layout/NavBars/components/SubTopNavBar/MoreOptions";
import { ThreadResponseDtoTargetTypeEnum } from "@rapidcanvas/rc-api-core";
import { AIGuideMiniIconButton } from "src/components/Buttons/AIGuideMiniIconButton";
import CommonLoader from "src/components/CommonLoader";

export const ModelModalV2 = ({ data }: $TSFixMe) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const predictionServiceResults = usePredictionServiceByModel(
    data.name,
    data?.scenarioId,
    data?.jobProps?.jobRunId
  );
  const [showAIGuideDialog, setShowAIGuideDialog] = useState(false);

  const {
    data: model,
    isLoading,
    refetch
  } = useEntities({
    id: data?.itemId,
    scenarioId: data?.isJobCanvas ? data?.scenarioId : undefined,
    jobRunId: data?.isJobCanvas ? data?.jobProps?.jobRunId : undefined,
    options: {
      cacheTime: Infinity,
      refetchOnMount: true,
      onError: (e) => {
        handleResponse({
          errorMessage: getErrorMessage(e) || `Error in fetching artifact - ${data.name}`
        });
      }
    }
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const setSideComponent = useDrawerStore(sideComponentSetter);
  const [scenarios] = useScenariosStore((state) => [state.scenarios]);

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(null, scenarios, data?.scenarioId),
    [data?.scenarioId, scenarios]
  );

  const uniqueEndpoint = useMemo(() => {
    const name = predictionServiceResults.data?.predictionServiceDetails?.name;
    return name ? getUniqueEndpoint(name) : null;
  }, [predictionServiceResults.data?.predictionServiceDetails?.name]);

  const getNavigatePath = () => {
    if (!!data?.jobProps) {
      return generatePath(`${WebPaths.JobRoutes}${WebPaths.JobModels}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        modelName: data.name
      });
    } else {
      return generatePath(WebPaths.ModelDetails, {
        modelName: data.name
      });
    }
  };

  const handleViewModalClick = () =>
    navigate({ pathname: getNavigatePath() }, { state: { prevPath: location.pathname } });

  const handlePredictionServiceClick = () => {
    let path = generatePath(WebPaths.ModelDetails, {
      modelName: data.name
    });
    navigate(path, {
      state: {
        prevPath: location.pathname,
        tab: TABS.predictionService.value,
        predictionServiceDetails: predictionServiceResults.data?.predictionServiceDetails
      }
    });
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const onClose = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  const navigateToAIGuide = () => {
    setShowAIGuideDialog(true);
  };

  const isUnbuilt = useMemo(() => {
    return !data?.status || !["BUILT"].includes(data?.status);
  }, [data?.status]);

  return (
    <NewThemeWrapper>
      <Box className={classes.drawerContent}>
        {isDeleteModalOpen && (
          <DeleteNodeModal
            open={isDeleteModalOpen}
            onClose={() => {
              setIsDeleteModalOpen(false);
            }}
            deleteNote={
              <div>
                <span>Note: Deleting this might impact associated dataapps(if any). </span>
                {psMessage}
              </div>
            }
            nodeId={data.itemId}
            nodeName={data.label}
            nodeType="model"
            onAfterSubmit={() =>
              setSideComponent({ sideComponent: null, sideComponentProps: null })
            }
          />
        )}
        <AIGuideDialog
          open={showAIGuideDialog}
          onClose={() => {
            setShowAIGuideDialog(false);
          }}
          projectId={data?.projectId!}
          scenarioId={data?.scenarioId!}
          modelId={data.itemId!}
          targetType={ThreadResponseDtoTargetTypeEnum.Model}
        />
        <SubTopNavBarWrapper
          variant="drawer"
          onDrawerClose={onClose}
          subTopNavBarLeftSection={{
            styleProps: {
              width: "auto"
            },
            component: (
              <>
                <ModelReadonly data={data} />
                <RenderText
                  color="textSecondary"
                  isOverflowTooltip
                  linkTo={getNavigatePath()}
                  data-testid="sidebar-model-name">
                  {data?.label}
                </RenderText>
              </>
            )
          }}
        />

        <div className={classes.content} style={{ marginTop: 44 }}>
          {isLoading ||
          predictionServiceResults.isLoading ||
          predictionServiceResults.isFetching ? (
            <CommonLoader />
          ) : (
            <>
              <Card className={classes.cardMargin}>
                <CardContent>
                  <Grid container direction="row" wrap="nowrap">
                    <Grid
                      container
                      item
                      md={11}
                      xs={10}
                      className={classes.datasetItemGap}
                      wrap="nowrap">
                      <Button
                        variant="contained"
                        startIcon={<EyeIcon viewBox="0 0 20 20" />}
                        color="primary"
                        size="small"
                        onClick={handleViewModalClick}>
                        Preview
                      </Button>
                      {!data?.isJobCanvas && !!isDefaultScenario && !isUnbuilt && (
                        <AIGuideMiniIconButton
                          width={38}
                          height={32}
                          viewBox="3 -4 48 48"
                          badgeStyleProps={{
                            marginTop: 2,
                            marginRight: -12,
                            marginLeft: 4,
                            width: 50
                          }}
                          badgeDotStyleProps={{ marginTop: -2, marginRight: 6 }}
                          targetId={data?.itemId!}
                          projectId={data?.projectId!}
                          onClick={navigateToAIGuide}
                        />
                      )}
                      {isDefaultScenario && !data?.isJobCanvas && (
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={handlePredictionServiceClick}
                          {...(predictionServiceResults.data?.predictionServiceDetails
                            ? {
                                startIcon: <Edit />
                              }
                            : {})}>
                          {`${
                            predictionServiceResults.isSuccess &&
                            !predictionServiceResults.data?.predictionServiceDetails
                              ? "+"
                              : ""
                          } Prediction Service`}
                        </Button>
                      )}
                    </Grid>
                    {!data?.isJobCanvas && isDefaultScenario && (
                      <Grid container item md={1} xs={2} className="moreIcon">
                        <MoreOptions
                          options={[
                            {
                              label: "Delete",
                              icon: <TrashIcon viewBox="0 0 20 22" />,
                              action: handleDelete
                            }
                          ]}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
              {model?.markdown && (
                <Card className={classes.cardMargin}>
                  <DrawerAbout
                    markdownString={model?.markdown}
                    projectId={data.projectId}
                    elementId={data.itemId}
                    hiddenUpdateContentAction={!!data?.isJobCanvas}
                    type="MODEL"
                    onSuccess={refetch}
                  />
                </Card>
              )}
              <div className={classes.cardMargin}>
                <Card>
                  <CardContent>
                    <Box className={classes.detailsWrapper}>
                      <Box className={classes.detailItem}>
                        <Typography className={classes.detailName}>Created:</Typography>
                        <Typography>{dateFormat(model?.created)}</Typography>
                      </Box>
                      <Box className={classes.detailItem}>
                        <Typography className={classes.detailName}>Updated:</Typography>
                        <Typography>{dateFormat(model?.updated)}</Typography>
                      </Box>
                      <Box className={classes.detailItem}>
                        <Typography className={classes.detailName}>Build:</Typography>
                        <Typography>{dateFormat(model?.built)}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </div>
              {predictionServiceResults.data?.predictionServiceDetails && (
                <Card className={classes.cardMargin}>
                  <DrawerAccordion
                    title="PREDICTION SERVICE"
                    content={
                      <Box className={classes.detailsWrapper}>
                        <Box className={classes.detailItem}>
                          <span className={classes.detailName}>Name:</span>
                          <Tooltip
                            placement="top-start"
                            title={
                              <Text
                                value={predictionServiceResults.data?.predictionServiceDetails.name}
                              />
                            }>
                            <Link
                              style={{ maxWidth: "470px", marginBottom: "12px" }}
                              className={`${classes.link} ${classes.endpoint}`}
                              to={`/artifacts-and-models/model/${data.name}`}
                              state={{
                                tab: TABS.predictionService.value,
                                predictionServiceDetails:
                                  predictionServiceResults.data?.predictionServiceDetails
                              }}>
                              <Text
                                style={{
                                  overflow: "hidden",
                                  maxWidth: "475px",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis"
                                }}
                                value={predictionServiceResults.data?.predictionServiceDetails.name}
                              />
                            </Link>
                          </Tooltip>
                        </Box>
                        <Box className={classes.detailItem}>
                          <Typography className={classes.detailName}>Environment:</Typography>
                          <Typography>
                            {`${
                              predictionServiceResults.data?.predictionServiceDetails.envDetails
                                .name
                            } | Core: ${
                              predictionServiceResults.data?.predictionServiceDetails.envDetails
                                .cores
                            } | Memory: ${
                              predictionServiceResults.data?.predictionServiceDetails.envDetails
                                .memInMbs / 1024
                            } GB | Disk Space:  ${
                              predictionServiceResults.data?.predictionServiceDetails.envDetails
                                .diskInGbs
                            } GB`}
                          </Typography>
                        </Box>
                        <Box component="div" sx={{ textOverflow: "ellipsis" }}>
                          <span
                            style={{ position: "relative", bottom: "5px" }}
                            className={classes.detailName}>{`Unique Endpoint: `}</span>
                          <Tooltip title={uniqueEndpoint ?? ""}>
                            <span className={classes.endpoint}>{uniqueEndpoint}</span>
                          </Tooltip>
                        </Box>
                      </Box>
                    }
                  />
                </Card>
              )}
            </>
          )}
        </div>
      </Box>
    </NewThemeWrapper>
  );
};
