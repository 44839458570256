import React, { useEffect, useState, useRef } from "react";

import api from "utils/AxiosClient";
import { JobRunStatuses } from "../../utils/Jobs.constants";
import { ProjectRunEntryDtoStatusEnum } from "@rapidcanvas/rc-api-core";

const useGetJobRunLogs = ({ jobRunId }: $TSFixMe) => {
  const interval = 5000;

  const isMounted = useRef(true);
  const timeoutRef = useRef(0);
  const jobRunStatusRef = useRef("");

  const [isFetching, setIsFetching] = useState(false);

  const [jobRunLogs, setJobRunLogs] = useState<string[]>([]);
  const [jobRunStatus, setJobRunStatus] = useState<ProjectRunEntryDtoStatusEnum | undefined>();
  const jobRunLogsInterval = useRef<$TSFixMe>(null);

  const isRunning = (status: $TSFixMe) =>
    ![
      JobRunStatuses.Success,
      JobRunStatuses.SuccessWithWarn,
      JobRunStatuses.Failure,
      JobRunStatuses.TimedOut,
      JobRunStatuses.RecipeTimedOut
    ].includes(status);

  const getJobRunLogs = async () => {
    try {
      const jobRunDetailsResponse = await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.getProjectRunEntryDetails(jobRunId)
      );

      const jobRunLogsResponse = await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.getLogs(jobRunId)
      );

      // Recipe Log code starts >>
      !isFetching && setIsFetching(true);

      if (
        jobRunLogs?.length === 0 ||
        (jobRunLogs?.length > 0 && isRunning(jobRunDetailsResponse?.entryDto?.status)) ||
        // The below condition is to set logs of first succeed status preceded by (after) a running status.
        (jobRunLogs?.length > 0 &&
          isRunning(jobRunStatusRef?.current) &&
          !isRunning(jobRunDetailsResponse?.entryDto?.status))
      ) {
        setJobRunLogs(jobRunLogsResponse);
        setJobRunStatus(jobRunDetailsResponse?.entryDto?.status);
      }

      // Must be after the above if condition, as it's previous value being used in the if condition.
      jobRunStatusRef.current = jobRunDetailsResponse?.entryDto?.status as string;
      // << Recipe Log code ends
    } catch (e) {
      jobRunLogsInterval.current !== null && clearTimeout(jobRunLogsInterval.current);
    }
  };

  useEffect(() => {
    const _ = async () => {
      await getJobRunLogs();
    };

    !!jobRunId && !isFetching && !jobRunStatusRef?.current && _();
  }, [jobRunId, isFetching, jobRunStatusRef?.current]);

  useEffect(() => {
    jobRunLogsInterval.current !== null && clearTimeout(jobRunLogsInterval.current);

    const _ = () => {
      // Call getJobRunLogs every n seconds that is defined for ${interval} above.
      jobRunLogsInterval.current = setTimeout(async () => {
        if (!isMounted.current) {
          return;
        }

        const beforeTimeInMillis = Date.now();
        await getJobRunLogs();
        const afterTimeInMillis = Date.now();

        if (isRunning(jobRunStatusRef?.current)) {
          const timeoutLeft = interval - (afterTimeInMillis - beforeTimeInMillis);
          timeoutRef.current = timeoutLeft <= 0 ? 0 : timeoutLeft;

          _();
        } else {
          clearTimeout(jobRunLogsInterval.current);
        }
      }, timeoutRef.current);
    };

    let canContinue = true;
    canContinue = canContinue && !!jobRunId;
    canContinue = canContinue && isFetching;
    canContinue = canContinue && (!jobRunStatusRef?.current || isRunning(jobRunStatusRef?.current));

    canContinue && _();
  }, [jobRunId, isFetching, jobRunStatusRef?.current]);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
      jobRunStatusRef.current = "";

      jobRunLogsInterval.current && clearTimeout(jobRunLogsInterval.current);
    };
  }, []);

  const jobRunInfo = !isRunning(jobRunStatusRef.current)
    ? { type: "error", message: "No logs found!" }
    : {};

  return {
    jobRunInfo,
    isFetching,
    jobRunLogs,
    jobRunStatus
  };
};

export default useGetJobRunLogs;
