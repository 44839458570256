import React, { useMemo, useState } from "react";

import { includes } from "lodash";
import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";

import AnalyzingFileAnimation from "../../AnalyzingFileAnimation/AnalyzingFileAnimation";
import PreviewIconNew from "src/icons/NewUX/Dataset/PreviewIconNew";

import useStoreSelectors from "../../../hooks/useStoreSelectors";

import {
  DatasetKeys,
  OntologyDatasetStatuses,
  DatasetHelperText
} from "../../../utils/Dataset.constants";

import OntologyHeader from "./OntologyHeader";
import OntologySampleData from "./OntologySampleData";
import OntologyLoaderWrapper from "../../OntologyLoaderWrapper";

import { useUploadContext } from "../../../contexts/Upload/useUploadContext";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flex: 1,
    overflow: "auto",
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(2),
    "& > [class^='MuiGrid-root']": {
      marginBottom: theme.spacing(2)
    }
  },
  cell: {
    display: "inline-block",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle"
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  }
}));

const OntologyContainer = (props: $TSFixMe) => {
  const {
    isDataSourcesFilesUpload,
    ontologyDatasetIndex,
    dataset,
    isConfigContainerExpanded,
    ontologySampleData,
    isProcessingFiles,
    isFetchingOntologySchema,
    isFetchingOntologySampleData,
    ontologySchemaOptionsData,
    ontologySchemaData,
    getDatasetCustomColumnsData,
    visibleColumns
  } = props || {};

  const classes: $TSFixMe = useStyles();

  // Stores - STARTS >>
  const {
    datasetDefaultDatasetStore,
    datasetIsFetchingOntologyDataStore,
    datasetWatchOntologySchemaSetStore
  } = useStoreSelectors();
  // << ENDS - Stores

  // States - STARTS >>
  const [searchTerm, setSearchTerm] = useState<$TSFixMe>("");
  // << ENDS - States

  const { updateDatasetLite } = useUploadContext();

  const loader = useMemo(() => {
    if (
      includes(
        [OntologyDatasetStatuses.Failed],
        dataset?.[DatasetKeys.OntologyConfig][DatasetKeys.Status]
      )
    ) {
      return;
    }

    if (isProcessingFiles) {
      return (
        <OntologyLoaderWrapper>
          <Typography color="primary">
            <AnalyzingFileAnimation />
          </Typography>
          <Typography variant="body2" color="primary">
            {isDataSourcesFilesUpload
              ? "Analyzing the data..."
              : "Analyzing & Configuring your file..."}
          </Typography>
        </OntologyLoaderWrapper>
      );
    } else if (isFetchingOntologySampleData) {
      return (
        <OntologyLoaderWrapper>
          <Typography color="primary">
            <PreviewIconNew />
          </Typography>
          <Typography variant="body2" color="primary">
            Fetching sample data...
          </Typography>
        </OntologyLoaderWrapper>
      );
    } else if (!datasetDefaultDatasetStore?.id && isFetchingOntologySchema) {
      return (
        <OntologyLoaderWrapper>
          <Typography color="primary">
            <PreviewIconNew />
          </Typography>
          <Typography variant="body2" color="primary">
            Fetching schema...
          </Typography>
        </OntologyLoaderWrapper>
      );
    } else if (datasetIsFetchingOntologyDataStore) {
      return <CommonLoader />;
    } else {
      return null;
    }
  }, [
    isDataSourcesFilesUpload,
    dataset,
    ontologyDatasetIndex,
    datasetIsFetchingOntologyDataStore,
    isProcessingFiles,
    isFetchingOntologySchema,
    isFetchingOntologySampleData
  ]);

  const noDataFoundMessage = (message: string) => (
    <OntologyLoaderWrapper>
      <Typography variant="body2" color="primary">
        {message}
      </Typography>
    </OntologyLoaderWrapper>
  );

  return (
    <Paper className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item>{DatasetHelperText.SampleData}</Grid>

        <Grid item>
          <OntologyHeader
            datasetDefaultDatasetStore={datasetDefaultDatasetStore}
            ontologyDatasetIndex={ontologyDatasetIndex}
            dataset={dataset}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            updateDatasetLite={updateDatasetLite}
          />
        </Grid>
      </Grid>

      {!!loader ? (
        loader
      ) : (ontologySampleData?.columns || [])?.length === 0 ||
        (ontologySampleData?.rows || [])?.length === 0 ? (
        noDataFoundMessage(DatasetHelperText.NoSampleDataFound)
      ) : (
        <OntologySampleData
          datasetDefaultDatasetStore={datasetDefaultDatasetStore}
          isConfigContainerExpanded={isConfigContainerExpanded}
          ontologyDatasetIndex={ontologyDatasetIndex}
          ontologySampleData={ontologySampleData}
          searchTerm={searchTerm}
          ontologySchemaOptionsData={ontologySchemaOptionsData}
          ontologySchemaData={ontologySchemaData}
          datasetWatchOntologySchemaSetStore={datasetWatchOntologySchemaSetStore}
          getDatasetCustomColumnsData={getDatasetCustomColumnsData}
          visibleColumns={visibleColumns}
        />
      )}
    </Paper>
  );
};

export default OntologyContainer;
