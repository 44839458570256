import React, { useMemo } from "react";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";

// Icons
import { CpuIcon } from "src/icons/CpuIcon";

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 15,
    borderRadius: 7.5
  },
  bar: {
    borderRadius: 5
  }
}))(LinearProgress);

const useStyles = makeStyles<undefined, Pick<IProps, "disabled">>(() => ({
  root: {
    rowGap: 10
  },
  titleContainer: {
    marginBottom: 10,
    columnGap: 5
  },
  progressBarDetailsContainer: ({ disabled }) => ({
    marginTop: 5,
    "& .MuiTypography-body2": {
      fontWeight: "400"
    },
    "& .MuiTypography-root": {
      color: disabled ? "#cac8c8" : ""
    }
  }),
  wrap: {
    cursor: ({ disabled }) => (disabled ? "not-allowed" : "default")
  }
}));

interface IProps {
  percentage: string;
  disabled?: boolean;
}

const CpuUsage = (props: IProps) => {
  const { percentage, disabled } = props;

  const classes = useStyles({ disabled });

  const formattedPercentage = useMemo(
    () => Number((percentage ? parseFloat(percentage) : 0).toFixed(2) || ""),
    [percentage]
  );

  const formattedLeftPercentage = useMemo(
    () => (percentage ? 100 - parseFloat(percentage) : 0).toFixed(2),
    [percentage]
  );

  return (
    <Box className={classes.wrap}>
      <Grid container className={classes.titleContainer}>
        <CpuIcon color="#4646b5" />
        <Typography
          variant="body2"
          color="primary"
          display="inline"
          data-testid="envConfigCpuUsageTitle">
          CPU Usage
        </Typography>
      </Grid>
      <BorderLinearProgress variant="determinate" color="primary" value={formattedPercentage} />
      <Grid
        container
        justifyContent="space-between"
        className={classes.progressBarDetailsContainer}>
        <Box>
          <Typography
            variant="caption"
            color="textPrimary"
            display="inline"
            data-testid="envConfigCpuUsageUsed">
            Used
          </Typography>
          <Typography
            variant="body2"
            color="textPrimary"
            display="inline"
            data-testid="envConfigCpuUsageValue">{` ${formattedPercentage}% of 100%`}</Typography>
        </Box>
        <Box>
          <Typography
            variant="caption"
            color="textPrimary"
            display="inline"
            data-testid="envConfigCpuUsageRemaining">
            Remaining
          </Typography>
          <Typography
            variant="body2"
            color="textPrimary"
            display="inline"
            data-testid="envConfigCpuUsageRemainingValue">{` ${formattedLeftPercentage}%`}</Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default CpuUsage;
