import React, { useMemo, useState } from "react";

// Packages
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Button } from "@material-ui/core";

// Icons
import { EyeIcon } from "src/icons/NewUX";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { WebPaths } from "src/routing/routes";

// Components
import DeleteNodeModal from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";

// Types
import { NodeData } from "src/types";
import { useContextStyles } from "../useContextMenuStyles";
import { RecipeTypeNames } from "src/pages/private/ProjectsModule/utils";
import { AiAssistedIcon } from "src/icons/NewUX/AiAssistedRecipeIcon";
import { TemplateRecipeIcon } from "src/icons/NewUX/TemplateRecipeIcon";
import ApiConnectorIcon from "src/icons/NewUX/ApiConnectorIcon";

type Props = {
  closeContextMenu: () => void;
  isDefaultScenario: boolean;
  data: NodeData;
  children?: React.ReactNode;
};

const ContextMenu = (props: Props) => {
  const { closeContextMenu, isDefaultScenario, data, children } = props || {};

  const location = useLocation();
  const navigate = useNavigate();

  const classes = useContextStyles();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const isBuilt = useMemo(() => ["BUILT"].includes(data?.status), [data?.status]);

  const onView = () => {
    if (!data?.name) {
      return;
    }

    let path = generatePath(WebPaths.ArtifactsDetails, { artifactName: data?.name });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobArtifacts}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        artifactName: data?.name
      });
    }

    !!path && navigate({ pathname: path }, { state: { prevPath: location.pathname } });
  };

  const addAiAssistedRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-code-recipe?artifact=${data?.id}`
    );
  };

  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?artifact=${data?.id}`;
    navigate(
      generatePath(url, {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const handleAddTemplateRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-recipe?artifact=${data?.id}`
    );
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    items.push(
      <Button
        key="view"
        data-testid="Preview"
        className="context-menu-border"
        startIcon={<EyeIcon viewBox="0 0 20 20" />}
        size="small"
        onClick={onView}>
        Preview
      </Button>
    );

    if (isBuilt && isDefaultScenario) {
      items.push(
        <Button
          key={RecipeTypeNames.AiAssisted}
          size="small"
          data-testid={`${RecipeTypeNames.AiAssisted} Recipe`}
          startIcon={<AiAssistedIcon viewBox="0 1 20 20" />}
          onClick={addAiAssistedRecipe}>
          {RecipeTypeNames.AiAssisted} Recipe
        </Button>
      );

      items.push(
        <Button
          key={RecipeTypeNames.Template}
          size="small"
          data-testid={`${RecipeTypeNames.Template} Recipe`}
          startIcon={<TemplateRecipeIcon width={16} height={16} viewBox="0 0 20 20" />}
          onClick={handleAddTemplateRecipe}>
          {RecipeTypeNames.Template} Recipe
        </Button>
      );

      items.push(
        <Button
          key={RecipeTypeNames.ApiConnector}
          size="small"
          data-testid={`${RecipeTypeNames.ApiConnector} Recipe`}
          className="context-menu-border"
          startIcon={<ApiConnectorIcon />}
          onClick={handleAddApiConnectorRecipe}>
          {RecipeTypeNames.ApiConnector} Recipe
        </Button>
      );
    }
    items.push(
      <Button
        key="delete"
        size="small"
        data-testid="Delete"
        startIcon={<TrashIcon viewBox="0 0 20 22" />}
        className="borderLeft"
        onClick={onDelete}>
        Delete
      </Button>
    );

    return items;
  }, [data?.isJobCanvas, data?.isJobCanvasPath, isDefaultScenario]);

  return (
    <>
      {!!isDeleteModalOpen && (
        <DeleteNodeModal
          open
          nodeId={data?.id}
          nodeName={data?.label}
          nodeType="artifact"
          deleteNote="Deleting this might impact associated DataApps (if any)"
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onAfterSubmit={closeContextMenu}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" orientation="vertical" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}
    </>
  );
};

export default ContextMenu;
