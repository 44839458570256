import { EnvUsageDto } from "@rapidcanvas/rc-api-core";
import { getEnvironmentUsage } from "src/api/environments";

export type ITypes = ("running job(s)" | "prediction job(s)" | "active recipe(s)")[];

export const getEnvironmentUsageDetails = async (id?: string): Promise<ITypes> => {
  if (!id) {
    return [];
  }

  const usageResponse: EnvUsageDto[] = await getEnvironmentUsage(id);

  const projectType = (usageResponse || [])?.filter(
    (eachEntry: EnvUsageDto) => eachEntry?.type === "PROJECT"
  );
  const recipeType = (usageResponse || [])?.filter(
    (eachEntry: EnvUsageDto) => eachEntry?.type === "RECIPE"
  );

  const areRunningJobs = projectType?.some(
    // @ts-ignore
    (eachEntry: EnvUsageDto) => eachEntry?.runningJobCount > 0
  );
  const areRunningPredictionJobs = projectType?.some(
    // @ts-ignore
    (eachEntry: EnvUsageDto) => eachEntry?.runningPredictionJobCount > 0
  );
  const areRunningRecipes = recipeType?.some(
    // @ts-ignore
    (eachEntry: EnvUsageDto) => eachEntry?.runningRecipeCount > 0
  );

  const types: ITypes = [];

  if (areRunningJobs) {
    types.push("running job(s)");
  }
  if (areRunningPredictionJobs) {
    types.push("prediction job(s)");
  }
  if (areRunningRecipes) {
    types.push("active recipe(s)");
  }

  return types;
};
