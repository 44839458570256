import React, { useCallback, useEffect, useMemo } from "react";
import axios from "axios";
import { Box, Grid, IconButton, Tooltip, Typography, CircularProgress } from "@material-ui/core";
import { useParams, useLocation } from "react-router-dom";

import type { Artifact, File } from "src/types/Artifact.type";
import { DeleteEntityFileConfirmationModal } from "../DeleteEntityFileConfirmationModal";
import { DeleteOutline } from "@material-ui/icons";
import { Download } from "src/icons/Download";
import { Table } from "src/components";
import { deleteAPIWithRethrow } from "src/utils/apiService";
import { download } from "src/utils/fileUtils";
import { downloadArtifactSignedUrl } from "src/api";
import { handleResponse } from "src/utils/apiService";
import { toastWrapper } from "src/utils/toastWrapper";
import { formatBytes } from "src/utils/format-bytes";
import useArtifactByName from "src/hooks/api/artifacts/useArtifactByName";
import CommonLoader from "src/components/CommonLoader";

type Props = {
  artifactName: string;
  artifact?: Artifact;
  onArtifactDelete?: () => void;
  onArtifactDownload?: () => void;
  disableDelete?: boolean;
  maxHeight?: string;
};

export const ArtifactTable = ({
  artifactName,
  artifact: initialArtifact,
  onArtifactDelete,
  onArtifactDownload,
  disableDelete = false,
  maxHeight
}: Props) => {
  const { scenarioId, jobRunId } = useParams<$TSFixMe>();
  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const [artifact, setArtifact] = React.useState<Artifact | undefined>(initialArtifact);
  const [filesDownloading, setFilesDownloading] = React.useState<Array<string>>([]);

  const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = React.useState<boolean>(false);
  const [fileToBeDeleted, setFileToBeDeleted] = React.useState<File | null>(null);
  const [isDeleteInProgress, setIsDeleteInProgress] = React.useState(false);

  const { data, isSuccess } = useArtifactByName(artifactName, scenarioId, jobRunId, {
    enabled: !initialArtifact
  });

  useEffect(() => {
    if (isSuccess && data) {
      setArtifact(data);
    }
  }, [isSuccess, data]);

  React.useEffect(() => {
    setArtifact(initialArtifact);
  }, [initialArtifact]);

  const onDownload = useCallback(
    async (path: string) => {
      setFilesDownloading((files) => [...files, path]);
      try {
        const signedUrl = await downloadArtifactSignedUrl(`${artifactName}/${path}`);
        await axios
          .get(signedUrl?.signedUrl, {
            responseType: "arraybuffer",
            headers: { ...signedUrl.headers, "Access-Control-Allow-Origin": "*" }
          })
          .then((response) => {
            download(response.data, path, response.headers["content-type"]);
          });

        onArtifactDownload?.();
      } catch (error: $TSFixMe) {
        handleResponse({
          errorMessage:
            error.response?.data?.msg ||
            error.message ||
            `Error in downloading artifact - ${artifactName}`
        });
      } finally {
        setFilesDownloading((files) => files?.filter((file) => file !== path));
      }
    },
    [artifactName, onArtifactDownload]
  );

  const onDelete = useCallback(
    async (path: string) => {
      setIsDeleteInProgress(true);
      try {
        await deleteAPIWithRethrow(
          `/v2/artifacts/contents/${artifactName}`,
          {},
          { params: { path } }
        );

        onArtifactDelete?.();
        toastWrapper({
          type: "success",
          content: `Artifact file - ${path} deleted successfully.`
        });
      } catch (error: $TSFixMe) {
        handleResponse({
          errorMessage:
            error.response?.data?.msg ||
            error.message ||
            `Error in deleting artifact - ${artifactName}`
        });
      } finally {
        setFileToBeDeleted(null);
        setIsDeleteInProgress(false);
        setIsDeleteFileModalOpen(false);
      }
    },
    [artifactName, onArtifactDelete]
  );

  const columns = useMemo(() => {
    return [
      {
        id: "File",
        accessor: "path",
        Header: "File",
        isSortable: true,
        Cell: ({ value }: $TSFixMe) => <Typography variant="body2">{value}</Typography>
      },
      {
        id: "File Size",
        accessor: "sizeInBytes",
        Header: "File Size",
        isSortable: true,
        Cell: ({ value }: $TSFixMe) => formatBytes(value)
      },
      {
        id: "File Type",
        accessor: "type",
        Header: "File Type",
        isSortable: true
      },
      {
        id: "Actions",
        accessor: "id",
        Header: "Actions",
        isSortable: false,
        Cell: ({ row }: $TSFixMe) => {
          const filePath = row?.original?.path;
          const isDownloadInProgress = !!filesDownloading.find((file) => file === filePath);

          return (
            <Grid container alignItems="center">
              <Tooltip title="Download File">
                {isDownloadInProgress ? (
                  <Box display="flex" px="12px">
                    <CircularProgress size="20px" />
                  </Box>
                ) : (
                  <IconButton
                    aria-label="download"
                    component="div"
                    onClick={() => onDownload(filePath)}>
                    <Download />
                  </IconButton>
                )}
              </Tooltip>
              {!isJobPath && (
                <Tooltip
                  title={artifact?.producer ? "This file cannot be deleted." : "Delete File"}>
                  <IconButton
                    disabled={disableDelete || !!artifact?.producer}
                    aria-label="delete"
                    component="div"
                    onClick={() => {
                      setFileToBeDeleted(row?.original);
                      setIsDeleteFileModalOpen(true);
                    }}>
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          );
        }
      }
    ];
  }, [artifact?.producer, disableDelete, filesDownloading, onDownload]);

  const handleConfirm = () => {
    fileToBeDeleted && onDelete(fileToBeDeleted.path);
  };
  return artifact ? (
    <>
      <Table
        data={artifact?.fileObjects || []}
        size="small"
        columns={columns}
        isCellSortEnabled
        emptyTableMessage="No files"
        {...(!!maxHeight
          ? {
              maxHeight
            }
          : {})}
        skipPageReset
      />
      {isDeleteFileModalOpen && (
        <DeleteEntityFileConfirmationModal
          fileName={fileToBeDeleted?.path || ""}
          entityLabel="Artifact"
          entityName={artifactName}
          isDeleteInProgress={isDeleteInProgress}
          onConfirm={handleConfirm}
          onClose={() => setIsDeleteFileModalOpen(false)}
        />
      )}
    </>
  ) : (
    <CommonLoader />
  );
};
