import api from "utils/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { UpdateApiAccessKeyDto } from "@rapidcanvas/rc-api-core";

interface IVariable {
  id: string;
  updateApikeyPayload: UpdateApiAccessKeyDto;
}

const useUpdateApiKey = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ updateApikeyPayload, id }) => {
      return await api.fetchResponse(() =>
        api.ApiAccessKeyControllerApi.updateApiAccessKey(id, updateApikeyPayload)
      );
    }
  });
};

export default useUpdateApiKey;
