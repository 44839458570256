import React, { useMemo } from "react";

// MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Hooks
import { useGetEnvironment } from "src/hooks/api";
import { usePollEnvironment } from "src/hooks/api/environments/usePollEnvironments";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import StatusBar from "../StatusBar/StatusBar";

interface IProps {
  environment?: EnvDto;
  onClose: () => void;
}

const Header: React.FC<IProps> = (props) => {
  const { environment, onClose } = props;

  const theme = useTheme();

  const { data: environmentResponse } = useGetEnvironment({
    id: environment?.id
  });

  const { data } = usePollEnvironment({
    id: environmentResponse?.id,
    status: environmentResponse?.launchStatus
  });

  const env = useMemo(
    () => data?.data?.[0] || environmentResponse || environment,
    [data?.data?.[0], environmentResponse, environment]
  );

  return (
    <SubTopNavBarWrapper
      variant="drawer"
      onDrawerClose={onClose}
      subTopNavBarLeftSection={{
        component: (
          <Typography variant="body2" color="textSecondary" data-testid="envConfigTitle">
            Configuration & Consumption
          </Typography>
        )
      }}
      subTopNavBarRightSection={{
        component: (
          <Grid container wrap="nowrap" style={{ columnGap: theme.spacing(1) }}>
            <StatusBar
              environment={env}
              styleProps={{
                style: { width: "auto", margin: "auto", borderRadius: 4 }
              }}
            />
          </Grid>
        )
      }}
    />
  );
};

export default Header;
