import React, { useEffect, useMemo, useState } from "react";

import { useParams, useNavigate, generatePath } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Alert from "@material-ui/lab/Alert";

import { toastWrapper } from "src/utils/toastWrapper";

import { UseGetProjectCanvasQueryKeys } from "src/hooks/api/projects/useGetProjectCanvas";

import { DagFlow } from "../../Dag/components";

import { PublishJobModal } from "./PublishJobModal";

import { useJobContext } from "./Job/context/useJobContext";

import { JobsHelperText } from "../utils/Jobs.constants";
import { WebPaths } from "src/routing/routes";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles(() => ({
  canvasActionsContainer: {
    display: "inline-flex",
    width: "auto",
    columnGap: 12
  }
}));

const JobCanvas = (props: $TSFixMe) => {
  const {
    isValidNodes,
    isValidatingNodes,
    isNodesValidated,
    hideCanvasActions = false,
    hideCanvasCompareAction = false,
    isPublishingJob,
    publishJobMutation,
    resetPublishJobMutation,
    isJobPublished,
    updateJobGlobalVariablesMutation,
    resetUpdateJobGlobalVariablesMutation,
    isJobGlobalVariablesUpdated
  } = props || {};

  const { projectId }: $TSFixMe = useParams() || {};

  const classes = useStyles();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Job context
  const {
    project,

    variablesData,
    refetchVariables,

    jobRunConfigContextState,

    refetchJob,
    refetchScenarios,

    projectCanvasData,
    validateNodes,

    currentJobId: jobId,
    currentScenarioId,

    refetchDestinations,
    refetchDatasets
  } = useJobContext() || {};

  const scenarioId = useMemo(
    () => (!!jobId ? currentScenarioId : jobRunConfigContextState?.values?.scenario),
    [jobId, currentScenarioId, jobRunConfigContextState]
  );

  const [showPublishJobModal, setShowPublishJobModal] = useState<$TSFixMe>(false);

  const publishJob = async () => {
    if (!(await validateNodes())) {
      toastWrapper({
        type: "error",
        content: "No recipe found for the project. Cannot republish the job!"
      });

      return;
    }

    resetPublishJobMutation();
    await publishJobMutation(jobId);
  };

  useEffect(() => {
    const _ = async () => {
      refetchVariables();
      resetUpdateJobGlobalVariablesMutation();

      // variables - STARTS >>
      const variables: $TSFixMe = {};
      if ((variablesData || [])?.length > 0) {
        (variablesData || [])?.forEach((eachParameter: $TSFixMe) => {
          if (!!eachParameter?.name && !!eachParameter?.value) {
            variables[eachParameter.name] = eachParameter.value;
          }
        });
      }
      // << ENDS - variables

      const payload: $TSFixMe = {
        id: jobId,
        variables
      };

      await updateJobGlobalVariablesMutation(payload);
    };

    isJobPublished && _();
  }, [isJobPublished]);

  useEffect(() => {
    if (isJobGlobalVariablesUpdated) {
      refetchJob();
      refetchScenarios();
      refetchDatasets();
      refetchDestinations();
      queryClient.invalidateQueries([UseGetProjectCanvasQueryKeys.ProjectCanvas]);

      setShowPublishJobModal(() => false);

      toastWrapper({ type: "success", content: "Project Canvas is republished to the Scheduler!" });
    }
  }, [isJobGlobalVariablesUpdated]);

  const compareCanvas = () => {
    navigate(
      generatePath(`${WebPaths.JobRoutes}${WebPaths.JobCompare}`, {
        projectId,
        scenarioId,
        jobId
      })
    );
  };

  return (
    <>
      {showPublishJobModal && (
        <PublishJobModal
          handleClose={() => setShowPublishJobModal(() => false)}
          handleSubmit={() => publishJob()}
          isSubmitLoading={isPublishingJob}
        />
      )}

      {!isNodesValidated || isValidatingNodes || Object.keys(project || {})?.length === 0 ? (
        <CommonLoader />
      ) : !isValidNodes ? (
        <Alert severity="info" style={{ margin: "auto" }}>
          No recipe found! Canvas cannot be loaded.
        </Alert>
      ) : (
        <DagFlow
          jobProps={{
            scenarioId,
            ...(!!jobId
              ? {
                  ...(!isJobPublished
                    ? // Passing projectCanvasData to get it compared with jobCanvasData and enable canvas-actions
                      // if there they are different.
                      { jobId, canvasData: projectCanvasData, canvasBackgroundColor: "#fffee3" }
                    : {}),
                  ...(!hideCanvasActions
                    ? {
                        renderContent: (
                          <Grid
                            container
                            direction="row"
                            className={classes.canvasActionsContainer}>
                            <Tooltip title={JobsHelperText.RepublishActionInfo}>
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => setShowPublishJobModal(() => true)}>
                                Republish
                              </Button>
                            </Tooltip>
                            {!hideCanvasCompareAction && (
                              <Tooltip title={JobsHelperText.CompareActionInfo}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={() => compareCanvas()}>
                                  Compare
                                </Button>
                              </Tooltip>
                            )}
                          </Grid>
                        )
                      }
                    : {})
                }
              : { canvasBackgroundColor: "#fffee3" })
          }}
        />
      )}
    </>
  );
};

export default JobCanvas;
