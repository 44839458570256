import { Button, Grid, InputProps, makeStyles, Typography } from "@material-ui/core";

import { filter, includes, isEmpty, toLower } from "lodash";
import React, { useMemo, useState } from "react";
import NoDataFoundDefault from "../common/NoDataFoundDefault";
import { DataAppTile } from "../DataApps/DataAppsDashboard/DataAppsTiles/DataAppTile";
import { Search } from "src/components";
import styles from "./Dashboard.module.scss";
import { DataAppsConfig } from "../DataApps/utils/DataApps.constants";
import { useProjectsStore } from "src/store/store";
import { projectsGetter } from "src/store/store.selectors";
import { ProjectDto } from "@rapidcanvas/rc-api-core";

import { WebPaths } from "src/routing/routes";
import { useNavigate } from "react-router";
import DataAppConfigDrawer from "../DataApps/common/DataAppConfigDrawer";
import { EditDataAppDrawer } from "../DataApps/EditDataAppDrawer/EditDataAppDrawer";
import DataAppLogsDrawer from "../DataApps/DataAppLogs/DataAppLogsDrawer";
import _ from "lodash";
import { DataAppType } from "../DataApps/DataApps.type";
import { DeleteDataAppModal } from "../DataApps/DataAppsDashboard/DeleteDataAppModal";
import { useGetRole } from "src/hooks/useGetRole";
import { defaultImages } from "../DataApps/common/DataAppDefaultImage";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import useDownloadDataAppZip from "src/hooks/api/dataapps/useDownloadDataAppZip";

const useStyles = makeStyles({
  viewAllBtn: {
    color: "#003656",
    borderRadius: "20px",
    background: "#fff",
    margin: "40px 0px"
  },

  noResultMessage: {
    fontSize: "16px",
    fontStyle: "italic",
    color: "#7C7C7C",
    padding: "0px 16px",
    fontWeight: 400,
    backgroundColor: "#ffff",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
  },
  noResultToolTip: {
    backgroundColor: "transparent",
    margin: " -13px 0 0 0"
  }
});

interface IProps {
  availableWindowSize: number;
  startLoc: number;
  dataApps: DataAppType[] | undefined;
  numberofDataAppCards: number;
  cardWidth: number;
}

const RecentDataApps: React.FC<IProps> = ({
  availableWindowSize,
  startLoc,
  dataApps,
  numberofDataAppCards,
  cardWidth
}) => {
  const navigate = useNavigate();
  const { viewAllBtn } = useStyles();

  const projects = useProjectsStore(projectsGetter);
  const downloadCode = useDownloadDataAppZip();
  const { isRoleYieldsDataAppView } = useGetRole();

  const [dataAppSearch, setDataAppSearch] = useState("");
  const [configDataApp, setConfigDataApp] = useState<DataAppType | null>(null);
  const [logsDataApp, setLogsDataApp] = useState<DataAppType | null>(null);
  const [editingDataApp, setEditingDataApp] = useState<DataAppType | null>(null);
  const [dataAppToDelete, setDataAppToDelete] = useState<DataAppType | null>(null);

  const handleDataAppSearch: InputProps["onChange"] = (event) => {
    setDataAppSearch(event.target.value);
  };

  const projectIdNameMap = useMemo(
    () =>
      projects?.reduce(
        (acc: any, project: ProjectDto) => ({ ...acc, [project.id + ""]: project.name }),
        {}
      ),
    [projects]
  );

  const updatedDataApps = useMemo(() => {
    let imageIndex = 0;
    if (dataApps)
      return dataApps?.map((dataApp) => {
        const projectName = projectIdNameMap?.[dataApp.projectId];
        const updatedImage = !dataApp.iconUrl ? defaultImages[imageIndex] : null;

        const isLastImage = imageIndex === defaultImages.length - 1;
        imageIndex = dataApp.iconUrl ? imageIndex : isLastImage ? 0 : imageIndex + 1;
        const stoppedBy =
          dataApp.stoppedBy === "CleanupJob"
            ? "System (Due to Inactivity)"
            : dataApp.stoppedBy || " - ";
        return { ...dataApp, imgComponent: updatedImage, projectName, stoppedBy };
      });
    return [];
  }, [dataApps, projectIdNameMap]);

  const filteredDataApps = useMemo(
    () =>
      _.orderBy(
        filter(updatedDataApps, (item) => includes(toLower(item.name), toLower(dataAppSearch))),
        [(item) => (item.launchStatus === "RUNNING" ? 1 : 0), "updatedOn"],
        ["desc", "desc"]
      ),

    [updatedDataApps, dataAppSearch]
  );

  const navigateToViewAllDataApps = () => {
    if (dataAppSearch) {
      navigate(WebPaths.Dataapps + `?search=${dataAppSearch}`);
    } else {
      navigate(WebPaths.Dataapps);
    }
  };

  const handleClose = () => {
    setConfigDataApp(null);
  };

  const handleConfigOpen = (appInfo: DataAppType) => {
    setConfigDataApp(appInfo);
  };

  const handleDataAppEdit = (appInfo: DataAppType) => {
    setEditingDataApp(appInfo);
  };

  const handleLogsOpen = (appInfo: DataAppType) => {
    setLogsDataApp(appInfo);
  };

  const handleDownloadCode = (appInfo: DataAppType) => {
    downloadCode.mutate({
      templateId: appInfo.appTemplateId,
      appType: appInfo.appType!
    });
  };

  const handleDelete = (dataApp: DataAppType) => {
    setDataAppToDelete(dataApp);
  };

  return (
    <NewThemeWrapper>
      {configDataApp && (
        <DataAppConfigDrawer
          open={!_.isEmpty(configDataApp)}
          dataApp={configDataApp}
          onClose={handleClose}
        />
      )}
      {editingDataApp && (
        <EditDataAppDrawer dataApp={editingDataApp} onClose={() => setEditingDataApp(null)} />
      )}
      {logsDataApp && (
        <DataAppLogsDrawer
          open={!_.isEmpty(logsDataApp)}
          dataApp={logsDataApp}
          onClose={() => setLogsDataApp(null)}
        />
      )}
      {dataAppToDelete && (
        <DeleteDataAppModal
          dataAppId={dataAppToDelete?.id}
          dataAppName={dataAppToDelete?.displayName || dataAppToDelete?.name}
          open={!!dataAppToDelete?.id}
          onClose={() => setDataAppToDelete(null)}
        />
      )}
      <Grid key="recent-jobs" container className={styles.dashboardContainer}>
        <Grid item container xs={12} style={{ padding: `0 ${startLoc}px`, marginBottom: "15px" }}>
          <Typography data-testid="recentJobText" className={styles.sectionContent}>
            Recent DataApps
          </Typography>
          <Grid style={{ marginLeft: "auto" }}>
            <Search
              onSearch={handleDataAppSearch}
              value={dataAppSearch}
              placeholder="Search DataApps"
            />
          </Grid>
        </Grid>
        {isEmpty(dataApps) || isEmpty(filteredDataApps) ? (
          <NoDataFoundDefault
            title={
              dataApps?.length === 0
                ? "No dataApp found"
                : `No dataApp found with keyword "${dataAppSearch}"`
            }
            subTitle={dataApps?.length === 0 ? "" : undefined}
            onClear={() => setDataAppSearch("")}
            minHeight="314.5px"
          />
        ) : (
          <>
            <Grid
              container
              style={{
                gap: "24px",
                width: availableWindowSize,
                padding: `0 ${startLoc}px`
              }}
              justifyContent="flex-start">
              {filteredDataApps?.map((data: $TSFixMe, i: number) => {
                if (i >= numberofDataAppCards) {
                  return null;
                }
                return (
                  <Grid
                    key={data.id}
                    item
                    xs
                    style={{
                      flex: `0 0 ${cardWidth}px`,
                      width: cardWidth,
                      height: DataAppsConfig.CardHeight
                    }}>
                    <DataAppTile
                      data={data}
                      isRoleYieldsDataAppView={isRoleYieldsDataAppView}
                      onConfigOpen={handleConfigOpen}
                      onEdit={handleDataAppEdit}
                      onLogsOpen={handleLogsOpen}
                      onDelete={handleDelete}
                      onDownloadCode={handleDownloadCode}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              style={{
                paddingLeft: filteredDataApps?.length === 1 ? startLoc : "0px",
                margin: projects.length < 2 ? "20px 0" : 0
              }}
              alignItems="center"
              justifyContent={"center"}
              container>
              {filteredDataApps?.length > 1 ? (
                <Button
                  variant="text"
                  className={viewAllBtn}
                  onClick={() => navigateToViewAllDataApps()}>
                  {`VIEW ALL ${filteredDataApps.length} DATAAPPS`}
                </Button>
              ) : (
                <Grid
                  style={{
                    margin: "58.25px 0px"
                  }}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </NewThemeWrapper>
  );
};

export default RecentDataApps;
