import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";
import { ProjectDto, UpdateProjectDto } from "@rapidcanvas/rc-api-core";

const useUpdateProject = (): UseMutationResult<ProjectDto, unknown, UpdateProjectDto, unknown> =>
  useMutation({
    mutationFn: async (payload: UpdateProjectDto) =>
      await api.fetchResponse(
        async () => await api.ProjectControllerApi.updateProject({ ...payload })
      )
  });

export default useUpdateProject;
