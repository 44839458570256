import React, { useMemo } from "react";

import { Button, CircularProgress } from "@material-ui/core";
import NewModal from "src/components/Modal/NewModal";
import Result from "src/components/Errors/Result";
import SomethingWentWrong from "src/components/Errors/SomethingWentWrong";
import { OverflowTooltip, Spinner } from "src/components";
import usePreviewArtifactFile, {
  getContentType
} from "src/hooks/api/artifacts/usePreviewArtifactFile";
import PrettyJsonEditor from "src/pages/private/ProjectsModule/pages/PredictionService/components/PrettyJsonEditor";
import MarkdownDisplay from "src/components/MarkdownDisplay";

export const MAX_LIMIT_EXCEEDED_MSG = "max-limit-exceeded";
export const MAX_ALLOWED_SIZE_FOR_PREVIEW = 50;

interface IProps {
  name: string;
  open: boolean;
  onClose: () => void;
  artifactName: string;
  type: string;
}

const ArtifactPreviewModal: React.FC<IProps> = (props) => {
  const { name, open, onClose, artifactName, type } = props;

  const isDoc = useMemo(
    () => name.toLowerCase().endsWith(".docx") || name.toLowerCase().endsWith(".doc"),
    [name]
  );

  const contentType = getContentType(type);

  const { data, isLoading, isError, refetch, isFetching } = usePreviewArtifactFile(
    `${artifactName}/${name}`,
    type
  );

  if (isDoc) {
    return null;
  }

  const getChildren = () => {
    switch (true) {
      case data === MAX_LIMIT_EXCEEDED_MSG:
        return (
          <Result
            heading={`Preview is unavailable for files larger than ${MAX_ALLOWED_SIZE_FOR_PREVIEW}MB`}
            subtitle1="Kindly open the downloaded file to view its contents"
            icon={<></>}
            extra={
              <Button
                size="small"
                variant="contained"
                disabled={isFetching}
                startIcon={
                  isFetching ? <CircularProgress style={{ color: "#fff" }} size={16} /> : undefined
                }
                onClick={() => refetch()}>
                Download Again
              </Button>
            }
          />
        );
      case isError:
        return (
          <SomethingWentWrong
            hideMargin
            heading="Something went wrong"
            subtitle1="Error in downloading the file"
          />
        );

      case isLoading:
        return <Spinner />;
      case !data:
        return <SomethingWentWrong hideMargin heading="Something went wrong" />;

      case name.toLowerCase().endsWith(".md"): {
        return <MarkdownDisplay string={data || ""} />;
      }
      case contentType.startsWith("text/html"): {
        return (
          <iframe
            src={data}
            style={{
              width: "100%",

              height: "calc(100vh - 200px)",
              border: "none",
              boxShadow: "none"
            }}
            title="Artifact Preview"
          />
        );
      }
      case contentType.startsWith("text/"): {
        return <pre style={{ textWrap: "wrap", color: "#000", fontSize: "14px" }}>{data}</pre>;
      }

      case type === "json": {
        return <PrettyJsonEditor jsonString={data} height="calc(100vh - 200px) !important" />;
      }
      case contentType.startsWith("image/"): {
        return (
          <img src={data} alt={name} style={{ width: "100%", maxHeight: "calc(100vh - 200px)" }} />
        );
      }
      case contentType === "application/pdf": {
        return (
          <iframe
            src={data}
            style={{
              width: "100%",
              height: "calc(100vh - 200px)",
              border: "none",
              boxShadow: "none"
            }}
            title="Artifact Preview"
          />
        );
      }
      case contentType.startsWith("video/"): {
        return (
          <video controls src={data} style={{ width: "100%" }}>
            Your browser does not support the video tag.
          </video>
        );
      }

      default:
        return <p>Unsupported file type: {name}</p>;
    }
  };

  return (
    <>
      <NewModal
        open={open}
        width={"70%"}
        maxHeight={"calc(100vh - 130px)"}
        header={<OverflowTooltip style={{ whiteSpace: "nowrap" }} value={`Preview of ${name}`} />}
        hideFooter
        onClose={onClose}>
        {getChildren()}
      </NewModal>
    </>
  );
};

export default ArtifactPreviewModal;
