import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";
import { capitalize, get, includes, isEmpty } from "lodash";
import { useState, useCallback, useMemo } from "react";
import useStatus from "./useStatus";
import { getEnvironmentUsageDetails } from "../utils/Environments.helpers";
import { EnvironmentStatusValuesMapping } from "src/pages/private/EnvironmentsModule/utils/Environments.constants";
import { useSelector } from "react-redux";
import { environmentActionStatusSelector } from "../../../Environment.selectors";
import { EnvironmentActionsStatusEnum } from "src/stores/environmentsSlice";

export const useStatusWithStopEnvState = ({ environment }: { environment: EnvDto }) => {
  const { id, launchStatus: status } = environment;

  const [showConfirmStopEnvModal, setShowConfirmStopEnvModal] = useState<any>(null);

  const { onRelaunch, onStop, dispatchEnvStopping, resetEnvStopping } = useStatus({
    environment
  });

  const environmentStatus = useSelector(environmentActionStatusSelector(id!));

  const promptConfirmStopEnvironment = useCallback(async () => {
    if (!id || !status) return;

    try {
      dispatchEnvStopping({
        id,
        status: EnvDtoLaunchStatusEnum.Stopping
      });

      const types = await getEnvironmentUsageDetails(id);

      if (isEmpty(types)) {
        onStop(id);
      } else {
        resetEnvStopping({ id, status });

        setShowConfirmStopEnvModal(() => ({ types, action: "stopping" }));
      }
    } catch {
      resetEnvStopping({ id, status });
    }
  }, [id, status]);

  const resetStopEnvironment = useCallback(() => setShowConfirmStopEnvModal(() => null), []);

  const confirmStopEnvironment = useCallback(() => {
    resetStopEnvironment();
    onStop(id);
  }, [id]);

  const onClick = () => {
    status === EnvDtoLaunchStatusEnum.Success ? promptConfirmStopEnvironment() : onRelaunch(id);
  };

  const { statusText, showProgress, statusClass } = useMemo(() => {
    if (!status) return { statusClass: "", statusText: "", showProgress: false };

    const statusClass = get(EnvironmentStatusValuesMapping, [status, "cssClassName"]);

    const statusText = get(
      EnvironmentStatusValuesMapping,
      [status, "displayName"],
      capitalize(status) || "Unknown"
    );

    const showProgress =
      includes([EnvDtoLaunchStatusEnum.Launching, EnvDtoLaunchStatusEnum.Stopping], status) ||
      includes(
        [EnvironmentActionsStatusEnum.Launching, EnvironmentActionsStatusEnum.Stopping],
        environmentStatus
      );

    return { statusText, showProgress, statusClass };
  }, [status, environmentStatus]);

  return {
    showConfirmStopEnvModal,
    confirmStopEnvironment,
    resetStopEnvironment,
    onClick,
    statusText,
    showProgress,
    statusClass
  };
};
